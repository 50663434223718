// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { GetCreditCards } from 'State/subscription/thunk';
import { GetGlobalStatus, GetOpsCreditCapacity } from 'State/globalStatus/thunk';
import { PlaceOrder, SaveEstimate, SetPlaceOrderSuccess } from 'State/order/thunk';
import { getOrders } from 'State/order/selectors';
import { GetBundles, ResetBundleState } from 'State/bundle/thunk';
import { getProjectEPSGCode, getProjectFiles } from 'State/project/selectors';
import { GetProjectEpsgCode } from 'State/project/thunk';

const mapStateToProps = (state: IState) => {
  const { projectId } = state.project;
  const project = _ADMIN_ ? state.project.list.find((p) => p._id === projectId) : state.project.project;
  const { selectedBundle } = state.bundle;
  const { imageryExtraDollarCost } = selectedBundle || {};

  const { fileStatusJson } = state.project;
  const files = getProjectFiles(state);
  const { orgTeams } = state.account;
  const { teams } = state.auth.user;
  const epsg = getProjectEPSGCode(state);

  const availableTeamNames: string[] = [];
  function isUserTeam(value: ITeam) {
    if (teams.includes(value._id)) {
      availableTeamNames.push(value.name);
      return value._id;
    }
    return false;
  }
  const availableTeams = orgTeams.filter((t) => isUserTeam(t));

  return {
    tif: project.tifFiles.length,
    las: project.lasFiles.length,
    tifEpsgCode: epsg?.tifEpsg,
    lasEpsgCode: epsg?.lasEpsg,
    epsgMatch: epsg?.epsgMatch,
    creditCards: state.subscription.creditCards,
    adjustedOrderAcres: state?.bundle?.selectedBundle?.adjustedOrderAcres,
    selectedBundle: state.bundle.selectedBundle,
    order: getOrders(state).activeOrder,
    project: state?.project?.project,
    selectedAdditionals: state.bundle.selectedAdditionals,
    bundleDeliverables: state.bundle.bundleDeliverables,
    availableCreditAcreage: state.account.org.availableCreditAcreage,
    imageryExtraDollarCost: imageryExtraDollarCost || 0,
    costPerAcre: state.account.org.costPerAcre,
    totalCost: state.bundle.totalCost,
    placeOrderSuccess: state.order.placeOrderSuccess,
    files,
    fileStatusJson,
    deliveryDate: state.bundle.deliveryDate,
    lowDensity: state.bundle?.density === 'low',
    selectedBundleId: state.bundle?.selectedBundle?.bundleId,
    imageryCreditCost: state.bundle?.selectedBundle?.imageryCreditCost,
    topoMajr: state.bundle.topoMajr,
    topoMinr: state.bundle.topoMinr,
    availableTeams,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetCreditCardsAction: GetCreditCards,
    GetGlobalStatusAction: GetGlobalStatus,
    PlaceOrderAction: PlaceOrder,
    SaveEstimateAction: SaveEstimate,
    ResetBundleStateAction: ResetBundleState,
    SetPlaceOrderSuccessAction: SetPlaceOrderSuccess,
    GetBundlesAction: GetBundles,
    GetProjectEpsgCodeAction: GetProjectEpsgCode,
    GetOpsCreditCapacityAction: GetOpsCreditCapacity,
  }, dispatch);

interface IOwnProps {
  orderFormVisible: boolean;
  setOrderFormVisible: (show: boolean) => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
