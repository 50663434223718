// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect, useLayoutEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import usePrevious from 'Common/hooks/usePrevious';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const LoadingSpinner = ({ loading, children }: PropsType) => {
  const classes = useStyles();
  const [showSpinner, setShowSpinner] = useState(false);
  const [timer, setTimer] = useState(null);
  const [show, setShow] = useState(true);

  const prevLoading = usePrevious(loading);

  useEffect(() => {
    if (show && prevLoading && !loading) {
      setShow(false);
    }
  }, [loading]);

  useLayoutEffect(() => {
    const t = setTimeout(
      () => setShowSpinner(true),
      10,
    );
    setTimer(t);

    return function cleanup() {
      clearTimeout(timer);
    };
  }, []);

  if (show) {
    return showSpinner ? (
      <div className={classes.wrapper}>
        <CircularProgress />
      </div>
    ) : null;
  }

  return (
    <>
      {children}
    </>
  );
};

export default compose(LoadingSpinner);
