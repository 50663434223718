// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createSelector } from 'reselect';

const getKeywordFilter = (state: IState) => state.project.keywordFilter.toLowerCase();
const getSortFilter = (state: IState) => state.project.sortFilter;
const getProjectsList = (state: IState) => state.project.list;
const getProjectId = (state: IState) => state.project.projectId;
const getFileStatuses = (state: IState) => state.project.fileStatusJson;
const getProject = (state: IState) => state.project.project;
const getNumProjectStatuses = (state: IState) => state.project.project && state.project.project.fileStatuses.length;

const projectsFilterSelector = createSelector(
  [getKeywordFilter, getSortFilter, getProjectsList],
  (keywordFilter, sortFilter, projects) => {
    const filtered = projects
      .filter((project) => !keywordFilter || project.name.toLowerCase().indexOf(keywordFilter) > -1);

    filtered
      .sort((project1, project2) => {
        const projectName1 = project1.name.toUpperCase();
        const projectName2 = project2.name.toUpperCase();

        switch (sortFilter) {
        case 'az':
          return projectName1 > projectName2 ? 1 : -1;
        case 'za':
          return projectName1 < projectName2 ? 1 : -1;
        default:
          return project1.updatedAt < project2.updatedAt ? 1 : -1;
        }
      });

    return filtered;
  },
);

const getProjectEPSGCode = createSelector(
  [getProject], (project) => {
    const tifEpsgs = project?.tifFiles?.filter((tif) => (tif.epsg > -1)).map((tif) => tif.epsg);
    const lasEpsgs = project?.lasFiles?.filter((las) => (las.epsg > -1)).map((las) => las.epsg);
    const tifEpsg = tifEpsgs.length ? tifEpsgs[0] : null;
    const lasEpsg = lasEpsgs.length ? lasEpsgs[0] : null;
    const epsgMatch = tifEpsgs?.some((epsg) => lasEpsgs?.includes(epsg));

    return { tifEpsg, lasEpsg, epsgMatch };
  },
);

const getAllCoordinates = createSelector(
  [getProjectId, getProjectsList],
  (projectId, projects) => {
    const coordinatesInfo = projects
      .filter((project) => project.middlePoint)
      .map((p) => ({
        coords: [p.middlePoint.longitude, p.middlePoint.latitude],
        hovered: p._id === projectId,
      }));
    return coordinatesInfo;
  },
);

const getHoveredCoordinates = createSelector(
  [getProjectId, getProjectsList],
  (projectId, projects) => {
    if (projectId !== null) {
      const project = projects
        .filter((p) => p._id === projectId);
      if (project[0] && project[0].middlePoint) {
        const projectCoordinates = [project[0].middlePoint.longitude, project[0].middlePoint.latitude];
        return projectCoordinates;
      }
    }
    return null;
  },
);

// Selector to return all uploaded files for a given project
const getProjectFiles = createSelector(
  [getProject],
  (project) => {
    if (project) {
      const tifFiles = project.tifFiles?.map((tifFile) => ({ name: tifFile.filename.split('/').pop(), _id: tifFile._id, epsg: tifFile.epsg, type: 'tif' }));
      const lasFiles = project.lasFiles?.map((lasFile) => {
        const { filename, _id, epsg, tile3DUrl, tileStatuses } = lasFile;
        return {
          name: filename.split('/').pop(),
          _id,
          epsg,
          type: 'las',
          tile3DUrl,
          tileStatuses,
        };
      });
      const projectFiles: IProjectFile[] = [...tifFiles, ...lasFiles];
      return projectFiles;
    }
    return [];
  },
);

export {
  projectsFilterSelector,
  getProjectEPSGCode,
  getAllCoordinates,
  getHoveredCoordinates,
  getProjectFiles,
};
