// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Page, Text, View, Document, Image } from '@react-pdf/renderer';
import logo from 'Assets/logo_small_dark.png';
import checkIcon from 'Assets/check-icon.png';
import checkCircleIcon from 'Assets/circle-check.png';
import { formatValue, deliverableCost } from 'Utils/deliverables';
import moment from 'moment';
import compose, { PropsType } from './container';
import styles from './styles';

export const PDF = React.memo(({ data }: PropsType) => {
  const {
    type,
    automatePlanType,
    projectName,
    referenceId,
    team,
    acreage,
    middlePoint,
    coordinates,
    cadTemplate,
    crs,
    topoMajr,
    topoMinr,
    selectedBundle,
    businessDays,
    bundleDeliverables,
    selectedAdditionals,
    screenshot,
    orderDate,
    deliveryDate,
    chargedCredits,
    chargedCreditsInDollars,
    totalCreditsCharged,
    totalCreditsChargedInDollars,
    addOnsCreditsString,
    deliverablesCreditsString,
    layersCreditsString,
    totalAdditionalCharged,
    totalCostForProject,
    anticipatedKickoffDate,
    imageryProvider,
    imageryCreditCost,
    imageryDollarCost,
  } = data;
  const addOnsDeliverables: IDeliverable[] = [];
  const addOnsTopography: IDeliverable[] = [];
  const layersUtilities: IDeliverable[] = [];
  const layersRoadSidewalk: IDeliverable[] = [];
  const layersVegetationLandscape: IDeliverable[] = [];
  const layersTopography: IDeliverable[] = [];
  const layersAdministrativeAnnotations: IDeliverable[] = [];
  const layersPlanimetrics: IDeliverable[] = [];
  const layersMeasurements: IDeliverable[] = [];
  const layersWallsFences: IDeliverable[] = [];

  selectedAdditionals.forEach((item: IDeliverable) => {
    if (item.type === 'deliverable' && item.subtype === 'Deliverables') addOnsDeliverables.push(item);
    else if (item.type === 'deliverable' && item.subtype === 'Topography') addOnsTopography.push(item);
    else if (item.subtype === 'Utilities') layersUtilities.push(item);
    else if (item.subtype === 'Road & Sidewalk') layersRoadSidewalk.push(item);
    else if (item.subtype === 'Vegetation & Landscape') layersVegetationLandscape.push(item);
    else if (item.type === 'layer' && item.subtype === 'Topography') layersTopography.push(item);
    else if (item.subtype === 'Administrative & Annotations') layersAdministrativeAnnotations.push(item);
    else if (item.subtype === 'Planimetrics') layersPlanimetrics.push(item);
    else if (item.subtype === 'Measurements') layersMeasurements.push(item);
    else if (item.subtype === 'Walls & Fences') layersWallsFences.push(item);
  });
  const addOnsSelected = addOnsDeliverables.length || addOnsTopography.length;
  const layersSelected = layersUtilities.length || layersRoadSidewalk.length || layersVegetationLandscape.length || layersTopography.length ||
  layersAdministrativeAnnotations.length || layersPlanimetrics.length || layersMeasurements.length || layersWallsFences.length;

  const addOns = [
    { name: 'Deliverables', data: addOnsDeliverables },
    { name: 'Topography', data: addOnsTopography },
  ];

  const layers = [
    { name: 'Utilities', data: layersUtilities },
    { name: 'Road & Sidewalk', data: layersRoadSidewalk },
    { name: 'Vegetation & Landscape', data: layersVegetationLandscape },
    { name: 'Topography', data: layersTopography },
    { name: 'Administrative & Annotations', data: layersAdministrativeAnnotations },
    { name: 'Planimetrics', data: layersPlanimetrics },
    { name: 'Measurements', data: layersMeasurements },
    { name: 'Walls & Fences', data: layersWallsFences },
  ];

  const deliverySpeed = selectedBundle.deliverySpeed?.split('_').join(' ');
  const date = moment(new Date()).format('MMMM Do YYYY, h:mm a');

  return (
    <Document>
      <Page size="LETTER">
        <View style={styles.container}>
          <View style={styles.header}>
            <View style={styles.headerLeft}>
              <Image src={logo} style={styles.logo} />
              <Text style={styles.name}>AIRWORKS</Text>
            </View>
            <View style={styles.headerRight}>
              <Text style={styles.text1}>Generated on: </Text>
              <Text style={styles.text2}>{date}</Text>
            </View>
          </View>
          <View style={styles.content}>
            <View style={styles.package}>
              <Text style={styles.title1}>{type === 'Estimate' ? 'Order Estimate' : 'Order Details'}</Text>
              {type === 'Invoice' && (
                <View style={styles.dates}>
                  <View style={styles.bundleItemsContainer}>
                    <Text style={styles.text2}>SUBMITTED ON:</Text>
                    <Text style={styles.text1}>{orderDate}</Text>
                  </View>
                  <View style={styles.bundleItemsContainer}>
                    <Text style={styles.text2}>DELIVERED ON:</Text>
                    <Text style={styles.text1}>{deliveryDate}</Text>
                  </View>
                </View>
              )}
              <Text style={styles.title2}>Your Package</Text>
              <Text style={styles.text1}>
                This is your base package that is included in your plan and comes with every project.
              </Text>
              <View style={styles.bundle}>
                <Image src={checkCircleIcon} style={styles.icon2} />
                <View style={styles.bundleInfo}>
                  <Text style={styles.title3}>{selectedBundle.name}</Text>
                  {selectedBundle.acreAdjustmentFactor && <Text style={styles.text3}>{`1 Credit = ${selectedBundle.acreAdjustmentFactor} ${selectedBundle.name}`}</Text>}
                </View>
              </View>
              {(selectedBundle.bundleId !== 5) && (
                <View style={styles.bundleItems}>
                  <View style={styles.bundleItemsContainer}>
                    <Text style={styles.text2}>LAYERS</Text>
                    {bundleDeliverables.map((item: IDeliverable) =>
                      (item.type === 'layer' ? (
                        <View style={styles.bundleListItem} key={item._id}>
                          <Image src={checkIcon} style={styles.icon} />
                          <Text style={styles.text1}>{item.name}</Text>
                        </View>
                      ) : null))}
                  </View>
                  <View style={styles.bundleItemsContainer}>
                    <Text style={styles.text2}>DELIVERABLES</Text>
                    {bundleDeliverables.map((item: IDeliverable) =>
                      (item.type === 'deliverable' ? (
                        <View style={styles.bundleListItem} key={item._id}>
                          <Image src={checkIcon} style={styles.icon} />
                          <Text style={styles.text1}>{item.name}</Text>
                        </View>
                      ) : null))}
                  </View>
                </View>
              )}
              {addOnsSelected ? (
                <View>
                  <Text style={styles.title2}>Additional Deliverables</Text>
                  {addOns.map((addOn) => (addOn.data.length ? (
                    <View key={addOn.name}>
                      <Text style={styles.text2}>{addOn.name}</Text>
                      {addOn.data.map((item) => (
                        <View style={styles.listItem} key={item._id}>
                          <View style={styles.addOnListItem}>
                            <Image src={checkIcon} style={styles.icon} />
                            <Text style={styles.text4}>
                              {item.name}
                              {item.acres || type === 'Estimate' ? `   ${deliverableCost(item.acreFactor, acreage).toFixed(2)} credits ` : `   $${formatValue(item.cost)}`}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  ) : null))}
                </View>
              ) : null}

              {layersSelected ? (
                <View>
                  <Text style={styles.title2}>Layers</Text>
                  {layers.map((layer) => (layer.data.length ? (
                    <View key={layer.name}>
                      <Text style={styles.text2}>{layer.name}</Text>
                      {layer.data.map((item) => (
                        <View style={styles.listItem} key={item._id}>
                          <View style={styles.addOnListItem}>
                            <Image src={checkIcon} style={styles.icon} />
                            <Text style={styles.text4}>
                              {item.name}
                              {item.acres || type === 'Estimate' ? `   ${deliverableCost(item.acreFactor, acreage).toFixed(2)} credits ` : `   $${formatValue(item.cost)}`}
                            </Text>
                          </View>
                        </View>
                      ))}
                    </View>
                  ) : null))}
                </View>
              ) : null}
            </View>

            <View style={styles.mapColumn}>
              <View style={styles.projectInfo}>
                <View style={styles.projectInfoLeft}>
                  <Text style={styles.title1}>{projectName}</Text>
                  <Text style={styles.text2}>REFERENCE ID:</Text>
                  <Text style={styles.text1}>{referenceId || 'N/A'}</Text>
                  <Text style={styles.text2}>TEAM:</Text>
                  <Text style={styles.text1}>{team || 'N/A'}</Text>
                  <Text style={styles.text1}> </Text>
                  <Text style={styles.text1}>
                    {acreage}
                    {' '}
                    acres
                  </Text>
                  <Text style={styles.text1}>{middlePoint}</Text>
                  <Text style={styles.text1}>{coordinates}</Text>
                </View>
                {type === 'Estimate' && !!screenshot && (
                  <View style={styles.projectInfoRight}>
                    <Image style={styles.screenshot} src={screenshot} />
                  </View>
                )}
              </View>
              <View style={styles.line} />
              <Text style={styles.text2}>CAD TEMPLATE</Text>
              <Text style={styles.text1}>{cadTemplate}</Text>
              <Text style={styles.text6}>CRS COORDINATE SYSTEM</Text>
              <Text style={styles.text1}>{crs || 'N/A'}</Text>
              {(topoMajr || topoMinr) && (
                <View>
                  <Text style={styles.text6}>TOPOGRAPHY INTERVAL</Text>
                  <Text style={styles.text1}>
                    {topoMajr && `Major: ${topoMajr} ft`}
                    {' '}
                    {topoMinr && `Minor: ${topoMinr} ft`}
                  </Text>
                </View>
              )}
              <Text style={styles.text6}>PROJECT DENSITY</Text>
              <Text style={styles.text1Cap}>{selectedBundle.density}</Text>
              <View style={styles.line} />
              {type === 'Estimate' && (
                <>
                  <Text style={styles.text2}>ESTIMATED DELIVERY DATE</Text>
                  <Text style={styles.text3}>{businessDays > 20 ? 'Estimate available upon request' : `${businessDays} Business Days`}</Text>
                </>
              )}
              {selectedBundle.deliverySpeed !== 'normal' && (
                <View style={styles.bundleListItem}>
                  <Image src={checkIcon} style={styles.icon} />
                  <Text style={styles.text4Cap}>
                    {deliverySpeed}
                    {' '}
                    Delivery
                  </Text>
                </View>
              )}
              {type === 'Estimate' && anticipatedKickoffDate && (
                <>
                  <Text style={styles.text2}>KICKOFF DATE</Text>
                  <Text style={styles.text3}>{moment.utc(anticipatedKickoffDate).format('MMMM Do YYYY')}</Text>
                </>
              )}
              <View style={styles.line} />

              <View style={styles.listItem}>
                <Text style={styles.text2}>{`${selectedBundle.name} Credits`}</Text>
                <Text style={styles.text2}>
                  {`${chargedCredits} credits`}
                  {type === 'Estimate' && `  ($${formatValue(chargedCreditsInDollars)})`}
                </Text>
              </View>
              <View style={styles.orderInfo}>
                <Text style={styles.text5}>- Project Size</Text>
                <Text style={styles.text5}>
                  {acreage}
                  {' '}
                  ac
                </Text>
              </View>
              <View style={styles.orderInfo}>
                <Text style={styles.text5}>- Bundle</Text>
                <Text style={styles.text5}>{selectedBundle.name}</Text>
              </View>
              <View style={styles.orderInfo}>
                <Text style={styles.text5}>- Density</Text>
                <Text style={styles.text5Cap}>{selectedBundle.density}</Text>
              </View>
              <View style={styles.orderInfo}>
                <Text style={styles.text5}>- Delivery Speed</Text>
                <Text style={styles.text5Cap}>{deliverySpeed}</Text>
              </View>

              <View style={styles.line} />

              <View style={styles.listItem}>
                <Text style={styles.text2}>Add-Ons</Text>
                <Text style={styles.text2}>
                  {addOnsCreditsString}
                </Text>
              </View>
              <View style={styles.orderInfo}>
                <Text style={styles.text5}>- Deliverables</Text>
                <Text style={styles.text5}>{deliverablesCreditsString}</Text>
              </View>
              <View style={styles.orderInfo}>
                <Text style={styles.text5}>- Layers</Text>
                <Text style={styles.text5}>{layersCreditsString}</Text>
              </View>
              {/* TODO - fix imageryDollarCost for order history pdf - Ishan 12/02/22 */}
              {imageryProvider && (
                <View style={styles.orderInfo}>
                  <Text style={styles.text5}>{`- ${imageryProvider} Imagery`}</Text>
                  <Text style={styles.text5}>
                    {`${imageryCreditCost} credits`}
                    {imageryDollarCost !== undefined && ` ($${formatValue(imageryDollarCost)})`}
                  </Text>
                </View>
              )}

              <View style={styles.listItem}>
                <Text style={styles.text2}>Total Credits Charged</Text>
                <Text style={styles.text2}>
                  {`${totalCreditsCharged} credits`}
                  {type === 'Estimate' && `  ($${formatValue(totalCreditsChargedInDollars)})`}
                </Text>
              </View>

              {totalAdditionalCharged > 0 && (
                <View style={styles.listItem}>
                  <Text style={styles.text2}>Total Additional Charges</Text>
                  <Text style={styles.text2}>{`$${formatValue(totalAdditionalCharged)}`}</Text>
                </View>
              )}
              {automatePlanType !== 'plan_free' && (
                <View style={styles.listItem}>
                  <Text style={styles.title4}>Total cost for project</Text>
                  <Text style={styles.title4}>{totalCostForProject >= 0 ? `$${formatValue(totalCostForProject)}` : 'N/A'}</Text>
                </View>
              )}

              {totalAdditionalCharged === 0 && (
                <Text style={styles.text7}>
                  &#42;
                  This project incurs no new charges. Cost is for estimating purposes and will come out of your account's current credit balance.
                </Text>
              )}

              {automatePlanType !== 'plan_free' && totalAdditionalCharged > 0 && (
                <Text style={styles.text7}>
                  &#42; Total cost is for estimating purpose only, this project includes purchases that exceed your license contact your sales rep to upgrade and reduce your project cost.
                </Text>
              )}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
});

export default compose(PDF);
