// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  warningIcon: {
    color: theme.palette.primary.main,
    width: '30px',
    height: '30px',
  },
  dialog: {
    minWidth: '600px',
    margin: 'auto',
  },
  dialogBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  dialogTitle: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  okButton: {
    color: '#B1A9A7',
    marginLeft: 'none',
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
  contactButton: {
    color: '#2E4DEB',
    marginLeft: 'none',
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
  titleText: {
    paddingLeft: theme.spacing(1),
  },
  linkColor: {
    color: '#01A1DD',
    fontWeight: 400,
  },
  compare: {
    display: 'grid',
    gridTemplateColumns: '1fr auto 1fr',
    alignItems: 'center',
    gridColumnGap: theme.spacing(3),
    marginTop: theme.spacing(3),
  },
  line: {
    width: '1px',
    height: '100%',
    backgroundColor: '#eee',
    border: 'none',
  },
  title: {
    fontWeight: 600,
  },
  subTitle: {
    color: '#788898',
    marginTop: theme.spacing(2),
  },
  terms: {
    color: '#788898',
    fontStyle: 'italic',
    marginTop: theme.spacing(3),
  },
  hrLine: {
    backgroundColor: '#eee',
    height: '1px',
    border: 'none',
  },
  cost: {
    fontWeight: 400,
    lineHeight: '28px',
  },
});

export default styles;
