// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, CircularProgress, Fade } from '@material-ui/core/';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation, onSaveChanges  }: PropsType) => {
    const classes = useStyles();
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const onCancelClick = () => {
      setModalVisible(false);
    };
    
    const handleBlockedNavigation = (nextLocation: Location): boolean => {
      if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
        setModalVisible(true);
        setLastLocation(nextLocation);
        return false;
      }
      return true;
    };

    const onSaveChangesClick = async () => {
      setSubmitting(true)
      await onSaveChanges();
      setSubmitting(false);
      setModalVisible(false);
    };
    
    const handleLeaveNavigationClick = () => {
      setModalVisible(false);
      setConfirmedNavigation(true);
    }; 
    useEffect(() => {
        setConfirmedNavigation(false)
        if (confirmedNavigation && lastLocation) {
          // Navigate to the previous blocked location with your navigate function
          navigate(lastLocation.pathname);
        }
      }, [confirmedNavigation, lastLocation]);
      return (
        <>
          <Prompt when={when} message={handleBlockedNavigation} />
    <Dialog
        disableBackdropClick
        open={modalVisible}
        onClose={handleLeaveNavigationClick}
    >
        <IconButton aria-label="close" className={classes.closeButton} onClick={onCancelClick}>
          <CloseIcon />
        </IconButton>
        <DialogTitle classes={{root: classes.dialogRoot}}><ErrorIcon className={classes.warningIcon}/> You have unsaved changes to your template!
        </DialogTitle>
        <DialogContent>Any unsaved changes will be lost after leaving the page.</DialogContent>
        <DialogActions>
        <Button
              className={classes.onSave}
              onClick={onSaveChangesClick}
            >
              <Fade in={submitting}>
                <CircularProgress size={16} className={classes.buttonProgress} />
              </Fade>
              <Fade in={!submitting}>
                <span>Save Changes</span>
              </Fade>
            </Button>
          <Button onClick={handleLeaveNavigationClick}>LEAVE WITHOUT SAVING</Button>
        </DialogActions>
    </Dialog>
      </>
    )
};

export default compose(RouteLeavingGuard);