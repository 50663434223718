// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Paper, Divider, Button, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RefreshIcon from '@material-ui/icons/Refresh';
import CloseIcon from '@material-ui/icons/Close';
import RemoveIcon from '@material-ui/icons/Remove';
import ConfirmProceedDialog from 'Common/ConfirmProceedDialog';
import compose from './container';

const MapControls = compose(({ classes, showLayers, canUndo, imageryRequested, hasPolygon, isEstimate, ZoomInAction, ZoomOutAction, DeleteAction, UndoAction, ResetBufferStateAction }) => {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const handleDelete = () => {
    if (isEstimate) setShowConfirmDialog(true);
    else {
      DeleteAction();
      ResetBufferStateAction();
    }
  };
  return (
    <div className={classes.mapControls}>
      {hasPolygon && !showLayers && (
        <Paper elevation={1} square className={classes.buttonPanel}>
          <Button
            onClick={UndoAction}
            disabled={!canUndo || imageryRequested}
            className={classes.mapButton}
            classes={{
              label: classes.buttonLabel,
            }}
          >
            <RefreshIcon />
            <Typography variant="caption">
              Undo
            </Typography>
          </Button>
          <Divider className={classes.divider} />
          <Button
            onClick={handleDelete}
            disabled={imageryRequested}
            className={classes.mapButton}
            classes={{
              label: classes.buttonLabel,
            }}
          >
            <CloseIcon />
            <Typography variant="caption">
              Delete
            </Typography>
          </Button>
        </Paper>
      )}
      <Paper elevation={1} square>
        <Button className={classes.mapButton} onClick={ZoomInAction}>
          <AddIcon />
        </Button>
        <Divider className={classes.divider} />
        <Button className={classes.mapButton} onClick={ZoomOutAction}>
          <RemoveIcon />
        </Button>
      </Paper>

      <ConfirmProceedDialog
        title="Deleting the KML will modify your saved estimate."
        contentText="Are you sure you want to proceed?"
        showDialog={showConfirmDialog}
        cancelButtonText="CANCEL"
        okButtonText="PROCEED"
        onOk={() => {
          DeleteAction();
          ResetBufferStateAction();
          setShowConfirmDialog(false);
        }}
        onCancel={() => setShowConfirmDialog(false)}
      />
    </div>
  );
});

export default MapControls;
