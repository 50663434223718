// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  GetUserProfileAsync, UpdateUserProfileAsync, UpdatePasswordAsync, GetOrgStatsAsync, BuyAcresAction, UpdateOrgProfileAsync, GetOrgProfileAsync, GetOrgUsersAsync, DeleteOrgUserAsync, CreateOrgUserAsync, DefaultTemplateAsync, GetOrgAssignableRolesAsync, EditOrgUserAsync, GetOrgTeamsAsync, DeleteOrgTeamAsync, UpdateOrgTeamAsync, AddOrgTeamAsync, ClearOrgAccount,
} from './actions';

const initialState: IAccountState = {
  loading: false,
  userUpdating: false,
  user: null,
  updatePasswordMessage: null,
  updateUserMessage: null,
  updateCompanyMessage: null,
  orgStats: null,
  org: null,
  orgUsers: [],
  orgTeams: [],
  orgRoles: {},
  teamError: null,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(GetUserProfileAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetUserProfileAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.user = payload;
  });
  handleAction(GetUserProfileAsync.failure, (draft) => { draft.loading = false; });

  handleAction(CreateOrgUserAsync.request, (draft) => { draft.loading = true; });
  handleAction(CreateOrgUserAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.orgUsers.push(payload);
  });
  handleAction(CreateOrgUserAsync.failure, (draft) => { draft.loading = false; });

  handleAction(GetOrgUsersAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetOrgUsersAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.orgUsers = payload;
  });
  handleAction(GetOrgUsersAsync.failure, (draft) => { draft.loading = false; });

  handleAction(DeleteOrgUserAsync.request, (draft, payload) => {
    const index = draft.orgUsers.findIndex((u) => u.email === payload);
    draft.orgUsers[index].deleting = true;
  });
  handleAction(DeleteOrgUserAsync.success, (draft, payload) => {
    draft.orgUsers = draft.orgUsers.filter((u) => u.email !== payload);
  });
  handleAction(DeleteOrgUserAsync.failure, (draft, payload) => {
    const index = draft.orgUsers.findIndex((u) => u.email === payload);
    draft.orgUsers[index].deleting = false;
  });
  handleAction(EditOrgUserAsync.success, (draft, payload) => {
    const index = draft.orgUsers.findIndex((u) => u._id === payload._id);
    draft.orgUsers[index].role = payload.role;
    draft.orgUsers[index].teams = payload.teams;
  });
  handleAction(UpdateUserProfileAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.user = payload.user;
    draft.updateUserMessage = payload.message;
  });

  handleAction(GetOrgProfileAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetOrgProfileAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.org = payload;
  });
  handleAction(GetOrgProfileAsync.failure, (draft) => { draft.loading = false; });

  handleAction(UpdateOrgProfileAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.org = payload.org;
    draft.updateCompanyMessage = payload.message;
  });
  handleAction(UpdatePasswordAsync.success, (draft, payload) => { draft.updatePasswordMessage = payload; });
  handleAction(UpdatePasswordAsync.failure, (draft) => { draft.updatePasswordMessage = null; });

  handleAction(GetOrgStatsAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetOrgStatsAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.orgStats = payload;
  });

  handleAction(BuyAcresAction, (draft, payload) => {
    draft.user.availableCreditAcreage += payload;
  });

  handleAction(DefaultTemplateAsync.success, (draft, payload) => {
    draft.org = payload;
  });

  handleAction(GetOrgAssignableRolesAsync.success, (draft, payload) => {
    draft.orgRoles = payload;
  });

  handleAction(GetOrgTeamsAsync.request, (draft) => {
    draft.loading = true;
    draft.teamError = null;
  });
  handleAction(GetOrgTeamsAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.orgTeams = payload;
    draft.teamError = null;
  });
  handleAction(GetOrgTeamsAsync.failure, (draft) => {
    draft.loading = false;
    draft.teamError = 'Failed to get teams';
  });

  handleAction(DeleteOrgTeamAsync.request, (draft) => {
    draft.teamError = null;
  });
  handleAction(DeleteOrgTeamAsync.success, (draft, payload) => {
    draft.orgTeams = draft.orgTeams.filter((u) => u._id !== payload);
    draft.teamError = null;
  });
  handleAction(DeleteOrgTeamAsync.failure, (draft) => {
    draft.teamError = 'Failed to delete team';
  });

  handleAction(UpdateOrgTeamAsync.request, (draft) => {
    draft.teamError = null;
  });
  handleAction(UpdateOrgTeamAsync.success, (draft, payload) => {
    const index = draft.orgTeams.findIndex((u) => u._id === payload._id);
    draft.orgTeams[index] = payload;
    draft.teamError = null;
  });
  handleAction(UpdateOrgTeamAsync.failure, (draft) => {
    draft.teamError = 'Failed to update team';
  });

  handleAction(AddOrgTeamAsync.request, (draft) => {
    draft.teamError = null;
  });
  handleAction(AddOrgTeamAsync.success, (draft, payload) => {
    draft.orgTeams.push(payload);
    draft.teamError = null;
  });
  handleAction(AddOrgTeamAsync.failure, (draft) => {
    draft.teamError = 'Failed to add team';
  });
  handleAction(ClearOrgAccount, (draft) => {
    draft.orgUsers = [];
    draft.orgTeams = [];
    draft.orgRoles = {};
  });
});
