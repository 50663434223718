// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { InitDraw, Draw, SetSelection, SelectPolygon, SetMode, SetCurrentMeasurementOption } from 'State/map/draw/thunk';
import { styles } from './styles';

const mapStateToProps = (state: IState) => ({
  modeName: state.map.draw.present.mode?.name,
  measurementMode: state.map.draw.present.measurementMode,
  currentMeasurementOption: state.map.draw.present.currentMeasurementOption,
});

const mapDispatchToProps = {
  InitDrawAction: InitDraw,
  DrawAction: Draw,
  SetSelectionAction: SetSelection,
  SelectPolygonAction: SelectPolygon,
  SetModeAction: SetMode,
  SetCurrentMeasurementOptionAction: SetCurrentMeasurementOption,
};

type PropsType =
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    WithStyles<typeof styles>;

export default compose<PropsType, null>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
