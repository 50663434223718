// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  closeButtonWrapper: {
    position: 'absolute',
    top: 16,
    right: 50,
    zIndex: 2000,
  },
});

export default styles;
