// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getOrders } from 'State/order/selectors';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => {
  const orders = getOrders(state);
  const kmls = orders
    .projectOrders
    .filter((o) => o.boundaryFile)
    .map((o) => {
      const kml = state.kml.present.kmls[o._id];
      return {
        id: o._id,
        visible: kml && kml.visible,
        geojson: kml && kml.featureCollection,
        name: o.boundaryFile.split('/').pop(),
      };
    });
  return ({
    projectId: state.project.projectId,
    kmls,
  });
};

interface IOwnProps {
  showLayers: boolean;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

const arraysEqual = (arr1: string[] | boolean[], arr2: string[] | boolean[]) => {
  if (arr1 === arr2) return true;
  if (!arr1 || !arr2) return false;

  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false;
  }
  return true;
};

export default compose<PropsType, IOwnProps>(
  connect(
    mapStateToProps,
    null,
    null,
    {
      areStatePropsEqual: (next, prev) => next.projectId === prev.projectId
        && arraysEqual(next.kmls.map((o) => o.name), prev.kmls.map((o) => o.name))
        && arraysEqual(next.kmls.map((o) => o.visible), prev.kmls.map((o) => o.visible)),
      areStatesEqual: (next, prev) => next.project.projectId === prev.project.projectId && next.kml.present.kmls === prev.kml.present.kmls,
    },
  ),
);
