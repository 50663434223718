// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { DeleteTifFile, DeleteLasFile, SetWarningAccepted, GetEpsgProjections } from 'State/project/thunk';
import { SetDelayInRasterTileLoadAcknowledgedAction, SetLargeTifAcknowledgedAction } from 'State/map/common/actions';
import { GetRasterTiles } from 'State/map/common/thunk';
import { getProjectFiles, getProjectEPSGCode } from 'State/project/selectors';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrderIds, getOrders } from 'State/order/selectors';
import styles from './styles';

const mapStateToProps = (state: IState) => {
  const { project, fileStatusJson } = state.project;
  // Getting TIF File paths and storing names and ids in tifFileNames
  const tifFileNames = project.tifFiles.map((tifFile) => ({ name: tifFile.filename.split('/').pop(), _id: tifFile._id, epsg: tifFile.epsg }));
  // Getting LAS File paths and storing names and ids in lasFileNames
  const lasFileNames = project.lasFiles.map((lasFile) => ({ name: lasFile.filename.split('/').pop(), _id: lasFile._id, epsg: lasFile.epsg }));
  const { imageryRequest } = project;
  const epsg = getProjectEPSGCode(state);
  const projectionData = state.project.epsgProjections && state.project.epsgProjections;
  const projCSStart = projectionData && projectionData.search('PROJCS');
  const projCS = projCSStart === 0 ? projectionData.match(/"([^"]+)"/)[1] : 'Unavailable';
  const geogCSStart = projectionData && projectionData.search('GEOGCS');
  const geogCS = geogCSStart && geogCSStart !== -1 ? projectionData.substring(geogCSStart).match(/"([^"]+)"/)[1] : 'Unavailable';

  // add/remove warning dialog depending on whether project files have warnings
  let warnings = false;
  const files = getProjectFiles(state);
  const { projectOrders } = getOrders(state);
  files.forEach((file) => {
    if (fileStatusJson && fileStatusJson[file.name] && fileStatusJson[file.name].status === 'WARNING') {
      warnings = warnings || true;
    }
  });

  const userReUpFiles = (projectOrders.length === 1 && projectOrders[0].orderStatus === 0) || projectOrders.some((order) => order.orderStatus === 3);

  const warningAccepted = state.project.warningAccepted.includes(state.project.projectId);
  const { newLargeTif, largeTifAcknowledged, rasterTileJson, allRasterTilesLoaded, lowResCreatedAt, delayInRasterTileLoad, delayInRasterTileLoadAcknowledged } = state.map.common;

  const loadingHighResTiles = !!rasterTileJson && !!lowResCreatedAt && !allRasterTilesLoaded;

  return {
    fileStatuses: fileStatusJson,
    tifFiles: tifFileNames,
    lasFiles: lasFileNames,
    warningAccepted,
    warningsExist: warnings,
    userReUpFiles,
    imageryRequest,
    epsgCode: epsg?.tifEpsg || epsg?.lasEpsg,
    projCS,
    geogCS,
    projectOrderCount: projectOrders.length,
    loadingHighResTiles,
    delayInRasterTileLoad,
    delayInRasterTileLoadAcknowledged,
    newLargeTif,
    largeTifAcknowledged,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    DeleteTifAction: DeleteTifFile,
    DeleteLasAction: DeleteLasFile,
    SetWarningAcceptedAction: SetWarningAccepted,
    GetEpsgProjectionsAction: GetEpsgProjections,
    GetRasterTilesAction: GetRasterTiles,
    SetDelayInRasterTileLoadAcknowledgedAction,
    SetLargeTifAcknowledgedAction,
  }, dispatch);

type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
