// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  GetOrdersAsync, CreateOrderAsync, UpdateOrderAction, DownloadFileAsync, GetOrderStatusAction, SetOrderIdAction, SetOutputLasAction, SetFileVersionAction, DeleteOrderAsync, CreditAdjustmentAsync,
  UpdateOrderSurfaceFilesAction, DeleteSurfaceFileAsync, UpdateOrderCadFilesAction, UpdateOrderLasFilesAction, UpdateOrderStatusAsync, SetPlaceOrderSuccessAction, DownloadPostgisDxfAsync,
  SaveFileDownloadStatusAction, DeleteDxfFileAsync, EditOrderAsync, SetOrderLoadingAction, SaveGeoJsonDownloadStatusAction, SetShowAdjustmentMessageAction, DeleteOutputLasFileAsync,
} from './actions';

const initialState: IOrderState = {
  list: [],
  fileVersions: {},
  orderId: null,
  outputLas: false,
  deleting: false,
  loading: false,
  fileLoading: false,
  orderStatus: {},
  orderEstimate: [],
  placeOrderSuccess: false,
  dxfFileStatus: [],
  geoJsonFileStatus: [],
  anyChangeInFileStatuses: false,
  showAdjustmentError: false,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(SetOrderLoadingAction, (draft, payload) => { draft.loading = payload; });
  handleAction(GetOrdersAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetOrdersAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.list = payload;
  });
  handleAction(GetOrdersAsync.failure, (draft) => { draft.loading = false; });

  handleAction(SetOrderIdAction, (draft, payload) => {
    draft.orderId = payload;
  });

  handleAction(SetFileVersionAction, (draft, payload) => {
    // if (!draft.fileVersions) {
    //   draft.fileVersions = payload;
    // } else {
    //   Object.assign(draft.fileVersions, payload);
    // }
    draft.fileVersions = payload;
  });

  handleAction(SetOutputLasAction, (draft, payload) => {
    draft.outputLas = payload;
  });

  handleAction(CreateOrderAsync.request, (draft) => { draft.loading = true; });
  handleAction(CreateOrderAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.list.push(payload);
  });
  handleAction(CreateOrderAsync.failure, (draft) => { draft.loading = false; });

  handleAction(SetPlaceOrderSuccessAction, (draft, payload) => {
    draft.placeOrderSuccess = payload;
  });

  handleAction(SetShowAdjustmentMessageAction, (draft, payload) => {
    draft.showAdjustmentError = payload;
  });

  handleAction(UpdateOrderAction, (draft, payload) => {
    const order = draft.list.find((o) => o._id === payload._id);
    if (order.updatedAt <= payload.updatedAt) {
      const index = draft.list.findIndex((o) => o._id === payload._id);
      const x = draft.list[index];
      Object.assign(x, payload);
    }
  });

  handleAction(EditOrderAsync.success, (draft, payload) => {
    const index = draft.list.findIndex((o) => o._id === payload._id);
    const x = draft.list[index];
    draft.list[index] = Object.assign(x, payload);
  });

  handleAction(UpdateOrderSurfaceFilesAction, (draft, payload) => {
    const order = draft.list.find((o) => o._id === payload.ownerOrder);
    order.surfaceFiles.push(payload);
  });

  handleAction(UpdateOrderLasFilesAction, (draft, payload) => {
    const order = draft.list.find((o) => o._id === payload.ownerOrder);
    order.outputLasFiles.push(payload);
  });

  handleAction(DeleteSurfaceFileAsync.success, (draft, payload) => {
    const order = draft.list.find((o) => o._id === payload.orderId);
    order.surfaceFiles = order.surfaceFiles.filter((f) => f._id !== payload.surfaceFileId);
  });

  handleAction(DeleteDxfFileAsync.success, (draft, payload) => {
    const order = draft.list.find((o) => o._id === payload.orderId);
    order.cadFiles = order.cadFiles.filter((f) => f._id !== payload.dxfFileId);
  });

  handleAction(DeleteOutputLasFileAsync.success, (draft, payload) => {
    const order = draft.list.find((o) => o._id === payload.orderId);
    order.outputLasFiles = order.outputLasFiles.filter((f) => f._id !== payload.lasFileId);
  });

  handleAction(UpdateOrderCadFilesAction, (draft, payload) => {
    const index = draft.list.findIndex((o) => o._id === payload.ownerOrder);
    const order = draft.list[index];
    const cadfileIndex = order.cadFiles.findIndex((f) => f._id === payload._id);
    const cadFile = order.cadFiles[cadfileIndex];

    if (cadfileIndex >= 0) {
      order.cadFiles[cadfileIndex] = Object.assign(cadFile, payload);
    } else {
      order.cadFiles.push(payload);
    }
  });

  handleAction(CreditAdjustmentAsync.success, (draft, payload) => {
    const orderIndex = draft.list.findIndex((o) => o._id === payload._id);
    const x = draft.list[orderIndex];
    Object.assign(x, payload);
  });

  handleAction(GetOrderStatusAction, (draft, payload) => {
    draft.orderEstimate = payload.orderEstimate;
    draft.orderStatus = payload.orderStatus;
  });

  handleAction(DownloadFileAsync.request, (draft) => { draft.fileLoading = true; });
  handleAction(DownloadFileAsync.success, (draft) => { draft.fileLoading = false; });

  handleAction(DeleteOrderAsync.request, (draft, payload) => {
    const index = draft.list.findIndex((o) => o._id === payload);
    draft.list[index].deleting = true;
  });
  handleAction(DeleteOrderAsync.success, (draft, payload) => {
    draft.list = draft.list.filter((p) => p._id !== payload);
  });
  handleAction(DeleteOrderAsync.failure, (draft, payload) => {
    const index = draft.list.findIndex((o) => o._id === payload);
    draft.list[index].deleting = false;
  });

  handleAction(UpdateOrderStatusAsync.success, (draft, payload) => {
    const index = draft.list.findIndex((o) => o._id === payload._id);
    draft.list[index].orderStatus = payload.orderStatus;
  });

  handleAction(DownloadPostgisDxfAsync.success, (draft) => { draft.fileLoading = false; });
  handleAction(SaveFileDownloadStatusAction, (draft, payload) => {
    if (payload.fileReady === true) {
      draft.dxfFileStatus = payload.downloadStatus;
      draft.anyChangeInFileStatuses = !draft.anyChangeInFileStatuses;
    } else {
      draft.dxfFileStatus.push(payload.downloadStatus);
      draft.anyChangeInFileStatuses = true;
    }
  });
  handleAction(SaveGeoJsonDownloadStatusAction, (draft, payload) => {
    if (payload.fileReady === true) {
      draft.geoJsonFileStatus = payload.downloadGeoJsonStatus;
      draft.anyChangeInFileStatuses = !draft.anyChangeInFileStatuses;
    } else {
      draft.geoJsonFileStatus.push(payload.downloadGeoJsonStatus);
      draft.anyChangeInFileStatuses = true;
    }
  });
});
