// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  GetSubscriptionPlansAsync, GetCreditCardsAsync,
  CreatePaymentInstrumentAsync,
  UpdateDefaultCardAsync, DeleteCreditCardAsync,
  UpdateCreditCardAsync,
  SendTabValueAction,
  GetSubscriptionAsync,
  CreateSubscriptionAsync,
  GetProratedChargesAsync,
  EditSubscriptionAsync,
  CheckPromoCodeAsync,
  SetPromoCodeAction,
  ClearPromoCodeInfoAction,
} from './actions';

const initialState: ISubscriptionState = {
  plansLoading: false,
  plans: [],
  creditCardsLoading: false,
  creditCards: [],
  deleteCreditCard: false,
  defaultCreditCard: false,
  subscriptionsLoading: false,
  cadSubscription: null,
  automateSubscription: null,
  currentTab: 0,
  proratedChargesLoading: false,
  proratedCharges: { updatedCost: '', immediateCost: '' },
  promoCode: {
    valid: null,
    discountPercent: null,
    discountAmount: null,
    totalCostAfterDiscount: null,
    promotionCode: null,
    promotionCodeId: null,
  },
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(GetSubscriptionPlansAsync.request, (draft) => { draft.plansLoading = true; });
  handleAction(GetSubscriptionPlansAsync.success, (draft, payload) => {
    draft.plansLoading = false;
    draft.plans = payload;
  });
  handleAction(GetSubscriptionPlansAsync.failure, (draft) => { draft.plansLoading = false; });

  handleAction(GetCreditCardsAsync.request, (draft) => { draft.creditCardsLoading = true; });
  handleAction(GetCreditCardsAsync.success, (draft, payload) => {
    draft.creditCardsLoading = false;
    draft.creditCards = payload;
  });
  handleAction(GetCreditCardsAsync.failure, (draft) => { draft.creditCardsLoading = false; });

  handleAction(CreatePaymentInstrumentAsync.success, (draft, payload) => {
    draft.creditCards.push(payload);
  });

  handleAction(UpdateDefaultCardAsync.request, (draft) => { draft.defaultCreditCard = true; });
  handleAction(UpdateDefaultCardAsync.success, (draft, payload) => {
    draft.defaultCreditCard = false;
    draft.creditCards.forEach((c) => { c.defaultCard = (c.id === payload); });
  });
  handleAction(UpdateDefaultCardAsync.failure, (draft) => { draft.defaultCreditCard = false; });

  handleAction(DeleteCreditCardAsync.request, (draft) => { draft.deleteCreditCard = true; });
  handleAction(DeleteCreditCardAsync.success, (draft, payload) => {
    draft.deleteCreditCard = false;
    draft.creditCards = payload;
  });
  handleAction(DeleteCreditCardAsync.failure, (draft) => { draft.deleteCreditCard = false; });

  handleAction(UpdateCreditCardAsync.success, (draft, payload) => {
    const index = draft.creditCards.findIndex((c) => c.id === payload.id);
    draft.creditCards[index] = payload;
  });

  handleAction(GetSubscriptionAsync.request, (draft) => { draft.subscriptionsLoading = true; });
  handleAction(GetSubscriptionAsync.success, (draft, payload) => {
    draft.cadSubscription = payload.response.cad;
    draft.automateSubscription = payload.response.automate;
    draft.subscriptionsLoading = false;
  });
  handleAction(GetSubscriptionAsync.failure, (draft) => { draft.subscriptionsLoading = false; });

  handleAction(SendTabValueAction, (draft, payload) => {
    draft.currentTab = payload;
  });

  handleAction(CreateSubscriptionAsync.request, (draft) => { draft.subscriptionsLoading = true; });
  handleAction(CreateSubscriptionAsync.success, (draft, payload) => {
    draft.cadSubscription = payload.response.cad;
    draft.subscriptionsLoading = false;
  });
  handleAction(CreateSubscriptionAsync.failure, (draft) => { draft.proratedChargesLoading = true; });

  handleAction(GetProratedChargesAsync.request, (draft) => { draft.subscriptionsLoading = true; });
  handleAction(GetProratedChargesAsync.success, (draft, payload) => {
    draft.proratedCharges.updatedCost = payload.updatedTotalCost;
    draft.proratedCharges.immediateCost = payload.immediateCost;
    draft.promoCode.discountPercent = payload.discountPercent;
    draft.promoCode.discountAmount = payload.discountAmount;
    draft.promoCode.totalCostAfterDiscount = payload.totalCostAfterDiscount;
    draft.proratedChargesLoading = false;
  });
  handleAction(GetProratedChargesAsync.failure, (draft) => { draft.proratedChargesLoading = false; });

  handleAction(EditSubscriptionAsync.request, (draft) => { draft.subscriptionsLoading = true; });
  handleAction(EditSubscriptionAsync.success, (draft, payload) => {
    draft.cadSubscription = payload.response.cad;
    draft.subscriptionsLoading = false;
  });
  handleAction(EditSubscriptionAsync.failure, (draft) => { draft.proratedChargesLoading = true; });

  handleAction(CheckPromoCodeAsync.success, (draft, payload) => {
    if (payload.promotionCodeId) {
      draft.promoCode.valid = true;
    }
    draft.promoCode.discountPercent = payload.discountPercent;
    draft.promoCode.discountAmount = payload.discountAmount;
    draft.promoCode.totalCostAfterDiscount = payload.totalCostAfterDiscount;
    draft.promoCode.promotionCode = payload.promotionCode;
    draft.promoCode.promotionCodeId = payload.promotionCodeId;
  });

  handleAction(CheckPromoCodeAsync.failure, (draft) => {
    draft.promoCode.valid = false;
  });

  handleAction(SetPromoCodeAction, (draft, payload) => {
    draft.promoCode.promotionCode = payload;
  });

  handleAction(ClearPromoCodeInfoAction, (draft) => {
    draft.promoCode = initialState.promoCode;
  });
});
