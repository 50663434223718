// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core';

import { Login, OktaAdminLogin } from 'State/auth/thunk';

import styles from './styles';

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    LoginAction: Login,
    OktaAdminLoginAction: OktaAdminLogin,
  }, dispatch);

const mapStateToProps = (state: IState) => ({
  failedLoginAttempts: state.auth.failedLoginAttempts,
});

type PropsType =
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithStyles<typeof styles> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
