import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { GetUserProfile, UpdateUserProfile } from 'State/account/thunk';

const mapStateToProps = (state: IState) => ({
  initialValues: state.account.user,
  updateUserMessage: state.account.updateUserMessage,
  loading: state.account.loading,
  organizationAccess: 'organizationAccess' in state.auth.resources,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetUserProfileAction: GetUserProfile,
    UpdateUserProfileAction: UpdateUserProfile,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
