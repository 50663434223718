// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { API_URL } from 'Config';
import { getJson, patchJson } from 'Utils/http';
import { LoadListAsync, ToggleAsync } from './actions';

export const GetNotifications = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/notifications/me`;
    const { token } = getState().auth;

    const result = await getJson<INotification[]>(url, token);
    if (result.success) {
      dispatch(LoadListAsync.success(result.data));
    }

    return result;
  };

export const ToggleNotification = (id: string, viewed: boolean) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/notifications/${id}/${viewed}`;
    const { token } = getState().auth;

    const result = await patchJson<INotification>(url, null, token);
    if (result.success) {
      dispatch(ToggleAsync.success(result.data));
    }
  };
