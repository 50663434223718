// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getProjectFiles } from 'State/project/selectors';

const mapStateToProps = (state: IState) => {
  const files = getProjectFiles(state);
  const tileLoading = files.some((file) => file.tile3DUrl !== undefined && file.tile3DUrl?.length === 0 && !file.tileStatuses?.some((item) => item?.status === 'ERROR'));
  const tile3DError = files.some((file) => file.type === 'las' && file.tileStatuses?.some((item) => item?.status === 'ERROR'));
  return {
    tileLoading,
    tile3DError,
  };
};

export type PropsType =
ReturnType<typeof mapStateToProps>

export default compose<PropsType, {}>(
  connect(
    mapStateToProps,
    null,
    null,
    {
      areStatesEqual: (next, prev) => JSON.stringify(next.project.changeJson) === JSON.stringify(prev.project.changeJson),
    },
  ),
);
