// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import { GetAdminGeneralRoute, GetAdminProjectsRoute, GetAdminSubscriptionRoute, GetAdminUsersRoute, GetAdminOrgPlanUsageRoute, GetAdminOrderHistoryRoute } from 'Utils/routes';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const AdminToolbar = ({ orgId, activeTabId }: PropsType) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {orgId && (
        <Tabs indicatorColor="primary" value={activeTabId}>
          <Tab
            component={Link}
            label="General"
            value={1}
            to={GetAdminGeneralRoute(orgId)}
            classes={{
              wrapper: classes.tabLabel,
            }}
          />
          <Tab
            component={Link}
            label="Projects"
            value={2}
            to={GetAdminProjectsRoute(orgId)}
            classes={{
              wrapper: classes.tabLabel,
            }}
          />
          <Tab
            component={Link}
            label="Subscription"
            value={3}
            to={GetAdminSubscriptionRoute(orgId)}
            classes={{
              wrapper: classes.tabLabel,
            }}
          />
          <Tab
            component={Link}
            label="Users"
            value={4}
            to={GetAdminUsersRoute(orgId)}
            classes={{
              wrapper: classes.tabLabel,
            }}
          />
          <Tab
            component={Link}
            label="Plan Usage"
            value={5}
            to={GetAdminOrgPlanUsageRoute(orgId)}
            classes={{
              wrapper: classes.tabLabel,
            }}
          />
          <Tab
            component={Link}
            label="Order History"
            value={6}
            to={GetAdminOrderHistoryRoute(orgId)}
            classes={{
              wrapper: classes.tabLabel,
            }}
          />
        </Tabs>
      )}
    </React.Fragment>
  );
};

export default compose(AdminToolbar);
