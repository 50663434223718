// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import {
  Fade,
  CircularProgress,
  Popper,
  Paper,
  Typography,
  Button,
  ClickAwayListener,
  Checkbox,
  FormControlLabel,
  Snackbar,
  IconButton,
  Dialog, DialogActions, DialogContent,
} from '@material-ui/core';
import moment from 'moment-timezone';
import HelpIcon from '@material-ui/icons/Help';
import Alert from '@material-ui/lab/Alert';
import { WideLightTooltip } from 'Common/Tooltip';
import downloadIcon from 'Assets/download-icon.png';
import ErrorIcon from '@material-ui/icons/Error';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');

export const DownloadPanel = ({ ClearDownloadFilesAction, PrepareSurfaceFileAction, PrepareLASAction, vectorTileJson, noLayersSelected,
  anyChangeInFileStatuses, downloadFileStatuses, id, open, anchorEl, orderId, orderFile, siteId, deliveryEstimate,
  orderedGeoJson, DownloadPostgisDxfAction, DownloadLASAction, DownloadSurfaceFileAction, order, setAnchorEl, downloadGeoJsonFileStatuses,
  SetDownloadFilesStateAction, SetGeoJsonDownloadStatusAction, surfaceFile, lasFilename }: PropsType) => {
  const classes = useStyles();
  const [includeEmptyLayers, setIncludeEmptyLayers] = useState(false);
  const [areDownloadsReady, setAreDownloadsReady] = useState(false);
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(false);
  const [isGeoJsonCheckboxEnabled, setIsGeoJsonCheckboxEnabled] = useState(false);
  const [isDxfCheckboxEnabled, setIsDxfCheckboxEnabled] = useState(false);
  const [showExpressAIWarning, setShowExpressAIWarning] = useState(false);
  const [dialogSpinner, setDialogSpinner] = useState(false);
  const [showFileDownloadState, setShowFileDownloadState] = useState('');
  const index = downloadFileStatuses.findIndex((obj: any) => obj.siteId === siteId);
  const downloadFileStatusesIndex = index >= 0 && downloadFileStatuses[index];
  const geoJsonIndex = downloadGeoJsonFileStatuses.findIndex((obj: any) => obj.siteId === siteId);
  const downloadGeoJsonFileStatusesIndex = geoJsonIndex >= 0 && downloadGeoJsonFileStatuses[geoJsonIndex];
  const [fileDownloadCheckboxState, setFileDownloadCheckboxState] = useState({
    dxfFile: false,
    lasFile: false,
    surfaceFile: false,
    geoJson: false,
  });
  const [showSnackbar, setShowSnackbar] = useState(false);
  useEffect(() => {
    let areAllFilesReady = true;
    if (index < 0 && geoJsonIndex < 0) {
      setShowFileDownloadState('Prepare Download');
      return;
    }
    // dxf and geojson both requested
    if (index >= 0 && geoJsonIndex >= 0) {
      const fileStatuses = downloadFileStatusesIndex.fileReadyStatus;
      const checkboxes = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const obj in fileStatuses) {
        if (obj in fileDownloadCheckboxState) {
          checkboxes[obj] = true;
        }
      }
      const geoJsonFileStatuses = downloadGeoJsonFileStatusesIndex.fileReadyStatus;
      // eslint-disable-next-line no-restricted-syntax
      for (const obj in geoJsonFileStatuses) {
        if (obj in fileDownloadCheckboxState) {
          checkboxes[obj] = true;
        }
      }

      setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, ...checkboxes });
      // eslint-disable-next-line no-restricted-syntax
      for (const i in fileStatuses) {
        if (fileStatuses[i] === 0) {
          areAllFilesReady = false;
          break;
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const i in geoJsonFileStatuses) {
        if (geoJsonFileStatuses[i] === 0) {
          areAllFilesReady = false;
          break;
        }
      }
    }
    // only dxf download requested
    if (index >= 0 && geoJsonIndex < 0) {
      const fileStatuses = downloadFileStatusesIndex.fileReadyStatus;
      const checkboxes = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const obj in fileStatuses) {
        if (obj in fileDownloadCheckboxState) {
          checkboxes[obj] = true;
        }
      }

      setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, ...checkboxes });
      // eslint-disable-next-line no-restricted-syntax
      for (const i in fileStatuses) {
        if (fileStatuses[i] === 0) {
          areAllFilesReady = false;
          break;
        }
      }
    }
    // only geojson download requested
    if (geoJsonIndex >= 0 && index < 0) {
      setShowFileDownloadState('Preparing Download');
      const geoJsonFileStatuses = downloadGeoJsonFileStatusesIndex.fileReadyStatus;
      const checkboxes = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const obj in geoJsonFileStatuses) {
        if (obj in fileDownloadCheckboxState) {
          checkboxes[obj] = true;
        }
      }
      setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, ...checkboxes });
      // eslint-disable-next-line no-restricted-syntax
      for (const i in geoJsonFileStatuses) {
        if (geoJsonFileStatuses[i] === 0) {
          areAllFilesReady = false;
          break;
        }
      }
    }
    if (!areAllFilesReady) {
      setAreDownloadsReady(false);
      setIsCheckboxEnabled(true);
      // The checkbox for selecting dxf on the download panel is enabled only when atleast one layer is selected
      // eslint-disable-next-line no-unused-expressions
      !noLayersSelected && setIsDxfCheckboxEnabled(true);
      setShowFileDownloadState('Preparing Download');
    } else {
      setAreDownloadsReady(true);
      setShowFileDownloadState('Download');
      setShowSnackbar(true);
    }
  }, [downloadFileStatuses, downloadGeoJsonFileStatuses, anyChangeInFileStatuses]);

  useEffect(() => {
    if (noLayersSelected) {
      // If no layers are selected and the download panel is opened, uncheck the dxf checkbox and disable it
      setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, dxfFile: false });
      setIsDxfCheckboxEnabled(true);
    } else {
      // Checkbox enabled when atleast one layer is selected
      setIsDxfCheckboxEnabled(false);
    }
  }, [noLayersSelected]);

  const cadPresent = order.cadFiles[0] !== null;
  const cadFile = order.cadFiles.find((file) => file._id === siteId);

  const PrepareOutputFiles = async () => {
    // check what are the files requested for dxf and las
    setIsCheckboxEnabled(true);
    setIsDxfCheckboxEnabled(true);
    setIsGeoJsonCheckboxEnabled(true);
    if (fileDownloadCheckboxState.dxfFile && fileDownloadCheckboxState.geoJson) {
      await SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState);
      await SetGeoJsonDownloadStatusAction(siteId, fileDownloadCheckboxState);
      await DownloadPostgisDxfAction(orderId, siteId, includeEmptyLayers, fileDownloadCheckboxState);
    }
    if (fileDownloadCheckboxState.dxfFile && !fileDownloadCheckboxState.geoJson) {
      // eslint-disable-next-line no-unused-expressions
      if (cadFile.expressAIUpload && cadFile.expressAIEmailSent) {
        setShowExpressAIWarning(true);
      } else {
        await SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState);
        await DownloadPostgisDxfAction(orderId, siteId, includeEmptyLayers, fileDownloadCheckboxState);
      }
    }

    if (fileDownloadCheckboxState.geoJson && !fileDownloadCheckboxState.dxfFile) {
      await SetGeoJsonDownloadStatusAction(siteId, fileDownloadCheckboxState);
      await DownloadPostgisDxfAction(orderId, siteId, includeEmptyLayers, fileDownloadCheckboxState);
    }

    if (fileDownloadCheckboxState.lasFile) {
      await SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState);
      await PrepareLASAction(siteId);
    }
    if (fileDownloadCheckboxState.surfaceFile) {
      await SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState);
      await PrepareSurfaceFileAction(siteId);
    }
  };

  const confirmDxfExport = async () => {
    setDialogSpinner(true);
    await SetDownloadFilesStateAction(siteId, fileDownloadCheckboxState);
    await DownloadPostgisDxfAction(orderId, siteId, includeEmptyLayers);
    setDialogSpinner(false);
    setShowExpressAIWarning(false);
  };

  const DownloadOutputFiles = async () => {
    const status = [];
    if (fileDownloadCheckboxState.dxfFile && fileDownloadCheckboxState.geoJson) {
      const filesReadyToDownload = [];
      if (index >= 0 && downloadFileStatusesIndex.url) {
        filesReadyToDownload.push(downloadFileStatusesIndex.url);
      }

      if (geoJsonIndex >= 0 && downloadGeoJsonFileStatusesIndex.url) {
        filesReadyToDownload.push(downloadGeoJsonFileStatusesIndex.url);
      }

      const interval = 3000;
      filesReadyToDownload.forEach((file, i) => {
        setTimeout(async () => {
          window.open(file, '_self');
        }, i * interval);
      });

      const pendoEventName = 'Download DXF and GeoJson';
      status.push('Y');
      window?.pendo?.track(pendoEventName, {
        filename: orderFile,
        filesize: `${(downloadFileStatusesIndex.size / 1000000).toFixed(2)} MB`,
      });
    }
    if (fileDownloadCheckboxState.dxfFile && !fileDownloadCheckboxState.geoJson) {
      if (index >= 0 && downloadFileStatusesIndex.url) {
        window.open(downloadFileStatusesIndex.url, '_self');
      }

      let pendoEventName = '';
      const expressAIOutput = orderFile.includes('expressAI');
      if (expressAIOutput) {
        pendoEventName = 'Express AI exported';
      } else {
        pendoEventName = 'Download DXF';
      }
      const layerList = vectorTileJson.vector_layers.map((layer) => layer.id);
      status.push('Y');
      window?.pendo?.track(pendoEventName, {
        filename: orderFile,
        filesize: `${(downloadFileStatusesIndex.size / 1000000).toFixed(2)} MB`,
        ...(expressAIOutput && { layers: layerList.join(', ') }),
      });
    }
    if (fileDownloadCheckboxState.geoJson && !fileDownloadCheckboxState.dxfFile) {
      if (geoJsonIndex >= 0 && downloadGeoJsonFileStatusesIndex.url) {
        window.open(downloadGeoJsonFileStatusesIndex.url, '_self');
      }
      const pendoEventName = 'Download GeoJson';
      status.push('Y');
      window?.pendo?.track(pendoEventName, {
        filename: orderFile,
        filesize: `${(downloadGeoJsonFileStatusesIndex.size / 1000000).toFixed(2)} MB`,
      });
    }
    if (fileDownloadCheckboxState.lasFile) {
      const res = await DownloadLASAction(orderId, lasFilename);
      if (res.success) {
        status.push('Y');
      }
    }
    if (fileDownloadCheckboxState.surfaceFile) {
      const res = await DownloadSurfaceFileAction(surfaceFile._id, orderId, siteId);
      if (res.success === true) {
        status.push('Y');
      }
    }
    if (status.every((val, i, arr) => val === arr[0])) {
      await ClearDownloadFilesAction(siteId);
      setIsCheckboxEnabled(false);
      setIsDxfCheckboxEnabled(true);
      setIsGeoJsonCheckboxEnabled(false);
      setAreDownloadsReady(false);
      setShowFileDownloadState('Prepare Download');
    }
  };

  const ButtonFunction = () => {
    // eslint-disable-next-line no-unused-expressions
    areDownloadsReady ? DownloadOutputFiles() : PrepareOutputFiles();
  };
  // Download files based on which of the files are ready to be downloaded
  const handleClickAway = () => {
    setAnchorEl(false);
  };

  const handleCheckboxChange = (event: any) => {
    setFileDownloadCheckboxState({ ...fileDownloadCheckboxState, [event.target.name]: event.target.checked });
    // handleDownLoadButtonChange();
  };

  const handleIncludeEmptyLayersCheckboxChange = () => {
    setIncludeEmptyLayers(!includeEmptyLayers);
  };

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };
  const prepareDownloadButton = !((fileDownloadCheckboxState.dxfFile) || (fileDownloadCheckboxState.geoJson) || (fileDownloadCheckboxState.lasFile) || (fileDownloadCheckboxState.surfaceFile));
  return (
    <>
      <Snackbar className={classes.snackBar} open={showSnackbar} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}>
        <Alert severity="success" className={classes.alert} color="info"> Your file(s) are ready to download</Alert>
      </Snackbar>
      <Popper id={id} open={open} anchorEl={anchorEl} transition placement="right-start" className={classes.popper}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleClickAway}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.downloadPanel}>
                <div className={classes.header}>
                  <Typography variant="h4">Export</Typography>
                  <WideLightTooltip
                    placement="top"
                    interactive
                    title={(
                      <div>
                        Please review
                        {' '}
                        <a className={classes.link} href="http://support.airworks.io/Workaround-for-exported-DXFs-in-AutoCAD.2406023169.html" target="_blank" rel="noreferrer">these steps</a>
                        {' '}
                        on importing an AirWorks dxf into AutoCAD.
                        {orderedGeoJson && (
                          <div>
                            {' '}
                            <br />
                            {' '}
                            Your .geojson file will download in the WGS84 coordinate system.
                            {' '}
                          </div>
                        )}
                      </div>
                    )}
                  >
                    <HelpIcon className={classes.icon} />
                  </WideLightTooltip>
                </div>
                {cadPresent && (
                  <div>
                    <FormControlLabel
                      control={(
                        <Checkbox color="primary" checked={fileDownloadCheckboxState.dxfFile} name="dxfFile" onChange={handleCheckboxChange} disabled={isDxfCheckboxEnabled} />
                      )}
                      label={orderFile}
                    />
                    <FormControlLabel
                      control={(
                        <Checkbox className={classes.smallCheckbox} color="primary" checked={includeEmptyLayers} name="emptyLayers" onChange={handleIncludeEmptyLayersCheckboxChange} disabled={isDxfCheckboxEnabled} />
                      )}
                      label="Empty Layers"
                      classes={{
                        root: classes.emptyLayers,
                        label: classes.emptyLayersLabel,
                      }}
                    />
                    {/* check if the user ordered geojson for this order and this is not an express AI dxf */}
                    { orderedGeoJson && !cadFile.expressAIUpload && (
                      <FormControlLabel
                        control={(
                          <Checkbox color="primary" checked={fileDownloadCheckboxState.geoJson} name="geoJson" onChange={handleCheckboxChange} disabled={isGeoJsonCheckboxEnabled} />
                        )}
                        label={`${orderFile.replace('.dxf', '.geojson')}`}
                      />
                    )}
                  </div>
                )}
                <br />
                {lasFilename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.lasFile} name="lasFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={lasFilename}
                  />
                )}
                <br />
                {surfaceFile.filename && (
                  <FormControlLabel
                    control={(
                      <Checkbox color="primary" checked={fileDownloadCheckboxState.surfaceFile} name="surfaceFile" onChange={handleCheckboxChange} disabled={isCheckboxEnabled} />
                    )}
                    label={surfaceFile.filename}
                  />
                )}
                <Button
                  disabled={prepareDownloadButton}
                  onClick={ButtonFunction}
                  className={classes.downloadButton}
                  color="primary"
                >
                  {
                    (showFileDownloadState === 'Preparing Download') &&
                  (
                    <CircularProgress size={16} className={classes.buttonProgress} color="primary" />
                  )
                  }
                  {(showFileDownloadState === 'Prepare Download' || showFileDownloadState === 'Download') &&
                (
                  <IconButton
                    aria-label="Download"
                    title="Download"
                    color="primary"
                  >
                    <img src={downloadIcon} alt="Download" />
                  </IconButton>
                )}
                  {showFileDownloadState}
                </Button>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <Dialog
        open={showExpressAIWarning}
        onClose={() => { setShowExpressAIWarning(false); }}
        className={classes.dialog}
      >
        <div className={classes.dialogContent}>
          <div className={classes.warningTitle}>
            <ErrorIcon className={classes.warningIcon} />
            <Typography variant="h3">Export Layer</Typography>
          </div>
          <DialogContent>
            <Typography display="inline">
              This layer was generated by the
              {' '}
              <strong>AirWorks Autonomous Pipeline</strong>
              {' '}
              and has not yet had its quality assessed. Your final output will be ready by
              {' '}
            </Typography>
            <Typography className={classes.deliveryDate} display="inline">
              {moment(deliveryEstimate).format('LL')}
              .
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { setShowExpressAIWarning(false); }}>Close</Button>
            {/* <Button className={classes.okButton} onClick={confirmDxfExport} >Export Anyway</Button> */}
            <Button
              variant="text"
              color="primary"
              onClick={confirmDxfExport}
            >
              <Fade in={dialogSpinner}>
                <CircularProgress size={16} className={classes.expressButtonProgress} />
              </Fade>
              <Fade in={!dialogSpinner}>
                <span>Export Anyway</span>
              </Fade>
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </>
  );
};

export default compose(DownloadPanel);
