// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  sortButton: {
    padding: '3px 10px',
  },
  pageToolbar: {
    position: 'fixed',
    height: '90px',
    width: '100%',
    // top: '200px',
    zIndex: '10',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(4),
    backgroundColor: '#fafafa',
  },
  pageToolbarMapview: {
    position: 'fixed',
    height: '90px',
    width: '40%',
    top: '175px',
    zIndex: '10',
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    paddingTop: theme.spacing(3),
    backgroundColor: '#fafafa',
  },
  pageToolbarContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto auto auto',
    width: '1200px',
    margin: '0 auto',
    // justifyContent : 'space-between'
  },
  pageToolbarContainerMapview: {
    paddingTop: '15px',
    display: 'grid',
    gridTemplateColumns: '1fr auto auto auto auto',
  },
  fontWeight: {
    fontWeight: 300,
    fontSize: '15px',
    textTransform: 'uppercase',
  },
  icons: {
    flexGrow: 0,
  },
  controlsWrapper: {
    display: 'flex',
  },
  markerIcon: {
    height: theme.spacing(3),
  },
}));

export default useStyles;
