// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import {Paper, Button } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddTemplateDialog from 'Common/AddTemplateDialog';
import Sidebar from 'Common/Sidebar';
import TemplateItems from '../templateItems';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders Sidebar
 *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {array} props.list List of all cad templates
 * @param {string} props.templateId Props - template id
 */
export const TemplateSidebar = ({ list, templateId }: PropsType) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);

  return (
    <Sidebar>
      <div className={classes.sidebarContent}>
        {list.map((template, index) => <TemplateItems template={template} index={index} templateId = {templateId} />)}
      </div>
      <Paper className={classes.createTemplateButtonWrapper}>
        <Button onClick={()=>{setShowDialog(true)}} className={classes.addTemplateButton}>
            <AddCircleIcon className={classes.addIcon} /> 
          New Template
        </Button>
      </Paper>
      <AddTemplateDialog 
        showDialog={showDialog}
        setShowDialog={setShowDialog}
      />
    </Sidebar>
  );
};



export default compose(TemplateSidebar);

