// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import cardValidator from 'card-validator';
import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';
import isStrongPassword from 'validator/lib/isStrongPassword';

const required = (value: string) => (value ? undefined : 'Required');
const email = (value: string) => !isEmail(value) && 'Invalid email address';
const mobilePhone = (value: string) => (value ? (!isMobilePhone(value) && 'Invalid phone number') : undefined);
const minLength = (min: number) => (value: string) =>
  (value && value.length < min ? `Must be at least ${min} characters long` : undefined);
const strongPassword = (value: string) => (value ? (!isStrongPassword(value) && 'Password is not strong enough') : undefined);
const minLength7 = minLength(7);
const maxLength = (max: number) => (value: string) =>
  (value && value.length > max ? `Maximum of ${max} characters long` : undefined);
const minValue = (min: number) => (value: string) =>
  (parseInt(value, 10) < min ? 'Required' : undefined);
const match = (matchName: string) => (value: string, allValues: any) =>
  (value !== allValues[matchName]
    ? `This field must match with ${matchName} field`
    : undefined);
const passwordsMatch = (value: string, allValues: any) =>
  (value !== allValues.password ? 'Passwords don\'t match' : undefined);

const creditCard = (number: string) => {
  const supportedCreditCards = ['visa', 'mastercard', 'american-express', 'diners-club', 'discover'];
  const result = cardValidator.number(number);

  return (result.isValid && supportedCreditCards.includes(result.card.type))
    ? undefined
    : 'Invalid card number';
};

const expireDate = (date: string) => {
  const result = cardValidator.expirationDate(date);

  return result.isValid
    ? undefined
    : 'Invalid date';
};

const composeValidators = (...validators: any[]) => (value: any, allValues: any, fieldState: any) =>
  validators.reduce((error, validator) => error || validator(value, allValues, fieldState), undefined);

export default {
  required,
  email,
  mobilePhone,
  minLength,
  strongPassword,
  minLength7,
  maxLength,
  match,
  passwordsMatch,
  minValue,
  creditCard,
  expireDate,
  composeValidators,
};
