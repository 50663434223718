// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  contentWrapperGrid: {
    position: 'relative',
    flex: 1,
  },
  contentWrapperMap: {
    position: 'relative',
    minWidth: '100%',
  },
  gridList: {
    paddingBottom: theme.spacing(3),
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: 30,
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(5),
  },
  mapList: {
    width: '80%',
    margin: 'auto',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    top: '95px',
    zIndex: 1,
    height: '100vh',
  },
  mapListWrapper: {
    margin: 0,
  },
  gridListWrapper: {
    width: '1200px',
    margin: '100px auto',
  },
  dashboardContent: {
    display: 'flex',
    flex: '1 1 auto',
    position: 'relative',
    minHeight: 0,
    flexDirection: 'row',
  },
  mapviewLayoutLeft: {
    flexGrow: 1,
    width: '50%',
    flex: 1,
    overflow: 'auto',
    margin: 0,
    position: 'relative',
    flexDirection: 'column',
  },
  mapviewLayoutRight: {
    flex: 1,
    flexGrow: 1.5,
    position: 'relative',
  },
  gridItemWrapper: {
    overflow: 'inherit',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
  },
  noProjects: {
    position: 'absolute',
    top: '250px',
    width: '100%',
    textAlign: 'center',
  },
});

export default styles;
