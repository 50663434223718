// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { FormControlLabel, Checkbox, Select, MenuItem, InputBase, Typography } from '@material-ui/core';
import compose, { PropsType } from './container';
import useStyles from './styles';

const CadLayers = ({ projectId, orderId, cadFiles, order3DCadFilesList, cad3dLayers, toggleLayer, SetLayer3DChangeAction, SetSelectedCadFile3DLayersAction, Get3DLineworkAction }: PropsType) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [currentfile, setCurrentFile] = useState(null);
  const [currentFile3DTileInfo, setCurrentFile3DTileInfo] = useState([]);

  useEffect(() => {
    const currentOrderCadFileIds = order3DCadFilesList[orderId];
    const fileList = currentOrderCadFileIds?.map((cadFileId: string) => {
      const cadFile = cadFiles.find((file) => file._id === cadFileId);
      const { _id, filename } = cadFile;
      const fileName = (filename?.split('/').pop() || 'Original file');
      return ({ fileName, _id });
    });
    setFiles(fileList);
    setCurrentFile(fileList[fileList.length - 1]);
  }, []);

  useEffect(() => {
    if (currentfile) {
      setCurrentFile3DTileInfo(cad3dLayers[currentfile._id]?.layers);
    }
  }, [currentfile, cad3dLayers]);

  const onFileChange = async (e: any) => {
    const siteId = e.target.value;
    const file = files.find((f) => f._id === siteId);
    const currentSiteId = currentfile._id;
    setCurrentFile(file);

    SetSelectedCadFile3DLayersAction({
      orderId,
      cadFile3DLayers: {
        siteId,
        visible: true,
      },
    });
    const site = cad3dLayers[siteId];
    if (site) {
      const { dataAvailable, layers } = site;
      const layerIds = layers.map((l) => l.layer_id);
      if (!dataAvailable) {
        await Get3DLineworkAction([{ projectId, siteId, layers: layerIds }]);
      }
      const layerChange: (string|boolean)[] = [];
      cad3dLayers[currentSiteId]?.layers.forEach((layer) => {
        layerChange.push(`${currentSiteId}-${layer.layer_id}`, false && layer.visible);
      });
      cad3dLayers[siteId]?.layers.forEach((layer) => {
        layerChange.push(`${siteId}-${layer.layer_id}`, true && layer.visible);
      });
      SetLayer3DChangeAction(layerChange);
    }
  };

  return (
    <div className={classes.layerList}>
      <Select
        input={<InputBase />}
        value={currentfile?._id || ''}
        renderValue={() => <>{currentfile?.fileName}</>}
        onChange={(e) => onFileChange(e)}
        className={classes.select}
      >
        {files?.map((file) => (
          <MenuItem value={file?._id} key={file?._id}>{file?.fileName}</MenuItem>
        ))}
      </Select>
      <div className={classes.listWrapper}>
        {currentFile3DTileInfo?.map((layer: any) => (
          <div className={classes.layerListItem} key={layer.layer_id}>
            <div className={classes.checkboxWrapper}>
              <FormControlLabel
                label={<Typography variant="h5" className={classes.sidebarSubheader}>{layer.name}</Typography>}
                control={(
                  <Checkbox
                    color="primary"
                    onChange={(e) => toggleLayer(e.target.checked, currentfile?._id, layer.layer_id)}
                    checked={layer.visible}
                    classes={{
                      root: classes.checkbox,
                    }}
                  />
                )}
              />
            </div>
            <div
              className={classes.layerColor}
              style={{ backgroundColor: (layer.color) }}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default compose(CadLayers);
