// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';

import { GetOrgStats } from 'State/account/thunk';
import { currentSubscription } from 'State/subscription/selectors';

import component from './component';
import styles from './styles';

const mapStateToProps = (state) => {
  const subscription = currentSubscription(state);

  return {
    orgStats: state.account.orgStats,
    loading: state.account.loading,
    subscriptionType: subscription.subscriptionType,
  };
};

const mapDispatchToProps = {
  GetOrgStatsAction: GetOrgStats,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
)(component);
