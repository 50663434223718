// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getOrders } from 'State/order/selectors';
import { UpdateProjectName } from 'State/project/thunk';

const mapStateToProps = (state: IState) => {
  const { projectOrders } = getOrders(state);
  // Check if any of the orders in the project have at least started processing

  return {
    projectOrders,
    ordersLoaded: projectOrders.length > 0,
  };
};

const mapDispatchToProps = {
  UpdateProjectNameAction: UpdateProjectName,
};

interface IOwnProps {
    initialProjectName: string;
    projectId: string;
}

export type PropsType =
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
