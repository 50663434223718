// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Box, MenuItem, Select, TableRow, TableCell, InputBase, Chip, Checkbox, ClickAwayListener } from '@material-ui/core';
import { ACI } from 'Utils/autoCadIndexes';
import { useInView } from 'react-intersection-observer';
import { lineStyles, lineWeight } from 'Utils/lineStyles';
import Tooltip from 'Common/Tooltip';
import { airworksLayerStandards } from 'Utils/airworksLayers';
import { InputBaseWidth } from 'Utils/themes';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { CompactPicker } from 'react-color';
import classnames from 'classnames';
import compose, { PropsType } from './container';
import useStyles from './styles';

const ITEM_HEIGHT = 70;
const toolTipText = 'This template is not editable. Duplicate to make edits on a copy.';

export const LayerItem = ({ layer, template, templateId, setLayersStyle, setLayersWeight, setLayersColor, setLayersMapping, SetSelectedStyleAction,
  SetSelectedWeightAction, SetSelectedColorAction, SetSelectedMappingAction }: PropsType) => {
  const classes = useStyles();

  const [openPicker, setOpenPicker] = useState({});

  const { ref, inView } = useInView({
    threshold: 0,
  });

  const openColorPicker = (layerId: string, airworksDefault : boolean) => {
    const obj: {[key: string]: boolean} = {};
    obj[layerId] = true;
    if (!airworksDefault) {
      setOpenPicker(obj);
    }
  };

  const closeColorPicker = () => {
    setOpenPicker({});
  };

  const handleChangeStyle = async (layerId: string, e: any) => {
    setSelectedStyle(layerId, e);
    SetSelectedStyleAction({ templateId, layerId, event: e.target.value });
  };

  const handleChangeWeight = async (layerId: string, e: any) => {
    setSelectedWeight(layerId, e);
    SetSelectedWeightAction({ templateId, layerId, event: e.target.value });
  };

  const handleChangeMapping = async (layerId: string, e: any) => {
    setSelectedMapping(layerId, e);
    SetSelectedMappingAction({ templateId, layerId, event: e.target.value });
  };

  const handleChangeComplete = async (layerId: string, color: any) => {
    setSelectedColor(layerId, color.hex.toUpperCase());
    SetSelectedColorAction({ templateId, layerId, color: color.hex.toUpperCase() });
    setOpenPicker({});
  };

  const createLayersStyleArray = (_id: string, value: string) => {
    setLayersStyle((layers: ILayers[]) => [...layers, { _id, style: value }]);
  };

  const createLayersWeightArray = (_id: string, value: string) => {
    setLayersWeight((layers: ILayers[]) => [...layers, { _id, weight: value }]);
  };

  const createLayersColorArray = (_id: string, value: string) => {
    setLayersColor((layers: ILayers[]) => [...layers, { _id, color: value }]);
  };

  const createLayersMappingArray = (_id: string, value: string) => {
    setLayersMapping((layers: ILayers[]) => [...layers, { _id, mapping: value }]);
  };

  const setSelectedStyle = (_id: string, event: any) => createLayersStyleArray(_id, event.target.value);
  const setSelectedWeight = (_id: string, event: any) => createLayersWeightArray(_id, event.target.value);
  const setSelectedColor = (_id: string, color: string) => createLayersColorArray(_id, color);
  const setSelectedMapping = (_id: string, event: any) => createLayersMappingArray(_id, event.target.value);

  const getLineHeight = (weight: number) => {
    if (weight < 10) return `0.0${weight}mm`;
    if (weight < 100) return `0.${weight}mm`;
    return '1mm';
  };

  return (
    <TableRow className={classes.row} ref={ref}>
      {inView ?
        (
          <>
            <Tooltip title={template.airworksTemplate ? toolTipText : ''} placement="top">
              <Box className={classes.borderStyle}>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  {layer.name}
                </TableCell>
              </Box>
            </Tooltip>
            <Tooltip title={template.airworksTemplate ? toolTipText : ''} placement="top">
              <Box className={classes.borderLineStyle}>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  <Select
                    input={<InputBase className={classes.input} />}
                    classes={{
                      root: classes.select,
                    }}
                    value={layer.style}
                    disabled={template.airworksTemplate}
                    disableUnderline
                    variant="outlined"
                    onChange={(e) => handleChangeStyle(layer._id, e)}
                  >
                    {lineStyles.map((line) => (
                      <MenuItem value={line.style} key={line.style}>
                        <img src={line.img} className={classes.lineStylePNG} alt="lineStyle" />
                        {' '}
                        {line.style}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </Box>
            </Tooltip>
            <Tooltip title={template.airworksTemplate ? toolTipText : ''} placement="top">
              <Box className={classes.borderStyle}>
                <TableCell className={classes.tableCell} component="th" scope="row">
                  <Select
                    classes={{
                      root: classes.selectWeight,
                    }}
                    input={<InputBase className={classes.input} />}
                    value={layer.weight}
                    disabled={template.airworksTemplate}
                    disableUnderline
                    onChange={(e) => handleChangeWeight(layer._id, e)}
                  >
                    {lineWeight.map((weight) => (
                      <MenuItem value={weight} key={weight}>
                        <hr
                          className={classes.horizontalLine}
                          style={{
                            height: getLineHeight(weight),
                          }}
                        />
                        {weight}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              </Box>
            </Tooltip>
            <ClickAwayListener onClickAway={closeColorPicker}>
              <Tooltip title={template.airworksTemplate ? toolTipText : ''} placement="top">
                <Box className={classnames(classes.borderStyle, classes.colorBox)} onClick={() => openColorPicker(layer._id, template.airworksTemplate)}>
                  <TableCell className={classes.tableCell} component="th" scope="row">
                    <div
                      className={classes.layerColor}
                      style={{ backgroundColor: layer.color }}
                    />
                    {ACI.getByHEX(layer.color)}
                    <div className={classes.compactPicker}>
                      {openPicker[layer._id] && (
                        <CompactPicker
                          position="below"
                          defaultValue={layer.color}
                          colors={ACI.list}
                          onChangeComplete={(color: string) => handleChangeComplete(layer._id, color)}
                        />
                      )}
                    </div>
                  </TableCell>
                </Box>
              </Tooltip>
            </ClickAwayListener>
            <Tooltip title={template.airworksTemplate ? toolTipText : ''} placement="top">
              <Box className={classnames(classes.borderStyle, classes.mappingBox)}>
                <TableCell className={classes.tableCellLayers} scope="row">
                  <MuiThemeProvider theme={InputBaseWidth}>
                    <Select
                      classes={{
                        root: classes.selectLayers,
                      }}
                      multiple
                      IconComponent={() => <div />}
                      value={layer.mapping}
                      disableUnderline
                      disabled={template.airworksTemplate}
                      onChange={(e) => handleChangeMapping(layer._id, e)}
                      renderValue={() => (
                        <div className={classes.chips}>
                          {layer.mapping.map((selected: string) => (
                            <Chip
                              size="medium"
                              classes={{ root: classes.chipRoot }}
                              onMouseDown={(event) => {
                                event.stopPropagation();
                              }}
                              key={selected}
                              label={selected}
                              className={classes.chip}
                            />
                          ))}
                          {layer.mapping.length > 1 && (
                            <div className={classes.badge}>
                              +
                              {layer.mapping.length - 1}
                            </div>
                          )}
                        </div>
                      )}
                      MenuProps={{
                        elevation: 0,
                        getContentAnchorEl: null,
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'center',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'center',
                        },
                        style: {
                          maxHeight: ITEM_HEIGHT * 4.5,
                          width: 280,
                        },
                      }}
                    >
                      {airworksLayerStandards.map((layers) => (
                        <MenuItem
                          classes={{ root: classes.selectMapping, selected: classes.selected }}
                          key={layers}
                          value={layers}
                        >
                          <Checkbox classes={{ checked: classes.checked }} checked={layer.mapping.indexOf(layers) > -1} />
                          {layers}
                        </MenuItem>
                      ))}
                    </Select>
                  </MuiThemeProvider>
                </TableCell>
              </Box>
            </Tooltip>
          </>
        ) : null }
    </TableRow>
  );
};

export default compose(LayerItem);
