// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  addons: {
    marginTop: '25px',
  },
  addonsHeading: {
    marginBottom: '16px',
    marginTop: '16px',
    textTransform: 'capitalize',
  },
  unavailableDeliverables: {
    pointerEvents: 'none',

    '& h3': {
      color: 'lightgray',
    },
    '& h4': {
      color: 'lightgray',
    },
    '& p': {
      color: 'lightgray',
    },
    '& span': {
      color: 'lightgray',
    },
  },
  noDeliverableMessage: {
    marginBottom: '8px',
    marginTop: '8px',
    fontSize: 'small',
    fontWeight: 400,
    color: 'lightgray',
  },
  checked: {
    color: '#01A1DD !important',
  },
  listItem: {
    padding: '0px',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: theme.spacing(1),
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  itemName: {
    fontWeight: 400,
    fontSize: '13px',
  },
  costPerAcre: {
    fontSize: '13px',
  },
  right: {
    fontWeight: 400,
    fontSize: '13px',
    position: 'absolute',
    right: 0,
  },
  formControl: {
    marginRight: theme.spacing(1),
  },
  icon: {
    color: '#01A1DD',
    fontSize: '18px',
  },
});

export default styles;
