// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { ToggleBasemapLayer } from 'State/map/common/thunk';

const mapStateToProps = (state: IState) => ({
  layers: state.map.common.layers,
});

const mapDispatchToProps = {
  ToggleBasemapLayerAction: ToggleBasemapLayer,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
