// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { ToggleNotification } from 'State/notification/thunk';

const mapStateToProps = (state: IState, pr: RouteComponentProps) => ({
  list: state.notification.list,
  newNotificationsCount: state.notification.list.filter((n) => !n.viewed).length,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    ToggleNotificationAction: ToggleNotification,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
