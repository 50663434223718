// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  contentCad: {
    display: 'grid',
    gridRowGap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subscriptionDate: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '20px',
  },
  dateAndButtons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentAutomate: {
    display: 'grid',
    gridTemplateColumns: 'auto auto',
    gridRowGap: theme.spacing(2),
    justifyContent: 'space-between',
    alignItems: 'center',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
      fontWeight: 400,
      textTransform: 'uppercase',
    },
  },
  buttons: {
    zIndex: 2000,
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
}));

export default useStyles;
