// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import {
  Fade,
  CircularProgress,
  TextField,
  Typography,
  Button,
  Dialog, DialogActions, DialogContent,
} from '@material-ui/core';
import { mininumBufferSize } from 'Utils/constants';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const KmlBufferDialog = ({ lineStringKmlUpload, bufferSize, creatingBuffer, SetBufferSizeAction, SetLineStringUploadAction, GenerateKmlBufferAction }: PropsType) => {
  const classes = useStyles();
  const [buffer, setBuffer] = useState(bufferSize);

  useEffect(() => {
    setBuffer(Math.max(bufferSize, mininumBufferSize));
  }, [bufferSize]);

  const onBufferSubmit = () => {
    let finalBufferSize = mininumBufferSize;
    if (!Number.isNaN(buffer)) {
      finalBufferSize = Math.max(buffer, mininumBufferSize);
    }
    window?.pendo?.track('KML buffer created', { buffer_distance: finalBufferSize });

    setBuffer(finalBufferSize);
    SetBufferSizeAction(finalBufferSize);
    GenerateKmlBufferAction();
  };

  return (
    <Dialog
      open={lineStringKmlUpload}
      onClose={() => { SetLineStringUploadAction(false); }}
      className={classes.dialog}
    >
      <div className={classes.dialogContent}>
        <Typography className={classes.dialogHeader} variant="h5">Buffer Distance</Typography>
        <DialogContent className={classes.dialogText}>
          <Typography>{`Please add below the buffer distance for your Right of Way. Please note AirWorks requires a minimum buffer distance of ${mininumBufferSize} feet.`}</Typography>
          <div className={classes.textForm}>
            <TextField
              id="buffer"
              label="Buffer Distance"
              variant="outlined"
              type="number"
              onChange={(e) => { setBuffer(parseFloat(e.target.value)); }}
              inputProps={{ min: mininumBufferSize }}
              value={buffer.toString()}
              className={classes.textField}
            />
            <div>
              <Typography variant="h5">ft</Typography>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={() => { SetLineStringUploadAction(false); }}>Close</Button>
          <Button
            variant="contained"
            color="primary"
            onClick={onBufferSubmit}
          >
            <Fade in={creatingBuffer}>
              <CircularProgress size={16} className={classes.expressButtonProgress} />
            </Fade>
            <Fade in={!creatingBuffer}>
              <span>Continue</span>
            </Fade>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default compose(KmlBufferDialog);
