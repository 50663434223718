// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import compose from './container';
import { Typography, TextField, CircularProgress } from '@material-ui/core/';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { airworksLayers } from 'Utils/airworksLayers';

const Matching = compose(({classes, currentTemplate, setCorrectMatching, SetTemplateIdAction}) => {
    interface IReverseMapping {
        [key: string]: string
    }

    const [rm, setRm] = React.useState<IReverseMapping>({})
    const [userLayers, setUserLayers] = React.useState([]);

    let _rm: IReverseMapping = {}

    React.useEffect(() => {
        currentTemplate && SetTemplateIdAction(currentTemplate._id);
        currentTemplate && currentTemplate.layers.forEach((l : any) => { 
            l.mapping.forEach((m: string) => {
                if(airworksLayers.includes(m)) {
                    _rm[m] = l.name
                }
            })
            setUserLayers(userLayers => [...userLayers, l.name]);
        })
        setRm(_rm)
        setCorrectMatching(_rm)
    }, [currentTemplate])

    return(
        <div className={classes.matchingWrapper}>
            <div className={classes.matchingText}>
                <Typography variant="h3">
                    Map Layers in your template to AirWorks Layers
                </Typography>
                <Typography variant="body1" className={classes.description}>
                    Each AirWorks layer should be matched to a layer in your template. All layers in your template that aren't mapped to an AirWorks layer will 
                    be exported in our output as specified by your template.
                </Typography>
            </div>
            <div className={classes.layerListTitle}>
                <Typography variant="h6">AirWorks Layer</Typography>
                <Typography variant="h6">Layer name from file</Typography>
                <Typography variant="h6">Matched</Typography>
            </div>
            {
                airworksLayers.map(l => (
                    <div className={classes.layerList}> 
                        <Typography className={classes.layerListText}>{l}</Typography>
                        <Autocomplete
                            id="layer-select"
                            className={classes.autoComplete}
                            options={userLayers}
                            autoHighlight
                            loading={rm[l] ? false : true}
                            getOptionLabel={(option) => {
                                return option
                            }}
                            renderOption={(option) => (
                                <React.Fragment>
                                <span>{option}</span>
                                </React.Fragment>
                            )}
                            value={rm[l] || "Choose a layer"}
                            onChange={(ev, val) => {
                                let _rm = {...rm}
                                _rm[l] = val
                                setRm(_rm)
                                setCorrectMatching(_rm)
                            }}
                            renderInput={(params) => {
                                return(
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    margin="dense"
                                    className={classes.outline}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password', // disable autocomplete and autofill
                                        style: {
                                            fontWeight: 300,
                                            fontSize: '14px',
                                        }
                                    }}
                                    />
                                );
                            }}
                        />
                    { !currentTemplate ? <CircularProgress  className={classes.matchingProgress} aria-describedby= "circularProgress" size = {20} /> : <CheckCircleIcon className={rm[l] ? classes.checkIcon : classes.checkIconHide}/> 
                        }
                    </div>
                ))
            }
        </div>
    )
})
export default Matching;