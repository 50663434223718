// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  ListInvoicesAsync,
  GetInvoiceAsync,
} from './actions';

const initialState: IInvoiceState = {
  invoicesLoading: false,
  invoices: [],
  currentInvoice: null,
};

export default createImmerReducer(initialState, (handleAction) => {
  // ListInvoicesAsync
  handleAction(ListInvoicesAsync.request, (draft) => { draft.invoicesLoading = true; });
  handleAction(ListInvoicesAsync.success, (draft, payload) => {
    draft.invoicesLoading = false;
    draft.invoices = payload;
  });
  handleAction(ListInvoicesAsync.failure, (draft) => { draft.invoicesLoading = false; });

  // GetInvoiceAsync
  handleAction(GetInvoiceAsync.request, (draft) => draft);
  handleAction(GetInvoiceAsync.success, (draft, payload) => {
    draft.currentInvoice = payload;
  });
  handleAction(GetInvoiceAsync.failure, (draft) => draft);
});
