// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import CreditCardIcon from 'Common/creditCardIcon';
import ErrorDialog from 'Common/ErrorDialog';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const CreditCardInfo = ({ card, cards, pendingImageryOrder, onEditClick, DeleteCreditCardAction, UpdateDefaultCardAction }: PropsType) => {
  const [showLoader, setShowLoader] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const classes = useStyles();

  const onDeleteClick = async () => {
    setShowLoader(true);
    if (cards.length > 1 || (cards.length === 1 && !pendingImageryOrder)) {
      await DeleteCreditCardAction(card.id);
    } else {
      setShowDialog(true);
    }
    setShowLoader(false);
  };
  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowDialog(false);
    e.stopPropagation();
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.cardIcon}>
        <CreditCardIcon cardType={card.cardType} />
      </div>
      <div>
        <Typography variant="body1">{card.number}</Typography>
        <Typography>{`Expires ${card.expDate}`}</Typography>
      </div>
      <div className={classes.buttonsWrapper}>
        <Button className={classes.button} onClick={onEditClick}>Edit</Button>
        <Button className={classes.button} onClick={onDeleteClick}>Delete</Button>
      </div>
      <div />
      <div>
        <Typography>{card.name}</Typography>
        <Typography>
          {card.address_line1}
          {card.address_line2}
        </Typography>
        <Typography>
          {card.address_city}
          {card.address_state}
          {card.address_zip}
        </Typography>
      </div>
      <div className={classes.defaultButton}>
        {card.defaultCard ? (
          <Typography color="primary">Primary Card</Typography>
        ) : (
          <Button onClick={() => UpdateDefaultCardAction(card.id)}>Make default</Button>
        )}
      </div>
      {showLoader && (
        <>
          <div className={classes.backdrop} />
          <div className={classes.progress}>
            <CircularProgress />
          </div>
        </>
      )}
      <ErrorDialog
        showDialog={showDialog}
        onCancel={onCancel}
        titleText="Error!"
        error
        cancelText="Cancel"
      >
        <Typography variant="body1">
          Unable to delete credit card. You have a saved order with a due balance. We need at least one credit card on file to process that order. If you still want to proceed with deleting the card, please cancel the pending order first or add a new credit card, and then delete the card you no longer need.
          If you need further assistance, please reach out to us through chat or
          {' '}
          <a href="mailto:support@airworks.io">support@airworks.io</a>
          {' '}

        </Typography>
      </ErrorDialog>
    </div>
  );
};

export default compose(CreditCardInfo);
