// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Typography, Button, Divider, Card, Tooltip } from '@material-ui/core';
import LoadingSpinner from 'Common/LoadingSpinner';
import SubscriptionInfo from './subscriptionInfo';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const AccountSubscription = ({
  orgId,
  org,
  automateSubscription,
  cadSubscription,
  GetOrgAction,
  GetOrgSubscriptionAction,
  SetOrgIdAction,
  GetImageryProvidersAction,
}: PropsType) => {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    SetOrgIdAction(orgId);
    const loadData = async () => {
      setShowLoader(true);
      await Promise.all([
        GetOrgAction(orgId),
        GetOrgSubscriptionAction(orgId),
        GetImageryProvidersAction(),
      ]);
      setShowLoader(false);
    };

    loadData();
  }, []);

  const stripeId = org?.stripeId ? org.stripeId : 'Stripe ID Unavailable';
  return (
    <LoadingSpinner loading={showLoader}>
      <div className={classes.wrapper}>
        <>
          <Card className={classes.card}>
            <Typography variant="h4">Customer Stripe ID</Typography>
            <br />
            <Tooltip title="Click to Copy" arrow>
              <Typography
                variant="h1"
                onClick={() => {
                  navigator.clipboard.writeText(stripeId);
                }}
              >
                {stripeId}
              </Typography>
            </Tooltip>
          </Card>
          <br />

          {(automateSubscription || cadSubscription) && (
            <div>
              <Typography variant="h4">Account and subscriptions</Typography>
              <Divider className={classes.divider} />
              <SubscriptionInfo
                {...{ org, cadSubscription, automateSubscription }}
              />
            </div>
          )}
        </>
      </div>
    </LoadingSpinner>
  );
};

export default compose(AccountSubscription);
