// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { DropzoneInputProps } from 'react-dropzone';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, withTheme, WithStyles, WithTheme } from '@material-ui/core';
import { InitFileUpload } from 'State/fileUpload/thunk';
import styles from './styles';

const mapDispatchToProps = {
  InitFileUploadAction: InitFileUpload,
};

interface IOwnProps {
  acceptedExtensions: string[];
  onDropAccepted: (files: File[]) => void;
  children: (getInputProps: (props?: DropzoneInputProps) => DropzoneInputProps, open: () => void) => any;
}

export type PropsType =
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  WithTheme &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  withTheme,
  connect(null, mapDispatchToProps),
);
