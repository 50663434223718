// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { IconButton, Badge } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import downloadIcon from 'Assets/download-icon.png';
import uploadKmlIcon from 'Assets/upload-kml-icon.png';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const CadDrawingsButtons = ({ checked, siteId, downloadFileStatuses, anyChangeInFileStatuses, layers, expand, toggleExpand, SetOrderIdAction, orderId, opsTrainer, clickDownload, ShowUploadKmlDialogAction, ShowUploadOpsTrainingDialogAction }: PropsType) => {
  const classes = useStyles();
  const [areDownloadsReady, setAreDownloadsReady] = useState(false);
  const index = downloadFileStatuses.findIndex((obj: { siteId: string; }) => obj.siteId === siteId);
  useEffect(() => {
    if (index < 0) {
      setAreDownloadsReady(false);
    }
    if (index >= 0) {
      const fileStatuses = downloadFileStatuses[index].fileReadyStatus;
      let areAllFilesReady = true;
      // eslint-disable-next-line no-restricted-syntax
      for (const i in fileStatuses) {
        if (fileStatuses[i] === 0) {
          areAllFilesReady = false;
          break;
        }
      }
      setAreDownloadsReady(areAllFilesReady);
    }
  }, [downloadFileStatuses, anyChangeInFileStatuses]);

  const DownloadButton = (
    <Badge color="primary" overlap="circle" variant="dot" invisible={!areDownloadsReady}>
      <IconButton
        aria-label="Download"
        className={classes.button}
        onClick={clickDownload}
        title="Download files"
      >
        <img src={downloadIcon} alt="Download" />
      </IconButton>
    </Badge>
  );

  const uploadKmlClick = (e: React.ChangeEvent<{}>) => {
    SetOrderIdAction(orderId);
    // eslint-disable-next-line no-unused-expressions
    opsTrainer ? ShowUploadOpsTrainingDialogAction() : ShowUploadKmlDialogAction();
    e.stopPropagation();
  };

  const uploadButton = (
    <IconButton
      aria-label="Upload"
      className={classes.button}
      onClick={(e) => uploadKmlClick(e)}
      title="Upload .dxf"
    >
      <img src={uploadKmlIcon} alt="Upload" />
    </IconButton>
  );

  const LayersExpandButton = (
    <IconButton className={classes.button} onClick={toggleExpand}>
      {expand
        ? <KeyboardArrowDownIcon fontSize="small" />
        : <KeyboardArrowRightIcon fontSize="small" />}
    </IconButton>
  );

  return (
    <>
      {uploadButton}
      {DownloadButton}
      {checked && LayersExpandButton}
    </>
  );
};

export default compose(CadDrawingsButtons);
