// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getProjectFiles } from 'State/project/selectors';
import { ToggleMapStyle } from 'State/map/common/thunk';

const mapStateToProps = (state: IState) => {
  const { fileStatusJson } = state.project;
  const { kmlError } = state.kml.present;
  const { rasterTileJson, allRasterTilesLoaded, lowResCreatedAt, lasBBoxLoading } = state.map.common;
  const loadingHighResTiles = !!rasterTileJson && !!lowResCreatedAt && !allRasterTilesLoaded;

  // add/remove paper on the map depending on whether project files have errors
  let errors = false;
  let tile3DError = false;
  let tileExist = false;
  let lasTileOnProcess = false;
  const files = getProjectFiles(state);
  files.forEach((file) => {
    if (fileStatusJson && fileStatusJson[file.name] && fileStatusJson[file.name].status === 'ERROR') {
      errors = errors || true;
    }
    if (file.type === 'las') {
      if (!tile3DError) {
        tile3DError = file.tileStatuses?.some((statusObj) => statusObj.status === 'ERROR');
      }
      if (!tileExist && !lasTileOnProcess) {
        tileExist = file.tile3DUrl?.length > 0;
        lasTileOnProcess = file.tile3DUrl !== undefined && file.tile3DUrl?.length === 0;
      }
    }
  });

  errors = errors || kmlError;
  return ({
    errorsExist: errors,
    fileStatus: fileStatusJson,
    kmlError,
    loadingHighResTiles,
    lasTileLoading: !lasBBoxLoading && !tileExist && lasTileOnProcess,
    tile3DError,
  });
};

const mapDispatchToProps = {
  ToggleMapStyleAction: ToggleMapStyle,
};

interface IOwnProps {
  setFileErrors: (arg0: boolean) => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      areStatesEqual: (next, prev) => JSON.stringify(next.project.changeJson) === JSON.stringify(prev.project.changeJson),
    },
  ),
);
