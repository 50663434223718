// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Layer } from 'react-mapbox-gl';
import { API_URL } from 'Config';
import compose from './container';

/**
 * Component renders the separate vector layer for every individual layer
 *
 * @param {object} props Component props
 * @param {string} props.id 'default-${params.orderId}-${layer.id}'
 * @param {string} props.sourceId default string
 * @param {string} props.sourceLayerId layer id of the current layer
 * @param {boolean} props.editorFunctions check the user has 2d editor resource
 * @param {string} props.orderId orderid of the current order
 * @param {function} props.map map
 * @param {string} props.color color attribute of the layer
 * @param {number} props.lineWidth line width attribute of the layer
 * @param {string} props.visible boolean for order and layer visibility
 * @param {booelan} props.update boolean from set layer update action
 * @param {boolean} props.autoSaveUpdate boolean from set autosave update action
 * @param {string} props.layerSelected layer selected from set layer selected action
 * @param {function} props.GetGeoJsonForLayerAction this action call the api to get geojson for the currently highlighted layer
 * @param {function} props.ResetUpdateEntitiesAction action to reset update entities array
 * @param {function} props.SetCurrentLayerAction action to set the current layer
 * @param {function} props.ResetDrawSourceAction action to reset draw source action
 * @param {function} props.SetSiteIdAction set the siteid
 * @param {function} props.SetLayerUpdateAction set layer update boolean
 * @param {function} props.AutosaveAction action to update autosave update boolean
 * @param {function} props.SetHighlightedLayerAction action to set highlighted layer name
 * @param {boolean} props.updateEntityStart boolean which checks if the updateLayer API has returned
 */
const MapLayers = compose(({ id, sourceId, sourceLayerId, editorFunctions, fileVersion, orderId, map, color, lineWidth, visible, update, layerSelected, currentTool, highlightedLayer, updateEntityStart,
  GetGeoJsonForLayerAction, SetlayerSelectedAction, SetClickedPointAction, ResetUpdateEntitiesAction, SetCurrentLayerAction, ResetDrawSourceAction, SetSiteIdAction, SetLayerUpdateAction, currentLayer, AutosaveAction, SetHighlightedLayerAction, SetAutoUpdateAction, autoSaveUpdate }) => {
  const onLayerClick = () => {
    // When we are in any other tool other than 'pointer' if currentLayer and layerSelected are null or if
    // currentLayer and layerSelected are both are not equal to the sourceLayerId return from this function
    if (currentTool !== 'pointer' && ((!currentLayer && !layerSelected) || (currentLayer !== sourceLayerId && layerSelected !== sourceLayerId))) {
      return;
    }
    if (highlightedLayer && highlightedLayer !== sourceLayerId) {
      return;
    }
    // If updateLayer API is called, wait till we get a response, avoid calling autosave twice
    if (updateEntityStart) {
      return;
    }
    // Users with no editorFunctions resource and Airworks Admins should not be able to click on the lines
    if (editorFunctions && !_ADMIN_) {
      SetHighlightedLayerAction(null);
      AutosaveAction();
      if (sourceLayerId !== layerSelected) {
        SetlayerSelectedAction(sourceLayerId);
      }
      if (currentLayer) {
        // Clear Editor's Draw source when we move to a new layer
        ResetDrawSourceAction();
        // TODO: Also clear the update entity arrays, because we probably didn't save? (Check again) - Vinutna - 4/14/2021
        ResetUpdateEntitiesAction();
        // Hide the layer(vector) for the layer just clicked
        map.setLayoutProperty(`default-${orderId}-${currentLayer}`, 'visibility', 'visible');
        map.setLayoutProperty(`default-${orderId}-${currentLayer}-invisible`, 'visibility', 'visible');
      }
      GetGeoJsonForLayerAction(sourceLayerId, fileVersion);
      map.setLayoutProperty(id, 'visibility', 'none');
      map.setLayoutProperty(`${id}-invisible`, 'visibility', 'none');
      SetCurrentLayerAction(sourceLayerId);
      SetSiteIdAction(fileVersion);
      // SetClickedPointAction(e.point);
      // If the value of update is true, it means that the currently active dynamic layer has been edited
      // In this case, when the user clicks on a new static layer, we refresh the tiles to see the updated tiles for the previous edited layer
      // Set the update variable in the store to false after mvt refresh
      if ((update || autoSaveUpdate) && map.getSource(sourceId)){
        map.getSource(sourceId).tiles =  [`${API_URL}/tiles/${fileVersion}/{z}/{x}/{y}.mvt?api_key=vector-tiles-LM25tq4&update=` + Math.random() ];
        map.style.sourceCaches[sourceId].clearTiles();
        map.style.sourceCaches[sourceId].update(map.transform);
        map.triggerRepaint();
        SetLayerUpdateAction(false);
        SetAutoUpdateAction(false);
      }
    }
  };

  useEffect(() => {
    if (layerSelected === sourceLayerId) {
      onLayerClick();
    }
  }, [layerSelected, autoSaveUpdate]);

  // For a given lineWidth-
  // width in mm = lineWidth * 0.01
  // width in pixels = lineWidth * 0.01 * 3.779
  //
  // lineWidth values range between -3 and 100
  // Exceptions - 
  // For lineWidth value -3, we set the width as 0.25mm. This is 3.779 pixels * 0.25 = 0.9447 pixels
  // We also treat the lineWidth values -1 and -2 as -3(this is temporary)
  // For lineWidth value 0, we treat as lineWidth 13 and set the width as 0.13mm. 
  const pixels = 3.779; // 1 mm = 3.779 pixels
  let widthInPixels = 0.9447; // set 0.25mm as the default value

  if (lineWidth === 0) {
    widthInPixels = 0.13 * pixels;
  } else {
    if (lineWidth === -1 || lineWidth === -2 || lineWidth === -3) {
      widthInPixels = 0.9447;
    } else {
      widthInPixels = lineWidth * 0.01 * pixels;
    }
  }
  // dynamic threshold for the transparent layer
  // minimum at 15, and if the widthInPixels * 2 is wider than 15, use the linewidth
  const newWidth = widthInPixels * 2 > 15 ? widthInPixels * 2 : 15;

  const onMouseEnter = () => {
    // Show the highlight layer when we hover over the current layer
    // Will allow this for all accounts in the future, but right now it is only for accounts with editorFunctions and also not Airworks Admins
    if (editorFunctions && !_ADMIN_) {
      if (highlightedLayer && highlightedLayer !== sourceLayerId) {
        return;
      }
      SetHighlightedLayerAction(sourceLayerId);
    }
  };

  const onMouseLeave = () => {
    if (editorFunctions && !_ADMIN_) {
      // Hide the highlight layer when the mouse leaves the current layer
      SetHighlightedLayerAction(null);
    }
  };

  return (
    <>
      <Layer
        before="vectorStub"
        id={id}
        key={id}
        type="line"
        sourceId={sourceId}
        sourceLayer={sourceLayerId}
        paint={{
          'line-color': color,
          'line-width': (widthInPixels * 2), // Doubling the line widths so that they look better on the app
        }}
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
      />
      {/* This dummy layer(highlight layer) is always hidden. It is shown on hovering over the actual layer and is controlled inside the actual layer */}
      <Layer
        before="vectorStub"
        id={`${id}-highlight`}
        key={`${id}-highlight`}
        type="line"
        sourceId={sourceId}
        sourceLayer={sourceLayerId}
        paint={{
          'line-color': '#2075FE',
          'line-width': 1,
        }}
        layout={{
          visibility: highlightedLayer === sourceLayerId ? 'visible' : 'none',
        }}
      />
      {/* This is a transparent layer to lower the sensitivity on hovering the layer */}
      <Layer
        id={`${id}-invisible`}
        key={`${id}-invisible`}
        type="line"
        sourceId={sourceId}
        sourceLayer={sourceLayerId}
        paint={{
          'line-color': '#CCCCCC',
          'line-opacity': 0,
          'line-width': newWidth,
        }}
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
        onClick={onLayerClick}
        onMouseEnter ={onMouseEnter}
        onMouseLeave ={onMouseLeave}
      />
    </>
  );
});

export default MapLayers;
