// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent } from '@material-ui/core';
import compose from './container';

const TermsAndConditionsDialog = compose(({ classes, OnAcceptTNCAction, LogoutAction, html }) => {
  const [showDialog, setshowDialog] = useState(true);

  const onAcceptClick = () => {
    OnAcceptTNCAction();
    setshowDialog(false);
  };

  const onRejectClick = () => {
     LogoutAction();
  };
  
  return (
    <div>
      <Dialog
      open={showDialog}
      classes={{
        paper: classes.dialog,
      }}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent className= {classes.dialogContent}>
        <div className={classes.dialogWrapper} dangerouslySetInnerHTML={{__html: html}} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onAcceptClick} color="primary">
         Accept
        </Button>
          <Button onClick={onRejectClick} color="primary">
        Reject
        </Button>
      </DialogActions>
    </Dialog>
    </div>
  );
});

export default TermsAndConditionsDialog;
