// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  bundleContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectedBundle: {
    border: '2px solid #01A1DD',
    opacity: 1,
    background: '#fff',
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.10)',
  },
  bundleOption: {
    width: '100%',
    padding: '5px',
    '& .MuiTypography-body1': {
      width: '100%',
    },
  },
});

export default styles;
