// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { ToggleCadDrawings, ChangeVersion } from 'State/map/common/thunk';

const mapStateToProps = (state: IState) => ({
  fileVersions: state.order.fileVersions,
});

const mapDispatchToProps = {
  ToggleCadDrawingsAction: ToggleCadDrawings,
  ChangeVersionAction: ChangeVersion,
};

interface IOwnProps {
  visible: boolean;
  order: IOrder;
  index: number;
  checked: boolean;
  orderDisplayed: boolean;
  toggleChecked: () => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
