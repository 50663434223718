// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect, useRef } from "react";
import compose from "./container";
import DrawControl from "react-mapbox-gl-draw";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import PolyLineMode from './curvemode/compoundCurveMode';
import DrawPolyLine from './modes/draw_poly_line';

import CircleMode from './modes/draw_circle';
import CustomDirectSelect from './curvemode/customDirectSelectMode';
import CustomSimpleSelect from './curvemode/customSimpleSelectMode';
import { GlobalHotKeys } from "react-hotkeys";

/**
 * Component renders the draw component for the editor
 *
 * @param {object} props Component props
 * @param {object} props.featureCollection GeoJSON feature collection for a clicked layer
 * @param {function} props.UpdateGjAction Method to update the geojson with the modified feature
 */
const MapEditor = compose(({ featureCollection, modeName, SetDrawModeAction, activeFeatureId, currentTool, UpdateFeatureAction, CopyActiveFeatureAction, CutActiveFeatureAction, DeleteActiveFeatureAction, PasteActiveFeatureAction, UndoDrawingAction, SetCurrentToolAction, SendPendoEventAction }) => {
  const [draw, setDraw] = useState(null);
  const [update, setUpdate] = useState(false);
  const [point, setPoint] = useState(null);

  // Using this function we call the draw API methods whenever draw instance changes, the geojson source changes
  // or when the draw mode or the active feature id changes.
  const syncDraw = () => {
    if (draw === null) return;
    draw.set(featureCollection);

    if (modeName) {
      const feature = draw.get(activeFeatureId);
      if (modeName === "direct_select") {
        if (!feature) return;
        draw.changeMode(modeName, { featureId: activeFeatureId });
        return;
      }
      if (currentTool === 'arc') {
        draw.changeMode(modeName, {curving: true});
        return;
      }
      if (modeName === "draw_poly_line" ) {
        draw.changeMode(modeName, {curving: false});
        return;
      }
      draw.changeMode(modeName);
    }
  };

  useEffect(() => {
    syncDraw();
  }, [draw, featureCollection, update, point, modeName, activeFeatureId]);

  const keyMap = {
    COMMAND_CUT: [{ sequence: "command+x", action: "keydown" }, { sequence: "ctrl+x", action: "keydown" }],
    COMMAND_COPY: [{ sequence: "command+c", action: "keydown" }, { sequence: "ctrl+c", action: "keydown" }],
    COMMAND_PASTE: [{ sequence: "command+v", action: "keydown" }, { sequence: "ctrl+v", action: "keydown" }],
    DELETE_ENTITY: ['d', 'delete', 'backspace', 'del'],
    COMMAND_UNDO: [{ sequence: "command+z", action: "keydown" }, { sequence: "ctrl+z", action: "keydown" }],
    ESC: ['Escape'],
    POLYLINE_TO_ARC: ['c'],
  };

  const deleteHandler = () => {
    if (draw.getMode() === 'draw_poly_line') {
      draw.trash();
      return;
    }
    // point is checked because otherwise it doesn't delete the whole feature
    if (point && modeName === 'direct_select') {
      draw.trash();
      setPoint(null);
      return;
    }
    DeleteActiveFeatureAction(activeFeatureId);
  };

  const TogglePolylineAndArc = () => {
    if (currentTool === 'polyline') {
      SetCurrentToolAction('arc');
    } else if (currentTool === 'arc') {
      SetCurrentToolAction('polyline');
    }
  }

  const handlers = {
    COMMAND_CUT: () => !_ADMIN_ ? CutActiveFeatureAction(activeFeatureId) : null,
    COMMAND_COPY: () => !_ADMIN_ ? CopyActiveFeatureAction(activeFeatureId) : null,
    COMMAND_PASTE: () => !_ADMIN_ && activeFeatureId ? PasteActiveFeatureAction() : null,
    DELETE_ENTITY: () => !_ADMIN_ ? deleteHandler() : null,
    COMMAND_UNDO: () => !_ADMIN_ ? UndoDrawingAction() : null,
    POLYLINE_TO_ARC: () => !_ADMIN_ ? TogglePolylineAndArc() : null,
    ESC: () => {
      if (!_ADMIN_) {
        if(currentTool !== 'circle'){
        const features = draw.getAll().features;
        const existingFeature = featureCollection.features.filter((f:any) => f.id === activeFeatureId)[0];
        const editedExistingFeature = features.filter((f:any) => f.id === activeFeatureId)[0];

        let newFeature = [];

        // Get the feature that is currently being drawn. This is in the case we create a new feature
        newFeature = features.filter((f:any) => !featureCollection.features.some(feature => feature.id === f.id));

        // When we extend an existing feature, create the event object with the updated feature
        if (newFeature.length === 0 && editedExistingFeature.geometry.coordinates.length > existingFeature.geometry.coordinates.length) {
          newFeature.push(editedExistingFeature);
        }

        const event = {
          features : newFeature
        }
        // Call updateDraw manually with the fake event
        if (newFeature.length > 0) updateDraw(event);
      }
        SetCurrentToolAction('pointer');
        SetDrawModeAction('simple_select');
      }
    },
  };

  // This function is called when the draw.update event is fired
  // The event data is an object with these properties
  // {
  //   features: Array<Feature>, // Array of features that were updated
  //   action: string // Name of the action that triggered the update -  'move' or 'change_coordinates'
  // }
  const updateDraw = (e: any) => {
    SendPendoEventAction();
    const action = e.action;
    const feature = e.features[0];
    const coordinates = feature?.geometry?.coordinates;
    if(coordinates.length === 0 || !coordinates[0][0]){
      return;
    }
    if (action && (action === "move" || action === "change_coordinates")) {
      UpdateFeatureAction(feature, 'edit');
    } else {
      UpdateFeatureAction(feature, 'add');
    }
  };

  const deleteDraw = (e: any) => {
    const feature = e.features[0];
    if (feature.geometry.type === 'LineString' && feature.geometry.coordinates.length === 0) {
      return;
    }
    UpdateFeatureAction(feature, 'delete');
  };

  const selectionChange = (e: any) => {
    if (e.features.length === 0 && e.points.length === 0) {
      return;
    }
    if (e.points.length > 0) {
      setPoint(e.points);
    }
    // If the feature is block ref, don't allow user to click on a vertex/create a new vertex
    if (e.points.length > 0 && e.features[0].properties.block_def_id) {
      SetDrawModeAction("simple_select");
      return;
    }
    SetDrawModeAction("direct_select", { featureId: e.features[0].id }); // forcing modeName to be direct_select
    SetCurrentToolAction('pointer');
  };

  // To access the Draw object with all the API methods, we define a ref(callback ref) on the <DrawControl> component,
  // and the Draw object will be in the draw field of this ref. We then set this draw object in the component state.
  return (
    <GlobalHotKeys keyMap={keyMap} handlers={handlers} allowChanges={true}>
      <DrawControl
        userProperties
        displayControlsDefault={false}
        modes={{
          ...MapboxDraw.modes,
          draw_poly_line: PolyLineMode,
          draw_circle: CircleMode,
          direct_select: CustomDirectSelect,
          simple_select: CustomSimpleSelect,
        }}
        snap={true}
        snapOptions={{
          snapPx: 5, // defaults to 15
        }}
        ref={(drawControl) => {
          if (drawControl !== null && drawControl.draw !== null) {
            setDraw(drawControl.draw);
          }
        }}
        onDrawCreate={updateDraw}
        onDrawUpdate={updateDraw}
        onDrawSelectionChange={selectionChange}
        onDrawDelete={deleteDraw}
      />
    </GlobalHotKeys>
  );
});

export default MapEditor;