// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, postJson, patchJson, deleteRequest } from 'Utils/http';
import { API_URL } from 'Config';
import { ReadFromStorage } from 'State/auth/thunk';
import { getProcessingOrders } from 'State/order/selectors';
import {
  GetUserProfileAsync, UpdateUserProfileAsync, UpdatePasswordAsync, GetOrgStatsAsync, UpdateOrgProfileAsync, GetOrgProfileAsync, GetOrgUsersAsync, DeleteOrgUserAsync, CreateOrgUserAsync, DefaultTemplateAsync, GetOrgAssignableRolesAsync, EditOrgUserAsync, GetOrgTeamsAsync, DeleteOrgTeamAsync, UpdateOrgTeamAsync, AddOrgTeamAsync,
} from './actions';

export const GetUserProfile = (polling: boolean = false) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    if (polling) {
      const processingOrders = getProcessingOrders(getState());
      if (!processingOrders.length) return {};
    }

    const url = `${API_URL}/users/me`;
    const { token } = getState().auth;

    dispatch(GetUserProfileAsync.request());

    const result = await getJson<IUser>(url, token);
    if (result.success) {
      result.data.roles = [];
      dispatch(GetUserProfileAsync.success(result.data));
      localStorage.setItem('user', JSON.stringify(result.data));
      ReadFromStorage()(dispatch);
    } else {
      dispatch(GetUserProfileAsync.failure(result.message));
    }

    return result;
  };

export const UpdateUserProfile = (data: IUser) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/users/me`;
    const { token } = getState().auth;

    dispatch(UpdateUserProfileAsync.request());

    const result = await patchJson<IUserResponse>(url, data, token);
    if (result.success) {
      dispatch(UpdateUserProfileAsync.success(result.data));
      localStorage.setItem('user', JSON.stringify(result));
      ReadFromStorage()(dispatch);
    } else {
      dispatch(UpdateUserProfileAsync.failure(result.data.message));
    }

    return result;
  };

export const GetOrgProfile = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/organizations`;
    const { token } = getState().auth;

    dispatch(GetOrgProfileAsync.request());

    const result = await getJson<IOrgResponse>(url, token);
    if (result.success) {
      dispatch(GetOrgProfileAsync.success(result.data.org));
    } else {
      dispatch(GetOrgProfileAsync.failure(result.message));
    }

    return result;
  };

export const GetOrgUsers = (orgId?: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const url = _ADMIN_ ? `${API_URL}/organizations/getOrgUsers/${orgId}` : `${API_URL}/organizations/getOrgUsers`;

    dispatch(GetOrgUsersAsync.request());

    const result = await getJson<IUser[]>(url, token);
    if (!result.success) {
      return result;
    }

    let orgUsers : {
      _id: string,
      email: string,
      fullName: string,
      admin: boolean,
      deleting: boolean,
      role: string[],
      teams: any,
  }[] = null;

    const filterTeams = (teamsArr: []) => {
      const arr:any = [];
      teamsArr.forEach((id) => {
        const team = getState().account?.orgTeams.find((p) => p._id === id);
        arr.push(team);
      });
      return arr;
    };

    if (result.success) {
      const users = result.data.data;
      orgUsers = [];
      users.forEach((user: IUser) => {
        orgUsers.push({
          ...user,
          deleting: false,
          teams: user.teams?.length > 0 ? filterTeams(user.teams) : [],
        });
      });
      dispatch(GetOrgUsersAsync.success(orgUsers));
    }
    return result;
  };

export const EditOrgUser = (updatedUser: IActiveUser, orgId?: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { _id, email, firstName, lastName, jobTitle, phoneNumber, updatedRoles, updatedTeams } = updatedUser;
  const initialUser = getState().account.orgUsers.find((user) => user._id === updatedUser._id);

  const { token } = getState().auth;
  let unique:any = [];
  const teamIds:any = [];
  if (updatedTeams && updatedTeams.length > 0) {
    updatedTeams.forEach((el: any) => {
      teamIds.push(el._id);
    });
  }
  if (updatedRoles && updatedRoles.length > 0) {
    unique = [...new Set(updatedRoles)];
  }
  const url = `${API_URL}/users/editOrgUserProfile`;
  const data = {
    orgId,
    userId: _id,
    email: email === initialUser.email ? undefined : email,
    firstName: firstName === initialUser.firstName ? undefined : firstName,
    lastName: lastName === initialUser.lastName ? undefined : lastName,
    jobTitle: jobTitle === initialUser.jobTitle ? undefined : jobTitle,
    phoneNumber: phoneNumber === initialUser.phoneNumber ? undefined : (phoneNumber || ''),
    roleAccess: unique.length ? unique : undefined,
    teams: updatedTeams ? teamIds : undefined,
  };

  const result = await patchJson<{user: IUser}>(url, data, token);
  if (result.success) {
    const { user } = result.data;
    dispatch(EditOrgUserAsync.success(user));
  }
  return result;
};

export const DeleteUser = (userEmail: string, migrateOpenOrders?: boolean) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/organizations/deleteOrgUser`;
    const data = { email: userEmail, migrateOpenOrders };

    dispatch(DeleteOrgUserAsync.request(userEmail));

    const result = await deleteRequest(url, token, data);
    if (result.success) {
      dispatch(DeleteOrgUserAsync.success(userEmail));
    } else {
      dispatch(DeleteOrgUserAsync.failure(userEmail));
    }
    return result;
  };

export const CreateUser = (userData: IActiveUser, orgId?: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { firstName, lastName, email, teams, jobTitle, phoneNumber, rolesArray } = userData;
    const { token } = getState().auth;
    const teamIds: string[] = [];
    if (teams.length > 0) {
      teams.forEach((el: ITeam) => {
        teamIds.push(el._id);
      });
    }
    const url = `${API_URL}/users/createAdminOrUnderling`;
    // assign automate role by default(if org doesn't have an active automate subscription, it won't add roles, we have that check on the BE)
    const roleAccess = [...rolesArray, 'automate'];
    const data = { firstName, lastName, email, roleAccess, teams: teamIds, jobTitle, ...(phoneNumber && { phoneNumber }), orgId };
    dispatch(CreateOrgUserAsync.request());

    const result = await postJson<any>(url, data, token);

    const filterTeams = (teamsArr: []) => {
      const arr:any = [];
      if (teamsArr.length > 0) {
        teamsArr.forEach((id) => {
          const team = getState().account.orgTeams.find((p) => p._id === id);
          arr.push(team);
        });
      }
      return arr;
    };

    if (result.success) {
      const { newUser } = result.data;
      const orgUser = { ...newUser, deleting: false, teams: newUser.teams?.length > 0 ? filterTeams(newUser.teams) : [] };

      dispatch(CreateOrgUserAsync.success(orgUser));
    } else {
      dispatch(CreateOrgUserAsync.failure(result.message));
    }
    return result;
  };

export const UpdateOrgProfile = (data: IOrg) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/organizations/updateOrgDetails`;
    const { token } = getState().auth;

    dispatch(UpdateOrgProfileAsync.request());
    const result = await patchJson<IOrgResponse>(url, { ownerOrganization: data._id, companyName: data.companyName, address: data.address, phoneNumber: data.phoneNumber }, token);
    if (result.success) {
      dispatch(UpdateOrgProfileAsync.success(result.data));
    } else {
      dispatch(UpdateOrgProfileAsync.failure(result.message));
    }

    return result;
  };

export const PasswordUpdate = (data: { oldPassword: string, password: string }) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/users/passwordUpdate`;
    const { token } = getState().auth;
    const _data = {
      oldpw: data.oldPassword,
      newpw: data.password,
    };

    const result = await postJson<string>(url, _data, token);
    if (result.success) {
      dispatch(UpdatePasswordAsync.success(result.data));
    } else {
      dispatch(UpdatePasswordAsync.failure());
    }

    return result;
  };

export const GetOrgStats = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/organizations/getOrgStats`;
    const { token } = getState().auth;

    dispatch(GetOrgStatsAsync.request());

    const result = await getJson<IOrgStats>(url, token);
    if (result.success) {
      dispatch(GetOrgStatsAsync.success(result.data));
    }
  };

// Set Default template for the org
export const DefaultTemplate = (data: { templateId: string }) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/organizations/updateDefaultTemplate`;
    const { token } = getState().auth;

    dispatch(DefaultTemplateAsync.request());

    const result = await patchJson<IOrgResponse>(url, data, token);
    if (result.success) {
      dispatch(DefaultTemplateAsync.success(result.data.org));
    }
    return result;
  };

export const GetOrgAssignableRoles = (orgId?: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = orgId ? `${API_URL}/role/getAssignableRoles/${orgId}` : `${API_URL}/role/getAssignableRoles`;
    const { token } = getState().auth;
    const result = await getJson<any>(url, token);
    const roles:any = {};
    if (result.success) {
      const { orgRoles } = result.data;

      orgRoles.forEach((role: {_id: string, name: string}) => {
        if (role.name === 'editor2d') {
          role.name = 'cad';
        }
        roles[role._id] = role.name;
      });
      dispatch(GetOrgAssignableRolesAsync.success(roles));
    }
    return result;
  };

export const GetOrgTeams = (orgId?: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    dispatch(GetOrgTeamsAsync.request());

    const url = orgId ? `${API_URL}/teams/${orgId}` : `${API_URL}/teams`;
    const { token } = getState().auth;
    const result = await getJson<IGetTeamsResponse>(url, token);

    if (result.success) {
      const { orgTeams } = result.data;
      dispatch(GetOrgTeamsAsync.success(orgTeams));
    } else {
      dispatch(GetOrgTeamsAsync.failure());
    }
    return result;
  };

export const DeleteOrgTeam = (codeId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    dispatch(DeleteOrgTeamAsync.request());

    const url = `${API_URL}/teams/${codeId}`;
    const { token } = getState().auth;
    const result = await deleteRequest(url, token);

    if (result.success) {
      dispatch(DeleteOrgTeamAsync.success(codeId));
    } else {
      dispatch(DeleteOrgTeamAsync.failure());
    }
    return result;
  };

export const UpdateOrgTeam = (teamId: string, name: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    dispatch(UpdateOrgTeamAsync.request());

    const url = `${API_URL}/teams/${teamId}`;
    const { token } = getState().auth;

    const data = { name };
    const result = await patchJson<ITeamResponse>(url, data, token);

    if (result.success) {
      const { team } = result.data;

      dispatch(UpdateOrgTeamAsync.success(team));
    } else {
      dispatch(UpdateOrgTeamAsync.failure());
    }
    return result;
  };

export const AddOrgTeam = (name: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    dispatch(AddOrgTeamAsync.request());

    const url = `${API_URL}/teams`;
    const { token } = getState().auth;
    const data = { name };
    const result = await postJson<ITeamResponse>(url, data, token);

    if (result.success) {
      const { team } = result.data;

      dispatch(AddOrgTeamAsync.success(team));
    } else {
      dispatch(AddOrgTeamAsync.failure());
    }
    return result;
  };
