// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { InView } from 'react-intersection-observer';
import {
  Typography,
  Avatar,
  CircularProgress,
  Button,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { GetAdminGeneralRoute } from 'Utils/routes';
import SearchField from 'Common/SearchField';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const Admin =
  ({
    history,
    list,
    page,
    searchFilter,
    loading,
    token,
    loadAll,
    GetListAction,
    SetListAction,
    SetLoadAllAction,
    SetLoadNewAction,
    SetSearchFilterAction,
    SetPageAction,
    SetSortFilterAction,
    DeleteOrgAction,
  }: PropsType) => {
    const classes = useStyles();
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const [failedResultMessage, setFailedResultMessage] = useState(null);

    const renderColumnHeader = (columnMeta: any) => (
      <td key={columnMeta.name} className={classes.columnHeader}>
        <Typography variant="caption">{columnMeta.label}</Typography>
      </td>
    );

    const getAvatarText = (value: string) => {
      const split = value && value.split(' ');
      const firstName = split && split[0];
      const lastName = split && split[1];
      const char1 = (firstName && firstName[0]) || '';
      const char2 = (lastName && lastName[0]) || '';

      return `${char1}${char2}`;
    };

    const handleDeleteOrg = async (e: any, orgId: string) => {
      e.stopPropagation();
      const result = await DeleteOrgAction(orgId);
      if (result.success) {
        setShowSuccess(true);
      } else if (!result.success && result.message) {
        setFailedResultMessage(result.message);
        setShowError(true);
      }
    };

    const handleSuccessSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowSuccess(false);
    };

    const handleErrorSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
      if (reason === 'clickaway') {
        return;
      }
      setShowError(false);
    };

    const pluralize = (number: number, noun: string, suffix = 's') =>
      `${number} ${noun}${number !== 1 ? suffix : ''}`;

    const columns = [
      {
        name: 'companyName',
        label: 'Company Name',
        options: {
          customHeadRender: renderColumnHeader,
          customBodyRender: (value: string) => (
            <div className={classes.nameColumn}>
              <Avatar className={classes.avatar}>{getAvatarText(value)}</Avatar>
              {value}
            </div>
          ),
        },
      },
      {
        name: 'location',
        label: 'Location',
        options: { customHeadRender: renderColumnHeader },
      },
      {
        name: 'subscriptions',
        label: 'Subscription',
        options: { customHeadRender: renderColumnHeader },
      },
      {
        name: 'phoneNumber',
        label: 'Phone number',
        options: { customHeadRender: renderColumnHeader },
      },
      {
        name: 'projectsCount',
        label: ' ',
        options: {
          customHeadRender: renderColumnHeader,
          customBodyRender: (value: number) => pluralize(value || 0, 'project'),
        },
      },
      {
        name: 'View',
        empty: true,
        options: {
          customHeadRender: () => null,
          customBodyRender: () => <Button>View</Button>,
        },
      },
      {
        name: '_id',
        empty: true,
        options: {
          customHeadRender: () => null,
          customBodyRender: (orgId: string) => (
            <Button onClick={(e) => {
              handleDeleteOrg(e, orgId);
            }}
            >
              Delete
            </Button>
          ),
        },
      },
    ];

    const options = {
      elevation: 0,
      responsive: 'stacked',
      serverSide: true,
      selectableRows: 'none',
      print: false,
      download: false,
      viewColumns: false,
      sort: false,
      search: false,
      filter: false,
      pagination: false,
      onTableInit: () => {
        token && GetListAction();
      },
      setRowProps: () => ({
        classes: {
          hover: classes.rowHover,
        },
      }),
      onRowClick: (
        rowData: string[],
        rowMeta: { dataIndex: number; rowIndex: number },
      ) => {
        const org = list[rowMeta.dataIndex];
        history.push(GetAdminGeneralRoute(org._id));
      },
    };

    return (
      <div className={classes.pageWrapper}>
        <div className={classes.toolbar}>
          <SearchField
            value={searchFilter}
            debounceTimeout={500}
            disabled={loading}
            onChange={(v) => {
              SetLoadNewAction(true);
              SetListAction([]);
              SetSearchFilterAction(v);
              SetLoadAllAction(false);
              SetPageAction(0);
              GetListAction();
            }}
          />
          {/* sort is not be handled, will fix it when requirements comes in */}
          {/* <SortSelect
            onChange={(ev) => {
              SetSortFilterAction(ev.target.value);
              GetListAction();
            }}
          /> */}
        </div>
        <Snackbar className={classes.snackBar} open={showSuccess} autoHideDuration={10000} onClose={handleSuccessSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="success" className={classes.alert} color="info">Organization Deleted</Alert>
        </Snackbar>
        <Snackbar className={classes.snackBar} open={showError} autoHideDuration={10000} onClose={handleErrorSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="error" className={classes.error} color="error">{ !failedResultMessage ? 'Unable to delete Organization' : failedResultMessage }</Alert>
        </Snackbar>
        <MUIDataTable
          data={list}
          columns={columns}
          options={options}
          className={classes.table}
        />
        <div className={classes.loading}>
          {loading && (<CircularProgress size={50} />)}
          {!loading && (loadAll ? (
            <Typography variant="h3">End of List</Typography>
          ) : (
            <>
              <InView onChange={(inView) => {
                if (inView) {
                  SetPageAction(page + 1);
                  GetListAction();
                }
              }}
              />
              <Typography variant="h3">Loading more...</Typography>
            </>
          ))}
        </div>
      </div>
    );
  };

export default compose(Admin);
