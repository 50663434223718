// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { DeleteProjectAdmin } from 'State/admin/thunk';
import { SetOutputLas, DownloadPostgisDxf, SetDownloadFilesState, ClearDownloadFiles } from 'State/order/thunk';
import { DownloadTIF, DeleteTifFile, DeleteLasFile, SendImageryUploadEmail } from 'State/project/thunk';
import { connect } from 'react-redux';
import { SetImageryCaptureDateAction } from 'State/fileUpload/actions';

const mapStateToProps = (state: IState) => ({
  emailLoading: state.project.emailLoading,
  downloadFileStatuses: state.order?.dxfFileStatus,
  downloading: state.order?.dxfFileStatus?.some((obj) => (obj.fileReadyStatus.dxfFile === 0)),
  downloaded: state.order?.dxfFileStatus?.some((obj) => (obj.fileReadyStatus.dxfFile === 1)),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    DownloadTIFAction: DownloadTIF,
    SetOutputLasAction: SetOutputLas,
    DeleteTifAction: DeleteTifFile,
    DeleteLasAction: DeleteLasFile,
    DownloadPostgisDxfAction: DownloadPostgisDxf,
    SetDownloadFilesStateAction: SetDownloadFilesState,
    ClearDownloadFilesAction: ClearDownloadFiles,
    DeleteProjectAction: DeleteProjectAdmin,
    SetImageryCaptureDateAction,
    SendImageryUploadEmailAction: SendImageryUploadEmail,
  }, dispatch);

interface IOwnProps {
  list: IOrder[];
  imageryRequest?: IImageryRequest;
  layers?: IVectorTileJson;
  selectIndex: (index: number) => void;
  currentIndex: number;
  siteId: string;
  isEstimate?: boolean;
  projectFileStatuses: any[];
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
