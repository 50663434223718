// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import { SetModeAction, UpdateKmlFeatureAction, SetSelectionAction, SetMeasurementModeAction, SetCreateKmlAction, SetCurrentMeasurementOptionAction, SetKmlLineDrawAction, SetFirstTimeChangeEstimateAction, ResetKmlDrawAction, ClearDrawnKmlAction, DeleteKmlFeatureAction, UploadingDrawnKmlAction } from './actions';

const initialState: IDrawState = {
  selectedFeatures: null,
  mode: null,
  measurementMode: null,
  currentMeasurementOption: null,
  firstTimeChangeEstimate: true,
  kmlLine: false,
  createKml: false,
  currentKml: {
    type: 'FeatureCollection',
    features: [],
  },
  uploadingDrawnKml: false,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(SetSelectionAction, (draft, payload) => { draft.selectedFeatures = payload; });
  handleAction(SetModeAction, (draft, payload) => { draft.mode = payload; });
  handleAction(SetMeasurementModeAction, (draft) => { draft.measurementMode = !draft.measurementMode; });
  handleAction(SetCurrentMeasurementOptionAction, (draft, payload) => { draft.currentMeasurementOption = payload; });
  handleAction(SetFirstTimeChangeEstimateAction, (draft, payload) => { draft.firstTimeChangeEstimate = payload; });
  handleAction(UpdateKmlFeatureAction, (draft, payload) => {
    const index = draft.currentKml.features.findIndex((feature: any) => feature.id === payload.id);
    if (index !== -1) {
      draft.currentKml.features[index] = payload;
    } else {
      draft.currentKml.features.push(payload);
    }
  });
  handleAction(DeleteKmlFeatureAction, (draft, payload) => {
    const index = draft.currentKml.features.findIndex((feature: any) => feature.id === payload.id);
    if (index !== -1) draft.currentKml.features.splice(index, 1);
  });
  handleAction(SetKmlLineDrawAction, (draft, payload) => { draft.kmlLine = payload; });
  handleAction(ResetKmlDrawAction, (draft) => {
    draft.createKml = initialState.createKml;
    draft.currentKml = initialState.currentKml;
  });
  handleAction(ClearDrawnKmlAction, (draft) => { draft.currentKml = initialState.currentKml; });
  handleAction(SetCreateKmlAction, (draft, payload) => { draft.createKml = payload; });
  handleAction(UploadingDrawnKmlAction, (draft, payload) => { draft.uploadingDrawnKml = payload; });
});
