// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@material-ui/core/';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const ConfirmProceedDialog = ({ showDialog, onOk, onCancel, title, contentText, cancelButtonText, okButtonText }: PropsType) => {
  const classes = useStyles();

  return (
    <Dialog
      open={showDialog}
      onClose={onCancel}
    >
      <Typography variant="h3" className={classes.title}>{title}</Typography>
      <DialogContent><Typography>{contentText}</Typography></DialogContent>
      <DialogActions className={classes.buttons}>
        <Button className={classes.cancelButton} onClick={onCancel}>{cancelButtonText}</Button>
        <Button className={classes.okButton} onClick={onOk}>{okButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(ConfirmProceedDialog);
