// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  bar2: {
    backgroundColor: theme.palette.secondary.main,
  },
  dashedBar: {
    animation: 'none',
    backgroundColor: theme.palette.lightGrey,
    backgroundImage: 'none',
  },
  hasAcreage: {
    backgroundColor: theme.palette.primary.main,
  },
  noAcreage: {
    backgroundColor: 'red',
  },
}));

export default useStyles;
