// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  map: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    top: 0,
    bottom: 0,
    width: '100%',
    flex: 1,
  },
});

export default styles;
