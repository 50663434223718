// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  map: {
    height: '100%',
    position: 'relative',
  },
  transparentLayer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  loadingModal: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    backgroundColor: 'rgba(0, 0, 0, .4)',
    top: 0,
    left: 0,
    color: '#eee',
    textAlign: 'center',
    '& p': {
      top: '45%',
      left: '45%',
      fontSize: '1.5em',
      position: 'absolute',
    },
  },
  markerIconWrapper: {
    '&:after': {
      top: 9,
      left: '50%',
      width: 10,
      height: 10,
      content: '""',
      position: 'absolute',
      borderRadius: '50%',
      backgroundColor: 'white',
      transform: 'translateX(-50%)',
    },
  },
  markerIcon: {
    width: 40,
    height: 40,
    fill: '#021E40',
  },
  markerIconHovered: {
    width: 40,
    height: 40,
    fill: theme.palette.primary.main,
  },
});

export default styles;
