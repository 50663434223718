// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';

import { SetSortFilter, SetPage, GetProjects } from 'State/project/thunk';
import { projectsFilterSelector } from 'State/project/selectors';

import styles from './styles';

const mapStateToProps = (state: IState) => ({
  list: state.project.list,
  sortFilter: state.project.sortFilter,
  totalProjectCount: state.project.projectsCount,
  html: state.auth.html,
  page: state.project.page,
  projectsLoading: state.project.projectsLoading,
  loadAll: state.project.loadAll,
});

const mapDispatchToProps = {
  SetSortFilterAction: SetSortFilter,
  SetPageAction: SetPage,
  GetProjectsAction: GetProjects,
};

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
