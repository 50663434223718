// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { FormControl, RadioGroup, Radio, FormControlLabel } from '@material-ui/core';
import classnames from 'classnames';

import RadioButtonLabel from './RadioButtonLabel';

import IncludedAddOns from './IncludedAddOns';
import compose from './container';

const BundleSelect = compose(({ classes, availableBundles, zeroAddOns, selectedBundle, bundles, density, deliverySpeed, checkFirstTimeChangeEstimate, SetDensityAction, SetDeliverablesAction }) => {
  const [value, setValue] = useState('');

  const selectBundle = (name: string) => {
    let findBundles;
    if (name === 'Topo') {
      findBundles = bundles.find((b) => b.name === name && b.deliverySpeed === deliverySpeed);
      SetDensityAction('normal');
    } else {
      findBundles = bundles.find((b) => b.name === name && b.density === density && b.deliverySpeed === deliverySpeed);
    }

    SetDeliverablesAction(findBundles, false);
  };

  useEffect(() => {
    setValue(selectedBundle?.name);
  }, [selectedBundle]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const bundleName = (event.target as HTMLInputElement).value;
    zeroAddOns();
    checkFirstTimeChangeEstimate(() => selectBundle(bundleName));
  };

  return (
    <div className={classes.bundleContainer}>
      <div className={classes.bundleContainer}>
        <FormControl className={classes.bundleOption}>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={value}
            onChange={handleChange}
            className={classes.bundleOption}
          >
            {availableBundles?.map((bundle: IBaseBundle) => (
              <div
                key={bundle._id}
                className={classnames(classes.bundleOption, {
                  [classes.selectedBundle]:
                    bundle.name === (selectedBundle && selectedBundle.name),
                })}
              >
                <FormControlLabel
                  className={classes.bundleOption}
                  value={bundle.name}
                  control={<Radio />}
                  label={<RadioButtonLabel bundle={bundle} />}
                />
              </div>
            ))}
          </RadioGroup>
        </FormControl>
      </div>
      <IncludedAddOns />
    </div>
  );
});

export default BundleSelect;
