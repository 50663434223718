// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const uesStyles = makeStyles((theme: Theme) => createStyles({
  projectName: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    gridColumnGap: theme.spacing(1),
  },
  titleButton: {
    textTransform: 'none',
    padding: '0px',
    width: '100%',
    backgroundColor: 'transparent !important',
    '&:hover': {
      backgroundColor: 'transparent !important',
    },
  },
  buttonText: {
    fontWeight: 500,
    fontSize: '1rem',
    justifyContent: 'left',
  },
  inputField: {
    '& .MuiInputBase-input': {
      width: '100%',
      padding: '4px',
      background: '#E6E8ED',
      height: '41px',
      fontSize: '1rem',
    },
  },
}));

export default uesStyles;
