// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

/* eslint-disable import/prefer-default-export */
export const occupationList = [
  'Architect',
  'Contractor',
  'Consultant/Advisor',
  'Engineer',
  'GIS',
  'Land Developer',
  'Sales/Marketing',
  'Student',
  'Surveyor',
  'UAV Pilot',
  'Other',
];

export const mininumBufferSize = 10;

export const opsDailyCreditCapacity = 150;
