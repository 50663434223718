// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Select, MenuItem, InputBase, Grid, IconButton } from '@material-ui/core';
import SearchField from 'Common/SearchField';
import gridviewIcon from 'Assets/grid-view-icon.png';
import gridviewIconActive from 'Assets/grid-view-icon-ACTIVE.png';
import mapviewIcon from 'Assets/map-view-icon.png';
import mapviewIconActive from 'Assets/map-view-icon-ACTIVE.png';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const PageToolbar = ({ sortFilter, keywordFilter, orderSortFilter, SetSortFilterAction, SetKeywordFilterAction, SetOrderSortFilterAction, showMapview, showMapviewClick, showGridviewClick, GetProjectsAction, SetLoadNewAction, SetPageAction, SetProjectsAction, SetLoadAllAction }: PropsType) => {
  const classes = useStyles();
  const toolbarStyle : string = showMapview ? classes.pageToolbarMapview : classes.pageToolbar;
  const toolbarContainer : string = showMapview ? classes.pageToolbarContainerMapview : classes.pageToolbarContainer;
  const loadNewData = () => {
    SetLoadNewAction(true);
    SetLoadAllAction(false);
    SetPageAction(0);
    SetProjectsAction([]);
    GetProjectsAction();
  };
  return (
    <div className={toolbarStyle}>
      <Grid className={toolbarContainer}>
        <SearchField
          className={classes.icons}
          value={keywordFilter}
          onChange={(e) => {
            SetKeywordFilterAction(e);
            loadNewData();
          }}
        />
        <IconButton
          aria-label="Mapview"
          onClick={showMapviewClick}
          target="_blank"
          className={classes.icons}
        >
          {showMapview ? (
            <img src={mapviewIconActive} alt="Gridview" />
          ) : (
            <img src={mapviewIcon} alt="Gridview" />
          )}
        </IconButton>
        <IconButton
          aria-label="Gridview"
          onClick={showGridviewClick}
          target="_blank"
          className={classes.icons}
        >
          {showMapview ? (
            <img src={gridviewIcon} alt="Gridview" />
          ) : (
            <img src={gridviewIconActive} alt="Gridview" />
          )}
        </IconButton>
        <Select
          inputProps={{
            name: 'sort',
            id: 'sort',
          }}
          input={<InputBase />}
          className={classes.sortButton}
          value={sortFilter}
          onChange={(ev) => {
            SetSortFilterAction(ev.target.value);
            loadNewData();
          }}
          classes={{
            root: classes.fontWeight,
          }}
        >
          <MenuItem className={classes.fontWeight} value="updatedAtD">
            Recent
          </MenuItem>
          <MenuItem className={classes.fontWeight} value="nameA">
            A-Z
          </MenuItem>
          <MenuItem className={classes.fontWeight} value="nameD">
            Z-A
          </MenuItem>
        </Select>
        <Select
          inputProps={{
            name: 'filterby',
            id: 'filterby',
          }}
          input={<InputBase />}
          className={classes.sortButton}
          value={orderSortFilter}
          onChange={(ev) => {
            SetOrderSortFilterAction(ev.target.value);
            loadNewData();
          }}
        >
          <MenuItem value="updatedAtD">
            All Projects
          </MenuItem>
          <MenuItem value="estimate">
            Estimate
          </MenuItem>
          <MenuItem value="completed">
            Completed
          </MenuItem>
        </Select>
      </Grid>
    </div>
  );
};

export default compose(PageToolbar);
