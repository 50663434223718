// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Dialog, DialogActions, Button, Typography } from '@material-ui/core/';
import ErrorIcon from '@material-ui/icons/Error';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const ErrorDialog = ({ showDialog, error, onAccept, onCancel, titleText, acceptText, cancelText, children }: PropsType) => {
  const classes = useStyles();
  const iconClass = error ? classes.errorIcon : classes.warningIcon;
  return (
    <Dialog open={showDialog} className={classes.dialog}>
      <div className={classes.dialogBox}>
        <div className={classes.dialogTitle}>
          <ErrorIcon className={iconClass} />
          <Typography variant="h3" className={classes.titleText}>{titleText}</Typography>
        </div>
        {children}
        <DialogActions className={classes.dialogActions}>
          {acceptText && <Button onClick={onAccept} className={classes.closeButton}>{acceptText}</Button>}
          <Button onClick={onCancel} className={classes.closeButton}>{cancelText}</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default compose(ErrorDialog);
