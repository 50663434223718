// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const GetGeoJsonForLayerAsync = createAsyncAction(
  'GET_GEOJSON_START',
  'GET_GEOJSON_SUCCESS',
  'GET_GEOJSON_FAILED',
)<null, any, string>();

export const SetClickedPointAction = createAction('SET_POINT', (action) => (data: { x: number, y: number }) => action(data));

export const EditFeatureAction = createAction('EDIT_FEATURE', (action) => (data: GeoJSON.Feature) => action(data));

export const DeleteFeatureAction = createAction('DELETE_FEATURE', (action) => (data: GeoJSON.Feature) => action(data));

export const CreateFeatureAction = createAction('CREATE_FEATURE', (action) => (data: GeoJSON.Feature) => action(data));

export const DeleteAddedOrEditedFeatureAction = createAction('DELETE_ADDED_EDITED_FEATURE', (action) => (data: GeoJSON.Feature) => action(data));

export const SetCurrentLayerAction = createAction('SET_CURRENT_LAYER', (action) => (data: string) => action(data));

export const SetSiteIdAction = createAction('SET_SITE_ID', (action) => (data: string) => action(data));

export const SetLayerUpdateAction = createAction('SET_LAYER_UPDATE', (action) => (data: boolean) => action(data));

export const SetAutoUpdateAction = createAction('SET_AUTO_UPDATE', (action) => (data: boolean) => action(data));

export const CopyActiveFeatureAction = createAction('COPY_ACTIVE_FEATURE', (action) => (data: GeoJSON.Feature) => action(data));

export const CutActiveFeatureAction = createAction('CUT_ACTIVE_FEATURE', (action) => (data: GeoJSON.Feature) => action(data));

export const PasteActiveFeatureAction = createAction('PASTE_ACTIVE_FEATURE', (action) => (data: GeoJSON.Feature) => action(data));

export const UpdateEntityAction = createAsyncAction(
  'UPDATE_ENTITY_START',
  'UPDATE_ENTITY_SUCCESS',
  'UPDATE_ENTITY_FAILED',
)<null, null, null>();

export const SetCurrentToolAction = createAction('SET_CURRENT_TOOL', (action) => (name: string) => action(name));

export const SetCurrentPanelAction = createAction('SET_CURRENT_PANEL', (action) => (name: string) => action(name));

export const SetEditorModeAction = createAction('SET_EDITOR_MODE', (action) => (data: boolean) => action(data));

export const SetDrawModeAction = createAction('SET_DRAW_MODE', (action) => (data: { name: string, params?: { featureId: string } }) => action(data));

export const ResetDrawSourceAction = createAction('RESET_DRAW_SOURCE');

export const SetShowSidebarAction = createAction('SET_SHOW_SIDEBAR', (action) => (show: boolean) => action(show));

export const ResetUpdateEntitiesAction = createAction('RESET_UPDATE_ENTITIES');

export const SetlayerSelectedAction = createAction('SET_LAYER_SELECTED', (action) => (data: string) => action(data));

export const SetOrderSelectedAction = createAction('SET_ORDER_SELECTED', (action) => (data: string) => action(data));

export const SetHighlightedLayerAction = createAction('SET_HIGHLIGHTED_LAYER', (action) => (data: string) => action(data));

export const ResetEditorStateAction = createAction('RESET_EDITOR_STATE');

export const AddNewLayerAction = createAsyncAction(
  'ADD_NEW_LAYER_START',
  'ADD_NEW_LAYER_SUCCESS',
  'ADD_NEW_LAYER_FAILED',
)<null, any, string>();
