// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Button, LinearProgress, IconButton } from '@material-ui/core';
import Dropzone from 'Common/dropzone';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import uploadFilesIcon from 'Assets/upload-files-icon.png';
import compose, { PropsType } from './container';
import useStyles from './styles';

const MultipleFileUploadForm = ({ FileUploadStartAction, uploadFiles, FileUploadCancelAction, FileDeleteAction, anyFilesUploaded, onSuccess, editorFunctions, placeOrder, allFileUploadsComplete }: PropsType) => {
  const classes = useStyles();
  // Allow Airworks admin to upload any files and restrict file types for platform users based on permissions
  let acceptedExtensionsForPlatformUsers;

  if (editorFunctions && placeOrder) {
    acceptedExtensionsForPlatformUsers = [
      '.tiff',
      '.tif',
      '.las',
      '.laz',
      '.kml',
      // '.dxf'
    ];
  } else if (!editorFunctions && placeOrder) {
    acceptedExtensionsForPlatformUsers = ['.tiff', '.tif', '.las', '.laz', '.kml'];
  } else if (editorFunctions && !placeOrder) {
    acceptedExtensionsForPlatformUsers = ['.tiff', '.tif'];
  }

  // Check if Airworks Admin and assign accepted extensions accordingly
  const acceptedExtensions = _ADMIN_ ? ['.tiff', '.tif', '.las', '.kml', '.laz'] : acceptedExtensionsForPlatformUsers;
  const getBackgroundByExtension = (extension: string) => {
    switch (extension) {
      case 'las': return classes.backgroundAqua;
      case 'dxf': return classes.backgroundAqua;
      case 'laz': return classes.backgroundAqua;
      case 'kml': return classes.backgroundGold;
      default: return classes.backgroundMediumBlue;
    }
  };

  const messagingWithout2dEditorPermissions = `Upload 2D data (.tif or .tiff) and/or point cloud data (.las or .laz) for one site
    to get started. Multiple .tif, .tiff, .las, and .laz are permitted. .kml files are also welcome.`;

  const messagingWith2dEditorPermissions = 'Upload 2D data (.tif or .tiff) to get started with your CAD drawing';
  const messagingForFileUpload = editorFunctions ? messagingWith2dEditorPermissions : messagingWithout2dEditorPermissions

  const cancelUploadOrDeleteFile = (progress: number, id: string, fileName: string) => {
    if (progress === 100) {
      FileDeleteAction(id, fileName);
    } else {
      FileUploadCancelAction(id);
    }
  };
  // @ts-ignore
  return (
    <form className={classes.uploadForm}>
      <Typography variant="h1" className={classes.uploadFormHeader}>
        Upload Data Set
      </Typography>
      <Typography className={classes.dataTypes}>
        Upload your data (.tif, .tiff, .las, .laz, .kml) to kick off your AirWorks Order.
      </Typography>
      <div className={classes.outputConfidenceBorder}>
        <ErrorIcon className={classes.errorIcon} />
        <Typography className={classes.outputConfidence}>
          Our outputs are only as good as the data you provide us! Please take a few minutes to review our
          <a className={classes.anchorTag} target="_blank" href="http://support.airworks.io/Layer-Output-Confidence-Level.2381381633.html" onClick={() => { window?.pendo?.track('Clicked Output Confidence Link'); }} rel="noreferrer"> Layer Output Confidence Level.</a>
        </Typography>
      </div>
      <Dropzone acceptedExtensions={acceptedExtensions} onDropAccepted={(acceptedFilesArray) => FileUploadStartAction(acceptedFilesArray)}>
        {(getInputProps, open) => (
          <>
            <img src={uploadFilesIcon} alt="Upload files" className={classes.uploadFormIcon} />
            <br />
            <input {...getInputProps()} />
            <Typography variant="h5" className={classes.uploadFormText}>
              Drag and drop to upload
            </Typography>
            <br />
            <Typography variant="caption" className={classes.uploadFormText}>
              {'or '}
              <button type="button" className={classes.openFileDialogLink} onClick={open}>browse</button>
              {' to choose a file'}
            </Typography>
          </>
        )}
      </Dropzone>
      <Typography variant="body1" className={classes.formText}>
        {messagingForFileUpload}
      </Typography>
      <Typography variant="body1" className={classes.formText}>
        There are some coordinate reference systems that we don’t yet support.
        <a href="https://awkb.scrollhelp.site/airworksknowledgebase/coordinate-reference-system-crs" className={classes.anchorTag} target="_blank" rel="noreferrer"> Look up your CRS here.</a>
      </Typography>
      <Typography variant="body1" className={classes.formText}>
        Need data? Explore the new
        <a href="https://awkb.scrollhelp.site/airworksknowledgebase/the-airworks-data-marketplace" className={classes.anchorTag} target="_blank" rel="noreferrer"> AirWorks Data Marketplace!</a>
      </Typography>
      <div className={classes.progressSectionsWrapper}>
        {
          uploadFiles.map((file) => (
            <div key={file.id} className={classes.progressSection}>
              <div
                className={`${classes.fileExtension} ${getBackgroundByExtension(file.extension)}`}
              >
                <Typography variant="body1" className={classes.fileExtensionText}>
                  {`.${file.extension}`}
                </Typography>
              </div>
              <div className={classes.progress}>
                <div className={classes.progressText}>
                  <Typography variant="body1">
                    {file.name}
                  </Typography>
                </div>
                <LinearProgress
                  variant="determinate"
                  value={file.progress}
                  classes={{
                    barColorPrimary: getBackgroundByExtension(file.extension),
                  }}
                />
              </div>
              <div className={classes.progressButtons}>
                {
                  file.progress === 100 && (
                    <CheckIcon className={classes.finishedIcon} />
                  )
                }
                <IconButton className={classes.progressButton} onClick={() => cancelUploadOrDeleteFile(file.progress, file.id, file.name)}>
                  <ClearIcon className={classes.cancelIcon} />
                </IconButton>
              </div>
            </div>
          ))
        }
      </div>
      {/* Always enable the continue button, but when a file is being uploaded wait for upload to finish */}
      <Button
        onClick={async () => {
          onSuccess();
        }}
        variant="outlined"
        disabled={(!anyFilesUploaded && _ADMIN_) || (anyFilesUploaded && !allFileUploadsComplete)}
        className={classes.uploadFormSubmit}
      >
        Continue
      </Button>
    </form>
  );
};

export default compose(MultipleFileUploadForm);
