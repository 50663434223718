// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { EditLayerAttribute } from 'State/map/editor/thunk';

// import {ToggleProjectLayer} from "State/map/common/thunk";
// import {AddNewLayer, EditLayerAttribute, SetCurrentLayer, SetlayerSelected} from "State/map/editor/thunk";

const mapStateToProps = (state: IState) => ({
  layerSelected: state.map.editor.present.layerSelected,
  editorFunctions: 'editorFunctions' in state.auth.resources,
});

const mapDispatchToProps = {
  EditLayerAttributeAction: EditLayerAttribute,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default compose<PropsType>(
  connect(mapStateToProps, mapDispatchToProps),
);
