// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { SetTemplateId } from 'State/cadTemplate/thunk';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => ({
  template: state.cadTemplate.list?.find((item) => item._id === ownProps.templateId),
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    SetTemplateIdAction: SetTemplateId,
  }, dispatch);

interface IOwnProps {
  templateId: string;
  setLayersStyle: (fun: any) => void;
  setLayersWeight: (fun: any) => void;
  setLayersColor: (fun: any) => void;
  setLayersMapping: (fun: any) => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
