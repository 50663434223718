// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import LoadingSpinner from 'Common/LoadingSpinner';
import CreateMap from 'Common/Map';
import UploadKmlDialog from 'Common/FileUploadForm/SingleFileUploadForm';
import OpsTrainingDialogForm from 'Common/FileUploadForm/OpsTrainerFileUpload';
import EpsgProjection from 'Common/epsgProjection';
import Sidebar from './Sidebar';
import MeasurementOptions from './Sidebar/MeasurementOptions';
import compose from './container';

const Map = CreateMap('layers');

const Files = compose(({ classes, match, orders, opsTrainer, SetProjectIdAction, GetOrdersAction, GetProjectAction, CreateOrderAction, SetOrderSelectedAction }) => {
  const [loading, setLoading] = useState(true);

  const onLoad = async () => {
    const projectId = match.params.id;
    SetProjectIdAction(projectId);
    await GetOrdersAction(projectId);
    await GetProjectAction();
    // Do not try to find the active order or create a new order in this page since we don't need it in this component
    // const activeOrder = orders.find((o) => o.ownerProject === projectId && o.orderStatus === 0);
    // if (!activeOrder && !opsTrainer) {
    //   await CreateOrderAction(projectId);
    // }
    setLoading(false);
  };
  useEffect(() => {
    onLoad();
    return () => {
      SetProjectIdAction(null);
      SetOrderSelectedAction(null);
    };
  }, []);

  return (
    <LoadingSpinner loading={loading}>
      <Sidebar />
      <div className={classes.map} id="uploadDxfFormContainer">
        <MeasurementOptions />
        <Map />
        <EpsgProjection />
      </div>
      <OpsTrainingDialogForm containerId="uploadTrainingDxfFormContainer" fileExtension=".dxf" />
      <UploadKmlDialog containerId="uploadDxfFormContainer" fileExtension=".dxf" />
    </LoadingSpinner>
  );
});

export default Files;
