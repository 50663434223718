// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Typography, Snackbar, Button, FormGroup, FormControlLabel, Switch, CircularProgress, Dialog, Select, MuiThemeProvider, Chip, MenuItem, Checkbox } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import history from 'Utils/history';
import { clearLocalStorage } from 'Utils/localStorageService';
import { ACCOUNT_BILLING_ROUTE } from 'Utils/routes';
import { Link as RouterLink } from 'react-router-dom';
import { InputBaseWidth } from 'Utils/themes';
import { Form } from 'react-final-form';
import { TextField, Validators } from 'Common/forms';
import compose, { PropsType } from './container';
import useStyles from './styles';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'left',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'left',
  },
  // @ts-ignore
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '250',
    },
  },
};

export const UserForm = ({ initialValues, orgId, orgTeams, organizationAccess, cadSubscription, activeUser, showUserForm, userFormMode,
  GetOrgUsersAction, GetOrgAssignableRolesAction, CreateUserAction, EditOrgUserAction, GetOrgSubscriptionAction, GetOrgTeamsAction, setActiveUser, setShowUserForm }: PropsType) => {
  const classes = useStyles();
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);
  const [cadChecked, setCadChecked] = useState(false);
  const [cadChanged, setCadChanged] = useState(false);
  const [adminChecked, setAdminChecked] = useState(false);
  const [adminChanged, setAdminChanged] = useState(false);
  const [teamsChanged, setTeamsChanged] = useState(false);
  const [failedResultMessage, setFailedResultMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disableUpdateUserButton, setDisableUpdateUserButton] = useState(true);
  const [updatedRoles, setUpdatedRoles] = useState([]);
  const [emptySeatsAvailable, setEmptySeatsAvailable] = useState(false);

  useEffect(() => {
    setEmptySeatsAvailable(cadSubscription?.cadSeatsAvailable > cadSubscription?.cadSeatsTaken);
  }, [cadSubscription]);

  useEffect(() => {
    setCadChecked(activeUser.rolesArray.includes('cad'));
    setAdminChecked(activeUser.rolesArray.includes('admin'));
    if (!showUserForm) {
      setCadChecked(false);
      setCadChanged(false);
      setAdminChecked(false);
      setAdminChanged(false);
      setTeamsChanged(false);
      setLoading(false);
      setUpdatedRoles([]);
    } else {
      setShowError(false);
    }
  }, [showUserForm]);

  useEffect(() => {
    if (!organizationAccess) {
      clearLocalStorage();
      history.push('/');
    }
    const loadData = async () => {
      const orgIdInUrl = _ADMIN_ ? orgId : undefined;
      await GetOrgTeamsAction(orgIdInUrl);
      await Promise.all([
        GetOrgUsersAction(orgIdInUrl),
        GetOrgAssignableRolesAction(orgIdInUrl),
        GetOrgSubscriptionAction(orgIdInUrl),
      ]);
    };
    if (!loading) {
      loadData();
    }
  }, [loading]);

  const toggleChecked = () => {
    setCadChecked((prev) => !prev);
    const stateCopy = [...activeUser.rolesArray];
    if (!cadChecked) {
      stateCopy.push('cad');
    } else {
      const index = stateCopy.indexOf('cad');
      if (index > -1) {
        stateCopy.splice(index, 1);
      }
    }
    setUpdatedRoles(stateCopy);
    setActiveUser({ ...activeUser, rolesArray: stateCopy });
    setCadChanged(true);
    if (userFormMode !== 'add') setDisableUpdateUserButton(false);
  };

  const toggleAdminChecked = () => {
    const stateCopy = [...activeUser.rolesArray];
    if (!adminChecked) {
      stateCopy.push('admin');
    } else {
      const index = stateCopy.indexOf('admin');
      if (index > -1) {
        stateCopy.splice(index, 1);
      }
    }
    setAdminChecked(!adminChecked);
    setUpdatedRoles(stateCopy);
    setActiveUser({ ...activeUser, rolesArray: stateCopy });
    setAdminChanged(true);
    if (userFormMode !== 'add') setDisableUpdateUserButton(false);
  };

  const handleClick = (_id: string, name: string) => {
    const stateCopy = { ...activeUser };
    if (!activeUser.teams.some((el: ITeam) => el._id === _id)) {
      stateCopy.teams = activeUser.teams.concat({ _id, name });
      setActiveUser(stateCopy);
    } else {
      const filteredArray = activeUser.teams.filter((t: ITeam) => t._id !== _id);
      setActiveUser({ ...stateCopy, teams: filteredArray });
    }
    setTeamsChanged(true);
    if (userFormMode !== 'add') setDisableUpdateUserButton(false);
  };

  const handleUserFormSubmit = async (values: any) => {
    const result = userFormMode === 'add' ? await createUser(values) : await editUser(values);
    if (result.success) {
      GetOrgSubscriptionAction(_ADMIN_ ? orgId : undefined);
    } else {
      setShowError(true);
    }

    setShowUserForm(false);
  };

  const createUser = async (values: any) => {
    setLoading(true);
    const { email, firstName, lastName, teams } = values;

    const result = await CreateUserAction(values, _ADMIN_ ? orgId : undefined);

    if (result.success) {
      window?.pendo?.track('User Created', { first_name: firstName, last_name: lastName, teams, cad_checked: cadChecked, email });
      setShowSuccess(true);
    } else if (!result.success && result.message) {
      setFailedResultMessage(result.message);
    }

    return result;
  };

  const editUser = async (values: any) => {
    setLoading(true);
    const updatedTeams:any = activeUser.teams;
    const updatedUser = {
      ...values,
      updatedRoles: (cadChanged || adminChanged) ? updatedRoles : undefined,
      updatedTeams: teamsChanged ? updatedTeams : undefined,
    };
    const result = await EditOrgUserAction(updatedUser, _ADMIN_ ? orgId : undefined);

    if (result.success) {
      window?.pendo?.track('User Edited', { user_name: activeUser.fullName, email: activeUser.email, teams: activeUser.teams });
    }
    setDisableUpdateUserButton(true);
    return result;
  };

  const handleSuccessSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSuccess(false);
  };

  const handleErrorSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  const handleOnClose = () => {
    setShowUserForm(false);
    if (userFormMode !== 'add') setDisableUpdateUserButton(true);
  };

  return (
    <>
      <Snackbar className={classes.snackBar} open={showSuccess} autoHideDuration={10000} onClose={handleSuccessSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success" className={classes.alert} color="info">Invitation Sent</Alert>
      </Snackbar>
      <Snackbar className={classes.snackBar} open={showError} autoHideDuration={10000} onClose={handleErrorSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" className={classes.error} color="error">{ !failedResultMessage ? `Unable to ${userFormMode} User` : failedResultMessage }</Alert>
      </Snackbar>
      <Dialog open={showUserForm} onClose={handleOnClose} aria-labelledby="form-dialog-title">
        <Form
          onSubmit={handleUserFormSubmit}
          keepDirtyOnReinitialize
          initialValues={initialValues}
          render={({ pristine, hasSubmitErrors, submitting, handleSubmit }) => (
            <form
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <Typography variant="h2" className={classes.heading}>{userFormMode === 'add' ? 'Add User' : 'Edit User'}</Typography>
              <div className={classes.formRow}>
                <TextField
                  label="First Name"
                  name="firstName"
                  validate={Validators.required}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: classes.outline,
                      input: classes.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: classes.inputLabel,
                      shrink: classes.inputLabelShrink,
                    },
                  }}
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  validate={Validators.required}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: classes.outline,
                      input: classes.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: classes.inputLabel,
                      shrink: classes.inputLabelShrink,
                    },
                  }}
                />
              </div>
              <div>
                <TextField
                  label="Work Email"
                  name="email"
                  validate={Validators.composeValidators(Validators.required, Validators.email)}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: classes.outline,
                      input: classes.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: classes.inputLabel,
                      shrink: classes.inputLabelShrink,
                    },
                  }}
                />
              </div>
              <div className={classes.formRow}>
                <TextField
                  label="Job Title"
                  name="jobTitle"
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: classes.outline,
                      input: classes.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: classes.inputLabel,
                      shrink: classes.inputLabelShrink,
                    },
                  }}
                />
                <TextField
                  label="Phone Number"
                  name="phoneNumber"
                  validate={Validators.mobilePhone}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: classes.outline,
                      input: classes.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: classes.inputLabel,
                      shrink: classes.inputLabelShrink,
                    },
                  }}
                />
              </div>
              <Typography className={classes.acceessText} variant="h4">Teams</Typography>
              <MuiThemeProvider theme={InputBaseWidth}>
                <Select
                  classes={{
                    root: classes.selectTeams,
                  }}
                  displayEmpty
                  multiple
                  value={activeUser.teams.map((el: ITeam) => el.name)}
                  disableUnderline
                  renderValue={(selected: []) => {
                    if (selected.length === 0) {
                      return <em>Choose one or more teams</em>;
                    }
                    return (
                      <div className={classes.chips}>
                        {activeUser.teams.map((team: ITeam) => (
                          <Chip
                            size="medium"
                            classes={{ root: classes.chipRoot }}
                            onMouseDown={(event) => {
                              event.stopPropagation();
                            }}
                            key={team._id}
                            label={team.name}
                            className={classes.chip}
                          />
                        ))}
                        {activeUser.teams.length > 3 && (
                          <div className={classes.badge}>
                            +
                            {activeUser.teams.length - 3}
                          </div>
                        )}
                      </div>
                    );
                  }}
                  MenuProps={MenuProps}
                >
                  {orgTeams.length > 0 ? orgTeams.map((team) => (
                    <MenuItem
                      classes={{ selected: classes.selected }}
                      key={team._id}
                      onClick={() => handleClick(team._id, team.name)}
                      value={team.name}
                    >
                      <Checkbox classes={{ checked: classes.checked }} checked={activeUser.teams.filter((el: ITeam) => team.name === el.name).length > 0} />
                      {team.name}
                    </MenuItem>
    
                  )) : (
                    <MenuItem disabled value="">
                      <em>There are no teams to show</em>
                    </MenuItem>
                  )}
                </Select>
              </MuiThemeProvider>
              <div className={classes.modalText}>
                <Typography variant="h4" className={classes.acceessText}>Access</Typography>
                <Typography>Users that aren't assigned to a license are only prohibited from editing projects; these users can still view all projects and account info</Typography>
              </div>
              <FormGroup className={classes.checkboxes}>
                <FormControlLabel
                  control={<Switch checked={cadChecked} onChange={toggleChecked} disabled={!cadChecked && !emptySeatsAvailable} color="primary" />}
                  label="CAD"
                />
                <FormControlLabel
                  control={<Switch checked={adminChecked} onChange={toggleAdminChecked} color="primary" />}
                  label="ADMIN"
                />
              </FormGroup>
              {!emptySeatsAvailable && (
                <div>
                  <Typography className={classes.warningText}>All licenses are currently assigned.</Typography>
                  <Typography>
                    You can add more licenses to your plan anytime in the Billing section of your Account.
                    {' '}
                    <RouterLink to={ACCOUNT_BILLING_ROUTE} title="Subscriptions">
                      Upgrade Plan Now!
                    </RouterLink>
                  </Typography>
                </div>
              )}
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                type="submit"
                disabled={userFormMode === 'add' ? (hasSubmitErrors || submitting) : (pristine && disableUpdateUserButton)}
              >
                {userFormMode === 'add' ? 'ADD NEW USER' : 'UPDATE USER'}
              </Button>
              {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
            </form>
          )}
        />

      </Dialog>
    </>
  );
};

export default compose(UserForm);
