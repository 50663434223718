// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    fontWeight: 400,
    fontSize: '28px',
  },
  subSectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
  },
  sectionTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
  },
  billingColumn: {
    padding: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  icons: {
    display: 'flex',
  },
  iconWrapper: {
    marginRight: theme.spacing(1),
    width: theme.spacing(5),
  },
});

export default styles;
