// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IsProjectListPath, IsProjectPath, IsAccountPath, IsAdminListPath, IsAdminPath } from 'Utils/routes';
import { currentSubscription } from 'State/subscription/selectors';
import { CreateProject, UpdateProjectName } from 'State/project/thunk';
import { GetOrgSubscription } from 'State/subscription/thunk';
import { UpdateProjectNameAsync } from 'State/project/actions';

const mapStateToProps = (state: IState, pr: RouteComponentProps) => {
  const projectListMatch = IsProjectListPath(pr.history.location.pathname);
  const projectMatch = IsProjectPath(pr.history.location.pathname);
  const accountMatch = IsAccountPath(pr.history.location.pathname);
  const adminListMatch = IsAdminListPath(pr.history.location.pathname);
  const adminMatch = IsAdminPath(pr.history.location.pathname);

  const { user } = state.auth;
  let orgId;
  if (!_ADMIN_) {
    orgId = state.account.org._id;
  }

  const isAdmin = user?.roles?.includes('admin');
  const opsTrainer = !_ADMIN_ && 'opsTrainer' in state.auth.resources;

  let avatarText = null;
  if (accountMatch) {
    const { firstName, lastName } = user;
    const char1 = (firstName && firstName[0]) || '';
    const char2 = (lastName && lastName[0]) || '';
    avatarText = `${char1}${char2}`;
  }

  const { projectId, project } = state.project;

  const adminUser = state.admin.org;

  const title =
    (projectListMatch && 'My Projects') ||
    (projectMatch && project && (opsTrainer ? project.training.nameEditable : project.name)) ||
    (accountMatch && user.email) ||
    (adminListMatch && 'AirWorks Admin') ||
    (adminMatch && adminUser && `${adminUser.companyName}`);

  const isOnFreePlan = (adminMatch || adminListMatch) ? false : currentSubscription(state).isOnFreePlan;

  const { uploadFiles } = state.fileUpload;

  // for now we don't want to allow users to upload DXF while creating a new project
  // const hasDxf = uploadFiles
  //   .find((f) =>
  //     f.progress === 100
  //     && (f.extension === 'dxf'));
  // && (f.extension === 'tif' || f.extension === 'tiff'));

  const hasLas = uploadFiles
    .find((f) =>
      f.progress === 100
      && f.extension === 'las');

  const hasLaz = uploadFiles
    .find((f) =>
      f.progress === 100
      && f.extension === 'laz');

  const hasKml = uploadFiles
    .find((f) =>
      f.progress === 100
       && f.extension === 'kml');

  const result = {
    projectListMatch,
    projectMatch,
    projectId,
    accountMatch,
    adminMatch,
    title,
    avatarText,
    isOnFreePlan,
    isAdmin,
    adminListMatch,
    orgId,
    filesUploadedLasOrkml: Boolean(hasLas || hasLaz || hasKml),
    // filesUploadedDxf: Boolean(hasDxf),
    editorFunctions: !_ADMIN_ ? 'editorFunctions' in state.auth.resources : true,
    placeOrder: !_ADMIN_ ? 'placeOrder' in state.auth.resources : true,
    processingUsage: !_ADMIN_ && 'processingUsage' in state.auth.resources,
    createProject: !_ADMIN_ ? 'createProject' in state.auth.resources : true,
    isOnCadTrial: state.subscription?.cadSubscription?.trial,
    cadSubscription: state.subscription?.cadSubscription,
    automateSubscription: state.subscription?.automateSubscription,
  };

  return result;
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    CreateProjectAction: CreateProject,
    UpdateProjectNameAction: UpdateProjectName,
    GetOrgSubscriptionsAction: GetOrgSubscription,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
