// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { GetProjects } from 'State/project/thunk';
import { GetOrders, GetOrderStatus } from 'State/order/thunk';
import { GetPlans } from 'State/subscription/thunk';
import { GetUserProfile, GetOrgProfile } from 'State/account/thunk';
import { GetNotifications } from 'State/notification/thunk';
import { GetTemplates } from 'State/cadTemplate/thunk';
import { GetImageryProviders } from 'State/imagery/thunk';
import { getUser } from 'Utils/localStorageService';
import { OktaAdminLogin } from 'State/auth/thunk';

const mapStateToProps = (state: IState) => {
  const user = JSON.parse(getUser());
  const { org } = state.account;

  let opsTrainer = false;

  if (state?.auth?.resources) {
    opsTrainer = !_ADMIN_ && 'opsTrainer' in state?.auth?.resources;
  }
  return {
    user,
    org,
    opsTrainer,
    token: state.auth?.token,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetImageryProvidersAction: GetImageryProviders,
    GetProjectsAction: GetProjects,
    GetOrdersAction: GetOrders,
    GetOrderStatusAction: GetOrderStatus,
    GetUserProfileAction: GetUserProfile,
    GetOrgProfileAction: GetOrgProfile,
    GetPlansAction: GetPlans,
    GetNotificationsAction: GetNotifications,
    GetTemplateAction: GetTemplates,
    OktaAdminLoginAction: OktaAdminLogin,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapDispatchToProps>

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
