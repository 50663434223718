// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Button, Fade, CircularProgress, SnackbarContent, Collapse, Switch, Typography, Divider } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Form } from 'react-final-form';
import { TextField, Validators, Helpers, Select } from 'Common/forms';
import LoadingSpinner from 'Common/LoadingSpinner';
import states from 'Common/states';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const AccountGeneral = ({ initialValues, orgId, optOutOfEmails, OrgEmailsAction, GetOrgAction, UpdateOrgAction, SetOrgIdAction }: PropsType) => {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);
  const [initialState, setInitialState] = useState(undefined);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    SetOrgIdAction(orgId);

    const loadData = async () => {
      setShowLoader(true);
      await GetOrgAction(orgId);
      setShowLoader(false);
    };

    loadData();
  }, []);

  useEffect(() => {
    if (!initialState && initialValues) {
      setInitialState(initialValues);
    }
  }, [initialValues]);

  useEffect(() => {
    setChecked(optOutOfEmails);
  }, [optOutOfEmails]);
  const toggleChecked = () => {
    if (orgId) {
      OrgEmailsAction();
    }
  };

  const onFormSubmit = (values: any) => Helpers.submit({ _id: orgId, ...values }, UpdateOrgAction);
  return (
    <LoadingSpinner loading={showLoader}>
      <Form
        onSubmit={onFormSubmit}
        keepDirtyOnReinitialize
        initialValues={initialState}
        render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, submitError, submitSucceeded, handleSubmit }) => (
          <form
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <TextField
              label="Company Name"
              name="companyName"
              validate={Validators.required}
            />
            <TextField
              label="Address 1"
              name="address.addressLine1"
              validate={Validators.required}
            />
            <TextField
              label="Address 2"
              name="address.addressLine2"
              validate={Validators.required}
            />
            <div className={classes.formRow}>
              <TextField
                label="City"
                name="address.city"
                validate={Validators.required}
              />
              <Select
                label="State"
                name="address.state"
                values={states}
                validate={Validators.required}
              />
              <TextField
                label="Zip Code"
                name="address.zipCode"
                validate={Validators.required}
              />
            </div>
            <TextField
              label="Phone Number"
              name="phoneNumber"
              validate={Validators.composeValidators(Validators.required, Validators.mobilePhone)}
            />
            <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
              <SnackbarContent
                className={classes.errorSnackbar}
                message={(
                  <div className={classes.snackbarContent}>
                    <ErrorIcon />
                    {submitError}
                  </div>
                )}
              />
            </Collapse>
            <Collapse in={!submitting && submitSucceeded}>
              <SnackbarContent
                className={classes.successSnackbar}
                message={(
                  <div className={classes.snackbarContent}>
                    <CheckCircleIcon />
                    Update Successful!
                  </div>
                )}
              />
            </Collapse>
            <div className={classes.sectionWrapper}>
              <Typography variant="h2">Email Notifications</Typography>
              <Divider className={classes.divider} />
              <div className={classes.settingWrapper}>
                <Typography variant="h4">Disable WebApp Emails</Typography>
                <Switch checked={checked} onChange={toggleChecked} color="primary" />
              </div>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              type="submit"
              disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
            >
              <Fade in={submitting}>
                <CircularProgress size={24} className={classes.buttonProgress} />
              </Fade>
              <Fade in={!submitting}>
                <span>Save Changes</span>
              </Fade>
            </Button>
          </form>
        )}
      />
    </LoadingSpinner>
  );
};

export default compose(AccountGeneral);
