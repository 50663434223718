// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction } from 'typesafe-actions';

export const SetCanvasSizeAction = createAction('SET_CANVAS_SIZE', (action) => (data: [number, number]) => action(data));
export const SetScreenshotAction = createAction('SET_SCREENSHOT', (action) => (data: string) => action(data));

export const ZoomInAction = createAction('ZOOM_IN');
export const ZoomOutAction = createAction('ZOOM_OUT');
export const SetViewportAction = createAction('SET_VIEWPORT', (action) => (data: { zoom: number, center?: [number, number] }) => action(data));

export const ChangeVersionAction = createAction('CHANGE_VERSION');
export const ChangeRasterAction = createAction('CHANGE_RASTER');
export const SetAllRasterTilesLoadedAction = createAction('SET_ALL_RASTER_TILES_LOADED', (action) => (data: boolean) => action(data));
export const SetLowResCreatedAtAction = createAction('SET_LOW_RES_CREATED_AT', (action) => (data: string) => action(data));
export const SetDelayInRasterTileLoadAction = createAction('SET_DELAY_IN_RASTER_TILES_LOAD', (action) => (data: boolean) => action(data));
export const SetDelayInRasterTileLoadAcknowledgedAction = createAction('SET_DELAY_IN_RASTER_TILES_LOAD_ACKNOWLEDGE', (action) => (data: boolean) => action(data));

export const GetTileJsonStartAction = createAction('GET_TILEJSON_START', (action) => (data: TileJsonType) => action(data));
export const GetTileJsonStopAction = createAction('GET_TILEJSON_STOP', (action) => (data: TileJsonType) => action(data));
export const GetVectorTileJsonAction = createAction('GET_VECTOR_TILEJSON', (action) => (data: { [key: string]: { [key: string]: IVectorTileJson; } }) => action(data));
export const GetRasterTileJsonAction = createAction('GET_RASTER_TILEJSON', (action) => (data: { [key: string]: IRasterTileJson; }) => action(data));
export const ClearTileJsonAction = createAction('CLEAR_TILEJSON', (action) => (data: TileJsonType) => action(data));

export const ToggleOrthomosaicLayerAction = createAction('TOGGLE_ORTHOMOSAIC_LAYER');
export const ToggleBasemapLayerAction = createAction('TOGGLE_BASEMAP_LAYER');

export const ToggleCadDrawingsLayerAction = createAction('TOGGLE_CAD_DRAWINGS_LAYER', (action) => (data: { orderId: string, fileVersion: string }) => action(data));
export const ToggleProjectLayerAction = createAction('TOGGLE_PROJECT_LAYER', (action) => (data: { orderId: string, id: string, fileVersion: string }) => action(data));
export const AdminToggleCadDropdownAction = createAction('ADMIN_TOGGLE_CAD_DROPDOWN_ACTION', (action) => (data: { orderId: string, fileVersion: string }) => action(data));

export const ToggleNoneVectorTilesAction = createAction('TOGGLE_NONE_VECTOR_TILES');

export const SetMarkerAction = createAction('SET_MARKER', (action) => (data: [number, number]) => action(data));
export const ClearMarkerAction = createAction('CLEAR_MARKER');

export const SetMapStyleAction = createAction('SET_MAP_STYLE', (action) => (data: MapStyleType) => action(data));

export const SetLatAction = createAction('SET_LATITUDE', (action) => (data: number) => action(data));

export const SetLngAction = createAction('SET_LONGITUDE', (action) => (data: number) => action(data));

export const ResetLatAndLngAction = createAction('RESET_LAT_AND_LNG');

export const AddNewLayerToVTJAction = createAction('ADD_NEW_LAYER_TILEJSON', (action) => (data: { orderId: string, siteId: string, layer: IVectorLayer }) => action(data));

export const UpdateLayerVTJAction = createAction('UPDATE_LAYER_TILEJSON', (action) => (data: { orderId: string, siteId: string, layer: IVectorLayer }) => action(data));

export const DeleteLayerFromVTJAction = createAction('DELETE_LAYER_TILEJSON', (action) => (data: { orderId: string, siteId: string, layerId: Number }) => action(data));

export const NewDrawingToVTJAction = createAction('NEW_DRAWING_TO_VTJ', (action) => (data: { orderId: string, siteId: string }) => action(data));

export const SetDxfExtentAction = createAction('SET_DXF_EXTENT', (action) => (data: number[]) => action(data));

export const SetNewLargeTifAction = createAction('SET_NEW_LARGE_TIF');
export const SetLargeTifCount = createAction('SET_LARGE_TIF_COUNT', (action) => (data: number) => action(data));
export const SetLargeTifAcknowledgedAction = createAction('SET_LARGE_TIF_ACKNOWLEDGE', (action) => (data: boolean) => action(data));
export const ResetProjectTifInfoAction = createAction('RESET_PROJECT_TIF_INFO');
export const ResetProjectLasInfoAction = createAction('RESET_PROJECT_LAS_INFO');
export const SetLasBBoxAction = createAction('SET_LAS_BBOX', (action) => (data: [number, number, number, number]) => action(data));
export const SetLasBBoxLoadingAction = createAction('SET_LAS_BBOX_LOADING', (action) => (data: boolean) => action(data));
