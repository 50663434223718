// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, postJson, deleteRequest, patchJson } from 'Utils/http';
import history from 'Utils/history';
import { API_URL } from 'Config';
import { GetAdminProjectsRoute } from 'Utils/routes';
import { CreateOrderAsync } from 'State/order/actions';
import { SetSiteId } from 'State/map/editor/thunk';
import { GetRasterTiles, GetLasBBox } from 'State/map/common/thunk';
import { ResetProjectTifInfoAction, ResetProjectLasInfoAction } from 'State/map/common/actions';
import { SetOrderId } from 'State/order/thunk';
import { datadogRum } from '@datadog/browser-rum';
import { getProjectEPSGCode, getProjectFiles } from './selectors';
import {
  GetProjectsAsync, GetProjectAsync, CreateProjectAsync, DeleteProjectAsync,
  SetKeywordFilterAction, SetSortFilterAction, SetProjectIdAction, UpdateProjectNameAsync, UpdateProjectAction,
  DeleteTifFileAsync, SetFileStatusJsonAction, ChangeJsonAction,
  SetThumbnailAsync, SetWarningAcceptedAction,
  DeleteLasFileAsync,
  GetEpsgProjectionsAction,
  UpdateProjectTifFilesAction,
  UpdateProjectLasFilesAction,
  SetLoadAllAction,
  SetPageAction,
  SetProjectsAction,
  SetLoadNewAction,
  ResetEpsgProjectionsAction,
  SetOrderFilterAction,
  SetShowKmlToolbarAction,
  SendImageryUploadEmailAsync,
  CreateImageryRequestAsync,
  UpdateImageryRequestAsync,
} from './actions';

export const SetProjectId = (projectId: string) => (dispatch: Dispatch) => dispatch(SetProjectIdAction(projectId));
export const ChangeJson = () => (dispatch: Dispatch) => dispatch(ChangeJsonAction());

export const SetPage = (page: number) => (dispatch: Dispatch) => dispatch(SetPageAction(page));

export const SetProjects = (projects: IProject[]) => (dispatch: Dispatch) => dispatch(SetProjectsAction(projects));

export const SetLoadNew = (loadNew: boolean) => (dispatch: Dispatch) => dispatch(SetLoadNewAction(loadNew));

export const SetLoadAll = (loadAll: boolean) => (dispatch: Dispatch) => dispatch(SetLoadAllAction(loadAll));

// Gets projects for a given user
export const GetProjects = (orgId: string = '', limit: number = 9) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { page, keywordFilter, sortFilter, loadNew, orderSortFilter } = getState().project;

    const url = `${API_URL}/projects?page=${page}&limit=${limit}&query=${keywordFilter}&sort=${sortFilter}&orgId=${orgId}&filterby=${orderSortFilter || ''}`;
    const { token } = getState().auth;

    dispatch(GetProjectsAsync.request());

    const result = await getJson<IProjectResponse>(url, token);

    const keywordFilterNow = getState().project.keywordFilter;

    if (result.success) {
      if (keywordFilterNow !== result.data.keyword) return {};
      if (loadNew) {
        dispatch(SetProjectsAction([]));
      }
      dispatch(GetProjectsAsync.success({
        list: result.data.list,
        totalCount: result.data.totalCount,
      }));
      if (result.data.list.length === 0) {
        dispatch(SetLoadAllAction(true));
      }
    } else {
      dispatch(GetProjectsAsync.failure(result.message));
    }

    dispatch(SetLoadNewAction(false));

    return result;
  };

// Reload projects
export const ReloadProjects = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    SetLoadNew(true)(dispatch);
    SetLoadAll(false)(dispatch);
    SetPage(0)(dispatch);
    SetProjects([])(dispatch);
    GetProjects()(dispatch, getState);
  };

// Reset sort filter and search
export const ResetFilters = () =>
  async (dispatch: Dispatch) => {
    SetSortFilter('updatedAtD')(dispatch);
    SetKeywordFilter('')(dispatch);
  };

export const GetProject = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { projectId } = getState().project;
    if (projectId) {
      const url = `${API_URL}/projects/${projectId}`;
      const { token } = getState().auth;

      dispatch(GetProjectAsync.request());
      const result = await getJson<IProject>(url, token);
      if (result.success) {
        dispatch(GetProjectAsync.success(result.data.data));
        SetFileStatus()(dispatch, getState);
      } else {
        dispatch(GetProjectAsync.failure(result.message));
      }
      return result;
    }
    return {};
  };

export const SetFileStatus = (option? : string) =>
  (dispatch: Dispatch, getState: () => IState) => {
    if (option === 'reset') {
      dispatch(SetFileStatusJsonAction(null));
      return;
    }
    const { fileStatuses } = getState().project.project;
    const fileStatusJson = getState().project.fileStatusJson || {};
    const projectFiles = getProjectFiles(getState());

    projectFiles.forEach((file) => {
      if (!fileStatusJson[file.name]) {
        fileStatusJson[file.name] = { status: '', messages: [] };
      }
    });

    fileStatuses.forEach((s: any) => {
      if (!fileStatusJson[s.filename]) {
        fileStatusJson[s.filename] = { status: '', messages: [] };
      }
      fileStatusJson[s.filename].status = s.status;
      fileStatusJson[s.filename].messages = s.messages;
    });
    dispatch(SetFileStatusJsonAction(fileStatusJson));
    dispatch(ChangeJsonAction());
  };

export const SetWarningAccepted = () => (dispatch: Dispatch) => dispatch(SetWarningAcceptedAction());

export const SetKeywordFilter = (keyword: string) => (dispatch: Dispatch) => dispatch(SetKeywordFilterAction(keyword));

export const SetSortFilter = (sort: ProjectSortFilter) =>
  (dispatch: Dispatch) => {
    // setSortFilter(sort);
    dispatch(SetSortFilterAction(sort));
  };

export const SetOrderSortFilter = (filterby: ProjectOrderSortFilter) =>
  (dispatch: Dispatch) => {
    dispatch(SetOrderFilterAction(filterby));
  };

/**
 * API call to create a project
 * @param editorFunctions: Resource permission for editorFunctions
 * @param projectData
 * @returns Result containing the project details
 * @updates project, order and cadFile state
 */
export const CreateProject = (projectData: { name: string, description: string, referenceId?: string, anticipatedKickoffDate?: string }, editorFunctions: Boolean) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/projects`;
    const { user, token } = getState().auth;
    const { orgId } = getState().admin;

    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    const data = isAdmin ?
      { ...projectData, orgId } :
      { ...projectData, editorFunctions };

    dispatch(CreateProjectAsync.request());

    const result = await postJson<IProject>(url, data, token);
    if (!result.success) {
      dispatch(CreateProjectAsync.failure(result.message));
      return result;
    }

    // Update state for project, order and cadFiles
    dispatch(CreateProjectAsync.success(result.data));

    // datadog action - tracking user actions by passing custom attributes
    datadogRum.addAction('new_project', {
      'project.name': result?.data?.name,
      'usr.id': user?._id,
      'usr.email': user?.email,
      'usr.org': user?.ownerOrganization,
    });

    dispatch(CreateOrderAsync.success(result.data.order));
    if (editorFunctions) {
      SetSiteId(result.data.order.cadFiles[0]?._id);
      SetOrderId(result.data.order._id);
    }
    return result;
  };

// Deletes a project with a given projectId
export const DeleteProject = (projectId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/projects/${projectId}`;
    const { user, token } = getState().auth;
    const { orgId } = getState().admin;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));

    dispatch(DeleteProjectAsync.request(projectId));

    const result = await deleteRequest(url, token);
    if (result.success) {
      if (isAdmin) {
        history.push(GetAdminProjectsRoute(orgId));
        dispatch(DeleteProjectAsync.success(projectId));
      } else {
        // Reset filter and reload projects from database when delete a project on client portal
        ResetFilters()(dispatch);
        ReloadProjects()(dispatch, getState);
      }
    } else {
      dispatch(DeleteProjectAsync.failure(projectId));
    }
  };

// Updates name of a project with ID & New name of the project in the body
export const UpdateProjectName = (projectData: { _id: string, name: string }) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/projects/${projectData._id}`;
    const { token } = getState().auth;
    const opsTrainer = 'opsTrainer' in getState().auth.resources;
    dispatch(UpdateProjectNameAsync.request());

    // if the user is ops trainer update the nameEditable field else update project name
    const data = opsTrainer ? { nameEditable: projectData.name } : { name: projectData.name };

    const result = await patchJson<IProject>(url, data, token);
    if (result.success) {
      const payload = { ...result.data.task, opsTrainer };
      dispatch(UpdateProjectNameAsync.success(payload));
    }

    return result;
  };

export const SendImageryUploadEmail = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;

    const { project: { imageryRequest } } = getState().project;

    const url = `${API_URL}/imageryRequest/${imageryRequest._id}`;

    dispatch(SendImageryUploadEmailAsync.request());

    const data = { notificationSent: true };

    const result = await patchJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (result.success) {
      dispatch(SendImageryUploadEmailAsync.success(result.data?.imageryRequest));
    } else {
      dispatch(SendImageryUploadEmailAsync.failure(result.message));
    }

    return result;
  };

export const ToggleImageryProcessing = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;

    const { project: { imageryRequest } } = getState().project;

    const url = `${API_URL}/imageryRequest/${imageryRequest._id}`;

    dispatch(UpdateImageryRequestAsync.request());

    const data = { processImmediately: !imageryRequest.processImmediately };

    const result = await patchJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (result.success) {
      dispatch(UpdateImageryRequestAsync.success(result.data?.imageryRequest));
    } else {
      dispatch(UpdateImageryRequestAsync.failure(result.message));
    }

    return result;
  };

export const RejectImagery = (reason: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;

    const { project: { imageryRequest } } = getState().project;

    const url = `${API_URL}/imageryRequest/${imageryRequest._id}`;

    dispatch(UpdateImageryRequestAsync.request());

    const data = { rejectedReason: reason };

    const result = await patchJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (result.success) {
      dispatch(UpdateImageryRequestAsync.success(result.data?.imageryRequest));
    } else {
      dispatch(UpdateImageryRequestAsync.failure(result.message));
    }

    return result;
  };

export const CreateImageryRequest = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const { projectId, imageryVendorSelected, projectionSelected, imageryProcessing } = getState().project;

    const data = {
      provider: imageryVendorSelected,
      ownerProject: projectId,
      projection: projectionSelected,
      processImmediately: imageryProcessing,
    };

    const url = `${API_URL}/imageryRequest`;

    dispatch(CreateImageryRequestAsync.request());

    const result = await postJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (result.success) {
      dispatch(CreateImageryRequestAsync.success(result.data?.imageryRequest));
    } else {
      dispatch(CreateImageryRequestAsync.failure(result.message));
    }

    return result;
  };

// Updates files & project data
export const UpdateProject = (project: IProject) => (dispatch: Dispatch) => dispatch(UpdateProjectAction(project));

export const SetShowKmlToolbar = (showKmlToolBar: boolean) => (dispatch: Dispatch) => dispatch(SetShowKmlToolbarAction(showKmlToolBar));

// Deletes TIF file from a project
export const DeleteTifFile = (tifFileId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/projects/deleteTIF`;
    const { token } = getState().auth;
    const { projectId } = getState().project;
    const { orgId } = getState().admin;

    const data = {
      projectId,
      tifFileId,
    };

    const projectData = _ADMIN_ ?
      { ...data, orgId } :
      { ...data };

    dispatch(DeleteTifFileAsync.request());

    const result = await postJson<IProject>(url, projectData, token);
    if (result.success) {
      dispatch(DeleteTifFileAsync.success({ tifFileId, projectId }));
      dispatch(ResetProjectTifInfoAction());
      GetRasterTiles()(dispatch, getState);
    } else {
      dispatch(DeleteTifFileAsync.failure());
    }
  };

// Deletes LAS file from a project
export const DeleteLasFile = (lasFileId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/projects/deleteLAS`;
    const { token } = getState().auth;
    const { projectId } = getState().project;
    const { orgId } = getState().admin;

    const data = {
      projectId,
      lasFileId,
    };

    const projectData = _ADMIN_ ?
      { ...data, orgId } :
      { ...data };

    dispatch(DeleteTifFileAsync.request());

    const result = await postJson<IProject>(url, projectData, token);
    if (result.success) {
      dispatch(DeleteLasFileAsync.success({ lasFileId, projectId }));
      dispatch(ResetProjectLasInfoAction());
      GetLasBBox()(dispatch, getState);
    } else {
      dispatch(DeleteLasFileAsync.failure());
    }
  };

export const UpdateProjectTifFiles = (tifFile: ITifFile) => (dispatch: Dispatch) => dispatch(UpdateProjectTifFilesAction(tifFile));

export const UpdateProjectLasFiles = (lasFile: ILasFile) => (dispatch: Dispatch) => dispatch(UpdateProjectLasFilesAction(lasFile));

// Download TIF
export const DownloadTIF = (filename: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else if ('opsTrainer' in getState().auth.resources) {
      const project = getState().project.list.find((p) => p._id === projectId);
      orgId = project.ownerOrganization;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }

    const url = `${API_URL}/downloadTIF/${orgId}/${projectId}/${filename}`;
    const result = await getJson<{preSignedUrl: string, size: number}>(url, token);
    if (result.success) {
      window.open(result.data.preSignedUrl, '_self');
      window?.pendo?.track('Download Input Data', {
        filetype: 'tif',
        filesize: `${(result.data.size / 1000000).toFixed(2)} MB`,
      });
    }
  };

// Read thumbnail with a given projectId
export const readThumbnail = (projectId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    let orgId;
    if ('opsTrainer' in getState().auth.resources) {
      const project = getState().project.list.find((p) => p._id === projectId);
      orgId = project.ownerOrganization;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }
    const url = `${API_URL}/readThumbnail/${orgId}/${projectId}`;
    const result = await getJson<string>(url, token);

    if (result.success) {
      const obj = { projectId, thumbnail: result.data };
      dispatch(SetThumbnailAsync.success(obj));
    } else {
      dispatch(SetThumbnailAsync.failure());
    }
  };

export const GetEpsgProjections = (epsgCode: number) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    if (epsgCode) {
      const url = `${API_URL}/projects/getEpsgProjections/${epsgCode}`;
      const { token } = getState().auth;

      const result = await getJson<IEPSG>(url, token);
      if (result.success) {
        dispatch(GetEpsgProjectionsAction({ ...result.data, epsgCode }));
      } else {
        dispatch(ResetEpsgProjectionsAction());
      }
      return result;
    }
    return {};
  };

export const GetProjectEpsgCode = (projectId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/projects/epsg/${projectId}`;
    const { token } = getState().auth;

    const result = await getJson<{ tifWithEpsg: ITifFile, lasWithEpsg: ILasFile }>(url, token);
    if (result.success) {
      const { tifWithEpsg, lasWithEpsg } = result.data;
      const { tifEpsg, lasEpsg } = getProjectEPSGCode(getState());
      if (tifWithEpsg && !tifEpsg) {
        dispatch(UpdateProjectTifFilesAction(tifWithEpsg));
      }
      if (lasWithEpsg && !lasEpsg) {
        dispatch(UpdateProjectLasFilesAction(lasWithEpsg));
      }
      return result.data;
    }
    return null;
  };
