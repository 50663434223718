// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const LoadListAsync = createAsyncAction(
  'LOAD_LIST_START',
  'LOAD_LIST_SUCCESS',
  'LOAD_LIST_FAILED',
)<null, { list: IOrg }, string>();

export const SetPageAction = createAction('SET_PAGE', (action) => (data: number) => action(data));

export const SetListAction = createAction('SET_LIST', (action) => (data: Array<IAdminUser>) => action(data));

export const SetLoadAllAction = createAction('SET_LOAD_ALL', (action) => (data: boolean) => action(data));

export const SetLoadNewAction = createAction('SET_LOAD_NEW', (action) => (data: boolean) => action(data));

export const UpdateCostPerAcreAction = createAction('UPDATE_COST_PER_ACRE', (action) => (data: number) => action(data));

export const GetOrgProjectsAsync = createAsyncAction(
  'GET_ORG_PROJECTS_START',
  'GET_ORG_PROJECTS_SUCCESS',
  'GET_ORG_PROJECTS_FAILED',
)<null, IAdminProject[], null>();

export const DeleteProjectAdminAsync = createAsyncAction(
  'DELETE_PROJECT_ADMIN_START',
  'DELETE_PROJECT_ADMIN_SUCCESS',
  'DELETE_PROJECT_ADMIN_FAILED',
)<null, string, null>();

export const SetRowsPerPageAction = createAction(
  'SET_ROWS_PER_PAGE',
  (action) => (data: number) => action(data),
);

export const SetSearchFilterAction = createAction(
  'SET_ADMIN_SEARCH_FILTER',
  (action) => (data: string) => action(data),
);

export const SetSortFilterAction = createAction(
  'SET_ADMIN_SORT_FILTER',
  (action) => (data: string) => action(data),
);

export const GetSubscriptionAsync = createAsyncAction(
  'GET_ORG_SUBSCRIPTION_START',
  'GET_ORG_SUBSCRIPTION_SUCCESS',
  'GET_ORG_SUBSCRIPTION_FAILED',
)<null, ISubscriptions, string>();

export const GetOrgAsync = createAsyncAction(
  'GET_ADMIN_ORG_START',
  'GET_ADMIN_ORG_SUCCESS',
  'GET_ADMIN_ORG_FAILED',
)<null, IOrg, string>();

export const UpdateOrgAsync = createAsyncAction(
  'UPDATE_ADMIN_ORG_START',
  'UPDATE_ADMIN_ORG_SUCCESS',
  'UPDATE_ADMIN_ORG_FAILED',
)<null, IOrg, string>();

export const GetUsersAsync = createAsyncAction(
  'GET_USERS_START',
  'GET_USERS_SUCCESS',
  'GET_USERS_FAILED',
)<null, IUser[], string>();

export const GetOrgUserAsync = createAsyncAction(
  'GET_ORG_USER_START',
  'GET_ORG_USER_SUCCESS',
  'GET_ORG_USER_FAILED',
)<null, IUser, string>();

export const SetOrgIdAction = createAction('SET_ADMIN_ORG_ID', (action) => (data: string) => action(data));

export const SetUserIdAction = createAction('SET_ADMIN_USER_ID', (action) => (data: string) => action(data));

export const CreateOrgAsync = createAsyncAction(
  'CREATE_ORG_START',
  'CREATE_ORG_SUCCESS',
  'CREATE_ORG_FAILED',
)<null, IOrg, string>();

export const OrgEmailsAsync = createAsyncAction(
  'ORG_EMAILS_START',
  'ORG_EMAILS_SUCCESS',
  'ORG_EMAILS_FAILED',
)<null, boolean, string>();

export const GetLastLoginAsync = createAsyncAction(
  'GET_LAST_LOGIN_START',
  'GET_LAST_LOGIN_SUCCESS',
  'GET_LAST_LOGIN_FAILED',
)<null, { lastLogin: string }, string>();

export const GetLoginStatsAsync = createAsyncAction(
  'GET_LOGIN_STATS_START',
  'GET_LOGIN_STATS_SUCCESS',
  'GET_LOGIN_STATS_FAILED',
)<null, { thisMonth: thisMonthStats, perMonth: perMonthStats }, string>();

export const DeleteOrgAsync = createAsyncAction(
  'DELETE_ORG_START',
  'DELETE_ORG_SUCCESS',
  'DELETE_ORG_FAILED',
)<string, string, string>();
