// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { ListItem, FormControlLabel, Typography, Checkbox } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import { deliverableCost } from 'Utils/deliverables';
import compose from './container';

const DeliverableSelect = compose(({ classes, bundleName, bundleId, additionalDeliverables, selectedAdditionals, deliverablesBoolean, topographyDeliverableBoolean, order, onAddOnsClick }) => {
  const { breaklinesAddon } = useFlags();
  let additionalDeliverablesFiltered = additionalDeliverables;

  if (breaklinesAddon && bundleId === 5) {
    additionalDeliverablesFiltered = additionalDeliverables.filter((del) => (del.deliverableId !== 10 && del.deliverableId !== 52));
  } else if (breaklinesAddon && bundleId === 1) {
    additionalDeliverablesFiltered = additionalDeliverables.filter((del) => (del.deliverableId !== 51));
  } else if (bundleId === 5) {
    additionalDeliverablesFiltered = additionalDeliverables.filter((del) => (del.deliverableId !== 10 && del.deliverableId !== 51 && del.deliverableId !== 52));
  } else {
    additionalDeliverablesFiltered = additionalDeliverables.filter((del) => (del.deliverableId !== 51 && del.deliverableId !== 52));
  }

  return (
    <div className={classes.addons}>
      <Typography className={classes.addonsHeading} variant="h3">
        Additional Deliverables
      </Typography>
      <Typography>Please select any additional deliverables you’d like to receive for your project.</Typography>
      {bundleId === 4 && (
        <Typography className={classes.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add the disabled deliverables at checkout
        </Typography>
      )}
      {deliverablesBoolean && (
        <div>
          <Typography className={classes.addonsHeading} variant="h4">
            Deliverables
          </Typography>
          {additionalDeliverablesFiltered.map((item) =>
            ((item.type === 'deliverable' && item.subtype === 'Deliverables') ? (
              <div className={((bundleName === '2D' && item.requirements.includes('1'))
              || (bundleId === 4 &&
                  (item.deliverableId === 12 ||
                    item.deliverableId === 13 ||
                    item.deliverableId === 15)) || (bundleId === 5 && item.deliverableId === 11)) && classes.unavailableDeliverables}
              >
                {bundleName === '2D' && item.requirements.includes('1') && (
                  <Typography className={classes.noDeliverableMessage}>
                    Please select the 2D + TOPO bundle to add
                    {' '}
                    {item.name}
                    {' '}
                    at checkout
                  </Typography>
                )}
                <ListItem button disableRipple className={classes.listItem}>
                  <FormControlLabel
                    label={(
                      <div>
                        <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                      </div>
                    )}
                    control={(
                      <Checkbox
                        classes={{
                          checked: classes.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography className={classes.right} component="span">
                    &nbsp;
                    {' '}
                    {`${deliverableCost(item.acreFactor, order.acreage).toFixed(2)} credits`}
                  </Typography>
                </ListItem>
              </div>
            ) : ''))}
        </div>
      )}
      {topographyDeliverableBoolean && (
        <div className={(bundleId === 0) && classes.unavailableDeliverables}>
          <Typography className={classes.addonsHeading} variant="h4">
            Topography
          </Typography>
          {(bundleId === 0) && (
            <Typography className={classes.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Topography features at checkout
            </Typography>
          )}
          {additionalDeliverablesFiltered.map((item) =>
            ((item.type === 'deliverable' && item.subtype === 'Topography') ? (
              <div className={(!item.selectable || !(bundleId !== 5 || (bundleId === 5 && item.deliverableId === 51))) && classes.unavailableDeliverables}>
                <ListItem button disableRipple className={classes.listItem}>
                  <FormControlLabel
                    className={classes.formControl}
                    label={(
                      <div>
                        <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                      </div>
                    )}
                    control={(
                      <Checkbox
                        classes={{
                          checked: classes.checked,
                        }}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  {item.deliverableId === 53 && <OpenInNewIcon className={classes.icon} onClick={() => window.open('http://support.airworks.io/Breaklines-Powered-by-DataSight.2636414981.html', '_blank')} />}
                  <Typography className={classes.right} component="span">
                      &nbsp;
                    {' '}
                    {`${deliverableCost(item.acreFactor, order.acreage).toFixed(2)} credits`}
                  </Typography>
                </ListItem>
              </div>
            ) : null))}
        </div>
      )}
    </div>
  );
});

export default DeliverableSelect;
