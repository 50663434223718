// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { GetOrgUsers, GetOrgAssignableRoles, EditOrgUser, GetOrgTeams, CreateUser } from 'State/account/thunk';
import { GetOrgSubscription } from 'State/subscription/thunk';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => {
  const { orgId } = ownProps.match.params;

  return ({
    initialValues: ownProps.activeUser,
    orgId,
    orgTeams: state.account.orgTeams,
    organizationAccess: _ADMIN_ ? true : 'organizationAccess' in state.auth.resources,
    cadSubscription: _ADMIN_ ? state.admin.cadSubscription : state.subscription?.cadSubscription,
  });
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetOrgUsersAction: GetOrgUsers,
    GetOrgAssignableRolesAction: GetOrgAssignableRoles,
    CreateUserAction: CreateUser,
    EditOrgUserAction: EditOrgUser,
    GetOrgSubscriptionAction: GetOrgSubscription,
    GetOrgTeamsAction: GetOrgTeams,
  }, dispatch);

interface IOwnProps extends RouteComponentProps<{ orgId: string }> {
  showUserForm: boolean;
  setShowUserForm: (show: boolean) => void;
  activeUser: IActiveUser;
  setActiveUser: (activeUser: IActiveUser) => void;
  userFormMode: string;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
