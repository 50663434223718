// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dropdownText: {
    padding: '4.5px 9px',
  },
  inputControls: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '20px 40px',
    outline: '1px solid #E4E4E6',
    borderRadius: '4pt',
    padding: '15px',
  },
  inputData: {
    display: 'flex',
    width: '100%',
    padding: '0 25px',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  divider: {
    margin: '10px 10px 30px 10px',
  },
  imageryTitle: {
    marginLeft: '10px',
  },
  files: {
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '20px',
  },
  fileInfo: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(1),
  },
  buttons: {
    display: 'flex',
  },
  requested: {
    color: '#243FDD',
  },
  topBarRequested: {
    borderTop: '5px solid #243FDD',
  },
  rejected: {
    color: '#FF4D6A',
  },
  topBarRejected: {
    borderTop: '5px solid #FF4D6A',
  },
  uploaded: {
    color: '#6ADAD4',
  },
  topBarUploaded: {
    borderTop: '5px solid #6ADAD4',
  },
  projectListContainer: {
    display: 'flex',
    position: 'relative',
  },
  downloadButtonContainer: {
    position: 'absolute',
    marginLeft: '85%',
  },
  sidebarContent: {
    position: 'relative',
    flex: 1,
    overflow: 'auto',
    paddingTop: theme.spacing(3),
  },
  buttonProgress: {
    marginRight: theme.spacing(1),
  },
  emailButtonProgress: {
    position: 'absolute',
    margin: '2px auto',
  },
  upperSidebarContent: {
    display: 'flex',
  },
  iconButton: {
    padding: '6px 12px',
  },
  deleteButtonContainer: {
    height: '90%',
    flexDirection: 'column',
    alignItems: 'start',
    display: 'flex',
    justifyContent: 'space-between',
  },
  deleteButton: {
    display: 'flex',
    color: '#F95264',
    paddingLeft: '50px',
    alignItems: 'center',
  },
  deleteButtonText: {
    color: '#F95264',
  },
  downloadButton: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    minWIdth: 0,
    textTransform: 'uppercase',
  },
}));

export default useStyles;
