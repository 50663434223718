// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { getProjectFiles } from 'State/project/selectors';
import styles from './styles';

const mapStateToProps = (state: IState) => {
  const { fileStatusJson } = state.project;
  return {
    fileStatuses: fileStatusJson,
    projectFiles: getProjectFiles(state),
  };
};

interface IOwnProps {
    showDialog: boolean;
    onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    contentText?: string;
}

type PropsType =
    ReturnType<typeof mapStateToProps> &
    WithStyles<typeof styles> &
    IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, null),
);
