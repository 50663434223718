// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction } from 'typesafe-actions';

export const SetSelectionAction = createAction('SET_SELECTION', (action) =>
  // tslint:disable-next-line: array-type
  (data: { points: GeoJSON.FeatureCollection<GeoJSON.Point>[] }) => action(data));

export const SetModeAction = createAction('SET_MODE', (action) => (data: { name: string, params?: { featureId: string } }) => action(data));

export const SetMeasurementModeAction = createAction('SET_MEASUREMENT_MODE');

export const SetCurrentMeasurementOptionAction = createAction('SET_CURRENT_OPTION', (action) => (name: string) => action(name));

export const SetFirstTimeChangeEstimateAction = createAction('SET_FIRST_TIME_CHANGE_ESTIMATE', (action) => (data: boolean) => action(data));

export const UpdateKmlFeatureAction = createAction('UPDATE_KML_FEATURE', (action) => (data: GeoJSON.Feature) => action(data));

export const DeleteKmlFeatureAction = createAction('DELETE_KML_FEATURE', (action) => (data: GeoJSON.Feature) => action(data));

export const SetCreateKmlAction = createAction('SET_CREATE_KML', (action) => (data: boolean) => action(data));

export const SetKmlLineDrawAction = createAction('SET_KML_LINE_DRAW_MODE', (action) => (data: boolean) => action(data));

export const UploadingDrawnKmlAction = createAction('SET_UPLOADING_DRAWN_KML_ACTION', (action) => (data: boolean) => action(data));

export const ResetKmlDrawAction = createAction('RESET_KML_DRAW');

export const ClearDrawnKmlAction = createAction('CLEAR_DRAWN_KML');
