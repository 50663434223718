// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  sidebarContent: {
    flex: 1,
    overflow: 'auto',
    paddingTop: theme.spacing(3),
  },
  createTemplateButtonWrapper: {
    bottom: 0,
    width: '100%',
    padding: theme.spacing(2),
  },
  addIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: '32px',
  },
  addTemplateButton: {
    width: '100%',
    justifyContent: 'left',
  },
}));

export default useStyles;
