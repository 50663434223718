// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { GetOrg, UpdateOrg, SetOrgId, OrgEmails } from 'State/admin/thunk';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps<{ orgId: string }>) => ({
  initialValues: state.admin.org,
  optOutOfEmails: state.admin.org?.optOutOfEmails,
  orgId: ownProps.match.params.orgId,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetOrgAction: GetOrg,
    UpdateOrgAction: UpdateOrg,
    SetOrgIdAction: SetOrgId,
    OrgEmailsAction: OrgEmails,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ orgId: string }>;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
