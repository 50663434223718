// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  SetCanvasSizeAction,
  SetViewportAction, ZoomInAction, ZoomOutAction,
  GetTileJsonStartAction, GetTileJsonStopAction, GetRasterTileJsonAction, GetVectorTileJsonAction, ClearTileJsonAction,
  SetMarkerAction, ClearMarkerAction, SetMapStyleAction, ChangeRasterAction, AddNewLayerToVTJAction, UpdateLayerVTJAction,
  ToggleBasemapLayerAction, ToggleOrthomosaicLayerAction, ToggleCadDrawingsLayerAction, ToggleProjectLayerAction, ChangeVersionAction,
  SetLatAction, SetLngAction, ResetLatAndLngAction, ToggleNoneVectorTilesAction, AdminToggleCadDropdownAction, DeleteLayerFromVTJAction,
  NewDrawingToVTJAction, SetAllRasterTilesLoadedAction, SetLowResCreatedAtAction, SetDelayInRasterTileLoadAction, SetDelayInRasterTileLoadAcknowledgedAction,
  SetScreenshotAction, SetNewLargeTifAction, SetLargeTifCount, SetLargeTifAcknowledgedAction,
  SetDxfExtentAction, ResetProjectTifInfoAction, ResetProjectLasInfoAction, SetLasBBoxAction, SetLasBBoxLoadingAction,
} from './actions';

const initialState: ICommonState = {
  viewport: {
    center: [-100, 40],
    zoom: 4,
  },
  canvasSize: null,
  layers: {
    orthomosaic: true,
    baseMap: true,
  },
  latitude: null,
  longitude: null,
  changeVersion: false,
  changeRaster: false,
  rasterTileJson: null,
  lowResCreatedAt: null,
  allRasterTilesLoaded: false,
  delayInRasterTileLoad: false,
  delayInRasterTileLoadAcknowledged: false,
  newLargeTif: false,
  largeTifCount: 0,
  largeTifAcknowledged: false,
  vectorTileJson: null,
  marker: null,
  mapStyle: 'streets',
  rasterTileJsonLoading: false,
  vectorTileJsonLoading: false,
  screenshot: null,
  dxfExtent: null,
  lasBBox: null,
  lasBBoxLoading: false,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(SetCanvasSizeAction, (draft, payload) => { draft.canvasSize = payload; });
  handleAction(SetScreenshotAction, (draft, payload) => { draft.screenshot = payload; });

  handleAction(SetViewportAction, (draft, payload) => { Object.assign(draft.viewport, payload); });
  handleAction(ZoomInAction, (draft) => { draft.viewport.zoom += 1; });
  handleAction(ZoomOutAction, (draft) => { draft.viewport.zoom -= 1; });

  handleAction(ChangeVersionAction, (draft) => { draft.changeVersion = !draft.changeVersion; });

  handleAction(SetAllRasterTilesLoadedAction, (draft, payload) => { draft.allRasterTilesLoaded = payload; });
  handleAction(SetNewLargeTifAction, (draft) => { draft.newLargeTif = true; });
  handleAction(SetLargeTifCount, (draft, payload) => { draft.largeTifCount = payload; });
  handleAction(SetLargeTifAcknowledgedAction, (draft, payload) => { draft.largeTifAcknowledged = payload; });

  handleAction(SetLowResCreatedAtAction, (draft, payload) => { draft.lowResCreatedAt = payload; });
  handleAction(SetDelayInRasterTileLoadAction, (draft, payload) => { draft.delayInRasterTileLoad = payload; });
  handleAction(SetDelayInRasterTileLoadAcknowledgedAction, (draft, payload) => { draft.delayInRasterTileLoadAcknowledged = payload; });

  handleAction(ChangeRasterAction, (draft) => { draft.changeRaster = !draft.changeRaster; });

  handleAction(GetTileJsonStartAction, (draft, payload) => {
    if (payload === 'raster') {
      if (draft.newLargeTif && !draft.largeTifAcknowledged) {
        draft.rasterTileJsonLoading = false;
      } else {
        draft.rasterTileJsonLoading = true;
      }
    } else {
      draft.vectorTileJsonLoading = true;
    }
  });

  handleAction(ResetProjectTifInfoAction, (draft) => {
    draft.rasterTileJson = null;
    draft.rasterTileJsonLoading = false;
    draft.allRasterTilesLoaded = false;
    draft.lowResCreatedAt = null;
    draft.delayInRasterTileLoad = false;
    draft.delayInRasterTileLoadAcknowledged = false;
    draft.newLargeTif = false;
    draft.largeTifCount = 0;
    draft.largeTifAcknowledged = false;
  });

  handleAction(ResetProjectLasInfoAction, (draft) => {
    draft.lasBBox = null;
    draft.lasBBoxLoading = false;
  });

  handleAction(GetRasterTileJsonAction, (draft, payload) => {
    if (!draft.rasterTileJson) {
      draft.rasterTileJson = payload;
    } else {
      Object.assign(draft.rasterTileJson, payload);
    }
  });

  handleAction(GetTileJsonStopAction, (draft, payload) => {
    if (payload === 'raster') {
      draft.rasterTileJsonLoading = false;
    } else {
      draft.vectorTileJsonLoading = false;
    }
  });

  handleAction(GetVectorTileJsonAction, (draft, payload) => {
    if (!draft.vectorTileJson) {
      draft.vectorTileJson = payload;
    } else if (Object.keys(draft.vectorTileJson).includes(Object.keys(payload)[0])) {
      Object.assign(draft.vectorTileJson[Object.keys(payload)[0]], payload[Object.keys(payload)[0]]);
    } else {
      Object.assign(draft.vectorTileJson, payload);
    }
  });

  handleAction(ClearTileJsonAction, (draft, payload) => {
    if (payload === 'raster') {
      draft.rasterTileJson = null;
      draft.allRasterTilesLoaded = false;
      draft.lowResCreatedAt = null;
      draft.delayInRasterTileLoad = false;
      draft.delayInRasterTileLoadAcknowledged = false;
    } else if (payload === 'vector') {
      draft.vectorTileJson = null;
    }
  });

  handleAction(SetMarkerAction, (draft, payload) => { draft.marker = payload; });
  handleAction(ClearMarkerAction, (draft) => { draft.marker = null; });

  handleAction(SetLasBBoxAction, (draft, payload) => { draft.lasBBox = payload; });

  handleAction(SetLasBBoxLoadingAction, (draft, payload) => { draft.lasBBoxLoading = payload; });

  handleAction(SetMapStyleAction, (draft, payload) => { draft.mapStyle = payload; });

  handleAction(ToggleBasemapLayerAction, (draft) => { draft.layers.baseMap = !draft.layers.baseMap; });
  handleAction(ToggleOrthomosaicLayerAction, (draft) => { draft.layers.orthomosaic = !draft.layers.orthomosaic; });
  handleAction(ToggleCadDrawingsLayerAction, (draft, payload) => {
    const { orderId, fileVersion } = payload;

    switch (draft.vectorTileJson[orderId][fileVersion].count) {
    case (draft.vectorTileJson[orderId][fileVersion].count = 0):
      draft.vectorTileJson[orderId][fileVersion].count = 1;
      draft.vectorTileJson[orderId][fileVersion].vector_layers.forEach(
        (layer) => { layer.visible = !layer.visible; },
      );
      break;
    case (draft.vectorTileJson[orderId][fileVersion].count = 1):
      if (draft.vectorTileJson[orderId][fileVersion].visible) {
        draft.vectorTileJson[orderId][fileVersion].vector_layers.forEach(
          (layer) => { layer.visible = true; },
        );
      }
      draft.vectorTileJson[orderId][fileVersion].count = 0;
      break;
    default:
      draft.vectorTileJson[orderId][fileVersion].count = 0;
    }
  });

  handleAction(ToggleNoneVectorTilesAction, (draft) => {
    Object.keys(draft.vectorTileJson || {}).forEach((orderId) => {
      Object.keys(draft.vectorTileJson[orderId]).forEach((fileVersion) => {
        draft.vectorTileJson[orderId][fileVersion].visible = false;
      });
    });
  });

  handleAction(AdminToggleCadDropdownAction, (draft, payload) => {
    const { orderId, fileVersion } = payload;
    Object.keys(draft.vectorTileJson).forEach((oid) => {
      Object.keys(draft.vectorTileJson[oid]).forEach((version) => {
        draft.vectorTileJson[oid][version].visible = false;
      });
    });
    if (draft.vectorTileJson[orderId] && draft.vectorTileJson[orderId][fileVersion]) {
      draft.vectorTileJson[orderId][fileVersion].visible = true;
    }
  });

  handleAction(ToggleProjectLayerAction, (draft, payload) => {
    const { orderId, fileVersion } = payload;
    const layer = draft.vectorTileJson[orderId][fileVersion].vector_layers.find((l) => l.id === payload.id);
    layer.visible = !layer.visible;
  });

  handleAction(SetLatAction, (draft, payload) => { draft.latitude = payload; });
  handleAction(SetLngAction, (draft, payload) => { draft.longitude = payload; });

  handleAction(ResetLatAndLngAction, (draft, payload) => {
    draft.latitude = null;
    draft.longitude = null;
  });

  handleAction(AddNewLayerToVTJAction, (draft, payload) => {
    const { orderId, siteId } = payload;
    draft.vectorTileJson[orderId][siteId].vector_layers.unshift(payload.layer);
  });

  handleAction(UpdateLayerVTJAction, (draft, payload) => {
    const { orderId, siteId } = payload;
    const layerObj = draft.vectorTileJson[orderId][siteId].vector_layers.find((layer) => layer.layerId === payload.layer.layerId);
    Object.assign(layerObj, payload.layer);
  });

  handleAction(DeleteLayerFromVTJAction, (draft, payload) => {
    const { orderId, siteId, layerId } = payload;
    const layerIdx = draft.vectorTileJson[orderId][siteId].vector_layers.findIndex((layer) => layer.layerId === layerId);
    if (layerIdx > -1) {
      draft.vectorTileJson[orderId][siteId].vector_layers.splice(layerIdx, 1);
    }
  });

  handleAction(NewDrawingToVTJAction, (draft, payload) => {
    const { orderId, siteId } = payload;
    draft.vectorTileJson[orderId] = {};
    draft.vectorTileJson[orderId][siteId] = {
      vector_layers: [],
      visible: true,
      count: 0,
    };
  });

  handleAction(SetDxfExtentAction, (draft, payload) => {
    draft.dxfExtent = payload;
  });
});
