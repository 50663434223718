// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';

import { HideUploadKmlDialog } from 'State/ui/uploadKmlDialog/thunk';
import { FileUploadStart, SetExpressAIUpload } from 'State/fileUpload/thunk';
import { SetDxfEpsgAction } from 'State/fileUpload/actions';
import styles from './styles';

const mapStateToProps = (state: IState) => ({
  showDialog: state.ui.uploadKmlDialog.show,
  file: state.fileUpload.uploadFiles[0],
  expressAIUpload: state.fileUpload.expressAIUpload,
  epsg: state.fileUpload.epsg,
  epsgList: state.fileUpload.epsgList,
});

const mapDispatchToProps = {
  HideUploadKmlDialogAction: HideUploadKmlDialog,
  FileUploadStartAction: FileUploadStart,
  SetExpressAIUploadAction: SetExpressAIUpload,
  SetDxfEpsgAction,
};

interface IOwnProps {
  containerId?: string;
  fileExtension: any;
  adminOutputUpload: boolean;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
