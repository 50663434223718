// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useMemo } from 'react';
import { Typography, TableRow, TableCell } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import downloadIcon from 'Assets/download-icon.png';
import { WideLightTooltip } from 'Common/Tooltip';
import moment from 'moment-timezone';
import { GetProjectDetailsRoute } from 'Utils/routes';
import { Link as RouterLink } from 'react-router-dom';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDF from 'Common/OrderDialog/PDF';
import { formatValue } from 'Utils/deliverables';
import OrderSummary from './orderSummary';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');

/**
 * Component renders order history table
 *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {array} props.list list of completed orders
 */
export const OrderListItem = ({ order, costPerAcre, automatePlanType, orgTeams, projectName, templates, epsgCode, imageryProvider }: PropsType) => {
  const classes = useStyles();
  const team = orgTeams?.find((t) => t._id === order.team);

  const pdfData = useMemo(() => {
    const { addOns, deliverables, referenceId, acreage, creditAcreage, costToCustomer, paidForThisProject, middlePoint, cadTemplate, topoIntervalMajor, topoIntervalMinor,
      orderDate, deliveryDate, bundleName, bundleAcreAdjustmentFactor, density, deliverySpeed } = order;
    const imageryCreditCost = order.imageryCreditCost || 0;
    const imageryDollarCost = order.imageryDollarCost || 0;
    let addOnsInAcres = false;
    // If all add-ons include an acres field, it indicates that the user paid for these add-ons in acres
    if (addOns?.every((addOn) => addOn.hasOwnProperty('acres'))) {
      addOnsInAcres = true;
    }
    const deliverablesCredits = addOns.reduce((sum, addOn) => {
      if (addOn.addOnType === 'deliverable') {
        if (addOnsInAcres) {
          return sum + addOn.acres;
        }
        return sum + addOn.cost;
      }
      return sum;
    }, 0);
    const deliverablesCreditsString = addOnsInAcres ? `${deliverablesCredits.toFixed(2)} credits` : `$${formatValue(deliverablesCredits)}`;
    const layersCredits = addOns.reduce((sum, addOn) => {
      if (addOn.addOnType === 'layer') {
        if (addOnsInAcres) {
          return sum + addOn.acres;
        }
        return sum + addOn.cost;
      }
      return sum;
    }, 0);
    const layersCreditsString = addOnsInAcres ? `${layersCredits.toFixed(2)} credits` : `$${formatValue(layersCredits)}`;
    const addOnsCreditsString = addOnsInAcres ? `${(imageryCreditCost + deliverablesCredits + layersCredits).toFixed(2)} credits` : `$${formatValue(imageryDollarCost + deliverablesCredits + layersCredits)}`;
    return ({
      type: 'Invoice',
      automatePlanType,
      projectName,
      referenceId,
      team: team?.name,
      acreage,
      middlePoint: `${middlePoint?.place}, ${middlePoint?.region} ${middlePoint?.postcode}`,
      coordinates: `${middlePoint?.longitude.toFixed(5)}, ${middlePoint?.latitude.toFixed(5)}`,
      cadTemplate: templates.find((temp) => temp._id === cadTemplate)?.name,
      imageryProvider,
      imageryCreditCost: imageryCreditCost?.toFixed(2),
      crs: epsgCode && `EPSG: ${epsgCode}`,
      topoMajr: topoIntervalMajor,
      topoMinr: topoIntervalMinor,
      orderDate: orderDate ? moment(orderDate).format('MMMM Do YYYY') : 'N/A',
      deliveryDate: deliveryDate ? moment(deliveryDate).format('MMMM Do YYYY') : 'N/A',
      selectedBundle: { name: bundleName, acreAdjustmentFactor: bundleAcreAdjustmentFactor, density, deliverySpeed },
      costPerAcre,
      bundleDeliverables: deliverables,
      selectedAdditionals: addOns,
      chargedCredits: bundleName === 'A la carte' ? 0 : (creditAcreage - (addOnsInAcres ? layersCredits + deliverablesCredits + imageryCreditCost : 0))?.toFixed(2),
      totalCreditsCharged: creditAcreage?.toFixed(2),
      deliverablesCreditsString,
      layersCreditsString,
      addOnsCreditsString,
      totalAdditionalCharged: paidForThisProject?.toFixed(2),
      totalCostForProject: costToCustomer?.toFixed(2),
    });
  }, []);

  return (
    <TableRow key={order._id}>
      <TableCell className={classes.tableCell} component="th" scope="row">
        {order.name}
      </TableCell>
      <TableCell className={classes.tableCell} component="th" scope="row">
        {order.referenceId || 'N/A'}
      </TableCell>
      <TableCell className={classes.tableCell} component="th" scope="row">
        {team?.name || 'N/A'}
      </TableCell>
      <TableCell className={classes.tableCell} component="th" scope="row">
        {/* If orderDate is undefined display N/A */}
        {order.orderDate ? moment(order.orderDate).format('MMMM D, YYYY') : 'N/A'}
      </TableCell>
      <TableCell className={classes.tableCell} component="th" scope="row">
        {order.acreage.toFixed(2)}
      </TableCell>
      <TableCell className={classes.tableCell} component="th" scope="row">
        <div className={classes.info}>
          {order.creditAcreage.toFixed(2)}
          <WideLightTooltip
            placement="bottom"
            interactive
            title={<OrderSummary order={order} />}
          >
            <InfoIcon className={classes.icon} />
          </WideLightTooltip>
        </div>
      </TableCell>
      <TableCell className={classes.tableCell} component="th" scope="row">
        <div className={classes.info}>
          {(order.costToCustomer ? `$${order.costToCustomer.toFixed(2)}` : 'N/A')}
          <WideLightTooltip
            placement="bottom"
            interactive
            title={(
              <div>
                <div className={classes.divGrid}>
                  <Typography variant="body1" className={classes.text}>Project cost</Typography>
                  <Typography className={classes.text} variant="body1">
                    {(order.costToCustomer >= 0) ? `$${(order.costToCustomer).toFixed(2)}` : 'N/A'}
                  </Typography>
                </div>
                <div className={classes.divGrid}>
                  <Typography variant="body1" className={classes.text}>Additional cost</Typography>
                  <Typography className={classes.text} variant="body1">
                    {(order.paidForThisProject >= 0 ? `$${order.paidForThisProject.toFixed(2)}` : 'N/A')}
                  </Typography>
                </div>
              </div>
            )}
          >
            <InfoIcon className={classes.icon} />
          </WideLightTooltip>
        </div>
      </TableCell>
      <TableCell className={classes.iconTableCell} component="th" scope="row">
        <PDFDownloadLink document={<PDF data={pdfData} />} fileName={`${projectName}_invoice.pdf`}>
          {({ blob, url, loading }) =>
            (!loading && (
              <div
                onClick={() => {
                  window?.pendo?.track('Invoice downloaded');
                }}
                aria-hidden="true"
              >
                <img src={downloadIcon} alt="download" />
              </div>
            ))}
        </PDFDownloadLink>
      </TableCell>
      {!_ADMIN_ && (
        <TableCell className={classes.tableCell} component="th" scope="row">
          <RouterLink to={GetProjectDetailsRoute(order.ownerProject)} className={classes.routerLink} title="View Project"><Typography variant="h6">View Project</Typography></RouterLink>
        </TableCell>
      )}
    </TableRow>
  );
};

export default compose(OrderListItem);
