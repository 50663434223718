// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core';
import styles from './styles';

type PropsType =
  WithStyles<typeof styles> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  withStyles(styles),
);
