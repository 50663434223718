// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const styles = (theme) => ({
  pageWrapper: {
    width: 600,
    margin: '0 auto',
  },
  margin: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: theme.spacing(2),
    alignItems: 'center',
  },
  acreageList: {
    gridRowGap: 0,
    marginBottom: theme.spacing(),
  },
  progressBar: {
    marginBottom: theme.spacing(3),
  },
  bar2: {
    backgroundColor: theme.palette.secondary.main,
  },
  dashedBar: {
    animation: 'none',
    backgroundColor: theme.palette.lightGrey,
    backgroundImage: 'none',
  },
  alignRight: {
    justifySelf: 'right',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    height: 2,
  },
  dayWidth: {
    flex: 1,
    maxWidth: '13.5%',
    minWidth: '13.5%',
    margin: '2px',
  },
  emptyDay: {
    flex: 1,
    maxWidth: '13.5%',
    minWidth: '13.5%',
    margin: '2px',
    backgroundColor: '#EFEFEF',
  },
  calendarHeaders: {
    flex: 1,
    maxWidth: '13.5%',
    minWidth: '13.5%',
    margin: '2px',
    padding: 0,
  },
  calendarDay: {
    flex: 1,
    maxWidth: '13.5%',
    minWidth: '13.5%',
    margin: '2px',
    height: '50px',
    backgroundColor: hexToRGBA(theme.palette.primary.main, 0.1),
  },
  calendarContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'stretch',
  },
  loginsCircle: {
    borderRadius: '50%',
    height: '37px',
    width: '37px',
    padding: 0,
    margin: '6.5px auto',
  },
  topMargin: {
    marginTop: theme.spacing(2),
  },
});

export default styles;
