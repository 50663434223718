// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

interface IOwnProps {
  lasFilesWith3DTile: ILasFile[];
  showPointCloud: boolean;
  showTerrain: boolean;
}

const mapStateToProps = (state: IState) => {
  const { linework3D, layer3DChange, cad3dLayers } = state.viewer3D;
  return {
    linework3D,
    layer3DChange,
    cad3dLayers,
  };
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(connect(mapStateToProps));
