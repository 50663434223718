// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => createStyles({
  form: {
    margin: '0 auto',
    width: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  formRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridGap: theme.spacing(1),
  },
  submitButton: {
    boxShadow: 'none',
    width: '100%',
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.dark,
    boxShadow: 'none',
    marginTop: theme.spacing(4),
  },
  successSnackbar: {
    backgroundColor: green[600],
    boxShadow: 'none',
    marginTop: theme.spacing(4),
  },
  snackbarContent: {
    display: 'grid',
    gridGap: theme.spacing(),
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  sectionWrapper: {
    width: 600,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    marginTop: '30px',
  },
  settingWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0 10px',
  },
}));

export default useStyles;
