// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import history from 'Utils/history';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Dialog, Slide, IconButton, LinearProgress, Button, Typography, Paper, TextField, Fade, CircularProgress, Tooltip } from '@material-ui/core';
import { GetTemplateRoute } from 'Utils/routes';
import { airworksLayers } from 'Utils/airworksLayers';
import Dropzone from 'Common/dropzone';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import uploadFilesIcon from 'Assets/upload-cad.png';
import ConfirmDialog from 'Common/ConfirmDialog';
import Matching from './Matching';
import compose from './container';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const AddTemplateDialog = compose(({ classes, file, templateId, AddLayersAction, currentTemplate, showDialog, setShowDialog, FileUploadCancelAction, FileUploadStartAction, EditTemplateAction, SetTemplateIdAction }) => {
  const [loading, setLoading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showDropzone, setShowDropzone] = useState(true);
  const [correctMatching, setCorrectMatching] = React.useState(null);
  const [templateName, setTemplateName] = useState('');
  const [openWarning, setOpenWarning] = React.useState(false);
  const [fileName, setFileName] = useState(null);

  const unmatchedLayers = correctMatching && (Object.keys(correctMatching).length > 0 && (Object.keys(correctMatching).length < 14 || Object.values(correctMatching).includes(null)));
  const canAddTemplate = correctMatching && (Object.keys(correctMatching).length > 0);

  useEffect(() => {
    SetTemplateIdAction(null);
    setShowDropzone(true);
    if (showDialog) {
      setShowUpload(false);
    }

    return () => {
      setCorrectMatching(null);
      setTemplateName('');
      setFileName(null);
    };
  }, [showDialog]);

  const onOkCloseDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpenWarning(false);
    setShowDialog(false);
    e.stopPropagation();
    if (fileName) {
      window?.pendo?.track('Client Template Created', { template_name: fileName });
    }
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setOpenWarning(false);
    e.stopPropagation();
  };

  const closeDialogClick = () => {
    setOpenWarning(true);
  };

  const addTemplate = async () => {
    const editArray: any[] = [];
    if (currentTemplate) {
      currentTemplate.layers.forEach((layer) => {
        const keys = Object.keys(correctMatching).filter((k) => correctMatching[k] === layer.name);
        const l: any = { _id: '', mapping: [] };
        l._id = layer._id;
        l.mapping = keys;
        editArray.push(l);
      });
    }
    setLoading(true);
    if (unmatchedLayers) {
      let newLayers: string[] = [];
      const temp = Object.keys(correctMatching).filter((l) => !correctMatching[l]);
      newLayers = [...newLayers, ...temp];
      const difference = airworksLayers.filter((l) => !Object.keys(correctMatching).includes(l));
      newLayers = [...newLayers, ...difference];
      const newLayersArr: {name: string, mapping: string[]}[] = [];
      newLayers.forEach((l) => newLayersArr.push({ name: l, mapping: [l] }));
      const data: {templateId: string, newLayers: any[]} = { templateId, newLayers: newLayersArr };
      await AddLayersAction(data);
    }
    await EditTemplateAction({ _id: templateId, layers: editArray });
    if (templateName.trim() !== '') {
      await EditTemplateAction({ _id: templateId, name: templateName });
    }
    setLoading(false);
    setTemplateName('');
    history.push(GetTemplateRoute(templateId));
    window?.pendo?.track('Client Template Created', { template_name: templateName.length ? templateName : fileName });
    setShowDialog(false);
  };
  return (
    <>
      <Dialog
        fullScreen
        open={showDialog}
        onClose={() => setShowDialog(false)}
        TransitionComponent={Transition}
        keepMounted={false}
      >
        {/* {loading && (
          <>
            <LinearProgress className={classes.progress} />
            <div className={classes.backdrop} />
          </>
        )} */}
        <div className={classes.closeButtonWrapper}>
          <IconButton
            color="inherit"
            onClick={() => {
              closeDialogClick();
              if (showUpload) {
                history.push(GetTemplateRoute(templateId));
              }
            }}
            aria-label="Close"
          >
            <ConfirmDialog
              showDialog={openWarning}
              onOk={onOkCloseDialog}
              onCancel={onCancel}
              contentText="This action will forfeit all changes made to the template"
            />
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <div>
          <div className={classes.pageTitle}>
            <Typography variant="h1">Add New CAD Template</Typography>
          </div>
          <div className={classes.uploadForm}>
            <div className={classes.form}>
              <TextField
                variant="outlined"
                placeholder="Template Name"
                className={classes.outline}
                value={templateName}
                onChange={(e) => setTemplateName(e.target.value)}
              />
            </div>
            {showDropzone && (
              <>
                <Dropzone
                  acceptedExtensions={['.dxf']}
                  onDropAccepted={async (acceptedFilesArray) => {
                    const f = acceptedFilesArray[0];
                    setShowDropzone(false);
                    await FileUploadStartAction([f], false, true);
                    setFileName(f.name);
                  }}
                >
                  {(getInputProps, open) => (
                    <div className={classes.dropzone}>
                      <div>
                        <img src={uploadFilesIcon} alt="Upload files" className={classes.uploadFormIcon} />
                        <input {...getInputProps()} />
                      </div>
                      <div className={classes.dropzoneText}>
                        <Typography variant="h5" className={classes.uploadFormText}>
                          Drag and drop to upload
                        </Typography>
                        <br />
                        <Typography variant="caption" className={classes.uploadFormText}>
                          {'or '}
                          <button type="button" className={classes.openFileDialogLink} onClick={open}>browse</button>
                          {' to choose a file'}
                        </Typography>
                      </div>
                    </div>
                  )}
                </Dropzone>
                <Typography variant="body2" className={classes.formText}>
                  If your file is in a .dwg, .dwt or .dgn file format, please convert to .dxf to upload
                </Typography>
              </>
            )}
          </div>
          {file && (
            <div className={classes.progressSection}>
              <div className={classes.fileExtension}>
                <Typography variant="body1" className={classes.fileExtensionText}>.dxf</Typography>
              </div>
              <div className={classes.progress}>
                <div className={classes.progressText}>
                  <Typography variant="body2">{file.name}</Typography>
                </div>
                {(file.progress < 100) && (
                  <LinearProgress
                    color="primary"
                    variant="determinate"
                    value={file.progress}
                  />
                )}
              </div>
              <div className={classes.progressButtons}>
                <IconButton
                  className={classes.progressButton}
                  onClick={() => {
                    setShowDropzone(true);
                    FileUploadCancelAction(file.id);
                    SetTemplateIdAction(null);
                    setCorrectMatching(null);
                  }}
                >
                  <ClearIcon className={classes.cancelIcon} />
                </IconButton>
              </div>
            </div>
          )}
          {file && file.progress === 100 && <Matching setCorrectMatching={setCorrectMatching} />}
        </div>
        <Paper elevation={1} className={classes.bottomPaper}>
          {unmatchedLayers && (
            <div className={classes.warningMessage}>
              <Typography variant="body2" style={{ fontWeight: 400 }}>You have unmatched layers</Typography>
              <Typography variant="body2">Unmatched layers will export using the AirWorks default layer properties</Typography>
            </div>
          )}
          <Tooltip title="Please wait while we update your template, this may take a minute." arrow disableHoverListener={!loading} placement="top">
            <span className={classes.addButton}>
              <Button
                disabled={!canAddTemplate || loading}
                variant="contained"
                color="primary"
                onClick={addTemplate}
              >
                <Fade in={loading}>
                  <CircularProgress size={24} className={classes.buttonProgress} />
                </Fade>
                <Fade in={!loading}>
                  <span>Add template</span>
                </Fade>
              </Button>
            </span>
          </Tooltip>
        </Paper>
      </Dialog>
    </>
  );
});

export default AddTemplateDialog;
