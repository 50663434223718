// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, postJson } from 'Utils/http';
import { API_URL } from 'Config';
import { getOrders } from 'State/order/selectors';
import {
  GetImageryProvidersAsync,
  CreateImageryProviderAsync,
  ImageryCoverageAsync,
} from './actions';

export const GetImageryProviders = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/imageryProviders`;
  const { token } = getState().auth;

  dispatch(GetImageryProvidersAsync.request());

  const { success, message, data } = await getJson<IImageryProvidersResponse>(url, token);
  if (!success) {
    dispatch(GetImageryProvidersAsync.failure(message));
    return;
  }

  dispatch(GetImageryProvidersAsync.success(data.imageryProviders));
};

export const CreateImageryProviders = (name: string, company: string, creditFactor: number) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;

  const url = `${API_URL}/imageryProvider`;

  dispatch(CreateImageryProviderAsync.request());

  const data = { name, company, creditFactor };
  const response = await postJson<IImageryProviderResponse>(url, data, token);

  if (!response.success) {
    dispatch(CreateImageryProviderAsync.failure(response.message));
    return;
  }

  dispatch(CreateImageryProviderAsync.success(response.data.imageryProvider));
};

export const NearmapImageryCoverage = (points: number[]) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/imageryProvider/nearmapCoverage`;
  dispatch(ImageryCoverageAsync.request());
  const data = { points };
  const response = await postJson<INearmapCoverageResponse>(url, data, token);

  if (!response.success || !response.data.coverage) {
    const { org } = getState().account;
    const { project } = getState().project;
    const orders = getOrders(getState());

    const { companyName, imageryProviders } = org;
    const { name } = project;
    const { acreage } = orders.activeOrder;

    const providerTier = getState().imagery.imageryProviders.filter((iP) => iP._id === imageryProviders[0]);

    let providerTierName = 'none';

    if (providerTier.length) {
      providerTierName = providerTier[0].name;
    }

    window?.pendo?.track('Nearmap Imagery Unavailable', {
      companyName,
      projectName: name,
      kmlSize: acreage,
      nearmapTier: providerTierName,
    });
  }

  if (!response.success) {
    dispatch(ImageryCoverageAsync.failure(response.message));
    return;
  }
  dispatch(ImageryCoverageAsync.success(response.data.coverage));
};
