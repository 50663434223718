// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import {
  Typography,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import compose from './container';

// This component renders signup success page
// page that gets render when sign up has been successful
const SignUpSuccess = compose(
  ({ classes }) => (
    <div>
      <Typography className={classes.header} variant="h1">
        Success! You’re signed up for your free 30-day trial.
      </Typography>
      <Typography className={classes.details}>
        We’ve sent you an email with a verification link. Follow the link in your email to sign into your account and get started with your free trial.
      </Typography>
      <Typography className={classes.details}>
        <Link component={RouterLink} className={classes.forgotText} to="/">
          Go to Log in
        </Link>
      </Typography>
    </div>
  ),
);

export default SignUpSuccess;
