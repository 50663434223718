// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Typography, Button, Dialog, Card, Divider } from '@material-ui/core';
import CreateSubscriptionDialog from 'Common/CreateSubscriptionDialog';
import { Link as RouterLink } from 'react-router-dom';
import { USER_MANAGEMENT_ROUTE, ACCOUNT_PLAN_USAGE } from 'Utils/routes';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');

/**
 * Component renders 'Accounts and Subscriptions' card on the subscriptions tab
 *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {object} props.cadSubscription CAD product subscription if the organization has any
 * @param {object} props.automateSubscription Automate product subscription if the organization has any
 * @param {boolean} props.subscriptionsLoading Boolean to indicate whether the getOrgActiveSubscription has returned the response or not
 */
export const PlanInfo = ({ cadSubscription, automateSubscription, subscriptionsLoading }: PropsType) => {
  const classes = useStyles();
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const cadSubscriptionExists = cadSubscription && Object.keys(cadSubscription).length > 0;
  const automateSubscriptionExists = automateSubscription && Object.keys(automateSubscription).length > 0;
  const anySubscriptionExists = cadSubscriptionExists || automateSubscriptionExists;

  const onUpgradePlanClick = () => {
    setShowSubscriptionDialog(true);
  };

  return (
    <div>
      <Card className={classes.subscriptionContent}>
        <Typography className={classes.header} variant="h4">Accounts and Subscriptions</Typography>
        <div>
          <>
            {cadSubscriptionExists && (
              <>
                <div className={classes.planOptions}>
                  <div>
                    <Typography className={classes.planName} variant="h2">{cadSubscription.name}</Typography>
                    <Typography className={classes.planName} variant="h2">{` - ${cadSubscription.interval}`}</Typography>
                    {cadSubscription.trial && <Typography className={classes.planName} variant="h2"> - Trial</Typography>}
                  </div>
                  <Button variant="outlined" onClick={onUpgradePlanClick}>Upgrade plan</Button>
                </div>
                <div>
                  <div className={classes.cadDetails}>
                    <Typography variant="h5" className={classes.planDetails}>
                      {cadSubscription.cadSeatsTaken}
                      {' '}
                      of
                      {' '}
                      {cadSubscription.cadSeatsAvailable}
                      {' '}
                      users assigned
                    </Typography>
                    <RouterLink to={USER_MANAGEMENT_ROUTE} title="Manage Users">
                      <Typography variant="h5" className={classes.optionsButton}>
                        Manage Users
                      </Typography>
                    </RouterLink>
                    <div />
                  </div>
                  <Typography variant="h5" className={classes.planDetails}>
                    Automatically renews on
                    {' '}
                    {moment(cadSubscription.cadCurrentPeriodEnd).format('LL')}
                  </Typography>
                </div>
              </>
            )}
            {automateSubscriptionExists && (
              <>
                <Divider className={classes.divider} />
                <div className={classes.planOptions}>
                  <div>
                    <Typography className={classes.planName} variant="h2">{automateSubscription.name}</Typography>
                    <Typography className={classes.planName} variant="h2">{` - ${automateSubscription.interval}`}</Typography>
                    {automateSubscription.trial && <Typography className={classes.planName} variant="h2"> - Trial</Typography>}
                  </div>
                  {!cadSubscriptionExists && (<Button variant="outlined" onClick={onUpgradePlanClick}>Add AirWorks CAD</Button>)}
                </div>
                <div>
                  <RouterLink to={ACCOUNT_PLAN_USAGE} title="View Usage and Limits">
                    <Typography variant="h5" className={classes.optionsButton}>
                      View Usage and Limits
                    </Typography>
                  </RouterLink>
                </div>
              </>
            )}
          </>
          {(!subscriptionsLoading && !cadSubscriptionExists && !automateSubscriptionExists) && (
            <div className={classes.planOptions}>
              <div>
                <Typography className={classes.planName} variant="h2">No active subscriptions</Typography>
              </div>
              <Button variant="outlined" onClick={onUpgradePlanClick}>Upgrade plan</Button>
            </div>
          )}
        </div>
      </Card>
      {/* <Dialog open={showEditModal} onClose={() => setShowEditModal(false)}>
        <div className={classes.dialogWrapper}>
          <Typography variant="h3">Upgrade plan</Typography>
          <Typography>To make any changes to your plan or cancel your plan, please contact our sales team at <Typography component="span" color="primary">857-990-1063</Typography></Typography>
        </div>
      </Dialog> */}
      <CreateSubscriptionDialog {...{ cadSubscriptionExists, showSubscriptionDialog, setShowSubscriptionDialog }} />
    </div>
  );
};

export default compose(PlanInfo);
