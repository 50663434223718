// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import { ADMIN_GENERAL_ROUTE, ADMIN_PROJECTS_ROUTE, ADMIN_SUBSCRIPTION_ROUTE, ADMIN_USERS_ROUTE, ADMIN_ORG_PLAN_USAGE_ROUTE, ADMIN_ORDER_HISTORY_ROUTE } from 'Utils/routes';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps) => ({
  orgId: state.admin.orgId,
  activeTabId: (matchPath(ownProps.location.pathname, { path: ADMIN_GENERAL_ROUTE }) && 1)
    || (matchPath(ownProps.location.pathname, { path: ADMIN_PROJECTS_ROUTE }) && 2)
    || (matchPath(ownProps.location.pathname, { path: ADMIN_SUBSCRIPTION_ROUTE }) && 3)
    || (matchPath(ownProps.location.pathname, { path: ADMIN_USERS_ROUTE }) && 4)
    || (matchPath(ownProps.location.pathname, { path: ADMIN_ORG_PLAN_USAGE_ROUTE }) && 5)
    || (matchPath(ownProps.location.pathname, { path: ADMIN_ORDER_HISTORY_ROUTE }) && 6),
});

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps),
);
