// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Button, Fade, CircularProgress, SnackbarContent, Collapse, Divider, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { TextField, Validators, Helpers } from 'Common/forms';
import { Form } from 'react-final-form';
import LoadingSpinner from 'Common/LoadingSpinner';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import compose, { PropsType } from './container';
import useStyles from './styles';
import AccountGeneralOrg from './orgGeneral';

export const AccountGeneral = ({ initialValues, updateUserMessage, organizationAccess, loading, GetUserProfileAction, UpdateUserProfileAction }: PropsType) => {
  const classes = useStyles();
  const [initialState, setInitialState] = useState(undefined);

  useEffect(() => {
    GetUserProfileAction();
    if (!initialState) setInitialState(initialValues);
  }, []);

  const onFormSubmit = (values: any) => Helpers.submit(values, UpdateUserProfileAction);

  return (
    <div className={classes.contentWrapper}>
      <Typography variant="h4">User Info</Typography>
      <LoadingSpinner loading={loading}>
        <Form
          onSubmit={onFormSubmit}
          keepDirtyOnReinitialize
          initialValues={initialState}
          render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, submitError, submitSucceeded, handleSubmit }) => (
            <form
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <div className={classes.formRow}>
                <TextField
                  label="First Name"
                  name="firstName"
                  validate={Validators.required}
                  autoFocus
                />
                <TextField
                  label="Last Name"
                  name="lastName"
                  validate={Validators.required}
                />
              </div>
              <TextField
                label="Work Email"
                name="email"
                disabled
                validate={Validators.composeValidators(Validators.required, Validators.email)}
              />
              <TextField
                label="Job Title"
                name="jobTitle"
                validate={Validators.required}
              />
              <TextField
                label="Phone Number"
                name="phoneNumber"
                validate={Validators.composeValidators(Validators.required, Validators.mobilePhone)}
              />
              <Collapse in={(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                <SnackbarContent
                  className={classes.errorSnackbar}
                  message={(
                    <div className={classes.snackbarContent}>
                      <ErrorIcon />
                      {submitError}
                    </div>
                  )}
                />
              </Collapse>
              <Collapse in={!submitting && submitSucceeded}>
                <SnackbarContent
                  className={classes.successSnackbar}
                  message={(
                    <div className={classes.snackbarContent}>
                      <CheckCircleIcon />
                      {updateUserMessage}
                    </div>
                  )}
                />
              </Collapse>
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                type="submit"
                disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} className={classes.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>Update User Info</span>
                </Fade>
              </Button>
            </form>
          )}
        />
      </LoadingSpinner>
      <Divider className={classes.divider} />
      {organizationAccess && <AccountGeneralOrg />}
    </div>
  );
};

export default compose(AccountGeneral);
