// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, user, ...rest }) => (
  <Route
    {...rest} // eslint-disable-line react/jsx-props-no-spreading
    render={(pr) => (
      user
        ? <Component {...pr} /> // eslint-disable-line react/jsx-props-no-spreading
        : <Redirect to={{ pathname: '/', state: { from: pr.location } }} />
    )}
  />
);

PrivateRoute.propTypes = {
  component: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
};

PrivateRoute.defaultProps = {
  user: null,
};

export default PrivateRoute;
