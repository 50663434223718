// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import deliveryEstimate from 'Utils/deliverables';
import {
  GetBundlesAction,
  SetDefaultBundleAction,
  SelectedAdditionalDeliverablesAndLayersAction,
  SetDeliverablesAction,
  ResetBundleStateAction,
  SetTopoMajrAction,
  SetTopoMinrAction,
  SetDensityAction,
  SetDeliveryDateAction,
  SetDeliveryDaysAction,
} from './actions';

const initialState: IBundleState = {
  list: [],
  availableBundles: [],
  additionalDeliverables: [],
  bundleDeliverables: [],
  selectedBundle: null,
  extraDeliverablesCost: 0,
  totalCost: 0,
  extraLayersCost: 0,
  selectedAdditionals: [],
  topoMajr: null,
  topoMinr: null,
  density: 'normal',
  densityOptions: [],
  deliverySpeed: 'normal',
  deliveryDays: 0,
  deliveryDate: null,
  loading: false,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(GetBundlesAction.request, (draft) => {
    draft.loading = true;
  });

  handleAction(GetBundlesAction.success, (draft, payload) => {
    draft.list = payload.deliverables.groupings;
    draft.availableBundles = payload.deliverables.bundles;
    draft.densityOptions = payload.deliverables.densityOptions;
    draft.loading = false;
  });

  handleAction(GetBundlesAction.failure, (draft) => {
    draft.loading = false;
  });

  handleAction(SetDeliverablesAction, (draft, payload) => {
    draft.additionalDeliverables = payload.additionalDeliverables;
    draft.bundleDeliverables = payload.bundleDeliverables;
    draft.selectedBundle = payload;
    draft.extraLayersCost = 0;
    draft.extraDeliverablesCost = 0;
    draft.selectedAdditionals = [];
    draft.totalCost = payload.bundleCost;
    draft.density = payload.density;
    draft.deliverySpeed = payload.deliverySpeed;
    draft.deliveryDays = payload.bundleDeliveryTime;
    draft.deliveryDate = deliveryEstimate(payload.bundleDeliveryTime);
  });

  handleAction(SetDefaultBundleAction, (draft, payload) => {
    draft.selectedBundle = payload;
    draft.bundleDeliverables = payload.bundleDeliverables;
    draft.additionalDeliverables = payload.additionalDeliverables;
    draft.totalCost = payload.bundleCost;
    draft.density = payload.density;
    draft.deliverySpeed = payload.deliverySpeed;
  });

  handleAction(SelectedAdditionalDeliverablesAndLayersAction, (draft, payload) => {
    const index = draft.selectedAdditionals.findIndex((s) => s._id === payload._id);
    if (index >= 0) {
      draft.selectedAdditionals.splice(index, 1);
      if (payload.type === 'deliverable') {
        draft.extraDeliverablesCost = +(draft.extraDeliverablesCost - payload.cost).toFixed(2);
      } else {
        draft.extraLayersCost = +(draft.extraLayersCost - payload.cost).toFixed(2);
      }
    } else {
      draft.selectedAdditionals.push(payload);
      if (payload.type === 'deliverable') {
        draft.extraDeliverablesCost = +(draft.extraDeliverablesCost + payload.cost).toFixed(2);
      } else {
        draft.extraLayersCost = +(draft.extraLayersCost + payload.cost).toFixed(2);
      }
    }
    draft.totalCost = +(draft.selectedBundle.bundleCost).toFixed(2);
  });

  handleAction(SetTopoMajrAction, (draft, payload) => { draft.topoMajr = payload; });

  handleAction(SetTopoMinrAction, (draft, payload) => { draft.topoMinr = payload; });

  handleAction(SetDensityAction, (draft, payload) => { draft.density = payload; });

  handleAction(ResetBundleStateAction, () => initialState);

  handleAction(SetDeliveryDateAction, (draft, payload) => { draft.deliveryDate = payload; });

  handleAction(SetDeliveryDaysAction, (draft, payload) => { draft.deliveryDays = payload; });
});
