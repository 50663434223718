// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { Logout } from 'State/auth/thunk';
import { ShowTokenExpiredDialog, HideTokenExpiredDialog } from 'State/ui/tokenExpiredDialog/thunk';

const mapStateToProps = (state: IState) => ({
  showDialog: state.ui.tokenExpiredDialog.show,
  tokenExpires: state.auth.tokenExpires,
});

const mapDispatchToProps = {
  LogoutAction: Logout,
  ShowTokenExpiredDialogAction: ShowTokenExpiredDialog,
  HideTokenExpiredDialogAction: HideTokenExpiredDialog,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
