// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageWrapper: {
      width: 1200,
      margin: '0 auto',
    },
    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(),
    },
    table: {
      backgroundColor: 'inherit',
      marginBottom: theme.spacing(3),
      cursor: 'pointer',
    },
    tablePagination: {
      width: 'inherit',
    },
    opacity: {
      opacity: 0.7,
    },
    columnHeader: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(),
      paddingTop: theme.spacing(),
    },
    nameColumn: {
      display: 'flex',
      alignItems: 'center',
    },
    rowHover: {
      '&:hover': {
        backgroundColor: 'white !important',
        boxShadow: theme.lightShadow,
      },
    },
    avatar: {
      backgroundColor: theme.palette.primary.main,
      marginRight: theme.spacing(2),
      width: 32,
      height: 32,
      fontSize: '16px',
    },
    progressBackdrop: {
      height: '100%',
      opacity: 0.7,
      position: 'absolute',
      width: '100%',
      left: 0,
      top: 0,
      backgroundColor: 'white',
    },
    loading: {
      height: '80px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '20px',
    },
    snackBar: {
      top: '170px',
    },
    alert: {
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
    },
    error: {
      display: 'flex',
      alignItems: 'center',
    },
  }));

export default useStyles;
