// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { Button, Collapse, Typography } from '@material-ui/core';
import CreateCreditCardForm from './CreateCreditCardForm';
import useStyles from './styles';

export const CreateCreditCard = () => {
  const [expand, setExpand] = useState(false);
  const [createCardState, setCreateCardState] = useState(null);
  const classes = useStyles();

  useEffect(() => {
    if (createCardState?.submitSucceeded) {
      setExpand(false);
    }
  }, [createCardState]);

  return (
    <div>
      <Button className={classes.button} onClick={() => setExpand(!expand)}>
        <AddCircleOutline className={classes.addIcon} />
        <Typography className={classes.buttonText} variant="h4">Add New Payment Method</Typography>
      </Button>
      <Collapse in={expand} mountOnEnter unmountOnExit>
        <CreateCreditCardForm setCreateCardState={setCreateCardState} onCancel={() => setExpand(false)} />
      </Collapse>
    </div>
  );
};

export default CreateCreditCard;
