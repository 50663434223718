// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useRef, useState } from 'react';
import { Dialog, Typography, Button, Collapse, Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Link } from 'react-router-dom';
import logo from 'Assets/logo_small.png';
import { TextField, Validators, Helpers } from 'Common/forms';
import { Form } from 'react-final-form';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { darkTheme } from 'Utils/themes';
import ReCAPTCHA from 'react-google-recaptcha';
import compose from './container';

// eslint-disable-next-line react/jsx-props-no-spreading
const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) =>
  <Slide ref={ref} direction="up" {...props} />);

const ForgotPassword = compose(
  ({ classes, ForgotPasswordAction, forgotPasswordLinkSend, SendForgotPasswordLinkAction }) => {
    const [disableSubmit, setDisableSubmit] = useState(true);
    const captchaRef = useRef(null);
    const onFormSubmit = async (values: any) => {
      values.captchaToken = await captchaRef.current.getValue();
      Helpers.submit(values, ForgotPasswordAction);
      captchaRef.current.reset();
    };

    return (
      <MuiThemeProvider theme={darkTheme}>
        <Dialog
          fullScreen
          open
          TransitionComponent={Transition}
          keepMounted={false}
          classes={{
            paper: classes.dialogPaper,
          }}
        >
          <div className={classes.dialog}>
            <img src={logo} alt="AirWorks small logo" />
            {forgotPasswordLinkSend ? (
              <>
                <Typography className={classes.header} variant="h1">
                  Check your Email
                </Typography>
                <Typography className={classes.successMessage}>
                  A password reset link has been emailed to the email address you entered. If you don't receive
                  an email, be sure to check your spam and that you entered the email associated with your account.
                </Typography>
                <Button variant="outlined" className={classes.button} onClick={() => SendForgotPasswordLinkAction(false)}>
                  RESEND LINK
                </Button>
              </>
            ) : (
              <>
                <Typography className={classes.header} variant="h1">
                  Forgot Password
                </Typography>
                <Typography className={classes.details}>
                  Enter the email address associated with this account and
                  we'll send you a link to reset your password.
                </Typography>
                <Form
                  onSubmit={onFormSubmit}
                  render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, handleSubmit }) => (
                    <form
                      className={classes.form}
                      onSubmit={handleSubmit}
                    >
                      <TextField
                        name="email"
                        label="Email Address"
                        autoComplete="email"
                        autoFocus
                        validate={Validators.composeValidators(Validators.required, Validators.email)}
                        className={classes.textField}
                      />

                      <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                        <Typography variant="body1" className={classes.errorMessage}>
                          Oops! We weren’t able to send you a reset link, please enter your email and try again.
                        </Typography>
                      </Collapse>
                      <div className={classes.captcha}>
                        <ReCAPTCHA
                          sitekey={_GOOGLE_CAPTCHA_SITE_KEY_}
                          onChange={() => setDisableSubmit(false)}
                          ref={captchaRef}
                        />
                      </div>
                      <div className={classes.buttonWrapper}>
                        <Button
                          type="submit"
                          variant="outlined"
                          className={classes.button}
                          disabled={(hasSubmitErrors && !modifiedSinceLastSubmit) || submitting || disableSubmit}
                        >
                          Reset Password
                        </Button>
                        <Button component={Link} to="/" className={classes.button2}>BACK TO SIGN IN</Button>
                      </div>
                    </form>
                  )}
                />
              </>
            )}
          </div>
        </Dialog>
      </MuiThemeProvider>
    );
  },
);

export default ForgotPassword;
