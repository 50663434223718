// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { Toggle3DLayerAction, Toggle3DOrderAction, SetLayer3DChangeAction } from 'State/viewer3D/actions';

const mapStateToProps = (state: IState) => {
  const { order3DCadFilesList, selected3DCadFiles, cad3dLayers } = state.viewer3D;

  return {
    order3DCadFilesList,
    selected3DCadFiles,
    cad3dLayers,
  };
};
interface IOwnProps {
  lasFilesWith3DTile: ILasFile[];
  showPointCloud: boolean;
  showTerrain: boolean;
  setShowPointCloud: (show: boolean) => void;
  setShowTerrain: (show: boolean) => void;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    Toggle3DOrderAction,
    Toggle3DLayerAction,
    SetLayer3DChangeAction,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
