// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { EmptyAC, PayloadAC, getType } from 'typesafe-actions';
import produce from 'immer';

interface IActionsMap<TState> {
  [key: string]: (draft: TState, payload: any) => void | TState;
}

/*
type ActionHandler<TState, TPayload> = (draft: TState, payload: TPayload) => void;
type Action<TPayload> = PayloadAC<string, TPayload> | EmptyAC<string>;
type HandleAction<TState, TPayload> = (action: Action<TPayload>, actionHandler: ActionHandler<TState, TPayload>) => void;
*/

/* eslint no-useless-return: "error" */
const createImmerReducer = <TState>(
  defaultState: TState,
  reducerConstructor: (handleAction: <TPayload>(action: PayloadAC<string, TPayload> | EmptyAC<string> | string, actionHandler: (draft: TState, payload: TPayload) => void) => void) => void) => {
  const actionsMap: IActionsMap<TState> = {};
  reducerConstructor((action, actionHandler) => {
    const actionAsString = action as string;
    if (actionAsString) {
      actionsMap[actionAsString] = actionHandler;
    } else {
      const actionType = getType(action as EmptyAC<string>);
      actionsMap[actionType] = actionHandler;
    }
  });

  return (
    state = defaultState,
    { type, payload }: { type: string, payload: any },
  ) =>
    produce(state, (draft: TState) => {
      const action = actionsMap[type];
      /* eslint-disable consistent-return */
      if (!action) return;

      const actionResult = action(draft, payload);
      if (actionResult) {
        return actionResult;
      }
    });
};

export default createImmerReducer;
