// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  bundleName: {
    fontSize: '16px',
    textTransform: 'uppercase',
  },
  buttonLabelContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  bundleNameContainer: {
    display: 'flex',
  },
  betaBanner: {
    marginLeft: theme.spacing(1),
    background: 'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    fontSize: '11px',
    padding: '1px 10px',
    color: '#ffffff',
    height: '18px',
  },
  anchorTag: {
    color: theme.palette.primary.main,
  },
  bundleInfoText: {
    fontSize: '11px',
    marginTop: '5px',
  },
});

export default styles;
