// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import CreditCardIcon from 'Common/creditCardIcon';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const Icon = ({ cardType }: PropsType) => {
  const classes = useStyles();

  return (
    <CreditCardIcon cardType={cardType} classes={{ icon: classes.cardIcon }} />
  );
};

export default compose(Icon);
