// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';
import { Paper, Typography, Button, IconButton, Fade, CircularProgress, MenuItem, InputBase, Badge, Select, Tooltip } from '@material-ui/core';
import FileUploadDialog from 'Common/FileUploadForm/SingleFileUploadForm';
import ClearIcon from '@material-ui/icons/Clear';
import downloadIcon from 'Assets/download-icon.png';
import ConfirmDialog from 'Common/ConfirmDialog';
import ConfirmAmountDialog from 'Common/ConfirmAmountDialog';
import aiIcon from 'Assets/ai.png';
import autonmousIcon from 'Assets/autonomous.png';
import LayerSelectionDialog from './layerSelectionDialog';
import KmlFileFormatDialog from './KmlFileFormatDialog';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');
/**
 * Component renders contents of an order card for each order in the project
 */
export const OrderCard = ({ order, projectId, downloadFileStatuses, hasValidEpsg, tifMissing, SetOrderIdAction, GetOrdersAction, ShowUploadDialogAction,
  DownloadLASAction, DownloadPostgisDxfAction, SetOutputLasAction, DownloadSurfaceFileAction, DeleteSurfaceFileAction, SetDownloadFilesStateAction,
  ClearDownloadFilesAction, DeleteDxfFileAction, EditOrderAction, DeleteOutputLasFileAction }: PropsType) => {
  const classes = useStyles();
  const [uploadFileExtension, setUploadFileExtension] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showLayerSelection, setShowLayerSelection] = useState(false);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [adminOutputUpload, setAdminOutputUpload] = useState(false);
  const [showConfirmDeleteXml, setShowConfirmDeleteXml] = useState(false);
  const [showConfirmDeleteDxf, setShowConfirmDeleteDxf] = useState(false);
  const [showConfirmDeleteLas, setShowConfirmDeleteLas] = useState(false);
  const [showKmlDownloadDialog, setShowKmlDownloadDialog] = useState(false);

  const [orderId, setOrderId] = useState('');
  const [xmlId, setXmlId] = useState('');
  const [dxfId, setDxfId] = useState('');
  const [lasId, setLasId] = useState('');
  const [orgId, setOrgId] = useState('');
  const [editOrder, setEditOrder] = useState(false);
  const [bundleEdit, setBundleEdit] = useState('');
  const [densityEdit, setDensityEdit] = useState('');
  const [deliverySpeedEdit, setDeliverySpeedEdit] = useState('');
  const [isEditSubmitting, setIsEditSubmitting] = useState(false);
  const [download, setDownload] = useState(false);

  const { creditAdjustments, bundleName, density, deliverySpeed } = order;

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    e.stopPropagation();
  };

  const onOkDeleteXml = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteXml(false);
    DeleteSurfaceFileAction(xmlId, orderId);
    e.stopPropagation();
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    setShowConfirmDeleteXml(false);
    e.stopPropagation();
  };

  const onOkDeleteDxf = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteDxf(false);
    DeleteDxfFileAction(dxfId, orderId);
    e.stopPropagation();
  };

  const onCancelDxf = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    setShowConfirmDeleteDxf(false);
    e.stopPropagation();
  };

  const onOkDeleteLas = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteLas(false);
    if (lasId && orderId && projectId) {
      DeleteOutputLasFileAction(lasId, orderId, projectId);
    }
    e.stopPropagation();
  };

  const onCancelLas = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteLas(false);
    e.stopPropagation();
  };

  const PrepareOutputFile = async (oId: string, siteId: string) => {
    await SetDownloadFilesStateAction(siteId);
    await DownloadPostgisDxfAction(oId, siteId, true);
  };

  const DownloadOutputFile = async (siteId: string) => {
    // No longer calling a separate API to download the generated dxf
    // using URL returned by checkdxfstatus API
    // Deprecated - 8/12/22 - Ishan
    // await DownloadCustomCadAction(orderId, siteId);
    window.open(downloadFileStatuses.find((f) => f.siteId === siteId)?.url, '_self');
    await ClearDownloadFilesAction(siteId);
  };

  const onKmlUploadClick = (oId: string) => {
    SetOrderIdAction(oId);
    setUploadFileExtension('.kml');
    setAdminOutputUpload(false);
    setShowUploadDialog(true);
  };

  const onDxfUploadClick = (oId: string) => {
    SetOrderIdAction(oId);
    setUploadFileExtension(['.dxf', '.las', '.xml']);
    setAdminOutputUpload(true);
    SetOutputLasAction(true);
    setShowUploadDialog(true);
  };

  useEffect(() => {
    if (showUploadDialog) {
      ShowUploadDialogAction();
      setShowUploadDialog(false);
    }
  }, [showUploadDialog]);

  const ButtonFunction = (oId: string, cadFile: any) => {
    const siteId = cadFile._id;
    if (downloadFileStatuses.some((obj) => (obj.siteId === siteId && obj.fileReadyStatus.dxfFile === 1))) {
      setDownload(false);
      DownloadOutputFile(siteId);
    } else {
      setDownload(true);
      PrepareOutputFile(oId, siteId);
    }
  };

  const resetOrderEdits = () => {
    setBundleEdit('');
    setDensityEdit('');
    setDeliverySpeedEdit('');
  };

  const editOrderClick = (oid: string) => {
    if (oid !== orderId) {
      resetOrderEdits();
    }
    setOrderId(oid);
    setEditOrder(true);
  };

  const cancelEditOrderClick = () => {
    setOrderId('');
    resetOrderEdits();
    setEditOrder(false);
  };

  const editOrderUpdateClick = async () => {
    setIsEditSubmitting(true);
    await EditOrderAction(orderId, bundleEdit, densityEdit, deliverySpeedEdit);
    await GetOrdersAction(projectId);
    setOrderId('');
    resetOrderEdits();
    setEditOrder(false);
    setIsEditSubmitting(false);
  };

  return (
    <>
      <Paper className={classes.card}>
        <Typography className={classes.itemWrapper}>{`${order.ownerOrganization}/${projectId}/${order._id}`}</Typography>
        <div className={classes.orderInfoWrapper}>
          <div>
            <div className={classes.inputTitleWrapper}>
              <Typography variant="h4">Input Files</Typography>
              <Button onClick={() => onKmlUploadClick(order._id)} color="primary">Upload Files</Button>
            </div>
            <div className={classes.cadFileList}>
              {order.boundaryFile && <Typography>{order.boundaryFile.split('/').pop()}</Typography>}
              {order.boundaryFile && (
                <IconButton onClick={() => setShowKmlDownloadDialog(true)}>
                  <img src={downloadIcon} alt="Download .kml" />
                </IconButton>
              )}
            </div>
            <div className={classes.inputTitleWrapper}>
              <Typography variant="h4">Output Files</Typography>
              <Button disabled={!hasValidEpsg || tifMissing} onClick={() => { onDxfUploadClick(order._id); }} color="primary">Upload Files</Button>
            </div>
            <div className={classes.outputFilesWrapper}>
              {(order.outputLasFiles && order.outputLasFiles.length > 0) && order.outputLasFiles.map((file) => (
                <div className={classes.cadFileList}>
                  <Typography>{file.filename && file.filename.split('/').pop()}</Typography>
                  <div>
                    <IconButton onClick={() => {
                      setShowConfirmDeleteLas(true);
                      setLasId(file._id);
                      setOrderId(order._id);
                    }}
                    >
                      <ClearIcon className={classes.deleteIcon} />
                    </IconButton>
                    <IconButton onClick={() => DownloadLASAction(order._id, file.filename.split('/').pop())}>
                      <img src={downloadIcon} alt="Download .las" />
                    </IconButton>
                  </div>
                </div>
              ))}
              {order?.surfaceFiles?.map((file) => (
                <div className={classes.cadFileList}>
                  <Typography>{file.filename}</Typography>
                  <div>
                    <IconButton onClick={() => {
                      setShowConfirmDeleteXml(true);
                      setXmlId(file._id);
                      setOrderId(order._id);
                    }}
                    >
                      <ClearIcon className={classes.deleteIcon} />
                    </IconButton>
                    <IconButton onClick={() => DownloadSurfaceFileAction(file._id, order._id)}>
                      <img src={downloadIcon} alt="Download .xml" />
                    </IconButton>
                  </div>
                </div>
              ))}
              {order?.cadFiles?.map((file) => (
                <div className={classes.cadFileList}>
                  <div className={classes.cadFileName}>
                    {file.expressAIUpload && (
                      <Tooltip title="Express AI" arrow placement="top">
                        <img src={aiIcon} alt="Express AI Upload" />
                      </Tooltip>
                    )}
                    {file.autonomous && (
                      <Tooltip title="Autonomous" arrow placement="top">
                        <img src={autonmousIcon} alt="Autonomous" />
                      </Tooltip>
                    )}
                    <Typography>{file.filename ? file.filename.split('/').pop() : 'original file'}</Typography>
                  </div>
                  {/* TODO - move this logic outside return - Ishan 06/23/2021 */}
                  {downloadFileStatuses.some((obj) => (obj.siteId === file._id && obj.fileReadyStatus.dxfFile === 1) && download) ? (
                    <Badge color="primary" overlap="circle" variant="dot">
                      <IconButton onClick={() => ButtonFunction(order._id, file)}>
                        <img src={downloadIcon} alt="Download .dxf" />
                      </IconButton>
                    </Badge>
                  ) :
                    (downloadFileStatuses.some((obj) => (
                      obj.siteId === file._id && obj.fileReadyStatus.dxfFile === 0)) && download) ?
                        <CircularProgress size={24} className={classes.downloadProgress} /> : (
                        <div>
                            <IconButton onClick={() => {
                            setShowConfirmDeleteDxf(true);
                            setDxfId(file._id);
                            setOrderId(order._id);
                          }}
                          >
                            <ClearIcon className={classes.deleteIcon} />
                          </IconButton>
                            <IconButton onClick={() => ButtonFunction(order._id, file)}>
                            <img src={downloadIcon} alt="Download .dxf" />
                          </IconButton>
                          </div>
                      )}
                </div>
              ))}
              <div>
                {(order?.cadFiles?.some((file) => (file.expressAIUpload === true && !file.expressAIEmailSent))) && (
                  <Button
                    onClick={() => { setShowLayerSelection(true); }}
                    color="primary"
                  >
                    SEND EMAIL
                  </Button>
                )}
                {(order?.cadFiles?.some((file) => file.expressAIEmailSent)) &&
                  <Typography variant="h5" className={classes.emailText}>EMAIL SENT</Typography>}
              </div>
            </div>
          </div>
          <div>
            <div className={classes.gridChild}>
              <Typography className={classes.itemWrapper}>Date Created</Typography>
              {order.createdAt ? <Typography>{moment(order.createdAt).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography className={classes.itemWrapper}>Date Processed</Typography>
              {order.orderDate ? <Typography>{moment(order.orderDate).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography className={classes.itemWrapper}>Est. Delivery Estimate</Typography>
              {order.deliveryEstimate ? <Typography>{moment(order.deliveryEstimate).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography className={classes.itemWrapper}>Delivery Date</Typography>
              {order.deliveryDate ? <Typography>{moment(order.deliveryDate).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography className={classes.itemWrapper}>Kickoff Date</Typography>
              {order.anticipatedKickoffDate ? <Typography>{moment.utc(order.anticipatedKickoffDate).format('MM/DD/YYYY')}</Typography> : <Typography>N/A</Typography>}

              <Typography className={classes.itemWrapper}>Order Acres</Typography>
              <Typography>{order.acreage || 'N/A'}</Typography>

              <Typography className={classes.itemWrapper}>Project Bundle</Typography>
              {editOrder ? (
                <Select
                  variant="outlined"
                  input={<InputBase style={{ width: '100px' }} />}
                  value={bundleEdit || bundleName}
                  onChange={(e) => {
                    if (e.target.value === 'Topo') {
                      setDensityEdit('normal');
                    }
                    setBundleEdit(e.target.value);
                  }}
                >
                  {
                    ['2D', '2D + Topo', 'Topo'].map((bundle) => <MenuItem value={bundle}>{bundle}</MenuItem>)
                  }
                </Select>
              ) :
                <Typography>{bundleName || 'N/A'}</Typography>}
              <Typography className={classes.itemWrapper}>Density</Typography>
              {(editOrder) && (!bundleEdit ? bundleName !== 'Topo' : bundleEdit !== 'Topo') ? (
                <Select
                  variant="outlined"
                  input={<InputBase style={{ width: '100px' }} />}
                  value={densityEdit || density}
                  onChange={(e) => setDensityEdit(e.target.value)}
                >
                  {
                    ['normal', 'low'].map((density) => <MenuItem value={density}>{density}</MenuItem>)
                  }
                </Select>
              ) :
                <Typography>{(!densityEdit ? density : densityEdit)}</Typography>}
              <Typography className={classes.itemWrapper}>Delivery Speed</Typography>
              {editOrder ? (
                <Select
                  variant="outlined"
                  input={<InputBase style={{ width: '100px' }} />}
                  value={deliverySpeedEdit || deliverySpeed}
                  onChange={(e) => setDeliverySpeedEdit(e.target.value)}
                >
                  {
                    ['normal', 'expedited', 'super_expedited'].map((speed) => <MenuItem value={speed}>{speed?.split('_').join(' ')}</MenuItem>)
                  }
                </Select>
              ) :
                <Typography>{deliverySpeed?.split('_').join(' ') || 'N/A'}</Typography>}
              <Typography className={classes.itemWrapper}>Initial Order Credits</Typography>
              <Typography>{order.initialCredits ? order.initialCredits.toFixed(2) : 'N/A'}</Typography>
              <Typography className={classes.itemWrapper}>Total Credits Charged</Typography>
              {editOrder ?
                <Typography>-</Typography> :
                <Typography>{order.creditAcreage ? order.creditAcreage.toFixed(2) : 'N/A'}</Typography>}

              <Typography className={classes.itemWrapper}>Adjusted Credits</Typography>
              <Typography>{order.adjustedAcres ? order.adjustedAcres.toFixed(2) : 0}</Typography>
            </div>
            { (creditAdjustments?.length > 0) &&
                    (
                      <ul className={classes.adjustmentList}>
                        {creditAdjustments.map((a, i) => (
                          <li key={a._id}>
                            <div className={classes.creditAdjustmentItemWrapper}>
                              <p className={classes.adjustmentNumber}>
                                {a.adjustment >= 0 ? '+' : ''}
                                {a.adjustment}
                              </p>
                              <p>{a.reason}</p>
                            </div>
                          </li>
                        ))}
                      </ul>
                    )}
            {editOrder ? (
              <div className={classes.buttonGroup}>
                <Button className={classes.editOrderButtonWrapper} onClick={cancelEditOrderClick} color="primary">Cancel</Button>
                <Button
                  className={classes.editOrderButtonWrapper}
                  onClick={editOrderUpdateClick}
                  disabled={isEditSubmitting || (!bundleEdit && !densityEdit && !deliverySpeedEdit) ||
                    ((bundleEdit !== '' || densityEdit !== '' || deliverySpeedEdit !== '') && (bundleEdit === bundleName) && (densityEdit === density) && (deliverySpeedEdit === deliverySpeed))}
                  color="primary"
                >
                  <Fade in={isEditSubmitting}>
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  </Fade>
                  <Fade in={!isEditSubmitting}>
                    <span>Update</span>
                  </Fade>
                </Button>
              </div>
            ) : (
              <Button
                className={classes.editOrderButtonWrapper}
                onClick={() => editOrderClick(order._id)}
                disabled={order.orderStatus === 0}
                color="primary"
              >
                Edit Order
              </Button>
            )}
          </div>
        </div>
      </Paper>
      <LayerSelectionDialog
        showDialog={showLayerSelection}
        onCancel={() => { setShowLayerSelection(false); }}
        orderId={order._id}
      />
      <KmlFileFormatDialog
        order={order}
        showDialog={showKmlDownloadDialog}
        onCancel={() => setShowKmlDownloadDialog(false)}
      />
      {uploadFileExtension && <FileUploadDialog fileExtension={uploadFileExtension} adminOutputUpload={adminOutputUpload} />}
      <ConfirmDialog
        showDialog={showConfirmDeleteXml}
        onOk={onOkDeleteXml}
        onCancel={onCancel}
        contentText="Selected surface file will be deleted"
      />
      <ConfirmDialog
        showDialog={showConfirmDeleteDxf}
        onOk={onOkDeleteDxf}
        onCancel={onCancelDxf}
        contentText="Selected dxf file will be deleted"
      />
      <ConfirmDialog
        showDialog={showConfirmDeleteLas}
        onOk={onOkDeleteLas}
        onCancel={onCancelLas}
        contentText="Selected las file will be deleted"
      />
      <ConfirmAmountDialog
        showDialog={showConfirmDialog}
        onOk={onOk}
        onCancel={onCancel}
        confirmAmount={+(order.creditAcreage.toFixed(2))}
        orderId={orderId}
        orgId={orgId}
        projectId={projectId}
      />
    </>
  );
};

export default compose(OrderCard);
