// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { UpdateFeature, SetDrawMode, CopyActiveFeature, PasteActiveFeature, CutActiveFeature, UndoDrawing, DeleteActiveFeature, SetCurrentTool, SendPendoEvent } from 'State/map/editor/thunk';
import { styles } from './styles';

const mapStateToProps = (state: IState) => ({
  featureCollection: state.map.editor.present.featureCollection,
  point: state.map.editor.present.clickedPoint,
  currentTool: state.map.editor.present.currentTool,
  modeName: state.map.editor.present.drawMode && state.map.editor.present.drawMode.name,
  activeFeatureId: state.map.editor.present.drawMode && state.map.editor.present.drawMode.params && state.map.editor.present.drawMode.params.featureId,
});

const mapDispatchToProps = {
  UpdateFeatureAction: UpdateFeature,
  SetDrawModeAction: SetDrawMode,
  CopyActiveFeatureAction: CopyActiveFeature,
  PasteActiveFeatureAction: PasteActiveFeature,
  CutActiveFeatureAction: CutActiveFeature,
  DeleteActiveFeatureAction: DeleteActiveFeature,
  UndoDrawingAction: UndoDrawing,
  SetCurrentToolAction: SetCurrentTool,
  SendPendoEventAction: SendPendoEvent,
};

interface IOwnProps {
    map: any;
}

type PropsType =
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    WithStyles<typeof styles> &
    IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  // TODO: Check if we can avoid unnecessary rerenders - Vinutna - 4/14/2021
  // connect(
  //     mapStateToProps,
  //     mapDispatchToProps,
  //     null,
  //     {
  //         areStatePropsEqual: (next: any, prev: any) => {
  //           console.log('areStatePropsEqual  ', next.currentTool === prev.currentTool)
  //           return next.currentTool === prev.currentTool
  //         },
  //       }
  // ),
);
