// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ShowUploadKmlDialog } from 'State/ui/uploadKmlDialog/thunk';
import { SetOrderId } from 'State/order/thunk';
import { ShowUploadOpsTrainingDialog } from 'State/ops/thunk';

interface IOwnProps {
  layers: IVectorTileJson;
  expand: boolean;
  checked:boolean;
  toggleExpand: () => void;
  clickDownload: (event: React.MouseEvent<HTMLElement>) => void;
  orderId: string;
  siteId: string;
}

const mapStateToProps = (state: IState, ownProps: IOwnProps) => ({
  siteId: ownProps.siteId,
  downloadFileStatuses: state.order?.dxfFileStatus,
  anyChangeInFileStatuses: state.order?.anyChangeInFileStatuses,
  opsTrainer: 'opsTrainer' in state.auth.resources,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    SetOrderIdAction: SetOrderId,
    ShowUploadKmlDialogAction: ShowUploadKmlDialog,
    ShowUploadOpsTrainingDialogAction: ShowUploadOpsTrainingDialog,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
