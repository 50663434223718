// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, Fade, CircularProgress, Divider, FormControlLabel, Checkbox, Tooltip } from '@material-ui/core';
import ContentEditable from 'react-contenteditable';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ConfirmDialog from 'Common/ConfirmDialog';
import history from 'Utils/history';
import RouteLeavingGuard from 'Common/RouteLeavingGuard';
import classnames from 'classnames';
import Layers from './layers';
import Sidebar from './sidebar';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders cadTemplates page
 *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {function} props.DeleteTemplateAction Function to delete template
 * @param {function} props.EditTemplateAction Function to edit template name and layer(s)
 * @param {function} props.DuplicateTemplateAction Function to duplicate template
 * @param {function} props.SetTemplateIdAction Function to set the TemplateId property on cadTemplate state
 * @param {function} props.SetTemplateIdAction Function to set the TemplateId property on cadTemplate state
 * @param {boolean} props.isDefaultTemplate Boolean value to check if the tempate is org's default template
 * @param {function} props.DefaultTemplateAction Function to set a default template
 * @param {object} props.template template object
 * @param {string} props.templateId Props - template id
 */
export const CadTemplates = ({ templateId, title, template, isDefaultTemplate, defaultTemplate,
  DuplicateTemplateAction, DeleteTemplateAction, EditTemplateAction, DefaultTemplateAction, SetTemplateIdAction }: PropsType) => {
  const classes = useStyles();
  const [editable, setEditable] = useState(false);
  const [checkIcon, setCheckIcon] = useState(false);
  const [templateTitle, setTemplateTitle] = useState(null);
  const [layersStyle, setLayersStyle] = useState([]);
  const [layersWeight, setLayersWeight] = useState([]);
  const [layersColor, setLayersColor] = useState([]);
  const [layersMapping, setLayersMapping] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [deleteTemplate, setDeleteTemplate] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  const ref = useRef(null);
  const scrollRef = useRef(null);

  const handleIconClick = () => {
    setEditable(true);
    setCheckIcon(true);
    ref.current.focus();
  };

  const handleOnChange = (e: any) => {
    setCheckIcon(true);
    setTemplateTitle(e);
  };

  const handleCheckIconClick = async () => {
    await EditTemplateAction({ _id: templateId, name: templateTitle });
    setCheckIcon(false);
    setEditable(false);
  };

  const deleteTemplateClick = (id: string) => {
    setShowConfirmDialog(true);
    setDeleteTemplate(id);
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    DeleteTemplateAction(deleteTemplate);
    history.push(`/account/cadTemplates/${defaultTemplate}`);
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  useEffect(() => {
    SetTemplateIdAction(templateId);
    setTemplateTitle(title);
    setLayersStyle([]);
    setLayersWeight([]);
    setLayersColor([]);
    scrollRef.current.scrollTop = 0;
  }, [title]);

  const EditLayer = async (id: string) => {
    setSubmitting(true);
    const map = new Map();
    layersStyle.forEach((item) => map.set(item._id, item));
    layersWeight.forEach((item) => map.set(item._id, { ...map.get(item._id), ...item }));
    layersColor.forEach((item) => map.set(item._id, { ...map.get(item._id), ...item }));
    layersMapping.forEach((item) => map.set(item._id, { ...map.get(item._id), ...item }));
    const mergedArr = Array.from(map.values());
    await EditTemplateAction({ _id: id, layers: mergedArr });
    setSubmitting(false);
    setLayersStyle([]);
    setLayersWeight([]);
    setLayersColor([]);
    setLayersMapping([]);
  };

  return (
    <>
      <Sidebar {...{ templateId }} />
      <div className={classes.scrollWrapper} ref={scrollRef}>
        {template && (
          <div>
            <div className={classes.headerWrapper}>
              <div className={classnames(classes.header, { [classes.headerAirworks]: template.airworksTemplate })}>
                <div className={classes.flex}>
                  <Typography variant="h1" color="textPrimary">
                    <ContentEditable
                      html={templateTitle}
                      disabled={!editable}
                      innerRef={ref}
                      className={classes.contentEditable}
                      onChange={(e) => handleOnChange(e.target.value)}
                    />
                  </Typography>
                  {' '}
                  &nbsp;&nbsp;
                  {!editable && !template.airworksTemplate && <EditIcon className={classes.editIcon} onClick={() => handleIconClick()} />}
                  {checkIcon && <CheckIcon className={classes.checkIcon} onClick={() => handleCheckIconClick()} />}
                </div>
                <Button
                  className={classes.button}
                  disabled={template.deleting}
                  onClick={() => DuplicateTemplateAction(template._id)}
                >
                  DUPLICATE
                </Button>
                {!template.airworksTemplate && !isDefaultTemplate && (
                  <Button
                    className={classes.button}
                    disabled={template.deleting}
                    onClick={() => {
                      deleteTemplateClick(template._id);
                    }}
                  >
                    DELETE
                  </Button>
                )}
                <ConfirmDialog
                  showDialog={showConfirmDialog}
                  onOk={onOk}
                  onCancel={onCancel}
                  contentText="Selected template will be removed"
                />
                <div>
                  {!template.airworksTemplate && (
                    <Tooltip title="Please wait while we update your template, this may take a minute." arrow disableHoverListener={!submitting} placement="top">
                      <span>
                        <Button
                          variant="contained"
                          color="primary"
                          className={classes.submitButton}
                          type="submit"
                          disabled={submitting || (layersWeight.length === 0 && layersStyle.length === 0 && layersColor.length === 0 && layersMapping.length === 0)}
                          onClick={() => EditLayer(template._id)}
                        >
                          <Fade in={submitting}>
                            <CircularProgress size={16} className={classes.buttonProgress} />
                          </Fade>
                          <Fade in={!submitting}>
                            <span>Save</span>
                          </Fade>
                        </Button>
                      </span>
                    </Tooltip>
                  )}
                </div>
              </div>
              <Divider className={classes.divider} />
            </div>
            <div className={classes.wrapper}>
              <div className={classes.checkbox}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      color="primary"
                      checked={isDefaultTemplate}
                      onChange={() => DefaultTemplateAction({ templateId: template._id })}
                    />
                  )}
                  label="Set as Default Template"
                />
                <Layers
                  templateId={templateId}
                  setLayersStyle={setLayersStyle}
                  setLayersWeight={setLayersWeight}
                  setLayersColor={setLayersColor}
                  setLayersMapping={setLayersMapping}
                />
              </div>
            </div>
          </div>
        )}
      </div>
      <>
        <RouteLeavingGuard
          when={layersWeight.length > 0 || layersStyle.length > 0 || layersColor.length > 0 || layersMapping.length > 0}
          shouldBlockNavigation={(location) => (!!location.pathname)}
          navigate={(path) => history.push(path)}
          onSaveChanges={() => EditLayer(templateId)}
        />
      </>
    </>
  );
};

export default compose(CadTemplates);
