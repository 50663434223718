// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import LanguageIcon from '@material-ui/icons/Language';
import { Tooltip } from '@material-ui/core';
import coordinatesIcon from 'Assets/coordinates-icon.png';
import container, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders coordinates on map mouse hover
 *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {number} props.epsgCode Props - epsgCode of the project
 * @param {number} props.lat Props - latitude
 * @param {number} props.lng Props - longitude
 * @param {string} props.projCS Props - projection coordinate system
 * @param {string} props.geogCS Props - geographic coordinate system
 * @param {string} props.datum Props - datum
 * @param {number} props.xCoordinate Props - xCoordinate - transform point coordinates from one coordinate system to another(4326 to epsgCode)
 * @param {string} props.yCoordinate Props - yCoordinate - transform point coordinates from one coordinate system to another(4326 to epsgCode)
 * @param {function} props.GetEpsgProjectionsAction Function to get the projections data
 * @param {function} props.ResetLatAndLngAction Function to reset lat and long on page load
 */
export const EpsgProjection = ({ epsgCode, epsgAuthName, lat, lng, projCS, geogCS, datum, xCoordinate, yCoordinate, GetEpsgProjectionsAction, ResetLatAndLngAction }: PropsType) => {
  const classes = useStyles();
  const [toolTipTitle, settoolTipTitle] = useState('');
  const onLoad = async () => {
    GetEpsgProjectionsAction(epsgCode);
    ResetLatAndLngAction();
  };

  const openToolTip = () => {
    settoolTipTitle(`PROJCS: ${projCS},\n GEOGCS: ${geogCS},\n DATUM: ${datum}`);
  };

  useEffect(() => {
    onLoad();
  }, [epsgCode]);

  return (
    <div>
      { ((lat && lng && !Number.isNaN(lat) && !Number.isNaN(lng)) ||
        (epsgAuthName !== 'USER' && epsgCode > 0 && geogCS) ||
        (xCoordinate && yCoordinate && !Number.isNaN(xCoordinate) && !Number.isNaN(yCoordinate))) && (
        <div className={classes.info}>
          {lat && lng && !Number.isNaN(lat) && !Number.isNaN(lng) ? (
            <>
              <LanguageIcon fontSize="small" className={classes.languageIcon} />
              <span>
                <span className={classes.latValue}>{`Lat: ${lat.toFixed(6)}`}</span>
                <span className={classes.lngValue}>{`Lon: ${lng.toFixed(6)}`}</span>
              </span>
            </>
          ) : null}
          {epsgAuthName !== 'USER' && epsgCode > 0 && geogCS && (
            <Tooltip title={toolTipTitle} placement="top" classes={{ tooltip: classes.tooltip }}>
              <span style={{ cursor: 'pointer' }} onMouseEnter={() => openToolTip()}>
                <img className={classes.coordinatesIcon} src={coordinatesIcon} alt="Coordinates icon" />
                <span className={classes.geogCS}>{` ${geogCS}` }</span>
                <span>&#8212;</span>
                <span>{` EPSG: ${epsgCode}`}</span>
              </span>
            </Tooltip>
          )}
          {xCoordinate && yCoordinate && !Number.isNaN(xCoordinate) && !Number.isNaN(yCoordinate) ? (
            <span className={classes.coordinates}>
              <span className={classes.latValue}>{ `X: ${xCoordinate}`}</span>
              <span className={classes.lngValue}>{` Y: ${yCoordinate}`}</span>
            </span>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default container(EpsgProjection);
