// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import ErrorIcon from '@material-ui/icons/Error';
import {
  Button,
  Collapse,
  Typography,
  Fade,
  CircularProgress,
  Dialog,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { TextField, Select } from 'Common/forms';
import compose from './container';

const EditOrder = compose(({ classes, orderId, referenceId, availableTeams, availableTeamNames, currentTeam, activeOrder, team, GetOrgTeamsAction, EditOrderAction }) => {
  const [open, setOpen] = useState(false);
  const [initialFormState, setInitialFormState] = useState(undefined);

  const handleClickOpen = () => {
    setInitialFormState({ referenceId, team: currentTeam?.name });
    setOpen(true);
  };

  useEffect(() => {
    GetOrgTeamsAction();
  }, []);

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = async (values: any) => {
    const teamInput = values.team || '';
    const referenceIdInput = values.referenceId || '';
    const selectedTeam = availableTeams.find((t) => t.name === teamInput);
    const teamChange = teamInput !== currentTeam?.name ? teamInput : 'N/A';
    const referenceIdChange = referenceIdInput !== activeOrder.referenceId ? referenceIdInput : 'N/A';

    window?.pendo?.track('Order Checkout', { team_name: teamChange, ref_id: referenceIdChange });

    await EditOrderAction(orderId, null, null, null, referenceIdInput, selectedTeam?._id);

    handleClose();
  };

  return (
    <div>
      <div className={classes.title}>
        <div>
          <Typography className={classes.sectionTitle} variant="h3">Order Details</Typography>
        </div>
        <Button
          className={classes.addTeamButton}
          onClick={handleClickOpen}
        >
          EDIT
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Form
          onSubmit={onFormSubmit}
          keepDirtyOnReinitialize
          initialValues={initialFormState}
          render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, submitError, handleSubmit }) => (
            <form
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <Typography variant="h2" className={classes.heading}>Edit Order</Typography>
              <div className={classes.formRow}>
                <TextField
                  label="Reference Id"
                  name="referenceId"
                  variant="outlined"
                  inputProps={{
                    name: referenceId,
                    classes: {
                      notchedOutline: classes.outline,
                      input: classes.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: classes.inputLabel,
                      shrink: classes.inputLabelShrink,
                    },
                  }}
                />
              </div>
              {availableTeamNames.length ? (
                <div className={classes.formRow}>
                  <Select
                    label="Team"
                    name="team"
                    values={availableTeamNames}
                    disabled
                    inputProps={{
                      name: team,
                      classes: {
                        notchedOutline: classes.outline,
                        input: classes.input,
                      },
                    }}
                    inputLabelProps={{
                      classes: {
                        outlined: classes.inputLabel,
                        shrink: classes.inputLabelShrink,
                      },
                    }}
                  />
                </div>
              ) : ''}
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                type="submit"
                disabled={(hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} className={classes.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>UPDATE</span>
                </Fade>
              </Button>
              <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                <div className={classes.errorMessage}>
                  <ErrorIcon />
                  <Typography variant="body1" className={classes.messageText}>
                    {submitError}
                  </Typography>
                </div>
              </Collapse>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
});

export default EditOrder;
