// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const SearchAsync = createAsyncAction(
  'MAP_SEARCH_START',
  'MAP_SEARCH_SUCCESS',
  'MAP_SEARCH_FAILED',
)<null, IPlacesSearchFeature[], string>();

export const ClearSuggestionsAction = createAction('MAP_CLEAR_SUGGESTIONS', (action) => () => action());
