// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  submitButton: {
    boxShadow: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(9),
    float: 'right',
    marginBottom: 'auto',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  modalText: {
    paddingTop: '12px',
  },
  warningText: {
    color: '#F95264',
  },
  form: {
    margin: '10px 20px',
    width: 'inherit',
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '20px',
  },
  heading: {
    marginBottom: '12px',
  },
  acceessText: {
    margin: '10px 0',
  },
  chips: {
    flexWrap: 'wrap',
  },
  chipRoot: {
    '&:nth-child(n+4)': {
      display: 'none',
    },
    borderRadius: '0',
    color: '#01A1DD',
  },
  chip: {
    margin: 2,
  },
  selectTeams: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  badge: {
    height: '30px',
    width: '40px',
    backgroundColor: '#01A1DD',
    position: 'absolute',
    right: '25px',
    top: '8px',
    color: '#FFFFFF',
    borderRadius: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '29px',
  },
  checked: {
    color: '#01A1DD !important',
  },
  selected: {
    color: '#01A1DD',
  },
  formRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(2),
  },
  outline: {
    borderRadius: 0,
    borderColor: 'rgba(0,0,0,0.1) !important',
    boxShadow: theme.lightShadow,
  },
  input: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabel: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabelShrink: {
    transform: 'translate(14px, -7px) scale(0.75) !important',
  },
  checkboxes: {
    flexDirection: 'row',
    gap: '30%',
  },
  snackBar: {
    top: '140px',
  },
  alert: {
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
  },
  error: {
    textTransform: 'capitalize',
    display: 'flex',
    alignItems: 'center',
  },
}));

export default useStyles;
