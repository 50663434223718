// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Button } from '@material-ui/core';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders info for one Stripe invoice
 *
 * @param {object} props Component props
 * @param {IInvoice} props.invoice Object containing Stripe invoice details
 */

export const InvoiceInfo = ({ invoice }: PropsType) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.dateWrapper}>
        <Typography variant="h6">{invoice.createdParsed || 'Unavailable'}</Typography>
      </div>
      <div className={classes.priceWrapper}>
        <Typography>
          {invoice.amountDue ? `$${invoice.amountDue}` : 'Unavailable'}
        </Typography>
      </div>
      {invoice.hostedInvoiceUrl && <Button color="primary" href={invoice.hostedInvoiceUrl} target="_blank" rel="noreferrer">View</Button>}
    </div>
  );
};

export default compose(InvoiceInfo);
