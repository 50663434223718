// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dialogBox: {
    padding: theme.spacing(3),
    width: '500px',
  },
  dialogActions: {
    padding: '8px 0',
  },
  dialogText: {
    fontSize: '18px',
    marginBottom: theme.spacing(2),
  },
  datePicker: {
    width: '100%',
    height: '60px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 0,
    borderColor: 'rgba(0,0,0,0.1) !important',
    boxShadow: theme.lightShadow,
    '& .MuiInput-underline:before': {
      border: 'none !important',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiFormLabel-root': {
      paddingLeft: '14px',
      fontWeight: 300,
      letterSpacing: '1.3px',
      fontSize: '21px',
      lineHeight: '14px',
    },
    '& .MuiInput-formControl': {
      margin: 0,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(5px, -5px) scale(0.75) !important',
    },
    '& .MuiInputBase-input': {
      paddingLeft: '14px',
      fontWeight: 300,
      letterSpacing: '1.3px',
      fontSize: '21px',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '40px',
      left: '14px',
      fontSize: '10px',
    },
  },
  calender: {
    transform: 'translate(56px, 15px) !important',
  },
}));

export default useStyles;
