// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { connect } from 'react-redux';
import component from './component';

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(component);
