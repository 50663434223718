// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { InputBase, IconButton, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders the Editable Text Input Field
 *
 * @param {string} initialValue Initial value to display
 * @param {function} updateValue Function to update the input value
 * @param {function} formatValue Function to format the input value to display
 */
export const TextEditor = ({ initialValue, updateValue, formatValue }: PropsType) => {
  const classes = useStyles();
  const [input, setInput] = useState(null);
  const [editable, setEditable] = useState(false);

  const startEditing = () => {
    setInput(initialValue);
    setEditable(true);
  };

  const updateInput = () => {
    // only update value when input has changed and is exist
    if (input && input.trim() !== initialValue) {
      updateValue(input);
    }
    setInput(null);
    setEditable(false);
  };

  return (
    <div className={classes.container}>
      {editable ? (
        <>
          <InputBase
            className={classes.inputField}
            autoFocus
            value={input}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === "Escape") {
                e.preventDefault();
                e.stopPropagation();
                updateInput();
              }
            }}
          />
          <IconButton className={classes.icon}
            aria-label="check"
            onClick={updateInput}
          >
            <CheckIcon />
          </IconButton>
        </>
      ) : (
        <>
          <Typography className={classes.value}>{formatValue ? formatValue(initialValue) : initialValue || 'N/A'}</Typography>
          <IconButton className={classes.icon}
            aria-label="edit"
            onClick={startEditing}
          >
            <EditIcon />
          </IconButton>
        </>
      )}
    </div>
  );
};

export default compose(TextEditor);
