// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Typography, Paper, ListItem, Divider, IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { Link } from 'react-router-dom';
import { GetTemplateRoute } from 'Utils/routes';
import history from 'Utils/history';
import ConfirmDialog from 'Common/ConfirmDialog';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders template items
 *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {array} props.list List of all cad templates
 * @param {object} props.org Props - current org object
 * @param {object} props.template template object
 * @param {function} props.DeleteTemplateAction Function to delete template
 * @param {function} props.DuplicateTemplateAction Function to duplicate template
 * @param {string} props.templateId Props - template id
 */
export const TemplateItems = ({ list, templateId, org, index, template, DeleteTemplateAction, DuplicateTemplateAction }: PropsType) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const [deleteTemplate, setDeleteTemplate] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [buttonHover, setButtonHover] = useState(false);

  const clickOpenPopper = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    e.preventDefault();
  };

  const deleteTemplateClick = (templateId: string, e: any) => {
    setShowConfirmDialog(true);
    setDeleteTemplate(templateId);
    e.preventDefault();
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    DeleteTemplateAction(deleteTemplate);
    history.push(GetTemplateRoute(org.defaultTemplate));
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };
  return (
    <>
      <ListItem
        onMouseEnter={() => { setButtonHover(true); }}
        onMouseLeave={() => { setButtonHover(false); }}
        selected={template._id === templateId}
        component={Link}
        to={GetTemplateRoute(template._id)}
        button
        className={classes.templateItem}
      >
        <Typography variant="h5">{template.name}</Typography>
        { org.defaultTemplate !== template._id ? (
          <IconButton className={classes.vertButton} onClick={clickOpenPopper}>
            { template._id === templateId ? <MoreVertIcon /> : buttonHover && <MoreVertIcon /> }
          </IconButton>
        ) : <Typography className={classes.defaultItem} variant="h6">Default</Typography> }
        <Menu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          keepMounted
          open={isMenuOpen}
        >
          <MenuItem onClick={() => DuplicateTemplateAction(template._id)}> Duplicate </MenuItem>
          {!template.airworksTemplate && (
            <MenuItem onClick={(e) => {
              setAnchorEl(null);
              deleteTemplateClick(template._id, e);
            }}
            >
              {' '}
              Delete
            </MenuItem>
          ) }
        </Menu>
      </ListItem>
      {index !== list.length - 1 && <Divider />}
      <ConfirmDialog
        showDialog={showConfirmDialog}
        onOk={onOk}
        onCancel={onCancel}
        contentText="Selected template will be removed"
      />
    </>
  );
};

export default compose(TemplateItems);
