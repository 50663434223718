// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Paper, Grid } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { darkTheme } from 'Utils/themes';
import logo from 'Assets/logo.png';

import Login from './Login';
import compose from './container';
import SignUp from './SignUp';
import SignUpSuccess from './signUpSuccess';
import ExpiredToken from './ExpiredToken';

const Auth = compose(({ classes, match }) => (
  <MuiThemeProvider theme={darkTheme}>
    <div className={classes.videoWrapper}>
      <video loop muted autoPlay className={classes.video}>
        <source src="https://content.airworks.io/sign-in_bg_video_11mb.mp4" type="video/mp4" />
      </video>
    </div>
    <div className={classes.gridWrapper}>
      <img src={logo} alt="AirWorks logo" className={classes.logo} />
      <Grid className={classes.grid} container justify="center" alignContent="center" alignItems="center">
        <div className={classes.stripeGradient}>
          <br />
        </div>
        <Grid item md={7}>
          <Paper className={classes.form} elevation={1} square>
            <div className={classes.displayChild}>
              { match.path === '/' && <Login /> }
              { match.path === '/expiredToken' && <ExpiredToken /> }
              { match.path === '/signUp' && <SignUp /> }
              { match.path === '/signUpSuccess' && <SignUpSuccess /> }
            </div>
          </Paper>
        </Grid>
      </Grid>
    </div>
  </MuiThemeProvider>
));

export default Auth;
