// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from "react";
import { Button } from "@material-ui/core";
import SVG from "react-inlinesvg";
import layers from "Assets/layers.svg";
import classnames from 'classnames';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const LayersPanel = ({ currentPanel, SetCurrentPanelAction }: PropsType) => {
  const classes = useStyles();

  return (
    <div className={classnames({ [classes.panelCurrent]: currentPanel === 'layers' })}>
      <Button className={classes.option} onClick={() => {
        SetCurrentPanelAction("layers");
      }}>
        <SVG src={layers} title="layers" className={classes.icon} />
      </Button>
    </div>
  );
};

export default compose(LayersPanel);
