// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  pageControls: {
    width: '100%',
    position: 'fixed',
    minHeight: '80px',
    maxHeight: '80px',
    background: 'white',
    zIndex: 2,
  },
  pageControlsSub: {
    width: '1400px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: theme.spacing(4),
    gridTemplateAreas: '"backButton breadcrumbs close"',
  },
  pageWrapper: {
    height: '100%',
    marginTop: '80px',
    width: '1200px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '8fr 4fr',
    gridColumnGap: '120px',
  },
  closeButtonWrapper: {
    marginTop: theme.spacing(2),
    gridArea: 'close',
    justifySelf: 'end',
    zIndex: 2000,
    color: 'gray',
  },
  backButton: {
    marginTop: theme.spacing(2),
    gridArea: 'backButton',
    justifySelf: 'start',
    zIndex: 2000,
    color: 'gray',
    width: theme.spacing(8),
  },
  pageTitle: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    fontWeight: 300,
    fontSize: '33px',
  },
  cancelOption: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
  },
  sectionTitle: {
    fontSize: '24px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  optionTitle: {
    fontSize: '20px',
  },
  cadOption: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
    marginBottom: theme.spacing(5),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  button: {
    borderRadius: '0px',
    backgroundColor: '#EAEBEF',
    border: 'none',
    width: '150px',
    height: '45px',
  },
  selected: {
    backgroundColor: 'white',
    border: '2px solid #01A1DD',
  },
  billingPeriod: {
    backgroundColor: 'white',
    width: '150px',
    height: '45px',
    border: '2px solid #EAEBEF',
    padding: '10px',
    textAlign: 'center',
  },
  icons: {
    display: 'flex',
  },
  iconWrapper: {
    marginRight: theme.spacing(1),
    width: theme.spacing(5),
  },
  seatsField: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
      height: '45px',
      width: '150px',
    },
    '& .MuiInputBase-input': {
      textAlign: 'center',
    },
  },
  iconButton: {
    border: '1px solid #bbb',
    height: '45px',
    width: '45px',
  },
  offerText: {
    color: '#01A1DD',
    marginTop: '5px',
  },
  optionsButton: {
    color: '#01A1DD',
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '19px',
    marginTop: theme.spacing(2),
  },
  routerLinks: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  stripeGradient: {
    lineHeight: '8px',
    background: 'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    [theme.breakpoints.up('md')]: {
      maxWidth: '100%',
    },
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(10),
  },
  warningTitle: {
    fontSize: '18px',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  warningMessageText: {
    fontSize: '16px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  warningMessage: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto 1fr',
    gridColumnGap: '20px',
    marginBottom: theme.spacing(2),
  },
  warningMessageSubText: {
    fontSize: '14px',
    fontWeight: 300,
  },
  cardIcon: {
    height: '23px',
    width: '23px',
  },
});

export default styles;
