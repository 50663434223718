// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { GetOrg, SetOrgId } from 'State/admin/thunk';
import { GetOrgSubscription } from 'State/subscription/thunk';
import { GetImageryProviders } from 'State/imagery/thunk';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps<{ orgId: string }>) => {
  const { org } = state.admin;
  const { cadSubscription, automateSubscription } = state.admin;
  return {
    orgId: ownProps.match.params.orgId,
    org,
    cadSubscription,
    automateSubscription,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      GetOrgAction: GetOrg,
      GetOrgSubscriptionAction: GetOrgSubscription,
      SetOrgIdAction: SetOrgId,
      GetImageryProvidersAction: GetImageryProviders,
    }, dispatch,
  );

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ orgId: string }>;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
