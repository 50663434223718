// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { AddOrgTeam } from 'State/account/thunk';

const mapDispatchToProps = {
  AddOrgTeamAction: AddOrgTeam,
};

interface IOwnProps {
  setSnackMessage: (message: string) => void;
  setShowSnackbar: (show: boolean) => void;
}

export type PropsType =
  IOwnProps &
  typeof mapDispatchToProps;

export default compose<PropsType, {}>(
  connect(null, mapDispatchToProps),
);
