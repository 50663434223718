// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link, Typography, Button, CircularProgress, Fade, Collapse } from '@material-ui/core';
import { Form } from 'react-final-form';
import history from 'Utils/history';
import { ROOT_ROUTE } from 'Utils/routes';
import { TextField, Validators, Helpers } from 'Common/forms';
import {
  clearLocalStorage,
} from 'Utils/localStorageService';
import { clearSessionStorage } from 'Utils/sessionStorageService';
import { withOktaAuth, useOktaAuth } from '@okta/okta-react';
import ReCAPTCHA from 'react-google-recaptcha';
import compose from './container';

const LoginForm = compose(({ classes, failedLoginAttempts, LoginAction }) => {
  const [disableSubmit, setDisableSubmit] = useState(true);
  const captchaRef = useRef(null);

  const { oktaAuth } = _ADMIN_ && useOktaAuth();

  const onLogin = () => {
    oktaAuth.signInWithRedirect({ originalUri: '/admin' });
  };
  useEffect(() => {
    if (failedLoginAttempts > 2) {
      captchaRef.current.reset();
      setDisableSubmit(true);
    }
  }, [failedLoginAttempts]);

  const onFormSubmit = (values: any) => Helpers.submit(values, LoginAction);

  useEffect(() => {
    const currentLocation = history.location.pathname;
    if (currentLocation === ROOT_ROUTE) {
      clearLocalStorage();
      clearSessionStorage();
    }
  }, []);

  return (
    <div>
      <Typography className={classes.header} variant="h1">
        {_ADMIN_ ? 'Airworks Admin Portal' : 'Log in to AirWorks'}
      </Typography>
      {!_ADMIN_ && (
        <Typography className={classes.header} variant="h5">
          Don’t have an AirWorks account?
          {' '}
          <Link
            component={RouterLink}
            className={classes.forgotText}
            to="/signUp"
          >
            Sign up now.
          </Link>
        </Typography>
      )}
      {_ADMIN_ ? (
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.buttonOkta}
            onClick={onLogin}
          >
            Click here for Okta login
          </Button>
        </div>
      ) : (
        <Form
          onSubmit={onFormSubmit}
          render={({ hasSubmitErrors, submitting, modifiedSinceLastSubmit, handleSubmit, submitError }) => (
            <form
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <TextField
                name="email"
                label="Email Address"
                autoComplete="email"
                autoFocus
                validate={Validators.composeValidators(Validators.required, Validators.email)}
              />
              <TextField
                name="password"
                label="Password"
                type="password"
                validate={Validators.composeValidators(Validators.required, Validators.minLength7)}
              />
              <Typography variant="caption">
                <Link component={RouterLink} className={classes.forgotText} to="/forgot">Forgot Password?</Link>
              </Typography>
              <Collapse in={Boolean((hasSubmitErrors && !modifiedSinceLastSubmit))}>
                <Typography variant="body1" className={classes.errorMessage}>
                  {submitError}
                </Typography>
              </Collapse>
              {failedLoginAttempts > 2 && (
                <div className={classes.captcha}>
                  <ReCAPTCHA
                    sitekey={_GOOGLE_CAPTCHA_SITE_KEY_}
                    onChange={() => setDisableSubmit(false)}
                    ref={captchaRef}
                  />
                </div>

              ) }
              <div className={classes.buttonWrapper}>
                <Button
                  type="submit"
                  variant="outlined"
                  className={classes.button}
                  disabled={(hasSubmitErrors && !modifiedSinceLastSubmit) || submitting || (failedLoginAttempts > 2 ? disableSubmit : false)}
                >
                  <Fade in={submitting}><CircularProgress size={24} className={classes.buttonProgress} /></Fade>
                  <Fade in={!submitting}><span>Sign in</span></Fade>
                </Button>
              </div>
            </form>
          )}
        />
      )}
    </div>
  );
});

export default LoginForm;
