// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { CircularProgress, InputBase } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import compose from './container';

const Input = compose(({ classes, mapSearchLoading, setSearchInputValue, inputRef = () => { }, inputProps: { ref, ...other } }) => (
  <div className={classes.autosuggestInputWrapper}>
    <SearchIcon className={classes.searchIcon} />
    <InputBase
      inputRef={(node) => {
        ref(node);
        inputRef(node);
      }}
      classes={{
        root: classes.autosuggestInput,
      }}
      {...other}
    />
    {mapSearchLoading
      && <CircularProgress className={classes.autosuggestProgress} size={20} />}
    {other.value
      && <CloseIcon className={classes.autosuggestClearIcon} onClick={() => setSearchInputValue('')} />}
  </div>
));

export default Input;
