// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  infoWrapper: {
    display: 'flex',
    padding: '25px',
    flexDirection: 'column',
  },
  dialogWrapper: {
    height: '30%',
    width: '40%',
  },
  inputWrapper: {
    alignItems: 'baseline',
    display: 'flex',
  },
  input: {
    width: '30%',
    marginRight: '25px',
  },
  title: {
    padding: '25px',
  },
  subTitle: {
    paddingLeft: '25px',
  },
}));

export default useStyles;
