// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { postJson } from 'Utils/http';
import { API_URL } from 'Config';
import deliveryEstimate from 'Utils/deliverables';
import { getOrders } from 'State/order/selectors';
import { GetBundlesAction, SetDefaultBundleAction, ResetBundleStateAction, SelectedAdditionalDeliverablesAndLayersAction, SetDeliverablesAction, SetTopoMajrAction, SetTopoMinrAction, SetDensityAction, SetDeliveryDaysAction, SetDeliveryDateAction } from './actions';

// Gets all bundles
export const GetBundles = (orderId: string, projectId: string, isEstimate = false) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/deliverables/getDeliverables`;
    const { token } = getState().auth;

    dispatch(GetBundlesAction.request());
    const data = { orderId, projectId };
    const result = await postJson<IBundleResponse>(url, data, token);
    let bundleName = '2D + Topo';
    let density = 'normal';
    let deliverySpeed = 'normal';
    if (result.success) {
      dispatch(GetBundlesAction.success(result.data));
      const { activeOrder } = getOrders(getState());
      if (isEstimate) {
        bundleName = activeOrder.bundleName;
        density = activeOrder.density;
        deliverySpeed = activeOrder.deliverySpeed;
      }
      const bundles = result.data.deliverables.groupings;
      const findBundles = bundles.find((b) => b.name === (bundleName || '2D + Topo') && b.density === (density || 'normal') && b.deliverySpeed === (deliverySpeed || 'normal'));
      const { additionalDeliverables, bundleDeliveryTime, bundleId } = findBundles;
      dispatch(SetDefaultBundleAction(findBundles));

      if (isEstimate) {
        let deliveryTime = bundleDeliveryTime;
        const { addOns } = activeOrder;
        addOns.forEach((addOn) => {
          const additional = additionalDeliverables.find((deliverable: IDeliverable) => deliverable.name === addOn.name);
          deliveryTime += additional.time;
          dispatch(SelectedAdditionalDeliverablesAndLayersAction(additional));
        });
        // If there are selected add-ons in A la carte bundle, add 2 days to the delivery time
        if (addOns.length > 0 && bundleId === 5) {
          if (deliverySpeed === 'expedited' || density === 'low') deliveryTime += 1.5;
          else if (deliverySpeed === 'super_expedited') deliveryTime += 1;
          else deliveryTime += 2;
        }
        dispatch(SetDeliveryDaysAction(deliveryTime));
        dispatch(SetDeliveryDateAction(deliveryEstimate(deliveryTime)));
      } else {
        dispatch(SetDeliveryDaysAction(bundleDeliveryTime));
        dispatch(SetDeliveryDateAction(deliveryEstimate(bundleDeliveryTime)));
      }
    } else {
      dispatch(GetBundlesAction.failure(result.message));
    }
    return {
      deliverySpeed,
    };
  };

export const SetDeliverables = (bundle: IBundle, keepAdditionals: boolean) => (dispatch: Dispatch, getState: () => IState) => {
  const { selectedAdditionals } = getState().bundle;
  const { additionalDeliverables, bundleDeliveryTime, bundleId, deliverySpeed, density } = bundle;

  dispatch(SetDeliverablesAction(bundle));
  // keepAdditionals boolean is true when density and delivery speed are changed, and false when bundle is changed
  if (selectedAdditionals.length > 0 && keepAdditionals) {
    let deliveryTime = bundleDeliveryTime;
    selectedAdditionals.forEach((addOn) => {
      const additional = additionalDeliverables.find((deliverable: IDeliverable) => deliverable.name === addOn.name);
      deliveryTime += additional.time;
      dispatch(SelectedAdditionalDeliverablesAndLayersAction(additional));
    });
    // If there are selectedAdditionals in A la carte bundle, add 2 days to the delivery time
    if (bundleId === 5) {
      if (deliverySpeed === 'expedited' || density === 'low') deliveryTime += 1.5;
      else if (deliverySpeed === 'super_expedited') deliveryTime += 1;
      else deliveryTime += 2;
    }
    dispatch(SetDeliveryDaysAction(deliveryTime));
    dispatch(SetDeliveryDateAction(deliveryEstimate(deliveryTime)));
  }
};

export const SelectedAdditionalDeliverablesAndLayers = (additionals: IDeliverable) => (dispatch: Dispatch) => dispatch(SelectedAdditionalDeliverablesAndLayersAction(additionals));

export const ResetBundleState = () => (dispatch: Dispatch) => dispatch(ResetBundleStateAction());

export const SetTopoMajr = (topoMajr: number) => (dispatch: Dispatch) => dispatch(SetTopoMajrAction(topoMajr));

export const SetTopoMinr = (topoMinr: number) => (dispatch: Dispatch) => dispatch(SetTopoMinrAction(topoMinr));

export const SetDensity = (density: string) => (dispatch: Dispatch) => dispatch(SetDensityAction(density));
