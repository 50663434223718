// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Typography, Divider, IconButton, Snackbar } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import ConfirmDialog from 'Common/ConfirmDialog';
import history from 'Utils/history';
import { clearLocalStorage } from 'Utils/localStorageService';
import Alert from '@material-ui/lab/Alert';
import AddTeamForm from './addTeam';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const Teams = ({ organizationAccess, GetOrgTeamsAction, orgTeams, UpdateOrgTeamAction, DeleteOrgTeamAction, teamError }: PropsType) => {
  const classes = useStyles();
  const [deleteTeam, setDeleteTeam] = useState('');
  const [editTeam, setEditTeam] = useState('');
  const [editTeamId, setEditTeamId] = useState('');
  const [snackMessage, setSnackMessage] = useState('');
  const [edittingTeamName, setEdittingTeamName] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    if (!organizationAccess) {
      clearLocalStorage();
      history.push('/');
    }
  }, [organizationAccess]);

  useEffect(() => {
    if (teamError !== null) {
      setShowSnackbar(true);
    }
  }, [teamError]);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const renderColumnHeader = (columnMeta: any) => (
    <td key={columnMeta.name} className={classes.columnHeader}>
      <Typography variant="h4">{columnMeta.label}</Typography>
    </td>
  );

  const deleteTeamClick = (team: string) => {
    setShowConfirmDialog(true);
    setDeleteTeam(team);
  };

  const onDeleteTeam = async () => {
    setShowConfirmDialog(false);
    const result = await DeleteOrgTeamAction(deleteTeam);
    if (result.success) {
      setSnackMessage('Team Deleted');
      setShowSnackbar(true);
    }
  };

  const editTeamClick = (id: string) => {
    setEditTeamId(id);
    setEdittingTeamName(true);
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  const handleTeamEdit = (e) => {
    setEditTeam(e.target.value);
  };

  const onUpdateTeam = async () => {
    window?.pendo?.track('Team Updated', { team_name: editTeam, team_id: editTeamId });

    const result = await UpdateOrgTeamAction(editTeamId, editTeam);

    if (result.success) {
      setSnackMessage('Team Updated');
      setShowSnackbar(true);
    }
    setEdittingTeamName(false);
    setEditTeam('');
    setEditTeamId('');
  };

  const columns = [
    {
      name: 'name',
      label: 'NAME',
      options: {
        customHeadRender: renderColumnHeader,
        customBodyRender: (value: string, tableMeta: { rowData : any }) => (
          <div className={classes.nameColumn}>
            {edittingTeamName && tableMeta.rowData[2] === editTeamId ? (
              <input
                defaultValue={value}
                className={classes.editInput}
                onChange={(e) => { handleTeamEdit(e); }}
              />
            ) : value}
          </div>
        ),
      },
    },
    {
      name: 'admin',
      empty: true,
      options: {
        customHeadRender: (): void => null,
        customBodyRender: (admin: boolean, tableMeta: { rowData : any }) => (
          <div className={classes.iconButtons}>
            {edittingTeamName && tableMeta.rowData[2] === editTeamId ? (
              <>
                <IconButton
                  onClick={() => setEdittingTeamName(false)}
                >
                  <ClearIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => onUpdateTeam()}
                >
                  <CheckIcon fontSize="small" />
                </IconButton>
              </>
            ) : (
              <>
                <IconButton
                  onClick={() => editTeamClick(tableMeta.rowData[2])}
                >
                  <EditIcon fontSize="small" />
                </IconButton>
                <IconButton
                  onClick={() => deleteTeamClick(tableMeta.rowData[2])}
                >
                  <DeleteIcon fontSize="small" />
                </IconButton>
              </>
            )}
          </div>
        ),
      },
    },
    {
      name: '_id',
      label: '_id',
      options: { display: false },
    },
  ];

  const options = {
    elevation: 0,
    responsive: 'stacked',
    serverSide: true,
    selectableRows: 'none',
    print: false,
    download: false,
    viewColumns: false,
    sort: false,
    search: false,
    filter: false,
    pagination: false,
    onTableInit: () => { GetOrgTeamsAction(); },
  };
  return (
    <div className={classes.pageWrapper}>
      <Snackbar className={classes.snackBar} open={showSnackbar} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        {teamError ? <Alert severity="error" className={classes.alert} color="error">{teamError}</Alert> : <Alert severity="success" className={classes.alert} color="info">{snackMessage}</Alert>}
      </Snackbar>
      <div className={classes.pageWrapper}>
        <AddTeamForm setSnackMessage={setSnackMessage} setShowSnackbar={setShowSnackbar} />
        <Divider className={classes.divider} />
        <MUIDataTable
          data={orgTeams}
          columns={columns}
          options={options}
          className={classes.table}
        />
        <ConfirmDialog
          showDialog={showConfirmDialog}
          onOk={onDeleteTeam}
          onCancel={onCancel}
          contentText="Are you sure you want to delete this team? This action can't be undone and will be removed from all relevant users."
        />
      </div>
    </div>
  );
};

export default compose(Teams);
