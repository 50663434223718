// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson } from 'Utils/http';
import { MAPBOX_KEY, MAPBOX_URL } from 'Config';
import { SearchAsync, ClearSuggestionsAction } from './actions';

interface ISearchResponse {
  // tslint:disable-next-line: array-type
  features: {
    text: string;
    place_name: string;
    center: [number, number];
    bbox: [number, number, number, number];
  }[];
}

export const MapSearch = (query: string) =>
  async (dispatch: Dispatch) => {
    const url = `${MAPBOX_URL}/mapbox.places/${query}.json?access_token=${MAPBOX_KEY}`;
    dispatch(SearchAsync.request());
    const result = await getJson<ISearchResponse>(url);

    const suggestions = result.data.features.map((feature) => ({
      text: feature.text,
      description: feature.place_name,
      center: feature.center,
      bbox: feature.bbox,
    }));

    dispatch(SearchAsync.success(suggestions));
  };

export const MapClearSuggestions = () =>
  async (dispatch: Dispatch) => {
    dispatch(ClearSuggestionsAction());
  };
