// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Clear, StartDraw, CancelDraw, SetMeasurementMode, SetCurrentMeasurementOption } from 'State/map/draw/thunk';
import { UpdateEntity, SetCurrentTool, SetEditorMode, SetDrawMode } from 'State/map/editor/thunk';

const mapStateToProps = (state: IState) => ({
  mode: state.map.draw.present.mode,
  currentMeasurementOption: state.map.draw.present.currentMeasurementOption,
  editorFunctions: 'editorFunctions' in state.auth.resources,
  currentTool: state.map.editor.present.currentTool,
});

const mapDispatchToProps = {
  StartDrawAction: StartDraw,
  CancelDrawAction: CancelDraw,
  ClearMapAction: Clear,
  SetMeasurementModeAction: SetMeasurementMode,
  SetCurrentMeasurementOptionAction: SetCurrentMeasurementOption,
  UpdateEntityAction: UpdateEntity,
  SetCurrentToolAction: SetCurrentTool,
  SetEditorModeAction: SetEditorMode,
  SetDrawModeAction: SetDrawMode,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
