// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { PlaceOrder } from 'State/order/thunk';
import { ToggleImageryProcessing } from 'State/project/thunk';
import { getOrders } from 'State/order/selectors';
import { GetTemplates, GetDefaultTemplate } from 'State/cadTemplate/thunk';
import { SetDeliverables, SetTopoMajr, SetTopoMinr } from 'State/bundle/thunk';
import { SetDeliveryDateAction, SetDeliveryDaysAction } from 'State/bundle/actions';

const mapStateToProps = (state: IState) => {
  const { activeOrder } = getOrders(state);
  const defaultTemplateId = state.account.org.defaultTemplate;
  const templates = state.cadTemplate.list;
  const defaultTemplate = templates.find((u) => u._id === defaultTemplateId);
  const { epsgCode, epsgProjections } = state.project;
  const geogCSStart = epsgProjections && epsgProjections.search('GEOGCS');
  const geogCS = geogCSStart && geogCSStart !== -1 ? epsgProjections.substring(geogCSStart).match(/"([^"]+)"/)[1] : 'Unavailable';

  const { orgTeams } = state.account;
  const { teams } = state.auth.user;
  const { selectedBundle, loading, totalCost, topoMajr, topoMinr, selectedAdditionals, density, densityOptions, deliveryDays, bundleDeliverables } = state.bundle;
  const { imageryCreditCost, imageryDollarCost, imageryExtraDollarCost, acreAdjustmentFactor, adjustedOrderAcres } = selectedBundle || {};

  const availableTeamNames: string[] = [];
  function isUserTeam(value: ITeam) {
    if (teams.includes(value._id)) {
      availableTeamNames.push(value.name);
      return value._id;
    }
    return false;
  }
  const availableTeams = orgTeams.filter((t) => isUserTeam(t));
  return {
    order: activeOrder,
    loading,
    selectedBundle,
    availableCreditAcreage: state.account.org.availableCreditAcreage,
    automatePlanType: state.subscription.automateSubscription.priceName,
    selectedBundleName: selectedBundle?.name,
    selectedBundleCost: selectedBundle?.bundleCost,
    imageryCreditCost: imageryCreditCost || 0,
    imageryDollarCost: imageryDollarCost || 0,
    imageryExtraDollarCost: imageryExtraDollarCost || 0,
    acreAdjustmentFactor,
    adjustedOrderAcres,
    defaultCostPerAcre: selectedBundle?.costPerFactors[1],
    template: state.cadTemplate.list,
    org: state.account.org,
    allowExpeditedProcess: state.globalStatus.status.allowExpeditedProcess,
    projectName: state.project.project.name,
    extraDeliverablesLength: selectedAdditionals.filter((u) => u.type === 'deliverable').length,
    extraLayersLength: selectedAdditionals.filter((u) => u.type === 'layer').length,
    defaultTemplate,
    totalCost,
    isAdmin: state.account.user.role[0],
    topoMajr,
    topoMinr,
    placeOrderSuccess: state.order.placeOrderSuccess,
    selectedAdditionals,
    cadTemplates: 'cadTemplates' in state.auth.resources,
    density,
    densityOptions,
    bundles: state.bundle.list,
    availableBundles: state.bundle.availableBundles,
    creditCards: state.subscription.creditCards,
    geogCS,
    epsgCode,
    deliveryDays,
    screenshot: state.map.common.screenshot,
    bundleDeliverables,
    availableTeams,
    imageryRequest: state.project.project.imageryRequest,
    dailyCreditCommitment: state.globalStatus.status.dailyCreditCommitment,
  };
};

const mapDispatchToProps = {
  PlaceOrderAction: PlaceOrder,
  GetTemplatesAction: GetTemplates,
  GetDefaultTemplateAction: GetDefaultTemplate,
  SetTopoMajrAction: SetTopoMajr,
  SetTopoMinrAction: SetTopoMinr,
  SetDeliverablesAction: SetDeliverables,
  SetDeliveryDaysAction,
  SetDeliveryDateAction,
  ToggleImageryProcessing,
};

interface IOwnProps {
  setOrderFormVisible: (visible: boolean) => void;
  goToNextPage: () => void;
  setPage: (arg0: number) => void;
  cadTemplate: string;
  setCadTemplate: (arg0: string) => void;
  setDeliverySpeed: (arg0: string) => void;
  deliverySpeed: string;
  isSubmitting: boolean;
  isSaved: boolean;
  isSaving: boolean,
  firstTimeChangeEstimate: boolean,
  saveEstimate: () => void;
  currentOrderAcreage: number,
  middlePoint: string,
  referenceId: string | null,
  team: string | null,
  coordinates: string,
  page: number,
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  bundleStep: number;
  addOnLayerAcres: number;
  addOnDeliverableAcres: number;
  addOnDeliverableDollars: number;
  addOnLayerDollars: number;
  cards: boolean;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
