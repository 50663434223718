// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { ReactElement } from 'react';

interface IOwnProps {
    showDialog: boolean;
    onAccept?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    error: boolean;
    titleText: string;
    acceptText?: string;
    cancelText: string;
    children?: ReactElement;
}

export type PropsType = IOwnProps;

export default compose<PropsType, IOwnProps>();
