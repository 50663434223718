// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  kmlUploadInfoGrid: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    columnGap: theme.spacing(1.5),
  },
  imageryRequestCard: {
    border: 'solid 1px #D9DDE1',
    borderTop: '5px solid #FAA738',
    borderRadius: '5px',
    background: '#fff',
    marginTop: 0,
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
  },
  imageryRequestBarUpdate: {
    borderTop: '5px solid #243FDD',
  },
  imageryRequestContent: {
    padding: theme.spacing(1),
  },
  imageryBottomContent: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  submitButton: {
    float: 'right',
    color: '#243FDD',
  },
  imageryInfoContent: {
    display: 'flex',
    alignItems: 'center',
  },
  imageryRequestTitle: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
    color: '#000',
  },
  imageryRequestTitleText: {
    paddingLeft: '10px',
  },
  imageryRequestDetails: {
    display: 'flex',
    fontSize: 14,
    marginTop: theme.spacing(1),
  },
  buttonProgress: {
    position: 'relative',
    left: '40px',
  },
  checkbox: {
    '&.Mui-disabled': {
      cursor: 'not-allowed',
    },
  },
  imageryRequestActionContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  imageryProcessingButton: {
    float: 'right',
  },
  imageryCoverageLoading: {
    display: 'flex',
    alignItems: 'center',
  },
  imageryCoverageLoadingText: {
    paddingLeft: '15px',
  },
  processingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
  },
  uploadYourOwnData: {
    color: '#778791',
    fontSize: '12px',
    fontWeight: 400,
  },
  imageryButton: {
    color: '#243FDD',
  },
  imageryRequestIcon: {
    color: '#FAA738',
    marginRight: '10px',
  },
  imageryRequestIconUpdate: {
    color: theme.palette.primary.main,
    marginRight: '10px',
  },
  imageryRequestControls: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  kmlUploadIcon: {
    height: '36px',
    width: '36px',
  },
  kmlUploadText: {
    fontSize: '12px',
    fontWeight: 400,
  },
  requestImageryButton: {
    padding: 0,
  },
  anchorTag: {
    color: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  timer: {
    color: '#2E4DEB',
    fontWeight: 400,
  },
}));

export default useStyles;
