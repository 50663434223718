// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { DeleteUser } from 'State/account/thunk';
import { GetOrgSubscription } from 'State/subscription/thunk';
import { SetOrgId } from 'State/admin/thunk';
import { ClearOrgAccount } from 'State/account/actions';
import { GetOrders } from 'State/order/thunk';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps<{ orgId: string }>) => {
  const { orgId } = ownProps.match.params;
  const cadSubscription = _ADMIN_ ? state.admin.cadSubscription : state.subscription?.cadSubscription;

  return ({
    orgId,
    orgUsers: state.account.orgUsers,
    orgRoles: state.account.orgRoles,
    orders: state.order.list,
    cadSubscription,
    cadSubscriptionExists: cadSubscription && Object.keys(cadSubscription).length > 0,
  });
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    SetOrgIdAction: SetOrgId,
    DeleteUserAction: DeleteUser,
    GetOrgSubscriptionAction: GetOrgSubscription,
    ClearOrgAccountAction: ClearOrgAccount,
    GetOrdersAction: GetOrders,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
