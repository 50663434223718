// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import BillingColumn from 'Components/common/OrderDialog/OrderForm/BillingColumn';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import InfoIcon from 'Assets/info-icon.svg';
import MoneyIcon from 'Assets/money-icon.svg';
import SVG from 'react-inlinesvg';
import { Button, IconButton, Typography, TextField, Divider, Card } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import classnames from 'classnames';
import history from 'Utils/history';
import PaymentConfirmation from 'Common/PaymentConfirmation';
import { Link as RouterLink } from 'react-router-dom';
import { USER_MANAGEMENT_ROUTE, PROJECTS_ROUTE } from 'Utils/routes';
import moment from 'moment-timezone';
import compose from './container';
import CancelSubscription from './CancelSubscription';
import PlanDetails from './PlanDetails';

moment.tz.setDefault('America/New_York');

const SubscriptionForm = compose(({ classes, cadSubscriptionExists, cadSubscription, ClearPromoCodeInfoAction, GetProratedChargeAction, GetCreditCardsAction, setShowSubscriptionDialog, GetOrgSubscriptionAction }) => {
  const [page, setPage] = useState(0);
  const [cards, setCards] = useState(false);
  const [billingPeriod, setBillingPeriod] = useState('annual');
  const [cadSeats, setCadSeats] = useState(1);
  const [cadSeatsChanged, setCadSeatsChanged] = useState(0);
  const [daysRemaining, setDaysRemaining] = useState(0);
  const [showCancelSubscriptionDialog, setShowCancelSubscriptionDialog] = React.useState(false);

  const cancelSubscriptionClick = () => {
    // API call to get the subscription info (Maybe this isn't needed? Might be good to have the most up-to-date stuff)
    GetOrgSubscriptionAction();
    setShowCancelSubscriptionDialog(true);
  };

  const handleClose = () => {
    setShowCancelSubscriptionDialog(false);
  };

  useEffect(() => {
    GetCreditCardsAction();
    if (cadSubscription && Object.keys(cadSubscription).length > 0) {
      const currentDate = moment();
      const endDate = moment(cadSubscription.cadCurrentPeriodEnd);
      const difference = endDate.diff(currentDate, 'days');
      setDaysRemaining(difference);
      !cadSubscription.trial ? setBillingPeriod(cadSubscription.interval) : setBillingPeriod('annual');
      setCadSeats(cadSubscription.cadSeatsAvailable);
    }
    return () => GetOrgSubscriptionAction();
  }, []);

  const onClose = () => {
    GetOrgSubscriptionAction();
    setShowSubscriptionDialog(false);
    ClearPromoCodeInfoAction();
    if (!_ADMIN_) {
      history.push('/account/billing');
    }
  };

  const changeCadSeats = async (change: string) => {
    let newNumberOfSeats = cadSeats;
    newNumberOfSeats = change === 'subtract' ? newNumberOfSeats - 1 : newNumberOfSeats + 1;
    if (!cadSubscriptionExists || cadSubscription.trial) {
      setCadSeats(newNumberOfSeats);
    } else {
      setCadSeatsChanged(newNumberOfSeats - cadSubscription.cadSeatsAvailable);
      setCadSeats(newNumberOfSeats);
      await GetProratedChargeAction(newNumberOfSeats);
    }
  };

  return (
    <div>
      <div className={classes.pageControls}>
        <div className={classes.pageControlsSub}>
          <div className={classes.backButton}>
            {(page > 0) && (
              <IconButton color="inherit" aria-label="Back" onClick={() => setPage(page - 1)}>
                <KeyboardBackspaceIcon fontSize="large" />
              </IconButton>
            )}
          </div>
          <div />
          <div className={classes.closeButtonWrapper}>
            <IconButton color="inherit" onClick={onClose} aria-label="Close">
              <CloseIcon fontSize="large" />
            </IconButton>
          </div>
        </div>
      </div>
      <div className={classes.pageWrapper}>
        {page === 0 && (
          <>
            <div>
              <Typography className={classes.pageTitle} variant="h1">{`${cadSubscriptionExists && !cadSubscription.trial ? 'Manage your' : 'Buy your'} AirWorks CAD Subscription`}</Typography>
              <div className={classes.cancelOption}>
                <Typography className={classes.sectionTitle} variant="h3">Your Subscription</Typography>
                {cadSubscriptionExists && !cadSubscription.trial ? (
                  <Button variant="text" onClick={cancelSubscriptionClick}>
                    CANCEL PLAN
                  </Button>
                ) : <div />}
              </div>
              <div className={classes.cadOption}>
                <div>
                  <Typography className={classes.optionTitle} variant="h3">Billing period</Typography>
                  <Typography className={classes.offerText} variant="h5">Save 33% with an annual plan!</Typography>
                </div>
                {cadSubscriptionExists && !cadSubscription.trial ? (
                  <div className={classes.optionTitle}>
                    <Typography className={classes.billingPeriod} variant="h4">{billingPeriod}</Typography>
                  </div>
                ) : (
                  <div>
                    <Button className={classnames(classes.button, { [classes.selected]: billingPeriod === 'annual' })} onClick={() => { setBillingPeriod('annual'); }}>
                      ANNUAL
                    </Button>
                    <Button className={classnames(classes.button, { [classes.selected]: billingPeriod === 'monthly' })} variant="outlined" onClick={() => { setBillingPeriod('monthly'); }}>
                      MONTHLY
                    </Button>
                  </div>
                )}
              </div>
              <div className={classes.cadOption}>
                <Typography className={classes.optionTitle} variant="h3">Number of Licenses</Typography>
                <div>
                  <IconButton className={classes.iconButton} disabled={cadSeats < 2} color="inherit" aria-label="subtract" onClick={() => { changeCadSeats('subtract'); }}>
                    <RemoveIcon />
                  </IconButton>
                  <TextField
                    className={classes.seatsField}
                    variant="outlined"
                    value={cadSeats}
                    onChange={(e) => e.target.value && setCadSeats(parseInt(e.target.value))}
                  />
                  <IconButton className={classes.iconButton} color="inherit" onClick={() => { changeCadSeats('add'); }} aria-label="add">
                    <AddIcon />
                  </IconButton>
                </div>
              </div>
              {(cadSeatsChanged !== 0) && (
                <div>
                  <div className={classes.stripeGradient}>
                    <br />
                  </div>
                  <Card className={classes.cardContent}>
                    <Typography className={classes.optionTitle} variant="h3">{`You are ${cadSeatsChanged > 0 ? 'adding' : 'removing'} ${Math.abs(cadSeatsChanged)} license(s). You now have ${cadSeats} license(s) total.`}</Typography>
                    <Typography className={classes.warningTitle} variant="h3">What happens next:</Typography>
                    <div className={classes.warningMessage}>
                      <SVG src={InfoIcon} title="Info" className={classes.cardIcon} />
                      <Typography className={classes.warningMessageText} variant="h5">{`${cadSeatsChanged > 0 ? 'Your new license(s) will be added immediately' : 'Your license(s) will be removed immediately'}`}</Typography>
                      <div />
                      <Typography className={classes.warningMessageSubText} variant="h5">
                        {`${cadSeatsChanged > 0 ? 'You can assign additional licenses to users today' : 'The CAD license(s) will be removed from your users immediately. You may need to reassign your remaining licenses.'}`}
                      </Typography>
                    </div>
                    {cadSeatsChanged > 0 && (
                      <div className={classes.warningMessage}>
                        <SVG src={MoneyIcon} title="Money" className={classes.cardIcon} />
                        <Typography className={classes.warningMessageText} variant="h5">
                          {`${cadSeatsChanged > 0 ? `There are ${daysRemaining} days remaning in your pay period. You will pay a prorated amount for your new license(s).` :
                            'Your license(s) will be removed immediately'}`}
                        </Typography>
                        <div />
                        <Typography className={classes.warningMessageSubText} variant="h5">
                          {`${cadSeatsChanged > 0 ? 'You will pay a prorated amount for your license(s) today.'
                            : 'The CAD license(s) will be removed from your users immediately. You may need to reassign your remaining licenses.'}`}
                        </Typography>
                      </div>
                    )}
                  </Card>
                </div>
              )}
              <Divider className={classes.divider} />
              <Typography className={classes.sectionTitle} variant="h3">Payment</Typography>
              <BillingColumn setCards={setCards} />
            </div>
          </>
        )}

        {page === 1 && (
          <>
            <div>
              <Typography className={classes.pageTitle} variant="h1">{`Your subscription ${cadSubscriptionExists ? 'has been updated' : 'is ready'}`}</Typography>
              <Typography variant="h3">
                Thank you for your purchase! Your account admin will receive a confirmation and receipt email shortly.
              </Typography>
              <div className={classes.routerLinks}>
                <RouterLink to={USER_MANAGEMENT_ROUTE} title="Manage Users">
                  <Typography variant="h5" className={classes.optionsButton}>
                    Manage Users
                  </Typography>
                </RouterLink>
                <RouterLink to={PROJECTS_ROUTE} title="View Usage and Limits">
                  <Typography variant="h5" className={classes.optionsButton}>
                    View Dashboard
                  </Typography>
                </RouterLink>
              </div>
              <PaymentConfirmation />
            </div>
          </>
        )}

        <PlanDetails {...{ cadSubscriptionExists, daysRemaining, billingPeriod, cadSeats, cadSeatsChanged, cards, page, setPage }} />
      </div>
      <CancelSubscription {...{ showCancelSubscriptionDialog, handleClose }} />
    </div>
  );
});

export default SubscriptionForm;
