// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  headerRow: {
    display: 'flex',
    width: '100%',
  },
  pageWrapper: {
    marginTop: theme.spacing(5),
  },
  table: {
    width: '95%',
    margin: '0 auto',
  },
  tableCellHeader: {
    borderBottom: 'none',
    width: '180px',
    height: '50px',
    marginLeft: '16px',
  },
  tableCellHeadeStyle: {
    borderBottom: 'none',
    marginLeft: theme.spacing(2),
    width: '235px',
    textAlign: 'left',
    height: '50px',
  },
  tableCellRoot: {
    paddingLeft: '0px',
  },
  colorCellHeader: {
    width: '115px',
  },
  loading: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(3),
  },
}));

export default useStyles;
