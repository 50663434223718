// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  promoField: {
    border: '1px solid black',
    '& .MuiOutlinedInput-root': {
      borderRadius: '0px',
      height: '40px',
      width: '100%',
    },
  },
  errorMessage: {
    fontSize: '14px',
    color: 'red',
  },
  submitButton: {
    height: '40px',
    backgroundColor: '#01A1DD',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#01A1DD !important',
    },
  },
  promoCodeForm: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
    gridColumnGap: theme.spacing(2),
  },
});

export default styles;
