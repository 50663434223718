// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { SetProjectId, readThumbnail, DeleteProject } from 'State/project/thunk';

import { getNotificationsByProject } from 'State/notification/selectors';

import { getOrders } from 'State/order/selectors';

const mapStateToProps = (state: IState, pr: IOwnProps) => {
  const notifications = getNotificationsByProject(state);
  const { projectOrders } = getOrders(state);

  const orderFinalized = projectOrders.some((o) => o.orderStatus === 7);

  const expressAIReady = projectOrders?.some((order) => order.cadFiles?.some((cadfile) => (cadfile.expressAIUpload && cadfile.expressAIEmailSent)));

  // Check if any of the orders in the project are being processed
  const projectProcessing = projectOrders.some((order) => [1, 2, 3, 4, 5, 6].includes(order.orderStatus));
  // Check if project is created by the current user
  const isProjectCreatedByUser = pr.project.owner === state.auth.user._id;
  // Variable to check if a project can be deleted
  const cannotDelete = projectProcessing || !isProjectCreatedByUser;
  const orderStatus = state.order.orderStatus[pr.project._id];
  const orderEstimate = state.order.orderEstimate.includes(pr.project._id);
  // Changes made for the new pizza tracker states, Commented previous states that were used for the old progress bar
  // new states:
  // isInProgress - when an order in this state we display the the pizza tracker (orderStatus = 1,2,3,4,5,6)
  // placed - when an order is placed (orderStatus = 1)
  // autonomous - when an order is placed and pipeline runs (orderStatus = 1, 2, 3)
  // cleaning - when an order is being manually drafted/ in QC (orderStatus = 4, 5, 6)
  // complete - when a dxf is uploaded for an order (orderStatus = 7)
  return {
    isInProgress: orderStatus && (orderStatus > 0 && orderStatus < 7),
    placed: orderStatus && orderStatus === 1 ? 0 : 0,
    autonomous: orderStatus && (orderStatus === 1 || orderStatus === 2 || orderStatus === 3) ? 1 : 0,
    cleaning: orderStatus && (orderStatus === 4 || orderStatus === 5 || orderStatus === 6) ? 2 : 0,
    complete: orderStatus && orderStatus === 7 ? 3 : 0,
    hasNotification: notifications[pr.project._id],
    cannotDelete,
    orderFinalized,
    expressAIReady,
    orderEstimate,
    orderStatus,
    projectDetailsTab: 'projectDetailsTab' in state.auth.resources,
    opsTrainer: 'opsTrainer' in state.auth.resources,
  };
};

const mapDispatchToProps = {
  DeleteProjectAction: DeleteProject,
  SetProjectIdAction: SetProjectId,
  GetThumbnailAction: readThumbnail,
};

interface IOwnProps {
  project: IProject;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
