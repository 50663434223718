// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { EditCreditCard } from 'State/subscription/thunk';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => ({
  initialValues: ownProps.card,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    EditCreditCardAction: EditCreditCard,
  }, dispatch);

interface IOwnProps {
  card: ICreditCard;
  onCancel: () => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
