// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import {
  InputBase,
  Popover, MenuItem, Select, Box, TableCell
} from '@material-ui/core';
import { ACI } from "Utils/autoCadIndexes";
import { CompactPicker } from "react-color";
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders the popover that contains dropdowns to change line weight and color
 * @param {object} props Component props
 * @param {object} Layer - Object containing the layer properties that is selected from the sidebar
 * @param {string} siteId - ID of the drawing that is being worked on in the Map component
 * @param {function} EditLayerAttributeAction - Function to edit the later attributes that are being changed in the component
 */
export const LinePropertiesFlyout = ({ layer, siteId, EditLayerAttributeAction }: PropsType) => {
  const classes = useStyles();
  const [popOverOpen, setPopoverOpen] = useState(null);

  const handlePopoverClick = (event: any) => {
    setPopoverOpen(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setPopoverOpen(null);
  };

  const handleChangeWeight = async (layerId: any, e: any) => {
    const newLineWidth = e.target.value;
    EditLayerAttributeAction(layerId, siteId, { lineWidth: newLineWidth });
  };

  const handleChangeColor = async (layerId: string, color: { hex: string }, e) => {
    const newColor = color.hex;
    EditLayerAttributeAction(layerId, siteId, { color: newColor });
  };

  let lineWeight: number[] = [];
  for (let i = -3; i <= 100; i++) {
    lineWeight.push(i);
  }

  // Contains line weights and corresponding heights
  const lineWeightDropdownContents = lineWeight.map(weight =>
  (weight < 10 ?
    (
      <MenuItem value={weight}>
        <hr className={classes.horizontalLine} style={{ 'height': '0.0' + (weight) + 'mm' }} />
        {weight}
      </MenuItem>
    )
    : weight > 9 && weight < 100 ? (
      <MenuItem value={weight}>
        <hr className={classes.horizontalLine} style={{ 'height': '0.' + (weight) + 'mm' }} />
        {weight}
      </MenuItem>
    ) : (
      <MenuItem value={weight}>
        <hr className={classes.horizontalLine} style={{ height: '1mm', backgroundColor: '#000000' }} />
        {weight}
      </MenuItem>
    )
  )
  )

  const open = Boolean(popOverOpen);
  const id = open ? "simple-popover" : undefined;

  // @ts-ignore
  return (
    <>
      <div
        onClick={handlePopoverClick}
        className={classes.layerColor}
        style={{ backgroundColor: (layer.color) }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={popOverOpen}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right"
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left"
        }}
        className={classes.popover}
      >
        <span className={classes.typography}>
          Line Color
        </span>
        <Box className={classes.borderStyle}>
          <TableCell className={classes.tableCell} component='th' scope='row'>
            <div className={classes.colorInput}>
              <div className={classes.layerColorInput}
                style={{ 'backgroundColor': (layer.color) }}
              >
              </div>
              <span>{ACI.getByHEX(layer.color.toUpperCase())}</span>
              <Select
                classes={{ root: classes.selectWeight, }}
                input={<InputBase style={{ width: '100%' }} />}
                value={layer.color}
                variant='outlined'>
                <CompactPicker
                  defaultValue={layer.color}
                  colors={ACI.list}
                  onChangeComplete={(color, event) => handleChangeColor(layer.layerId, color, event)}
                />
              </Select>
            </div>
          </TableCell>
        </Box>

        <span className={classes.typography}>
          Line Weight
        </span>
        <Box className={classes.borderStyle}>
          <TableCell className={classes.tableCell} component='th' scope='row'>
            <Select
              classes={{
                root: classes.selectWeight,
              }}
              input={<InputBase style={{ width: '100%' }} />}
              value={layer.lineWidth}
              variant='outlined'
              onChange={(e) => handleChangeWeight(layer.layerId, e)}
            >
              {lineWeightDropdownContents}
            </Select>
          </TableCell>
        </Box>
      </Popover>
    </>
  );
};

export default compose(LinePropertiesFlyout);
