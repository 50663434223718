// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  outputConfidenceBorder: {
    borderStyle: 'solid',
    padding: '20px 0',
    borderColor: '#7DD5D8',
    marginBottom: theme.spacing(2),
    borderWidth: '1px',
  },
  errorIcon: {
    marginLeft: '15px',
    height: '30px',
    width: '30px',
    color: '#01A1DD',
    float: 'left',
  },
  dataTypes: {
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    fontWeight: 400,
    fontSize: '15px',
  },
  outputConfidence: {
    textAlign: 'left',
    paddingLeft: '55px',
    fontWeight: 400,
    fontSize: '15px',
  },
  uploadForm: {
    margin: '0 auto',
    width: 600,
    textAlign: 'center',
    height: '100%',
  },
  openFileDialogLink: {
    color: theme.palette.secondary.main,
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    font: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  uploadFormText: {
    display: 'inline',
  },
  uploadFormIcon: {
    marginBottom: theme.spacing(1),
  },
  formText: {
    marginTop: theme.spacing(2),
    fontWeight: 400,
    fontSize: '15px',
  },
  uploadFormHeader: {
    marginBottom: theme.spacing(1),
  },
  uploadFormSubmit: {
    paddingLeft: theme.spacing(11),
    paddingRight: theme.spacing(11),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  progressSectionsWrapper: {
    marginBottom: 30,
    marginTop: 20,
    paddingRight: 20,
    maxHeight: 200,
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
    },
    '&::-webkit-scrollbar:vertical': {
      width: '11px',
    },
    '&::-webkit-scrollbar:horizontal': {
      height: '11px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
      border: '2px solid white',
      backgroundColor: 'rgba(0, 0, 0, .5)',
    },
  },
  progressSection: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
    position: 'relative',
  },
  fileExtension: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    margin: theme.spacing(1),
    width: 55,
  },
  fileExtensionText: {
    color: 'white',
  },
  progress: {
    flex: 1,
  },
  progressText: {
    textAlign: 'start',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  backgroundMediumBlue: {
    backgroundColor: '#3369A0',
  },
  backgroundAqua: {
    backgroundColor: '#7DD5D8',
  },
  backgroundGold: {
    backgroundColor: '#FAA738',
  },
  progressButtons: {
    position: 'absolute',
    right: 0,
  },
  progressButton: {
    padding: theme.spacing(0.5),
  },
  cancelIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
  },
  finishedIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
    marginRight: theme.spacing(1),
    color: theme.palette.darkGrey,
  },
  anchorTag: {
    color: '#7DD5D8',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
}));

export default useStyles;
