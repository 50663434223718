// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const isProduction = _ENV_ === 'production';
const isTest = _ENV_ === 'test';
const isQa = _ENV_ === 'qa';
const isDev = _ENV_ === 'dev';
const buildVersion = '[AIV]{version}[/AIV]';

export const BUILD_VERSION = buildVersion;

export const DD_ENV =
  (isProduction && 'prod') ||
  (isTest && 'stage') ||
  'dev';

export const URL =
(isProduction && 'https://cloud.airworks.io') ||
(isTest && 'https://test.cloud.airworks.io') ||
(isQa && 'https://qa.cloud.airworks.io') ||
'https://dev.cloud.airworks.io';

export const API_URL =
  (isProduction && 'https://api.airworks.io') ||
  (isTest && 'https://test.api.airworks.io') ||
  (isQa && 'https://qa.api.airworks.io') ||
  'https://dev.api.airworks.io';

export const S3_STORAGE_URL =
  (isProduction && 'https://fileupload.airworks.io') ||
  (isTest && 'https://test.fileupload.airworks.io') ||
  (isQa && 'https://qa.fileupload.airworks.io') ||
  'https://dev.fileupload.airworks.io';

const TERMS_AND_CONDITIONS_URL =
  'https://airworks-terms-and-conditions-files-prod.s3.amazonaws.com';

export const MAPBOX_URL = 'https://api.mapbox.com/geocoding/v5';
export const MAPBOX_KEY = 'pk.eyJ1Ijoic29mdHdhcmVhaXJ3b3JrcyIsImEiOiJjandndmppMWcwMDF5NDhwa2ttend0d2ZhIn0.-VBuyD7PiS6Geo5A1tGbeQ';

export const ESRI_API_Key = 'AAPK1ce75b4e31b14315b75e506a7c36c9b5b54KnIswbAO2SEod607bOLuWkZtQzvx1gbCHoGjAgV3T8MeINhMKzARcbsRK0Mlh';

export const CESIUM_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI0ZDUzYzk5ZS04ODdkLTRiYmQtYmE1YS0wMTEyYTRhMDBmODUiLCJpZCI6MTIyMTk1LCJpYXQiOjE2NzQ2NjE4NzN9.ONf_R_natmkflibyxIAZakaIP49iqacxFZaPW0fF9zc';

export const MAP_TILESERVER_URL =
  (isProduction && 'https://tiles.airworks.io') ||
  (isTest && 'https://test.tiles.airworks.io') ||
  (isQa && 'https://qa.tiles.airworks.io') ||
  'https://dev.tiles.airworks.io';

export const LOCALE = 'en-US';

export const STRIPE_URL = 'https://api.stripe.com/v1';
export const STRIPE_KEY =
  (isProduction && 'pk_live_WlvwLPv0qBlePTN9Bx2DiLfN00Y1Pq10jW') ||
  (isTest && 'pk_test_4hHDux0VQQyAmt9bnNggPaor00pDXKggEV') ||
  'pk_test_4hHDux0VQQyAmt9bnNggPaor00pDXKggEV';

export const MAP_LAYERS_POLLING_INTERVAL_IN_MILLISECONDS = 3000;
export const MAP_HIGH_RES_TILES_POLLING_INTERVAL_IN_MILLISECONDS = 30000;

export const GetTifFileSizeUrl = (orgId: string, projectId: string, fileName: string) => (
  `${S3_STORAGE_URL}/${orgId}/${projectId}/${fileName}.tif`
);

export const GetRasterMetadataUrl = (userId: string, projectId: string, fileName: string) => (
  `${MAP_TILESERVER_URL}/${userId}/${projectId}/${fileName}/metadata.json`
);

export const GetRasterLogFileUrl = (userId: string, projectId: string, fileName: string) => (
  `${MAP_TILESERVER_URL}/${userId}/${projectId}/${fileName}/log.txt`
);

export const GetVectorMetadataUrl = (userId: string, projectId: string, orderId: string, fileVersion: string) => (
  `${MAP_TILESERVER_URL}/${userId}/${projectId}/${orderId}/${fileVersion}/metadata.json`
);

export const GetTermsAndConditionsUrl = (html: string) => (
  `${TERMS_AND_CONDITIONS_URL}/${html}`
);

export const GetRasterTilePbfUrl = (orgId: string, projectId: string, fileName:string, isTms: boolean) => (
  isTms ?
    `${MAP_TILESERVER_URL}/${orgId}/${projectId}/${fileName}/{z}/{x}/{y}.png` :
    `${MAP_TILESERVER_URL}/${orgId}/${projectId}/${fileName}/{z}/{x}/{y}.pbf`
);

export const GetVectorTilePbfUrl = (userId: string, projectId: string, orderId: string, fileVersion: string) => (
  `${MAP_TILESERVER_URL}/${userId}/${projectId}/${orderId}/${fileVersion}/{z}/{x}/{y}.pbf`
);

export const GetElevationLabelsMajrUrl = (userId: string, projectId: string, orderId: string, fileVersion: string) => (
  `${MAP_TILESERVER_URL}/${userId}/${projectId}/${orderId}/${fileVersion}/topo_elevations_majr.geojson`
);

export const GetElevationLabelsMinrUrl = (userId: string, projectId: string, orderId: string, fileVersion: string) => (
  `${MAP_TILESERVER_URL}/${userId}/${projectId}/${orderId}/${fileVersion}/topo_elevations_minr.geojson`
);
