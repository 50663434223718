// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import moment from 'moment';
import { Dispatch } from 'redux';
import { getJson, postJson, deleteRequest, patchJson } from 'Utils/http';
import { API_URL } from 'Config';
import history from 'Utils/history';
import { GetSubscription } from 'State/subscription/thunk';
import { getOrders, getProcessingOrders } from './selectors';
import {
  CreateOrderAsync, UpdateOrderAction, GetOrdersAsync, DownloadFileAsync, GetOrderStatusAction, SetOrderIdAction, SetOutputLasAction, SetFileVersionAction, DeleteOrderAsync, CreditAdjustmentAsync,
  UpdateOrderSurfaceFilesAction, DeleteSurfaceFileAsync, UpdateOrderCadFilesAction, UpdateOrderLasFilesAction, UpdateOrderStatusAsync, SetPlaceOrderSuccessAction, DownloadPostgisDxfAsync,
  SaveFileDownloadStatusAction, EditOrderAsync, SetShowAdjustmentMessageAction,
  DeleteDxfFileAsync,
  SetOrderLoadingAction,
  SaveGeoJsonDownloadStatusAction,
  DeleteOutputLasFileAsync,
} from './actions';

export const SetOrderId = (orderId: string) => (dispatch: Dispatch) => dispatch(SetOrderIdAction(orderId));

export const ClearFileVersions = () => (dispatch: Dispatch) => dispatch(SetFileVersionAction({}));

export const SetFileVersion = (orderId: string, siteId: string) => (dispatch: Dispatch, getState: () => IState) => {
  const orders = getOrders(getState());
  const processedOrders = orders.projectOrders.filter((o) => o.cadFiles && o.cadFiles.length > 0);
  const fileVersions = getState().order.fileVersions || {};

  if (!Object.keys(fileVersions)?.length) {
    processedOrders.forEach((order) => {
      fileVersions[order._id] = '';
    });
  } else {
    fileVersions[orderId] = siteId;
  }
  dispatch(SetFileVersionAction(fileVersions));
};

export const SetOutputLas = (outputLas: boolean) => (dispatch: Dispatch) => dispatch(SetOutputLasAction(outputLas));

export const CreateOrder = (projectId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/orders`;
    const { user, token } = getState().auth;
    const { orgId } = getState().admin;

    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    const data = isAdmin ?
      { ownerProject: projectId, orgId } :
      { ownerProject: projectId };

    dispatch(CreateOrderAsync.request());

    const result = await postJson<IOrder>(url, data, token);
    if (result.success) {
      dispatch(CreateOrderAsync.success(result.data));
    } else {
      dispatch(CreateOrderAsync.failure(result.message));
    }

    return result;
  };

export const UpdateOrder = (order: IOrder) => (dispatch: Dispatch) => dispatch(UpdateOrderAction(order));

// Deletes order with a given orderId(airworks admin only)
export const DeleteOrder = (orderId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/admins/deleteOrder/${orderId}`;
    const { token } = getState().auth;

    dispatch(DeleteOrderAsync.request(orderId));

    const result = await deleteRequest(url, token);

    if (result.success) {
      dispatch(DeleteOrderAsync.success(orderId));
    } else {
      dispatch(DeleteOrderAsync.failure(orderId));
    }
  };

export const UpdateOrderStatus = (orderId: string, sid: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/orders/updateOrderStatus/${orderId}`;
    const { token } = getState().auth;
    dispatch(UpdateOrderStatusAsync.request());
    const result = await patchJson<IOrder>(url, { sid }, token);

    if (result.success) {
      dispatch(UpdateOrderStatusAsync.success(result.data));
    } else {
      dispatch(UpdateOrderStatusAsync.failure(result.message));
    }

    return result;
  };

export const EditOrder = (orderId: string, bundleName?: string, density?: string, deliverySpeed?: string, referenceId?: string, team?: string, anticipatedKickoffDate?: Date) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/orders/${orderId}`;
    const { token } = getState().auth;
    const { orgId } = getState().admin;
    const data = {
      orgId,
      ...(bundleName && { bundleName }),
      ...(density && { density }),
      ...(deliverySpeed && { deliverySpeed }),
      ...(referenceId && { referenceId }),
      ...(team && { team }),
      ...(anticipatedKickoffDate && { anticipatedKickoffDate }),
    };
    dispatch(EditOrderAsync.request());
    const result = await patchJson<IOrder>(url, data, token);
    if (result.success) {
      dispatch(EditOrderAsync.success(result.data.order));
    } else {
      dispatch(EditOrderAsync.failure(result.message));
    }
    return result;
  };

export const RemoveEstimate = (orderId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/orders/${orderId}`;
    const { token } = getState().auth;
    const { orgId } = getState().admin;
    const data = {
      orgId,
      removeEstimate: true,
    };
    dispatch(EditOrderAsync.request());
    const result = await patchJson<IOrder>(url, data, token);
    if (result.success) {
      dispatch(EditOrderAsync.success(result.data.order));
    } else {
      dispatch(EditOrderAsync.failure(result.message));
    }
    return result;
  };

export const AdjustOrderCredits = (orderId: string, orgId: string, projectId: string, adjustment: number, reason: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/creditadjustment`;

    const { token } = getState().auth;
    const { org } = getState().admin;
    const data = { orderId, orgId, projectId, adjustment, reason };

    dispatch(CreditAdjustmentAsync.request());

    const result = await postJson<IOrder>(url, data, token);

    if (result.success) {
      const { order, creditAdjustments, totalAdjustment } = result.data.updatedOrder;

      order.totalAdjustment = totalAdjustment;
      order.creditAdjustments = creditAdjustments;

      dispatch(CreditAdjustmentAsync.success(order));
    } else {
      const errordata = result.message;
      if (adjustment > org.availableCreditAcreage && errordata?.includes('availableCreditAcreage')) {
        dispatch(SetShowAdjustmentMessageAction(true));
      }
      dispatch(CreditAdjustmentAsync.failure(errordata));
    }
  };

export const GetOrders = (projectId?: string, orgId?: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const orgUrl = _ADMIN_ ? `${API_URL}/admins/getOrgOrders/${orgId}` : `${API_URL}/orders`;
    const url = projectId ? `${API_URL}/orders/${projectId}` : orgUrl;
    const { token } = getState().auth;
    dispatch(GetOrdersAsync.request());

    const result = await getJson<IOrder[]>(url, token);
    if (result.success) {
      dispatch(GetOrdersAsync.success(result.data));
      if (!_ADMIN_) GetOrderStatus()(dispatch, getState);
    } else {
      dispatch(GetOrdersAsync.failure(result.message));
    }

    return result;
  };

export const UpdateOrderSurfaceFiles = (surfaceFile: ISurfaceFile) => (dispatch: Dispatch) => dispatch(UpdateOrderSurfaceFilesAction(surfaceFile));

export const UpdateOrderLasFiles = (lasFile: ILasFile) => (dispatch: Dispatch) => dispatch(UpdateOrderLasFilesAction(lasFile));

export const UpdateOrderCadFiles = (cadFile: ICadFile) => (dispatch: Dispatch) => dispatch(UpdateOrderCadFilesAction(cadFile));

export const PrepareSurfaceFile = (siteId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const downloadStatus = getState().order.dxfFileStatus;
    const index = downloadStatus.findIndex((obj) => obj.siteId === siteId);
    downloadStatus[index].fileReadyStatus.surfaceFile = 1;
    dispatch(SaveFileDownloadStatusAction({ downloadStatus, fileReady: true }));
  };

export const DownloadSurfaceFile = (fileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }

    const url = `${API_URL}/downloadSurfaceFile/${orgId}/${projectId}/${orderId}/${fileId}`;
    const result = await getJson<string>(url, token);
    if (result.success) {
      setTimeout(() => {
        window.open(result.data, '_self');
      }, 100);
      return result;
    }
    return {};
  };

export const DeleteSurfaceFile = (surfaceFileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/orders/deleteSurfaceFile`;

    const data = { surfaceFileId };
    dispatch(DeleteSurfaceFileAsync.request());

    const result = await postJson<string>(url, data, token);
    if (result.success) {
      dispatch(DeleteSurfaceFileAsync.success({ surfaceFileId, orderId }));
    } else {
      dispatch(DeleteSurfaceFileAsync.failure());
    }
  };

export const DeleteDxfFile = (dxfFileId: string, orderId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/orders/deleteDxfFile/${dxfFileId}`;

    dispatch(DeleteDxfFileAsync.request());

    const result = await deleteRequest(url, token);
    if (result.success) {
      dispatch(DeleteDxfFileAsync.success({ dxfFileId, orderId }));
    } else {
      dispatch(DeleteDxfFileAsync.failure());
    }
  };

export const DeleteOutputLasFile = (lasFileId: string, orderId: string, projectId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const { orgId } = getState().admin;
    const url = `${API_URL}/projects/deleteLAS`;

    dispatch(DeleteOutputLasFileAsync.request());
    const data = {
      orgId,
      projectId,
      lasFileId,
    };
    const result = await postJson(url, data, token);
    if (result.success) {
      dispatch(DeleteOutputLasFileAsync.success({ lasFileId, orderId }));
    } else {
      dispatch(DeleteOutputLasFileAsync.failure());
    }
  };

export const SaveOrder = (templateId?: string, deliveryDate?: string, isEstimate = false, orderData?: IOrder, saveEstimateClicked = false, projectDetailsSubmit = false) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/orders/saveOrder`;
    const { token } = getState().auth;
    const { orgId } = getState().admin;

    const orders = getOrders(getState());
    let data = {};
    if (!_ADMIN_ && !projectDetailsSubmit) {
      if (orderData) {
        const selectedAddOns = orderData.addOns.map((del) => del.id);

        data = {
          oid: orderData._id,
          orgId,
          templateId: orderData.cadTemplate,
          currentUserTime: moment(new Date()).format(),
          bundles: [orderData.bundleId],
          deliverablesList: orderData.deliverablesList,
          contourMajr: orderData.topoIntervalMajor,
          contourMinr: orderData.topoIntervalMinor,
          selectedAddOns,
          density: orderData.density,
          deliverySpeed: orderData.deliverySpeed,
          referenceId: orderData.referenceId,
          isEstimate: orderData.isEstimate,
          saveEstimateClicked,
        };
      } else {
        const { adjustedOrderAcres } = getState().bundle.selectedBundle;
        const bundleId = getState().bundle.selectedBundle._id;
        const additionalDeliverables = getState().bundle.selectedAdditionals.map((del) => del._id);
        const bundleDeliverables = getState().bundle.bundleDeliverables.map((del) => del._id);
        data = {
          oid: orders.activeOrder._id,
          orgId,
          templateId,
          currentUserTime: moment(new Date()).format(),
          deliveryEstimate: deliveryDate,
          bundles: [bundleId],
          deliverablesList: bundleDeliverables,
          selectedAddOns: additionalDeliverables,
          contourMajr: getState().bundle.topoMajr,
          contourMinr: getState().bundle.topoMinr,
          adjustedOrderAcres,
          density: getState().bundle.density,
          deliverySpeed: getState().bundle.deliverySpeed,
          referenceId: orders.activeOrder.referenceId,
          isEstimate,
          saveEstimateClicked,
        };
      }
    } else if (projectDetailsSubmit) {
      data = { ...orders.activeOrder, projectDetailsSubmit };
    } else {
      const { tifFiles, lasFiles } = getState().project?.project;
      const tifFilesUploaded = tifFiles && tifFiles.length > 0;
      const lasFilesUploaded = lasFiles && lasFiles.length > 0;
      let defaultBundle = '';
      if (tifFilesUploaded && !lasFilesUploaded) {
        defaultBundle = '2D';
      } else if (!tifFilesUploaded && lasFilesUploaded) {
        defaultBundle = 'Topo';
      } else if (tifFilesUploaded && lasFilesUploaded) {
        defaultBundle = '2D + Topo';
      }

      data = {
        oid: orders.activeOrder._id,
        orgId,
        ...(defaultBundle && { defaultBundle }),
        deliverySpeed: getState().bundle.deliverySpeed,
        currentUserTime: moment(new Date()).format(),
      };
    }

    dispatch(CreateOrderAsync.request());
    const result = await postJson<IPlaceOrderResponse>(url, data, token);

    if (!result.success) {
      dispatch(CreateOrderAsync.failure(result.message));
      return {};
    }

    return result;
  };

export const SaveEstimate = (templateId?: string, deliveryDate?: string, isEstimate = false, orderData?: IOrder, saveEstimateClicked = false) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const result = await SaveOrder(templateId, deliveryDate, isEstimate, orderData, saveEstimateClicked)(dispatch, getState);
    await dispatch(UpdateOrderAction(result.data.order));
    dispatch(SetOrderLoadingAction(false));
    return result;
  };

export const PlaceOrder = (templateId?: string, deliveryDate?: string, isEstimate = false, projectDetailsSubmit = false) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const orders = getOrders(getState());
    let result;
    if (projectDetailsSubmit) {
      result = await SaveOrder(undefined, undefined, undefined, undefined, false, projectDetailsSubmit)(dispatch, getState);
    } else {
      result = await SaveOrder(templateId, deliveryDate, isEstimate)(dispatch, getState);
    }
    const placedOrder = { ...orders.activeOrder };
    dispatch(CreateOrderAsync.success(result.data.newOrder));
    dispatch(UpdateOrderAction({ ...placedOrder, orderStatus: 1 }));
    dispatch(SetPlaceOrderSuccessAction(true));
    if (result.data.subscriptionChange) {
      await GetSubscription()(dispatch, getState);
    }

    return result;
  };

export const SetPlaceOrderSuccess = (data: boolean) => (dispatch: Dispatch) => dispatch(SetPlaceOrderSuccessAction(data));

export const ReprocessOrder = (orderId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/orders/reprocessOrder`;
    const { token } = getState().auth;
    const { orgId } = getState().admin;

    const data = { orderId, orgId };
    const result = await postJson<IOrder>(url, data, token);
    const order = getState().order.list.find((o) => o._id === orderId);
    if (!result.success) return result;

    dispatch(UpdateOrderAction({ ...order, orderStatus: 1 }));
    return result;
  };

export const DownloadFile = (type: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const orders = getOrders(getState());
    const { activeOrder } = orders;

    const url = `${API_URL}/orders/${activeOrder._id}/${type}`;
    const { token } = getState().auth;

    dispatch(DownloadFileAsync.request());

    const result = await getJson(url, token);
    dispatch(DownloadFileAsync.success());

    return result;
  };

// Previously - GetOrderStatus thunk call checks for all orders that are in progress or failed. And for these orders, we
// calculate the progress percentages using the parseOrderStatus thunk function.
// Current change - Make this function just save the orderStatus for all processing orders in the Store

export const GetOrderStatus = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const processingOrders = getProcessingOrders(getState());
    if (!processingOrders.length) return { success: true };

    const { token } = getState().auth;
    const url = `${API_URL}/orders`;

    const result = await getJson<IOrder[]>(url, token);
    if (!result.success) {
      return result;
    }

    const orderStatusResult: {
      [key: string]: number,
    } = {};

    const orderEstimateResult: string[] = [];

    result.data.forEach((order) => {
      if (order.isEstimate) orderEstimateResult.push(order.ownerProject);
      if ([1, 2, 3, 4, 5, 6, 7].includes(order.orderStatus)) orderStatusResult[order.ownerProject] = order.orderStatus;
    });

    dispatch(GetOrderStatusAction({ orderStatus: orderStatusResult, orderList: result.data, orderEstimate: orderEstimateResult }));
    return result;
  };

// temporarily remove calculation of percentage remaining for processing orders

// export const GetOrderStatus = () =>
//   async (dispatch: Dispatch, getState: () => IState) => {
//     const processingOrders = getProcessingOrders(getState());
//     if (!processingOrders.length) return { success: true };

//     const { token } = getState().auth;
//     const url = `${API_URL}/orders`;

//     const result = await getJson<IOrder[]>(url, token);
//     if (!result.success) {
//       return result;
//     }

//     const statusResult: {
//       [key: string]: IOrder,
//     } = {};

//     const inProgressOrFailedOrders = result
//       .data
//       .filter(o => o.orderStatus === 1 || o.orderStatus === 3);
//     const orderStatusResult = parseOrderStatus(inProgressOrFailedOrders);

//     dispatch(GetOrderStatusAction({ orderStatus: orderStatusResult, orderList: result.data }));
//     return result;
//   };

// const parseOrderStatus = (orders: IOrder[]) => {
//   // tslint:disable-next-line: array-type
//   const result: {
//     [key: string]: IOrderStatus,
//   } = {};

//   const currentMoment = moment.utc();
//   const currentTimestamp = currentMoment.valueOf();

//   orders.forEach((order) => {
//     if (!order.orderDate || !order.deliveryEstimate) {
//       result[order.ownerProject] = {
//         orderStatus: order.orderStatus,
//         progress: 0,
//         remaining: null,
//       };
//       return;
//     }

//     const startMoment = moment.utc(order.orderDate);
//     const endMoment = moment.utc(order.deliveryEstimate);

//     const startTimestamp = startMoment.valueOf();
//     const endTimestamp = endMoment.valueOf();

//     const total = endTimestamp - startTimestamp;
//     const current = currentTimestamp - startTimestamp;

//     let progress = Math.floor((current / total) * 100);
//     if (progress > 100) progress = 100;

//     result[order.ownerProject] = {
//       progress,
//       remaining: progress === 100 ? 'Finalizing' : `${endMoment.from(currentMoment, true)} remaining`,
//       orderStatus: order.orderStatus,
//     };
//   });

//   return result;
// };

// Call API to download the generated custom CAD
// Deprecated - 08/12/22 - Ishan
// started using url returned by check dxf status API
export const DownloadCustomCad = (orderId: string, orderFile: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user, token } = getState().auth;
    const opsTrainer = !_ADMIN_ && 'opsTrainer' in getState().auth.resources;
    const { projectId } = getState().project;
    const project = getState().project.list.find((p) => p._id === projectId);
    // eslint-disable-next-line no-nested-ternary
    const orgId = _ADMIN_ ? getState().admin.orgId : opsTrainer ? project.ownerOrganization : user.ownerOrganization;
    const urlToDownloadDXF = `${API_URL}/downloadDXF/${orgId}/${projectId}/${orderId}/${orderFile}`;
    const result = await getJson<{preSignedUrl:string, size: number}>(urlToDownloadDXF, token);
    if (result.success) {
      window.open(result.data.preSignedUrl, '_self');
    }
    return result;
  };

// Function to call the DownloadDxf API that triggers a Lambda which further fetches data from the PostGIS DB to create a
// DXF file. If the DownloadDxf API returns a 200, then this function would call the checkDxfStatus API that polls every
// 5 seconds to check if a DXF file has been generated. If the API receives a file URL in the response, the file is
// automatically downloaded. This API will keep getting called even if the user navigates away after clicking on download
// DXF in the UI.
// This type of polling is particularly useful when it comes to generating DXFs for large files since the process of
// file generation could take several minutes.
export const DownloadPostgisDxf = (orderId: string, siteId: string, includeEmptyLayers: boolean, fileDownloadCheckboxState?: { dxfFile: boolean,
  lasFile: boolean,
  surfaceFile: boolean,
  geoJson: boolean }) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    let orgId: string;
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else if ('opsTrainer' in getState().auth.resources) {
      const project = getState().project.list.find((p) => p._id === projectId);
      orgId = project.ownerOrganization;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }

    let layers: string[] = [];

    // when download dxf from admin portal, always download all layers
    if (!_ADMIN_ || fileDownloadCheckboxState) {
      const { vector_layers } = (getState().map.common.vectorTileJson[orderId] && getState().map.common.vectorTileJson[orderId][siteId]) || {};

      const allLayersVisible =
        vector_layers && vector_layers.every((l) => l.visible);
      const someLayersSelected =
        vector_layers && vector_layers.some((l) => l.visible);

      // set layers array depending on whether all, some or none of the layers are selected
      if (allLayersVisible) {
        layers = [];
      } else if (someLayersSelected) {
        layers = vector_layers.filter((l) => l.visible).map((l) => l.id);
      } else {
        // this is the case when no layers are selected in the sidebar for a site. In this case no API should be called
        return;
      }
    }

    const timeRequested = new Date();
    const url = `${API_URL}/tiles/downloadDxf`;
    const data = {
      orgId,
      projectId,
      orderId,
      cadFileId: siteId,
      layers,
      includeEmptyLayers,
    };
    // 1 prepare download -> clicked -> change the status  in redux orderid:true, polling
    // 2 preparing download -> polling -> success ->change state in redux orderid:finished
    // 3 finished downloading -> onclick => { }
    const result = await postJson<{}>(url, data, token);
    // eslint-disable-next-line no-nested-ternary
    const filesRequested = !_ADMIN_ && (fileDownloadCheckboxState.dxfFile && fileDownloadCheckboxState.geoJson ? 'multiple' : fileDownloadCheckboxState.dxfFile ? 'dxf' : fileDownloadCheckboxState.geoJson ? 'geojson' : null);
    if (result.success) {
      // poll the checkDxfStatus API to check File availability
      const testingVar = setInterval(async () => {
        const url2 = `${API_URL}/tiles/checkDxfStatus`;
        let fileAvailable = false;
        const data2 = {
          orgId,
          projectId,
          orderId,
          filesRequested,
          timeRequested,
          cadFileId: siteId,
        };
        const result2 = await postJson<{ fileURL: any }>(url2, data2, token);
        fileAvailable = result2.success;
        if (fileAvailable) {
          const downloadStatus = getState().order.dxfFileStatus;
          const downloadGeoJsonStatus = getState().order.geoJsonFileStatus;

          // find obj from downloadstatus array which has this siteid
          if (_ADMIN_ || filesRequested === 'dxf') {
            const index = downloadStatus.findIndex((obj) => obj.siteId === siteId);
            downloadStatus[index].fileReadyStatus.dxfFile = 1;
            downloadStatus[index].url = result2.data.fileURL[0].url;
            downloadStatus[index].size = result2.data.fileURL[0].size;
          }
          if (filesRequested === 'geojson') {
            const index = downloadGeoJsonStatus.findIndex((obj) => obj.siteId === siteId);
            downloadGeoJsonStatus[index].fileReadyStatus.geoJson = 1;
            downloadGeoJsonStatus[index].url = result2.data.fileURL[0].url;
          }
          if (filesRequested === 'multiple') {
            const index = downloadStatus.findIndex((obj) => obj.siteId === siteId);
            downloadStatus[index].fileReadyStatus.dxfFile = 1;
            downloadStatus[index].url = result2.data.fileURL.find((x: any) => x.fileType === 'dxf').url;

            const geojsonIndex = downloadGeoJsonStatus.findIndex((obj) => obj.siteId === siteId);
            downloadGeoJsonStatus[geojsonIndex].fileReadyStatus.geoJson = 1;
            downloadGeoJsonStatus[geojsonIndex].url = result2.data.fileURL.find((x: any) => x.fileType === 'geojson').url;
          }

          dispatch(SaveFileDownloadStatusAction({ downloadStatus, fileReady: true }));
          dispatch(SaveGeoJsonDownloadStatusAction({ downloadGeoJsonStatus, fileReady: true }));
          clearInterval(testingVar);
        }
      }, 5000);
    }
  };

// Set status of LAS file with a given orderId
export const PrepareLAS = (siteId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const downloadStatus = getState().order.dxfFileStatus;
    const index = downloadStatus.findIndex((obj) => obj.siteId === siteId);
    downloadStatus[index].fileReadyStatus.lasFile = 1;
    dispatch(SaveFileDownloadStatusAction({ downloadStatus, fileReady: true }));
  };

// Call API to Download LAS file
export const DownloadLAS = (orderId: string, lasFile: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }

    const url = `${API_URL}/downloadLAS/${orgId}/${projectId}/${orderId}/${lasFile}`;
    const result = await getJson<string>(url, token);

    if (result.success) {
      setTimeout(() => {
        window.open(result.data, '_self');
      }, 1000);
    }

    return result;
  };

// Set download status of files that have been requested by the user
export const SetDownloadFilesState = (siteId: string, fileDownloadCheckboxState?: IFileStatus) => async (dispatch: Dispatch) => {
  const { dxfFile, lasFile, surfaceFile } = !_ADMIN_ && fileDownloadCheckboxState;
  const downloadStatus: object = {
    siteId,
    fileReadyStatus: {},
  };
  if (dxfFile || _ADMIN_) {
    downloadStatus.fileReadyStatus.dxfFile = 0;
  }
  if (lasFile && !_ADMIN_) {
    downloadStatus.fileReadyStatus.lasFile = 0;
  }
  if (surfaceFile && !_ADMIN_) {
    downloadStatus.fileReadyStatus.surfaceFile = 0;
  }
  dispatch(SaveFileDownloadStatusAction({ downloadStatus, fileReady: false }));
};

// Set download status of geojson file
export const SetGeojsonFilesState = (siteId: string, fileDownloadCheckboxState?: IFileStatus) => async (dispatch: Dispatch) => {
  const { geoJson } = !_ADMIN_ && fileDownloadCheckboxState;
  const downloadGeoJsonStatus: object = {
    siteId,
    fileReadyStatus: {},
  };
  if (geoJson || !_ADMIN_) {
    downloadGeoJsonStatus.fileReadyStatus.geoJson = 0;
    dispatch(SaveGeoJsonDownloadStatusAction({ downloadGeoJsonStatus, fileReady: false }));
  }
};

// Clear Downloaded file status
export const ClearDownloadFiles = (siteId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const oldDownloadStatus = getState().order.dxfFileStatus;
    const arr = [...oldDownloadStatus];
    const index = oldDownloadStatus.findIndex((obj) => obj.siteId === siteId);
    arr.splice(index, 1);

    const oldDownloadGeoJsonStatus = getState().order.geoJsonFileStatus;
    const arrGeoJson = [...oldDownloadGeoJsonStatus];
    const geoJsonIndex = oldDownloadGeoJsonStatus.findIndex((obj) => obj.siteId === siteId);
    arrGeoJson.splice(geoJsonIndex, 1);

    dispatch(SaveFileDownloadStatusAction({ downloadStatus: arr, fileReady: true }));
    dispatch(SaveGeoJsonDownloadStatusAction({ downloadGeoJsonStatus: arrGeoJson, fileReady: true }));
  };

// Download LAS(airworks admin only)
export const AdminDownloadLAS = (filename: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    const { orgId } = getState().admin;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));

    if (!isAdmin) {
      history.push('/projects');
    }
    const url = `${API_URL}/adminDownloadLAS/${orgId}/${projectId}/${filename}`;
    const result = await getJson<string>(url, token);
    if (result.success) {
      window.open(result.data, '_self');
    }
  };
