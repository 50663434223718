// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { ShowDialogAction, HideDialogAction } from './actions';

export const ShowTokenExpiredDialog = () => (dispatch: Dispatch) => dispatch(ShowDialogAction());
export const HideTokenExpiredDialog = () => (dispatch: Dispatch) => dispatch(HideDialogAction());
