// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Link } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Tabs, Tab } from '@material-ui/core';
import { GetProjectDetailsRoute, GetProjectFilesRoute, GetProject3DViewerRoute } from 'Utils/routes';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const ProjectToolbar = ({ projectId, history, isAnyOrderProcessed, editorFunctions, projectDetailsTab, viewer3DTab }: PropsType) => {
  const classes = useStyles();
  const { viewer3D } = useFlags();
  const isTabDisabled = editorFunctions ? false : !isAnyOrderProcessed;
  return (
    <>
      {projectId && (
        <Tabs indicatorColor="primary" value={history.location.pathname}>
          {projectDetailsTab && (
            <Tab
              component={Link}
              label="Project Details"
              value={GetProjectDetailsRoute(projectId)}
              to={GetProjectDetailsRoute(projectId)}
              classes={{
                wrapper: classes.tabLabel,
              }}
            />
          )}
          <Tab
            component={Link}
            label="CAD View"
            value={GetProjectFilesRoute(projectId)}
            to={GetProjectFilesRoute(projectId)}
            classes={{
              wrapper: classes.tabLabel,
            }}
            // disable CAD View if the customer doesn't have a processed order or doesn't have 2d editor permissions
            disabled={isTabDisabled}
          />
          {viewer3DTab && viewer3D && (
            <Tab
              component={Link}
              label="3D Viewer"
              value={GetProject3DViewerRoute(projectId)}
              to={GetProject3DViewerRoute(projectId)}
              classes={{
                wrapper: classes.tabLabel,
              }}
            />
          )}
        </Tabs>
      )}
    </>
  );
};

export default compose(ProjectToolbar);
