// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  tableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    width: '22%',
    fontSize: '1em',
  },
  routerLink: {
    color: '#2E4DEB',
  },
  borderStyle: {
    border: '1px solid #000000',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '180px',
    textAlign: 'left',
    height: '50px',
    cursor: 'pointer',
  },
  row: {
    display: 'flex',
    height: '66px',
  },
  select: {
    padding: '0px 21px 0px 0px',
  },
  selectWeight: {
    padding: '0px 21px 0px 0px',
    display: 'flex',
  },
  lineStylePNG: {
    height: '1px',
    marginTop: theme.spacing(1),
  },
  borderLineStyle: {
    border: '1px solid #000000',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
    width: '235px',
    textAlign: 'left',
    height: '50px',
    cursor: 'pointer',
  },
  chips: {
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  tableCellLayers: {
    borderBottom: 'none',
    padding: '2px 4px',
    width: '235px',
  },
  badge: {
    height: '30px',
    width: '40px',
    backgroundColor: '#01A1DD',
    position: 'absolute',
    right: 0,
    top: '8px',
    color: '#FFFFFF',
    borderRadius: '15px',
    textAlign: 'center',
    verticalAlign: 'middle',
    lineHeight: '29px',
  },
  chipRoot: {
    '&:not(:first-child)': {
      display: 'none',
    },
    borderRadius: '0',
    color: '#01A1DD',
  },
  selectLayers: {
    width: '100%',
  },
  selected: {
    color: '#01A1DD',
  },
  checked: {
    color: '#01A1DD !important',
  },
  compactPicker: {
    '& div': {
      zIndex: 1,
    },
  },
  horizontalLine: {
    backgroundColor: '#000000',
    width: '50%',
    marginRight: '10px',
    marginLeft: '0px',
  },
  layerColor: {
    float: 'left',
    height: '20px',
    width: '30px',
    marginBottom: '15px',
    border: '1px solid #000000',
    clear: 'both',
    marginRight: '20px',
  },
  selectMapping: {
    fontFamily: 'Work Sans',
    fontWeight: 300,
  },
  input: {
    width: '100%',
  },
  colorBox: {
    width: '115px',
  },
  mappingBox: {
    width: '235px',
  },
}));

export default useStyles;
