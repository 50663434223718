// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core';
import { HideUploadKmlDialog } from 'State/ui/uploadKmlDialog/thunk';
import { getOrders } from 'State/order/selectors';
import { SetScreenshotAction } from 'State/map/common/actions';
import { currentSubscription } from 'State/subscription/selectors';
import { GetOrders } from 'State/order/thunk';
import styles from './styles';

const mapStateToProps = (state: IState) => {
  const currentProject = state.project.project;
  const subscription = currentSubscription(state);
  const placeOrder = 'placeOrder' in state.auth.resources;
  const { epsgCode, epsgProjections } = state.project;
  const geogCSStart = epsgProjections && epsgProjections.search('GEOGCS');
  const geogCS = geogCSStart && geogCSStart !== -1 ? epsgProjections.substring(geogCSStart).match(/"([^"]+)"/)[1] : 'Unavailable';
  const { imageryRequest, _id } = currentProject;
  const order = getOrders(state).activeOrder;
  const { bundleName, bundleAcreAdjustmentFactor, topoIntervalMajor, topoIntervalMinor, addOns, density, deliverySpeed, deliveryDays, deliverables, imageryCreditCost, imageryDollarCost, creditAcreage, creditCost, costToCustomer, adjustedOrderAcres, paidForThisProject } = order;
  const { orgTeams } = state.account;
  const { teams } = state.auth.user;

  const availableTeamNames: string[] = [];
  const isUserTeam = (value: ITeam) => {
    if (teams.includes(value._id)) {
      availableTeamNames.push(value.name);
      return value._id;
    }
    return false;
  };

  const availableTeams = orgTeams.filter((t) => isUserTeam(t));
  const imageryRequested = (order?.isEstimate && !!(currentProject?.imageryRequest?.requested)) || !!(currentProject?.imageryRequest?.uploaded) || !!(currentProject?.imageryRequest?.notificationSent);
  return {
    projectId: _id,
    screenshot: state.map.common.screenshot,
    tif: currentProject.tifFiles[0] && currentProject.tifFiles[0].filename,
    las: currentProject.lasFiles[0] && currentProject.lasFiles[0].filename,
    automatePlanType: state.subscription.automateSubscription?.priceName,
    availableCreditAcreage: state.account.org.availableCreditAcreage,
    creditCost,
    creditAcreage,
    projectName: state.project.project.name,
    availableTeams,
    selectedBundle: { name: bundleName, acreAdjustmentFactor: bundleAcreAdjustmentFactor, density, deliverySpeed },
    topoMajr: topoIntervalMajor,
    topoMinr: topoIntervalMinor,
    deliverySpeed,
    adjustedOrderAcres,
    deliveryDays,
    selectedAdditionals: addOns,
    bundleDeliverables: deliverables,
    template: state.cadTemplate.list,
    org: state.account.org,
    imageryCreditCost,
    imageryDollarCost,
    costToCustomer,
    paidForThisProject,
    isOnFreePlan: subscription.isOnFreePlan,
    placeOrder,
    epsgCode,
    geogCS,
    order,
    imageryRequest,
    imageryRequested,
  };
};

const mapDispatchToProps = {
  HideUploadKmlDialogAction: HideUploadKmlDialog,
  GetOrdersAction: GetOrders,
  SetScreenshotAction,
};

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
