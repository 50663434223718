// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { bindActionCreators, Dispatch } from 'redux';
import { DownloadTIF } from 'State/project/thunk';
import styles from './styles';

const mapStateToProps = (state: IState) => {
  const { projectId } = state.project;
  const project = _ADMIN_ ? state.project.list.find((p) => p._id === projectId) : state.project.project;
  if (project.tifFiles.length !== 0) {
    return {
      tifFiles: project.tifFiles,
    };
  }
  return {
    tifFiles: null,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    DownloadTIFAction: DownloadTIF,
  }, dispatch);

type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
