// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  progress: {
    zIndex: 3001,
    position: 'absolute',
    width: '100%',
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    zIndex: 3000,
    opacity: 0.5,
  },
  closeButtonWrapper: {
    position: 'absolute',
    top: 50,
    right: 50,
    zIndex: 2000,
  },
  contentWrapper: {
    position: 'relative',
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));

export default useStyles;
