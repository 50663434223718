// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { DownloadKML } from 'State/kml/thunk';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => ({
  kmlGeojson: state.kml.present.kmls[ownProps.order._id]?.featureCollection,
});

const mapDispatchToProps = {
  DownloadKMLAction: DownloadKML,
};

interface IOwnProps {
  order: IOrder;
  showDialog: boolean;
  onCancel: () => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
