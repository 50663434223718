// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import Dropzone from 'react-dropzone';
import compose, { PropsType } from './container';

export const DropzoneWrapper = compose(({ classes, theme, acceptedExtensions, children, onDropAccepted, InitFileUploadAction }: PropsType) => {
  useEffect(() => {
    InitFileUploadAction();
  }, []);

  return (
    <Dropzone noClick accept={acceptedExtensions.join(',')} onDropAccepted={onDropAccepted}>
      {({ getRootProps, getInputProps, open, isDragActive }) => (
        <div
          {...getRootProps({
            style: {
              ...(isDragActive ? {
                borderColor: theme.palette.primary.main,
              } : {}),
            },
          })}
          className={classes.uploadField}
        >
          {children(getInputProps, open)}
        </div>
      )}
    </Dropzone>
  );
});

export default DropzoneWrapper;
