// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  expansionPanelHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    margin: 0,
  },
  kmlFiles: {
    display: 'grid',
    gridTemplateColumns: 'auto',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  kmlHideButton: {
    marginTop: theme.spacing(0.5),
    // padding: theme.spacing(0.5),
  },
  tabIcon: {
    width: 18,
    marginRight: theme.spacing(0.5),
  },
  kmlTabsWrapper: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
  },
  kmlTabRoot: {
    minHeight: 'initial',
    minWidth: 'initial',
  },
  kmlTabWrapper: {
    flexDirection: 'row',
  },
  button: {
    padding: 6,
  },
  sidebarList: {
    padding: 0,
    paddingBottom: theme.spacing(1),
  },
  listItem: {
    paddingTop: 5,
    paddingBottom: 5,
  },
  listItemIcon: {
    minWidth: 0,
    marginRight: theme.spacing(2),
  },
  listItemSelected: {
    backgroundColor: 'transparent !important',
    color: `${theme.palette.secondary.main} !important`,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08) !important',
    },
    '& $listItemIcon': {
      color: `${theme.palette.secondary.main} !important`,
    },
    '& $listItemText': {
      color: `${theme.palette.secondary.main} !important`,
    },
  },
  sidebarButtonText: {
    paddingLeft: 0,
  },
  dataSetRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(2),
  },
  cancelButton: {
    padding: theme.spacing(0.5),
  },
  cancelButtonIcon: {
    fontSize: theme.spacing(2),
  },
  listItemText: {
    color: theme.palette.darkGrey,
  },
  uploadIcon: {
    width: 20,
    height: 20,
    margin: 2,
  },
  businessDays: {
    marginTop: theme.spacing(1),
    display: 'flex',
    gap: theme.spacing(1),
    alignItems: 'center',
  },
  businessDaysText: {
    color: '#01A1DD',
    fontSize: '14px',
    fontWeight: 400,
  },
  helpIcon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    cursor: 'pointer',
  },
});

export default styles;
