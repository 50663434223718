// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core';
import { SetProjectId, GetProject } from 'State/project/thunk';
import { CreateOrder, DownloadFile, GetOrders } from 'State/order/thunk';
import { getOrders } from 'State/order/selectors';
import { SetOrderSelected } from 'State/map/editor/thunk';

import styles from './styles';

const mapStateToProps = (state: IState) => {
  const orders = getOrders(state);
  return {
    order: orders.activeOrder,
    mode: state.map.draw.present.mode,
    currentMeasurementOption: state.map.draw.present.currentMeasurementOption,
    orders: state.order.list,
    opsTrainer: 'opsTrainer' in state.auth.resources,
  };
};

const mapDispatchToProps = {
  SetProjectIdAction: SetProjectId,
  DownloadFileAction: DownloadFile,
  GetProjectAction: GetProject,
  CreateOrderAction: CreateOrder,
  SetOrderSelectedAction: SetOrderSelected,
  GetOrdersAction: GetOrders,
};
interface IRouterProps {
  id: string;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps<IRouterProps>;

export default compose<PropsType, {}>(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
