// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Dialog, Button } from '@material-ui/core/';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import { DropzoneArea } from 'material-ui-dropzone';
import DialogContent from '@material-ui/core/DialogContent';
import compose from './container';

const OpsTrainingDialogForm = compose(({
  classes, showOpsTrainingDialog, HideUploadOpsTrainingAction, UploadDxfLayersAction }) => {
  useEffect(() => {
    HideUploadOpsTrainingAction();
  }, []);

  const [notes, setNotes] = useState('');
  const [fileUpload, setFileUpload] = useState({});

  const handleTextBoxChange = (e: any) => {
    setNotes(e.target.value);
  };

  const handleDxfLogsUpload = async () => {
    await UploadDxfLayersAction(notes, fileUpload);
    setNotes('');
    HideUploadOpsTrainingAction();
  };

  const handleDxfClose = async () => {
    setNotes('');
    HideUploadOpsTrainingAction();
  };

  return (
    <Dialog open={showOpsTrainingDialog} onClose={handleDxfClose}>
      <DialogContent>
        <DialogContentText>
          Upload your files
        </DialogContentText>
        <DropzoneArea
          acceptedFiles={['.dxf']}
          onChange={(event) => setFileUpload(event[0])}
          dropzoneText="Upload DXF file with new layers"
          maxFileSize={1073741824}
          showFileNames
        />
        <TextField
          margin="dense"
          id="log-notes"
          label="Log Notes"
          type="log-notes"
          variant="outlined"
          fullWidth
          onChange={(e) => handleTextBoxChange(e)}
          value={notes}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDxfClose} color="primary">
          CANCEL
        </Button>
        <Button onClick={handleDxfLogsUpload} color="primary">
          CONFIRM AND UPLOAD
        </Button>
      </DialogActions>
    </Dialog>
  );
});

export default OpsTrainingDialogForm;
