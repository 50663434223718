// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { SetOrderId, SetOutputLas, GetOrders, DownloadLAS, DownloadPostgisDxf, DownloadSurfaceFile, DeleteSurfaceFile, SetFileVersion, SetDownloadFilesState, ClearDownloadFiles, DeleteDxfFile, EditOrder, DeleteOutputLasFile } from 'State/order/thunk';
import { SetProjectId } from 'State/project/thunk';
import { ShowUploadKmlDialog } from 'State/ui/uploadKmlDialog/thunk';

const mapStateToProps = (state: IState) => ({
  downloadFileStatuses: state.order?.dxfFileStatus,
  anyChangeInFileStatuses: state.order?.anyChangeInFileStatuses,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    SetOutputLasAction: SetOutputLas,
    SetOrderIdAction: SetOrderId,
    GetOrdersAction: GetOrders,
    SetProjectIdAction: SetProjectId,
    ShowUploadDialogAction: ShowUploadKmlDialog,
    DownloadLASAction: DownloadLAS,
    DownloadPostgisDxfAction: DownloadPostgisDxf,
    DownloadSurfaceFileAction: DownloadSurfaceFile,
    DeleteSurfaceFileAction: DeleteSurfaceFile,
    SetFileVersionAction: SetFileVersion,
    SetDownloadFilesStateAction: SetDownloadFilesState,
    ClearDownloadFilesAction: ClearDownloadFiles,
    DeleteDxfFileAction: DeleteDxfFile,
    EditOrderAction: EditOrder,
    DeleteOutputLasFileAction: DeleteOutputLasFile,
  }, dispatch);

interface IOwnProps {
  projectId: string;
  hasValidEpsg: boolean;
  tifMissing: boolean;
  order: IOrder;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
