// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { GetOrders, SetFileVersion, ClearFileVersions, UpdateOrderStatus, PlaceOrder, DeleteOrder, ReprocessOrder } from 'State/order/thunk';
import { SetProjectId, GetProject } from 'State/project/thunk';
import { RouteComponentProps } from 'react-router';
import { AdminToggleKml, LoadKmls, ClearKmls } from 'State/kml/thunk';
import { AdminToggleCadDropdown, ChangeVersion, GetDxfExtent, GetVectorTiles, StopLoading, ToggleNoneVectorTiles } from 'State/map/common/thunk';
import { SetDxfExtentAction } from 'State/map/common/actions';
import { SetOrgId, GetOrg, DeleteProjectAdmin } from 'State/admin/thunk';
import { SetEpsgListAction } from 'State/fileUpload/actions';
import { SetShowAdjustmentMessageAction } from 'State/order/actions';
import { getOrders } from 'State/order/selectors';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps<{ orgId: string, projectId: string }>) => {
  const { projectId, orgId } = ownProps.match.params;
  const orders = getOrders(state).projectOrdersReverse;
  const adjustedAcresTotal = orders.reduce((a, b) => a + (b.adjustedAcres || 0), 0);
  return {
    vectorTileJson: state.map.common.vectorTileJson,
    fileVersions: state.order.fileVersions,
    projectId,
    orgId,
    orgTeams: state.account.orgTeams,
    org: state.admin.org,
    epsgCode: state.fileUpload?.epsgList.length && state.fileUpload.epsgList[0],
    templates: state.cadTemplate.list,
    adjustedAcresTotal,
    list: orders,
    project: state.project.project,
    downloadFileStatuses: state.order?.dxfFileStatus,
    anyChangeInFileStatuses: state.order?.anyChangeInFileStatuses,
    kmlChange: state.kml.present.loading,
    showAdjustmentError: state.order.showAdjustmentError,
    trainingErrorMessage: state.opsTraining.errorMessage,
  };
};
const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetOrdersAction: GetOrders,
    SetProjectIdAction: SetProjectId,
    SetFileVersionAction: SetFileVersion,
    GetVectorTilesAction: GetVectorTiles,
    ChangeVersionAction: ChangeVersion,
    ToggleNoneVectorTilesAction: ToggleNoneVectorTiles,
    AdminToggleCadDropdownAction: AdminToggleCadDropdown,
    AdminToggleKmlVisibilityAction: AdminToggleKml,
    ClearKmlsAction: ClearKmls,
    StopLoadingAction: StopLoading,
    ClearFileVersionsAction: ClearFileVersions,
    GetDxfExtentAction: GetDxfExtent,
    SetDxfExtentAction,
    GetProjectAction: GetProject,
    SetOrgIdAction: SetOrgId,
    GetOrgAction: GetOrg,
    DeleteProjectAction: DeleteProjectAdmin,
    DeleteOrderAction: DeleteOrder,
    UpdateOrderStatusAction: UpdateOrderStatus,
    ReprocessOrderAction: ReprocessOrder,
    PlaceOrderAction: PlaceOrder,
    LoadKmlsAction: LoadKmls,
    SetEpsgListAction,
    SetShowAdjustmentMessageAction,
  }, dispatch);

interface IOwnProps {
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
