// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Divider, Typography, Select, MenuItem, InputBase, Button, Tooltip, Badge, IconButton, Fade, CircularProgress } from '@material-ui/core';
import Sidebar from 'Common/Sidebar';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import SettingsOutlinedIcon from '@material-ui/icons/SettingsOutlined';
import moment from 'moment-timezone';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import classnames from 'classnames';
import ClearIcon from '@material-ui/icons/Clear';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmDialog from 'Common/ConfirmDialog';
import UploadFilesDialog from 'Common/UploadFilesDialog';
import LightTooltip from 'Common/Tooltip';
import ProjectLayersList from 'Components/files/Sidebar/CadDrawings/ProjectLayersList';
import downloadIcon from 'Assets/download-icon.png';
import history from 'Utils/history';
import { GetAdminProjectsRoute } from 'Utils/routes';
import ImageryCaptureDialog from '../ImageryCaptureDialog';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');

export const ProjectDetails = ({ list, selectIndex, siteId, downloading, imageryRequest, isEstimate, emailLoading, downloaded, layers, currentIndex, downloadFileStatuses, projectFileStatuses, DeleteProjectAction, DeleteTifAction, DownloadTIFAction, DeleteLasAction, SetOutputLasAction, SetDownloadFilesStateAction, DownloadPostgisDxfAction, ClearDownloadFilesAction, SetImageryCaptureDateAction, SendImageryUploadEmailAction }: PropsType) => {
  const classes = useStyles();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [deleteFileId, setDeleteFileId] = useState('');
  const [deleteFileType, setDeleteFileType] = useState('');
  const [uploadFilesFormVisible, setUploadFilesFormVisible] = useState(false);
  const [download, setDownload] = useState(false);
  const [showConfirmDeleteProject, setShowConfirmDeleteProject] = useState(false);
  const [imageryCaptureDialogVisible, setImageryCaptureDialogVisible] = useState(false);

  const index = downloadFileStatuses.findIndex((obj: IDxfFileState) => obj.siteId === siteId);
  const downloadFileStatusesIndex = index >= 0 && downloadFileStatuses[index];

  let requested; let uploaded; let rejected; let notificationSent;

  // For later
  // let provider; let captured; let projection; let filetypes;
  if (imageryRequest) {
    ({ requested, uploaded, rejected, notificationSent } = imageryRequest);
  }

  const imageryRequested = requested && !rejected && !uploaded && !notificationSent;
  const imageryRejected = requested && rejected;
  const imageryUploaded = requested && !rejected && uploaded && !notificationSent;
  const imageryNotificationSent = requested && !rejected && uploaded && notificationSent;

  let imageryRequestTopBarClass;
  let imageryRequestIcon;
  let imageryRequestTitle;
  let imageryRequestDescription;
  let imageryRequestInfo;

  if (imageryRequested) {
    imageryRequestTopBarClass = classes.topBarRequested;
    imageryRequestIcon = <InfoIcon fontSize="small" className={classes.requested} />;
    imageryRequestTitle = 'Imagery Requested';
    imageryRequestDescription = 'An imagery has been requested for this project. Upload your files above';
    imageryRequestInfo = (
      <div>
        <b>Requested On:</b>
        {' '}
        {moment(requested).format('LL')}
      </div>
    );
  } else if (imageryRejected) {
    imageryRequestTopBarClass = classes.topBarRejected;
    imageryRequestIcon = <CheckCircleIcon className={classes.rejected} fontSize="small" />;
    imageryRequestTitle = 'Imagery Request Rejected';
    imageryRequestDescription = 'User has rejected the imagery requested on this project';
    imageryRequestInfo = (
      <div>
        <b>Rejected On:</b>
        {' '}
        {moment(rejected).format('LL')}
      </div>
    );
  } else if (imageryUploaded) {
    imageryRequestTopBarClass = classes.topBarUploaded;
    imageryRequestIcon = <CheckCircleIcon className={classes.uploaded} fontSize="small" />;
    imageryRequestTitle = 'Imagery Uploaded';
    imageryRequestDescription = 'Requested imagery has been uploaded on this project';
    imageryRequestInfo = (
      <Button className={classes.requested} onClick={() => SendImageryUploadEmailAction()}>
        <Fade in={emailLoading}>
          <CircularProgress size={24} className={classes.emailButtonProgress} />
        </Fade>
        <Fade in={!emailLoading}>
          <span>Send Notification</span>
        </Fade>
      </Button>
    );
  } else if (imageryNotificationSent) {
    imageryRequestTopBarClass = classes.topBarUploaded;
    imageryRequestIcon = <CheckCircleIcon className={classes.uploaded} fontSize="small" />;
    imageryRequestTitle = 'Imagery Uploaded';
    imageryRequestDescription = 'Requested imagery has been uploaded on this project';
    imageryRequestInfo = (
      <div>
        <b>User Notified On:</b>
        {' '}
        {moment(notificationSent).format('LL')}
      </div>
    );
  }

  const DownloadButton = ({ filename, text }: { filename: string, text: string }) => (
    <IconButton
      aria-label={text}
      onClick={() => DownloadTIFAction(filename)}
    >
      <img src={downloadIcon} alt={text} />
    </IconButton>
  );

  const userReUpFiles = (list.length === 1 && list[0].orderStatus === 0) || list.some((order) => order.orderStatus === 3);
  const disableFileUpload = !!(requested) && (list.length > 1 && list.some((order) => order.orderStatus > 0));

  const onOkDeleteProject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    DeleteProjectAction(list[currentIndex].ownerProject);
    history.push(GetAdminProjectsRoute(list[currentIndex].ownerOrganization));
    e.stopPropagation();
  };

  const deleteInputFile = (id: string, type: string) => {
    setShowConfirmDialog(true);
    setDeleteFileId(id);
    setDeleteFileType(type);
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    if (deleteFileType === 'tif') {
      DeleteTifAction(deleteFileId);
    } else {
      DeleteLasAction(deleteFileId);
    }
    e.stopPropagation();
  };

  const uploadInputFilesClick = () => {
    SetOutputLasAction(false);
    if (imageryRequest) {
      SetImageryCaptureDateAction(null);
      setImageryCaptureDialogVisible(true);
    } else {
      setUploadFilesFormVisible(true);
    }
  };

  const PrepareOutputFiles = async () => {
    // check what are the files requested for dxf and las
    if (layers) {
      const downloadState = {
        dxfFile: true,
        lasFile: false,
        surfaceFile: false,
        geoJson: false,
      };
      setDownload(true);
      await SetDownloadFilesStateAction(siteId, downloadState);
      await DownloadPostgisDxfAction(list[currentIndex]._id, siteId, false, downloadState);
    }
  };
  const DownloadOutputFiles = async () => {
    const filesReadyToDownload = [];
    if (index >= 0 && downloadFileStatusesIndex.url) {
      filesReadyToDownload.push(downloadFileStatusesIndex.url);
    }
    const interval = 3000;
    filesReadyToDownload.forEach((file, i) => {
      setTimeout(async () => {
        window.open(file, '_self');
      }, i * interval);
    });
    await ClearDownloadFilesAction(siteId);
    setDownload(false);
  };
  const ButtonFunction = () => {
    // eslint-disable-next-line no-unused-expressions
    downloaded ? DownloadOutputFiles() : PrepareOutputFiles();
  };

  const onCancel = () => setShowConfirmDialog(false);

  return (
    <Sidebar>
      <div className={classes.sidebarContent}>
        <Select
          variant="outlined"
          input={<InputBase style={{ width: '400px' }} />}
          className={classes.dropdownText}
          value={currentIndex}
          renderValue={(currentIndex: number) => <>{`Order ${currentIndex + 1}`}</>}
          classes={{ root: classes.select }}
          disabled={list.filter((order) => order.boundaryFile).length === 0}
        >
          {
            list.map((order, i) =>
              (<MenuItem key={order._id} value={order._id} onClick={() => selectIndex(i)}>{`Order ${i + 1}`}</MenuItem>
              ))
          }
        </Select>
        <Divider className={classes.divider} />
        <>
          <div className={classes.inputData}>
            <Typography variant="h4">Input data</Typography>
            <Button
              disabled={disableFileUpload || (!isEstimate && !!imageryRequest)}
              onClick={uploadInputFilesClick}
              color="primary"
            >
              Upload files
            </Button>
          </div>
          {imageryRequest && (
            <div className={classnames(classes.inputControls, imageryRequestTopBarClass)}>
              <Typography className={classes.buttons} variant="h4">
                {imageryRequestIcon}
                <div className={classes.imageryTitle}>
                  {imageryRequestTitle}
                </div>
              </Typography>
              <Typography className={classes.divider} variant="body1">{imageryRequestDescription}</Typography>
              {imageryRequestInfo}
            </div>
          )}
          {projectFileStatuses?.map((file) => (
            <div className={classes.files} key={file.id}>
              <div className={classes.fileInfo}>
                {file.adminUpload && (
                  <LightTooltip title="Uploaded by Admin" placement="top">
                    <SettingsOutlinedIcon />
                  </LightTooltip>
                )}
                <Typography>{`${file.name} - [ EPSG: ${file.epsg === -1 ? 'invalid' : file.epsg} ]`}</Typography>
              </div>
              {(file.status === 'ERROR') && (
                <Tooltip title={file.messages.join(', ')} placement="top">
                  <ErrorIcon />
                </Tooltip>
              )}
              <div className={classes.buttons}>
                <div>
                  <DownloadButton
                    filename={file.name}
                    text={`Download .${file.type}`}
                  />
                </div>
                <div>
                  {(userReUpFiles || (file.status === 'ERROR')) && (
                    <IconButton
                      onClick={() => deleteInputFile(file.id, file.type)}
                    >
                      <ClearIcon />
                    </IconButton>
                  )}
                </div>
              </div>
            </div>
          ))}
        </>
        {(layers) && (
          <>
            <Divider className={classes.divider} />
            <div className={classes.projectListContainer}>
              <div>
                <ProjectLayersList order={list[currentIndex]} layers={layers} siteId={siteId} expressAIInfo={false} />
              </div>
              <div className={classes.downloadButtonContainer}>
                { (downloaded && download) ? (
                  <Badge color="primary" overlap="circle" variant="dot">
                    <IconButton className={classes.iconButton} onClick={ButtonFunction}>
                      <img src={downloadIcon} alt="Download .dxf" />
                    </IconButton>
                  </Badge>
                ) : (
                  <Button
                    disabled={!layers}
                    onClick={ButtonFunction}
                    className={classes.downloadButton}
                    color="primary"
                  >
                    {
                      (download && downloading && !downloaded) &&
                  (
                    <CircularProgress size={16} className={classes.buttonProgress} color="primary" />
                  )
                    }
                    {(!download) &&
                (
                  <IconButton
                    aria-label="Download"
                    title="Download"
                    color="primary"
                    className={classes.downloadButton}
                  >
                    <img src={downloadIcon} alt="Download" />
                  </IconButton>
                )}
                  </Button>
                )}
              </div>
            </div>

          </>
        )}
        <div className={classes.deleteButtonContainer}>
          <div />
          <div className={classes.deleteButton}>
            <DeleteIcon />
            <Button className={classes.deleteButtonText} onClick={() => setShowConfirmDeleteProject(true)}>Delete Project</Button>
          </div>
        </div>
        <ConfirmDialog
          showDialog={showConfirmDeleteProject}
          onOk={onOkDeleteProject}
          onCancel={() => setShowConfirmDeleteProject(false)}
          contentText="Selected project will be deleted"
        />
        <ConfirmDialog
          showDialog={showConfirmDialog}
          onOk={onOk}
          onCancel={onCancel}
          contentText={`Selected .${deleteFileType} file will be removed `}
        />
        <ImageryCaptureDialog showDialog={imageryCaptureDialogVisible} provider={imageryRequest?.provider} onCancel={() => setImageryCaptureDialogVisible(false)} onNext={() => setUploadFilesFormVisible(true)} />
        <UploadFilesDialog showDialog={uploadFilesFormVisible} setShowDialog={setUploadFilesFormVisible} />
      </div>
    </Sidebar>
  );
};

export default compose(ProjectDetails);
