// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { GetOrgTeams, DeleteOrgTeam, UpdateOrgTeam } from 'State/account/thunk';

const mapStateToProps = (state: IState) => ({
  orgTeams: state.account.orgTeams,
  organizationAccess: 'organizationAccess' in state.auth.resources,
  teamError: state.account.teamError,
});

const mapDispatchToProps = {
  GetOrgTeamsAction: GetOrgTeams,
  DeleteOrgTeamAction: DeleteOrgTeam,
  UpdateOrgTeamAction: UpdateOrgTeam,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
