// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Collapse, IconButton, Divider, ListItem, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import downloadIcon from 'Assets/download-icon.png';
import OrthomosaicCheckbox from './OrthomosaicCheckbox';
import OrthomosaicButtons from './OrthomosaicButtons';
import compose from './container';

const Orthomosaic = compose(({ classes, tifFiles, DownloadTIFAction }) => {
  const [dataSetsExpanded, setDataSetsExpanded] = useState(true);

  const toggleDataSetsExpanded = () => {
    setDataSetsExpanded(!dataSetsExpanded);
  };

  const handleDownloadClick = async (tifFile: ITifFile) => {
    await DownloadTIFAction(tifFile.filename.split('/').pop());
  };

  return (
    <>
      <ListItem button className={classes.expansionPanelHeader}>
        <OrthomosaicCheckbox />
        <div>
          <OrthomosaicButtons />
        </div>
        <IconButton aria-label={dataSetsExpanded ? 'Collapse' : 'Expand'} className={classes.button} onClick={toggleDataSetsExpanded}>
          {dataSetsExpanded
            ? <KeyboardArrowDownIcon fontSize="small" />
            : <KeyboardArrowRightIcon fontSize="small" />}
        </IconButton>
      </ListItem>
      <Collapse in={dataSetsExpanded}>
        <div className={classes.wrapper}>
          <div className={classes.dataSetRow}>
            {tifFiles && tifFiles.length > 0 && tifFiles.map((tif: ITifFile) => (
              <div className={classes.filesList}>
                <Typography variant="body2" className={classes.file}>{tif.filename.split('/').pop()}</Typography>
                <IconButton
                  aria-label="Download .tif"
                  className={classes.button}
                  onClick={() => handleDownloadClick(tif)}
                >
                  <img src={downloadIcon} alt="Download .tif" />
                </IconButton>
              </div>
            ))}
          </div>
        </div>
      </Collapse>
      <Divider />
    </>
  );
});

export default Orthomosaic;
