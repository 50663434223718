// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { currentSubscription } from 'State/subscription/selectors';

const mapStateToProps = (state: IState) => {
  const subscription = currentSubscription(state);

  return {
    totalAcreage: subscription.totalAcreage,
    usedCreditAcreage: subscription.usedCreditAcreage,
    usedPercent: subscription.usedPercent,
    processingPercent: subscription.processingPercent,
    noAcreage: subscription.noAcreage,
    subscriptionType: state?.subscription?.automateSubscription?.interval,
  };
};

export type PropsType =
  ReturnType<typeof mapStateToProps>

export default compose<PropsType, {}>(
  connect(mapStateToProps),
);
