// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Divider } from '@material-ui/core';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders the order summary tooltip
 *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {object} props.order order object
 */
const reducer = (previousValue: number, currentValue: number): number => previousValue + currentValue;

export const OrderSummary = ({ order }: PropsType) => {
  const classes = useStyles();
  const { addOns, creditAdjustments, adjustedAcres, imageryCreditCost, imageryDollarCost, imageryProvider } = order;
  const layers = [];
  const deliverables = [];
  let addOnsInAcres = false;
  // If all add-ons include an acres field, it indicates that the user paid for these add-ons in acres
  if (addOns?.every((addOn) => addOn.hasOwnProperty('acres'))) {
    addOnsInAcres = true;
  }
  if (addOns) {
    for (let i = 0; i < addOns.length; i += 1) {
      const el = addOns[i];
      if (el.addOnType === 'layer') {
        if (el.acres) {
          layers.push(el.acres);
        } else {
          layers.push(el.cost);
        }
      } else if (el.addOnType === 'deliverable') {
        if (el.acres) {
          deliverables.push(el.acres);
        } else {
          deliverables.push(el.cost);
        }
      }
    }
  }
  const layersCost = layers.length ? layers.reduce(reducer) : 0;
  const deliverablesCost = deliverables.length ? deliverables.reduce(reducer) : 0;
  const imageryCost = addOnsInAcres ? imageryCreditCost || 0 : imageryDollarCost || 0;

  return (
    <div className={classes.tooltip}>
      <div className={classes.divGrid}>
        <div className={classes.titleGrid}>
          <Typography variant="subtitle2">Charged Credits</Typography>
        </div>
        <Typography className={classes.rightContent} variant="subtitle2">
          {`${order.creditAcreage.toFixed(2)} cr`}
        </Typography>
      </div>
      <div className={classes.extras}>
        <div className={classes.divGrid}>
          <Typography variant="body1" className={classes.extrasText}>- Project size</Typography>
          <Typography className={classes.extrasCost} variant="body1">
            {`${order.acreage} ac`}
          </Typography>
        </div>
        <div className={classes.divGrid}>
          <Typography variant="body1" className={classes.extrasText}>- Bundle</Typography>
          <Typography className={classes.extrasCost} variant="body1">
            {order.bundleName}
          </Typography>
        </div>
        <div className={classes.divGrid}>
          <Typography variant="body1" className={classes.extrasText}>- Density</Typography>
          <Typography className={classes.extrasCost} variant="body1">
            {order.density}
          </Typography>
        </div>
        {order.largeProjectDiscount > 0 &&
          (
            <div className={classes.divGrid}>
              <Typography variant="body1" className={classes.extrasText}>- Large project discount</Typography>
              <Typography className={classes.extrasCost} variant="body1">
                {`${order.largeProjectDiscount}%`}
              </Typography>
            </div>
          )}
        <div className={classes.divGrid}>
          <Typography variant="body1" className={classes.extrasText}>- Delivery speed</Typography>
          <Typography className={classes.extrasCost} variant="body1">
            {order.deliverySpeed}
          </Typography>
        </div>
      </div>
      <Divider className={classes.divider} />
      <div className={classes.divGrid}>
        <div className={classes.titleGrid}>
          <Typography variant="subtitle2">Add-Ons</Typography>
        </div>
        <Typography className={classes.rightContent} variant="subtitle2">
          {addOns ? `${addOnsInAcres ? '' : '$'}${(imageryCost + layersCost + deliverablesCost).toFixed(2)} ${addOnsInAcres ? 'cr' : ''}` : 'N/A'}
        </Typography>
      </div>
      <div className={classes.extras}>
        <div className={classes.divGrid}>
          <Typography variant="body1" className={classes.extrasText}>{`- Deliverables ${addOns ? `(${deliverables.length}) items` : 'N/A'}`}</Typography>
          <Typography className={classes.extrasCost} variant="body1">
            {addOns && `${addOnsInAcres ? '' : '$'}${deliverablesCost.toFixed(2)} ${addOnsInAcres ? 'cr' : ''}`}
          </Typography>
        </div>
        <div className={classes.divGrid}>
          <Typography variant="body1" className={classes.extrasText}>{`- Layers ${addOns ? `(${layers.length}) items` : 'N/A'}`}</Typography>
          <Typography className={classes.extrasCost} variant="body1">
            {addOns && `${addOnsInAcres ? '' : '$'}${layersCost.toFixed(2)} ${addOnsInAcres ? 'cr' : ''}`}
          </Typography>
        </div>
        { imageryCreditCost !== undefined && imageryProvider && (
          <div className={classes.divGrid}>
            <Typography variant="body1" className={classes.extrasText}>{`- ${imageryProvider} Imagery `}</Typography>
            <Typography className={classes.extrasCost} variant="body1">
              { `${imageryCreditCost.toFixed(2)} cr `}
            </Typography>
          </div>
        ) }
      </div>
      { creditAdjustments && !!adjustedAcres && (
        <>
          <Divider className={classes.divider} />
          <div className={classes.divGrid}>
            <div className={classes.titleGrid}>
              <Typography variant="subtitle2">Adjusted Credits</Typography>
            </div>
            <Typography className={classes.rightContent} variant="subtitle2">
              { adjustedAcres >= 0 ? '+' : '' }
              { `${adjustedAcres.toFixed(2)} cr`}
            </Typography>
          </div>
          {creditAdjustments.map((credit) => (
            <div className={classes.divGrid} key={credit._id}>
              <Typography variant="body1" className={classes.reasonText}>{`- ${credit.reason}`}</Typography>
              <Typography className={classes.extrasCost} variant="body1">
                {credit.adjustment >= 0 ? '+' : ''}
                { `${credit.adjustment.toFixed(2)} cr`}
              </Typography>
            </div>
          ))}

        </>
      )}
      <Divider className={classes.divider} />
      <div className={classes.divGrid}>
        <div className={classes.titleGrid}>
          <Typography variant="subtitle2">Total Credits Charged</Typography>
        </div>
        <Typography className={classes.rightContentTotal} variant="subtitle2">
          {`${order.creditAcreage.toFixed(2)} cr`}
        </Typography>
      </div>
    </div>
  );
};

export default compose(OrderSummary);
