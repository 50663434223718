// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { SetProjectId } from 'State/project/thunk';
import { GetOrgTeams } from 'State/account/thunk';
import { SetOrgId, GetOrg, GetOrgProjects } from 'State/admin/thunk';
import { GetTemplates } from 'State/cadTemplate/thunk';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps<{ orgId: string }>) => {
  const { orgId } = ownProps.match.params;
  return {
    orgId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetProjectsAction: GetOrgProjects,
    SetOrgIdAction: SetOrgId,
    SetProjectIdAction: SetProjectId,
    GetOrgTeamsAction: GetOrgTeams,
    GetTemplatesAction: GetTemplates,
    GetOrgAction: GetOrg,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ orgId: string }>;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
