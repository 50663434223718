// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  LoadListAsync, ToggleAsync,
} from './actions';

const initialState: INotificationState = {
  list: [],
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(LoadListAsync.success, (draft, payload) => { draft.list = payload; });
  handleAction(ToggleAsync.success, (draft, payload) => {
    const index = draft.list.findIndex((n) => n._id === payload._id);
    draft.list[index] = payload;
  });
});
