// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { GetOrgProfile, UpdateOrgProfile } from 'State/account/thunk';

const mapStateToProps = (state: IState) => ({
  initialValues: state.account.org,
  loading: state.account.loading,
  updateCompanyMessage: state.account.updateCompanyMessage,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetOrgProfileAction: GetOrgProfile,
    UpdateOrgProfileAction: UpdateOrgProfile,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
