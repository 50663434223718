// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { FileUploadStart, FileUploadCancel } from 'State/fileUpload/thunk';
import { EditTemplate, SetTemplateId, AddLayers } from 'State/cadTemplate/thunk';

import styles from './styles';

const mapStateToProps = (state: IState) => {
  const { uploadFiles } = state.fileUpload;
  const { templateId } = state.cadTemplate;
  const file = uploadFiles.find((f) => f.extension === 'dxf');
  return ({
    templateId,
    file,
    currentTemplate: state.cadTemplate.list && state.cadTemplate.list.find((t) => t._id === templateId),
  });
};

const mapDispatchToProps = {
  FileUploadStartAction: FileUploadStart,
  EditTemplateAction: EditTemplate,
  FileUploadCancelAction: FileUploadCancel,
  SetTemplateIdAction: SetTemplateId,
  AddLayersAction: AddLayers,
};

interface IOwnProps {
    showDialog: boolean;
    setShowDialog: (show: boolean) => void;
}

type PropsType =
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    WithStyles<typeof styles> &
    IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
