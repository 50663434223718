// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { API_URL } from 'Config';
import { postOpsFile, postJson } from 'Utils/http';
import { GetOrders, UpdateOrderCadFiles } from 'State/order/thunk';
import { ShowOpsTrainingDialogAction, HideOpsTrainingDialogAction, UploadDxfLayersAction, InitializeTrainingProjectAction } from './actions';

export const ShowUploadOpsTrainingDialog = () => (dispatch: Dispatch) => {
  dispatch(ShowOpsTrainingDialogAction());
};
export const HideUploadOpsTrainingDialog = () => (dispatch: Dispatch) => dispatch(HideOpsTrainingDialogAction());

export const UploadDxfLayers = (notes: string, file: any) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { orderId } = getState().order;
    const { _id, ownerOrganization } = getState().project.project;
    const url = `${API_URL}/opsusers/uploadLabeledFileFromClientOpsTrain/${ownerOrganization}/${_id}/${orderId}`;

    const { token } = getState().auth;

    dispatch(UploadDxfLayersAction.request());
    const _file = file;
    const { name } = _file;
    const fieldName = 'labeledFile';
    const result = postOpsFile(url, notes, token, _file, name, fieldName);

    result.onreadystatechange = () => {
      if (result.readyState === XMLHttpRequest.DONE) {
        const resParse = JSON.parse(result.responseText);
        UpdateOrderCadFiles(resParse.newCadFile)(dispatch);
      }
    };
  };

export const InitializeTrainingProject = (orderId: string, newProjectName: string, dxfDropboxLink: string, tifDropboxLink: string, kmlDropboxLinkfile: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { _id, ownerOrganization } = getState().project.project;
    const url = `${API_URL}/opsusers/initializeTrainingProject/${ownerOrganization}/${_id}/${orderId}`;

    const { token } = getState().auth;

    dispatch(InitializeTrainingProjectAction.request());

    const data = {
      newProjectName,
      dxfDropboxLink,
      tifDropboxLink,
      kmlDropboxLinkfile,
    };

    const result = await postJson<{imageryRequest: IImageryRequest}>(url, data, token);
    if (!result.success) {
      dispatch(InitializeTrainingProjectAction.failure(result.message));
    }
  };
