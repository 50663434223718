// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const InitAsync = createAsyncAction(
  'INIT_START',
  'INIT_SUCCESS',
  'INIT_FAILED',
)<null, { user: IUser, token: string, tokenExpires: number, html: string, resources: any, planDetails: any, failedLoginAttempts: number }, number>();

export const LoginAsync = createAsyncAction(
  'LOGIN_START',
  'LOGIN_SUCCESS',
  'LOGIN_FAILED',
)<null, { user: IUser, token: string, tokenExpires: number, html: string, resources: any }, number>();

export const OktaAdminLoginAsync = createAsyncAction(
  'OKTA_ADMIN_LOGIN_START',
  'OKTA_ADMIN_LOGIN_SUCCESS',
  'OKTA_ADMIN_LOGIN_FAILED',
)<null, { user: { email: string, roles: any }, token: string, tokenExpires: number, resources: any[] }, string>();

export const RegisterAsync = createAsyncAction(
  'REGISTER_START',
  'REGISTER_SUCCESS',
  'REGISTER_FAILED',
)<null, IUser, string>();

export const OnAcceptAsync = createAsyncAction(
  'ONACCEPT_START',
  'ONACCEPT_SUCCESS',
  'ONACCEPT_FAILED',
)<null, IUserResponse, string>();

export const LogoutAction = createAction('LOG_OUT', (action) => () => action());

export const SendForgotPasswordLink = createAction('SEND_FORGOT_PASSWORD_LINK', (action) => (data: boolean) => action(data));
