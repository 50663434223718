// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  sidebarContent: {
    flex: 1,
    width: 320,
    overflow: 'auto',
    padding: theme.spacing(2),
  },
  title: {
    color: '#788898',
  },
  layers: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(1),
  },
  layerName: {
    color: '#021E40',
  },
  lasfileContainer: {
    marginLeft: theme.spacing(2),
  },
  lasfile: {
    fontWeight: 400,
    color: '#021E40',
    padding: '4px 0 4px 8px',
    borderLeft: '3px solid #B0B8C4',
  },
  checkBoxTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  layerGroup: {
    marginTop: theme.spacing(1),
  },
  divider: {
    marginTop: theme.spacing(1),
  },
  checkbox: {
    padding: theme.spacing(0.5),
  },
}));

export default useStyles;
