// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { CSVLink } from 'react-csv';
import { Typography, TableHead, Table, TableRow, TableBody, TableCell, Divider } from '@material-ui/core';
import exportIcon from 'Assets/export.png';
import orderListIcon from 'Assets/order_list_icon.png';
import InfoIcon from '@material-ui/icons/Info';
import { GetProjectDetailsRoute } from 'Utils/routes';
import { URL } from 'Config';
import moment from 'moment';
import { WideLightTooltip } from 'Common/Tooltip';
import LoadingSpinner from 'Common/LoadingSpinner';
import SearchField from 'Common/SearchField';
import OrderListItem from './orderListItem';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders order history table
 *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {array} props.list list of completed orders
 */
export const OrderHistory = ({ list, organization, orgId, orgTeams, GetOrgAction, GetOrgTeamsAction, GetProjectsAction, GetOrdersAction, SetLoadNewAction, SetKeywordFilterAction, SetOrgIdAction }: PropsType) => {
  const classes = useStyles();
  const headers = [
    { label: 'Order Name', key: 'orderName' },
    { label: 'Reference ID', key: 'referenceId' },
    { label: 'Team', key: 'team' },
    { label: 'Date', key: 'date' },
    { label: 'Orders Acres', key: 'orderAcres' },
    { label: 'Charged Credits', key: 'chargedAcres' },
    { label: 'Imagery Credits', key: 'imageryCreditCost' },
    { label: 'Project Size', key: 'projectSize' },
    { label: 'Bundle', key: 'bundle' },
    { label: 'Density', key: 'density' },
    { label: 'Delivery Speed', key: 'deliverySpeed' },
    { label: 'Add ons', key: 'addOns' },
    { label: 'Adjusted Credits', key: 'adjustedCredits' },
    { label: 'Total Adjustment', key: 'totalAdjustment' },
    { label: 'Total Cost for Project', key: 'totalCost' },
    { label: 'Additional Charges', key: 'additionalCost' },
    ..._ADMIN_ ? [] : [{ label: 'Project Link', key: 'projectLink' }],
  ];
  const [showLoader, setShowLoader] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [fileName, setFileName] = useState('');
  const [orderList, setOrderList] = useState([]);

  // Load all projects on the Order History tab in order to display all processed orders for an organization
  useEffect(() => {
    SetOrgIdAction(orgId);
    const loadData = async () => {
      setShowLoader(true);
      SetLoadNewAction(true);
      SetKeywordFilterAction('');
      if (_ADMIN_) {
        await Promise.all([
          GetOrgAction(orgId),
          GetProjectsAction(orgId, 0),
        ]);
      } else {
        await GetProjectsAction('', 0);
      }
      await GetOrgTeamsAction();
      await GetOrdersAction(null, orgId);

      setShowLoader(false);
    };

    loadData();
  }, []);

  const prepareCsvData = () => {
    setCsvData(list.map((order) => {
      const team = orgTeams?.find((t) => t._id === order.team);
      let addOnsList;
      let adjustmentList;
      if (order.addOns.length > 0) {
        addOnsList = order.addOns.map((addOn) => `[${addOn.hasOwnProperty('acres') ? `${addOn.acres.toFixed(2)} ac, $${addOn.cost.toFixed(2)}` : `$${addOn.cost.toFixed(2)}`}] - ${addOn.name || addOn.addOnType}`).join(' \n');
      }
      if (order.creditAdjustments?.length > 0) {
        adjustmentList = order.creditAdjustments.map((adjsmnt) => `[${`${adjsmnt.adjustment >= 0 ? '+' : ''}${adjsmnt.adjustment.toFixed(2)} cr`}] - ${adjsmnt.reason}`).join(' \n');
      }

      const data = {
        orderName: order.name,
        referenceId: order.referenceId || 'N/A',
        team: team?.name || 'N/A',
        date: order.orderDate ? moment.utc(order.orderDate).local().format('MMMM D, YYYY') : 'N/A',
        orderAcres: order.acreage,
        // This includes addOn acres
        chargedAcres: order.creditAcreage,
        imageryCreditCost: order.imageryCreditCost ? `${order.imageryProvider} - ${order.imageryCreditCost} cr` : 'N/A',
        projectSize: order.acreage,
        bundle: order.bundleName,
        density: order.density,
        deliverySpeed: order.deliverySpeed,
        addOns: addOnsList || 'N/A',
        adjustedCredits: adjustmentList,
        totalAdjustment: order.adjustedAcres,
        totalCost: order.costToCustomer ? `$${order.costToCustomer.toFixed(2)}` : 'N/A',
        additionalCost: order.paidForThisProject ? `$${order.paidForThisProject.toFixed(2)}` : 'N/A',
      };
      if (!_ADMIN_) data.projectLink = URL + GetProjectDetailsRoute(order.ownerProject);
      return data;
    }));
    setFileName(`${organization}_orderhistory_${moment().format('YYYYMMDD')}.csv`);
  };

  useEffect(() => {
    prepareCsvData();
    setOrderList(list);
  }, [list]);

  const handleSearchChange = (e: string): void => {
    if (!e) {
      setOrderList(list);
      return;
    }
    const teams = orgTeams.filter(({ name }) => name.toLowerCase().includes(e.toLowerCase()));

    const filteredlist = list.filter(({ referenceId, team, name }) =>
      (referenceId && referenceId.toLowerCase().includes(e.toLowerCase())) ||
      (name && name.toLowerCase().includes(e.toLowerCase())) ||
      (team && teams.some(({ _id }) => _id === team)));

    setOrderList(filteredlist);
  };

  return (
    <LoadingSpinner loading={showLoader}>
      <div className={classes.pageWrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.itemsWrapper}>
            <SearchField
              className={classes.icons}
              placeholder="Search"
              debounceTimeout={500}
              onChange={(e) => handleSearchChange(e)}
            />
            <CSVLink
              data={csvData}
              headers={headers}
              filename={fileName}
              className={classes.exportButton}
              onClick={() => {
                window?.pendo?.track('Export Order History');
              }}
            >
              <img src={exportIcon} alt="export .csv" />
              <Typography variant="h6" className={classes.exportButtonText}>Export CSV</Typography>
            </CSVLink>
          </div>
          <Divider className={classes.divider} />
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography variant="h4">Order Name</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="h4">Reference Id</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="h4">Team</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="h4">Date</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="h4">Order Acres</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="h4">Charged Credits</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <div className={classes.costHeader}>
                  <Typography variant="h4">Cost</Typography>
                  <WideLightTooltip
                    placement="bottom"
                    interactive
                    title={(
                      <div>
                        Projects submitted prior to July 2022 will continue to display the prices charged at the time of submission.
                        Orders from July 2022 onwards have their add-ons charged in credits.
                      </div>
                    )}
                  >
                    <div className={classes.infoIcon}>
                      <InfoIcon className={classes.icon} />
                    </div>
                  </WideLightTooltip>
                </div>
              </TableCell>
              <TableCell className={classes.iconTableCell} align="right" />
              {!_ADMIN_ && <TableCell className={classes.tableCell} align="right" />}
            </TableRow>
          </TableHead>
          {orderList.length ? (
            <TableBody>
              {orderList.map((row: IOrder) => <OrderListItem order={row} key={row._id} />)}
            </TableBody>
          ) : null}
        </Table>
        {orderList.length === 0 ? (
          <div className={classes.noOrderContainer}>
            <img src={orderListIcon} alt="icon" />
            <Typography variant="h5">There are no orders to show.</Typography>
          </div>
        ) : null}
      </div>
    </LoadingSpinner>
  );
};

export default compose(OrderHistory);
