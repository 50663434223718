// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import moment from 'moment-business-days';
import { Moment } from 'moment';
import MomentUtils from '@date-io/moment';
import { Button, Typography } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Form } from 'react-final-form';
import { TextField, Validators } from 'Common/forms';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const CreateProjectForm = ({ CreateProjectAction, onUpload, onCreate, onCreateEstimate, editorFunctions, ReloadProjectsAction, ResetFiltersAction }: PropsType) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date: Moment) => {
    setSelectedDate(date);
  };

  const validateDate = () => {
    if (selectedDate) {
      const anticipatedKickoffDate = selectedDate.format('YYYY-MM-DD');
      const today = moment(new Date()).format('YYYY-MM-DD');
      if (moment(today).isAfter(moment(anticipatedKickoffDate))) return 'Invalid date';
      return anticipatedKickoffDate;
    }
    return undefined;
  };

  const onFormSubmit = async (values: any) => {
    const submitType = values?.submitType;
    const anticipatedKickoffDate = validateDate();
    if (anticipatedKickoffDate === 'Invalid date') return;
    delete values.submitType;
    const result = await CreateProjectAction({ ...values, anticipatedKickoffDate }, editorFunctions);
    if (!_ADMIN_) {
      ResetFiltersAction();
      ReloadProjectsAction();
    }

    if (result.success) {
      if (submitType === 'processProject') {
        window?.pendo?.track('Process A Project');
        if (_ADMIN_) onCreate(result.data);
        onUpload(result.data);
      } else {
        window?.pendo?.track('Create Estimate', { ...(anticipatedKickoffDate && { kickoff_date: selectedDate.format('MM-DD-YYYY') }) });
        onCreateEstimate(result.data);
      }
    }
  };

  return (
    <>
      <div className={classes.pageTitle}>
        <Typography variant="h1">Start New Project</Typography>
      </div>
      <Form
        onSubmit={onFormSubmit}
        render={({ form, handleSubmit }) => (
          <form
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <TextField
              name="name"
              label="Project Name"
              variant="outlined"
              placeholder="Enter Project Name"
              validate={Validators.required}
              inputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
              inputLabelProps={{
                classes: {
                  outlined: classes.inputLabel,
                  shrink: classes.inputLabelShrink,
                },
              }}
              helperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
            />
            <TextField
              name="description"
              label="Description"
              placeholder="Description"
              variant="outlined"
              inputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
              inputLabelProps={{
                classes: {
                  outlined: classes.inputLabel,
                  shrink: classes.inputLabelShrink,
                },
              }}
              helperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
            />
            <div className={classes.fields}>
              <TextField
                name="referenceId"
                label="Reference ID"
                placeholder="Reference ID"
                variant="outlined"
                inputProps={{
                  classes: {
                    notchedOutline: classes.outline,
                    input: classes.input,
                  },
                }}
                inputLabelProps={{
                  classes: {
                    outlined: classes.inputLabel,
                    shrink: classes.inputLabelShrink,
                  },
                }}
                helperTextProps={{
                  classes: {
                    root: classes.helperText,
                  },
                }}
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  allowKeyboardControl={false}
                  variant="inline"
                  format="MM/DD/yyyy"
                  value={selectedDate}
                  onChange={handleDateChange}
                  className={classes.datePicker}
                  label="Anticipated Kickoff"
                  placeholder="Anticipated Kickoff"
                  disablePast
                  disableToolbar
                  minDateMessage="Date should not be past date"
                  PopoverProps={{
                    classes: {
                      root: classes.calender,
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.buttonsWrapper}>
              <Button
                variant="outlined"
                className={classes.uploadFormSubmit}
                type="submit"
                onClick={() => { form.change('submitType', 'processProject'); }}
              >
                PROCESS A PROJECT
              </Button>
              {_ADMIN_ ? '' : (
                <Button
                  variant="outlined"
                  className={classes.uploadFormSubmit}
                  type="submit"
                  onClick={() => { form.change('submitType', 'createEstimate'); }}
                >
                  CREATE ESTIMATE
                </Button>
              )}
            </div>
          </form>
        )}
      />
    </>
  );
};

export default compose(CreateProjectForm);
