// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  GetGeoJsonForLayerAsync, SetClickedPointAction, SetSiteIdAction, SetCurrentLayerAction, SetLayerUpdateAction, EditFeatureAction, SetlayerSelectedAction, AddNewLayerAction,
  DeleteFeatureAction, CreateFeatureAction, DeleteAddedOrEditedFeatureAction, SetDrawModeAction, SetCurrentToolAction, SetEditorModeAction, SetCurrentPanelAction,
  ResetDrawSourceAction, ResetUpdateEntitiesAction, CopyActiveFeatureAction, PasteActiveFeatureAction, CutActiveFeatureAction, SetAutoUpdateAction, SetHighlightedLayerAction,
  SetOrderSelectedAction, SetShowSidebarAction, ResetEditorStateAction, UpdateEntityAction,
} from './actions';

const initialState: IEditorState = {
  featureCollection: {
    type: 'FeatureCollection',
    features: [],
  },
  clickedPoint: null,
  currentLayer: null,
  siteId: null,
  update: false,
  editEntities: [],
  addEntities: [],
  deleteEntities: [],
  currentTool: 'pointer',
  currentPanel: 'layers',
  editorMode: true,
  drawMode: null,
  cloneEntity: null,
  layerSelected: null,
  orderSelected: null,
  autoUpdate: false,
  highlightedLayer: null,
  showSidebar: true,
  updateEntityStart: false,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(GetGeoJsonForLayerAsync.success, (draft, payload) => {
    draft.featureCollection.features = payload.features;
    if (draft.cloneEntity) {
      // assign newly selected layer properties to clone entity properties
      Object.assign(draft.cloneEntity.properties, draft.featureCollection.features[0].properties);
    }
  });
  handleAction(SetClickedPointAction, (draft, payload) => { draft.clickedPoint = payload; });
  handleAction(SetCurrentLayerAction, (draft, payload) => { draft.currentLayer = payload; });
  handleAction(SetSiteIdAction, (draft, payload) => { draft.siteId = payload; });
  handleAction(SetLayerUpdateAction, (draft, payload) => { draft.update = payload; });
  handleAction(SetAutoUpdateAction, (draft, payload) => { draft.autoUpdate = payload; });

  handleAction(UpdateEntityAction.request, (draft) => { draft.updateEntityStart = true; });
  handleAction(UpdateEntityAction.success, (draft) => { draft.updateEntityStart = false; });
  handleAction(UpdateEntityAction.failure, (draft) => { draft.updateEntityStart = false; });

  handleAction(EditFeatureAction, (draft, payload) => {
    // Check if the feature exists in featureCollection
    const index1 = draft.featureCollection.features.findIndex((feature: any) => feature.id === payload.id);
    if (index1 !== -1) {
      draft.featureCollection.features.splice(index1, 1);
      draft.featureCollection.features.push(payload);
    }
    const index2 = draft.editEntities.findIndex((feature: any) => feature.id === payload.id);
    if (index2 !== -1) {
      draft.editEntities[index2] = payload;
    } else {
      draft.editEntities.push(payload);
    }
  });
  handleAction(DeleteFeatureAction, (draft, payload) => {
    draft.deleteEntities.push(payload);
    const index = draft.featureCollection.features.findIndex((feature: any) => feature.id === payload.id);
    if (index !== -1) draft.featureCollection.features.splice(index, 1);
  });
  handleAction(CreateFeatureAction, (draft, payload) => {
    // Check if the feature exists in featureCollection
    const index1 = draft.featureCollection.features.findIndex((feature: any) => feature.id === payload.id);
    if (index1 !== -1) {
      // if it exists, update it
      draft.featureCollection.features[index1] = payload;
    } else {
      draft.featureCollection.features.push(payload);
    }
    // Check if a feature exists in the addEntities array
    const index2 = draft.addEntities.findIndex((feature: any) => feature.id === payload.id);
    if (index2 !== -1) {
      // if it exists, update it
      draft.addEntities[index2] = payload;
    } else {
      draft.addEntities.push(payload);
    }
  });
  handleAction(DeleteAddedOrEditedFeatureAction, (draft, payload) => {
    // Check if the feature exists in featureCollection (-_-)
    const index1 = draft.featureCollection.features.findIndex((feature: any) => feature.id === payload.id);
    if (index1 !== -1) draft.featureCollection.features.splice(index1, 1);
    // Check if feature exists in the addEntities array
    const index2 = draft.addEntities.findIndex((feature: any) => feature.id === payload.id);
    if (index2 !== -1) draft.addEntities.splice(index2, 1);
    // Check if feature exists in the editEntities array
    const index3 = draft.editEntities.findIndex((feature: any) => feature.id === payload.id);
    if (index3 !== -1) draft.editEntities.splice(index3, 1);
  });
  handleAction(SetCurrentToolAction, (draft, payload) => { draft.currentTool = payload; });
  handleAction(CopyActiveFeatureAction, (draft, payload) => { draft.cloneEntity = payload; });
  handleAction(CutActiveFeatureAction, (draft, payload) => {
    const index = draft.featureCollection.features.findIndex((feature: any) => feature.id === payload.id);
    if (index !== -1) draft.featureCollection.features.splice(index, 1);
    draft.cloneEntity = payload;
  });
  handleAction(PasteActiveFeatureAction, (draft, payload) => {
    draft.featureCollection.features.push(payload);
    draft.addEntities.push(payload);
  });
  handleAction(SetCurrentPanelAction, (draft, payload) => { draft.currentPanel = payload; });
  handleAction(SetEditorModeAction, (draft, payload) => { draft.editorMode = payload; });
  handleAction(SetDrawModeAction, (draft, payload) => { draft.drawMode = payload; });
  handleAction(SetHighlightedLayerAction, (draft, payload) => { draft.highlightedLayer = payload; });
  handleAction(ResetDrawSourceAction, (draft) => { draft.featureCollection = initialState.featureCollection; });
  handleAction(ResetUpdateEntitiesAction, (draft) => {
    draft.editEntities = initialState.editEntities;
    draft.addEntities = initialState.addEntities;
    draft.deleteEntities = initialState.deleteEntities;
  });
  handleAction(SetlayerSelectedAction, (draft, payload) => { draft.layerSelected = payload; });
  handleAction(SetShowSidebarAction, (draft, payload) => { draft.showSidebar = payload; });
  handleAction(SetOrderSelectedAction, (draft, payload) => { draft.orderSelected = payload; });
  handleAction(ResetEditorStateAction, () => initialState);
});
