// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { WithStyles, withStyles } from '@material-ui/core';
import { getOrders } from 'State/order/selectors';
import styles from './styles';

const mapStateToProps = (state: IState) => {
  const { additionalDeliverables, selectedBundle, selectedAdditionals } = state.bundle;
  return {
    bundleName: selectedBundle?.name,
    bundleId: selectedBundle?.bundleId,
    additionalDeliverables,
    selectedAdditionals,
    deliverablesBoolean: additionalDeliverables && additionalDeliverables.some((d) => d.subtype === 'Deliverables'),
    topographyDeliverableBoolean: additionalDeliverables && additionalDeliverables.some((d) => d.subtype === 'Topography' && d.type === 'deliverable'),
    order: getOrders(state).activeOrder,
  };
};

interface IOwnProps {
    onAddOnsClick: (item: IDeliverable, checked: boolean) => void;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps),
);
