// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { IconButton } from '@material-ui/core';
import downloadIcon from 'Assets/download-icon.png';
import compose from './container';

const OrthomosaicButtons = compose(({ classes, tifFiles, DownloadTIFAction }) => {
  const handleDownloadAll = async () => {
    const interval = 3000;
    tifFiles.forEach((file, index) => {
      setTimeout(async () => {
        const name = file.filename.split('/').pop();
        await DownloadTIFAction(name);
      }, index * interval);
    });
  };

  // Disable 'Download all' button when there are more than six tifs
  return (
    <div>
      {tifFiles && (tifFiles.length > 1 && tifFiles.length <= 6) ? (
        <IconButton
          title="Download all .tif files"
          aria-label="Download all .tif files"
          className={classes.button}
          onClick={() => handleDownloadAll()}
        >
          <img src={downloadIcon} alt="Download all .tif files" />
        </IconButton>
      ) : <div />}
    </div>
  );
});

export default OrthomosaicButtons;
