// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import { SearchAsync, ClearSuggestionsAction } from './actions';

const initialState: IGeocodeState = {
  mapSearchLoading: false,
  mapSuggestions: [],
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(SearchAsync.request, (draft) => { draft.mapSearchLoading = true; });
  handleAction(SearchAsync.success, (draft, payload) => {
    draft.mapSearchLoading = false;
    draft.mapSuggestions = payload;
  });
  handleAction(ClearSuggestionsAction, (draft) => { draft.mapSuggestions = []; });
});
