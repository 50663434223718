// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import {
  ACCOUNT_GENERAL_ROUTE, ACCOUNT_BILLING_ROUTE, ACCOUNT_PASSWORD_ROUTE, ORDER_HISTORY_ROUTE, ACCOUNT_PLAN_USAGE, USER_MANAGEMENT_ROUTE, TEAMS_ROUTE, GetTemplatesRoute,
} from 'Utils/routes';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component to render tab names in account management section for the user
 *
 * @param {object} props.classes Injected style props
 * @param {object} props.org Object containing org details
 * @param {number} props.activeTabId Number containing active tab ID
 * @param {boolean} props.organizationAccess Boolean to indicate if the user has organization access
 * @param {boolean} props.billing Boolean to indicate if the user has billing access
 * @param {boolean} props.processingUsage Boolean to indicate if the user has processingUsage access
 * @param {boolean} props.cadTemplates Boolean to indicate if the user has cadTemplates access
 */
export const AccountToolbar = ({ org, activeTabId, organizationAccess, billing, processingUsage, cadTemplates, placeOrder }: PropsType) => {
  const classes = useStyles();

  return (
    <Tabs indicatorColor="primary" value={activeTabId}>
      <Tab
        component={Link}
        label="General"
        value={1}
        to={ACCOUNT_GENERAL_ROUTE}
        classes={{
          wrapper: classes.tabLabel,
        }}
      />
      {cadTemplates && (
        <Tab
          component={Link}
          label="CAD Templates"
          value={2}
          to={GetTemplatesRoute(org.defaultTemplate)}
          classes={{
            wrapper: classes.tabLabel,
          }}
        />
      )}
      {billing && (
        <Tab
          component={Link}
          label="Subscription"
          value={3}
          to={ACCOUNT_BILLING_ROUTE}
          classes={{
            wrapper: classes.tabLabel,
          }}
        />
      )}
      <Tab
        component={Link}
        label="Password"
        value={4}
        to={ACCOUNT_PASSWORD_ROUTE}
        classes={{
          wrapper: classes.tabLabel,
        }}
      />
      {placeOrder && (
        <Tab
          component={Link}
          label="Order History"
          value={5}
          to={ORDER_HISTORY_ROUTE}
          classes={{
            wrapper: classes.tabLabel,
          }}
        />
      )}
      {processingUsage && (
        <Tab
          component={Link}
          label="Plan Usage"
          value={6}
          to={ACCOUNT_PLAN_USAGE}
          classes={{
            wrapper: classes.tabLabel,
          }}
        />
      )}
      {organizationAccess && (
        <Tab
          component={Link}
          label="User Management"
          value={7}
          to={USER_MANAGEMENT_ROUTE}
          classes={{
            wrapper: classes.tabLabel,
          }}
        />
      )}
      {organizationAccess && (
        <Tab
          component={Link}
          label="Teams"
          value={8}
          to={TEAMS_ROUTE}
          classes={{
            wrapper: classes.tabLabel,
          }}
        />
      )}
    </Tabs>
  );
};

export default compose(AccountToolbar);
