// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { GetVectorTiles, ChangeVersion, ToggleCadDrawings } from 'State/map/common/thunk';
import { ResetDrawSource, SetlayerSelected } from 'State/map/editor/thunk';
import { SetFileVersion } from 'State/order/thunk';
import styles from './styles';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => ({
  layers:
    state.map.common.vectorTileJson &&
    state.map.common.vectorTileJson[ownProps.order._id] &&
    state.map.common.vectorTileJson[ownProps.order._id][state.order.fileVersions[ownProps.order._id]],
  projectId: state.project.projectId,
  userId: state.auth.user.ownerOrganization,
  orderId: ownProps.order._id,
  fileVersions: state.order.fileVersions,
  state: state.map.common,
  projectAccessUS: 'projectAccessUS' in state.auth.resources,
  projectAccessIndia: 'projectAccessIndia' in state.auth.resources,
  opsTrainer: 'opsTrainer' in state.auth.resources,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    SetFileVersionAction: SetFileVersion,
    GetVectorTilesAction: GetVectorTiles,
    ChangeVersionAction: ChangeVersion,
    ToggleCadDrawingsAction: ToggleCadDrawings,
    ResetDrawSourceAction: ResetDrawSource,
    SetlayerSelectedAction: SetlayerSelected,
  }, dispatch);

interface IOwnProps {
  order: IOrder;
  index: number;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
