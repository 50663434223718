// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAsyncAction } from 'typesafe-actions';

export const LoadListAsync = createAsyncAction(
  'GET_NOTIFICATIONS_START',
  'GET_NOTIFICATIONS_SUCCESS',
  'GET_NOTIFICATIONS_FAILED',
)<null, INotification[], string>();

export const ToggleAsync = createAsyncAction(
  'TOGGLE_NOTIFICATION_START',
  'TOGGLE_NOTIFICATION_SUCCESS',
  'TOGGLE_NOTIFICATION_FAILED',
)<null, INotification, string>();
