// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { SetBufferSize, GenerateKmlBuffer, SetLineStringUpload } from 'State/kml/thunk';

const mapStateToProps = (state: IState) => ({
  lineStringKmlUpload: state.kml.present.lineStringKmlUpload,
  bufferSize: state.kml.present.bufferSize,
  creatingBuffer: state.kml.present.creatingBuffer,
});

const mapDispatchToProps = {
  SetBufferSizeAction: SetBufferSize,
  GenerateKmlBufferAction: GenerateKmlBuffer,
  SetLineStringUploadAction: SetLineStringUpload,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default compose<PropsType, null>(
  connect(mapStateToProps, mapDispatchToProps),
);
