// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  dialogRoot: {
    position: 'absolute',
  },
  backdrop: {
    position: 'absolute',
  },
  dialog: {
    width: 500,
  },
  paperFullscreen: {
    height: 'auto',
  },
  dropzoneWrapper: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.grey[50],
  },
  openFileDialogLink: {
    color: theme.palette.primary.main,
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    font: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  uploadIconWrapper: {
    width: 120,
    height: 120,
    backgroundColor: theme.palette.grey[100],
    borderRadius: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: theme.spacing(2),
  },
  uploadFormIcon: {
    width: theme.spacing(5),
    hieght: theme.spacing(5),
  },
  uploadFormText: {
    paddingBottom: theme.spacing(1),
  },
  progressRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  progress: {
    width: theme.spacing(8),
  },
  progressBar: {
    backgroundColor: '#FAA738',
  },
});

export default styles;
