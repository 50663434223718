// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { GetCreditCards, GetPlans, CreateSubscription, GetOrgSubscription, GetProratedCharge, ClearPromoCodeInfo } from 'State/subscription/thunk';

import styles from './styles';

const mapStateToProps = (state: IState) => ({
  cadSubscription: state.subscription.cadSubscription,
  creditCards: state.subscription.creditCards,
  proratedCharges: state.subscription.proratedCharges,
});

const mapDispatchToProps = {
  CreateSubscriptionAction: CreateSubscription,
  GetCreditCardsAction: GetCreditCards,
  GetPlansAction: GetPlans,
  GetOrgSubscriptionAction: GetOrgSubscription,
  GetProratedChargeAction: GetProratedCharge,
  ClearPromoCodeInfoAction: ClearPromoCodeInfo,
};

interface IOwnProps {
  setShowSubscriptionDialog: (show: boolean) => void;
  cadSubscriptionExists: boolean;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
