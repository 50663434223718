// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import { mininumBufferSize } from 'Utils/constants';
import { SetKmlAction, LoadKmlAsync, CreateKmlBufferAsync, ToggleKmlVisibility, AdminToggleKmlVisibility, ClearKmlsAction, SetShowBusinessDaysAction, SetLineStringUploadAction, SetShowBufferInfoAction, SetBufferSizeAction, ResetBufferStateAction, SetKmlErrorAction, SetKmlBBoxAction } from './actions';

const initialState: IKmlState = {
  kmls: {},
  loading: false,
  showBusinessDays: true,
  lineStringKmlUpload: false,
  bufferSize: mininumBufferSize,
  creatingBuffer: false,
  showBufferInfo: false,
  kmlError: false,
  bbox: null,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(SetKmlAction, (draft, payload) => {
    draft.kmls[payload.orderId] = {
      visible: true,
      featureCollection: payload.kml,
    };
  });

  handleAction(LoadKmlAsync.request, (draft) => { draft.loading = true; });
  handleAction(LoadKmlAsync.success, (draft, payload) => {
    const kmls: {
      [key: string]: {
        visible: boolean;
        featureCollection: GeoJSON.FeatureCollection<GeoJSON.Polygon>;
      };
    } = {};

    payload.forEach((p) => {
      kmls[p.orderId] = {
        visible: !_ADMIN_,
        featureCollection: p.kml,
      };
    });

    draft.kmls = kmls;
    draft.loading = false;
  });
  handleAction(LoadKmlAsync.failure, (draft) => { draft.loading = false; });

  handleAction(ToggleKmlVisibility, (draft, payload) => {
    draft.kmls[payload].visible = !draft.kmls[payload].visible;
  });

  handleAction(ClearKmlsAction, (draft) => { draft.kmls = {}; });

  handleAction(AdminToggleKmlVisibility, (draft, payload) => {
    Object.keys(draft.kmls).forEach((id) => {
      if (payload === id) {
        draft.kmls[id].visible = true;
      } else {
        draft.kmls[id].visible = false;
      }
    });
  });

  handleAction(SetShowBusinessDaysAction, (draft, payload) => {
    draft.showBusinessDays = payload;
  });

  handleAction(SetLineStringUploadAction, (draft, payload) => {
    draft.lineStringKmlUpload = payload;
  });

  handleAction(SetBufferSizeAction, (draft, payload) => {
    draft.bufferSize = payload;
  });

  handleAction(ResetBufferStateAction, (draft) => {
    draft.bufferSize = mininumBufferSize;
    draft.showBufferInfo = false;
    draft.kmlError = false;
  });

  handleAction(CreateKmlBufferAsync.request, (draft) => { draft.creatingBuffer = true; });
  handleAction(CreateKmlBufferAsync.success, (draft) => { draft.creatingBuffer = false; });
  handleAction(CreateKmlBufferAsync.failure, (draft) => { draft.creatingBuffer = false; });

  handleAction(SetShowBufferInfoAction, (draft, payload) => {
    draft.showBufferInfo = payload;
  });

  handleAction(SetKmlErrorAction, (draft, payload) => { draft.kmlError = payload; });

  handleAction(SetKmlBBoxAction, (draft, payload) => { draft.bbox = payload; });
});
