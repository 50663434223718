// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Dialog, DialogActions, Typography, Button, Radio, RadioGroup, TextField, FormControlLabel, FormControl } from '@material-ui/core/';
import compose, { PropsType } from './container';
import useStyles from './styles';

interface StringObject { [key: string]: string; }

const reasons: StringObject = {
  quality: 'Image Quality - I need higher resolution',
  date: 'Imagery Date - I need more recent imagery',
  accident: 'I submitted this order by accident',
  bad: 'This imagery is not appropriate for my project',
  other: 'Other',
};

export const CancelImageryRequestDialog = ({ showDialog, setShowDialog, RejectImageryAction }: PropsType) => {
  const classes = useStyles();
  const [reason, setReason] = useState('');
  const [selection, setSelection] = useState('');
  const [openOther, setOpenOther] = useState(false);

  const changeReason = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSelection(value);

    if (value !== 'other') {
      setOpenOther(false);
      setReason(reasons[value]);
    } else {
      setOpenOther(true);
    }
  };

  const onClose = () => {
    setSelection('');
    setShowDialog(false);
    setOpenOther(false);
  };

  const onOk = () => {
    onClose();
    window?.pendo?.track('Cancel Order', {
      cancelReason: reason,
    });
    RejectImageryAction(reason);
  };

  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
    >
      <Typography className={classes.dialogTitle} variant="h2">Are you sure?</Typography>
      <Typography className={classes.dialogSubtitle} variant="h6">Please share the reason behind canceling this order:</Typography>
      <FormControl className={classes.dialogContent}>
        <RadioGroup
          className={classes.radioContent}
          onChange={changeReason}
          value={selection}
          defaultValue={0}
          name="cancel-imagery-request"
        >
          <FormControlLabel value="quality" control={<Radio />} label={reasons.quality} />
          <FormControlLabel value="date" control={<Radio />} label={reasons.date} />
          <FormControlLabel value="accident" control={<Radio />} label={reasons.accident} />
          <FormControlLabel value="bad" control={<Radio />} label={reasons.bad} />
          <FormControlLabel value="other" control={<Radio />} label={reasons.other} />

        </RadioGroup>
        { openOther && (
          <div className={classes.textContent}>
            <TextField
              onChange={(e) => setReason(e.target.value)}
              multiline
              rows={3}
              maxRows={5}
              variant="outlined"
              label="Required"
              style={{ width: 400 }}
            />
          </div>
        )}
      </FormControl>
      <DialogActions className={classes.dialogButtons}>
        <Button onClick={onClose}>Cancel</Button>
        <Button variant="contained" color="primary" onClick={onOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(CancelImageryRequestDialog);
