// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const GetBundlesAction = createAsyncAction(
  'GET_BUNDLES_START',
  'GET_BUNDLES_SUCCESS',
  'GET_BUNDLES_FAILED',
)<null, IBundleResponse, string>();

export const SetDeliverablesAction = createAction('SET__DELIVERABLES', (action) => (data: IBundle) => action(data));

export const SetDefaultBundleAction = createAction('SET_DEFAULT_BUNDLE', (action) => (data: IBundle) => action(data));

export const SelectedAdditionalDeliverablesAndLayersAction = createAction('SELECTED_ADDITIONALS', (action) => (data: IDeliverable) => action(data));

export const ResetBundleStateAction = createAction('RESET_BUNDLE_STATE');

export const SetTopoMajrAction = createAction('SET_TOPO_MAJR', (action) => (data: number) => action(data));

export const SetTopoMinrAction = createAction('SET_TOPO_MINR', (action) => (data: number) => action(data));

export const SetDensityAction = createAction('SET_DENSITY', (action) => (data: string) => action(data));

export const SetDeliveryDateAction = createAction('SET_DELIVERY_DATE', (action) => (data: string) => action(data));

export const SetDeliveryDaysAction = createAction('SET_DELIVERY_DAYS', (action) => (data: number) => action(data));
