// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { GetOrg, SetOrgId, UpdateCostPerAcre, UpdateOrg } from 'State/admin/thunk';
import { GetOrgSubscription, GetPlans } from 'State/subscription/thunk';

const mapStateToProps = (state: IState) => ({
  costPerAcre: state.admin.org.costPerAcre,
  imageryProviders: state.imagery.imageryProviders,
  createdAt: state.admin.org.createdAt,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      GetOrgAction: GetOrg,
      GetOrgSubscriptionAction: GetOrgSubscription,
      SetOrgIdAction: SetOrgId,
      GetPlansAction: GetPlans,
      UpdateCostPerAcreAction: UpdateCostPerAcre,
      UpdateOrgAction: UpdateOrg,
    },
    dispatch,
  );

interface IOwnProps {
  org: IOrg;
  cadSubscription: ISubscriptions;
  automateSubscription: ISubscriptions;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
