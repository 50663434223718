// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState, useCallback } from 'react';
import { Button, Typography, Card, Divider, Fade, CircularProgress, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import moment from 'moment-timezone';
import PromoForm from '../PromoForm';
import compose from './container';

moment.tz.setDefault('America/New_York');

const PlanDetails = compose(({ classes, plans, page, setPage, promoValid, ClearPromoCodeInfoAction,
  discountPercent, discountAmount, totalCostAfterDiscount, promoCode, immediateCost, updatedCost, billingPeriod, daysRemaining, cards, cadSeats, cadSeatsChanged, cadSubscription, cadSubscriptionExists, currentInvoice, CreateSubscriptionAction, EditSubscriptionAction, GetInvoiceAction }) => {
  const [planSelected, setPlanSelected] = useState(null);
  const [renewDate, setRenewDate] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [planCost, setPlanCost] = useState(0);
  const [showPromoForm, setShowPromoForm] = useState(true);
  const [applyPromoCode, setApplyPromoCode] = useState(false);
  const [discount, setDiscount] = useState('');
  const [finalCostAfterDiscount, setFinalCostAfterDiscount] = useState('');

  useEffect(() => {
    const selectedPlan = plans.find((plan) => {
      if (billingPeriod.toLowerCase() === 'monthly' && plan.planType === 'month') {
        return plan;
      }
      if (billingPeriod.toLowerCase() === 'annual' && plan.planType === 'year') {
        return plan;
      }
    });
    setPlanSelected(selectedPlan);
    const currentDate = moment();
    let endDate;
    if (cadSubscriptionExists && !cadSubscription.trial) {
      endDate = moment(cadSubscription.cadCurrentPeriodEnd);
      setRenewDate(endDate.format('LL'));
    } else if (billingPeriod === 'annual') {
      endDate = currentDate.add(1, 'y');
      setRenewDate(endDate.format('LL'));
    } else {
      endDate = currentDate.add(1, 'M');
      setRenewDate(endDate.format('LL'));
    }
  }, [billingPeriod]);

  const parseCost = (cost: string) => {
    let costSubstring = cost.substring(1);
    costSubstring = costSubstring.replace(',', '');
    return parseFloat(costSubstring);
  };

  const handleCloseClick = () => {
    setShowPromoForm(true);
    setApplyPromoCode(false);
    ClearPromoCodeInfoAction();
  };

  useEffect(() => {
    if (immediateCost) {
      setPlanCost(parseCost(immediateCost));
    }
  }, [immediateCost]);

  useEffect(() => {
    if (promoValid || applyPromoCode) {
      setShowPromoForm(false);
      setApplyPromoCode(true);
      const cadTrial = (cadSubscriptionExists && cadSubscription.trial);
      if (cadTrial || (discountPercent && !discountAmount && !totalCostAfterDiscount)) {
        if (planSelected) {
          const productCost = planSelected.amount * cadSeats;
          const discountedAmount = (productCost * discountPercent) / 100;
          const costAfterDiscount = productCost - discountedAmount;
          setDiscount(`$${discountedAmount.toFixed(2)}`);
          setFinalCostAfterDiscount(`$${costAfterDiscount.toFixed(2)}`);
        }
      } else {
        setDiscount(discountAmount);
        setFinalCostAfterDiscount(totalCostAfterDiscount);
      }
    }
  }, [promoValid, cadSeats, planSelected]);

  const onPurchase = async () => {
    setIsSubmitting(true);
    if (cards) {
      createOrEditSubscription();
    }
  };

  const createOrEditSubscription = async () => {
    let purchaseResult;
    if (cadSubscriptionExists && !cadSubscription.trial) {
      purchaseResult = await EditSubscriptionAction(cadSeats);
    } else {
      purchaseResult = await CreateSubscriptionAction(planSelected.id, cadSeats);
    }
    setIsSubmitting(false);
    if (purchaseResult.success) {
      setPage(1);
    }
  };
  useEffect(() => {
    if (cadSubscription.latestInvoice) {
      GetInvoiceAction(cadSubscription.latestInvoice);
    }
  }, [cadSubscription]);

  const onDownloadInvoice = useCallback(
    () => {
      const link = document.createElement('a');
      link.href = currentInvoice.invoicePdf;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    [currentInvoice],
  );

  return ((
    <div className={classes.card}>
      <div className={classes.stripeGradient}>
        <br />
      </div>
      <Card className={classes.cardContent}>
        {cadSeatsChanged > 0 && (
          <>
            <Typography className={classes.cardTitle} variant="h3">Changes to Plan</Typography>
            <Typography variant="h5" className={classes.planDetails}>Due Today</Typography>
            <Divider className={classes.divider} />
            <div className={classes.calculation}>
              <div>
                <Typography variant="h3">
                  {Math.abs(cadSeatsChanged)}
                  {' '}
                  Additional User(s) (
                  {cadSeats}
                  {' '}
                  total)
                </Typography>
                <Typography variant="h5" className={classes.planDetails}>
                  {Math.abs(cadSeatsChanged)}
                  {' '}
                  x $
                  {immediateCost ? ((planCost / Math.abs(cadSeatsChanged)).toFixed(2)) : '-'}
                  ,
                  Billed
                  {' '}
                  {billingPeriod.toLowerCase() === 'annual' ? 'annually' : billingPeriod}
                  ,
                  prorated for
                  {' '}
                  {daysRemaining}
                  {' '}
                  days.
                </Typography>
              </div>
              <Typography variant="h3">{immediateCost || '-'}</Typography>
            </div>
            <Divider className={classes.divider} />

            <div className={classes.calculation}>
              <Typography variant="h3">Total Due Now</Typography>
              <Typography variant="h3">{immediateCost || '-'}</Typography>
            </div>
            <Divider className={classes.boldDivider} />
          </>
        )}
        <Typography className={classes.cardTitle} variant="h3">
          {' '}
          AirWorks CAD
          {' '}
          {` - ${(planSelected && planSelected.nickname)} `}
          {' '}
        </Typography>
        <Typography variant="h5" className={classes.planDetails}>
          Automatically renews on
          {' '}
          {renewDate}
        </Typography>
        <Divider className={classes.divider} />
        <div className={classes.calculation}>
          <div>
            <Typography variant="h3">
              {cadSeats}
              {' '}
              User(s)
            </Typography>
            <Typography variant="h5" className={classes.planDetails}>
              {cadSeats}
              {' '}
              x $
              {planSelected && planSelected.amount}
              ,
              Billed
              {' '}
              {billingPeriod === 'annual' ? 'annually' : billingPeriod}
            </Typography>
          </div>
          <Typography variant="h3">{cadSeatsChanged ? updatedCost : `$${(planSelected && (planSelected.amount * cadSeats))}`}</Typography>
        </div>
        <Divider className={classes.divider} />
        {/* We only want to show promo code when user is creating a subscription for the first time or if they are on cad trial */}
        {(!cadSubscriptionExists || (cadSubscriptionExists && cadSubscription.trial) || (cadSubscriptionExists && page === 1)) && (
          <>
            <Typography variant="h3">Promo Code</Typography>
            <div>
              {showPromoForm ?
                <PromoForm cadSeats={cadSeats} showPromoForm={showPromoForm} setShowPromoForm={setShowPromoForm} />
                : (
                  <>
                    <div className={classes.promoSection}>
                      <div className={classes.promoTag}>
                        <div className={classes.promoCode}>
                          <Typography className={classes.promoCodeText}>
                            {promoCode}
                          </Typography>
                        </div>
                        <Typography className={classes.promoDiscount}>
                          {`${discountPercent}% Discount`}
                        </Typography>
                        {page !== 1 ? (
                          <IconButton
                            className={classes.promoClose}
                            color="inherit"
                            onClick={handleCloseClick}
                            aria-label="Close"
                          >
                            <CloseIcon fontSize="small" />
                          </IconButton>
                        )
                          : <CheckIcon className={classes.promoCheck} fontSize="small" />}
                      </div>
                      <Typography variant="h3">
                        -
                        {discount}
                      </Typography>
                    </div>
                  </>
                )}
            </div>
            <Divider className={classes.divider} />
          </>
        )}
        <div className={classes.calculation}>
          <Typography variant="h3">Total</Typography>
          <Typography variant="h3">{applyPromoCode ? finalCostAfterDiscount : (cadSeatsChanged ? updatedCost : `$${(planSelected && (planSelected.amount * cadSeats))}`)}</Typography>
        </div>
        {page === 0 ? (
          <Button
            className={classes.purchaseButton}
            variant="contained"
            type="submit"
            disabled={!cards || isSubmitting || (cadSubscriptionExists && !cadSubscription.trial && !cadSeatsChanged)}
            onClick={onPurchase}
          >
            <Fade in={isSubmitting}>
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Fade>
            <Fade in={!isSubmitting}>
              <span>Purchase now</span>
            </Fade>
          </Button>
        ) : cadSubscription.latestInvoice && (
          <Button
            className={classes.purchaseButton}
            variant="contained"
            onClick={onDownloadInvoice}
          >
            <span>Download Invoice</span>
          </Button>
        )}
      </Card>
    </div>
  ));
});

export default PlanDetails;
