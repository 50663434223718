// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  sidebar: {
    margin: 0,
    position: 'relative',
    zIndex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '0px',
    borderRight: '1px solid #E3E7EC',
  },
}));

export default useStyles;
