// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import compose from './container';

const OrthomosaicChckbox = compose(({ classes, layers, ToggleOrthomosaicLayerAction }) => (
  <FormControlLabel
    label={<Typography variant="h4" className={classes.sidebarHeader}>Orthomosaic</Typography>}
    control={(
      <Checkbox
        color="primary"
        onChange={() => ToggleOrthomosaicLayerAction()}
        checked={layers.orthomosaic}
        classes={{
          root: classes.checkbox,
        }}
      />
    )}
  />
));

export default OrthomosaicChckbox;
