// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from "react";
import compose from "./container";
import { Popup } from "react-mapbox-gl";
import DrawControl from "react-mapbox-gl-draw";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import CustomDirectSelect from '../MapEditor/curvemode/customDirectSelectMode';
import drawAngle from './modes/draw_angle';
import area from "@turf/area";
import centroid from "@turf/centroid";
import length from "@turf/length";
import bearing from "@turf/bearing";
import DeleteIcon from "@material-ui/icons/Delete";
import { Typography } from "@material-ui/core";
import { drawToolStyles } from './styles';

MapboxDraw.modes.direct_select = CustomDirectSelect;

/**
 * Component renders the draw component for map measurements along with displaying the measurement label
 *
 * @param {object} props Component props
 * @param {string} props.classes Injected style props
 * @param {string} props.modeName Current draw mode that draw is in
 * @param {string} props.currentMeasurementOption Measurement option that the user selected
 * @param {boolean} props.measurementMode Boolean to detect selecting a measurement option so that syncDraw is called
 * @param {function} props.SetModeAction Sets the current modename in the state
 */
const MapMeasurements = compose(({ classes, modeName, currentMeasurementOption, measurementMode, SetModeAction, SetCurrentMeasurementOptionAction}) => {
    const [draw, setDraw] = useState(null);
    const [modeFeatureId, setModeFeatureId] = useState(null);
    const [featureCollection, setFeatureCollection] = useState({
      type: "FeatureCollection",
      features: []
    });

    const syncDraw = () => {
      if (draw === null) return;
      if (draw.getMode() === "draw_polygon" && modeName === "draw_polygon") return;
      if (draw.getMode() === "draw_line_string" && modeName === "draw_line_string") return;
      if (draw.getMode() === "draw_angle" && modeName === "draw_angle") return;
      if (draw.getMode() === "draw_circle" && modeName === "draw_circle") return;

      draw.set(featureCollection);
      if (modeName) {
        if (modeName === "direct_select") {
          if (!featureCollection || featureCollection.features.length === 0) return;
        } else {
          draw.changeMode(modeName);
        }
      }
    };

    const switchModes = () => {
    if (currentMeasurementOption === 'line_string' || currentMeasurementOption === 'polygon' || currentMeasurementOption === 'angle' || currentMeasurementOption === 'circle') {
      let _fc = featureCollection.features
        for(let index=featureCollection.features.length-1; index >= 0; index--){
          setDraw(draw.delete(_fc[index].id).getAll());
          setDraw(null);
          _fc.splice(index, 1)
        }
        setFeatureCollection((prevState) => ({
          type: prevState.type,
          features: _fc
        }))
      }
    };

    useEffect(() => {
      syncDraw();
    }, [draw, modeName, modeFeatureId, featureCollection, measurementMode]);

    useEffect(() => {
      switchModes();
    }, [currentMeasurementOption]);

    const getPolyArea = (feature: any) => (area(feature)*10.764).toFixed(2);
    const getLineDistance = (feature: any) => (length(feature)*3280.84).toFixed(2);
    const getCoordinatesCentroid = (feature: any) => centroid(feature).geometry.coordinates;
    const getAngle = (feature: any) => {
      let coordinates = feature.geometry.coordinates;
      if (coordinates.length < 3) return;
      let bearing1 = bearing(coordinates[1], coordinates[0])
      let bearing2 = bearing(coordinates[1], coordinates[2])
      let r = (bearing2 - bearing1) % 360.0
      if (r >= 180.0) r-=360.0
      return Math.abs(r).toFixed(2);
    }

    const popupText = (feature: any) => {
      if (feature.geometry.type === "Polygon") {
        return `${getPolyArea(feature)} sq.ft.`;
      }
      if (feature.geometry.type === "LineString") {
        if (feature.properties.type === "Angle"){
          return `${getAngle(feature)}°`;
        }
        return `${getLineDistance(feature)} ft.`;
      }
    };

    const updateDraw = (e: any) => {
      const action = e.action;
      const feature = e.features[0];
      if (feature) {
        let _fc = featureCollection;
        if (action && (action === "move" || action === "change_coordinates")) {
          _fc.features.forEach((f, i) => {
            if (f.id === feature.id) {
              _fc.features.splice(i, 1);
            }
          });
        }
        _fc.features.push(feature);
        setFeatureCollection(prevState => ({
          type: prevState.type,
          features: _fc.features
        }));
      }
    };

    return (
      <>
        <div>
          <DrawControl
            userProperties={true}
            displayControlsDefault={false}
            styles={drawToolStyles}
            ref={drawControl => {
              if (drawControl !== null && drawControl.draw !== null) {
                setDraw(drawControl.draw);
              }
            }}
            modes={{
              ...MapboxDraw.modes,
              direct_select: CustomDirectSelect,
              draw_angle: drawAngle,
            }}
            onDrawCreate={updateDraw}
            onDrawUpdate={updateDraw}
            onDrawSelectionChange={e => {
              if (e.features.length === 0 && e.points.length === 0) {
                return;
              } else {
                SetModeAction("direct_select");
                setModeFeatureId(e.features[0].id);
                SetCurrentMeasurementOptionAction(null);
              }
            }}
          />
        </div>
        {featureCollection.features.length > 0 &&
          featureCollection.features.map((f, index) => {
            return (
              <Popup coordinates={getCoordinatesCentroid(f)} className={classes.popup}>
                <Typography className={classes.popupText} variant="body2">
                  {popupText(f)}
                </Typography>
                <DeleteIcon
                  className={classes.popupDelete}
                  onClick={() => {
                    let _fc = featureCollection;
                    _fc.features.splice(index, 1);
                    setFeatureCollection(_fc);
                    setDraw(draw.delete(f.id).getAll());
                    setDraw(null);
                  }}
                />
              </Popup>
            );
          })}
      </>
    );
  }
);

export default MapMeasurements;
