// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { AdjustOrderCredits } from 'State/order/thunk';

interface IOwnProps {
  showDialog: boolean;
  onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onCancel: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  confirmAmount: number;
  orderId: string;
  orgId: string;
  projectId: string;
}

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    AdjustOrderCreditsAction: AdjustOrderCredits,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(null, mapDispatchToProps),
);
