// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import LoadingSpinner from 'Common/LoadingSpinner';
import useInterval from 'Common/hooks/useInterval';
import ExpiredSessionModal from 'Common/ExpiredSessionModal';
import { useOktaAuth } from '@okta/okta-react';
import Header from './Header';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const Shell = ({ children,
  GetProjectsAction, GetOrdersAction, GetOrderStatusAction, GetUserProfileAction, GetImageryProvidersAction, GetOrgProfileAction, GetPlansAction, GetNotificationsAction, GetTemplateAction, user, org, opsTrainer, token, OktaAdminLoginAction }: PropsType) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const { authState, oktaAuth } = _ADMIN_ && useOktaAuth();

  useEffect(() => {
    const loadData = async () => {
      // eslint-disable-next-line no-unused-expressions
      authState && !token && await OktaAdminLoginAction(authState.accessToken);
    };
    // eslint-disable-next-line no-unused-expressions
    _ADMIN_ && loadData();
  }, [authState, oktaAuth]);

  const loadData = async () => {
    const promiseArray = [
      GetProjectsAction(),
      GetUserProfileAction(),
      GetOrgProfileAction(),
      GetPlansAction(),
      GetNotificationsAction(),
      GetTemplateAction(),
    ];
    // no need to get all orders and order status for ops training users
    // only required to display pizza tracker
    // eslint-disable-next-line no-unused-expressions
    !opsTrainer && promiseArray.push(GetOrdersAction(), GetOrderStatusAction());
    const resultArray = await Promise.all(promiseArray);
    const anyFailed = resultArray.some((r) => !r.success);
    setLoading(anyFailed);
  };

  useEffect(() => {
    _ADMIN_ ? setLoading(false) : loadData();
  }, []);

  useEffect(() => {
    if (!_ADMIN_ && org?.imageryProviders?.length > 0) {
      GetImageryProvidersAction();
    }
  }, [org?.imageryProviders]);

  useEffect(() => {
    const script = document.createElement('script');

    script.src = '//js.hs-scripts.com/3434755.js';
    script.async = true;

    document.body.appendChild(script);
    // Start of Survicate (www.survicate.com) code
    if (window.location.hostname === 'cloud.airworks.io' && org !== null) {
      (function (opts) {
        opts.traits = {
          email: user.email,
          first_name: user.firstName,
          last_name: user.lastName,
          organization: org.companyName,
        };
      }(window._sva = window._sva || {}));
      (function (w) {
        const s = document.createElement('script');
        s.src = 'https://survey.survicate.com/workspaces/e446d987fb1a2dc0466a010b938591dd/web_surveys.js';
        s.async = true;
        const e = document.getElementsByTagName('script')[0];
        e.parentNode.insertBefore(s, e);
      }(window));
    }
    // End of Survicate code
    return () => {
      document.body.removeChild(script);
    };
  }, [org]);

  useInterval(() => {
    if (!loading && !_ADMIN_) {
      GetUserProfileAction(true);
    }
  }, 2000);

  // Call getOrders API every 30 minutes
  useInterval(() => {
    if (!loading && !_ADMIN_ && !opsTrainer) {
      GetOrderStatusAction();
    }
  }, 1800000);

  useInterval(() => {
    if (!loading && !_ADMIN_) {
      GetNotificationsAction();
    }
  }, 10000);

  return (
    <div className={classes.shellWrapper}>
      <LoadingSpinner loading={loading}>
        { token && (
          <>
            <div className={classes.headerWrapper}>
              <Header />
            </div>
            <div className={classes.contentWrapper}>
              {children}
            </div>

          </>
        ) }

      </LoadingSpinner>
      <ExpiredSessionModal />
    </div>
  );
};

export default compose(Shell);
