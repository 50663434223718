// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  GetGlobalStatusAsync,
  UpdateGlobalStatusAsync,
  GetOpsCreditCapacityAsync,
} from './actions';

const initialState: IGlobalStatusState = {
  status: {
    allowExpeditedProcess: false,
    dailyCreditCommitment: null,
  },
  error: null,
};

export default createImmerReducer(initialState, (handleAction) => {
  // GetGlobalStatusAsync
  handleAction(GetGlobalStatusAsync.request, (draft) => draft);
  handleAction(GetGlobalStatusAsync.success, (draft, payload) => {
    draft.status.allowExpeditedProcess = payload.allowExpeditedProcess;
    draft.status.dailyCreditCommitment = payload.dailyCreditCommitment;
    draft.error = null;
  });
  handleAction(GetGlobalStatusAsync.failure, (draft, payload) => { draft.error = payload; });

  // GetOpsCreditCapacityAsync
  handleAction(GetOpsCreditCapacityAsync.request, (draft) => draft);
  handleAction(GetOpsCreditCapacityAsync.success, (draft, payload) => {
    draft.status.dailyCreditCommitment = payload;
    draft.error = null;
  });
  handleAction(GetOpsCreditCapacityAsync.failure, (draft, payload) => { draft.error = payload; });

  // UpdateGlobalStatusAsync
  handleAction(UpdateGlobalStatusAsync.request, (draft) => draft);
  handleAction(UpdateGlobalStatusAsync.success, (draft, payload) => {
    draft.status.allowExpeditedProcess = payload.allowExpeditedProcess;
    draft.error = null;
  });
  handleAction(UpdateGlobalStatusAsync.failure, (draft, payload) => { draft.error = payload; });
});
