// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Typography, Button, CircularProgress, Fade, FormControlLabel, Checkbox } from '@material-ui/core';
import { WideLightTooltip } from 'Common/Tooltip';
import classnames from 'classnames';
import InfoIcon from '@material-ui/icons/Info';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment-timezone';
import kmlNotUploaded from 'Assets/kml-location-inactive.png';
import kmlUploaded from 'Assets/kml-location-active.png';
import ErrorIcon from '@material-ui/icons/Error';
import VendorSelectionDialog from 'Common/VendorSelectionDialog';
import ConfirmProceedDialog from 'Common/ConfirmProceedDialog';
import bbox from '@turf/bbox';
import Countdown from 'react-countdown';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');

export const ImageryRequestInfo = ({ kmlExists, imageryRequest, isEstimate, projectName, activeOrderKml, activeOrder, placedOrder, acreage, setShowCancelDialog, SetKmlBBoxAction, PlaceOrderAction, RemoveEstimateAction, nearmapCoverageLoading, nearmapCoverage, NearmapImageryCoverage, ResetImageryCoverageAction, ToggleImageryProcessing, bboxCoords }: PropsType) => {
  const classes = useStyles();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showVendorSelectorForm, setShowVendorSelectorForm] = useState(false);
  const [showConfirmProceedDialog, setShowConfirmProceedDialog] = useState(false);
  const [imageryChecked, setImageryChecked] = useState(false);

  useEffect(() => {
    if (kmlExists && !imageryRequest && activeOrderKml) {
      const kmlBBox = bbox(activeOrderKml);
      SetKmlBBoxAction(kmlBBox);
      setImageryChecked(false);
      ResetImageryCoverageAction();
    }
  }, [kmlExists, imageryRequest, activeOrderKml]);

  const determineCoverage = async () => {
    setImageryChecked(true);
    await NearmapImageryCoverage(bboxCoords);
  };

  const requestImagery = () => {
    if (isEstimate) {
      setShowConfirmProceedDialog(true);
    } else {
      setShowVendorSelectorForm(true);
      window?.pendo?.track('Request Imagery', { kml_size: acreage });
    }
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmProceedDialog(false);
    RemoveEstimateAction(activeOrder._id);
    setShowVendorSelectorForm(true);
    window?.pendo?.track('Request Imagery', { kml_size: acreage });
    e.stopPropagation();
  };

  const submitOrder = async () => {
    setIsSubmitting(true);
    await PlaceOrderAction(undefined, undefined, undefined, true);

    const now = moment(new Date());

    const notification = moment(new Date(imageryRequest.notificationSent)).add(1, 'd');

    const duration = moment.duration(notification.diff(now));

    const hours = Math.floor(duration.asHours());
    const minutes = Math.floor(duration.asMinutes()) % 60;

    window?.pendo?.track('Submit Now', {
      projection: imageryRequest.projection,
      captureDate: moment(imageryRequest.captured).format('LL'),
      timeLeft: `${hours} hours, ${minutes} minutes`,
      projectName,
      totalCredits: activeOrder.creditAcreage,
      imageryCreditCost: activeOrder.imageryCreditCost,
    });
    setIsSubmitting(false);
  };

  let requested; let uploaded; let rejected; let notificationSent;

  if (imageryRequest) {
    ({ requested, uploaded, rejected, notificationSent } = imageryRequest);
  }

  const imageryRequested = requested && !rejected && !uploaded && !notificationSent;
  const imageryNotificationSent = requested && !rejected && uploaded && notificationSent;

  const toggleImageryProcessing = async () => {
    await ToggleImageryProcessing();
  };

  let imageryRequestIcon;
  let imageryRequestTitle;
  let imageryRequestDescription;
  let imageryInfo;
  let imageryRequestAction;

  if (imageryRequested && !isEstimate && !placedOrder) {
    imageryRequestIcon = <ErrorIcon className={classes.imageryRequestIcon} fontSize="small" />;
    imageryRequestTitle = 'Imagery Requested';
    imageryRequestDescription = 'Make sure you go to checkout and save your estimate so we know exactly what you need.';
    imageryInfo = (
      <Typography className={classes.imageryRequestDetails}>
        <Typography>
          <b>Vendor:</b>
          {' '}
          {imageryRequest.provider}
        </Typography>
      </Typography>
    );
    // This may become an edit button
    imageryRequestAction = (
      <div className={classes.processingContainer}>
        <FormControlLabel
          label={<Typography>Process Immediately</Typography>}
          control={(
            <Checkbox
              color="primary"
              disabled={isEstimate}
              onChange={toggleImageryProcessing}
              checked={imageryRequest.processImmediately}
              classes={{
                root: classes.checkbox,
              }}
            />
          )}
        />
        <WideLightTooltip
          placement="bottom"
          interactive
          disableHoverListener={isEstimate}
          title={(
            <div>
              When start processing immediately is selected, you are forfeiting your chance to review the available imagery for your area of interest before placing your order. If you want to review the imagery before kicking off your project, unselect this option.
            </div>
          )}
        >
          <InfoIcon fontSize="small" />
        </WideLightTooltip>
      </div>
    );
  } else if (imageryRequested && isEstimate && activeOrder.orderStatus === 0) {
    imageryRequestIcon = <InfoIcon className={classes.imageryRequestIconUpdate} fontSize="small" />;
    imageryRequestTitle = 'Imagery Requested';
    imageryRequestDescription = `AirWorks has received your request for ${imageryRequest.provider} imagery. You can expect your dataset to be uploaded here in the next 24 hours.`;
    imageryInfo = <div />;
    imageryRequestAction = (
      <div className={classes.imageryRequestActionContainer}>
        {imageryRequest.processImmediately ? <Typography>Processing Immediately</Typography> : <div />}
        <Button className={classes.imageryButton && classes.imageryProcessingButton} onClick={() => setShowCancelDialog(true)}>
          Cancel
        </Button>
      </div>
    );
  } else if (imageryNotificationSent && !placedOrder) {
    imageryRequestIcon = <InfoIcon className={classes.imageryRequestIconUpdate} fontSize="small" />;
    imageryRequestTitle = 'Order Placed!';
    const delivery = moment.utc(notificationSent).local().add(1, 'd').format('YYYY-MM-DD HH:mm:ss');
    imageryRequestDescription = "Your Project's order will auto-submit in:";
    imageryInfo = (
      <div className={classes.imageryInfoContent}>
        <AccessTimeIcon className={classes.imageryRequestIconUpdate} fontSize="small" />
        <Typography>
          <Countdown
            date={delivery}
            renderer={(props) => (
              <span className={classes.timer}>
                {props.hours}
                :
                {props.minutes}
                :
                {props.seconds}
              </span>
            )}
          />
        </Typography>
      </div>
    );
    imageryRequestAction = (
      <>
        <Button className={classes.imageryButton} onClick={() => setShowCancelDialog(true)}>
          Cancel
        </Button>
        <Button onClick={submitOrder} className={classes.submitButton} disabled={isSubmitting}>
          <Fade in={isSubmitting}>
            <CircularProgress size={24} className={classes.buttonProgress} />
          </Fade>
          <Fade in={!isSubmitting}>
            <span>Submit Now</span>
          </Fade>
        </Button>

      </>
    );
  } else if (imageryNotificationSent && placedOrder) {
    imageryRequestIcon = <InfoIcon className={classes.imageryRequestIconUpdate} fontSize="small" />;
    imageryRequestTitle = 'Order Placed!';

    imageryRequestDescription = 'Your order was placed on';
    imageryInfo = (
      <div className={classes.imageryInfoContent}>
        <AccessTimeIcon className={classes.imageryRequestIconUpdate} fontSize="small" />
        <Typography>
          {moment.utc(placedOrder.orderDate).local().format('MMMM D, YYYY')}
        </Typography>
      </div>
    );
  }

  const checkImageryButton = () => {
    if (!imageryChecked && !nearmapCoverage && !nearmapCoverageLoading) {
      return (
        <Button className={classes.requestImageryButton} variant="text" color="primary" onClick={determineCoverage}>
          Check Imagery Availability!
        </Button>
      );
    } if (imageryChecked && !nearmapCoverage && nearmapCoverageLoading) {
      return (
        <div className={classes.imageryCoverageLoading}>
          <CircularProgress size={15} />
          <Typography className={classes.imageryCoverageLoadingText}>Checking...</Typography>
        </div>
      );
    } if (imageryChecked && nearmapCoverage && !nearmapCoverageLoading) {
      return (
        <Button className={classes.requestImageryButton} variant="text" color="primary" onClick={requestImagery}>
          Request Imagery!
        </Button>
      );
    } if (imageryChecked && !nearmapCoverage && !nearmapCoverageLoading) {
      return (
        <Typography className={classes.uploadYourOwnData}> Please upload your own data.</Typography>
      );
    }
  };

  return (
    <>
      {!kmlExists && !placedOrder ? (
        <div className={classes.kmlUploadInfoGrid}>
          <img className={classes.kmlUploadIcon} src={kmlNotUploaded} alt="no kml uploaded" />
          <Typography className={classes.kmlUploadText}>
            Draw or Upload your KML to request imagery from the AirWorks Data Marketplace!
          </Typography>
        </div>
      ) : (
        <>
          {
            (imageryRequest && !imageryRequest.rejected) ? (
              <>
                <div className={classnames(classes.imageryRequestCard, { [classes.imageryRequestBarUpdate]: isEstimate })}>
                  <div>
                    <Typography className={classes.imageryRequestTitle}>
                      {imageryRequestIcon}
                      {imageryRequestTitle}
                    </Typography>
                    <Typography className={classes.imageryRequestDetails}>
                      {imageryRequestDescription}
                    </Typography>
                    <div className={classes.imageryBottomContent}>
                      {imageryInfo}
                      {imageryRequestAction}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className={classes.kmlUploadInfoGrid}>
                  <img className={classes.kmlUploadIcon} src={kmlUploaded} alt="kml uploaded" />
                  <div>
                    <Typography className={classes.kmlUploadText}>
                      {((imageryChecked && nearmapCoverage) || !imageryChecked) ? <a href="https://awkb.scrollhelp.site/airworksknowledgebase/the-airworks-data-marketplace" className={classes.anchorTag} rel="noreferrer" target="_blank">Need data for this site?</a> : 'Imagery is not available'}
                    </Typography>
                    {checkImageryButton()}
                  </div>
                </div>
              </>
            )
          }
        </>
      )}
      <ConfirmProceedDialog
        title="Making an Imagery Request will remove your saved estimate and require you to click 'Save Estimate' again after selecting your Projection"
        contentText="Are you sure you want to proceed?"
        showDialog={showConfirmProceedDialog}
        cancelButtonText="CANCEL"
        okButtonText="PROCEED"
        onOk={onOk}
        onCancel={() => setShowConfirmProceedDialog(false)}
      />
      <VendorSelectionDialog showDialog={showVendorSelectorForm} setShowDialog={setShowVendorSelectorForm} />
    </>
  );
};

export default compose(ImageryRequestInfo);
