// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import moment from 'moment-business-days';
import { Dialog, DialogActions, Button, Typography } from '@material-ui/core/';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Moment } from 'moment';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const KickoffDatePicker = ({ orderId, showDialog, setShowDialog, EditOrderAction }: PropsType) => {
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date: Moment) => {
    setSelectedDate(date);
  };

  const onCancel = () => {
    setShowDialog(false);
  };

  const onSave = async () => {
    const anticipatedKickoffDate = validateDate();
    if (anticipatedKickoffDate === 'Invalid date') return;
    await EditOrderAction(orderId, null, null, null, null, null, new Date(anticipatedKickoffDate));
    setShowDialog(false);
  };

  const validateDate = () => {
    if (selectedDate) {
      const anticipatedKickoffDate = selectedDate.format('YYYY-MM-DD');
      const today = moment(new Date()).format('YYYY-MM-DD');
      if (moment(today).isAfter(moment(anticipatedKickoffDate))) return 'Invalid date';
      return anticipatedKickoffDate;
    }
    return undefined;
  };

  return (
    <Dialog open={showDialog} onClose={onCancel}>
      <div className={classes.container}>
        <Typography variant="h2">Save Estimate</Typography>
        <Typography variant="body1" className={classes.text}>We recommend adding an anticipated kickoff date for this estimate.</Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            allowKeyboardControl={false}
            variant="inline"
            format="MM/DD/yyyy"
            value={selectedDate}
            onChange={handleDateChange}
            className={classes.datePicker}
            label="Anticipated Kickoff"
            placeholder="Anticipated Kickoff"
            disablePast
            disableToolbar
            minDateMessage="Date should not be past date"
            PopoverProps={{
              classes: {
                root: classes.calender,
              },
            }}
          />
        </MuiPickersUtilsProvider>
        <DialogActions className={classes.buttons}>
          <Button onClick={onCancel} className={classes.dismissButton}>DISMISS</Button>
          <Button variant="contained" color="primary" className={classes.saveButton} onClick={onSave}>SAVE</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default compose(KickoffDatePicker);
