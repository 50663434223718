// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContentText, Typography, Button, Input, TextField } from '@material-ui/core/';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const ConfirmAmountDialog = ({ showDialog, onOk, onCancel, confirmAmount, orderId, orgId, projectId, AdjustOrderCreditsAction }: PropsType) => {
  const classes = useStyles();
  const [adjustment, setAdjustment] = useState(confirmAmount);
  const [reason, setReason] = useState(null);

  const confirm = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onOk(e);
    AdjustOrderCreditsAction(orderId, orgId, projectId, adjustment, reason);
    setReason(null);
  };

  const confirmCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    onCancel(e);
    setReason(null);
  };

  const validateAmount = (input: number) => {
    if (input < (confirmAmount * -1)) {
      setAdjustment(confirmAmount * -1);
    } else {
      setAdjustment(input);
    }
  };

  return (
    <Dialog
      open={showDialog}
      onClose={confirmCancel}
    >
      <Typography className={classes.title} variant="h3">Adjust Order</Typography>
      <Typography className={classes.subTitle} variant="h6">Please enter the number of credits this order should be adjusted by:</Typography>
      <div className={classes.infoWrapper}>
        <div className={classes.inputWrapper}>
          <Input
            required
            type="number"
            className={classes.input}
            defaultValue={confirmAmount}
            inputProps={{ min: (confirmAmount * -1) }}
            onChange={(e) => validateAmount(+(e.target.value))}
          />
          <DialogContentText>Credits</DialogContentText>
        </div>
        <TextField
          required
          id="outlined-required"
          label="Adjustment Reason"
          onChange={(e) => setReason(e.target.value)}
        />
      </div>
      <DialogActions>
        <Button onClick={confirmCancel}>Cancel</Button>
        <Button disabled={!adjustment || !reason} onClick={confirm}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(ConfirmAmountDialog);
