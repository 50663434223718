// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';

const getNotificationList = (state: IState) => state.notification.list;
const getOrdersList = (state: IState) => state.order.list;

const getNotificationsByProject = createSelector(
  [getNotificationList, getOrdersList],
  (notifications, orders) => {
    const result: { [projectId: string]: boolean } = {};

    notifications
      .filter((n) => !n.viewed)
      .forEach((n) => {
        const order = orders.find((o) => o._id === n.order);
        if (!order) return;

        result[order.ownerProject] = true;
      });

    return result;
  },
);

export { getNotificationsByProject };
