// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CreateOrg } from 'State/admin/thunk';

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    CreateOrgAction: CreateOrg,
  }, dispatch);

const mapStateToProps = (state: IState) => ({
  createOrgSuccess: state.admin.createOrgSuccess,
});

export type PropsType =
  ReturnType<typeof mapDispatchToProps> &
  ReturnType<typeof mapStateToProps>;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
