// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Divider, Typography, Button } from '@material-ui/core';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const AccountOpsSettings = ({ allowExpeditedProcess, dailyCreditCommitment, GetGlobalStatusAction, GetOpsCreditCapacityAction, UpdateGlobalStatusAction }: PropsType) => {
  const classes = useStyles();
  const [allowExpedited, setAllowExpedited] = useState(false);

  useEffect(() => {
    const loadData = async () => {
      await GetOpsCreditCapacityAction();
      await GetGlobalStatusAction();
    };
    loadData();
  }, []);

  useEffect(() => {
    setAllowExpedited(allowExpeditedProcess);
  }, [allowExpeditedProcess]);

  const setAllowExpeditedProcess = async (value: boolean) => {
    setAllowExpedited(value);
    await UpdateGlobalStatusAction(value);
  };

  return (
    <div className={classes.pageWrapper}>
      <Typography variant="h2">Operations Settings</Typography>
      <Divider className={classes.divider} />
      <div className={classes.settingWrapper}>
        <Typography variant="h4">Allow Expedited Processing</Typography>
        <div>
          <Button className={allowExpedited && classes.activeButton} onClick={() => setAllowExpeditedProcess(true)}>Yes</Button>
          {' '}
          /
          {' '}
          <Button className={!allowExpedited && classes.activeButton} onClick={() => setAllowExpeditedProcess(false)}>No</Button>
        </div>
      </div>
      {dailyCreditCommitment && (
        <div>
          <Divider className={classes.divider} />
          <div className={classes.settingWrapper}>
            <Typography variant="h4">Ops Credit Capacity</Typography>
            <div>
              <Typography variant="h6">
                {dailyCreditCommitment}
                {' '}
                credits per day
              </Typography>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default compose(AccountOpsSettings);
