// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  form: {
    margin: '10px 40px',
    width: 'inherit',
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '20px',
  },
  formRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    display: 'grid',
    gridGap: theme.spacing(),
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
  messageText: {
    color: theme.palette.error.main,
    fontWeight: 400,
    marginTop: '2px',
    fontSize: '14px',
  },
  submitButton: {
    boxShadow: 'none',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    marginBottom: 'auto',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  addTeamButton: {
    padding: 0,
    justifyContent: 'flex-end',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  outline: {
    borderRadius: 0,
    borderColor: 'rgba(0,0,0,0.1) !important',
    boxShadow: theme.lightShadow,
  },
  input: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabel: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabelShrink: {
    transform: 'translate(14px, -7px) scale(0.75) !important',
  },
  heading: {
    marginBottom: '12px',
  },
  addTeamText: {
    color: 'white',
  },
  sectionTitle: {
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
  },
  title: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },
});

export default styles;
