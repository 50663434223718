// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Dialog, Typography, Button, Collapse } from '@material-ui/core';
import logo from 'Assets/logo_small.png';
import { Form } from 'react-final-form';
import { TextField, Validators, Helpers } from 'Common/forms';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { darkTheme } from 'Utils/themes';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const resetPassword =
  ({ match, VerifyTokenAction, ResetPasswordAction }: PropsType) => {
    const classes = useStyles();
    const onLoad = async () => {
      const { token } = match.params;
      await VerifyTokenAction(token);
    };

    useEffect(() => {
      onLoad();
    });

    const onFormSubmit = (values: any) => {
      Helpers.submit(values, ResetPasswordAction);
    };

    return (
      <MuiThemeProvider theme={darkTheme}>
        <Dialog
          fullScreen
          open
          keepMounted={false}
          classes={{
            paper: classes.dialogPaper,
          }}
        >
          <div className={classes.dialog}>
            <img src={logo} alt="AirWorks small logo" />
            <Typography className={classes.header} variant="h1">
              Reset your Password
            </Typography>
            <Typography className={classes.details}>
              Choose a new password that meets password requirements
            </Typography>
            <div className={classes.requirements}>
              <Typography className={classes.rheader} variant="h5">
                Your password must contain:
              </Typography>
              <Typography className={classes.rdetails}>A minimum of 8 characters</Typography>
              <Typography className={classes.rdetails}>A number</Typography>
              <Typography className={classes.rdetails}>A lowercase letter and an uppercase letter</Typography>
              <Typography className={classes.rdetails}>
                A special character: ~`!@#$%^&*()_-+=
                {'{'}
                [
                {'}'}
                ]|\:;
                &quot;&apos;
                {'<'}
                ,
                {'>'}
                .?/
              </Typography>
            </div>
            <Form
              onSubmit={onFormSubmit}
              render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, submitError, handleSubmit }) => (
                <form
                  className={classes.form}
                  onSubmit={handleSubmit}
                >
                  <TextField
                    name="password"
                    label="New Password"
                    type="password"
                    className={classes.textField}
                    validate={Validators.composeValidators(Validators.required, Validators.strongPassword)}
                  />
                  <TextField
                    name="passwordConfirm"
                    label="Confirm Password"
                    type="password"
                    className={classes.textField}
                    validate={Validators.composeValidators(Validators.required, Validators.strongPassword, Validators.passwordsMatch)}
                  />
                  <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                    <Typography variant="body1" className={classes.errorMessage}>
                      {submitError}
                    </Typography>
                  </Collapse>
                  <div className={classes.buttonWrapper}>
                    <Button
                      type="submit"
                      variant="outlined"
                      className={classes.button}
                      disabled={(hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
                    >
                      Reset Password
                    </Button>
                  </div>
                </form>
              )}
            />
          </div>
        </Dialog>
      </MuiThemeProvider>
    );
  };

export default compose(resetPassword);
