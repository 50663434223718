// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  GetProjectsAsync, GetProjectAsync, CreateProjectAsync,
  DeleteProjectAsync, SetKeywordFilterAction, SetSortFilterAction,
  SetProjectIdAction, UpdateProjectNameAsync, UpdateProjectAction,
  DeleteTifFileAsync, ChangeJsonAction,
  SetThumbnailAsync, SetWarningAcceptedAction,
  SetFileStatusJsonAction,
  DeleteLasFileAsync,
  GetEpsgProjectionsAction,
  UpdateProjectTifFilesAction,
  UpdateProjectLasFilesAction,
  SetProjectsAction,
  SetLoadAllAction,
  SetPageAction,
  SetLoadNewAction,
  ResetEpsgProjectionsAction,
  SetOrderFilterAction,
  SetShowKmlToolbarAction,
  UpdateImageryRequestAsync,
  SendImageryUploadEmailAsync,
  CreateImageryRequestAsync,
  SetImageryVendorSelectedAction,
  SetProjectionSelectedAction,
  SetAvailableProjections,
  ToggleImageryProcessingAction,
} from './actions';

const initialState: IProjectState = {
  loading: false,
  emailLoading: false,
  projectsLoading: false,
  createImageryLoading: false,
  projectsCount: 0,
  loadAll: false,
  loadNew: true,
  page: 0,
  projectId: null,
  project: null,
  list: [],
  keywordFilter: '',
  sortFilter: 'updatedAtD',
  orderSortFilter: 'updatedAtD',
  fileStatusJson: null,
  epsgCode: null,
  epsgProjections: null,
  epsgAuthName: null,
  firstProjection: null,
  secondProjection: null,
  changeJson: false,
  warningAccepted: [],
  showKmlToolBar: false,
  imageryVendorSelected: null,
  imageryProcessing: true,
  projectionSelected: null,
  availableProjections: [],
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(GetProjectsAsync.request, (draft) => {
    draft.projectsLoading = true;
  });
  handleAction(GetProjectsAsync.success, (draft, payload) => {
    draft.projectsLoading = false;
    draft.list = [...draft.list, ...payload.list];
    draft.projectsCount = payload.totalCount;
  });
  handleAction(GetProjectsAsync.failure, (draft) => {
    draft.projectsLoading = false;
  });
  handleAction(SetProjectsAction, (draft, payload) => {
    draft.list = payload;
  });
  handleAction(SetLoadAllAction, (draft, payload) => {
    draft.projectsLoading = false;
    draft.loadAll = payload;
  });
  handleAction(SetLoadNewAction, (draft, payload) => {
    draft.loadNew = payload;
  });
  handleAction(SetPageAction, (draft, payload) => {
    draft.page = payload;
  });

  handleAction(GetProjectAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetProjectAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.project = payload;
  });
  handleAction(GetProjectAsync.failure, (draft) => { draft.loading = false; });

  handleAction(SetProjectIdAction, (draft, payload) => {
    draft.projectId = payload;
  });

  handleAction(ChangeJsonAction, (draft) => { draft.changeJson = !draft.changeJson; });

  handleAction(SetFileStatusJsonAction, (draft, payload) => {
    draft.fileStatusJson = payload;
  });

  handleAction(SetWarningAcceptedAction, (draft) => {
    if (!draft.warningAccepted.includes(draft.projectId)) {
      draft.warningAccepted.push(draft.projectId);
    }
  });

  handleAction(SetThumbnailAsync.success, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload.projectId);
    draft.list[index].thumbnail = payload.thumbnail;
  });

  handleAction(CreateProjectAsync.request, (draft) => { draft.loading = true; });
  handleAction(CreateProjectAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.projectId = payload._id;
    if (_ADMIN_) draft.list.unshift(payload);
  });
  handleAction(CreateProjectAsync.failure, (draft) => { draft.loading = false; });

  handleAction(DeleteProjectAsync.request, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload);
    draft.list[index].deleting = true;
  });
  handleAction(DeleteProjectAsync.success, (draft, payload) => { draft.list = draft.list.filter((p) => p._id !== payload); });
  handleAction(DeleteProjectAsync.failure, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload);
    draft.list[index].deleting = false;
  });

  handleAction(UpdateProjectNameAsync.success, (draft, payload) => {
    const project = draft.list.find((p) => p._id === payload._id);
    /* eslint-disable no-unused-expressions */
    payload.opsTrainer
      ? (project.training.nameEditable = payload.training.nameEditable)
      : (project.name = payload.name);
  });

  handleAction(UpdateProjectAction, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload._id);
    draft.list[index] = payload;
  });

  handleAction(UpdateProjectTifFilesAction, (draft, payload) => {
    const projectInList = draft.list.find((p) => p._id === payload.ownerProject);
    if (projectInList) {
      const index = projectInList.tifFiles.findIndex((t) => t._id === payload._id);
      if (index < 0) {
        projectInList.tifFiles.push(payload);
      } else {
        projectInList.tifFiles[index] = payload;
      }
    }
    const { project } = draft;
    if (project._id === payload.ownerProject) {
      const index = project.tifFiles.findIndex((t) => t._id === payload._id);
      if (index < 0) {
        project.tifFiles.push(payload);
      } else {
        project.tifFiles[index] = payload;
      }
    }
  });

  handleAction(UpdateProjectLasFilesAction, (draft, payload) => {
    const projectInList = draft.list.find((p) => p._id === payload.ownerProject);
    if (projectInList) {
      const index = projectInList.lasFiles.findIndex((t) => t._id === payload._id);
      if (index < 0) {
        projectInList.lasFiles.push(payload);
      } else {
        projectInList.lasFiles[index] = payload;
      }
    }
    const { project } = draft;
    if (project._id === payload.ownerProject) {
      const index = project.lasFiles.findIndex((t) => t._id === payload._id);
      if (index < 0) {
        project.lasFiles.push(payload);
      } else {
        project.lasFiles[index] = payload;
      }
    }
  });

  handleAction(SetKeywordFilterAction, (draft, payload) => { draft.keywordFilter = payload; });
  handleAction(SetSortFilterAction, (draft, payload) => { draft.sortFilter = payload; });
  handleAction(SetOrderFilterAction, (draft, payload) => { draft.orderSortFilter = payload; });

  handleAction(DeleteTifFileAsync.success, (draft, payload) => {
    const projectInList = draft.list.find((p) => p._id === payload.projectId);
    if (projectInList) {
      projectInList.tifFiles = projectInList.tifFiles.filter((t) => t._id !== payload.tifFileId);
    }
    const { project } = draft;
    if (project._id === payload.projectId) {
      project.tifFiles = project.tifFiles.filter((t) => t._id !== payload.tifFileId);
    }
  });

  handleAction(DeleteLasFileAsync.success, (draft, payload) => {
    const projectInList = draft.list.find((p) => p._id === payload.projectId);
    if (projectInList) {
      projectInList.lasFiles = projectInList.lasFiles.filter((t) => t._id !== payload.lasFileId);
    }
    const { project } = draft;
    if (project._id === payload.projectId) {
      project.lasFiles = project.lasFiles.filter((t) => t._id !== payload.lasFileId);
    }
  });

  handleAction(GetEpsgProjectionsAction, (draft, payload) => {
    draft.epsgCode = payload.epsgCode;
    draft.epsgProjections = payload.projectionData;
    draft.epsgAuthName = payload.epsgAuthName;
    draft.firstProjection = payload.proj4Data.first;
    draft.secondProjection = payload.proj4Data.second;
  });
  handleAction(ResetEpsgProjectionsAction, (draft) => {
    draft.epsgCode = null;
    draft.epsgProjections = null;
    draft.epsgAuthName = null;
    draft.firstProjection = null;
    draft.secondProjection = null;
  });

  handleAction(SetShowKmlToolbarAction, (draft, payload) => {
    draft.showKmlToolBar = payload;
  });
  handleAction(UpdateImageryRequestAsync.success, (draft, payload) => {
    const projectInList = draft.list.find((p) => p._id === payload.ownerProject);
    if (projectInList) {
      Object.assign(projectInList.imageryRequest, payload);
    }
    const { project } = draft;
    if (project._id === payload.ownerProject) {
      Object.assign(project.imageryRequest, payload);
    }
  });

  handleAction(SendImageryUploadEmailAsync.request, (draft) => { draft.emailLoading = true; });

  handleAction(SendImageryUploadEmailAsync.success, (draft, payload) => {
    const projectInList = draft.list.find((p) => p._id === payload.ownerProject);
    if (projectInList) {
      Object.assign(projectInList.imageryRequest, payload);
    }
    const { project } = draft;
    if (project._id === payload.ownerProject) {
      Object.assign(project.imageryRequest, payload);
    }
    draft.emailLoading = false;
  });
  handleAction(SendImageryUploadEmailAsync.failure, (draft) => { draft.emailLoading = false; });

  handleAction(CreateImageryRequestAsync.request, (draft) => { draft.createImageryLoading = true; });

  handleAction(CreateImageryRequestAsync.success, (draft, payload) => {
    const projectInList = draft.list.find((p) => p._id === payload.ownerProject);
    if (projectInList) {
      projectInList.imageryRequest = payload;
    }
    const { project } = draft;
    if (project._id === payload.ownerProject) {
      projectInList.imageryRequest = payload;
    }

    draft.createImageryLoading = false;
    draft.projectionSelected = null;
    draft.imageryProcessing = true;
  });

  handleAction(CreateImageryRequestAsync.failure, (draft) => {
    draft.createImageryLoading = false;
    draft.projectionSelected = null;
    draft.imageryProcessing = true;
  });

  handleAction(ToggleImageryProcessingAction, (draft) => { draft.imageryProcessing = !draft.imageryProcessing; });
  handleAction(SetImageryVendorSelectedAction, (draft, payload) => { draft.imageryVendorSelected = payload; });
  handleAction(SetProjectionSelectedAction, (draft, payload) => { draft.projectionSelected = payload; });
  handleAction(SetAvailableProjections, (draft, payload) => { draft.availableProjections = payload; });
});
