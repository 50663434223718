// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  layerColor: {
    height: '20px',
    width: '36px',
    border: '1px solid #000000',
  },
  layerColorInput: {
    height: '20px',
    width: '60px',
    border: '1px solid #000000',
    marginRight: '11px',
  },
  typography: {
    paddingLeft: theme.spacing(1),
  },
  select: {
    padding: '0px 21px 0px 0px',
  },
  lineStylePNG: {
    height: '1px',
    marginTop: theme.spacing(1),
  },
  selectWeight: {
    padding: '0px 21px 0px 0px',
    display: 'flex',
  },
  horizontalLine: {
    backgroundColor: '#000000',
    width: '30%',
    marginRight: '10px',
    marginLeft: '0px',
  },
  popover: {
    '& .MuiPaper-root': {
      height: '180px',
      width: '250px',
      paddingTop: '8px',
    },
  },
  borderStyle: {
    border: '1px solid #000000',
    margin: theme.spacing(1),
    width: '233px',
    height: '50px',
    cursor: 'pointer',
  },
  compactPicker: {
    '& div': {
      zIndex: 1,
    },
  },
  tableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    width: '22%',
    fontSize: '1em',
  },
  colorInput: {
    display: 'flex',
  },
}));

export default useStyles;
