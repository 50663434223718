// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { CreateImageryRequest } from 'State/project/thunk';
import { SetImageryVendorSelectedAction, SetProjectionSelectedAction, ToggleImageryProcessingAction } from 'State/project/actions';
import { GetAvailableProjections } from 'State/kml/thunk';

const mapStateToProps = (state: IState) => ({
  showLoader: state.project.createImageryLoading,
  bbox: state.kml.present.bbox,
  availableProjections: state.project.availableProjections,
  projection: state.project.projectionSelected,
  imageryProcessing: state.project.imageryProcessing,
});

const mapDispatchToProps = {
  CreateImageryRequestAction: CreateImageryRequest,
  SetImageryVendorSelectedAction,
  SetProjectionSelectedAction,
  ToggleImageryProcessingAction,
  GetAvailableProjectionsAction: GetAvailableProjections,
};

interface IOwnProps {
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
