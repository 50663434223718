// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  header: {
    marginTop: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  errorMessage: {
    minHeight: '3em',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
  buttonWrapper: {
    marginTop: theme.spacing(4),
    position: 'relative',
    display: 'inline-block',
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  buttonOkta: {
    marginTop: theme.spacing(10),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    border: '1px solid #ffffff',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  forgotText: {
    color: '#01a1dd',
  },
  captcha: {
    marginTop: theme.spacing(2),
  },
});

export default styles;
