// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createTheme } from '@material-ui/core/styles';
import { TextTransformProperty } from 'csstype'; // eslint-disable-line import/no-unresolved

const navy = '#1c364b';
const lightOrangeColor = '#faa738';
const darkGrey = '#4f5a64';
const lightGrey = '#abb6c1';
const lightShadow = '0px 2px 12px 0px rgba(0,0,0,0.1)';
const sidebarWidth = 350;
const lightBlue = '#01a1dd';

const blueColor = '#2E4DEB';
const cyanColor = '#56E4F9';

const typography = {
  fontFamily: [
    '"Work Sans"',
    '"Roboto"',
    '"Helvetica"',
    '"Arial"',
    'sans-serif',
  ].join(','),
  h1: {
    fontSize: '30px',
    fontWeight: 300,
    letterSpacing: '1.3px',
    lineHeight: '35px',
  },
  h2: {
    fontSize: 20,
    fontWeight: 600,
    letterSpacing: '.86px',
    lineHeight: '24px',
  },
  h3: {
    fontSize: 18,
    fontWeight: 400,
    letterSpacing: '.75px',
    lineHeight: '24px',
  },
  h4: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '.2px',
    lineHeight: '19px',
    textTransform: 'uppercase' as TextTransformProperty,
    color: darkGrey,
  },
  h5: {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '.7px',
    lineHeight: '19px',
  },
  h6: {
    fontSize: '14px',
    fontWeight: 500,
    letterSpacing: '.6px',
    lineHeight: '18px',
    textTransform: 'uppercase' as TextTransformProperty,
  },
  body1: {
    fontWeight: 300,
    color: darkGrey,
    letterSpacing: 'normal',
  },
  body2: {
    fontWeight: 300,
    color: '#000',
    fontSize: '12px',
    lineHeight: 1.3,
    letterSpacing: 'normal',
  },
};

const lightTheme = createTheme({
  palette: {
    primary: {
      main: blueColor,
    },
    secondary: {
      main: cyanColor,
      contrastText: '#FFFFFF',
    },
    text: {
      primary: navy,
    },
    lightGrey,
    darkGrey,
  },
  typography,
  lightShadow,
  sidebarWidth,
  overrides: {
    MuiButton: {
      contained: {
        borderRadius: 0,
        boxShadow: 'none',
      },
      outlined: {
        borderRadius: 0,
        borderColor: navy,
      },
    },
    MuiPaper: {
      elevation1: {
        boxShadow: lightShadow,
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: darkGrey,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 400,
      },
    },
    MUIDataTable: {
      responsiveStacked: {
        overflowX: 'inherit',
        overflowY: 'inherit',
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '1em',
      },
    },
  },
});

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#FFFFFF',
    },
    secondary: {
      main: lightBlue,
    },
    background: {
      default: '#2d2d2d',
      paper: navy,
    },
  },
  typography,
  overrides: {
    MuiPaper: {
      elevation1: {
        boxShadow: lightShadow,
      },
    },
    MuiFormLabel: {
      root: {
        fontWeight: 400,
      },
    },
  },
});

const InputBaseWidth = createTheme({
  overrides: {
    MuiInput: {
      root: {
        width: '100%',
        zIndex: 0,
      },
    },

  },
});

export { lightTheme, darkTheme, InputBaseWidth };
