// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Dialog, Slide, IconButton, LinearProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import MultipleFileUploadForm from 'Common/FileUploadForm/MultipleFileUploadForm';
import CreateProjectForm from './CreateProjectForm';
import compose, { PropsType } from './container';
import useStyles from './styles';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

export const CreateProjectDialog = ({ showDialog, setShowDialog, showLoader, history, projectId, SetProjectIdAction, getProjectUrl }: PropsType) => {
  const classes = useStyles();

  const [showFileUpload, setShowFileUpload] = useState(false);
  useEffect(() => {
    if (showDialog) {
      setShowFileUpload(false);
    }
  }, [showDialog]);

  const createProjectClick = (
    <CreateProjectForm
      onCreate={(project) => {
        history.push(getProjectUrl(project._id));
        setShowDialog(false);
      }}
      onUpload={(project) => {
        SetProjectIdAction(project._id);
        setShowFileUpload(true);
      }}
      onCreateEstimate={(project) => {
        SetProjectIdAction(project._id);
        history.push(getProjectUrl(project._id));
        setShowDialog(false);
      }}
    />
  );

  const fileUploadClick = (
    <MultipleFileUploadForm
      onSuccess={() => {
        history.push(getProjectUrl(projectId));
        setShowDialog(false);
      }}
    />
  );

  return (
    <>
      <Dialog
        fullScreen
        open={showDialog}
        onClose={() => setShowDialog(false)}
        TransitionComponent={Transition}
        keepMounted={false}
      >
        {showLoader && (
          <>
            <LinearProgress className={classes.progress} />
            <div className={classes.backdrop} />
          </>
        )}
        <div className={classes.closeButtonWrapper}>
          <IconButton
            color="inherit"
            onClick={() => {
              // TODO 5/18: Fix page crash due to polygon check in Map/MapControls/container
              // if (showFileUpload) {
              //   history.push(getProjectUrl(projectId));
              // }
              setShowDialog(false)
            }}
            aria-label="Close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <div className={classes.contentWrapper}>
          {
            !showFileUpload ? createProjectClick : fileUploadClick
          }
        </div>
      </Dialog>
    </>
  );
};

export default compose(CreateProjectDialog);
