// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  pageTitle: {
    textAlign: 'center',
    margin: '50px auto',
    width: 600,
  },
  form: {
    marginBottom: theme.spacing(5),
  },
  formText: {
    margin: '30px auto',
    textAlign: 'center',
    width: '60%',
  },
  outline: {
    boxShadow: '0px 2px 5px 0px rgba(0,0,0,0.1)',
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '14px',
    width: '100%',
    '& fieldset': {
      borderRadius: 0,
      borderColor: 'rgba(0,0,0,0.1)',
    },
  },
  dropzone: {
    display: 'grid',
    gridTemplateColumns: '1fr 4fr',
    alignItems: 'center',
  },
  dropzoneText: {
    marginLeft: theme.spacing(2),
  },
  uploadForm: {
    margin: '0 auto',
    width: '60%',
  },
  openFileDialogLink: {
    color: theme.palette.primary.main,
    background: 'none!important',
    border: 'none',
    padding: '0!important',
    font: 'inherit',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  uploadFormText: {
    display: 'inline',
  },
  uploadFormIcon: {
    marginBottom: theme.spacing(2),
  },
  input: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabel: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabelShrink: {
    transform: 'translate(14px, -7px) scale(0.75) !important',
  },
  helperText: {
    fontWeight: 300,
    letterSpacing: '1.3px',
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  button: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    transition: theme.transitions.create('box-shadow'),
    '&:hover': {
      boxShadow: theme.lightShadow,
    },
  },
  icon: {
    display: 'block',
    margin: '0 auto',
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    zIndex: 3000,
    opacity: 0.5,
  },
  closeButtonWrapper: {
    position: 'absolute',
    top: 50,
    right: 50,
    zIndex: 2000,
  },
  progressSection: {
    display: 'flex',
    alignItems: 'center',
    margin: '8px auto',
    width: '60%',
    position: 'relative',
  },
  progress: {
    flex: 1,
    width: '80%',
    margin: '10px auto',
  },
  progressText: {
    textAlign: 'start',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  fileExtension: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    margin: theme.spacing(1),
    width: 45,
    backgroundColor: theme.palette.primary.main,
  },
  fileExtensionText: {
    color: 'white',
  },
  progressButtons: {
    position: 'absolute',
    right: 0,
  },
  progressButton: {
    padding: theme.spacing(0.5),
  },
  cancelIcon: {
    fontSize: '16px',
    verticalAlign: 'middle',
  },
  bottomPaper: {
    height: '75px',
    width: '100%',
    borderRadius: '0px',
    position: 'fixed',
    bottom: '0px',
    right: '0px',
  },
  addButton: {
    position: 'absolute',
    right: '40px',
    top: '16px',
  },
  warningMessage: {
    position: 'absolute',
    right: '200px',
    top: '16px',
    textAlign: 'end',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: '#fff',
  },
});

export default styles;
