// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import styles from './styles';

const mapStateToProps = (state: IState) => ({
  marker: state.map.common.marker,
});

type PropsType =
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withStyles(styles),
  connect(mapStateToProps),
);
