// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Button, Fade, CircularProgress, SnackbarContent, Collapse, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Form } from 'react-final-form';
import { TextField, Validators, Helpers, Select } from 'Common/forms';
import states from 'Common/states';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoadingSpinner from 'Common/LoadingSpinner';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const AccountGeneralOrg = ({ initialValues, loading, UpdateOrgProfileAction, updateCompanyMessage, GetOrgProfileAction }: PropsType) => {
  const classes = useStyles();
  const [initialState, setInitialState] = useState(undefined);

  useEffect(() => {
    GetOrgProfileAction();
    if (!initialState) setInitialState(initialValues);
  }, []);

  const onFormSubmit = (values: any) => Helpers.submit(values, UpdateOrgProfileAction);
  return (
    <div>
      <Typography variant="h4">Company Info</Typography>
      <LoadingSpinner loading={loading}>
        <Form
          onSubmit={onFormSubmit}
          keepDirtyOnReinitialize
          initialValues={initialState}
          render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, submitError, submitSucceeded, handleSubmit }) => (
            <form
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <TextField
                label="Company Name"
                name="companyName"
                validate={Validators.required}
              />
              <TextField
                label="Phone Number"
                name="phoneNumber"
                validate={Validators.required}
              />
              <TextField
                label="Address 1"
                name="address.addressLine1"
                validate={Validators.required}
              />
              <TextField
                label="Address 2"
                name="address.addressLine2"
              />
              <div className={classes.formRow2}>
                <TextField
                  label="City"
                  name="address.city"
                  validate={Validators.required}
                />
                <Select
                  label="State"
                  name="address.state"
                  values={states}
                  validate={Validators.required}
                />
                <TextField
                  label="Zip Code"
                  name="address.zipCode"
                  validate={Validators.required}
                />
              </div>
              <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                <SnackbarContent
                  className={classes.errorSnackbar}
                  message={(
                    <div className={classes.snackbarContent}>
                      <ErrorIcon />
                      {submitError}
                    </div>
                  )}
                />
              </Collapse>
              <Collapse in={!submitting && submitSucceeded}>
                <SnackbarContent
                  className={classes.successSnackbar}
                  message={(
                    <div className={classes.snackbarContent}>
                      <CheckCircleIcon />
                      {updateCompanyMessage}
                    </div>
                  )}
                />
              </Collapse>
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                type="submit"
                disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} className={classes.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>Update Company Info</span>
                </Fade>
              </Button>
            </form>
          )}
        />
      </LoadingSpinner>
    </div>
  );
};

export default compose(AccountGeneralOrg);
