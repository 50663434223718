// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      display: 'grid',
      gridTemplateColumns: 'auto 1fr auto',
      alignItems: 'center',
      gridColumnGap: theme.spacing(3),
      gridRowGap: theme.spacing(3),
    },
    cardIcon: {
      width: theme.spacing(4),
    },
    buttonsWrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
    button: {
      padding: 0,
      justifyContent: 'flex-end',
      '&:hover': {
        backgroundColor: '#fff',
      },
    },
    backdrop: {
      backgroundColor: 'white',
      opacity: 0.6,
      position: 'absolute',
      width: '100%',
      height: '100%',
      top: 0,
      left: 0,
    },
    progress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    defaultButton: {
      alignSelf: 'end',
    },
  }));

export default useStyles;
