// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect, useRef} from 'react';
import DrawControl from 'react-mapbox-gl-draw';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import CustomDirectSelect from '../MapEditor/curvemode/customDirectSelectMode';
import CustomKmlSimpleSelect from './modes/customKmlSimpleSelect';
import compose from './container';
import drawToolStyles from './styles';
import ConfirmProceedDialog from 'Common/ConfirmProceedDialog';

const MapDraw = compose(({
  modeName, modeFeatureId, kmlFile, collection, imageryRequested, InitDrawAction, DrawAction, UndoAction, SetSelectionAction, SelectPolygonAction, SetFirstTimeChangeEstimateAction, SetShowBusinessDaysAction, UpdateKmlFeatureAction }) => {
  const [draw, setDraw] = useState(null);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);

  useEffect(() => {
    SetFirstTimeChangeEstimateAction(true);
  }, []);

  const syncDraw = () => {
    if (draw === null) return;
    draw.set(collection);
    if (imageryRequested) {
      draw.changeMode('simple_select', { useMode: 'kmlDraw' });
      return;
    }
    if (modeName) {
      if (modeName === 'direct_select') {
        if (!collection || collection.features.length === 0) return;
        const feature = draw.get(modeFeatureId);
        if (!feature) return;
        draw.changeMode(modeName, { featureId: modeFeatureId, useMode: 'kmlDraw' });
      } else if (modeName === 'simple_select') {
        draw.changeMode(modeName, { useMode: 'kmlDraw' });
      } else {
        draw.changeMode(modeName);
      }
    }
  };

  useEffect(() => {
    syncDraw();
  }, [draw, collection, modeName, modeFeatureId, imageryRequested]);

  useEffect(() => {
    if (!imageryRequested) InitDrawAction();
  }, [kmlFile]);

  const updateDraw = async (e: any) => {
    if (e.features[0].geometry.type === "LineString") {
      UpdateKmlFeatureAction(e.features[0]);
      return;
    }
    const { isEstimate, firstTimeChangeEstimate } = await DrawAction(e.features[0]);
    if (isEstimate && firstTimeChangeEstimate) {
      setShowConfirmDialog(true);
    }
  };

  const cancelKmlChange = () => {
    UndoAction();
    setShowConfirmDialog(false);
    SetFirstTimeChangeEstimateAction(true);
  };

  const proceedKmlChange = () => {
    setShowConfirmDialog(false);
    SetShowBusinessDaysAction(false);
  };

  return (
    <>
      <DrawControl
        userProperties
        displayControlsDefault={false}
        styles={drawToolStyles}
        modes={{
          ...MapboxDraw.modes,
          direct_select: CustomDirectSelect,
          simple_select: CustomKmlSimpleSelect,
        }}
        ref={(drawControl) => {
          if (drawControl !== null && drawControl.draw !== null) {
            setDraw(drawControl.draw);
          }
        }}
        onDrawCreate={updateDraw}
        onDrawUpdate={updateDraw}
        onDrawSelectionChange={(e) => {
          if (!(e.features.length === 0 && e.points.length === 0)) {
            SetSelectionAction(e);
          }
        }}
        onDrawModeChange={(e) => {
          if (e.mode === 'simple_select') {
            SelectPolygonAction();
          }
        }}
      />
      <ConfirmProceedDialog
        title='You have changed the project size. This modifies the saved estimate for this project.'
        contentText="Please let us know if you'd like to proceed"
        showDialog={showConfirmDialog}
        cancelButtonText='CANCEL'
        okButtonText='PROCEED'
        onOk={proceedKmlChange}
        onCancel={cancelKmlChange}
      />
    </>
  );
});

export default MapDraw;
