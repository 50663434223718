// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from "react";
import * as Sentry from "@sentry/browser";
import { Typography, Button, Dialog } from "@material-ui/core";
import ErrorIcon from '@material-ui/icons/Error'

class ErrorScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null, show: true };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);

      if (localStorage.getItem("user")) {
        try {
          const user = JSON.parse(localStorage.getItem("user"));
          scope.setUser({ email: user.email });
          /* eslint-disable brace-style */
        } catch {
          /* eslint-disable no-empty */
        }
      }

       if (_MODE_ === 'production') {
      const eventId = Sentry.captureException(error);
      this.setState({ eventId: eventId });
       }
    });
  }

  render() {
    const { hasError, eventId, show } = this.state;
    const font = [
      '"Work Sans"',
      '"Roboto"',
      '"Helvetica"',
      '"Arial"',
      "sans-serif"
    ].join(",");
    if (hasError) {
      return (
        <Dialog open={show}>
          <div
            style={{
              height: "200px",
              width: "550px",
              fontFamily: font,
              position: "relative",
              padding: "30px"
            }}
          >
            <div>
              <ErrorIcon style={{ color: "red" }} />
            </div>
            <div
              style={{
                display: "inline-block",
                fontSize: "1.3em",
                left: "64px",
                top: "29px",
                float: "right",
                position: "absolute"
              }}
            >
              {" "}
              Unexpected error{" "}
            </div>
            <br></br>
            <div style={{ fontSize: "1.1em" }}>
              We were unable to process your last request. We’d love more details on how this occurred.
            </div>
            <br />
            <button
              style={{
                color: "blue",
                fontSize: "1.2em",
                position: "absolute",
                cursor: "pointer",
                fontFamily: font,
                border: 'none',
                outline:0
              }}
              onClick={() => {
                Sentry.showReportDialog({ eventId })
                this.setState({show :false})
              }}
            >
              REPORT FEEDBACK
            </button>
          </div>
        </Dialog>
      );
    }

    const { children } = this.props;
    return children;
  }
}

export default ErrorScreen;
