// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { GetRasterTiles, GetVectorTiles, CheckHighResolutionRaster, GetLasBBox } from 'State/map/common/thunk';
import { SetDelayInRasterTileLoadAction } from 'State/map/common/actions';

const mapStateToProps = (state: IState) => {
  const { project } = state.project;
  const { allRasterTilesLoaded, lowResCreatedAt, delayInRasterTileLoadAcknowledged, rasterTileJson, vectorTileJsonLoading, rasterTileJsonLoading, lasBBox, lasBBoxLoading } = state.map.common;
  return {
    tif: project.tifFiles.length > 0,
    vectorLoading: vectorTileJsonLoading,
    rasterLoading: rasterTileJsonLoading,
    lasBBox,
    lasBBoxLoading,
    rasterTileJson,
    allRasterTilesLoaded,
    lowResCreatedAt,
    delayInRasterTileLoadAcknowledged,
  };
};

const mapDispatchToProps = {
  GetRasterTilesAction: GetRasterTiles,
  GetVectorTilesAction: GetVectorTiles,
  GetLasBBoxAction: GetLasBBox,
  CheckHighResolutionRasterAction: CheckHighResolutionRaster,
  SetDelayInRasterTileLoadAction,
};

interface IOwnProps {
  showLayers: boolean;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
