// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  progress: {
    zIndex: 3001,
    position: 'absolute',
    width: '100%',
  },
  backdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    zIndex: 3000,
    opacity: 0.5,
  },
  closeButtonWrapper: {
    position: 'absolute',
    top: 50,
    right: 50,
    zIndex: 2000,
  },
  processingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  contentWrapper: {
    margin: 'auto',
    textAlign: 'center',
    width: '60%',
  },
  vendorOption: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(2),
    border: 'solid 1px #01A1DD',
    borderRadius: '5px',
    background: '#fff',
    width: '60%',
    margin: '40px auto 8px auto',
    padding: theme.spacing(3),
    boxShadow: '0px 6px 10px 0px rgba(0,0,0,0.15)',
  },
  contentText: {
    marginTop: theme.spacing(2),
  },
  contentText2: {
    marginTop: theme.spacing(2),
    fontWeight: 400,
    fontSize: 12,
  },
  contentText3: {
    fontWeight: 400,
    fontSize: 14,
  },
  continueButton: {
    marginTop: theme.spacing(3),
    paddingLeft: theme.spacing(11),
    paddingRight: theme.spacing(11),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  vendorLogo: {
    height: theme.spacing(5),
  },
  vendorName: {
    fontWeight: 400,
  },
  vendorDescription: {
    marginTop: 0,
    fontWeight: 400,
    fontSize: 12,
  },
  vendorText: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
  },
  verticalDivider: {
    margin: '0 10px',
  },
  formControl: {
    width: '100%',
    margin: '40px 0',
    borderRadius: 0,
    borderColor: 'rgba(0,0,0,0.1)',
    boxShadow: theme.lightShadow,
    maxWidth: '1020px',
  },
  placeholder: {
    float: 'left',
    fontStyle: 'normal',
    color: theme.palette.lightGrey,
    fontSize: '20px',
  },
  select: {
    '& .MuiSelect-selectMenu': {
      display: 'flex',
      alignItems: 'center',
      height: '40px',
      paddingLeft: '10px',
      fontSize: '18px',
    },
  },
  anchorTag: {
    color: '#01A1DD',
  },
}));

export default useStyles;
