// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAsyncAction, createAction } from 'typesafe-actions';

export const CreateCustomerAsync = createAsyncAction(
  'CREATE_CUSTOMER_START',
  'CREATE_CUSTOMER_SUCCESS',
  'CREATE_CUSTOMER_FAILED',
)<null, null, null>();

export const GetCreditCardsAsync = createAsyncAction(
  'GET_CREDIT_CARDS_START',
  'GET_CREDIT_CARDS_SUCCESS',
  'GET_CREDIT_CARDS_FAILED',
)<null, ICreditCard[], string>();

export const CreatePaymentInstrumentAsync = createAsyncAction(
  'CREATE_PAYMENT_INSTRUMENT_START',
  'CREATE_PAYMENT_INSTRUMENT_SUCCESS',
  'CREATE_PAYMENT_INSTRUMENT_FAILED',
)<null, ICreditCard, string>();

export const GetSubscriptionPlansAsync = createAsyncAction(
  'GET_SUBSCRIPTION_PLANS_START',
  'GET_SUBSCRIPTION_PLANS_SUCCESS',
  'GET_SUBSCRIPTION_PLANS_FAILED',
)<null, ISubscriptionPlan[], string>();

export const UpdateDefaultCardAsync = createAsyncAction(
  'UPDATE_DEFAULT_CARD_START',
  'UPDATE_DEFAULT_CARD_SUCCESS',
  'UPDATE_DEFAULT_CARD_FAILED',
)<null, string, string>();

export const DeleteCreditCardAsync = createAsyncAction(
  'DELETE_CREDIT_CARD_START',
  'DELETE_CREDIT_CARD_SUCCESS',
  'DELETE_CREDIT_CARD_FAILED',
)<null, ICreditCard[], string>();

export const UpdateCreditCardAsync = createAsyncAction(
  'UPDATE_CREDIT_CARD_START',
  'UPDATE_CREDIT_CARD_SUCCESS',
  'UPDATE_CREDIT_CARD_FAILED',
)<null, ICreditCard, string>();

export const SendTabValueAction = createAction('SEND_TAB_VALUE', (action) => (data: number) => action(data));

export const GetSubscriptionAsync = createAsyncAction(
  'GET_ORG_SUBSCRIPTION_START',
  'GET_ORG_SUBSCRIPTION_SUCCESS',
  'GET_ORG_SUBSCRIPTION_FAILED',
)<null, ISubscriptionsResponse, string>();

export const CreateSubscriptionAsync = createAsyncAction(
  'CREATE_SUBSCRIPTION_START',
  'CREATE_SUBSCRIPTION_SUCCESS',
  'CREATE_SUBSCRIPTION_FAILED',
)<null, ISubscriptionsResponse, string>();

export const GetProratedChargesAsync = createAsyncAction(
  'GET_PRORATED_CHARGES_START',
  'GET_PRORATED_CHARGES_SUCCESS',
  'GET_PRORATED_CHARGES_FAILED',
)<null, any, string>();

export const EditSubscriptionAsync = createAsyncAction(
  'EDIT_SUBSCRIPTION_START',
  'EDIT_SUBSCRIPTION_SUCCESS',
  'EDIT_SUBSCRIPTION_FAILED',
)<null, ISubscriptionsResponse, string>();

export const EndSubscriptionRenewalAsync = createAsyncAction(
  'END_SUBSCRIPTION_RENEWAL_START',
  'END_SUBSCRIPTION_RENEWAL_SUCCESS',
  'END_SUBSCRIPTION_RENEWAL_FAILED',
)<null, null, null>();

export const CheckPromoCodeAsync = createAsyncAction(
  'CHECK_PROMO_CODE_START',
  'CHECK_PROMO_CODE_SUCCESS',
  'CHECK_PROMO_CODE_FAILED',
)<null, any, string>();

export const SetPromoCodeAction = createAction('SET_PROMO_CODE', (action) => (data: string) => action(data));

export const ClearPromoCodeInfoAction = createAction('CLEAR_PROMO_CODE_INFO');
