// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { API_URL } from 'Config';
import { ActionCreators } from 'redux-undo';
import { getJson, patchJson, postJson, deleteRequest } from 'Utils/http';
import { datadogRum } from '@datadog/browser-rum';
import GenerateGuid from 'Utils/guid';
import { SetFileVersion } from 'State/order/thunk';
import { wktToCurve, curveToWkt } from 'WktCurves';
import {
  GetGeoJsonForLayerAsync, SetClickedPointAction, SetSiteIdAction, SetCurrentLayerAction, SetLayerUpdateAction, EditFeatureAction, SetlayerSelectedAction, AddNewLayerAction,
  DeleteFeatureAction, CreateFeatureAction, DeleteAddedOrEditedFeatureAction, SetCurrentToolAction, SetDrawModeAction, SetCurrentPanelAction, ResetDrawSourceAction, ResetUpdateEntitiesAction,
  SetEditorModeAction, CopyActiveFeatureAction, PasteActiveFeatureAction, CutActiveFeatureAction, SetAutoUpdateAction, SetHighlightedLayerAction, SetOrderSelectedAction, SetShowSidebarAction,
  ResetEditorStateAction, UpdateEntityAction,
} from './actions';
import { AddNewLayerToVTJ, UpdateLayerVTJ, DeleteLayerVTJ, GetVectorTiles } from '../common/thunk';
import { getOrders } from '../../order/selectors';
import { CreateOrderAsync, UpdateOrderCadFilesAction } from '../../order/actions';
import { NewDrawingToVTJAction } from '../common/actions';

export const GetGeoJsonForLayer = (layerName: string, fileVersion: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/tiles/${fileVersion}/getSingleGeoJson/${layerName}`;
    const { token } = getState().auth;
    dispatch(GetGeoJsonForLayerAsync.request());

    const result = await getJson<any>(url, token);
    if (result.success) {
      if (result.data.features && result.data.features.length > 0) {
        // Parse WKT into curve form.
        result.data.features.forEach((feature: GeoJSON.Feature) => {
          if (feature.properties.wkt) {
            feature.properties.curve = wktToCurve(feature.properties.wkt, { normalize: true, circlesToPolygons: true });
            delete feature.properties.wkt;
          }
        });
        dispatch(GetGeoJsonForLayerAsync.success(result.data));
      }
    } else {
      dispatch(GetGeoJsonForLayerAsync.failure(result.message));
    }

    return result;
  };

export const Autosave = () => async (dispatch: Dispatch, getState: () => IState) => {
  const { token } = getState().auth;
  const { siteId } = getState().map.editor.present;
  const { editEntities } = getState().map.editor.present;
  const { addEntities } = getState().map.editor.present;
  const { deleteEntities } = getState().map.editor.present;
  const featureCollection = { editEntities, addEntities, deleteEntities };

  if (
    addEntities.length > 0 ||
    editEntities.length > 0 ||
    deleteEntities.length > 0
  ) {
    addEntities.forEach((feature: GeoJSON.Feature) => {
      if (feature.properties.curve) {
        feature.properties.wkt = curveToWkt(feature.properties.curve, { autoPolygon: true, forceContinuous: true });
        delete feature.properties.curve;
      }
    });
    editEntities.forEach((feature: GeoJSON.Feature) => {
      if (feature.properties.curve) {
        feature.properties.wkt = curveToWkt(feature.properties.curve, { autoPolygon: true, forceContinuous: true });
        delete feature.properties.curve;
      }
    });
    const url = `${API_URL}/tiles/${siteId}/updateLayer`;

    dispatch(UpdateEntityAction.request());
    const result = await patchJson<any>(url, featureCollection, token);

    // When we get the geojson for a layer that is just updated, set the update variable in the store to true
    if (result.success) {
      dispatch(UpdateEntityAction.success());
      // Clear addEntities, editEntities and deleteEntities arrays once UpdateLayer API is called
      ResetUpdateEntities()(dispatch);
      // Clear Source GeoJSON as well, because we are going to get the updated GeoJSON for this layer
      ResetDrawSource()(dispatch);
      SetAutoUpdate(true)(dispatch);
    }
  }
};

export const SetClickedPoint = (point: { x: number, y: number }) => (dispatch: Dispatch) => dispatch(SetClickedPointAction(point));

export const CopyActiveFeature = (featureId: any) => async (dispatch: Dispatch, getState: () => IState) => {
  const findCollection = getState().map.editor.present.featureCollection.features.find((f) => f.id === featureId);
  dispatch(CopyActiveFeatureAction(findCollection));
};

export const CutActiveFeature = (featureId: any) => (dispatch: Dispatch, getState: () => IState) => {
  const findCollection = getState().map.editor.present.featureCollection.features.find((f) => f.id === featureId);
  dispatch(CutActiveFeatureAction(findCollection));
  dispatch(DeleteFeatureAction(findCollection));
};

export const PasteActiveFeature = () => (dispatch: Dispatch, getState: () => IState) => {
  const entity = { ...getState().map.editor.present.cloneEntity };
  if (entity) { entity.id = GenerateGuid(); }
  dispatch(PasteActiveFeatureAction(entity));
  SetDrawMode('direct_select', { featureId: entity.id })(dispatch);
};

export const UndoDrawing = () =>
  (dispatch: Dispatch) => {
    dispatch(ActionCreators.undo());
  };

export const UpdateEntity = (feature?: any) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const { editEntities } = getState().map.editor.present;
    const { addEntities } = getState().map.editor.present;
    const { deleteEntities } = getState().map.editor.present;
    const layerName = getState().map.editor.present.currentLayer;
    const { siteId } = getState().map.editor.present;

    // Combine editEntities, addEntities, deleteEntities to make a dictionary for the API request body
    const featureCollection = { editEntities, addEntities, deleteEntities };
    const url = `${API_URL}/tiles/${siteId}/updateLayer`;

    dispatch(GetGeoJsonForLayerAsync.request());
    const result = await patchJson<any>(url, featureCollection, token);

    // When we get the geojson for a layer that is just updated, set the update variable in the store to true
    if (result.success) {
      // Clear addEntities, editEntities and deleteEntities arrays once UpdateLayer API is called
      ResetUpdateEntities()(dispatch);
      // Clear Source GeoJSON as well, because we are going to get the updated GeoJSON for this layer
      ResetDrawSource()(dispatch);
      await GetGeoJsonForLayer(layerName, siteId)(dispatch, getState);
      SetLayerUpdate(true)(dispatch);
    }
  };

export const AddNewDrawing = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token, resources } = getState().auth;

    // people with no 2DEditor access can't add a new drawing
    const hasAccess = Boolean('editorFunctions' in resources);
    if (!hasAccess) {
      return;
    }
    const orgId = getState().auth.user.ownerOrganization;
    const { projectId } = getState().project;
    const url = `${API_URL}/tiles/createNewDrawing`;
    const result = await postJson<any>(url, { orgId, projectId }, token);

    if (result.success) {
      const { order, cadFile } = result.data;
      if (!order.cadFiles) {
        order.cadFiles = [];
      }
      dispatch(CreateOrderAsync.success(order));
      dispatch(UpdateOrderCadFilesAction(cadFile));
      dispatch(NewDrawingToVTJAction({ orderId: order._id, siteId: cadFile._id }));
      SetFileVersion(order._id, cadFile._id)(dispatch, getState);
    }
  };

export const AddNewLayer = (orderId: string, siteId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { currentLayer } = getState().map.editor.present;
    const { token } = getState().auth;
    const url = `${API_URL}/tiles/${siteId}/createNewLayer`;
    const result = await postJson<any>(url, null, token);
    if (result.success) {
      const newLayer = result.data;
      const layer: IVectorLayer = {
        id: newLayer.name,
        visible: true,
        color: newLayer.color,
        lineType: newLayer.line_type,
        lineWidth: newLayer.line_width,
        layerId: newLayer.id,
      };

      AddNewLayerToVTJ(orderId, siteId, layer)(dispatch, getState);
      SetlayerSelected(layer.id)(dispatch);
      // If a layer is in geojson, display it as a static layer and clear the draw source
      if (currentLayer) {
        SetCurrentLayer(null)(dispatch);
        ResetDrawSource()(dispatch);
        ResetUpdateEntities()(dispatch);
        GetVectorTiles()(dispatch, getState);
      }
    }
  };

// Function that is called when we update any layer attribute including name, lineType, lineWidth and/or color
export const EditLayerAttribute = (layerId: string, siteId: string, data?: { name?: string, lineType?: string, lineWidth?: number, color?: string }) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/tiles/${siteId}/${layerId}/editLayer`;

    const result = await patchJson<any>(url, data, token);

    if (result.success) {
      const orderId = getState().map.editor.present.orderSelected;
      const updatedLayer = result.data;
      // eslint-disable-next-line no-undef
      const layer: IVectorLayer = {
        id: updatedLayer.name,
        visible: true,
        color: updatedLayer.color,
        lineType: updatedLayer.line_type,
        lineWidth: updatedLayer.line_width,
        layerId: updatedLayer.id,
      };
      UpdateLayerVTJ(orderId, siteId, layer)(dispatch, getState);
      dispatch(SetlayerSelectedAction(layer.id));
      GetVectorTiles()(dispatch, getState);

      // datadog action - tracking user actions by passing custom attributes
      datadogRum.addAction('new_layer', {
        'layer.name': updatedLayer?.name,
      });
    }
  };

export const DeleteLayer = (siteId: string, layerId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const url = `${API_URL}/tiles/${siteId}/${layerId}/deleteLayer`;

    // dispatch(GetGeoJsonForLayerAsync.request());
    const result = await deleteRequest(url, token);

    if (result.success) {
      const orderId = getState().map.editor.present.orderSelected;
      SetlayerSelected(null)(dispatch);
      SetCurrentLayer(null)(dispatch);
      ResetDrawSource()(dispatch);
      DeleteLayerVTJ(orderId, siteId, Number(layerId))(dispatch, getState);
      GetVectorTiles()(dispatch, getState);
    }
  };

export const SetCurrentLayer = (layer: string) => (dispatch: Dispatch) => dispatch(SetCurrentLayerAction(layer));

export const SetSiteId = (siteId: string) => (dispatch: Dispatch) => dispatch(SetSiteIdAction(siteId));

// Set the value of the update boolean in the store
export const SetLayerUpdate = (update: boolean) => (dispatch: Dispatch) => dispatch(SetLayerUpdateAction(update));

export const SetAutoUpdate = (update: boolean) => (dispatch: Dispatch) => dispatch(SetAutoUpdateAction(update));

export const SendPendoEvent = () => (dispatch: Dispatch, getState: () => IState) => {
  const mode = getState().map.editor?.present?.currentTool;
  window?.pendo?.track('Modified Linework', { tool: mode });
};

export const UpdateFeature = (feature: GeoJSON.Feature, operation: string) =>
  (dispatch: Dispatch, getState: () => IState) => {
    // Depending on the update operation, store the GeoJSON feature in the corresponding array
    if (operation === 'edit') {
      // If we edit a feature we just created(before clicking save) update it in the addEntities array
      if (getState().map.editor.present.addEntities.findIndex((f: any) => f.id === feature.id) !== -1) {
        dispatch(CreateFeatureAction(feature));
        return;
      }
      dispatch(EditFeatureAction(feature));
    }
    if (operation === 'add') {
      // Different cases of 'adding' a feature-
      // - New linestring feature(line or polygon) added
      //      - can be added to on a existing layer
      //      - can be added to a new layer
      // - New linestring feature is extended but not closed
      // - New linestring feature is extended and closed
      // - Exisiting linestring feature is extended but not closed
      // - Exisiting linestring feature is extended and closed
      const { currentTool } = getState().map.editor.present;
      const { layerSelected } = getState().map.editor.present;
      const { vectorTileJson } = getState().map.common;
      const { siteId } = getState().map.editor.present;
      const orderId = getState().map.editor.present.orderSelected;
      const newSiteId = getState().order.fileVersions[orderId];

      // If there is no layer selected- the newly added feature will have the properties of the first layer in the list
      let layerProps = vectorTileJson[orderId][newSiteId].vector_layers[0];

      // If a layer is selected- get the properties of the layer from the vectorTileJson object
      if (layerSelected) {
        layerProps = vectorTileJson[orderId][newSiteId].vector_layers.find((layer) => layer.id === layerSelected);
      }

      // If no layer is selected or there is no siteId in the editor state, set them
      if (!layerSelected) SetlayerSelected(layerProps.id)(dispatch);
      if (!siteId) SetSiteId(newSiteId)(dispatch);

      let polygonType = false;
      const coords = feature.geometry.coordinates;
      const featureInAddEntities = getState().map.editor.present.addEntities.findIndex((f: any) => f.id === feature.id) !== -1;
      const featureInFeatureCollection = getState().map.editor.present.featureCollection.features.findIndex((f: any) => f.id === feature.id) !== -1;

      // Check if the feature is a Polygon by checking if the first and last coordinates are the same
      if ((currentTool === 'polyline' || currentTool === 'pointer' || currentTool === 'arc') && (coords[0][0] === coords[coords.length - 1][0] && coords[0][1] === coords[coords.length - 1][1])) {
        polygonType = true;
      }

      let curveProperty = feature.properties.curve;
      if (polygonType && feature.properties.curve) {
        curveProperty = ['curvepolygon', curveProperty];
      }

      const newFeature: GeoJSON.Feature<any> = {
        id: feature.id,
        type: 'Feature',
        geometry: {
          type: polygonType ? 'Polygon' : feature.geometry.type,
          coordinates: polygonType ? [[...feature.geometry.coordinates]] : [...feature.geometry.coordinates],
        },
        properties: {
          color: layerProps.color,
          line_type: layerProps.lineType,
          line_width: layerProps.lineWidth,
          name: layerProps.id,
          layer_id: layerProps.layerId,
          origin_epsg: layerProps.originEpsg,
          srid: layerProps.srid,
          ...(curveProperty && { curve: curveProperty }),
        },
      };

      if (!featureInAddEntities && featureInFeatureCollection) {
        dispatch(EditFeatureAction(newFeature));
      } else {
        dispatch(CreateFeatureAction(newFeature));
      }
    }
    if (operation === 'delete') {
      // If we delete a feature we just created(before clicking save) remove it from the addEntities array
      if (getState().map.editor.present.addEntities.findIndex((f: any) => f.id === feature.id) !== -1) {
        dispatch(DeleteAddedOrEditedFeatureAction(feature));
        return;
      }
      // If we delete a feature we edited remove it editEntities array
      if (getState().map.editor.present.editEntities.findIndex((f: any) => f.id === feature.id) !== -1) {
        dispatch(DeleteAddedOrEditedFeatureAction(feature));
        return;
      }
      // Do not add empty arc features to the deleteEntities array
      if (feature.geometry.type === 'LineString' && Object.keys(feature.properties).length === 0) {
        return;
      }
      dispatch(DeleteFeatureAction(feature));
    }
  };

export const DeleteActiveFeature = (featureId: any) => (dispatch: Dispatch, getState: () => IState) => {
  const findCollection = getState().map.editor.present.featureCollection.features.find((f) => f.id === featureId);
  UpdateFeature(findCollection, 'delete')(dispatch, getState);
};

export const SetCurrentTool = (name: string) => (dispatch: Dispatch, getState: () => IState) => {
  const layerSelected = getState().map.editor.present.layerSelected && getState().map.editor.present.layerSelected;
  const { vectorTileJson } = getState().map.common;
  const { siteId } = getState().map.editor.present;
  // TODO: Assume that there is one placed order in a project for now. Need to get this orderId differently - Vinutna - 05/10/2021
  // const orderId = getOrders(getState()).placedOrder._id;
  const orderId = getState().map.editor.present.orderSelected;
  const newSiteId = getState().order.fileVersions[orderId];
  const layerProps = vectorTileJson[orderId][newSiteId].vector_layers && vectorTileJson[orderId][newSiteId].vector_layers[0];
  if (!layerProps && !layerSelected) {
    AddNewLayer(orderId, newSiteId)(dispatch, getState);
  }

  // When we select a tool and there is no layerSelected, set it as the first layer on the list
  if (!layerSelected && layerProps) SetlayerSelected(layerProps.id)(dispatch);
  if (!siteId) SetSiteId(newSiteId)(dispatch);

  dispatch(SetCurrentToolAction(name));
};

export const ResetEditorState = () => (dispatch: Dispatch) => {
  dispatch(ResetEditorStateAction());
};

export const SetCurrentPanel = (name: string) => (dispatch: Dispatch) => {
  dispatch(SetCurrentPanelAction(name));
  dispatch(SetShowSidebarAction(true));
};

export const SetHighlightedLayer = (id: string) => (dispatch: Dispatch) => dispatch(SetHighlightedLayerAction(id));

export const SetEditorMode = (data: boolean) => (dispatch: Dispatch) => dispatch(SetEditorModeAction(data));

export const SetDrawMode = (name: string, params?: { featureId: string }) => (dispatch: Dispatch) => dispatch(SetDrawModeAction({ name, params }));

// Clear Source GeoJSON when we move to a different layer
export const ResetDrawSource = () => (dispatch: Dispatch) => dispatch(ResetDrawSourceAction());

// Clear addEntities, editEntities and deleteEntities arrays
export const ResetUpdateEntities = () => (dispatch: Dispatch) => dispatch(ResetUpdateEntitiesAction());

export const SetlayerSelected = (layer: string) => (dispatch: Dispatch) => dispatch(SetlayerSelectedAction(layer));

export const SetShowSidebar = (show: boolean) => (dispatch: Dispatch) => dispatch(SetShowSidebarAction(show));

export const SetOrderSelected = (orderId: string) => (dispatch: Dispatch) => dispatch(SetOrderSelectedAction(orderId));
