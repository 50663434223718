// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from 'react-loadable';
import { useHistory, withRouter } from 'react-router';
import { datadogRum } from '@datadog/browser-rum';

import { BUILD_VERSION, DD_ENV } from 'Config';

import PrivateRoute from 'Common/PrivateRoute';
import {
  ROOT_ROUTE, FORGOT_PASSWORD_ROUTE, SIGN_UP_ROUTE, SIGN_UP_SUCCESS, RESET_PASSWORD_ROUTE, PROJECTS_ROUTE,
  PROJECT_DETAILS_ROUTE, PROJECT_FILES_ROUTE, PROJECT_3DVIEWER_ROUTE, ADMIN_OPS_SETTINGS_ROUTE,
  ACCOUNT_GENERAL_ROUTE, ADMIN_PROJECTS_ROUTE, ACCOUNT_BILLING_ROUTE, ACCOUNT_PASSWORD_ROUTE, ACCOUNT_PLAN_USAGE,
  ADMIN_ROUTE, ADMIN_GENERAL_ROUTE, ADMIN_SUBSCRIPTION_ROUTE, ADMIN_USER_ROUTE, ADMIN_PROJECT_ROUTE, ORDER_HISTORY_ROUTE,
  USER_MANAGEMENT_ROUTE, TEAMS_ROUTE, CAD_TEMPLATE_ROUTE, ADMIN_ORG_PLAN_USAGE_ROUTE, EXPIRED_TOKEN_ROUTE, ADMIN_ORDER_HISTORY_ROUTE,
} from 'Utils/routes';

import Auth from 'Components/auth';
import forgotPassword from 'Components/forgotPassword';
import resetPassword from 'Components/resetPassword';
import Shell from 'Components/shell';
import Projects from 'Components/projects';
import Files from 'Components/files';
import Viewer3D from 'Common/Viewer3D';
import AccountGeneral from 'Components/account/general';
import AccountBilling from 'Components/account/billing';
import CadTemplates from 'Components/account/cadTemplates';
import AccountPassword from 'Components/account/password';
import UserManagement from 'Components/account/userManagement';
import Teams from 'Components/account/teams';
import AccountPlanUsage from 'Components/account/planUsage';
import AdminDashboard from 'Components/admin/dashboard';
import AdminOpsSettings from 'Components/admin/opsSettings';
import AdminGeneral from 'Components/admin/general';
import AdminSubscription from 'Components/admin/subscription';
import AdminProjects from 'Components/admin/projects';
import AdminProject from 'Components/admin/projects/project';
import AdminPlanUsage from 'Components/admin/planUsage';

import '../styles/App.css';
import OrderHistory from 'Components/account/orderHistory';
import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import oktaConfig from 'Utils/oktaConfig';
import { useLDClient } from 'launchdarkly-react-client-sdk';
import { getPlanDetails } from 'Utils/localStorageService';

// Initialize datadog real user management, used for tracking user flows.  Only initialize
// this for non admin portal.
if (!_ADMIN_) {
  datadogRum.init({
    applicationId: 'e31bc513-cb03-44be-a642-5dc5ac649a44',
    clientToken: 'pub5b764e1da0d74719b2e86c281d5329e2',
    site: 'datadoghq.com',
    service: 'airworks-client',
    env: DD_ENV,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: BUILD_VERSION,
    sampleRate: 100,
    trackInteractions: true,
  });
}

const AsyncProject = loadable({
  loading: () => null,
  loader: () => import('Components/project'),
});

const PrivatePart = () => (
  <Shell>
    <PrivateRoute exact path={PROJECTS_ROUTE} component={Projects} />
    <PrivateRoute path={PROJECT_DETAILS_ROUTE} component={AsyncProject} />
    <PrivateRoute path={PROJECT_FILES_ROUTE} component={Files} />
    <PrivateRoute path={PROJECT_3DVIEWER_ROUTE} component={Viewer3D} />
    <PrivateRoute path={ACCOUNT_GENERAL_ROUTE} component={AccountGeneral} />
    <PrivateRoute path={CAD_TEMPLATE_ROUTE} component={CadTemplates} />
    <PrivateRoute path={ACCOUNT_BILLING_ROUTE} component={AccountBilling} />
    <PrivateRoute path={ORDER_HISTORY_ROUTE} component={OrderHistory} />
    <PrivateRoute path={ACCOUNT_PASSWORD_ROUTE} component={AccountPassword} />
    <PrivateRoute path={ACCOUNT_PLAN_USAGE} component={AccountPlanUsage} />
    <PrivateRoute path={USER_MANAGEMENT_ROUTE} component={UserManagement} />
    <PrivateRoute path={TEAMS_ROUTE} component={Teams} />
  </Shell>
);

const AdminPrivatePart = () => (
  <Shell>
    <SecureRoute exact path={ADMIN_ROUTE} component={AdminDashboard} />
    <SecureRoute path={ADMIN_OPS_SETTINGS_ROUTE} component={AdminOpsSettings} />
    <SecureRoute path={ADMIN_GENERAL_ROUTE} component={AdminGeneral} />
    <SecureRoute path={ADMIN_SUBSCRIPTION_ROUTE} component={AdminSubscription} />
    <SecureRoute path={ADMIN_USER_ROUTE} component={UserManagement} />
    <SecureRoute exact path={ADMIN_PROJECTS_ROUTE} component={AdminProjects} />
    <SecureRoute path={ADMIN_PROJECT_ROUTE} component={AdminProject} />
    <SecureRoute path={ADMIN_ORG_PLAN_USAGE_ROUTE} component={AdminPlanUsage} />
    <SecureRoute path={ADMIN_ORDER_HISTORY_ROUTE} component={OrderHistory} />
  </Shell>
);

const mapStateToProps = (state: IState) => ({
  state,
  _id: state.auth?.user?._id,
  ownerOrganization: state.auth?.user?.ownerOrganization,
  firstName: state.auth?.user?.firstName,
  lastName: state.auth?.user?.lastName,
  createdAt: state.auth?.user?.createdAt,
  editorFunctions: state.auth?.resources?.editorFunctions,
  email: state.auth?.user?.email,
  companyName: state.account?.org?.companyName,
  availableCreditAcreage: state.account?.org?.availableCreditAcreage,
  processingCreditAcreage: state.account?.org?.processingCreditAcreage,
  totalPurchasedCreditAcreage: state.account?.org?.totalPurchasedCreditAcreage,
  usedCreditAcreage: state.account?.org?.usedCreditAcreage,
  orgImageryProviders: state.account?.org?.imageryProviders,
  imageryProviders: state.imagery?.imageryProviders,
  resources: state.auth?.resources,
  plans: state.subscription?.plans,
});

const oktaClient = new OktaAuth(oktaConfig);

const App = (props: any) => {
  const ldClient = useLDClient();
  const history = useHistory();
  const restoreOriginalUri = async (oktaAuth: any, originalUri: any) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  useEffect(() => {
    datadogRum.setUser({
      id: props._id,
      name: `${props.firstName} ${props.lastName}`,
      email: props.email,
      organization: props.companyName,
    });
  }, [props.location?.pathname]);

  useEffect(() => {
    // this check is to avoid sending empty user attributes to launch darkly from public pages
    if (!_ADMIN_ && props.location?.pathname !== '/' && props.location?.pathname !== '/forgot' && props.location?.pathname !== '/signUp') {
      // send user attributes to launch darkly(we use this to target individual users and organizations)
      ldClient.identify({ key: props._id, email: props.email, custom: { orgId: props.ownerOrganization } });
    }
    // For testing allow DD_ENV === 'dev'
    // For production DD_ENV === 'prod'
    if (DD_ENV === 'prod' && !_ADMIN_) {
      if (props.state?.account?.org && props.plans.length > 0) {
        const isNumber = (value: number | null) => {
          if (typeof (value) === 'number') { return value; }
          return 'N/A';
        };

        let provider;
        if (props.orgImageryProviders?.length) {
          // In the future, when there are more providers, this function will need to change
          provider = props.imageryProviders.find((iP: IImageryProvider) => iP._id === props.orgImageryProviders[0]);
        }

        const { availableCreditAcreage, processingCreditAcreage, usedCreditAcreage, totalPurchasedCreditAcreage, companyName, plans, state } = props;
        const { automateStartingAcres } = JSON.parse(getPlanDetails());
        const cadTemplates = state.cadTemplate?.list?.map((cad: ICadTemplate) => cad?.name);
        window.pendo?.initialize({
          visitor: {
            id: props._id, // User Id
            email: props.email,
            available_acreage: isNumber(availableCreditAcreage),
            processing_acreage: isNumber(processingCreditAcreage),
            used_acreage: isNumber(usedCreditAcreage),
            purchased_acreage: isNumber(totalPurchasedCreditAcreage),
            most_recent_purchase: isNumber(automateStartingAcres),
            company: companyName,
            resources: Object.keys(props.resources),
            plans: props.plans.map((plan) => plan.nickname),
            nearmapTier: provider?.name || 'no subscription',
            cadTemplates,
          },
          account: {
            id: props.ownerOrganization, // Org Id
          },
        });
      }
    }
  }, [props.state?.account?.org, props.plans]);
  return (
    <>
      { _ADMIN_ ? (
        <Security
          oktaAuth={oktaClient}
          restoreOriginalUri={restoreOriginalUri}
        >
          <Switch>
            <Route exact path={ROOT_ROUTE} component={Auth} />
            <Route path="/callback" component={LoginCallback} />
            <Route exact path={EXPIRED_TOKEN_ROUTE} component={Auth} />
            <Route component={AdminPrivatePart} />
          </Switch>
        </Security>
      ) : (
        <Switch>
          <Route exact path={ROOT_ROUTE} component={Auth} />
          <Route exact path={EXPIRED_TOKEN_ROUTE} component={Auth} />
          <Route exact path={SIGN_UP_ROUTE} component={Auth} />
          <Route exact path={SIGN_UP_SUCCESS} component={Auth} />
          <Route exact path={FORGOT_PASSWORD_ROUTE} component={forgotPassword} />
          <Route exact path={RESET_PASSWORD_ROUTE} component={resetPassword} />
          <Route component={PrivatePart} />
        </Switch>
      )}
    </>
  );
};

export default compose(
  withRouter,
  connect(mapStateToProps),
)(App);
