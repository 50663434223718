// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  dialog: {
    minWidth: '600px',
    margin: 'auto',
  },
  dialogBox: {
    padding: theme.spacing(3),
  },
  dialogActions: {
    padding: '8px 0',
  },
  dialogText: {
    fontSize: '18px',
    marginBottom: theme.spacing(2),
  },
}));

export default useStyles;
