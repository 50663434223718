// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  expansionPanelHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    margin: 0,
  },
  select: {
    display: 'flex',
    fontSize: '0.875rem',
    fontWeight: 400,
    marginLeft: '20px',
    marginBottom: '5px',
    width: '75%',
  },
  processingText: {
    padding: '2px 10px',
    color: '#ffffff',
    lineHeight: '8px',
    background: 'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
  },
  expressIconImg: {
    width: '20px',
  },
  sidebarSubheader: {
    fontSize: 14,
    fontWeight: 300,
  },
  layersLabel: {
    color: 'black',
  },
  checkboxWrapper: {
    paddingLeft: theme.spacing(1),
  },
  listWrapper: {
    paddingBottom: theme.spacing(1),
  },
  checkbox: {
    padding: theme.spacing(0.5),
  },
  layerColor: {
    height: '20px',
    width: '20px',
    border: '1px solid #000000',
  },
  layerListItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  layerList: {
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}));

export default useStyles;
