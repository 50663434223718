// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { DownloadLAS, DownloadSurfaceFile, DownloadPostgisDxf, SetDownloadFilesState, PrepareLAS, PrepareSurfaceFile, ClearDownloadFiles, SetGeojsonFilesState } from 'State/order/thunk';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => {
  const vectorTileJson =
    state.map.common.vectorTileJson &&
    state.map.common.vectorTileJson[ownProps.order._id] &&
    state.map.common.vectorTileJson[ownProps.order._id][ownProps.siteId];

  // Boolean which checks whether all layers for this site are deselected
  const noLayersSelected = vectorTileJson?.vector_layers && vectorTileJson.vector_layers.every((l) => !l.visible);
  return {
    id: ownProps.open ? 'simple-popper' : undefined,
    userId: ownProps.order.owner,
    projectId: ownProps.order.ownerProject,
    orderId: ownProps.order._id,
    deliveryEstimate: ownProps.order.deliveryEstimate,
    orderFile: ownProps.fileName,
    siteId: ownProps.siteId,
    orgId: ownProps.order.ownerOrganization,
    order: ownProps.order,
    orderedGeoJson: ownProps.order?.addOns?.some((el) => el.name === 'Geojson File Format (.geojson)'),
    downloadFileStatuses: state.order?.dxfFileStatus,
    downloadGeoJsonFileStatuses: state.order?.geoJsonFileStatus,
    anyChangeInFileStatuses: state.order?.anyChangeInFileStatuses,
    noLayersSelected,
    vectorTileJson,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    DownloadLASAction: DownloadLAS,
    DownloadSurfaceFileAction: DownloadSurfaceFile,
    DownloadPostgisDxfAction: DownloadPostgisDxf,
    SetDownloadFilesStateAction: SetDownloadFilesState,
    SetGeoJsonDownloadStatusAction: SetGeojsonFilesState,
    PrepareLASAction: PrepareLAS,
    PrepareSurfaceFileAction: PrepareSurfaceFile,
    ClearDownloadFilesAction: ClearDownloadFiles,
  }, dispatch);

interface IOwnProps {
  anchorEl: HTMLElement;
  setAnchorEl: any;
  open: boolean;
  order: IOrder;
  fileName: string;
  siteId: string;
  surfaceFile: ISurfaceFile;
  lasFilename: string;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
