// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const FileUploadStartAction = createAction('FILE_UPLOAD_START', (action) => (data: {
    id: string, name: string, extension: string, request: XMLHttpRequest, progress: number, success: boolean,
  }) => action(data));
export const FileUploadUpdateAction = createAction('FILE_UPLOAD_UPDATE', (action) => (data: { id: string, progress?: number, success?: boolean, fileId?: string, fileKey?: string }) => action(data));
export const FileUploadCancelAction = createAction('FILE_UPLOAD_CANCEL', (action) => (data: string) => action(data));

export const FileUploadInitAction = createAction('FILE_UPLOAD_INIT');

export const SetExpressAIUploadAction = createAction('SET_EXPRESS_AI_OUTPUT');

export const SetDxfEpsgAction = createAction('SET_DXF_EPSG', (action) => (data: number) => action(data));

export const SetEpsgListAction = createAction('SET_EPSG_LIST', (action) => (data: number[]) => action(data));

export const SetImageryCaptureDateAction = createAction('SET_IMAGERY_CAPTURE_DATE', (action) => (data: string) => action(data));

export const SendExpressAIEmailAsync = createAsyncAction(
  'SEND_EXPRESS_AI_EMAIL_START',
  'SEND_EXPRESS_AI_EMAIL_SUCCESS',
  'SEND_EXPRESS_AI_EMAIL_FAILED',
)<null, any, string>();
