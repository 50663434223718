// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

// TODO - either convert this file to typescript or define all the props (garrett, 2021-08-16)
/* eslint react/prop-types: 0 */

import React, { useEffect } from 'react';
import classnames from 'classnames';
import BarChart from 'recharts/es6/chart/BarChart';
import XAxis from 'recharts/es6/cartesian/XAxis';
import YAxis from 'recharts/es6/cartesian/YAxis';
import Bar from 'recharts/es6/cartesian/Bar';
import { Typography, LinearProgress, Divider } from '@material-ui/core';
import LoadingSpinner from 'Common/LoadingSpinner';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const formatNumber = (number) => +Number(number).toFixed(2);

const PlanUsage = ({ classes, theme, loading, orgStats, GetOrgStatsAction, subscriptionType }) => {
  useEffect(() => {
    GetOrgStatsAction();
  }, []);

  const creditAcresData =
    orgStats &&
    orgStats.creditAcres.processedPerMonth.map((v, index) => ({
      monthName: monthNames[v.month - 1],
      year: (index === 0 || v.month === 1) ? v.year : '',
      creditAcres: +(v.value).toFixed(2),
    }));
  const projectsData =
    orgStats &&
    orgStats.projects.perMonth.map((v, index) => ({
      monthName: monthNames[v.month - 1],
      year: (index === 0 || v.month === 1) ? v.year : '',
      projects: v.value,
    }));

  const BarLabel = (params) => {
    const { value: barLabelValue } = params;
    if (barLabelValue === 0) return <text />;

    const {
      x, y, value, width, height,
    } = params;

    return (
      <text
        x={x}
        y={y}
        dx={7}
        dy={-8}
        fontSize={12}
        fill={theme.palette.darkGrey}
        className="recharts-bar-label"
        width={width}
        height={height}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  };

  return (
    <LoadingSpinner loading={loading}>
      {orgStats && (
        <div className={classes.pageWrapper}>
          <div className={classes.margin}>
            <Typography className={classes.title} variant="h4">Credit Usage</Typography>
            <div className={classnames(classes.list, classes.acreageList)}>
              <Typography variant="subtitle1" color="textSecondary">
                <Typography component="span" variant="subtitle1" color="primary">{formatNumber(orgStats.creditAcres.totalProcessed)}</Typography>
                {` of ${formatNumber(orgStats.creditAcres.limit)} credits`}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary" className={classes.alignRight}>
                <Typography component="span" variant="subtitle1" color="secondary">{formatNumber(orgStats.creditAcres.totalPending)}</Typography>
                {' credits pending'}
              </Typography>
              <Typography variant="body1">{subscriptionType === 'Monthly' ? 'processed this month' : 'processed this year'}</Typography>
              <Typography variant="subtitle1" color="textSecondary" className={classes.alignRight}>{`${formatNumber(orgStats.creditAcres.available)} credits remaining`}</Typography>
            </div>
            <LinearProgress
              variant="buffer"
              value={(orgStats.creditAcres.totalProcessed / orgStats.creditAcres.limit) * 100}
              valueBuffer={((orgStats.creditAcres.totalProcessed + orgStats.creditAcres.totalPending) / orgStats.creditAcres.limit) * 100}
              className={classes.progressBar}
              classes={{
                bar2Buffer: classes.bar2,
                dashed: classes.dashedBar,
              }}
            />
            <Typography variant="subtitle1" color="textSecondary">Credits Per Month</Typography>
            <BarChart height={200} width={600} data={creditAcresData}>
              <XAxis dataKey="monthName" tickLine={false} />
              <XAxis dataKey="year" axisLine={false} tickLine={false} xAxisId="year" />
              <YAxis padding={{ bottom: 8, top: 16 }} hide />
              <Bar dataKey="creditAcres" fill={theme.palette.primary.main} label={<BarLabel />} barSize={16} radius={[2, 2, 2, 2]} />
            </BarChart>
            <div className={classes.list}>
              <Typography variant="subtitle1" color="textSecondary">Credits Processed to Date</Typography>
              <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                {formatNumber(orgStats.creditAcres.totalProcessed)}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">Avg Credits Per Month</Typography>
              <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                {formatNumber(orgStats.creditAcres.averagePerMonth)}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">Avg Credits Per Project</Typography>
              <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                {formatNumber(orgStats.creditAcres.averagePerProject)}
              </Typography>
            </div>
            <Divider className={classes.divider} />
            <Typography className={classes.title} variant="h4">Project usage</Typography>
            <Typography variant="subtitle1" color="textSecondary">Projects Per Month</Typography>
            <BarChart height={200} width={600} data={projectsData}>
              <XAxis dataKey="monthName" tickLine={false} />
              <XAxis dataKey="year" axisLine={false} tickLine={false} xAxisId="year" />
              <YAxis padding={{ bottom: 8, top: 16 }} hide />
              <Bar dataKey="projects" fill={theme.palette.primary.main} label={<BarLabel />} barSize={16} radius={[2, 2, 2, 2]} />
            </BarChart>
            <div className={classes.list}>
              <Typography variant="subtitle1" color="textSecondary">Projects Processed to Date</Typography>
              <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                {orgStats.projects.total}
              </Typography>
              <Typography variant="subtitle1" color="textSecondary">Avg Projects Per Month</Typography>
              <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                {formatNumber(orgStats.projects.averagePerMonth)}
              </Typography>
            </div>
          </div>
        </div>
      )}
    </LoadingSpinner>
  );
};

export default PlanUsage;
