// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';

import { ZoomIn, ZoomOut } from 'State/map/common/thunk';
import { Delete, Undo } from 'State/map/draw/thunk';
import { getOrders } from 'State/order/selectors';
import { ResetBufferStateAction } from 'State/kml/actions';

import styles from './styles';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => {
  const orders = getOrders(state);
  const { createKml, currentKml } = state.map.draw.present;
  const currentProject = state.project.project;
  const imageryRequested = (orders?.activeOrder?.isEstimate && !!(currentProject?.imageryRequest?.requested)) || !!(currentProject?.imageryRequest?.uploaded) || !!(currentProject?.imageryRequest?.notificationSent);
  let uploadedKml;
  let atLeastOneKmlFeature;
  if (!_ADMIN_ && !ownProps.showLayers) {
    uploadedKml = state.kml.present.kmls[orders.activeOrder?._id]?.featureCollection;
    atLeastOneKmlFeature = createKml ? currentKml.features.length > 0 : uploadedKml?.features.length > 0;
  }
  return {
    canUndo: createKml ? state.map.draw.past.length > 0 : state.kml.past.length > 0,
    imageryRequested,
    hasPolygon: _ADMIN_ ? false : atLeastOneKmlFeature,
    isEstimate: orders.activeOrder?.isEstimate,
  };
};

const mapDispatchToProps = {
  ZoomInAction: ZoomIn,
  ZoomOutAction: ZoomOut,
  DeleteAction: Delete,
  UndoAction: Undo,
  ResetBufferStateAction,
};

interface IOwnProps {
  showLayers: boolean;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
