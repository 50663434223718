// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import {
  Dialog,
  Typography,
  Checkbox,
  FormGroup,
  Button,
  FormControlLabel,
  DialogActions,
  Fade,
  CircularProgress,
} from '@material-ui/core';
import compose, { PropsType } from './container';
import useStyles from './styles';

const LayerSelectionDialog = ({
  showDialog,
  orderId,
  emailLoading,
  onCancel,
  SendExpressAIEmailAction,
}: PropsType) => {
  const classes = useStyles();
  const expressAILayers = [
    'Roads',
    'Sidewalks',
    'Curbs',
    'Buildings',
    'Pavement Markings',
    'Manholes',
    'Catch Basins',
    'Water',
    'Vegetation',
    'Contours (Major, Minor)',
    'Obstruction (Areas Obstructing Ground Segmentation)',
  ];
  const [selectedLayers, setSelectedLayers] = useState([]);

  const toggleLayer = (layer: string) => {
    if (selectedLayers.includes(layer)) {
      setSelectedLayers((selectedLayers) =>
        selectedLayers.filter((l) => l !== layer)
      );
    } else {
      setSelectedLayers((selectedLayers) => [...selectedLayers, layer]);
    }
  };

  const onDialogClose = () => {
    setSelectedLayers([]);
    onCancel();
  };

  return (
    <Dialog
      open={showDialog}
      onClose={onDialogClose}
      className={classes.dialog}
    >
      <div className={classes.dialogBox}>
        <Typography variant="h5" className={classes.dialogText}>
          Express AI layers
        </Typography>
        <Typography>Select below the Express AI layers.</Typography>
        <FormGroup className={classes.layerList}>
          {expressAILayers.map((layer) => (
            <FormControlLabel
              key={layer}
              value={layer}
              label={<Typography>{layer}</Typography>}
              control={(
                <Checkbox
                  color="primary"
                  onChange={(e) => toggleLayer(e.target.value)}
                  checked={selectedLayers.includes(layer)}
                />
              )}
            />
          ))}
        </FormGroup>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={onCancel} variant="text">
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              SendExpressAIEmailAction(orderId, selectedLayers);
              onDialogClose();
            }}
          >
            <Fade in={emailLoading}>
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Fade>
            <Fade in={!emailLoading}>
              <span>Send Email</span>
            </Fade>
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default compose(LayerSelectionDialog);
