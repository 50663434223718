// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { DownloadFile } from 'State/order/thunk';
import { AddNewDrawing, SetShowSidebar, SetOrderSelected } from 'State/map/editor/thunk';
import { getOrders } from 'State/order/selectors';
import { ShowUploadKmlDialog, HideUploadKmlDialog } from 'State/ui/uploadKmlDialog/thunk';

const mapStateToProps = (state: IState) => {
  const { ordersWithCadFiles } = getOrders(state);
  return {
    layers: state.map.common.layers,
    fileLoading: state.order.fileLoading,
    ordersWithCadFiles,
    currentPanel: state.map.editor.present.currentPanel,
    showSidebar: state.map.editor.present.showSidebar,
    orderSelected: state.map.editor.present.orderSelected,
    editorFunctions: state.auth?.resources?.editorFunctions,
  };
};

const mapDispatchToProps = {
  DownloadFileAction: DownloadFile,
  ShowUploadKmlDialogAction: ShowUploadKmlDialog,
  HideUploadKmlDialogAction: HideUploadKmlDialog,
  SetShowSidebarAction: SetShowSidebar,
  SetOrderSelectedAction: SetOrderSelected,
  AddNewDrawingAction: AddNewDrawing,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
