// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Typography, Divider } from '@material-ui/core';
import CreditCardIcon from 'Common/creditCardIcon';
import CreditCard from 'Components/account/billing/PaymentInfo/CreditCard';
import CreateCreditCard from 'Components/account/billing/PaymentInfo/CreateCreditCard';
import CreateCreditCardForm from 'Components/account/billing/PaymentInfo/CreateCreditCard/CreateCreditCardForm';
import compose from './container';

const BillingColumn = compose(({ classes, creditCards, orderFormVisible, setCards, totalCost, addOnDeliverableDollars, addOnLayerDollars, imageryExtraDollarCost }) => {
  const hasCards = creditCards.length > 0;
  if (!hasCards) {
    setCards(false);
  } else {
    setCards(true);
  }

  let addOnCost = 0;

  if (addOnDeliverableDollars) {
    addOnCost += addOnDeliverableDollars;
  }
  if (addOnLayerDollars) {
    addOnCost += addOnLayerDollars;
  }
  if (imageryExtraDollarCost) {
    addOnCost += imageryExtraDollarCost;
  }

  const Cards = () => (
    <>
      {!hasCards && (
        <>
          <Typography className={classes.subSectionTitle} variant="h6">Billing Address and Card Details</Typography>
          <div className={classes.icons}>
            <div className={classes.iconWrapper}><CreditCardIcon cardType="visa" /></div>
            <div className={classes.iconWrapper}><CreditCardIcon cardType="mastercard" /></div>
            <div className={classes.iconWrapper}><CreditCardIcon cardType="diners-club" /></div>
            <div className={classes.iconWrapper}><CreditCardIcon cardType="american-express" /></div>
            <div className={classes.iconWrapper}><CreditCardIcon cardType="discover" /></div>
          </div>
          <CreateCreditCardForm onCancel={() => {}} />
        </>
      )}
      {hasCards && (
        <>
          {orderFormVisible && (
            <>
              <Typography className={classes.sectionTitle} variant="h3">Payment Information</Typography>
              <Divider className={classes.divider} />
            </>
          )}
          {creditCards.map((card) => <CreditCard card={card} />)}
          <CreateCreditCard />
        </>
      )}
    </>
  );

  return (
    <>
      <div className={classes.billingColumn}>
        {orderFormVisible ? (
          <>
            <Typography className={classes.pageTitle} variant="h1">
              Billing and Payment
            </Typography>
            {(totalCost + addOnCost) > 0 ? (
              <Cards />
            ) : (
              <Typography variant="body1">
                No additional charges required
              </Typography>
            )}
          </>
        ) : (
          <Cards />
        )}
      </div>
    </>
  );
});

export default BillingColumn;
