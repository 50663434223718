// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  map: {
    position: 'absolute',
    height: '100%',
    width: '100%',
  },
  mapControls: {
    position: 'absolute',
    right: theme.spacing(6),
    bottom: theme.spacing(6),
  },
  mapButton: {
    padding: theme.spacing(1),
    minWidth: 0,
  },
  divider: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
}));

export default useStyles;
