// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import Sidebar from 'Common/Sidebar';
import SvgIcon from '@material-ui/core/SvgIcon';
import { Typography } from '@material-ui/core';
import CadDrawings from './CadDrawings';
import Orthomosaic from './Orthomosaic';
import Basemap from './Basemap';
import Panel from './Panel';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const FilesSidebar = ({ editorFunctions, ordersWithCadFiles, currentPanel, showSidebar, orderSelected, SetShowSidebarAction, AddNewDrawingAction, HideUploadKmlDialogAction, SetOrderSelectedAction }: PropsType) => {
  const classes = useStyles();

  useEffect(() => {
    if (!orderSelected && ordersWithCadFiles.length > 0) SetOrderSelectedAction(ordersWithCadFiles[0]._id)
  });

  return (
    <Sidebar data-test="sidebar-rendering" onClick={HideUploadKmlDialogAction}>
      <div className={classes.mainContent}>
        <Panel />
        {showSidebar && (
          <div className={classes.sidebarContent}>
            <div className={classes.filesSidebar}>
              <div className={classes.sidebarTitle}>
                <Typography display="inline" className={classes.header} variant="h4"> {currentPanel} </Typography>
                <SvgIcon className={classes.hideSidebar} fontSize="inherit" onClick={() => SetShowSidebarAction(false)}>
                  {/* svg below is the << icon that will hide the sidebar */}
                  <rect fill="none" width="24" height="24"></rect>
                  <g>
                    <polygon points="23.79,3.77 22.02,2 12.02,12 22.02,22 23.79,20.23 15.56,12 "></polygon>
                    <polygon points="12.02,3.77 10.25,2 0.25,12 10.25,22 12.02,20.23 3.79,12" id="polygon1"></polygon>
                  </g>
                </SvgIcon>
              </div>
              {ordersWithCadFiles.map((order, index) => (
                <CadDrawings order={order} index={ordersWithCadFiles.length - index} />
              ))}
              <Orthomosaic />
              <Basemap />
            </div>
            {/*{editorFunctions && (*/}
            {/*  <Paper square className={classes.bottom}>*/}
            {/*    <Button onClick={AddNewDrawingAction} data-test="add-new-drawing-button" className={classes.bottomButton} startIcon={<AddIcon />}> New Drawing </Button>*/}
            {/*  </Paper>*/}
            {/*)}*/}
          </div>
        )}
      </div>
    </Sidebar>
  );
};

export default compose(FilesSidebar);
