// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

// eslint-disable-next-line import/prefer-default-export
export const UploadDxfLayersAction = createAsyncAction(
  'UPLOAD_DXF_LAYERS_START',
  'UPLOAD_DXF_LAYERS_SUCCESS',
  'UPLOAD_DXF_LAYERS_FAILED',
)<null, string, string>();

export const InitializeTrainingProjectAction = createAsyncAction(
  'INITIALIZE_TRAINING_PROJECT_START',
  'INITIALIZE_TRAINING_PROJECT_SUCCESS',
  'INITIALIZE_TRAINING_PROJECT_FAILED',
)<null, null, string>();

export const ShowOpsTrainingDialogAction = createAction('SHOW_OPS_TRAINING_DIALOG', (action) => () => action());

export const HideOpsTrainingDialogAction = createAction('HIDE_OPS_TRAINING_DIALOG', (action) => () => action());
