// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { VerifyToken, ResetPassword } from 'State/auth/thunk';

const mapDispatchToProps = {
  VerifyTokenAction: VerifyToken,
  ResetPasswordAction: ResetPassword,
};

interface IRouterProps {
    token : string;
}

export type PropsType =
  typeof mapDispatchToProps &
  RouteComponentProps<IRouterProps>;

export default compose<PropsType, {}>(
  withRouter,
  connect(null, mapDispatchToProps),
);
