// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import { TextField, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import compose from './container';

const SearchField = compose(({ classes, value = '', debounceTimeout = 500, onChange, placeholder = '' }) => (
  <TextField
    value={value}
    placeholder={placeholder}
    onChange={(ev) => onChange(ev.target.value)}
    InputProps={{
      classes: {
        root: classes.searchField,
        input: classes.searchFieldInput,
      },
      inputComponent: DebounceInput,
      inputProps: { debounceTimeout },
      disableUnderline: true,
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
    }}
  />
));

export default SearchField;
