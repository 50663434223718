// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import React, { useState, useEffect, useRef } from 'react';
import ReactMapboxGl, { MapContext, Marker } from 'react-mapbox-gl';
import { ESRI_API_Key, MAPBOX_KEY } from 'Config';
import markerIcon from '@mapbox/maki/icons/marker-15.svg';
import SVG from 'react-inlinesvg';
import { useFlags } from 'launchdarkly-react-client-sdk';
import MapControls from './MapControls';
import MapRasterLayer from './MapRasterLayer';
import MapVectorLayers from './MapVectorLayers';
import MapLayersController from './MapLayersController';
import MapMeasurements from './MapMeasurements';
import MapDraw from './MapDraw';
import MapLasBBox from './MapLasBBox';
import MapSearchMarker from './MapSearchMarker';
import MapStyleToggle from './MapStyleToggle';
import ErrorPaper from './FileErrors';
import compose from './container';
import MapKMLLayer from './MapKMLLayer';
import MapEditor from './MapEditor';

const MapWrapper = compose(({
  classes, map: Map, mode, viewport, layers, mapStyle, coordinates, hoveredCoords, projectId, rasterTileJson, lasBBoxLoading, GetOrdersAction, LoadKmlsAction, ToggleNoneVectorTilesAction, SetLatAction, SetLngAction,
  editor, rasterLoading, SetViewportAction, InitMapAction, SetCanvasSizeAction, editorFunctions, showSidebar, opsTrainer, imageryRequested, SetScreenshotAction,
}) => {
  const interactive = mode !== 'sidebar';
  const showLayers = mode === 'layers';
  const dashboard = mode === 'dashboard';
  const rasterTab = mode === 'details';

  const { esriBasemap } = useFlags();
  const [fileErrors, setFileErrors] = useState(false);

  useEffect(() => {
    if (showLayers) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [showSidebar]);

  const updateViewport = (m: any, loadEvent: any) => {
    const canvas = m.getCanvas();
    const canvasSize = [canvas.width, canvas.height];
    SetCanvasSizeAction(canvasSize as [number, number]);

    const center = m.getCenter();
    const zoom = m.getZoom();
    SetViewportAction(center, zoom);
    // Force map movement to render tiles after rasterTileJson is created in the store
    if (mode === 'details' && rasterTileJson && loadEvent?.type === 'load') {
      m.panBy([-1, 1], { duration: 5000 });
    }
    if (mode === 'sidebar' || (imageryRequested && mode === 'details')) {
      m.once('idle', () => {
        const img = m.getCanvas().toDataURL();
        SetScreenshotAction(img);
      });
    }
  };

  const getLatLong = (map, e) => {
    SetLatAction(JSON.stringify(e.lngLat.lat));
    SetLngAction(JSON.stringify(e.lngLat.lng));
  };

  const ref = useRef(null);
  useEffect(() => {
    if (!ref.current
      || !ref.current.getBoundingClientRect().width
      || !ref.current.getBoundingClientRect().height) return;

    const { width, height } = ref.current.getBoundingClientRect();
    SetCanvasSizeAction([width, height]);
  }, [ref.current, hoveredCoords]);

  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    const effect = async () => {
      setInitialized(false);
      if (opsTrainer && showLayers) {
        await GetOrdersAction(projectId);
        await LoadKmlsAction();
      }
      await InitMapAction(showLayers, dashboard);
      if (_ADMIN_ && showLayers) {
        ToggleNoneVectorTilesAction();
      }
      setInitialized(true);
    };
    effect();
  }, [rasterLoading, lasBBoxLoading, projectId]);

  const getMapStyle = () => {
    const showMapboxLayers = !showLayers || layers.baseMap;
    if (!showMapboxLayers) return 'mapbox://styles/mapbox/empty-v8';

    const mapboxStreetBasemapUrl = dashboard ? 'mapbox://styles/mapbox/light-v9' : 'mapbox://styles/mapbox/streets-v11';
    const streetBasemapUrl = esriBasemap ? `https://basemaps-api.arcgis.com/arcgis/rest/services/styles/ArcGIS:Streets?type=style&token=${ESRI_API_Key}` : mapboxStreetBasemapUrl;
    const imageryBasemapUrl = esriBasemap ? `https://basemaps-api.arcgis.com/arcgis/rest/services/styles/ArcGIS:Imagery:Standard?type=style&token=${ESRI_API_Key}` : 'mapbox://styles/mapbox/satellite-v9';

    return mapStyle === 'streets' ? streetBasemapUrl : imageryBasemapUrl;
  };

  return (
    <>
      <div className={classes.map} ref={ref}>
        {initialized && (
          <Map
            style={getMapStyle()}
            center={viewport.center}
            zoom={[viewport.zoom]}
            containerStyle={{
              height: '100%',
              width: '100%',
            }}
            onMouseMove={(map, e) => getLatLong(map, e)}
            onMoveEnd={updateViewport}
            onStyleLoad={(m: any, loadEvent: any) => {
              updateViewport(m, loadEvent);
            }}
          >
            {!dashboard && (
              <MapContext.Consumer>
                {(map) => (
                  <div>
                    {rasterTab && <ErrorPaper setFileErrors={setFileErrors} />}
                    <MapSearchMarker />
                    <MapRasterLayer showLayers={showLayers} />
                    <MapLayersController showLayers={showLayers} />
                    <MapVectorLayers map={map} showLayers={showLayers} />
                    {!showLayers && <MapLasBBox />}
                    {(showLayers && !editor) && <MapMeasurements />}
                    {(showLayers && editor && editorFunctions) && <MapEditor map={map} />}
                    {showLayers && _ADMIN_ && <MapKMLLayer />}
                    {!showLayers && <MapDraw />}
                  </div>
                )}
              </MapContext.Consumer>
            )}
            {dashboard && coordinates.map((c) => (
              <div>
                <Marker coordinates={c.coords}>
                  <div className={classes.markerIconWrapper}>
                    <SVG
                      src={markerIcon}
                      className={classes.markerIcon}
                    />
                  </div>
                </Marker>
                {hoveredCoords && (
                  <Marker coordinates={hoveredCoords}>
                    <div className={classes.markerIconWrapper}>
                      <SVG
                        src={markerIcon}
                        className={classes.markerIconHovered}
                      />
                    </div>
                  </Marker>
                )}
              </div>
            ))}
          </Map>
        )}
        {(!dashboard && !interactive) && <div className={classes.transparentLayer} />}
      </div>
      {!initialized && _ADMIN_ && showLayers && <div className={classes.loadingModal}><p>Loading...</p></div>}

      {/* Checking for rasterLoading in the CAD View page */}
      {(!rasterTab && rasterLoading && interactive) && editorFunctions && <div className={classes.loadingModal}><p>Loading...</p></div>}

      {/* Checking for rasterLoading in the project details page */}
      {(rasterTab && (rasterLoading || lasBBoxLoading) && interactive && !fileErrors) && <div className={classes.loadingModal}><p>Loading...</p></div>}

      {(!dashboard && interactive && !opsTrainer && !_ADMIN_) && <MapControls showLayers={showLayers} />}
      {interactive && <MapStyleToggle />}
    </>
  );
});

const CreateMap = (mode: 'details' | 'layers' | 'sidebar' | 'dashboard') => {
  const map = ReactMapboxGl({
    accessToken: MAPBOX_KEY,
    interactive: mode !== 'sidebar',
    maxZoom: 24,
  });

  return () => <MapWrapper map={map} mode={mode} />;
};

export default CreateMap;
