// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { withStyles, WithStyles } from '@material-ui/core';

import styles from './styles';

interface IOwnProps {
  showSubscriptionDialog: boolean;
  setShowSubscriptionDialog: (visible: boolean) => void;
  cadSubscriptionExists: boolean;
}

type PropsType =
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
);
