// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const GetTemplatesAsync = createAsyncAction(
  'GET_TEMPLATES_START',
  'GET_TEMPLATES_SUCCESS',
  'GET_TEMPLATES_FAILED',
)<null, ICadTemplate[], string>();

export const SetTemplateIdAction = createAction('SET_TEMPLATE_ID', (action) => (data: string) => action(data));

export const DeleteTemplateAsync = createAsyncAction(
  'DELETE_TEMPLATE_START',
  'DELETE_TEMPLATE_SUCCESS',
  'DELETE_TEMPLATE_FAILED',
)<string, string, string>();

export const EditTemplateAsync = createAsyncAction(
  'EDIT_TEMPLATE_START',
  'EDIT_TEMPLATE_SUCCESS',
  'EDIT_TEMPLATE_FAILED',
)<null, ICadTemplate, null>();

export const DuplicateTemplateAsync = createAsyncAction(
  'DUPLICATE_TEMPLATE_START',
  'DUPLICATE_TEMPLATE_SUCCESS',
  'DUPLICATE_TEMPLATE_FAILED',
)<null, ICadTemplate, null>();

export const SetSelectedStyleAction = createAction('SET_SELECTED_STYLE', (action) => (data: { templateId: string, layerId: string, event: string }) => action(data));

export const SetSelectedWeightAction = createAction('SET_SELECTED_WEIGHT', (action) => (data: { templateId: string, layerId: string, event: number }) => action(data));

export const SetSelectedColorAction = createAction('SET_SELECTED_COLOR', (action) => (data: { templateId: string, layerId: string, color: string }) => action(data));

export const SetSelectedMappingAction = createAction('SET_SELECTED_MAPPING', (action) => (data: { templateId: string, layerId: string, event: Array<string> }) => action(data));

export const AddTemplateAction = createAction('ADD_NEW_TEMPLATE', (action) => (data: ICadTemplate) => action(data));

export const GetDefaultTemplateAction = createAction('GET_DEFAULT_TEMPLATE', (action) => (data: string) => action(data));
