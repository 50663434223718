// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    margin: '0 auto',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    width: 500,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  formRow: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: theme.spacing(1),
  },
  submitButton: {
    boxShadow: 'none',
    width: '100%',
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.dark,
    boxShadow: 'none',
    marginTop: theme.spacing(4),
  },
  snackbarContent: {
    display: 'grid',
    gridGap: theme.spacing(),
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
  addIcon: {
    marginRight: theme.spacing(),
  },
  card: {
    minWidth: 275,
    height: '100px',
    width: '500px',
    backgroundColor: '#FFFFFF',
    boxShadow: '0 2px 12px 0 rgba(0,0,0,0.1)',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(12),
    paddingLeft: theme.spacing(2),
  },
}));

export default useStyles;
