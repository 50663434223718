// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, patchJson } from 'Utils/http';
import { API_URL } from 'Config';
import {
  GetGlobalStatusAsync,
  UpdateGlobalStatusAsync,
  GetOpsCreditCapacityAsync,
} from './actions';

export const GetGlobalStatus = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/globalStatus`;

  dispatch(GetGlobalStatusAsync.request());

  const { success, message, data } = await getJson<IGlobalStatusResponse>(url, token);
  if (!success) {
    dispatch(GetGlobalStatusAsync.failure(message));
    return {};
  }

  dispatch(GetGlobalStatusAsync.success(data.globalStatus));

  return data.globalStatus;
};

export const GetOpsCreditCapacity = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/globalStatus/dailyCreditCommitment`;

  dispatch(GetOpsCreditCapacityAsync.request());

  const response = await getJson<IOpsCapacityResponse>(url, token);

  if (!response.success) {
    dispatch(GetOpsCreditCapacityAsync.failure(response.message));
    return;
  }

  dispatch(GetOpsCreditCapacityAsync.success(response.data.creditsPerDay));
};

export const UpdateGlobalStatus = (allowExpeditedProcess: boolean) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/globalStatus`;

  dispatch(UpdateGlobalStatusAsync.request());

  const { success, message, data } = await patchJson<IGlobalStatusResponse>(url, {
    allowExpeditedProcess,
  }, token);

  if (!success) {
    dispatch(UpdateGlobalStatusAsync.failure(message));
    return;
  }

  dispatch(UpdateGlobalStatusAsync.success(data.globalStatus));
};
