// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';

import styles from './styles';

const mapStateToProps = (state: IState) => ({
  mapSearchLoading: state.map.geocode.mapSearchLoading,
});

interface IOwnProps {
  setSearchInputValue: (value: string) => void;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps),
);
