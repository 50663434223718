// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  planDetails: {
    fontSize: '12px',
    lineHeight: '18px',
    color: '#141B22',
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  boldDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    height: theme.spacing(0.5),
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(10),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  stripeGradient: {
    lineHeight: '8px',
    background: 'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    [theme.breakpoints.up('md')]: {
      maxWidth: '650px',
    },
  },
  cardTitle: {
    fontSize: '20px',
    marginBottom: theme.spacing(2),
  },
  calculation: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(2),
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  purchaseButton: {
    marginTop: theme.spacing(2),
    width: '100%',
    height: '50px',
    backgroundColor: '#01A1DD',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#01A1DD !important',
    },
  },
  promoSection: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  promoTag: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '1fr auto auto',
    backgroundColor: '#F8F8F8',
  },
  promoCode: {
    overflow: 'hidden',
  },
  promoCodeText: {
    fontWeight: 400,
    textTransform: 'capitalize',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: theme.spacing(0.5),
  },
  promoDiscount: {
    fontWeight: 400,
    color: '#01A1DD',
    padding: theme.spacing(0.5),
  },
  promoClose: {
    '&:hover': {
      backgroundColor: '#F8F8F8 !important',
    },
  },
  promoCheck: {
    color: '#01A1DD',
    margin: theme.spacing(0.5),
  },
});

export default styles;
