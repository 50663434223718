// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import classnames from 'classnames';
import { IconButton, Badge, Popper, Fade, Paper, Typography, ButtonBase, ClickAwayListener } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const NotificationsMenu = ({ list, newNotificationsCount, ToggleNotificationAction }: PropsType) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const clickOpenPopper = (e: any) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
    e.preventDefault();
  };

  return (
    <>
      <IconButton onClick={clickOpenPopper} aria-label="notifications">
        <Badge badgeContent={newNotificationsCount} color="primary">
          <NotificationsIcon />
        </Badge>
      </IconButton>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-start" transition className={classes.popper}>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.notificationsPanel}>
                <Typography variant="h2">Notifications</Typography>
                <div className={classes.notificationsList}>
                  { list.length > 0 ? list.map((notification) => {
                  // add classes for applying different styles to errors and warnings
                    const content = (
                      <div className={classnames(classes.notificationItem, { [classes.activeItem]: !notification.viewed }, { [classes.warningItem]: (!notification.viewed && notification.title === 'WARNING') },
                        { [classes.errorItem]: (!notification.viewed && notification.title === 'ERROR') })}
                      >
                        <Typography>{notification.message}</Typography>
                      </div>
                    );
                    return <ButtonBase className={classes.notificationCard} key={notification._id} onClick={() => ToggleNotificationAction(notification._id, !notification.viewed)}>{content}</ButtonBase>;
                  }) : <Typography>You have no notifications yet</Typography> }
                </div>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default compose(NotificationsMenu);
