// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@material-ui/core/';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const WarningDeliverableDialog = ({ showDialog, onOk, onCancel, titleText, questionText, okText, dependencyList, tifDependencyList, lasDependencyList }: PropsType) => {
  const classes = useStyles();

  return (
    <Dialog open={showDialog} onClose={onCancel} className={classes.dialog}>
      <div className={classes.dialogBox}>
        <div className={classes.dialogTitle}>
          <Typography variant="h3" className={classes.titleText}>{titleText}</Typography>
        </div>
        <DialogContent className={classes.dialogContent}>
          {dependencyList && dependencyList.map((dep) => (
            <>
              <Typography variant="body1" className={classes.dependency}>{dep}</Typography>
            </>
          ))}
          {tifDependencyList && tifDependencyList.length > 0 && (
            <>
              <Typography variant="body1" className={classes.dependencyTitle}>
                An orthomosaic(.tif) is required to process the following
              </Typography>
              {tifDependencyList.map((dep) => (
                <Typography variant="body1" className={classes.dependency}>{dep}</Typography>
              ))}
            </>
          )}
          {lasDependencyList && lasDependencyList.length > 0 && (
            <>
              <Typography variant="body1" className={classes.dependencyTitle}>
                A point cloud(.las or.laz) is required to process the following
              </Typography>
              {lasDependencyList.map((dep) => (
                <Typography variant="body1" className={classes.dependency}>{dep}</Typography>
              ))}
            </>
          )}
        </DialogContent>
        <Typography variant="h3" className={classes.titleText}>{questionText}</Typography>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={onOk} className={classes.okButton}>{okText}</Button>
          <Button onClick={onCancel}>Cancel</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default compose(WarningDeliverableDialog);
