// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  GetTemplatesAsync, AddTemplateAction, SetTemplateIdAction, DeleteTemplateAsync, EditTemplateAsync, DuplicateTemplateAsync, SetSelectedStyleAction, SetSelectedWeightAction, SetSelectedColorAction, SetSelectedMappingAction,
} from './actions';

const initialState: ICadTemplateState = {
  loading: false,
  templateId: null,
  list: [],
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(GetTemplatesAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetTemplatesAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.list = payload;
  });
  handleAction(GetTemplatesAsync.failure, (draft) => { draft.loading = false; });

  handleAction(SetTemplateIdAction, (draft, payload) => {
    draft.templateId = payload;
  });

  handleAction(DeleteTemplateAsync.request, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload);
    draft.list[index].deleting = true;
  });
  handleAction(DeleteTemplateAsync.success, (draft, payload) => { draft.list = draft.list.filter((p) => p._id !== payload); });
  handleAction(DeleteTemplateAsync.failure, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload);
    draft.list[index].deleting = false;
  });

  handleAction(AddTemplateAction, (draft, payload) => {
    draft.list.push(payload);
  });

  handleAction(EditTemplateAsync.request, (draft) => { draft.loading = true; });

  handleAction(EditTemplateAsync.success, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload._id);
    draft.list[index] = payload;
  });

  handleAction(EditTemplateAsync.failure, (draft) => { draft.loading = false; });

  handleAction(DuplicateTemplateAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.list.push(payload);
  });

  handleAction(DuplicateTemplateAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.list.push(payload);
  });

  handleAction(SetSelectedStyleAction, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload.templateId);
    const indexLayer = draft.list[index].layers.findIndex((p) => p._id === payload.layerId);
    draft.list[index].layers[indexLayer].style = payload.event;
  });

  handleAction(SetSelectedWeightAction, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload.templateId);
    const indexLayer = draft.list[index].layers.findIndex((p) => p._id === payload.layerId);
    draft.list[index].layers[indexLayer].weight = payload.event;
  });

  handleAction(SetSelectedColorAction, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload.templateId);
    const indexLayer = draft.list[index].layers.findIndex((p) => p._id === payload.layerId);
    draft.list[index].layers[indexLayer].color = payload.color;
  });

  handleAction(SetSelectedMappingAction, (draft, payload) => {
    const index = draft.list.findIndex((p) => p._id === payload.templateId);
    const indexLayer = draft.list[index].layers.findIndex((p) => p._id === payload.layerId);
    draft.list[index].layers[indexLayer].mapping = payload.event;
  });
});
