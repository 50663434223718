// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import {
  Button,
  Fade,
  CircularProgress,
  SnackbarContent,
  Collapse,
  Typography,
} from '@material-ui/core';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import { TextField, Validators, Helpers } from 'Common/forms';
import { Form } from 'react-final-form';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const AccountPassword = ({ updatePasswordMessage, PasswordUpdateAction }: PropsType) => {
  const classes = useStyles();

  const onFormSubmit = (values: any) => {
    Helpers.submit(values, PasswordUpdateAction);
  };

  return (
    <div className={classes.contentWrapper}>
      <Form
        onSubmit={onFormSubmit}
        render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, submitError, submitSucceeded, handleSubmit }) => (
          <form
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <Typography variant="h4">RESET PASSWORD</Typography>
            <TextField
              name="oldPassword"
              label="Current Password"
              type="password"
              validate={Validators.composeValidators(Validators.required, Validators.minLength7)}
            />
            <TextField
              name="password"
              label="New Password"
              type="password"
              validate={Validators.composeValidators(Validators.required, Validators.strongPassword)}
            />
            <TextField
              name="passwordConfirm"
              label="Confirm Password"
              type="password"
              validate={Validators.composeValidators(Validators.required, Validators.strongPassword, Validators.passwordsMatch)}
            />
            <Collapse in={(hasSubmitErrors && !modifiedSinceLastSubmit)}>
              <SnackbarContent
                className={classes.errorSnackbar}
                message={(
                  <div className={classes.snackbarContent}>
                    <ErrorIcon />
                    {submitError}
                  </div>
                )}
              />
            </Collapse>
            <Collapse in={!submitting && submitSucceeded}>
              <SnackbarContent
                className={classes.successSnackbar}
                message={(
                  <div className={classes.snackbarContent}>
                    <CheckCircleIcon />
                    {updatePasswordMessage}
                  </div>
                )}
              />
            </Collapse>
            <Button
              variant="outlined"
              color="primary"
              className={classes.submitButton}
              type="submit"
              disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
            >
              <Fade in={submitting}>
                <CircularProgress size={24} className={classes.buttonProgress} />
              </Fade>
              <Fade in={!submitting}>
                <span>Update Password</span>
              </Fade>
            </Button>
          </form>
        )}
      />
      <div className={classes.requirements}>
        <Typography className={classes.rheader} variant="h5">
          Your new password must contain:
        </Typography>
        <Typography className={classes.rdetails}>A minimum of 8 characters</Typography>
        <Typography className={classes.rdetails}>A number</Typography>
        <Typography className={classes.rdetails}>A lowercase letter and an uppercase letter</Typography>
        <Typography className={classes.rdetails}>
          A special character: ~`!@#$%^&*()_-+=
          {'{'}
          [
          {'}'}
          ]|\:;
          &quot;&apos;
          {'<'}
          ,
          {'>'}
          .?/
        </Typography>
      </div>
    </div>
  );
};

export default compose(AccountPassword);
