// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  videoWrapper: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    overflow: 'hidden',
    zIndex: -100,
    background: 'linear-gradient(134.72deg, #000000 0%, #05071A 49.93%, #18216D 100%)',
  },
  video: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0.5,
  },
  '@media (min-aspect-ratio: 16/9)': {
    video: {
      width: '100%',
      height: 'auto',
    },
  },
  '@media (max-aspect-ratio: 16/9)': {
    video: {
      width: 'auto',
      height: '100%',
    },
  },
  gridWrapper: {
    position: 'relative',
    height: '100%',
  },
  logo: {
    position: 'absolute',
    margin: theme.spacing(10),
    width: '17%',
    [theme.breakpoints.down('sm')]: {
      width: '15%',
    },
    zIndex: 1,
  },
  grid: {
    [theme.breakpoints.up('md')]: {
      height: '100%',
    },
    flexGrow: 1,
  },
  pageDescriptionWrapper: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(10),
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: theme.spacing(1) * 12,
      paddingBottom: theme.spacing(8),
    },
  },
  pageDescription: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  pageDescriptionText: {
    fontFamily: 'Work Sans',
    fontWeight: 300,
    fontSize: '42px',
    lineHeight: '52px',
    textShadow: '1px 1px 12px rgba(0, 0, 0, 0.6)',
    color: 'white',
  },
  form: {
    [theme.breakpoints.up('md')]: {
      maxWidth: '650px',
    },
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(5),
      marginRight: theme.spacing(5),
      marginBottom: theme.spacing(5),
    },
    display: 'block', // Fix IE 11 issue.
    position: 'absolute',
    top: 0,
    left: 0,
    minHeight: '100%',
    padding: theme.spacing(12),
    background: '#021422',
    border: 'white',
  },
  tabLabel: {
    color: 'white',
  },
  stripeGradient: {
    lineHeight: '8px',
    background: 'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    [theme.breakpoints.up('md')]: {
      maxWidth: '650px',
    },
  },
  displayChild: {
    paddingTop: theme.spacing(6),
  },
});

export default styles;
