// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Paper } from '@material-ui/core';
import compose from './container';
import useStyles from './styles';

export const Sidebar = ({ children, ...props }) => {
  const classes = useStyles();

  return (
    <Paper className={classes.sidebar} elevation={1} {...props}>
      {children}
    </Paper>
  );
};

export default compose(Sidebar);
