// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => {
  const { project } = state.project;
  const tifFileNames = project.tifFiles.map((tifFile) => (tifFile.filename.split('/').pop()));
  // eslint-disable-next-line no-nested-ternary
  const orgId = _ADMIN_ ? state.admin.orgId : 'opsTrainer' in state.auth.resources ? project.ownerOrganization : state.auth.user.ownerOrganization;
  return ({
    rasterTileJson: state.map.common.rasterTileJson,
    orgId,
    projectId: state.project.projectId,
    visible: !ownProps.showLayers || state.map.common.layers.orthomosaic,
    rasterLoading: state.map.common.rasterTileJsonLoading,
    changeRaster: state.map.common.changeRaster,
    tifFileNames,
  });
};

interface IOwnProps {
  showLayers: boolean;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(
    mapStateToProps,
    null,
    null,
    {
      areStatePropsEqual: (next, prev) => next.projectId === prev.projectId &&
        next.orgId === prev.orgId &&
        next.rasterLoading === prev.rasterLoading &&
        next.visible === prev.visible &&
        next.changeRaster === prev.changeRaster,
    },
  ),
);
