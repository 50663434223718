// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import { API_URL } from 'Config';
import LayerDefinitions from 'Common/layers';
import VectorLayer from './VectorLayer';
import ElevationLayer from './ElevationLayer';
import compose from './container';
import { SetClickedPointAction } from 'State/map/editor/actions';
import { LayersRounded } from '@material-ui/icons';

/**
 * Component renders the vector layers for different layers in the dxf file, along with elevation labels for G-TOPO-MAJR and G-TOPO-MINR
 *
 * @param {object} props Component props
 * @param {object} props.vectorTileJson vectorTileJson object to access the layers for each order
 * @param {boolean} props.showLayers Boolean variable to identify CAD view tab
 * @param {string} props.orgId Org Id of the current user
 * @param {string} props.projectId Project Id of the current project
 * @param {array} props.orderIds List of order Ids of orders under the project with projectId
 * @param {object} props.fileVersions Object in the state that stores current file version selected for each order
 */
const MapLayers = compose(({ vectorTileJson, showLayers, orgId, projectId, map, orderIds, fileVersions, layerSelected, currentLayer, dxfExtent }) => {
  useEffect(() => {
    if (_ADMIN_ && dxfExtent) {
      map.once('idle', () => {
        map.fitBounds([
          [dxfExtent[2], dxfExtent[3]], // southwestern corner of the bounds
          [dxfExtent[0], dxfExtent[1]], // northeastern corner of the bounds
        ], {
          padding: 20,
        });
      });
    }
  }, [dxfExtent]);

  const vectorReady =
    showLayers &&
    vectorTileJson;

  const OrderVectorLayers = (params: { orderId: string }) => {
    const fileVersion = fileVersions[params.orderId];
    const tileJson = fileVersion && vectorTileJson[params.orderId] && vectorTileJson[params.orderId][fileVersion];
    const sourceId = `default-${params.orderId}`;

    if (!tileJson || !tileJson.visible) return null;
    // Create an array of topo layers available for the current order
    const topoLayers = tileJson.vector_layers.filter(layer => (layer.id === 'G-TOPO-MAJR' || layer.id === 'G-TOPO-MINR'));
    return (
      <>
        {/* Create an elevation label layer for each of the available topo layers for this order */}
        {/* {Object.keys(topoLayers).length > 0 &&
          topoLayers.map(layer => (
            <ElevationLayer
              orderId={params.orderId}
              fileVersion = {fileVersion}
              layerId={layer.id}
            />
        ))} */}
        <Source
          id={sourceId}
          tileJsonSource={{
            type: 'vector',
            // bounds: tileJson.bounds,
            tiles: [`${API_URL}/tiles/${fileVersion}/{z}/{x}/{y}.mvt`],
          }}
        />
        {tileJson.vector_layers.map((layer, index) => (
          <VectorLayer
            id={`default-${params.orderId}-${layer.id}`}
            orderId={params.orderId}
            fileVersion={fileVersion}
            sourceId={sourceId}
            sourceLayerId={layer.id}
            color={layer.color}
            lineWidth={layer.lineWidth}
            layerSelected={layerSelected}
            map={map}
          />
        ))}
      </>
    );
  };

  return (
    <>
      {/* Layers order is important so we need to always add tiles layers before draw layers */}
      <Layer id="vectorStub" />
      {vectorReady && orderIds.map(orderId => <OrderVectorLayers orderId={orderId} />)}
    </>
  );
});

export default MapLayers;
