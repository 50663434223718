// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { WithStyles, withStyles } from '@material-ui/core';
import { SelectedAdditionalDeliverablesAndLayers } from 'State/bundle/thunk';
import { getOrders } from 'State/order/selectors';
import { SetDeliveryDateAction, SetDeliveryDaysAction } from 'State/bundle/actions';
import styles from './styles';

const mapStateToProps = (state: IState) => ({
  projectId: state.project.projectId,
  order: getOrders(state).activeOrder,
  bundleDeliverables: state.bundle.bundleDeliverables,
  additionalDeliverables: state.bundle.additionalDeliverables,
  selectedAdditionals: state.bundle.selectedAdditionals,
  adjustedOrderAcres: state?.bundle?.selectedBundle?.adjustedOrderAcres,
  currentBundleId: state.bundle?.selectedBundle?.bundleId,
  lowDensity: state.bundle?.density === 'low',
  isAdmin: state.account.user.role[0],
  availableCreditAcreage: state.account.org.availableCreditAcreage,
  costPerAcre: state.account.org.costPerAcre,
  availableBundles: state.bundle.availableBundles,
  deliveryDays: state.bundle.deliveryDays,
  loading: state.bundle.loading,
  bundleCost: state.bundle.totalCost,
  acreAdjustmentFactor: state.bundle?.selectedBundle?.acreAdjustmentFactor,
});

const mapDispatchToProps = {
  SelectedAdditionalDeliverablesAndLayersAction: SelectedAdditionalDeliverablesAndLayers,
  SetDeliveryDaysAction,
  SetDeliveryDateAction,
};

interface IOwnProps {
  setOrderFormVisible: (visible: boolean) => void;
  deliverySpeed: string;
  isSaved: boolean;
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  page: number;
  bundleStep: number;
  setBundleStep: (bundleStep: number) => void;
  manageAddOns: (deliverableId: number, type: string, acreFactor: number, checked: boolean) => void;
  addOnDeliverableDollars: number;
  addOnLayerDollars: number;
  zeroAddOns: () => void;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
