// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, List, ListItemIcon } from '@material-ui/core';
import HelpIcon from '@material-ui/icons/Help';
import CheckIcon from '@material-ui/icons/Check';
import compose from './container';

const IncludedAddOns = compose(
  ({ classes, bundleDeliverables, bundleName }) => (
    <>
      {bundleName === 'A la carte' ? (
        <div className={classes.infoBox}>
          <div className={classes.infoTitle}>
            <HelpIcon className={classes.icon} />
            <Typography variant="h3">A la carte Bundle</Typography>
          </div>
          <Typography variant="body1" className={classes.infoText}>
            If you only want a select few layers on your .dxf, select this option.
            Next, you'll be able to pick which specific layers you want. A-la-carte layer selection enables us to deliver your project faster.
          </Typography>
        </div>
      ) : (
        <div className={classes.list}>
          <div className={classes.insideDiv}>
            <Typography variant="h4">Layers</Typography>
            {bundleDeliverables.map(({ type, deliverableId, name }) =>
              (type === 'layer' ? (
                <List className={classes.bundleListItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CheckIcon className={classes.finishedIcon} />
                  </ListItemIcon>
                  {deliverableId === 20 && bundleName === '3D Feature Elevation'
                    ? 'Building Footprints'
                    : name}
                </List>
              ) : null))}
          </div>
          <div className={classes.insideDiv}>
            <Typography variant="h4">Deliverables</Typography>
            {bundleDeliverables.map((item) =>
              (item.type === 'deliverable' ? (
                <List className={classes.bundleListItem}>
                  <ListItemIcon className={classes.listItemIcon}>
                    <CheckIcon className={classes.finishedIcon} />
                  </ListItemIcon>
                  {item.name}
                </List>
              ) : null))}
          </div>
        </div>
      )}
    </>
  )
);

export default IncludedAddOns;
