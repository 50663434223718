// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { GetGlobalStatus, UpdateGlobalStatus, GetOpsCreditCapacity } from 'State/globalStatus/thunk';

const mapStateToProps = (state: IState) => ({
  allowExpeditedProcess: state.globalStatus.status.allowExpeditedProcess,
  dailyCreditCommitment: state.globalStatus.status.dailyCreditCommitment,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetGlobalStatusAction: GetGlobalStatus,
    GetOpsCreditCapacityAction: GetOpsCreditCapacity,
    UpdateGlobalStatusAction: UpdateGlobalStatus,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
