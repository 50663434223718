// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import Sidebar from 'Common/Sidebar';
import { Typography, Divider, Checkbox, FormControlLabel, Collapse } from '@material-ui/core';
import compose, { PropsType } from './container';
import CadLayers from './CadLayers';
import useStyles from './styles';

export const Viewer3DSidebar = ({ lasFilesWith3DTile, order3DCadFilesList, selected3DCadFiles, showPointCloud, showTerrain, cad3dLayers, setShowPointCloud, setShowTerrain, Toggle3DOrderAction, Toggle3DLayerAction, SetLayer3DChangeAction }: PropsType) => {
  const classes = useStyles();
  const orders = Object.keys(order3DCadFilesList);

  const toggleOrder = (checked: boolean, orderId: string) => {
    Toggle3DOrderAction(orderId);
    const site = selected3DCadFiles[orderId];
    if (site) {
      const { siteId } = site;
      const layerChange: (string|boolean)[] = [];
      cad3dLayers[siteId]?.layers?.forEach((layer) => {
        layerChange.push(`${siteId}-${layer.layer_id}`, checked && layer.visible);
      });
      SetLayer3DChangeAction(layerChange);
    }
  };

  const toggleLayer = (checked: boolean, siteId: string, layerId: number) => {
    Toggle3DLayerAction({ siteId, layerId });
    const layerChange: (string|boolean)[] = [];
    layerChange.push(`${siteId}-${layerId}`, checked);
    SetLayer3DChangeAction(layerChange);
  };

  return (
    <Sidebar>
      <div className={classes.sidebarContent}>
        <Typography variant="h5" className={classes.title}>LAYERS</Typography>
        {orders.map((orderId, index) => (
          <div className={classes.layerGroup} key={orderId}>
            <FormControlLabel
              label={(
                <div className={classes.checkBoxTitle}>
                  <Typography variant="h4">{`CAD ${orders.length - index}`}</Typography>
                </div>
              )}
              control={(
                <Checkbox
                  color="primary"
                  onChange={(e) => toggleOrder(e.target.checked, orderId)}
                  checked={selected3DCadFiles[orderId].visible}
                  classes={{
                    root: classes.checkbox,
                  }}
                />
              )}
            />
            <Collapse in={selected3DCadFiles[orderId].visible}>
              <CadLayers orderId={orderId} toggleLayer={toggleLayer} />
            </Collapse>
            <Divider className={classes.divider} />
          </div>
        ))}
        <div className={classes.layers}>
          <FormControlLabel
            control={(
              <Checkbox
                checked={showPointCloud}
                onChange={() => setShowPointCloud(!showPointCloud)}
                name="pointCloud"
                color="primary"
              />
            )}
            label={<Typography variant="h5" className={classes.layerName}>Point Cloud</Typography>}
          />
          <div className={classes.lasfileContainer}>
            {lasFilesWith3DTile.map((las) => (
              <Typography variant="h5" className={classes.lasfile} key={las._id}>{las.name}</Typography>
            ))}
          </div>
          <FormControlLabel
            control={(
              <Checkbox
                checked={showTerrain}
                onChange={() => setShowTerrain(!showTerrain)}
                name="terrain"
                color="primary"
              />
            )}
            label={<Typography variant="h5" className={classes.layerName}>Terrain</Typography>}
          />
        </div>
      </div>
    </Sidebar>
  );
};

export default compose(Viewer3DSidebar);
