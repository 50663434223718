// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  GetSelectedCadFile3DLayersAysnc, Toggle3DLayerAction, Toggle3DOrderAction,
  Get3DLineworkAction, SetLayer3DChangeAction, Clear3DLineworkAction,
  SetOrder3DCadFilesListAction, SetSelectedCadFile3DLayersAction, SetCad3DLayersAction,
} from './actions';

const initialState: IViewer3DState = {
  order3DCadFilesList: null,
  selected3DCadFiles: {},
  cad3dLayers: {},
  layersLoading: false,
  linework3D: [],
  layer3DChange: [],
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(SetOrder3DCadFilesListAction, (draft, payload) => {
    draft.order3DCadFilesList = payload;
  });

  handleAction(SetSelectedCadFile3DLayersAction, (draft, payload) => {
    const { orderId, cadFile3DLayers } = payload;
    draft.selected3DCadFiles[orderId] = cadFile3DLayers;
  });

  handleAction(SetCad3DLayersAction, (draft, payload) => {
    draft.cad3dLayers = payload;
  });

  handleAction(GetSelectedCadFile3DLayersAysnc.request, (draft) => { draft.layersLoading = true; });
  handleAction(GetSelectedCadFile3DLayersAysnc.success, (draft, payload) => {
    draft.layersLoading = false;
    const { orderId, cadFile3DLayers } = payload;
    draft.selected3DCadFiles[orderId] = cadFile3DLayers;
  });
  handleAction(GetSelectedCadFile3DLayersAysnc.failure, (draft) => { draft.layersLoading = false; });

  handleAction(Toggle3DLayerAction, (draft, payload) => {
    const { siteId, layerId } = payload;
    const layer = draft.cad3dLayers[siteId]?.layers?.find((l: any) => l.layer_id === layerId);
    if (layer) {
      layer.visible = !layer.visible;
    }
  });

  handleAction(Toggle3DOrderAction, (draft, payload) => {
    const order = draft.selected3DCadFiles[payload];
    order.visible = !order.visible;
  });

  handleAction(Get3DLineworkAction.success, (draft, payload) => {
    payload.forEach((site) => {
      const { site_id } = site;
      if (draft.cad3dLayers[site_id]) {
        draft.cad3dLayers[site_id].dataAvailable = true;
      }
    });
    draft.linework3D = payload;
  });

  handleAction(Clear3DLineworkAction, (draft) => {
    draft.linework3D = [];
  });

  handleAction(SetLayer3DChangeAction, (draft, payload) => {
    draft.layer3DChange = payload;
  });
});
