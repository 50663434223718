// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { DeleteProjectAdmin } from 'State/admin/thunk';

const mapStateToProps = (state: IState) => ({
  // when get projects from server, projects are sorted by updatedAt
  list: state.admin.orgProjects,
  loading: state.admin.loading,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    DeleteProjectAction: DeleteProjectAdmin,
  }, dispatch);

interface IOwnProps {
  orgId?: string;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
