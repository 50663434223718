// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography } from '@material-ui/core';
import { WideLightTooltip } from 'Common/Tooltip';
import compose from './container';

const RadioButtonLabel = compose(({ classes, bundle, density }) => {
  const { acreAdjustmentFactor, name, bundleId } = bundle;
  return (
    <>
      <div className={classes.buttonLabelContainer}>
        <div className={classes.bundleNameContainer}>
          <Typography variant="h4" className={classes.bundleName}>{name}</Typography>
          {name === '3D Feature Elevation' && (
            <WideLightTooltip
              placement="top"
              interactive
              title={(
                <div>
                  This bundle is currently in beta. There might be adjustments made to turnaround time and add-on layers in the near future. All layers in this bundle are returned in 3D by default. Please select 2D + Topo if you don’t require feature elevation
                  Read more about the 3D Feature Elevation offering
                  <a href="https://awkb.scrollhelp.site/airworksknowledgebase/Feature-Elevation-Bundle" className={classes.anchorTag} target="_blank" rel="noreferrer"> here. </a>
                </div>
              )}
            >
              <div className={classes.betaBanner}>
                BETA
              </div>
            </WideLightTooltip>
          )}
          {bundleId === 5 && (
            <div className={classes.betaBanner}>
              NEW
            </div>
          )}
        </div>
        {bundleId !== 5 && (
          <Typography variant="h6">
            {`1 credit = ${acreAdjustmentFactor.length === 1 || density === 'normal' ? acreAdjustmentFactor[0] : acreAdjustmentFactor[1]} ${name}`}
          </Typography>
        )}
      </div>
      {bundleId === 5 && (
        <Typography variant="body2" className={classes.bundleInfoText}>
          Select this option if you only want specific layers, not all of the ones offered in a base package
        </Typography>
      )}
    </>
  );
});

export default RadioButtonLabel;
