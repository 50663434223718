// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapper: {
      position: 'relative',
      marginBottom: theme.spacing(1),
      border: '1px transparent solid',
      display: 'flex',
      justifyContent: 'space-between',
    },
    priceWrapper: {
      width: '100px',
    },
    dateWrapper: {
      width: '200px',
    },
    primary: {
      borderColor: theme.palette.primary.main,
    },
  }));

export default useStyles;
