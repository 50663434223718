// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson } from 'Utils/http';
import { API_URL } from 'Config';
import {
  GetInvoiceAsync,
  ListInvoicesAsync,
} from './actions';

export const GetInvoice = (invoiceId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/invoices/${invoiceId}`;
  const { token } = getState().auth;

  dispatch(GetInvoiceAsync.request());

  const { success, message, data } = await getJson<IInvoiceResponse>(url, token);
  if (!success) {
    dispatch(GetInvoiceAsync.failure(message));
    return;
  }

  dispatch(GetInvoiceAsync.success(data.invoice));
};

export const ListInvoices = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const { subscriptionId } = getState().subscription.cadSubscription;

  const url = `${API_URL}/listInvoices/${subscriptionId}`;

  dispatch(ListInvoicesAsync.request());

  const response = await getJson<IInvoiceListResponse>(url, token);

  if (!response.success) {
    dispatch(ListInvoicesAsync.failure(response.message));
    return;
  }

  dispatch(ListInvoicesAsync.success(response.data.invoices));
};
