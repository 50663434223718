// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core';
import { ForgotPassword } from 'State/auth/thunk';

import { SendForgotPasswordLink } from 'State/auth/actions';
import styles from './styles';

const mapStateToProps = (state: IState) => ({
  forgotPasswordLinkSend: state.auth.forgotPasswordLinkSend,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    ForgotPasswordAction: ForgotPassword,
    SendForgotPasswordLinkAction: SendForgotPasswordLink,
  }, dispatch);

type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithStyles<typeof styles> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
