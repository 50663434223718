// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Collapse, Divider, ListItem, Select, MenuItem, InputBase, Typography } from '@material-ui/core';
import aiIcon from 'Assets/ai.png';
import CadDrawingsCheckbox from './CadDrawingsCheckbox';
import CadDrawingsButtons from './CadDrawingsButtons';
import ProjectLayersList from './ProjectLayersList';
import DownloadPanel from './DownloadPanel';
import compose from './container';

const CadDrawings = compose(({ classes, index, order, orderId, layers, projectAccessUS, projectAccessIndia, opsTrainer,
  SetFileVersionAction, GetVectorTilesAction, ChangeVersionAction, ResetDrawSourceAction, SetlayerSelectedAction }) => {
  const [expand, setExpand] = useState(true);
  const [checked, setChecked] = useState(true);

  const orderAllowed = (projectAccessUS || projectAccessIndia || order.orderStatus === 0 || order.orderStatus === 7);
  let orderDisplayed = orderAllowed;
  const toggleExpand = () => {
    setExpand(!expand);
  };

  const toggleChecked = () => {
    setChecked(!checked);
  };

  // Check if there are files in the cadFiles. If there's none present, display 'Original file;
  const files = order.cadFiles.map((cadFile) => {
    const { _id, s3Url, filename, expressAIUpload, expressAIEmailSent } = cadFile;
    const fileName = opsTrainer ? s3Url?.split('/').pop() : (filename?.split('/').pop() || 'Original file');
    return ({ fileName, siteId: _id, expressAI: (expressAIUpload && expressAIEmailSent), visible: (orderAllowed || (expressAIUpload && expressAIEmailSent)) });
  });

  const visibleFiles = files.filter((f) => f.visible);

  const defaultFile = visibleFiles[visibleFiles.length - 1];
  const [fileName, setFileName] = useState(defaultFile && defaultFile.fileName);
  const [siteId, setSiteId] = useState(defaultFile && defaultFile.siteId);
  const [expressAI, setExpressAI] = useState(defaultFile && defaultFile.expressAI);

  const [mostRecentSurfaceFile] = useState(order.surfaceFiles?.reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}));
  const [mostRecentLasFile] = useState(order.outputLasFiles?.filter((file) => file.autonomous === false).reduce((prev, current) => ((+prev.createdAt > +current.createdAt) ? prev : current), {}).filename?.split('/').pop());

  if (files.some((f) => f.expressAI)) {
    orderDisplayed = true;
  }

  useEffect(() => {
    SetFileVersionAction(orderId, siteId);
  }, []);

  const onFileChange = (e: any) => {
    const siteId = e.target.value;
    const file = files.find((o) => o.siteId === siteId);
    const { fileName, expressAI } = file;
    setFileName(fileName);
    setSiteId(siteId);
    setExpressAI(expressAI);
    SetFileVersionAction(orderId, siteId);
    ResetDrawSourceAction();
    SetlayerSelectedAction(null);
    GetVectorTilesAction();
    ChangeVersionAction();
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const popperRef = React.createRef<HTMLDivElement>();
  const clickOpenPopper = () => {
    setAnchorEl(anchorEl ? null : popperRef.current);
  };

  return (
    <>
      <ListItem button className={classes.expansionPanelHeader} ref={popperRef}>
        <CadDrawingsCheckbox index={index} visible={layers ? layers.visible : false} order={order} {...{ checked, toggleChecked, orderDisplayed }} />
        {(expressAI && orderDisplayed) && (
          <img className={classes.expressIconImg} src={aiIcon} alt="Express AI Upload" />
        )}
        {orderDisplayed ? <CadDrawingsButtons {...{ expand, checked, toggleExpand, siteId, orderId, layers, clickDownload: clickOpenPopper }} />
          : <Typography variant="h6">Processing</Typography>}
      </ListItem>
      {orderDisplayed && (
        <>
          {checked && (
            <Select
              input={<InputBase />}
              value={fileName}
              renderValue={(filename) => <>{filename}</>}
              onChange={(e) => onFileChange(e)}
              className={classes.select}
            >
              {files.filter((f) => f.visible).map((file) => (
                <MenuItem value={file.siteId}>{file.fileName}</MenuItem>
              ))}
            </Select>
          )}
          {mostRecentLasFile && (
            <Typography className={classes.fileName}>
              <span className={classes.dot}>&#183;</span>
              {mostRecentLasFile}
            </Typography>
          )}
          {mostRecentSurfaceFile.filename && (
            <Typography className={classes.fileName}>
              <span className={classes.dot}>&#183;</span>
              {mostRecentSurfaceFile.filename}
            </Typography>
          )}
          {(layers && expand) && (
            <Collapse in={expand}>
              <ProjectLayersList order={order} layers={layers} siteId={siteId} expressAIInfo={expressAI} />
            </Collapse>
          )}
          <DownloadPanel {...{ anchorEl, open, order, fileName, siteId, setAnchorEl, surfaceFile: mostRecentSurfaceFile, lasFilename: mostRecentLasFile }} />
        </>
      )}
      <Divider />
    </>
  );
});

export default CadDrawings;
