// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrders } from 'State/order/selectors';
import { Get3DLinework } from 'State/viewer3D/thunk';
import { SetLayer3DChangeAction, SetSelectedCadFile3DLayersAction } from 'State/viewer3D/actions';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => {
  const { ordersWithCadFiles } = getOrders(state);
  const currentOrder = ordersWithCadFiles.find((order) => order._id === ownProps.orderId);
  const cadFiles = currentOrder?.cadFiles;
  const { order3DCadFilesList, cad3dLayers } = state.viewer3D;

  return {
    projectId: currentOrder?.ownerProject,
    order3DCadFilesList,
    ordersWithCadFiles,
    cadFiles,
    cad3dLayers,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    SetLayer3DChangeAction,
    SetSelectedCadFile3DLayersAction,
    Get3DLineworkAction: Get3DLinework,
  }, dispatch);

interface IOwnProps {
  orderId: string;
  toggleLayer: (checked: boolean, siteId: string, layerId: number) => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
