// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { SetProjectId, GetProject } from 'State/project/thunk';
import { Init3DViewer } from 'State/viewer3D/thunk';
import { Clear3DLineworkAction } from 'State/viewer3D/actions';

const mapStateToProps = (state: IState) => {
  const lasFilesWith3DTile = state.project.project?.lasFiles?.reduce((filtered, lasFile) => {
    if (lasFile.tile3DUrl) {
      filtered.push({ name: lasFile.filename.split('/').pop(), ...lasFile });
    }
    return filtered;
  }, []);
  return {
    lasFilesWith3DTile,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    SetProjectIdAction: SetProjectId,
    GetProjectAction: GetProject,
    Init3DViewerAction: Init3DViewer,
    Clear3DLineworkAction,
  }, dispatch);

interface IRouterProps {
  id: string;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<IRouterProps>;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
