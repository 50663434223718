// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { GetEpsgProjections } from 'State/project/thunk';
import proj4 from 'proj4';
import { ResetLatAndLng } from 'State/map/common/thunk';
import { getProjectEPSGCode } from 'State/project/selectors';

const mapStateToProps = (state: IState) => {
  const epsg = getProjectEPSGCode(state);
  const { epsgAuthName } = state.project;
  const projectionData = state.project.epsgProjections && state.project.epsgProjections;
  const projCSStart = projectionData && projectionData.search('PROJCS');
  const projCS = projCSStart === 0 ? projectionData.match(/"([^"]+)"/)[1] : 'Unavailable';
  const geogCSStart = projectionData && projectionData.search('GEOGCS');
  const geogCS = geogCSStart && geogCSStart !== -1 ? projectionData.substring(geogCSStart).match(/"([^"]+)"/)[1] : 'Unavailable';
  const datumStart = projectionData && projectionData.search('DATUM');
  const datum = datumStart && datumStart !== -1 ? projectionData.substring(datumStart).match(/"([^"]+)"/)[1].replace(/_/g, ' ') : 'Unavailable';
  const lat = +state.map.common.latitude;
  const lng = +state.map.common.longitude;
  const coordinates = state.project.firstProjection && state.project.secondProjection && lat && lng && proj4(state.project.firstProjection, state.project.secondProjection, [lng, lat]);
  const xCoordinate = coordinates && coordinates[0].toFixed(3);
  const yCoordinate = coordinates && coordinates[1].toFixed(3);

  return ({
    lat,
    lng,
    epsgCode: epsg?.tifEpsg || epsg?.lasEpsg,
    epsgAuthName,
    projCS,
    geogCS,
    datum,
    xCoordinate,
    yCoordinate,
  });
};

const mapDispatchToProps = {
  GetEpsgProjectionsAction: GetEpsgProjections,
  ResetLatAndLngAction: ResetLatAndLng,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
