// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';
import { Card, CardActionArea, CardActions, CardMedia, Typography, IconButton, LinearProgress, Button, Box, Avatar, Stepper, Step, StepLabel, StepConnector } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Brightness1Icon from '@material-ui/icons/Brightness1';
import ProjectPreview from 'Assets/projectPreview.png';
import { GetProjectDetailsRoute, GetProjectFilesRoute } from 'Utils/routes';
import { StepIconProps } from '@material-ui/core/StepIcon';
import { withStyles } from '@material-ui/core/styles';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');

/**
 * Component renders contents of project card on the dashboard *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {boolean} props.hasNotification Boolean to indicate if there is a unacknowledged notifcation for this project
 * @param {boolean} props.isInProgress Boolean to indicate if this project card needs to show the pizza tracker
 * @param {boolean} props.cannotDelete Boolean to indicate if this project is deletable or not
 * @param {boolean} props.orderEstimate Boolean to indicate is an estimate
 * @param {number} props.orderStatus Status of order
 * @param {boolean} props.opsTrainier Boolean to indicate if the user has ops training role
 * @param {number} props.placed Number to indicate if this project has a placed order(number is used for the pizza tracker)
 * @param {number} props.autonomous Number to indicate if this project has an order that is currently processing(number is used for the pizza tracker)
 * @param {number} props.cleaning Number to indicate if this project has an order that is currently being manually drafted(number is used for the pizza tracker)
 * @param {number} props.complete Number to indicate if this project has an order that is done and delivered(number is used for the pizza tracker)
 * @param {function} props.DeleteProjectAction Function to delete the project when user clicks on the delete icon
 * @param {function} props.SetProjectIdAction Function to set projectId in the state as the id of the project card clicked
 * @param {boolean} props.processing Boolean to indicate if the user has processing access
 */

export const ProjectItem = ({ project, expressAIReady, projectDetailsTab, placed, isInProgress, autonomous, cleaning, complete, orderFinalized, orderEstimate, orderStatus, cannotDelete, opsTrainer, DeleteProjectAction, SetProjectIdAction, GetThumbnailAction }: PropsType) => {
  const classes = useStyles();
  // check project details resource as well as orderStatus not equal to 7 then navigate user to project details tab, else cad view tab
  let projectUrl = projectDetailsTab ? GetProjectDetailsRoute(project._id) : GetProjectFilesRoute(project._id);

  if (orderFinalized || expressAIReady) {
    projectUrl = GetProjectFilesRoute(project._id);
  }

  const thumbnail = !project.thumbnail || project.thumbnail.includes('undefined');

  useEffect(() => {
    if (!thumbnail) {
      GetThumbnailAction(project._id);
    }
  }, []);

  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);

  const ref = useRef<any>(null);

  const [hovered, setHovered] = useState(false);

  const enter = () => {
    SetProjectIdAction(project._id);
    setHovered(true);
  };
  const leave = () => {
    SetProjectIdAction(null);
    setHovered(false);
  };

  useEffect(() => {
    if (ref && ref.current) {
      ref.current.addEventListener('onmouseenter', enter);
      ref.current.addEventListener('onmouseleave', leave);
    }
    return () => {
      if (ref && ref.current) {
        ref.current.removeEventListener('onmouseenter', enter);
        ref.current.removeEventListener('onmouseleave', leave);
      }
    };
  }, [ref]);

  const initials = project.creator.match(/\b\w/g) || [];

  const deleteProjectClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowDeleteConfirmation(true);
  };

  const deleteCancelClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowDeleteConfirmation(false);
  };

  const deleteConfirmClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    setShowDeleteConfirmation(false);
    DeleteProjectAction(project._id);
  };

  const contactSupportClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    window.open('mailto:support@airworks.io', '_blank');
  };

  const ProjectItemIcons = () => (
    <div className={classes.projectItemIcons}>
      { !cannotDelete && (
        <IconButton
          className={classNames(classes.deleteIcon, { [classes.activeIcon]: showDeleteConfirmation })}
          title="Delete Project"
          onClick={deleteProjectClick}
        >
          <DeleteIcon />
        </IconButton>
      ) }
      <Avatar className={classes.avatar} title={project.creator}>{initials.join('').toLowerCase()}</Avatar>
    </div>

  );

  const DetailIcon = () => {
    if (orderEstimate) {
      return (
        <div className={classes.detailsWrapper}>
          <Brightness1Icon className={classes.notificationIcon} fontSize="inherit" htmlColor="#ff9900" />
          <Typography className={classes.dateText} variant="subtitle2">
            Estimate
          </Typography>
        </div>
      );
    } if (orderStatus === 7) {
      return (
        <div className={classes.detailsWrapper}>
          <Brightness1Icon className={classes.notificationIcon} fontSize="inherit" htmlColor="#66ff66" />
          <Typography className={classes.dateText} variant="subtitle2">
            Completed
          </Typography>
        </div>
      );
    }
    return <div />;
  };

  const DeleteConfirmationControls = () => (
    <>
      <div className={classes.cardControls}>
        <div className={classes.cardTextWrapper}>
          <Typography variant="body1" className={classes.cardText}>
            <Box fontWeight="fontWeightBold">
              {'You\'re deleting this project.'}
            </Box>
          </Typography>
          <Typography variant="body1" className={classes.cardText}>
            {'This action can\'t be undone'}
          </Typography>
        </div>
        <Button className={classes.projectItemButton} title="Delete Project" onClick={deleteConfirmClick}>Delete</Button>
        <Button className={classes.projectItemButton} title="Cancel" onClick={deleteCancelClick}>Cancel</Button>
      </div>
    </>
  );

  const ColorlibConnector = withStyles({
    alternativeLabel: {
      top: 8,
      left: 'calc(-50% + 0px)',
      right: 'calc(50% + 0px)',
    },
    active: {
      '& $line': {
        backgroundImage:
          'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
      },
    },
    completed: {
      '& $line': {
        backgroundImage:
          'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
      },
    },
    line: {
      height: 4,
      border: 0,
      backgroundColor: '#797979',
      borderRadius: 1,
      width: '100%',
    },
  })(StepConnector);

  const ColorlibStepIcon = (props: StepIconProps) => {
    const { active, completed } = props;
    return (
      <div
        className={classNames(classes.root, {
          [classes.stepActive]: active,
          [classes.stepCompleted]: completed,
        })}
      />
    );
  };

  // Component for pizza tracker
  const ProgressTracker = () => {
    const ActiveLabels = ['Order placed', 'Processing', 'Checking for Quality', 'Done'];
    const steps = ['', '', '', ''];
    const activeStep = placed + autonomous + cleaning + complete;

    return (
      <div className={classes.progressPanel}>
        <Typography variant="h6" className={classes.labelText}>{ActiveLabels[activeStep]}</Typography>
        <div className={classes.progressStepperRoot}>
          <Stepper className={classes.stepper} activeStep={activeStep} alternativeLabel connector={<ColorlibConnector />}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
      </div>
    );
  };

  return (
    <>
      <div ref={ref} onMouseEnter={enter} onMouseLeave={leave} className={classes.cardWrapper}>
        {project.deleting && (
          <>
            <LinearProgress className={classes.progress} />
            <div className={classes.loadingBackdrop} />
          </>
        )}
        <Card className={classes.projectItem}>
          <RouterLink to={projectUrl} className={classes.routerLink} title="View Project">
            <CardActionArea>
              <CardMedia
                className={classes.media}
                image={thumbnail ? ProjectPreview : project.thumbnail}
              >
                <div className={
                  classNames(
                    classes.backdrop,
                    { [classes.standardBackdrop]: showDeleteConfirmation },
                    { [classes.successBackdrop]: isInProgress },
                  )
                }
                />
                <ProjectItemIcons />
                {showDeleteConfirmation ? <DeleteConfirmationControls /> : isInProgress ? <ProgressTracker /> :
                  (<Typography variant="h4" className={classes.label}>View Project</Typography>)}
              </CardMedia>
            </CardActionArea>
            <CardActions className={classes.cardActions}>
              <div className={classNames(classes.bottomBackdrop, { [classes.bottomSuccessBackdrop]: isInProgress })} />
              <div className={classes.nameText}>
                <Typography variant="h5">{ (opsTrainer ? project.training.nameEditable : project.name) || 'N/A'}</Typography>
              </div>
              <Typography className={classes.dateText} variant="body2">
                <div className={classes.details}>
                  {project.updatedAt && moment(project.updatedAt).format('LL')}
                  {DetailIcon()}
                </div>
              </Typography>
              {/* {isFailed && <ErrorIcon className={classes.errorIcon} />} */}
            </CardActions>
          </RouterLink>
        </Card>
      </div>
    </>
  );
};

export default compose(ProjectItem);
