// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import CreditCardIcon from 'Common/creditCardIcon';
import { Typography } from '@material-ui/core';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const PaymentConfirmation = ({ creditCards }: PropsType) => {
  const classes = useStyles();
  const card = creditCards.find((card) => card.defaultCard);

  return (
    <>
      <Typography className={classes.subSectionTitle} variant="h6">
        Billing Address
      </Typography>
      <Typography variant="body1">{card.name}</Typography>
      <Typography variant="body1">{card.address_line1}</Typography>
      <Typography variant="body1">{card.address_line2}</Typography>
      <Typography variant="body1">{`${card.address_city}, ${card.address_state} ${card.address_zip}`}</Typography>
      <Typography className={classes.subSectionTitle} variant="h6">
        Payment Information
      </Typography>
      <div className={classes.cardWrapper}>
        <div className={classes.iconWrapper}>
          <CreditCardIcon cardType={card.cardType} />
        </div>
        <div>
          <Typography variant="body1">{card.number}</Typography>
          <Typography variant="body1">{`Expires ${card.expDate}`}</Typography>
        </div>
      </div>
    </>
  );
};

export default compose(PaymentConfirmation);
