// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

const mapStateToProps = (state: IState) => ({
  cadSubscription: state.subscription.cadSubscription,
  automateSubscription: state.subscription.automateSubscription,
  subscriptionsLoading: state.subscription.subscriptionsLoading,
});

export type PropsType =
  ReturnType<typeof mapStateToProps>

export default compose<PropsType, {}>(
  connect(mapStateToProps),
);
