// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography } from '@material-ui/core/';
import ErrorIcon from '@material-ui/icons/Error';
import compose from './container';

const WarningDialog = compose(({ classes, showDialog, onOk, onCancel, fileStatuses, projectFiles }) => {
    const fileStatusesValid = fileStatuses && Object.keys(fileStatuses).length > 0;
    let messages: string[] = [];
    if (projectFiles.length > 0) {
        projectFiles.forEach((file: any) => {
            if(fileStatusesValid && fileStatuses[file.name] && fileStatuses[file.name].status === 'WARNING'){
                fileStatuses[file.name].messages.forEach((message) => {
                    messages.push(file.name + ': '+ message);
                })
            }
        })
    }
    return(
        <Dialog open={showDialog} onClose={onCancel} className={classes.dialog}>
            <div className={classes.dialogBox}>  
            <div className={classes.dialogTitle}>
                <ErrorIcon className={classes.warningIcon}/> 
                <Typography variant='h3' className={classes.titleText}>Warning(s) found for these files</Typography>
                <DialogActions>
                    <Button onClick={onCancel}>Close</Button>
                </DialogActions>
            </div>
            <DialogContent>
                {messages.map(m => (<Typography>{m}</Typography>))}
            </DialogContent>
            <DialogActions>
                <Button onClick={onOk} className={classes.okButton}>Ok, proceed to project</Button>
            </DialogActions>
            </div>
        </Dialog>
    );
});

export default WarningDialog;