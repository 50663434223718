// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { PasswordUpdate } from 'State/account/thunk';

const mapStateToProps = (state: IState) => ({
  updatePasswordMessage: state.account.updatePasswordMessage,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    PasswordUpdateAction: PasswordUpdate,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
