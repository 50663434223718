// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

interface IOwnProps {
    showDialog: boolean;
    onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    contentText?: string;
    titleText: string;
    questionText: string;
    okText: string;
    dependencyList?: any[];
    tifDependencyList?: any[];
    lasDependencyList?: any[];
}

export type PropsType =
    IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(null, null),
);
