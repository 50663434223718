// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import {
  Dialog,
  Typography,
  Button,
  DialogActions,
} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Moment } from 'moment';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const ImageryCaptureDialog = ({
  showDialog,
  provider,
  imageryCaptureDate,
  SetImageryCaptureDateAction,
  onCancel,
  onNext,
}: PropsType) => {
  const classes = useStyles();
  const [disableNext, setDisableNext] = useState(true);

  const onClose = () => {
    SetImageryCaptureDateAction(null);
    setDisableNext(true);
    onCancel();
  };

  const handleDateChange = (date: Moment) => {
    // eslint-disable-next-line no-unused-expressions
    date && SetImageryCaptureDateAction(date.format('YYYY-MM-DD'));
    setDisableNext(!date?.isValid() || date?.isAfter());
  };

  return (
    <Dialog
      open={showDialog}
    >
      <div className={classes.dialogBox}>
        <Typography variant="h5" className={classes.dialogText}>
          Imagery Capture Date
        </Typography>
        <Typography>
          Please add the capture date from
          {' '}
          {provider}
          {' '}
          for this file.
        </Typography>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <KeyboardDatePicker
            allowKeyboardControl={false}
            autoOk
            variant="inline"
            format="MM/DD/yyyy"
            value={imageryCaptureDate}
            onChange={handleDateChange}
            className={classes.datePicker}
            label="Imagery Capture"
            placeholder="Imagery Capture"
            disableToolbar
            disableFuture
            PopoverProps={{
              classes: {
                root: classes.calender,
              },
            }}
          />
        </MuiPickersUtilsProvider>
        <DialogActions className={classes.dialogActions}>
          <Button onClick={onClose} variant="text">
            Dismiss
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              onNext();
              onCancel();
            }}
            disabled={!imageryCaptureDate || disableNext}
          >
            Next
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default compose(ImageryCaptureDialog);
