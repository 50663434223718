// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Divider,
  Button,
  IconButton,
  Typography,
  TableHead,
  Table,
  TableRow,
  TableBody,
  TableCell,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SearchField from 'Common/SearchField';
import orderListIcon from 'Assets/order_list_icon.png';
import CreateProjectDialog from 'Common/CreateProjectDialog';
import ConfirmDialog from 'Common/ConfirmDialog';
import { GetAdminProjectRoute } from 'Utils/routes';
import LoadingSpinner from 'Common/LoadingSpinner';
import { GetOrderStatus } from 'Utils/orderStatus';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const ProjectsTable = ({ list, orgId, loading, DeleteProjectAction }: PropsType) => {
  const classes = useStyles();
  const [showDialog, setShowDialog] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [showConfirmDeleteProject, setShowConfirmDeleteProject] = useState(false);
  const [selectedProjectId, setSelectedProjectId] = useState(null);

  useEffect(() => {
    setProjectList(list);
  }, [list]);

  const handleSearchChange = (e: string): void => {
    if (!e) {
      setProjectList(list);
      return;
    }

    const filteredlist = list.filter(({ referenceId, name }) =>
      (referenceId && referenceId.toLowerCase().includes(e.toLowerCase())) ||
      (name && name.toLowerCase().includes(e.toLowerCase())));

    setProjectList(filteredlist);
  };

  const onOkDeleteProject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    DeleteProjectAction(selectedProjectId);
    e.stopPropagation();
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    setSelectedProjectId(null);
    e.stopPropagation();
  };

  return (
    <LoadingSpinner loading={loading}>
      <div className={classes.pageWrapper}>
        <div className={classes.headerWrapper}>
          <div className={classes.headerOptions}>
            <Typography variant="h2">Projects</Typography>
            <div className={classes.itemsWrapper}>
              <SearchField
                className={classes.icons}
                placeholder="Search"
                debounceTimeout={500}
                onChange={(e) => handleSearchChange(e)}
              />
            </div>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setShowDialog(true)}
            >
              New project
            </Button>
          </div>
          <Divider className={classes.headerDivider} />
        </div>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCell}>
                <Typography variant="h4">Project Name</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="h4">Reference Id</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="h4">Status</Typography>
              </TableCell>
              <TableCell className={classes.tableCell} align="right">
                <Typography variant="h4">Orders</Typography>
              </TableCell>
              <TableCell className={classes.iconTableCell} align="right" />
              {!_ADMIN_ && (
                <TableCell className={classes.tableCell} align="right" />
              )}
            </TableRow>
          </TableHead>
          {projectList?.length ? (
            <TableBody>
              {projectList.map((project: IAdminProject) => (
                <TableRow key={project._id}>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {project.name}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {project.referenceId || 'N/A'}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {project.orderCount === 0 ? 'N/A' : GetOrderStatus(project.status)}
                  </TableCell>
                  <TableCell
                    className={classes.tableCell}
                    component="th"
                    scope="row"
                  >
                    {project.orderCount}
                  </TableCell>
                  <TableCell className={classes.tableCell} component="th" scope="row">
                    <div className={classes.projectOptions}>
                      <RouterLink to={GetAdminProjectRoute(orgId, project._id)} className={classes.routerLink} title="View Project"><Typography variant="h6">View Project</Typography></RouterLink>
                      <IconButton
                        className={classes.deleteIcon}
                        title="Delete Project"
                        onClick={() => {
                          setSelectedProjectId(project._id);
                          setShowConfirmDeleteProject(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          ) : null}
        </Table>
        {projectList?.length === 0 ? (
          <div className={classes.noOrderContainer}>
            <img src={orderListIcon} alt="icon" />
            <Typography variant="h5">There are no projects created yet.</Typography>
          </div>
        ) : null}
      </div>
      <CreateProjectDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        getProjectUrl={(newProjectId) => GetAdminProjectRoute(orgId, newProjectId)}
      />
      <ConfirmDialog
        showDialog={showConfirmDeleteProject}
        onOk={onOkDeleteProject}
        onCancel={onCancel}
        contentText="Selected project will be deleted"
      />
    </LoadingSpinner>
  );
};

export default compose(ProjectsTable);
