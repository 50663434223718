// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import LoadingSpinner from 'Common/LoadingSpinner';
import Map3D from 'Common/Map3D';
import history from 'Utils/history';
import { GetProjectDetailsRoute } from 'Utils/routes';
import Sidebar from './Sidebar';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const Viewer3D = ({ match, lasFilesWith3DTile, SetProjectIdAction, GetProjectAction, Init3DViewerAction, Clear3DLineworkAction }: PropsType) => {
  const classes = useStyles();
  const { viewer3D } = useFlags();

  const [loading, setLoading] = useState(true);
  const [showPointCloud, setShowPointCloud] = useState(true);
  const [showTerrain, setShowTerrain] = useState(false);

  useEffect(() => {
    if (!viewer3D) {
      history.push(GetProjectDetailsRoute(match.params.id));
    } else {
      const onLoad = async () => {
        const projectId = match.params.id;
        SetProjectIdAction(projectId);
        await GetProjectAction();
        await Init3DViewerAction();
        setLoading(false);
      };
      onLoad();
    }
    return () => {
      SetProjectIdAction(null);
      Clear3DLineworkAction();
    };
  }, []);

  useEffect(() => {
    if (!loading && !lasFilesWith3DTile.length) {
      history.push(GetProjectDetailsRoute(match.params.id));
    }
  }, [loading, lasFilesWith3DTile]);

  return (
    <LoadingSpinner loading={loading}>
      <Sidebar lasFilesWith3DTile={lasFilesWith3DTile} showPointCloud={showPointCloud} setShowPointCloud={setShowPointCloud} showTerrain={showTerrain} setShowTerrain={setShowTerrain} />
      <div className={classes.map}>
        <Map3D lasFilesWith3DTile={lasFilesWith3DTile} showPointCloud={showPointCloud} showTerrain={showTerrain} />
      </div>
    </LoadingSpinner>
  );
};

export default compose(Viewer3D);
