// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const GetUserProfileAsync = createAsyncAction(
  'GET_USER_PROFILE_START',
  'GET_USER_PROFILE_SUCCESS',
  'GET_USER_PROFILE_FAILED',
)<null, IUser, string>();

export const UpdateUserProfileAsync = createAsyncAction(
  'UPDATE_USER_PROFILE_START',
  'UPDATE_USER_PROFILE_SUCCESS',
  'UPDATE_USER_PROFILE_FAILED',
)<null, IUserResponse, string>();

export const GetOrgProfileAsync = createAsyncAction(
  'GET_ORG_PROFILE_START',
  'GET_ORG_PROFILE_SUCCESS',
  'GET_ORG_PROFILE_FAILED',
)<null, IOrg, string>();

export const UpdateOrgProfileAsync = createAsyncAction(
  'UPDATE_ORG_PROFILE_START',
  'UPDATE_ORG_PROFILE_SUCCESS',
  'UPDATE_ORG_PROFILE_FAILED',
)<null, IOrgResponse, string>();

export const CreateOrgUserAsync = createAsyncAction(
  'CREATE_ORG_USER_START',
  'CREATE_ORG_USER_SUCCESS',
  'CREATE_ORG_USER_FAILED',
)<null, IOrgUser, string>();

export const GetOrgUsersAsync = createAsyncAction(
  'GET_ORG_USERS_START',
  'GET_ORG_USERS_SUCCESS',
  'GET_ORG_USERS_FAILED',
)<null, IOrgUser[], string>();

export const DeleteOrgUserAsync = createAsyncAction(
  'DELETE_ORG_USER_START',
  'DELETE_ORG_USER_SUCCESS',
  'DELETE_ORG_USER_FAILED',
)<string, string, string>();

export const EditOrgUserAsync = createAsyncAction(
  'EDIT_ORG_USER_START',
  'EDIT_ORG_USER_SUCCESS',
  'EDIT_ORG_USER_FAILED',
)<string, IUser, string>();

export const UpdatePasswordAsync = createAsyncAction(
  'UPDATE_PASSWORD_START',
  'UPDATE_PASSWORD_SUCCESS',
  'UPDATE_PASSWORD_FAILED',
)<null, string, null>();

export const GetOrgStatsAsync = createAsyncAction(
  'GET_USER_STATS_START',
  'GET_USER_STATS_SUCCESS',
  'GET_USER_STATS_FAILED',
)<null, IOrgStats, string>();

export const BuyAcresAction = createAction('BUY_ACRES', (action) => (data: number) => action(data));

export const DefaultTemplateAsync = createAsyncAction(
  'DEFAULT_TEMPLATE_START',
  'DEFAULT_TEMPLATE_SUCCESS',
  'DEFAULT_TEMPLATE_FAILED',
)<null, IOrg, null>();

export const GetOrgAssignableRolesAsync = createAsyncAction(
  'GET_ORG_ASSIGNABLE_ROLES_START',
  'GET_ORG_ASSIGNABLE_ROLES_SUCCESS',
  'GET_ORG_ASSIGNABLE_ROLES_FAILED',
)<null, any, null>();

export const GetOrgTeamsAsync = createAsyncAction(
  'GET_ORG_TEAMS_START',
  'GET_ORG_TEAMS_SUCCESS',
  'GET_ORG_TEAMS_FAILED',
)<null, ITeam[], null>();

export const DeleteOrgTeamAsync = createAsyncAction(
  'DELETE_ORG_TEAM_START',
  'DELETE_ORG_TEAM_SUCCESS',
  'DELETE_ORG_TEAM_FAILED',
)<null, string, null>();

export const UpdateOrgTeamAsync = createAsyncAction(
  'UPDATE_ORG_TEAM_START',
  'UPDATE_ORG_TEAM_SUCCESS',
  'UPDATE_ORG_TEAM_FAILED',
)<null, ITeam, null>();

export const AddOrgTeamAsync = createAsyncAction(
  'ADD_ORG_TEAM_START',
  'ADD_ORG_TEAM_SUCCESS',
  'ADD_ORG_TEAM_FAILED',
)<null, ITeam, null>();

export const ClearOrgAccount = createAction('CLEAR_ORG_ACCOUNT', (action) => () => action());
