// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { getOrders } from 'State/order/selectors';
import { SetKmlBBoxAction } from 'State/kml/actions';
import { PlaceOrder, RemoveEstimate } from 'State/order/thunk';
import { NearmapImageryCoverage } from 'State/imagery/thunk';
import { ToggleImageryProcessing } from 'State/project/thunk';
import { ResetImageryCoverageAction } from 'State/imagery/actions';

const mapStateToProps = (state: IState) => {
  const orders = getOrders(state);
  const { activeOrder, placedOrder } = orders;
  const kmlExists = !!(activeOrder?.boundaryFile);
  const { isEstimate } = activeOrder;
  const { project } = state.project;
  const { imageryRequest, name: projectName } = project;
  const { nearmapCoverageLoading, nearmapCoverage } = state.imagery;

  const acreage = orders?.activeOrder?.acreage;

  return {
    kmlExists,
    imageryRequest,
    projectName,
    isEstimate,
    activeOrder,
    placedOrder,
    bboxCoords: state.kml.present.bbox,
    activeOrderKml: state.kml.present.kmls[activeOrder?._id]?.featureCollection,
    acreage,
    nearmapCoverageLoading,
    nearmapCoverage,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    PlaceOrderAction: PlaceOrder,
    RemoveEstimateAction: RemoveEstimate,
    ResetImageryCoverageAction,
    SetKmlBBoxAction,
    NearmapImageryCoverage,
    ToggleImageryProcessing,
  }, dispatch);

interface IOwnProps {
  setShowCancelDialog: (show: boolean) => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
