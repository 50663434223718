// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  dialogWrapper: {
    margin: theme.spacing(2),
  },
  dialog: {
    maxWidth: '40%',
    height: '60%',
  },
  errorMessage: {
    minHeight: '3em',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
  dialogContent: {
    padding: '8px 12px',
  },
});

export default styles;
