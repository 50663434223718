// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { plans, products, tokens } from 'stripe';
import { Dispatch } from 'redux';
import nFormatter from 'Common/nFormatter';
import { getJson, postJson, patchJson, postForm } from 'Utils/http';
import { API_URL, STRIPE_URL, STRIPE_KEY } from 'Config';
import { currentSubscription } from 'State/subscription/selectors';
import { GetUserProfile, GetOrgProfile } from 'State/account/thunk';
import { BuyAcresAction } from 'State/account/actions';
import {
  CreateCustomerAsync,
  CreatePaymentInstrumentAsync,
  UpdateCreditCardAsync,
  GetSubscriptionPlansAsync,
  GetCreditCardsAsync,
  UpdateDefaultCardAsync,
  DeleteCreditCardAsync,
  SendTabValueAction,
  GetSubscriptionAsync,
  CreateSubscriptionAsync,
  GetProratedChargesAsync,
  EditSubscriptionAsync,
  CheckPromoCodeAsync,
  EndSubscriptionRenewalAsync,
  SetPromoCodeAction,
  ClearPromoCodeInfoAction,
} from './actions';

export const CreateCustomer = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/subscriptions/createCustomer`;
  const { token } = getState().auth;

  dispatch(CreateCustomerAsync.request());
  const result = await getJson(url, token);
};

export const CreatePaymentInstrument = (card: ICreditCard) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const cardTokenUrl = `${STRIPE_URL}/tokens`;
  const cardTokenData = {
    card: {
      name: card.name,
      number: card.number.replace(/\s/g, ''),
      exp_month: card.expDate.split('/')[0],
      exp_year: card.expDate.split('/')[1],
      cvc: card.cvv,
      address_line1: card.address_line1,
      address_line2: card.address_line2,
      address_city: card.address_city,
      address_state: card.address_state,
      address_zip: card.address_zip,
    },
  };

  dispatch(CreatePaymentInstrumentAsync.request());

  const creditCardTokenResult = await postForm<tokens.ICardToken>(
    cardTokenUrl,
    cardTokenData,
    STRIPE_KEY,
  );
  if (!creditCardTokenResult.success) {
    dispatch(
      CreatePaymentInstrumentAsync.failure(creditCardTokenResult.message),
    );
    return creditCardTokenResult;
  }

  const url = `${API_URL}/subscriptions/createPaymentInstrument`;
  const { token } = getState().auth;
  const data = { stripeToken: creditCardTokenResult.data.id };

  const createPaymentInstrumentResult = await postJson<ICreditCardResponse>(
    url,
    data,
    token,
  );
  if (!createPaymentInstrumentResult.success) {
    dispatch(
      CreatePaymentInstrumentAsync.failure(
        createPaymentInstrumentResult.message,
      ),
    );
  } else {
    const parsedResponse = parseCreditCardResponse(
      createPaymentInstrumentResult.data,
    );
    dispatch(CreatePaymentInstrumentAsync.success(parsedResponse));

    await UpdateDefaultCard(parsedResponse.id)(dispatch, getState);
  }
  return createPaymentInstrumentResult;
};

export const EditCreditCard = (card: ICreditCard) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/subscriptions/updateCardDetails`;
  const { token } = getState().auth;

  const result = await postJson(url, card, token);
  if (result.success) {
    dispatch(UpdateCreditCardAsync.success(card));
  }

  return result;
};

const parseCreditCardResponse = (creditCard: ICreditCardResponse) => {
  const expMonth = `0${creditCard.exp_month}`.slice(-2);
  const expYear = creditCard.exp_year;

  const result: ICreditCard = {
    name: creditCard.name,
    address_city: creditCard.address_city,
    address_line1: creditCard.address_line1,
    address_line2: creditCard.address_line2,
    address_state: creditCard.address_state,
    address_zip: creditCard.address_zip,
    number: `**** **** **** ${creditCard.last4}`,
    expDate: `${expMonth}/${expYear}`,
    cardType: creditCard.brand,
    id: creditCard.id,
    last4: creditCard.last4,
    defaultCard: creditCard.defaultCard,
  };

  return result;
};

export const GetCreditCards = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/subscriptions/getCards`;

  dispatch(GetCreditCardsAsync.request());

  const result = await getJson<ICreditCardResponse[]>(url, token);
  if (!result.success) {
    dispatch(GetCreditCardsAsync.failure(result.message));
    return;
  }

  const parsedResult = result.data.map(parseCreditCardResponse);
  dispatch(GetCreditCardsAsync.success(parsedResult));
};

export const GetPlans = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;

  dispatch(GetSubscriptionPlansAsync.request());

  const getPlansUrl = `${API_URL}/subscriptions/getPlans`;

  const plansResult = await getJson<plans.IPlan[]>(getPlansUrl, token);

  if (!plansResult.success) {
    dispatch(GetSubscriptionPlansAsync.failure(plansResult.message));
    return { success: false };
  }

  const result = plansResult.data.map((plan) => ({
    id: plan.id,
    nickname: plan.nickname,
    amount: plan.amount / 100,
    amountText: `$${nFormatter(plan.amount, 0)}`,
    planType: plan.interval as IPlanType,
  }));

  dispatch(GetSubscriptionPlansAsync.success(result));

  return { success: true };
};

export const UpdateDefaultCard = (cardId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/subscriptions/setDefaultCard`;
  const { token } = getState().auth;
  const data = { stripeToken: cardId };

  dispatch(UpdateDefaultCardAsync.request());

  const result = await postJson<string>(url, data, token);
  if (result.success) {
    dispatch(UpdateDefaultCardAsync.success(cardId));
  } else {
    dispatch(UpdateDefaultCardAsync.failure(result.message));
  }

  return result;
};

export const DeleteCreditCard = (cardId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/subscriptions/deleteCard`;
  const { token } = getState().auth;
  const data = { id: cardId };

  dispatch(DeleteCreditCardAsync.request());

  const result = await postJson<ICreditCardResponse[]>(url, data, token);
  if (!result.success) {
    dispatch(DeleteCreditCardAsync.failure(result.message));
    return;
  }

  const parsedResult = result.data.map(parseCreditCardResponse);
  dispatch(DeleteCreditCardAsync.success(parsedResult));
};

export const BuyAcres = (acres: number) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/subscriptions/buyAcres`;
  const data = { acres };

  const result = await postJson(url, data, token);
  if (result.success) {
    dispatch(BuyAcresAction(acres));
  }

  return result;
};

export const SendTabValue = (currentTab: number) => (dispatch: Dispatch) => {
  dispatch(SendTabValueAction(currentTab));
};

export const GetOrgSubscription = (orgId?: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const ownerOrgId = _ADMIN_ ? orgId : getState().account.org._id;
  const url = `${API_URL}/subscriptions/getOrgActiveSubscription/${ownerOrgId}`;
  const { token } = getState().auth;

  dispatch(GetSubscriptionAsync.request());
  const result = await getJson<ISubscriptionsResponse>(url, token);

  if (result.success) {
    dispatch(GetSubscriptionAsync.success(result.data));
  } else {
    dispatch(GetSubscriptionAsync.failure(result.message));
  }
  return result;
};

export const CreateSubscription = (priceId: string, seats: number) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/subscriptions/createSubscription`;
    const { token } = getState().auth;

    const data = {
      trial: 0,
      priceId,
      seats,
      promotionCodeId: getState().subscription.promoCode.promotionCodeId,
    };
    dispatch(CreateSubscriptionAsync.request());
    const result = await postJson<ISubscriptionsResponse>(url, data, token);

    if (result.success) {
      dispatch(CreateSubscriptionAsync.success(result.data));
    } else {
      dispatch(CreateSubscriptionAsync.failure(result.message));
    }
    return result;
  };

export const EditSubscription = (seats: number) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/subscriptions/editSubscription`;
    const { token } = getState().auth;
    const data = {
      subscriptionId: getState().subscription.cadSubscription.subscriptionId,
      cadId: getState().subscription.cadSubscription.subscriptionItemId,
      prorationBehavior: 'always_invoice',
      quantity: seats,
      promotionCodeId: getState().subscription.promoCode.promotionCodeId,
    };

    dispatch(EditSubscriptionAsync.request());
    const result = await patchJson<ISubscriptionsResponse>(url, data, token);

    if (result.success) {
      dispatch(EditSubscriptionAsync.success(result.data));
    } else {
      dispatch(EditSubscriptionAsync.failure(result.message));
    }
    return result;
  };

export const CancelSubscription = (subscriptionId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/subscriptions/endSubscriptionRenewal`;
    const { token } = getState().auth;

    const data = {
      subscriptionId,
    };
    dispatch(EndSubscriptionRenewalAsync.request());
    const result = await postJson<null>(url, data, token);
    return result.success;
  };

export const GetProratedCharge = (seats: number) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/subscriptions/subscriptionCostEstimate`;
    const { token } = getState().auth;
    const { subscriptionId, subscriptionItemId } = getState().subscription.cadSubscription;

    const data = {
      subscriptionId,
      cadId: subscriptionItemId,
      prorationBehavior: 'always_invoice',
      quantity: seats,
      promoCode: getState().subscription.promoCode.promotionCode,
    };

    dispatch(GetProratedChargesAsync.request());
    const result = await patchJson<IProratedChargesResponse>(url, data, token);
    if (result.success) {
      dispatch(GetProratedChargesAsync.success(result.data));
    } else {
      dispatch(GetProratedChargesAsync.failure(result.message));
    }
    return result;
  };

export const CheckPromoCode = (input: {code: string, quantity: number}) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    dispatch(SetPromoCodeAction(input.code));

    const url = `${API_URL}/subscriptions/subscriptionCostEstimate`;
    const { token } = getState().auth;
    const data = {
      subscriptionId: getState().subscription.cadSubscription.subscriptionId,
      cadId: getState().subscription.cadSubscription.subscriptionItemId,
      promoCode: input.code,
      quantity: input.quantity,
      prorationBehavior: 'always_invoice',
    };

    const result = await patchJson<IProratedChargesResponse>(url, data, token);
    if (result.success) {
      dispatch(CheckPromoCodeAsync.success(result.data));
    } else {
      dispatch(CheckPromoCodeAsync.failure(result.message));
    }
    return result;
  };

export const ClearPromoCodeInfo = () => (dispatch: Dispatch) => {
  dispatch(ClearPromoCodeInfoAction());
};
