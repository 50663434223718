// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => {
  const project = state.project.list?.find((item) => item._id === ownProps.order.ownerProject);
  const epsgCode = project?.tifFiles?.[0]?.epsg;

  return ({
    costPerAcre: state.account.org?.costPerAcre,
    imageryProvider: project.imageryRequest?.provider,
    automatePlanType: state.subscription.automateSubscription?.priceName,
    orgTeams: state.account.orgTeams,
    projectName: project?.name,
    templates: state.cadTemplate.list,
    epsgCode,
  });
};

interface IOwnProps {
    order: IOrder;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps),
);
