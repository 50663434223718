// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography } from '@material-ui/core/';
import { formatValue, deliverableCost } from 'Utils/deliverables';
import ErrorIcon from '@material-ui/icons/Error';
import compose from './container';

const TalkToSalesDialog = compose(({ classes, showDialog, titleText, order, costPerAcre, bundleCost, availableCreditAcreage, additionalDeliverables, acreAdjustmentFactor, onOk, onCancel, addOnDeliverableDollars, addOnLayerDollars, onProceed }) => {
  const costB = availableCreditAcreage * costPerAcre + bundleCost + addOnLayerDollars + addOnDeliverableDollars;
  const [costA, setCostA] = useState(0);
  
  useEffect(() => {
    let orderAdjustedAcres = order.acreage;
    let addOnCost = 0;
    if (order.isEstimate) {
      orderAdjustedAcres = order.acreage * acreAdjustmentFactor;
      if (order.addOns.length > 0 && additionalDeliverables.length > 0) {
        order.addOns.forEach((addOn) => {
          const additional = additionalDeliverables.find((deliverable: IDeliverable) => deliverable.name === addOn.name);
          const additionalAcreFactor = additional ? additional.acreFactor : 0;
          const acreCost = deliverableCost(additionalAcreFactor, order.acreage);
          addOnCost += (acreCost * costPerAcre);
        });
      }
    }
    setCostA(orderAdjustedAcres * costPerAcre + addOnCost);
  }, [acreAdjustmentFactor, additionalDeliverables]);

  return (
    <Dialog open={showDialog} onClose={onCancel} className={classes.dialog}>
      <div className={classes.dialogBox}>
        <div className={classes.dialogTitle}>
          <ErrorIcon className={classes.warningIcon} />
          <Typography variant="h3" className={classes.titleText}>{titleText}</Typography>
          <DialogActions>
            <Button onClick={onCancel}>Close</Button>
          </DialogActions>
        </div>
        <DialogContent>
          <Typography>
            Your project size exceeds your plan's available credits. You could save
            {' '}
            <strong>{`$${formatValue(costB - costA)}`}</strong>
            {' '}
            on this project by renewing your plan. Reach out to sales at
            {' '}
            <a className={classes.linkColor} target="_blank" href="mailto:sales@airworks.io" rel="noreferrer">sales@airworks.io</a>
            {' '}
            to get started.
            <Typography className={classes.title} variant="body1">Not renewing will result in an increased cost of $85 per acre</Typography>
          </Typography>
          <div className={classes.compare}>
            <div>
              <Typography className={classes.title} variant="h5">If you renew</Typography>
              <Typography className={classes.subTitle} variant="h6">Cost</Typography>

              <Typography className={classes.cost} variant="h2" color="primary">{`$${formatValue(costA)}`}</Typography>

              <Typography className={classes.subTitle} variant="h6">
                {order.acreage}
                {' '}
                credits
              </Typography>
              <Typography className={classes.cost} variant="h2" color="primary">{`($${costPerAcre.toFixed(2)}/cr)`}</Typography>
            </div>
            <div
              className={classes.line}
            />
            <div>
              <Typography className={classes.title} variant="h5">If you don't renew</Typography>
              <Typography className={classes.subTitle} variant="h6">Cost</Typography>

              <Typography className={classes.cost} variant="h2">{`$${formatValue(costB)}`}</Typography>

              <Typography className={classes.subTitle} variant="h6">
                {order.acreage}
                {' '}
                credits
              </Typography>
              <Typography className={classes.cost} variant="h2">($85.00/ac)</Typography>
            </div>
          </div>
          <Typography className={classes.terms}>* The prices shown here are estimates. Final project costs may change at AirWorks' discretion, based on project details.</Typography>
          <hr className={classes.hrLine} />
        </DialogContent>
        <DialogActions>
          <Button onClick={onOk} className={classes.contactButton}>Renew My Plan</Button>
          <Button onClick={onProceed} color="primary" className={classes.okButton}>Proceed Anyway</Button>
        </DialogActions>
      </div>
    </Dialog>
  );
});

export default TalkToSalesDialog;
