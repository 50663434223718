// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  columnHeader: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(),
    paddingTop: theme.spacing(),
  },
  nameColumn: {
    display: 'flex',
    alignItems: 'center',
  },
  table: {
    backgroundColor: 'inherit',
    marginBottom: theme.spacing(3),
    cursor: 'pointer',
  },
  pageWrapper: {
    width: 900,
    margin: '0 auto',
    marginTop: theme.spacing(8),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  iconButtons: {
    textAlign: 'right',
  },
  editInput: {
    width: 'inherit',
    display: 'block',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    padding: '5px',
  },
  snackBar: {
    top: '140px',
  },
  alert: {
    backgroundColor: 'white',
  },
}));

export default useStyles;
