// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import { ShowOpsTrainingDialogAction, HideOpsTrainingDialogAction, InitializeTrainingProjectAction } from './actions';

const initialState: IOpsTrainingState = {
  show: false,
  errorMessage: null,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(ShowOpsTrainingDialogAction, (draft) => {
    draft.show = true;
  });
  handleAction(HideOpsTrainingDialogAction, (draft) => { draft.show = false; });
  handleAction(InitializeTrainingProjectAction.request, (draft) => { draft.errorMessage = null; });
  handleAction(InitializeTrainingProjectAction.failure, (draft, payload) => { draft.errorMessage = payload; });
});
