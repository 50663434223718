// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL
/* eslint-disable no-nested-ternary */

import React from 'react';
import { ButtonBase } from '@material-ui/core';
import SatelliteView from 'Assets/satelliteView.png';
import StreetsView from 'Assets/streetsView.png';
import ESRIImageryView from 'Assets/esri-imagery-view.png';
import ESRIStreetsView from 'Assets/esri-street-view.png';
import { useFlags } from 'launchdarkly-react-client-sdk';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const MapStyleToggle = ({ mapStyle, ToggleMapStyleAction }: PropsType) => {
  const classes = useStyles();
  const { esriBasemap } = useFlags();

  return (
    <ButtonBase
      className={classes.styleToggle}
      onClick={ToggleMapStyleAction}
    >
      <img src={mapStyle === 'streets' ? (esriBasemap ? ESRIImageryView : SatelliteView) : (esriBasemap ? ESRIStreetsView : StreetsView)} alt="Toggle Map Style" />
    </ButtonBase>
  );
};

export default compose(MapStyleToggle);
