// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Dialog, Slide, IconButton, LinearProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CreateOrgForm from './CreateOrgForm';
import compose, { PropsType } from './container';
import useStyles from './styles';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

export const CreateOrgDialog = ({ showOrgDialog, setShowOrgDialog, showLoader }: PropsType) => {
  const classes = useStyles();

  return (
    <>
      <Dialog
        fullScreen
        open={showOrgDialog}
        onClose={() => setShowOrgDialog(false)}
        TransitionComponent={Transition}
        keepMounted={false}
      >
        {showLoader && (
          <>
            <LinearProgress className={classes.progress} />
            <div className={classes.backdrop} />
          </>
        )}
        <div className={classes.closeButtonWrapper}>
          <IconButton
            color="inherit"
            onClick={() => {
              setShowOrgDialog(false);
            }}
            aria-label="Close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <div className={classes.contentWrapper}>
          <CreateOrgForm />
        </div>
      </Dialog>
    </>
  );
};

export default compose(CreateOrgDialog);
