// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { getJson, postJson, patchJson, deleteRequest } from 'Utils/http';
import history from 'Utils/history';
import { API_URL } from 'Config';
import { GetTemplatesRoute } from 'Utils/routes';
import {
  GetTemplatesAsync, AddTemplateAction, SetTemplateIdAction, DeleteTemplateAsync, EditTemplateAsync, DuplicateTemplateAsync, SetSelectedStyleAction, SetSelectedWeightAction, SetSelectedColorAction, SetSelectedMappingAction, GetDefaultTemplateAction,
} from './actions';

export const GetDefaultTemplate = (template: string) => (dispatch: Dispatch) => dispatch(GetDefaultTemplateAction(template));

export const GetTemplates = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url =
      `${API_URL}/cadtemplates`;
    const { token } = getState().auth;

    dispatch(GetTemplatesAsync.request());

    const result = await getJson<ICadTemplate[]>(url, token);
    if (result.success) {
      dispatch(GetTemplatesAsync.success(result.data));
    } else {
      dispatch(GetTemplatesAsync.failure(result.message));
    }

    return result;
  };

// Deletes a template with a given templateId
export const DeleteTemplate = (templateId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/cadtemplates/${templateId}`;
    const { token } = getState().auth;
    dispatch(DeleteTemplateAsync.request(templateId));

    const result = await deleteRequest(url, token);
    if (result.success) {
      history.push(GetTemplatesRoute(templateId));
      dispatch(DeleteTemplateAsync.success(templateId));
    } else {
      dispatch(DeleteTemplateAsync.failure(templateId));
    }
  };

// Duplicate template
export const DuplicateTemplate = (data: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/cadtemplates/copyTemplate`;
    const { token } = getState().auth;
    dispatch(DuplicateTemplateAsync.request());

    const result = await postJson<ICadTemplate>(url, { templateId: data }, token);
    if (result.success) {
      dispatch(DuplicateTemplateAsync.success(result.data.newTemplate));
    }
    return result;
  };

// Update template name or template layer(s)
export const AddTemplate = (cadTemplate: ICadTemplate) => (dispatch: Dispatch) => dispatch(AddTemplateAction(cadTemplate));

export const EditTemplate = (data: {_id: string, layers?: any[], name?: string}) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/cadtemplates/editTemplate/${data._id}`;
    const { token } = getState().auth;
    dispatch(EditTemplateAsync.request());

    const result = await patchJson<ICadTemplate>(url, data, token);
    if (result.success) {
      dispatch(EditTemplateAsync.success(result.data.cadTemplate));
    } else {
      dispatch(EditTemplateAsync.failure());
    }
    return result;
  };

export const SetTemplateId = (templateId: string) => (dispatch: Dispatch) => dispatch(SetTemplateIdAction(templateId));

export const SetSelectedStyle = (data : { templateId: string, layerId: string, event: string}) => (dispatch: Dispatch) => dispatch(SetSelectedStyleAction(data));

export const SetSelectedWeight = (data : { templateId: string, layerId: string, event: number}) => (dispatch: Dispatch) => dispatch(SetSelectedWeightAction(data));

export const SetSelectedColor = (data : { templateId: string, layerId: string, color: string}) => (dispatch: Dispatch) => dispatch(SetSelectedColorAction(data));

export const AddLayers = (data: {templateId: string, newLayers: any[]}) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/cadtemplates/addLayers`;
    const { token } = getState().auth;

    const result = await postJson<{[key: string]: string}>(url, data, token);
    return result;
  };
export const SetSelectedMapping = (data : { templateId: string, layerId: string, event: Array<string>}) => (dispatch: Dispatch) => dispatch(SetSelectedMappingAction(data));
