// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const uesStyles = makeStyles((theme: Theme) => createStyles({
  pageControls: {
    width: '100%',
    position: 'fixed',
    minHeight: '80px',
    maxHeight: '80px',
    background: 'white',
    zIndex: 2,
  },
  pageControlsSub: {
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr auto',
    gridColumnGap: theme.spacing(4),
    gridTemplateAreas: '"backButton breadcrumbs close"',
  },
  pageWrapper: {
    height: '100%',
    marginTop: '80px',
    width: '1300px',
    marginRight: 'auto',
    marginLeft: 'auto',
    display: 'grid',
    justifyContent: 'center',
    gridTemplateColumns: '8fr 6fr',
    gridColumnGap: '100px',
  },
  closeButtonWrapper: {
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(4),
    gridArea: 'close',
    justifySelf: 'end',
    zIndex: 2000,
    color: 'gray',
  },
  backButton: {
    marginTop: theme.spacing(2),
    paddingLeft: theme.spacing(4),
    gridArea: 'backButton',
    justifySelf: 'start',
    zIndex: 2000,
    color: 'gray',
    width: theme.spacing(8),
  },
  tabs: {
    display: 'grid',
    gridTemplateColumns: 'auto auto auto auto auto',
    alignItems: 'center',
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(3),
    gridArea: 'breadcrumbs',
    justifySelf: 'start',
  },
  chevron: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  activeTabName: {
    fontWeight: 400,
  },
  errorMessage: {
    paddingLeft: theme.spacing(1),
  },
  bold: {
    fontWeight: 400,
  },
  filesList: {
    paddingLeft: theme.spacing(1),
    fontWeight: 400,
  },
  epsgErrorMessage: {
    paddingLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  errorText: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    color: 'black',
  },
  errorFile: {
    fontWeight: 400,
    display: 'inline',
  },
  errorItem: {
    backgroundColor: 'rgba(171,182,193,0.14)',
    padding: '8px 16px',
    borderLeft: '5px solid #ABB6C1',
    marginBottom: theme.spacing(1),
  },
  link: {
    color: '#01A1DD',
    textDecoration: 'underline',
  },
}));

export default uesStyles;
