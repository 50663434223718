// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  LoadListAsync,
  SetPageAction,
  SetListAction,
  SetLoadAllAction,
  SetLoadNewAction,
  SetRowsPerPageAction,
  SetSearchFilterAction,
  SetSortFilterAction,
  GetOrgAsync,
  UpdateOrgAsync,
  SetOrgIdAction,
  SetUserIdAction,
  GetOrgUserAsync,
  GetUsersAsync,
  CreateOrgAsync,
  GetLastLoginAsync,
  GetLoginStatsAsync,
  GetSubscriptionAsync,
  UpdateCostPerAcreAction,
  GetOrgProjectsAsync,
  DeleteProjectAdminAsync,
  DeleteOrgAsync,
  OrgEmailsAsync,
} from './actions';
import { GetOrgStatsAsync } from '../account/actions';

const initialState: IAdminState = {
  loading: false,
  loadAll: false,
  loadNew: true,
  searchFilter: '',
  sortFilter: 'companyName',
  page: 0,
  count: 0,
  rowsPerPage: 15,
  list: [],
  orgStats: null,
  orgId: null,
  userId: null,
  org: null,
  orgProjects: [],
  loginStats: null,
  usersList: [],
  user: null,
  automateSubscription: null,
  cadSubscription: null,
  createOrgSuccess: null,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(LoadListAsync.request, (draft) => {
    draft.loading = true;
  });
  handleAction(LoadListAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.list = [...draft.list, ...payload.list];
    draft.count = payload.count;
  });
  handleAction(SetLoadNewAction, (draft, payload) => {
    draft.loadNew = payload;
  });
  handleAction(SetLoadAllAction, (draft, payload) => {
    draft.loading = false;
    draft.loadAll = payload;
  });
  handleAction(SetListAction, (draft, payload) => {
    draft.list = payload;
  });
  handleAction(SetSearchFilterAction, (draft, payload) => {
    draft.searchFilter = payload;
  });
  handleAction(SetSortFilterAction, (draft, payload) => {
    draft.sortFilter = payload;
  });
  handleAction(SetPageAction, (draft, payload) => {
    draft.page = payload;
  });
  handleAction(SetRowsPerPageAction, (draft, payload) => {
    draft.rowsPerPage = payload;
  });
  handleAction(GetOrgProjectsAsync.request, (draft) => {
    draft.loading = true;
  });
  handleAction(GetOrgProjectsAsync.success, (draft, payload) => {
    draft.orgProjects = payload;
    draft.loading = false;
  });
  handleAction(GetOrgProjectsAsync.failure, (draft) => {
    draft.loading = false;
  });

  handleAction(DeleteProjectAdminAsync.success, (draft, payload) => { draft.orgProjects = draft.orgProjects.filter((p) => p._id !== payload); });

  handleAction(GetOrgAsync.success, (draft, payload) => {
    draft.org = payload;
  });
  handleAction(UpdateOrgAsync.success, (draft, payload) => {
    draft.org = payload;
  });
  handleAction(GetUsersAsync.success, (draft, payload) => {
    draft.usersList = payload;
  });
  handleAction(OrgEmailsAsync.success, (draft, payload) => {
    draft.org.optOutOfEmails = payload;
  });
  handleAction(GetOrgUserAsync.success, (draft, payload) => {
    draft.user = payload;
  });
  handleAction(SetOrgIdAction, (draft, payload) => {
    draft.orgId = payload;
  });
  handleAction(SetUserIdAction, (draft, payload) => {
    draft.userId = payload;
  });
  handleAction(CreateOrgAsync.request, (draft) => {
    draft.loading = true;
  });
  handleAction(CreateOrgAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.list.push(payload);
    draft.createOrgSuccess = payload.message;
  });
  handleAction(CreateOrgAsync.request, (draft) => {
    draft.loading = true;
  });
  handleAction(CreateOrgAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.list.push(payload);
    draft.createOrgSuccess = payload.message;
  });
  handleAction(CreateOrgAsync.failure, (draft) => { draft.loading = false; });
  handleAction(GetLastLoginAsync.request, (draft) => { draft.loginStats = null; });
  handleAction(GetLastLoginAsync.success, (draft, payload) => {
    draft.loginStats = Object.assign(payload, draft.loginStats);
  });
  handleAction(GetLastLoginAsync.failure, (draft) => { draft.loginStats.lastLogin = 'N/A'; });
  handleAction(GetLoginStatsAsync.success, (draft, payload) => {
    draft.loginStats = Object.assign(payload, draft.loginStats);
  });
  handleAction(GetOrgStatsAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetOrgStatsAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.orgStats = payload;
  });
  handleAction(GetSubscriptionAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetSubscriptionAsync.success, (draft, payload) => {
    draft.cadSubscription = payload.response.cad;
    draft.automateSubscription = payload.response.automate;
    draft.loading = false;
  });
  handleAction(GetSubscriptionAsync.failure, (draft) => { draft.loading = false; });
  handleAction(UpdateCostPerAcreAction, (draft, payload) => {
    draft.org.costPerAcre = payload;
  });
  handleAction(DeleteOrgAsync.success, (draft, payload) => {
    draft.list = draft.list.filter((p) => p._id !== payload);
  });
});
