// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { matchPath, generatePath } from 'react-router';

export const ROOT_ROUTE = '/';
export const FORGOT_PASSWORD_ROUTE = '/forgot';
export const SIGN_UP_ROUTE = '/signUp';
export const SIGN_UP_SUCCESS = '/signUpSuccess';
export const RESET_PASSWORD_ROUTE = '/verifyToken/:token';
export const EXPIRED_TOKEN_ROUTE = '/expiredToken';

export const PROJECTS_ROUTE = '/projects';
export const PROJECT_DETAILS_ROUTE = '/projects/:id/details';
export const GetProjectDetailsRoute = (projectId: string) => generatePath(PROJECT_DETAILS_ROUTE, { id: projectId });
export const PROJECT_FILES_ROUTE = '/projects/:id/files';
export const GetProjectFilesRoute = (projectId: string) => generatePath(PROJECT_FILES_ROUTE, { id: projectId });
export const PROJECT_3DVIEWER_ROUTE = '/projects/:id/3d';
export const GetProject3DViewerRoute = (projectId: string) => generatePath(PROJECT_3DVIEWER_ROUTE, { id: projectId });

export const ACCOUNT_GENERAL_ROUTE = '/account/general';
export const CAD_TEMPLATES_ROUTE = '/account/cadTemplates/:id';
export const GetTemplatesRoute = (id: string) => generatePath(CAD_TEMPLATES_ROUTE, { id });
export const CAD_TEMPLATE_ROUTE = '/account/cadTemplates/:templateId?';
export const GetTemplateRoute = (templateId: string) => generatePath(CAD_TEMPLATE_ROUTE, { templateId });
export const ACCOUNT_BILLING_ROUTE = '/account/billing';
export const ACCOUNT_PASSWORD_ROUTE = '/account/password';
export const ORDER_HISTORY_ROUTE = '/account/orderHistory';
export const ACCOUNT_PLAN_USAGE = '/account/planUsage';
export const USER_MANAGEMENT_ROUTE = '/account/userManagement';
export const TEAMS_ROUTE = '/account/teams';

export const ADMIN_ROUTE = '/admin';
export const ADMIN_OPS_SETTINGS_ROUTE = '/admin/opsSettings';
export const ADMIN_GENERAL_ROUTE = '/admin/:orgId/general';
export const GetAdminGeneralRoute = (orgId: string) => generatePath(ADMIN_GENERAL_ROUTE, { orgId });
export const ADMIN_PROJECTS_ROUTE = '/admin/:orgId/projects';
export const GetAdminProjectsRoute = (orgId: string) => generatePath(ADMIN_PROJECTS_ROUTE, { orgId });
export const ADMIN_PROJECT_ROUTE = '/admin/:orgId/projects/:projectId';
export const GetAdminProjectRoute = (orgId: string, projectId: string) => generatePath(ADMIN_PROJECT_ROUTE, { orgId, projectId });
export const ADMIN_SUBSCRIPTION_ROUTE = '/admin/:orgId/subscription';
export const GetAdminSubscriptionRoute = (orgId: string) => generatePath(ADMIN_SUBSCRIPTION_ROUTE, { orgId });
export const ADMIN_USERS_ROUTE = '/admin/:orgId/users';
export const GetAdminUsersRoute = (orgId: string) => generatePath(ADMIN_USERS_ROUTE, { orgId });
export const ADMIN_USER_ROUTE = '/admin/:orgId/users/:userId?';
export const GetAdminUserRoute = (orgId: string, userId: string) => generatePath(ADMIN_USER_ROUTE, { orgId, userId });
export const ADMIN_ORG_PLAN_USAGE_ROUTE = '/admin/:orgId/planUsage';
export const GetAdminOrgPlanUsageRoute = (orgId: string) => generatePath(ADMIN_ORG_PLAN_USAGE_ROUTE, { orgId });
export const ADMIN_ORDER_HISTORY_ROUTE = '/admin/:orgId/orderHistory';
export const GetAdminOrderHistoryRoute = (orgId: string) => generatePath(ADMIN_ORDER_HISTORY_ROUTE, { orgId });

export const IsProjectListPath = (currentPath: string) => matchPath(currentPath, { path: PROJECTS_ROUTE, exact: true });

export const IsProjectPath = (currentPath: string) => (
  matchPath(currentPath, { path: PROJECT_DETAILS_ROUTE, exact: true })
  || matchPath(currentPath, { path: PROJECT_FILES_ROUTE, exact: true })
  || matchPath(currentPath, { path: PROJECT_3DVIEWER_ROUTE, exact: true })
);

export const IsAccountPath = (currentPath: string) => (
  matchPath(currentPath, { path: ACCOUNT_GENERAL_ROUTE, exact: true })
  || matchPath(currentPath, { path: CAD_TEMPLATE_ROUTE, exact: true })
  || matchPath(currentPath, { path: ACCOUNT_BILLING_ROUTE, exact: true })
  || matchPath(currentPath, { path: ORDER_HISTORY_ROUTE, exact: true })
  || matchPath(currentPath, { path: ACCOUNT_PASSWORD_ROUTE, exact: true })
  || matchPath(currentPath, { path: ACCOUNT_PLAN_USAGE, exact: true })
  || matchPath(currentPath, { path: USER_MANAGEMENT_ROUTE, exact: true })
  || matchPath(currentPath, { path: TEAMS_ROUTE, exact: true })
);

export const IsAdminListPath = (currentPath: string) => (
  matchPath(currentPath, { path: ADMIN_ROUTE, exact: true })
  || matchPath(currentPath, { path: ADMIN_OPS_SETTINGS_ROUTE, exact: true })
);

export const IsAdminPath = (currentPath: string) => (
  matchPath(currentPath, { path: ADMIN_GENERAL_ROUTE, exact: true })
  || matchPath(currentPath, { path: ADMIN_PROJECTS_ROUTE, exact: true })
  || matchPath(currentPath, { path: ADMIN_SUBSCRIPTION_ROUTE, exact: true })
  || matchPath(currentPath, { path: ADMIN_PROJECT_ROUTE, exact: true })
  || matchPath(currentPath, { path: ADMIN_USERS_ROUTE, exact: true })
  || matchPath(currentPath, { path: ADMIN_USER_ROUTE, exact: true })
  || matchPath(currentPath, { path: ADMIN_ORG_PLAN_USAGE_ROUTE, exact: true })
  || matchPath(currentPath, { path: ADMIN_ORDER_HISTORY_ROUTE, exact: true })
);
