// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Dialog, Slide, Button, IconButton, Divider, LinearProgress, Typography, MenuItem, FormControl, Select, Input, FormControlLabel, Checkbox } from '@material-ui/core';
import { WideLightTooltip } from 'Common/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import NearmapLogo from 'Assets/nearmap-logo-full.png';
import compose, { PropsType } from './container';
import useStyles from './styles';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

export const VendorSelectionDialog = ({ bbox, showDialog, showLoader, availableProjections, projection, imageryProcessing, setShowDialog, CreateImageryRequestAction, SetImageryVendorSelectedAction, ToggleImageryProcessingAction, GetAvailableProjectionsAction, SetProjectionSelectedAction }: PropsType) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);

  useEffect(() => {
    const loadProjections = async () => {
      setLoading(true);
      SetProjectionSelectedAction(null);
      await GetAvailableProjectionsAction(bbox);
      setLoading(false);
    };
    if (showDialog && bbox) {
      loadProjections();
    }
  }, [bbox, showDialog]);

  useEffect(() => {
    if (!projection) {
      setDisableContinue(true);
    } else {
      setDisableContinue(false);
    }
  }, [projection]);

  const selectVendor = async () => {
    // Setting vendor selected as Nearmap for this release
    window?.pendo?.track('Projection Selected', { projection_selected: projection });
    SetImageryVendorSelectedAction('Nearmap');
    await CreateImageryRequestAction();
    setShowDialog(false);
  };

  const selectProjection = (event: React.ChangeEvent<HTMLInputElement>) => {
    SetProjectionSelectedAction(event.target.value);
  };

  return (
    <>
      <Dialog
        fullScreen
        open={showDialog}
        onClose={() => setShowDialog(false)}
        TransitionComponent={Transition}
        keepMounted={false}
      >
        {(showLoader || loading) && (
          <>
            <LinearProgress className={classes.progress} />
            <div className={classes.backdrop} />
          </>
        )}
        <div className={classes.closeButtonWrapper}>
          <IconButton
            color="inherit"
            onClick={() => {
              setShowDialog(false);
            }}
            aria-label="Close"
          >
            <CloseIcon fontSize="large" />
          </IconButton>
        </div>
        <div className={classes.contentWrapper}>
          <Typography variant="h1">Your Imagery Vendor</Typography>
          <Typography className={classes.contentText}>
            <b>Nearmap</b>
            {' '}
            will be your default Imagery Vendor, choose your imagery projection and click on Continue.
          </Typography>
          {/* Vendor option will be clickable */}
          <div className={classes.vendorOption}>
            <img className={classes.vendorLogo} src={NearmapLogo} alt="nearmap logo" />
            <Divider orientation="vertical" className={classes.verticalDivider} flexItem />
            <div className={classes.vendorText}>
              <Typography className={classes.vendorName}>Nearmap</Typography>
              <Typography className={classes.vendorDescription}>Nearmap gives you every dimension of location intelligence in one central hub.</Typography>
            </div>
          </div>
          <Typography className={classes.contentText2}>Other providers coming soon.</Typography>
          <FormControl variant="outlined" required className={classes.formControl}>
            <Select
              className={classes.select}
              displayEmpty
              value={projection}
              onChange={selectProjection}
              input={<Input disableUnderline />}
              renderValue={(selected) => {
                if (!selected) {
                  return <em className={classes.placeholder}>Select Projection</em>;
                }
                return selected;
              }}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 350,
                  },
                },
              }}
            >
              {availableProjections?.map((item: string) => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography className={classes.contentText3}>
            Feel free to review
            {' '}
            <a href="https://www.nearmap.com/us/en/current-aerial-maps-coverage" className={classes.anchorTag} rel="noreferrer" target="_blank">Nearmap’s coverage map</a>
            {' '}
            and
            {' '}
            <a href="https://docs.nearmap.com/display/NUB/2020/02/04/Projections+and+Coordinate+Systems+and+the+Importance+of+the+Coordinates" className={classes.anchorTag} rel="noreferrer" target="_blank">projection system recommendations</a>
            {' '}
            before proceeding.
          </Typography>
          <Typography className={classes.contentText3}>Please continue to checkout and save an estimate so we can kickoff your data upload process.</Typography>
          <div className={classes.processingContainer}>
            <FormControlLabel
              label={<Typography>Begin Processing Immediately</Typography>}
              control={(
                <Checkbox
                  color="primary"
                  onChange={ToggleImageryProcessingAction}
                  checked={imageryProcessing}
                />
              )}
            />
            <WideLightTooltip
              placement="bottom"
              interactive
              title={(
                <div>
                  When start processing immediately is selected, you are forfeiting your chance to review the available imagery for your area of interest before placing your order. If you want to review the imagery before kicking off your project, unselect this option.
                </div>
              )}
            >
              <InfoIcon fontSize="small" />
            </WideLightTooltip>
          </div>
          <Button disabled={disableContinue} className={classes.continueButton} variant="outlined" onClick={selectVendor}>Continue</Button>
        </div>
      </Dialog>
    </>
  );
};

export default compose(VendorSelectionDialog);
