// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

// TODO - either convert this file to typescript or define all the props (garrett, 2021-08-16)
/* eslint react/prop-types: 0 */

import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import BarChart from 'recharts/es6/chart/BarChart';
import XAxis from 'recharts/es6/cartesian/XAxis';
import YAxis from 'recharts/es6/cartesian/YAxis';
import Bar from 'recharts/es6/cartesian/Bar';
import { Typography, LinearProgress, Divider, Container, Paper } from '@material-ui/core';
import LoadingSpinner from 'Common/LoadingSpinner';

const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

const longMonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const dayNames = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

const hexToRGBA = (hex, alpha) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const formatNumber = (number) => +Number(number).toFixed(2);

const PlanUsage = ({ classes, theme, orgId, orgStats, GetOrgStatsAction, GetOrgSubscriptionAction, subscriptionType, loginStats, SetOrgIdAction, GetOrgAction, GetLoginStatsAction, GetLastLoginAction }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    SetOrgIdAction(orgId);
    const loadData = async () => {
      await Promise.all([
        GetOrgAction(orgId),
        GetLoginStatsAction(orgId),
        GetLastLoginAction(orgId),
        GetOrgSubscriptionAction(orgId),
        GetOrgStatsAction(orgId),
      ]);
      setLoading(false);
    };
    loadData();
  }, []);

  const loginsData =
    loginStats && loginStats.perMonth &&
    loginStats.perMonth.map((v, index) => ({
      monthName: monthNames[v.month - 1],
      year: index === 0 || v.month === 1 ? v.year : '',
      logins: v.value,
    }));

  const creditAcresData =
    orgStats &&
    orgStats.creditAcres.processedPerMonth.map((v, index) => ({
      monthName: monthNames[v.month - 1],
      year: (index === 0 || v.month === 1) ? v.year : '',
      creditAcres: +(v.value).toFixed(2),
    }));
  const projectsData =
    orgStats &&
    orgStats.projects.perMonth.map((v, index) => ({
      monthName: monthNames[v.month - 1],
      year: index === 0 || v.month === 1 ? v.year : '',
      projects: v.value,
    }));

  const BarLabel = (params) => {
    const { value: barLabelValue } = params;
    if (barLabelValue === 0) return <text />;

    const { x, y, value, width, height } = params;

    return (
      <text
        x={x}
        y={y}
        dx={7}
        dy={-8}
        fontSize={12}
        fill={theme.palette.darkGrey}
        className="recharts-bar-label"
        width={width}
        height={height}
        textAnchor="middle"
      >
        {value}
      </text>
    );
  };

  const Calendar = (thisMonth) => {
    const now = new Date();
    const { getFullYear: year, getMonth: month } = now;
    const { length: monthLength } = month;
    const daysToPrepend = new Date(year, month, 1).getDay();
    const daysToAppend = 6 - new Date(year, month, monthLength).getDay();

    return (
      <div className={classes.calendarContainer}>
        {dayNames.map((day) => (
          <Container className={classes.calendarHeaders}>
            <Typography align="left">{day}</Typography>
          </Container>
        ))}
        { Array.from({ length: daysToPrepend }, (_) => <Paper elevation={0} className={classes.emptyDay} />)}
        {
          thisMonth.map((stat) => ( // eslint-disable-line react/destructuring-assignment
            <Paper elevation={0} className={classes.calendarDay}>
              <Typography gutterBottom style={{ position: 'absolute', paddingLeft: '5px' }}>{stat.day}</Typography>
              <Container className={classes.loginsCircle} style={{ backgroundColor: hexToRGBA(theme.palette.primary.main, stat.value > 10 ? 1 : stat.value / 10) }}>
                <Typography align="center" variant="h3" style={{ color: stat.value > 0 ? 'white' : 'black', lineHeight: '37px' }}>{stat.value}</Typography>
              </Container>
            </Paper>
          ))
        }
        { Array.from({ length: daysToAppend }, (_) => <Paper elevation={0} className={classes.emptyDay} />)}
      </div>
    );
  };

  return (
    <LoadingSpinner loading={loading}>
      <div className={classes.pageWrapper}>
        <div className={classes.margin}>
          {orgStats && (
            <div>
              <Typography className={classes.title} variant="h4">Credit Usage</Typography>
              <div className={classnames(classes.list, classes.acreageList)}>
                <Typography variant="subtitle1">
                  <Typography component="span" variant="subtitle1" color="primary">{formatNumber(orgStats.creditAcres.totalProcessed)}</Typography>
                  {` of ${formatNumber(orgStats.creditAcres.limit)} credits`}
                </Typography>
                <Typography variant="subtitle1" className={classes.alignRight}>
                  <Typography component="span" variant="subtitle1" color="secondary">{formatNumber(orgStats.creditAcres.totalPending)}</Typography>
                  {' credits pending'}
                </Typography>
                <Typography variant="body1">{subscriptionType === 'Monthly' ? 'processed this month' : 'processed this year'}</Typography>
                <Typography variant="subtitle1" className={classes.alignRight}>{`${formatNumber(orgStats.creditAcres.available)} credits remaining`}</Typography>
              </div>
              <LinearProgress
                variant="buffer"
                value={(orgStats.creditAcres.totalProcessed / orgStats.creditAcres.limit) * 100}
                valueBuffer={((orgStats.creditAcres.totalProcessed + orgStats.creditAcres.totalPending) / orgStats.creditAcres.limit) * 100}
                className={classes.progressBar}
                classes={{
                  bar2Buffer: classes.bar2,
                  dashed: classes.dashedBar,
                }}
              />
              <Typography variant="subtitle1">Credits Per Month</Typography>
              <BarChart height={200} width={600} data={creditAcresData}>
                <XAxis dataKey="monthName" tickLine={false} />
                <XAxis dataKey="year" axisLine={false} tickLine={false} xAxisId="year" />
                <YAxis padding={{ bottom: 8, top: 16 }} hide />
                <Bar dataKey="creditAcres" fill={theme.palette.primary.main} label={<BarLabel />} barSize={16} radius={[2, 2, 2, 2]} />
              </BarChart>
              <div className={classes.list}>
                <Typography variant="subtitle1">Credits Processed to Date</Typography>
                <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                  {formatNumber(orgStats.creditAcres.totalProcessed)}
                </Typography>
                <Typography variant="subtitle1">Avg Credits Per Month</Typography>
                <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                  {formatNumber(orgStats.creditAcres.averagePerMonth)}
                </Typography>
                <Typography variant="subtitle1">Avg Credits Per Project</Typography>
                <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                  {formatNumber(orgStats.creditAcres.averagePerProject)}
                </Typography>
              </div>
              <Divider className={classes.divider} />
              <Typography className={classes.title} variant="h4">Project usage</Typography>
              <Typography variant="subtitle1">Projects Per Month</Typography>
              <BarChart height={200} width={600} data={projectsData}>
                <XAxis dataKey="monthName" tickLine={false} />
                <XAxis dataKey="year" axisLine={false} tickLine={false} xAxisId="year" />
                <YAxis padding={{ bottom: 8, top: 16 }} hide />
                <Bar dataKey="projects" fill={theme.palette.primary.main} label={<BarLabel />} barSize={16} radius={[2, 2, 2, 2]} />
              </BarChart>
              <div className={classes.list}>
                <Typography variant="subtitle1">Projects Processed to Date</Typography>
                <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                  {orgStats.projects.total}
                </Typography>
                <Typography variant="subtitle1">Avg Projects Per Month</Typography>
                <Typography variant="h1" color="textSecondary" className={classes.alignRight}>
                  {formatNumber(orgStats.projects.averagePerMonth)}
                </Typography>
              </div>
            </div>
          )}
          <Divider className={classes.divider} />
          {loginStats && (
            <div>
              <Typography className={classes.title} variant="h4">Login Analytics</Typography>
              <div className={classes.list}>
                <Typography variant="subtitle1">Last Login Date</Typography>
                <Typography variant="h4" color="textSecondary" className={classes.alignRight}>
                  {loginStats.lastLogin}
                </Typography>
              </div>
              <Typography variant="subtitle1" className={classes.topMargin}> Logins Per Month </Typography>
              <BarChart height={200} width={600} data={loginsData}>
                <XAxis dataKey="monthName" tickLine={false} />
                <XAxis
                  dataKey="year"
                  axisLine={false}
                  tickLine={false}
                  xAxisId="year"
                />
                <YAxis padding={{ bottom: 8, top: 16 }} hide />
                <Bar
                  dataKey="logins"
                  fill={theme.palette.primary.main}
                  label={<BarLabel />}
                  barSize={16}
                  radius={[2, 2, 2, 2]}
                />
              </BarChart>

              <Typography variant="subtitle1"> Logins This Month </Typography>
              <Typography color="textSecondary" variant="subtitle1">{`${longMonthNames[new Date().getMonth()]} ${new Date().getFullYear()}`}</Typography>
              {loginStats.thisMonth && Calendar(loginStats.thisMonth)}
            </div>
          )}
        </div>
      </div>
    </LoadingSpinner>
  );
};

export default PlanUsage;
