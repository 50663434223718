// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { kml } from '@tmcw/togeojson';
import { ActionCreators } from 'redux-undo';
import { getFile, getJson, postJson } from 'Utils/http';
import GenerateGuid from 'Utils/guid';
import { getOrders } from 'State/order/selectors';
import { API_URL } from 'Config';
import { UpdateOrder } from 'State/order/thunk';
import { ResetKmlDrawAction } from 'State/map/draw/actions';
import { SetAvailableProjections, SetShowKmlToolbarAction } from 'State/project/actions';
import { SetKmlAction, LoadKmlAsync, CreateKmlBufferAsync, ToggleKmlVisibility, AdminToggleKmlVisibility, ClearKmlsAction, SetLineStringUploadAction, SetBufferSizeAction, SetShowBufferInfoAction, SetKmlErrorAction } from './actions';

const readFromText = (text: string) => {
  const emptyCollection: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
    type: 'FeatureCollection',
    features: [],
  };
  const parser = new DOMParser();

  const parsedDocument = parser.parseFromString(text, 'text/xml');
  const geoJson = kml(parsedDocument);
  geoJson.features.forEach((feature) => {
    feature.id = GenerateGuid();
  });

  return geoJson || emptyCollection;
};

export const ToggleKml = (id: string) => (dispatch: Dispatch) => dispatch(ToggleKmlVisibility(id));
export const AdminToggleKml = (id: string) => (dispatch: Dispatch) => dispatch(AdminToggleKmlVisibility(id));

export const SetKml = (featureCollection: GeoJSON.FeatureCollection, featureId?:string) =>
  (dispatch: Dispatch, getState: () => IState) => {
    const emptyCollection: GeoJSON.FeatureCollection = {
      type: 'FeatureCollection',
      features: [],
    };

    const orders = getOrders(getState());
    const { activeOrder } = orders;

    const kmlBoundary = featureCollection || emptyCollection;
    kmlBoundary.features.forEach((feature) => {
      feature.id = GenerateGuid();
    });

    dispatch(SetKmlAction({ orderId: activeOrder._id, kml: kmlBoundary }));
  };

export const ReadFromText = (text: string) =>
  (dispatch: Dispatch, getState: () => IState) => {
    const geoJson = readFromText(text);

    SetKml(geoJson)(dispatch, getState);
  };

export const ReadFromUrl = (url: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    if (!url) {
      SetKml(null)(dispatch, getState);
      return;
    }

    const fileBlobResult = await getFile(url);
    if (!fileBlobResult.success) {
      return;
    }

    ReadFromText(fileBlobResult.data)(dispatch, getState);
  };

export const ClearKmls = () => (dispatch: Dispatch) => dispatch(ClearKmlsAction());

export const LoadKmls = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const emptyCollection: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
      type: 'FeatureCollection',
      features: [],
    };
    dispatch(LoadKmlAsync.request());
    const orders = getOrders(getState());

    const ordersWithoutKml = orders.projectOrders.filter((order) => !order.boundaryFile);
    const ordersWithKml = orders.projectOrders.filter((order) => order.boundaryFile);
    const { token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    if (!_ADMIN_ && 'opsTrainer' in getState().auth.resources) {
      const project = getState().project.list.find((p) => p._id === projectId);
      orgId = project.ownerOrganization;
    } else {
      orgId = _ADMIN_ ? getState().admin.orgId : getState().auth.user.ownerOrganization;
    }
    const promiseArray = [];
    /* eslint-disable no-await-in-loop */
    for (let i = 0; i < ordersWithKml.length; i += 1) {
      const filename = ordersWithKml[i].boundaryFile.split('/').pop();
      const url = `${API_URL}/readKML/${orgId}/${projectId}/${ordersWithKml[i]._id}/${filename}`;
      const res = await getJson<string>(url, token);
      promiseArray.push(getFile(res.data));
    }
    const fileResult = await Promise.all(promiseArray);
    const anyFailed = fileResult.some((r) => !r.success);
    if (anyFailed) return;

    const result = [
      ...ordersWithKml
        .map((order, index) => ({ orderId: order._id, kml: readFromText(fileResult[index].data) })),
      ...ordersWithoutKml
        .map((o) => ({ orderId: o._id, kml: emptyCollection })),
    ];
    dispatch(LoadKmlAsync.success(result));
  };

// Download KML with a given orderId and filename
export const DownloadKML = (orderId: string, filename: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    let orgId;
    const isAdmin = Boolean(user.roles && user.roles.includes('admin'));
    if (isAdmin) {
      orgId = getState().admin.orgId;
    } else {
      orgId = getState().auth.user.ownerOrganization;
    }

    const url = `${API_URL}/downloadKML/${orgId}/${projectId}/${orderId}/${filename}`;
    const result = await getJson<string>(url, token);

    if (result.success) {
      window.open(result.data, '_self');
    }
  };

// Generate buffer for a LineString Kml
export const GenerateKmlBuffer = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user, token } = getState().auth;
    const { projectId } = getState().project;
    const orgId = user.ownerOrganization;
    const orders = getOrders(getState());
    const order = orders.activeOrder;
    const orderId = order._id;
    const { bufferSize } = getState().kml.present;
    const data = {
      radius: bufferSize,
    };
    const url = `${API_URL}/bufferKML/${orgId}/${projectId}/${orderId}`;

    dispatch(CreateKmlBufferAsync.request());
    const result = await postJson<any>(url, data, token);

    if (result.success) {
      const resultOrder = result.data;
      const filename = resultOrder.boundaryFile.split('/').pop();
      const readKmlUrl = `${API_URL}/readKML/${orgId}/${projectId}/${resultOrder._id}/${filename}`;
      const kmlResponse = await getJson<string>(readKmlUrl, token);
      if (kmlResponse.success) {
        const fileBlobResult = await getFile(kmlResponse.data);
        ReadFromText(fileBlobResult.data)(dispatch, getState);
        dispatch(ResetKmlDrawAction());
        UpdateOrder(resultOrder as IOrder)(dispatch);
        dispatch(CreateKmlBufferAsync.success());
        dispatch(SetLineStringUploadAction(false));
        dispatch(SetShowKmlToolbarAction(true));
        dispatch(SetShowBufferInfoAction(true));
        dispatch(ActionCreators.clearHistory());
      }
      return;
    }

    // Handle error in bufferKML API or readKML API
    dispatch(CreateKmlBufferAsync.failure());
    dispatch(SetKmlErrorAction(true));
    dispatch(SetLineStringUploadAction(false));
  };

export const SetLineStringUpload = (lineStringUpload: boolean) => (dispatch: Dispatch) => {
  dispatch(SetLineStringUploadAction(lineStringUpload));
};

export const SetBufferSize = (bufferSize: number) => (dispatch: Dispatch) => dispatch(SetBufferSizeAction(bufferSize));

export const GetAvailableProjections = (kmlBBox: number[]) => async (dispatch: Dispatch, getState: () => IState) => {
  const { token } = getState().auth;
  const url = `${API_URL}/imageryRequest/getProjections`;
  const data = { bbox: kmlBBox };
  const result = await postJson<any>(url, data, token);
  if (result.success) {
    dispatch(SetAvailableProjections(result.data.availableProjections));
  }
};
