// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  pageTitle: {
    textAlign: 'center',
    marginBottom: 30,
  },
  form: {
    margin: '0 auto',
    width: 750,
  },
  outline: {
    borderRadius: 0,
    borderColor: 'rgba(0,0,0,0.1) !important',
    boxShadow: theme.lightShadow,
  },
  input: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabel: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabelShrink: {
    transform: 'translate(14px, -7px) scale(0.75) !important',
  },
  helperText: {
    fontWeight: 300,
    letterSpacing: '1.3px',
  },
  fields: {
    display: 'flex',
    gap: theme.spacing(2),
  },
  buttonsWrapper: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'space-around',
  },
  uploadFormSubmit: {
    paddingLeft: theme.spacing(11),
    paddingRight: theme.spacing(11),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  datePicker: {
    width: '100%',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 0,
    borderColor: 'rgba(0,0,0,0.1) !important',
    boxShadow: theme.lightShadow,
    '& .MuiInput-underline:before': {
      border: 'none !important',
    },
    '& .MuiInput-underline:after': {
      border: 'none',
    },
    '& .MuiFormLabel-root': {
      paddingLeft: '14px',
      fontWeight: 300,
      letterSpacing: '1.3px',
      fontSize: '21px',
      lineHeight: '14px',
    },
    '& .MuiInput-formControl': {
      margin: 0,
    },
    '& .MuiInputLabel-shrink': {
      transform: 'translate(5px, -5px) scale(0.75) !important',
    },
    '& .MuiInputBase-input': {
      paddingLeft: '14px',
      fontWeight: 300,
      letterSpacing: '1.3px',
      fontSize: '21px',
    },
    '& .MuiFormHelperText-root': {
      position: 'absolute',
      top: '40px',
      left: '14px',
      fontSize: '10px',
    },
  },
  calender: {
    transform: 'translate(56px, 15px) !important',
  },
}));

export default useStyles;
