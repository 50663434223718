// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';
import { cadViewPermissions } from 'State/order/selectors';

const mapStateToProps = (state: IState) => {
  const cadViewPermission = cadViewPermissions(state);
  let isAnyOrderProcessed = false;
  const viewer3DTab = state.project.project?.lasFiles?.filter((las) => las.tile3DUrl).length;

  if (_ENV_ === 'production' && (cadViewPermission.cadViewProd)) {
    isAnyOrderProcessed = true;
  }
  if ((_ENV_ === 'test' || _ENV_ === 'dev') && (cadViewPermission.cadViewDevStage)) {
    isAnyOrderProcessed = true;
  }

  return {
    projectId: state.project.projectId,
    isAnyOrderProcessed,
    editorFunctions: !_ADMIN_ && 'editorFunctions' in state.auth.resources,
    projectDetailsTab: !_ADMIN_ && 'projectDetailsTab' in state.auth.resources,
    viewer3DTab,
  };
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps),
);
