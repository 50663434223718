// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  orderWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardWrapper: {
    padding: '0 150px 150px 150px',
    width: '100%',
  },
  adjustmentErrorPaper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },
  adjustmentErrorTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  adjustmentErrorBody: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  errorPaper: {
    padding: theme.spacing(2),
  },
  errorIcon: {
    color: '#F95264',
    fontSize: '25px',
  },
  errorTitleText: {
    color: '#F95264',
  },
  errorBodyText: {
    marginLeft: theme.spacing(4),
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  estimateContainer: {
    display: 'flex',
    paddingLeft: '15px',
  },
  icon: {
    color: '#FAA737',
    marginRight: '5px',
  },
  estimateButtonContainer: {
    display: 'flex',
  },
  verticalDivider: {
    margin: '0 10px',
  },
  downloadText: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  deleteButton: {
    color: '#f95264',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  sideTitle: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px 0 15px',
  },
  bodyWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  headerWrapper: {
    minHeight: '75px',
    marginBottom: '15px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  select: {
    fontWeight: 300,
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  routerLink: {
    color: '#2E4DEB',
    display: 'flex',
    alignItems: 'center',
    paddingRight: '20px',
  },
  orderNumber: {
    padding: '6px',
  },
  statusText: {
    padding: '9px',
  },
  dropdownMap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  mapWrapper: {
    flex: 1,
    position: 'relative',
    height: '500px',
    width: '100%',
  },
  dropdownText: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '4.5px 9px',
  },
  itemWrapper: {
    marginBottom: '10px',
  },
}));

export default useStyles;
