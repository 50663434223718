// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useRef, useEffect, useState } from 'react';
import {
  Typography,
  Link,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
import classnames from 'classnames';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';
import history from 'Utils/history';
import compose from './container';

// This component renders signup form
// It renders on "/signUp" route, parent component is auth(index.tsx)
// All form fields are required except occupation
const SignUp = compose(
  ({ classes, CreateOrgAction }) => {
    const captchaRef = useRef(null);
    const [disableSubmit, setDisableSubmit] = useState(true);

    // hubspot form initilization
    useHubspotForm({
      portalId: '3434755',
      formId: 'd9f1edf9-5ff0-43be-bfa8-a48f1112a337',
      target: '#my-hubspot-form',
    });

    useEffect(() => {
      // this is to use hubspot callbacks without jQuery
      window.addEventListener('message', handler);
      return () => {
        window.removeEventListener('message', handler);
      };
    }, []);

    // hubspot handler on event listener
    const handler = async (event: any) => {
      // Callback that executes after form is validated, just before the data is actually sent.
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormSubmit'
      ) {
        if (event.data.id === 'd9f1edf9-5ff0-43be-bfa8-a48f1112a337') {
          const obj = {};
          // loop through the actual data that was sent to hubspot
          for (let i = 0; i < event.data.data.length; i++) {
            const newData = Object.values(event.data.data[i]);
            const entries = new Map([newData]);
            Object.assign(obj, Object.fromEntries(entries));
          }
          // rename hubspot object keys to our keys for eg: rename firstname --> firstName...etc
          const renameKey = (obj: any, old_key: string, new_key: string) => {
            // check if old key = new key
            if (old_key !== new_key) {
              Object.defineProperty(
                obj,
                new_key,
                Object.getOwnPropertyDescriptor(obj, old_key),
              );
              delete obj[old_key];
            }
          };
          for (const key in obj) {
            if (key === 'firstname') renameKey(obj, 'firstname', 'firstName');
            if (key === 'lastname') renameKey(obj, 'lastname', 'lastName');
            if (key === 'company') renameKey(obj, 'company', 'companyName');
            if (key === 'industry_demo_form') { renameKey(obj, 'industry_demo_form', 'occupation'); }
          }
          obj.captchaToken = await captchaRef.current.getValue();
          // call create org admin on submit button click
          await CreateOrgAction(obj);
          captchaRef.current.reset();
        }
      }
      // Callback the data is actually sent.
      if (
        event.data.type === 'hsFormCallback' &&
        event.data.eventName === 'onFormSubmitted'
      ) {
        // once form submitted redirect the user to signupSuccess page
        history.push('/signUpSuccess');
      }
    };

    return (
      <div>
        <Typography variant="h5" className={classnames(classes.details, { [classes.signupForm]: !disableSubmit })}>
          Verify to Sign up
        </Typography>
        <div className={classes.captcha}>
          <ReCAPTCHA
            sitekey={_GOOGLE_CAPTCHA_SITE_KEY_}
            onChange={() => setDisableSubmit(false)}
            ref={captchaRef}
          />
        </div>
        <div id="my-hubspot-form" className={disableSubmit && classes.signupForm} />
        <Typography className={classes.header} variant="h1">
          Start a free trial of AirWorks CAD
        </Typography>
        <Typography className={classes.details} variant="h5">
          Already have an account?
          {' '}
          <Link component={RouterLink} className={classes.forgotText} to="/">
            Login
          </Link>
        </Typography>
        <Typography className={classes.details} variant="h5">
          Looking for our automation software?
          {' '}
          <Link className={classes.forgotText} target="_blank" href="https://meetings.hubspot.com/oliver-fox1/talk-to-sales-learn-more-about-airworks">
            Contact our sales team
            {' '}
          </Link>
          to get a trial.
        </Typography>

        {/* we might need this in the future, if we can find a way to integrate hubspot form under react form   */}
        {/* <form
          className={classes.form}
          onSubmit={handleSubmit(ev =>
            Helpers.submit({ occupation, ...ev }, CreateOrgAction)
          )}
        >
          <div className={classes.formRow}>
            <Field
              name="firstName"
              label="First Name"
              variant="outlined"
              placeholder="First Name"
              component={TextField}
              validate={[Validators.required]}
            />
            <Field
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              variant="outlined"
              component={TextField}
              validate={[Validators.required]}
            />
          </div>
          <Field
            name="email"
            label="email"
            placeholder="email"
            variant="outlined"
            component={TextField}
            validate={[Validators.required]}
          />
          <Field
            name="companyName"
            label="Company Name / Organization"
            placeholder="Company Name"
            variant="outlined"
            component={TextField}
            validate={[Validators.required]}
          />
          <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel className={classes.occupationLabel}>
            {"Profession Type"}
          </InputLabel>
          <Select
            input={<InputBase style={{ width: "100%" }} />}
            value={occupation}
            onChange={e => handleChange(e)}
            variant="outlined"
            className={classes.dropdown}
          >
            {occupationList.map((o: any) => (
              <MenuItem value={o} className={classes.menuItem}>
                <Typography variant="h4" className={classes.innerDropdownText}>
                  {o}
                </Typography>
              </MenuItem>
            ))}
          </Select>
          </FormControl>
          <Collapse in={Boolean(error)}>
            <Typography variant="body1" className={classes.errorMessage}>
              {error}
            </Typography>
          </Collapse>
          <div className={classes.buttonWrapper}>
            <Button
              type="submit"
              variant="outlined"
              className={classes.button}
              disabled={!valid || submitting}
            >
              <Fade in={submitting}>
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              </Fade>
              <Fade in={!submitting}>
                <span>{"Sign Up"}</span>
              </Fade>
            </Button>
            <Typography variant="body1" className={classes.agreeTerms}>
              {"By clicking this button, you agree to the AirWorks"}{" "}
              <Link
                className={classes.forgotText}
                href="https://airworks.io/legal-tos-app/"
                target="_blank"
              >
                {"Terms & conditions."}
              </Link>
            </Typography>
          </div>
        </form> */}
      </div>
    );
  },
);

export default SignUp;
