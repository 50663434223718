// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';

import { GetList, SetList, SetLoadAll, SetLoadNew, SetSearchFilter, SetPage, SetRowsPerPage, SetSortFilter, DeleteOrg } from 'State/admin/thunk';

const mapStateToProps = (state: IState) => ({
  loading: state.admin.loading,
  loadAll: state.admin.loadAll,
  token: state.auth.token,
  list: state.admin.list,
  page: state.admin.page,
  rowsPerPage: state.admin.rowsPerPage,
  searchFilter: state.admin.searchFilter,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetListAction: GetList,
    SetListAction: SetList,
    SetLoadAllAction: SetLoadAll,
    SetLoadNewAction: SetLoadNew,
    SetSearchFilterAction: SetSearchFilter,
    SetPageAction: SetPage,
    SetRowsPerPageAction: SetRowsPerPage,
    SetSortFilterAction: SetSortFilter,
    DeleteOrgAction: DeleteOrg,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
