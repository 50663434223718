// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Paper, Button, Typography } from '@material-ui/core';
import moment from 'moment';
import { ACCOUNT_BILLING_ROUTE } from 'Utils/routes';
import { Link as RouterLink } from 'react-router-dom';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const Banner = ({ endDate }: PropsType) => {
  const classes = useStyles();
  const days = moment(endDate).diff(moment(), 'days');
  return (
    <>
      <Paper elevation={3} className={classes.paper}>
        <Typography variant="h5" className={classes.trialText}>
          You have
          {' '}
          {days}
          {' '}
          days remaining in your trial for Airworks CAD.
          <RouterLink to={ACCOUNT_BILLING_ROUTE} title="Subscriptions">
            <Button className={classes.upgradeButton}>
              Upgrade Now
            </Button>
          </RouterLink>
        </Typography>
      </Paper>
    </>
  );
};

export default compose(Banner);
