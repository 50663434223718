// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { RejectImagery } from 'State/project/thunk';

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    RejectImageryAction: RejectImagery,
  }, dispatch);

interface IOwnProps {
    showDialog: boolean;
    setShowDialog: (show: boolean) => void;
}

export type PropsType =
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, {}>(
  connect(null, mapDispatchToProps),
);
