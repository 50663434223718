// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { API_URL } from 'Config';
import { getJson, postJson } from 'Utils/http';
import { getOrders } from 'State/order/selectors';
import { SetOrder3DCadFilesListAction, SetSelectedCadFile3DLayersAction, GetSelectedCadFile3DLayersAysnc, Get3DLineworkAction, SetCad3DLayersAction } from './actions';

export const Init3DViewer = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const orders = getOrders(getState());
    const projectId = getState().project.project._id;
    const { token } = getState().auth;
    const order3DCadFilesList: {
        [key: string]: string[]
    } = {};
    const cadFile3DLayers: {
        [key: string]: ICad3DLayer
    } = {};
    const mostRecentSites: ILinework3DRequest[] = [];
    const processedOrders = orders.ordersWithCadFiles;
    await Promise.all(processedOrders.map(async (order) => {
      order3DCadFilesList[order._id] = null;
      const cadFileArray = order.cadFiles.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1)).map((cadFile) => {
        const url = `${API_URL}/tiles/${cadFile._id}/3DLayers`;
        return (async () => ({
          site_id: cadFile._id,
          result: await getJson<any[]>(url, token),
        }))();
      });
      const cadFilesResult = await Promise.all(cadFileArray);
      const cadFiles3D: string[] = [];
      let mostRecentFileLayers: ILayer[] = [];
      let mostRecentFile = '';
      cadFilesResult.forEach((fileResult) => {
        const { data } = fileResult?.result;
        if (data?.length > 0) {
          cadFiles3D.push(fileResult?.site_id);
          mostRecentFileLayers = data;
          mostRecentFile = fileResult?.site_id;
          cadFile3DLayers[fileResult.site_id] = { layers: data };
        }
      });

      if (cadFiles3D.length) {
        order3DCadFilesList[order._id] = cadFiles3D;
        mostRecentSites.push({
          siteId: mostRecentFile,
          projectId,
          layers: mostRecentFileLayers?.map((layer: any) => layer.layer_id),
        });
      } else {
        delete order3DCadFilesList[order._id];
      }

      dispatch(SetSelectedCadFile3DLayersAction({ orderId: order._id, cadFile3DLayers: { siteId: mostRecentFile, visible: true } }));
    }));
    dispatch(SetCad3DLayersAction(cadFile3DLayers));
    await Get3DLinework(mostRecentSites)(dispatch, getState);
    dispatch(SetOrder3DCadFilesListAction(order3DCadFilesList));
  };

export const Get3DLayersForFile = (orderId: string, siteId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    const projectId = getState().project.project._id;
    const url = `${API_URL}/tiles/${siteId}/3DLayers`;
    const result = await getJson<any[]>(url, token);
    dispatch(GetSelectedCadFile3DLayersAysnc.request());

    if (result.success) {
      const { data } = result;
      const layers: ILayer[] = [];
      if (data?.length > 0) {
        data.forEach((layer) => {
          layers.push({ ...layer, visible: true });
        });
        dispatch(GetSelectedCadFile3DLayersAysnc.success({ orderId, cadFile3DLayers: { siteId, visible: true } }));
      }
    } else {
      dispatch(GetSelectedCadFile3DLayersAysnc.failure(result.message));
    }
  };

export const Get3DLinework = (data: ILinework3DRequest[]) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { token } = getState().auth;
    dispatch(Get3DLineworkAction.request());
    const results = await Promise.all(data.map((site) => {
      const { projectId, siteId, layers } = site;
      const url = `${API_URL}/3D/getLineworks/${projectId}/${siteId}`;
      return postJson<{ site_id: string, data: ILayerLineworkData[] }>(url, { layers }, token);
    }));
    const lineworks: ILinework3DState[] = [];
    results.forEach((result) => {
      if (result.success) {
        lineworks.push(result.data);
      }
    });
    dispatch(Get3DLineworkAction.success(lineworks));
  };
