// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Typography, CircularProgress } from '@material-ui/core';
import { deliveryEstimate } from 'Utils/deliverables';
import WarningDeliverableDialog from 'Common/WarningDeliverableDialog';
import TalkToSalesDialog from 'Common/TalkToSalesDialog';
import BundleSelect from './BundleSelect';
import LayerSelect from './LayerSelect';
import DeliverableSelect from './DeliverableSelect';
import BundleButtons from './BundleButtons';

import compose from './container';

const Package = compose(
  ({
    classes,
    order,
    bundleDeliverables,
    additionalDeliverables,
    selectedAdditionals,
    SelectedAdditionalDeliverablesAndLayersAction,
    deliveryDays,
    currentBundleId,
    loading,
    availableBundles,
    availableCreditAcreage,
    costPerAcre,
    manageAddOns,
    deliverySpeed,
    lowDensity,
    isSaved,
    page,
    SetDeliveryDaysAction,
    SetDeliveryDateAction,
    checkFirstTimeChangeEstimate,
    bundleStep,
    setBundleStep,
    bundleCost,
    acreAdjustmentFactor,
    addOnDeliverableDollars,
    addOnLayerDollars,
    zeroAddOns,
  }) => {
    const [unselectedDependencies, setUnselectedDependencies] = useState([]);
    const [unselectedDependencyNames, setUnselectedDependencyNames] = useState([]);
    const [childDependencyCheck, setChildDependencyCheck] = useState(false);
    const [selectItem, setSelectItem] = useState(null);
    const [contactSalesInteraction, setContactSalesInteraction] = useState(false);
    const [initialActiveIndex, setInitialActiveIndex] = useState(0);
    useEffect(() => {
      if (order.bundleName === 'Topo') {
        setInitialActiveIndex(1);
      }
    }, [order.bundleName]);

    useEffect(() => {
      if (availableBundles?.length > 0 && !contactSalesInteraction && (availableCreditAcreage < order.acreage)) {
        const costB = availableCreditAcreage * costPerAcre + bundleCost;
        let orderAdjustedAcres = order.acreage;
        let addOnCost = 0;

        if (order.isEstimate) {
          orderAdjustedAcres = order.acreage * acreAdjustmentFactor;

          if (order.addOns.length > 0 && additionalDeliverables.length > 0) {
            order.addOns.forEach((addOn) => {
              const additional = additionalDeliverables.find((deliverable: IDeliverable) => deliverable.name === addOn.name);
              const flatFee = additional.costPerFactors[0];
              const costPerAcreAddon = additional.costPerFactors[1];
              addOnCost += (flatFee + costPerAcreAddon * order.acreage);
            });
          }
        }

        const costA = orderAdjustedAcres * costPerAcre + bundleCost;
        window?.pendo?.track('Out of Credits', {
          orderAcreage: order.acreage,
          costWithRenewing: costA,
          costWithoutRenewing: costB,
        });
      }
    }, [availableBundles, contactSalesInteraction, availableCreditAcreage, order.acreage]);

    const selectAdditionalDeliverable = (data: IDeliverable, checked: boolean) => {
      let days = deliveryDays;
      const { deliverableId, type, acreFactor, time } = data;
      if (checked) {
        days += time;
        // When we add the first add-on to A la carte bundle, add 1.5 or 2 days depending on expedited or low density vs normal
        if (currentBundleId === 5 && selectedAdditionals.length === 0) {
          if (deliverySpeed === 'expedited' || lowDensity) days += 1.5;
          else if (deliverySpeed === 'super_expedited') days += 1;
          else days += 2;
        }
      } else {
        days -= time;
        if (currentBundleId === 5 && selectedAdditionals.length === 1) {
          if (deliverySpeed === 'expedited' || lowDensity) days -= 1.5;
          else if (deliverySpeed === 'super_expedited') days -= 1;
          else days -= 2;
        }
      }
      manageAddOns(deliverableId, type, acreFactor, checked);

      const calculateDate = deliveryEstimate(days);
      SetDeliveryDaysAction(days);
      SetDeliveryDateAction(calculateDate);
      SelectedAdditionalDeliverablesAndLayersAction(data);
    };

    const onCancelWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setUnselectedDependencies([]);
      const deliverable = additionalDeliverables.find((del) => del._id === selectItem);
      selectAdditionalDeliverable(deliverable, false);
      setSelectItem(null);
      e.stopPropagation();
    };

    const onCancelRemoveWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      setUnselectedDependencies([]);
      const deliverable = additionalDeliverables.find((del) => del._id === selectItem);
      selectAdditionalDeliverable(deliverable, true);
      setSelectItem(null);
      e.stopPropagation();
    };

    const onOkWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      unselectedDependencies.forEach((dep) => {
        const deliverable = additionalDeliverables.find((del) => del._id === dep);
        selectAdditionalDeliverable(deliverable, true);
      });
      setUnselectedDependencies([]);
      e.stopPropagation();
    };

    const onOkChildWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      unselectedDependencies.forEach((dep) => {
        const deliverable = additionalDeliverables.find((del) => del._id === dep);
        SelectedAdditionalDeliverablesAndLayersAction(deliverable);
        manageAddOns(deliverable.deliverableId, deliverable.type, deliverable.acreFactor, false);
      });
      setUnselectedDependencies([]);
      e.stopPropagation();
    };

    const onAddOnsClick = (item: IDeliverable, checked: boolean) => {
      checkFirstTimeChangeEstimate(() => {
        checkParentDependencies(item, checked);
        selectAdditionalDeliverable(item, checked);
      });
    };

    const bundleOption = () => {
      switch (bundleStep) {
      case 0:
        return (<BundleSelect deliverySpeed={deliverySpeed} zeroAddOns={zeroAddOns} checkFirstTimeChangeEstimate={checkFirstTimeChangeEstimate} />);
      case 1:
        return (<DeliverableSelect onAddOnsClick={onAddOnsClick} />);
      case 2:
        return (<LayerSelect onAddOnsClick={onAddOnsClick} />);
      default:
        break;
      }
    };

    const checkParentDependencies = (item: any, checked: boolean) => {
      const _un: any[] = [];
      const _names: string[] = [];
      if (checked) {
        setChildDependencyCheck(false);
        item.dependencies.forEach((dep: any) => {
          const dependencySelected = bundleDeliverables.some((del) => del._id === dep) || selectedAdditionals.some((del) => del._id === dep);
          if (!dependencySelected) {
            const deliverable = additionalDeliverables.find((b) => b._id === dep);
            _un.push(dep);
            _names.push(deliverable.name);
          }
        });
      } else {
        setChildDependencyCheck(true);
        item.childDependencies.forEach((dep: any) => {
          const dependencySelected = bundleDeliverables.some((del) => del._id === dep) || selectedAdditionals.some((del) => del._id === dep);
          if (dependencySelected) {
            const deliverable = additionalDeliverables.find((b) => b._id === dep);
            _un.push(dep);
            _names.push(deliverable.name);
          }
        });
      }
      setUnselectedDependencies(_un);
      setUnselectedDependencyNames(_names);
      setSelectItem(item._id);
    };

    const contactSalesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setContactSalesInteraction(true);
      window.open('mailto:sales@airworks.io', '_blank');
    };

    return (
      <div className={classes.packageColumn}>
        {isSaved && <Typography className={classes.alert}>Alert: Any changes to your bundle or add-ons will invalidate your saved estimate.</Typography>}
        <Typography className={classes.pageTitle} variant="h1">Select Base Package and Add-Ons</Typography>
        <Typography>
          This is your base package that is included in your plan and comes with every project. You may choose another base package for this project; please note that any price differences will be adjusted in your usage at the end of the billing period. Please contact sales for questions or more info.
        </Typography>
        {(page === 0 && availableBundles?.length) ? (
          <div>
            {bundleOption()}
            <BundleButtons
              bundleStep={bundleStep}
              setBundleStep={setBundleStep}
            />
          </div>
        ) : '' }
        {(page === 0 && loading) ? (
          <div className={classes.spinner}>
            <CircularProgress color="primary" size={50} />
          </div>
        ) : ''}
        <WarningDeliverableDialog
          showDialog={unselectedDependencies.length > 0 && !childDependencyCheck}
          onOk={onOkWarning}
          onCancel={onCancelWarning}
          titleText="This add-on requires the following items to be selected in order to be processed:"
          dependencyList={unselectedDependencyNames}
          questionText="Would you like to add required items to your order?"
          okText="Yes, add items"
        />
        <WarningDeliverableDialog
          showDialog={unselectedDependencies.length > 0 && childDependencyCheck}
          onOk={onOkChildWarning}
          onCancel={onCancelRemoveWarning}
          titleText="This item is required by the following add-ons in order to be processed:"
          dependencyList={unselectedDependencyNames}
          questionText="Would you like to remove these add-ons from your order?"
          okText="Yes, remove items"
        />
        {(availableBundles?.length > 0) && (
          <TalkToSalesDialog
            showDialog={!contactSalesInteraction && (availableCreditAcreage < order.acreage || (addOnDeliverableDollars + addOnLayerDollars) > 0)}
            onOk={contactSalesClick}
            onProceed={() => {
              setContactSalesInteraction(true);
            }}
            onCancel={() => {
              setContactSalesInteraction(true);
            }}
            addOnDeliverableDollars={addOnDeliverableDollars}
            addOnLayerDollars={addOnLayerDollars}
            titleText="Credit Limit Reached!"
          />
        )}
      </div>
    );
  },
);

export default Package;
