// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  option: {
    padding: '5px',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  icon: {
    height: '30px',
    width: '60px',
  },
  panelCurrent: {
    backgroundColor: '#ffffff',
  },
}));

export default useStyles;
