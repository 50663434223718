// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography } from '@material-ui/core';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const SelectionInfo = ({ order }: PropsType) => {
  const classes = useStyles();

  return (
    <>
      {order && order.middlePoint && Object.entries(order.middlePoint).length > 0 && (
        <div className={classes.selection}>
          <div className={classes.selectionRow}>
            <Typography variant="h2">Your selection</Typography>
          </div>
          <div className={classes.selectionRow}>
            <Typography variant="h4" className={classes.padding}>Location</Typography>
            <Typography variant="body1">
              {`${order.middlePoint.place}, ${order.middlePoint.region} ${order.middlePoint.postcode}`}
              <br />
              {`${order.middlePoint.longitude.toFixed(5)}, ${order.middlePoint.latitude.toFixed(5)}`}
            </Typography>
          </div>
          <div className={classes.selectionRow}>
            <Typography variant="h4" className={classes.padding}>Acreage</Typography>
            <Typography variant="body1">{`${order.acreage} acres`}</Typography>
          </div>
        </div>
      )}
    </>
  );
};

export default compose(SelectionInfo);
