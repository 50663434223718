// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { Menu, MenuItem, Checkbox, InputBase, Divider, Typography, List, ListItem, Dialog, DialogActions, DialogContent, ListItemSecondaryAction, Button } from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import aiIcon from 'Assets/ai.png';
import LinePropertiesFlyout from './LinePropertiesFlyout/index';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');

export const ProjectLayersList = ({ order, layers, siteId, editorFunctions, fileVersions, layerSelected, expressAIInfo, ToggleProjectLayerAction, SetlayerSelectedAction, SetOrderSelectedAction, AddNewLayerAction, EditLayerAttributeAction, AutosaveAction, DeleteLayerAction }: PropsType) => {
  const classes = useStyles();
  const [checked, setChecked] = useState(true);
  const [layerName, setLayerName] = useState(null);
  const [edit, setEdit] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [newLayer, setNewLayer] = useState(false);
  const [mouseX, setMouseX] = useState(null);
  const [mouseY, setMouseY] = useState(null);
  const [deleteLayerId, setDeleteLayerId] = useState(null);

  const inputRef = useRef(null);

  const handleClick = (event, layerId) => {
    setDeleteLayerId(layerId);
    event.preventDefault();
    setMouseX(event.clientX - 2);
    setMouseY(event.clientY - 4);
  };

  const handleClose = () => {
    setMouseX(null);
    setMouseY(null);
  };

  const handleDelete = async () => {
    handleClose();
    await DeleteLayerAction(siteId, deleteLayerId);
  };

  useEffect(() => {
    setChecked(layers.visible);
  }, [layers.visible]);

  useEffect(() => {
    // When we create a new layer and the newly created layer is appended to the vector_layers array and it's name includes 'untitled-layer'
    // Set this newly created layer in edit mode
    if (newLayer && (layers && layers.vector_layers.length > 0 && layers.vector_layers[0].id === layerSelected) && layerSelected.includes('untitled-layer')) {
      setLayerName(layerSelected);
      setEdit(true);
      setNewLayer(false);
    }
  }, [layerSelected, newLayer]);

  // Function to call add new layer action as well as autosave currently drawn entities
  const onAddNewLayerClick = () => {
    AutosaveAction();
    AddNewLayerAction(order._id, siteId);
    setNewLayer(true);
  };

  return (
    <div className={classes.listWrapper}>
      <div className={classes.projectLayersText}>
        <Typography variant="h6" className={classes.layersLabel}>
          Project Layers
        </Typography>
      </div>
      <Divider className={classes.divider} />
      {editorFunctions && (
        <div>
          <Button
            className={classes.newLayerButton}
            onClick={() => onAddNewLayerClick()}
          >
            <AddCircleOutline className={classes.addIcon} />
            <Typography variant="h6" color="primary">
              New layer
            </Typography>
          </Button>
          <Divider className={classes.layerDivider} />
        </div>
      )}
      <List>
        {editorFunctions && (
          <Menu
            keepMounted
            open={mouseY !== null}
            onClose={handleClose}
            anchorReference="anchorPosition"
            anchorPosition={
              mouseY !== null && mouseX !== null
                ? { top: mouseY, left: mouseX }
                : undefined
            }
          >
            <MenuItem onClick={handleDelete}>Delete layer</MenuItem>
          </Menu>
        )}
        {layers.vector_layers.map((layer, index) => (
          <div key={layer.layerId} className={classes.listItemWrapper}>
            <ListItem
              selected={editorFunctions && layer.id === layerSelected}
              classes={{ root: classes.listItem }}
              onContextMenu={(e) => handleClick(e, layer.layerId)}
              style={{ cursor: editorFunctions ? 'context-menu' : 'default' }}
            >
              <ListItemSecondaryAction
                classes={{ root: classes.checkboxWrapper }}
              >
                <Checkbox
                  color="primary"
                  onChange={() => {
                    ToggleProjectLayerAction(order._id, layer.id, fileVersions[order._id]);
                  }}
                  checked={checked && layer.visible}
                  classes={{
                    root: classes.checkbox,
                  }}
                />
              </ListItemSecondaryAction>
              {editorFunctions && (
                <>
                  {/* Display the list item as a button when
                      - On initial page load  when layerSelected = null or
                      - When edit = false or
                      - If a layer is not a selected layer */}
                  {!edit || !layerSelected || !(layer.id === layerSelected) ? (
                    <Button
                      classes={{
                        root: classes.listItemButton,
                        label: classes.buttonText,
                      }}
                      disableRipple
                      onClick={() => {
                        if (edit) setEdit(false);
                        setLayerName(null); // hmmmm
                        SetlayerSelectedAction(layer.id);
                        SetOrderSelectedAction(order._id);
                      }}
                      onDoubleClick={() => {
                        setLayerName(layer.id);
                        setEdit(true);
                      }}
                    >
                      <span className={classes.layerText}>{layer.id}</span>
                    </Button>
                  ) : (
                    <div>
                      {/* Otherwise the list item is an input field */}
                      <InputBase
                        className={classes.inputField}
                        // defaultValue={layer.id}
                        ref={inputRef}
                        autoFocus
                        value={layerName}
                        onChange={(e) => {
                          setLayerName(e.target.value);
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter' || e.key === 'Escape') {
                            // check if layer name has changed
                            if (!layerName || layerName.trim() === layer.id) {
                              setEdit(false);
                              setLayerName(null);
                              return;
                            }
                            // check if layer name is duplicate and display the warning dialog
                            for (let i = 0; i < layers.vector_layers.length; i++) {
                              if (layers.vector_layers[i].id === layerName) {
                                setShowDialog(true);
                                setEdit(false);
                                return;
                              }
                            }
                            // else call the editLayer API
                            EditLayerAttributeAction(layer.layerId, siteId, { name: layerName });
                            e.preventDefault();
                            e.stopPropagation();
                            setLayerName(null);
                            setEdit(false);
                          }
                        }}
                      />
                    </div>
                  )}
                </>
              )}
              {!editorFunctions && (
                <Typography variant="h5" className={classes.listItemText}>
                  {layer.id}
                </Typography>
              )}
              <LinePropertiesFlyout layer={layer} siteId={siteId} />
            </ListItem>
          </div>
        ))}
        {expressAIInfo && (
          <div className={classes.expressAIInfo}>
            <img className={classes.aiIcon} src={aiIcon} alt="Express AI Upload" />
            <div className={classes.aiTextBox}>
              <Typography className={classes.aiText}>This output is full-AI. A fully QC’d dxf will be uploaded by</Typography>
              <Typography className={classes.aiTextDate} color="secondary">{`${moment(order.deliveryEstimate).format('LL')}`}</Typography>
            </div>
          </div>
        )}
      </List>
      <Dialog className={classes.dialog} open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <Typography className={classes.dialogText}>
            {`The name "${layerName}" is already taken. Please choose a different name`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button className={classes.dialogButton} onClick={() => setShowDialog(false)}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default compose(ProjectLayersList);
