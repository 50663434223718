// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { getCompletedOrders } from 'State/order/selectors';
import { GetProjects, SetLoadNew, SetKeywordFilter } from 'State/project/thunk';
import { GetOrgTeams } from 'State/account/thunk';
import { GetOrders } from 'State/order/thunk';
import { GetOrg, SetOrgId } from 'State/admin/thunk';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps<{ orgId: string }>) => ({
  list: getCompletedOrders(state),
  organization: _ADMIN_ ? state.admin.org?.companyName : state.account.org?.companyName,
  orgId: ownProps.match.params.orgId,
  orgTeams: state.account.orgTeams,
});

const mapDispatchToProps = {
  GetOrgAction: GetOrg,
  GetOrgTeamsAction: GetOrgTeams,
  GetProjectsAction: GetProjects,
  GetOrdersAction: GetOrders,
  SetLoadNewAction: SetLoadNew,
  SetKeywordFilterAction: SetKeywordFilter,
  SetOrgIdAction: SetOrgId,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps<{ orgId: string }>;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
