// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Button, CircularProgress, Fade } from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import { Helpers } from 'Common/forms';
import CreditCardControls from '../../CreditCardControls';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const CreateCardForm = ({ initialValues, onCancel, EditCreditCardAction }: PropsType) => {
  const classes = useStyles();
  const [formState, setFormState] = useState(null);

  useEffect(() => {
    if (formState?.submitSucceeded) {
      onCancel();
    }
  }, [formState]);

  const onFormSubmit = (values: any) => Helpers.submit(values, EditCreditCardAction);

  return (
    <Form
      onSubmit={onFormSubmit}
      keepDirtyOnReinitialize
      initialValues={initialValues}
      render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <FormSpy onChange={(state) => {
            setFormState(state);
          }}
          />
          <CreditCardControls editable initialValues={initialValues} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
            className={classes.submitButton}
          >
            <Fade in={submitting}>
              <CircularProgress size={24} className={classes.buttonProgress} />
            </Fade>
            <Fade in={!submitting}>
              <span>Edit credit card</span>
            </Fade>
          </Button>
          <Button variant="outlined" onClick={onCancel}>Cancel</Button>
        </form>
      )}
    />
  );
};

export default compose(CreateCardForm);
