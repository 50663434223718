/* eslint-disable react/jsx-props-no-spreading */
// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { FormControl, InputLabel, Select, MenuItem, FormHelperText, SelectProps } from '@material-ui/core';
import { Field, FieldRenderProps } from 'react-final-form';

interface SelectFieldProps {
  label: string;
  values: any;
  renderValue: any;
  renderMenuItems: any;
}

type SelectWrapperProps = FieldRenderProps<SelectProps & SelectFieldProps>;

const selectComponent = (props: SelectWrapperProps) => {
  const {
    input, label, values, className, meta: { invalid, touched, error }, renderValue, renderMenuItems,
  } = props;
  let hasError = false;
  if (error !== undefined) {
    hasError = true;
  }
  const shouldShowError = touched && invalid;
  hasError = shouldShowError && hasError;

  return (
    <FormControl error={hasError} className={className} margin="normal">
      {label && <InputLabel>{label}</InputLabel>}
      <Select {...input} renderValue={renderValue}>
        {renderMenuItems ? renderMenuItems() : values.map((v: any) => <MenuItem key={v} value={v}>{v}</MenuItem>)}
      </Select>
      {hasError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

const select = (props: any) => (
  <Field
    component={selectComponent}
    {...props}
  />
);

export default select;
