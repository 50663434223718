// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const SetKmlAction = createAction('SET_KML', (action) => (data: { orderId: string, kml: GeoJSON.FeatureCollection<GeoJSON.Polygon> }) => action(data));

export const LoadKmlAsync = createAsyncAction(
  'LOAD_KML_START',
  'LOAD_KML_SUCCESS',
  'LOAD_KML_FAILED',
  // tslint:disable-next-line: array-type
)<null, { orderId: string, kml: GeoJSON.FeatureCollection<GeoJSON.Polygon> }[], string>();

export const ToggleKmlVisibility = createAction('TOGGLE_KML_VISIBILITY', (action) => (id: string) => action(id));

export const AdminToggleKmlVisibility = createAction('ADMIN_TOGGLE_KML_VISIBILITY', (action) => (id: string) => action(id));

export const ClearKmlsAction = createAction('CLEAR_KMLS');

export const SetShowBusinessDaysAction = createAction('SET_SHOW_BUSINESS_DAYS', (action) => (data: boolean) => action(data));

export const SetLineStringUploadAction = createAction('SET_LINESTRING_KML', (action) => (data: boolean) => action(data));

export const SetShowBufferInfoAction = createAction('SET_SHOW_BUFFER_INFO', (action) => (data: boolean) => action(data));

export const SetBufferSizeAction = createAction('SET_BUFFER_SIZE', (action) => (data: number) => action(data));

export const SetKmlErrorAction = createAction('SET_KML_ERROR', (action) => (data: boolean) => action(data));

export const ResetBufferStateAction = createAction('RESET_BUFFER_STATE');

export const CreateKmlBufferAsync = createAsyncAction(
  'CREATE_KML_BUFFER_START',
  'CREATE_KML_BUFFER_SUCCESS',
  'CREATE_KML_BUFFER_FAILED',
)<null, null, null>();

export const SetKmlBBoxAction = createAction('SET_KML_BBOX', (action) => (data: number[]) => action(data));
