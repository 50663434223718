// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  FileUploadStartAction, FileUploadUpdateAction, FileUploadCancelAction, FileUploadInitAction, SetExpressAIUploadAction, SendExpressAIEmailAsync, SetDxfEpsgAction, SetEpsgListAction, SetImageryCaptureDateAction,
} from './actions';

const initialState: IFileUploadState = {
  uploadFiles: [],
  uploading: false,
  uploadSuccess: false,
  expressAIUpload: false,
  emailLoading: false,
  epsg: null,
  epsgList: [],
  imageryCaptureDate: null,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(FileUploadStartAction, (draft, payload) => { draft.uploadFiles.push(payload); });
  handleAction(FileUploadUpdateAction, (draft, payload) => {
    const index = draft.uploadFiles.findIndex((f) => f.id === payload.id);
    if (index >= 0) {
      if (!draft.uploadFiles[index].fileId) {
        Object.assign(draft.uploadFiles[index], payload);
      } else {
        draft.uploadFiles[index].progress = payload.progress;
      }
    }
  });
  handleAction(FileUploadCancelAction, (draft, payload) => { draft.uploadFiles = draft.uploadFiles.filter((f) => f.id !== payload); });
  handleAction(FileUploadInitAction, (draft) => {
    draft.uploadFiles = initialState.uploadFiles;
    draft.uploading = initialState.uploading;
    draft.uploadSuccess = initialState.uploadSuccess;
  });
  handleAction(SetExpressAIUploadAction, (draft) => { draft.expressAIUpload = !draft.expressAIUpload; });

  handleAction(SendExpressAIEmailAsync.request, (draft) => { draft.emailLoading = true; });
  handleAction(SendExpressAIEmailAsync.success, (draft) => {
    draft.emailLoading = false;
  });
  handleAction(SendExpressAIEmailAsync.failure, (draft) => { draft.emailLoading = false; });
  handleAction(SetDxfEpsgAction, (draft, payload) => { draft.epsg = payload; });
  handleAction(SetEpsgListAction, (draft, payload) => { draft.epsgList = payload; });
  handleAction(SetImageryCaptureDateAction, (draft, payload) => { draft.imageryCaptureDate = payload; });
});
