// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import moment from 'moment-timezone';
import { Typography, Select, MenuItem, InputBase, Divider, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import MomentUtils from '@date-io/moment';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import TextEditor from 'Common/TextEditor';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');

export const SubscriptionInfo = ({ org, cadSubscription, automateSubscription, costPerAcre, UpdateCostPerAcreAction, UpdateOrgAction, createdAt, imageryProviders }: PropsType) => {
  const classes = useStyles();

  const [openDatepicker, setOpenDatepicker] = useState(false);

  const [selectedDate, handleDateChange] = useState(org.currentContractStart || createdAt);
  const { name, automateCurrentPeriodEnd, automateStartingAcres, automateAcresProcessed, automateAcresProcessing, automateAcresRemaining } = automateSubscription;

  const handleChange = (e) => {
    handleDateChange(e.format());
  };

  const handleChangeNearmap = (value: string) => {
    UpdateOrgAction({ ...org }, value);
  };

  const handleAccept = () => {
    setOpenDatepicker(false);
    UpdateOrgAction({ ...org, currentContractStart: selectedDate });
  };

  return (
    <>
      <div className={classes.subscriptionDate}>
        <Typography>Subscription Start Date</Typography>
        { openDatepicker ? (
          <div className={classes.dateAndButtons}>
            <div>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  allowKeyboardControl={false}
                  variant="inline"
                  format="MM/DD/yyyy"
                  value={selectedDate}
                  onChange={(date) => handleChange(date)}
                />
              </MuiPickersUtilsProvider>
            </div>
            <div className={classes.buttons}>
              <IconButton
                color="inherit"
                onClick={() => setOpenDatepicker(false)}
                aria-label="Close"
              >
                <ClearIcon fontSize="small" />
              </IconButton>
              <IconButton
                color="inherit"
                onClick={handleAccept}
                aria-label="Close"
              >
                <CheckIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        ) : (
          <div className={classes.dateAndButtons}>
            <Typography>{moment(selectedDate).format('LL')}</Typography>
            <IconButton
              color="inherit"
              onClick={() => setOpenDatepicker(true)}
              aria-label="Close"
            >
              <EditIcon fontSize="small" />
            </IconButton>
          </div>
        )}
      </div>
      <div className={classes.subscriptionDate}>
        <Typography>Nearmap Tier</Typography>
        {imageryProviders && (
          <Select
            variant="outlined"
            input={<InputBase style={{ width: '150px' }} />}
            value={org.imageryProviders[0]}
            onChange={(e) => handleChangeNearmap(e.target.value)}
          >
            {
              imageryProviders.map(({ name: ipName, _id }) => <MenuItem value={_id}>{ipName}</MenuItem>)
            }
          </Select>
        )}
      </div>
      {Object.keys(cadSubscription).length > 0 && (
        <>
          <div className={classes.contentCad}>
            <Typography variant="h5">{cadSubscription.name}</Typography>
            <Typography>
              {cadSubscription.cadSeatsTaken}
              {' '}
              of
              {' '}
              {cadSubscription.cadSeatsAvailable}
              {' '}
              user assigned
            </Typography>
            <Typography>
              Automatically renews on
              {' '}
              {moment(cadSubscription.cadCurrentPeriodEnd).format('LL')}
            </Typography>
          </div>
        </>
      )}
      <Divider className={classes.divider} />
      {Object.keys(automateSubscription).length > 0 && (
        <>
          <div className={classes.contentAutomate}>
            <Typography variant="h5">{name}</Typography>
            <Typography> </Typography>
            <Typography>
              Eligible to renew on
              {' '}
              {moment(automateCurrentPeriodEnd).format('LL')}
            </Typography>
            <Typography> </Typography>
            <Typography>Starting Credits</Typography>
            <Typography>{automateStartingAcres ? Number.parseFloat(automateStartingAcres).toFixed(2) : 'N/A'}</Typography>
            <Typography>Credits Processed</Typography>
            <Typography>{automateAcresProcessed ? automateAcresProcessed.toFixed(2) : 'N/A'}</Typography>
            <Typography>Credits in Processing</Typography>
            <Typography>{automateAcresProcessing ? automateAcresProcessing.toFixed(2) : 'N/A'}</Typography>
            <Typography>Credits Remaining</Typography>
            <Typography>{automateAcresRemaining ? automateAcresRemaining.toFixed(2) : 'N/A'}</Typography>
            <Typography>Price per Credit</Typography>
            <TextEditor initialValue={String(costPerAcre)} updateValue={UpdateCostPerAcreAction} formatValue={(value) => `$${value}`} />
          </div>
        </>
      )}
    </>
  );
};

export default compose(SubscriptionInfo);
