// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  popper: {
    zIndex: 1000,
    width: 350,
  },
  downloadPanel: {
    padding: theme.spacing(2),
  },
  downloadButtons: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridRowGap: theme.spacing(0.5),
    gridColumnGap: theme.spacing(),
    alignItems: 'center',
    wordBreak: 'break-all',
  },
  downloadButton: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: theme.spacing(),
    paddingRight: theme.spacing(),
    minWIdth: 0,
    textTransform: 'uppercase',
  },
  buttonProgress: {
    marginRight: theme.spacing(1),
  },
  snackBar: {
    top: '90px',
    bottom: '0px',
  },
  alert: {
    backgroundColor: 'white',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  icon: {
    fontSize: theme.spacing(2),
    cursor: 'pointer',
  },
  link: {
    color: theme.palette.primary.main,
  },
  expressButtonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
  warningIcon: {
    color: '#01a1dd',
    fontSize: '26px',
  },
  warningTitle: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
    padding: '16px 24px',
    gridColumnGap: theme.spacing(1),
  },
  dialog: {
    minWidth: '600px',
    margin: 'auto',
  },
  dialogContent: {
    padding: theme.spacing(2),
  },
  deliveryDate: {
    color: '#01a1dd',
  },
  emptyLayers: {
    marginLeft: '22px',
  },
  emptyLayersLabel: {
    fontSize: '14px',
  },
  smallCheckbox: {
    '& .MuiSvgIcon-root': {
      width: '18px',
    },
  },
}));

export default useStyles;
