// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { FormControlLabel, Typography, ListItem, Checkbox } from '@material-ui/core';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const LayerSelect = ({ bundleName, bundleId, additionalDeliverables, selectedAdditionals, order, onAddOnsClick }: PropsType) => {
  const classes = useStyles();
  const utilitiesBoolean = additionalDeliverables.some((d) => d.subtype === 'Utilities');
  const roadsAndSidewalkBoolean = additionalDeliverables.some((d) => d.subtype === 'Road & Sidewalk');
  const vegetationAndLandscapeBoolean = additionalDeliverables.some((d) => d.subtype === 'Vegetation & Landscape');
  const topographyLayerBoolean = additionalDeliverables.some((d) => d.subtype === 'Topography' && d.type === 'layer');
  const administrativeBoolean = additionalDeliverables.some((d) => d.subtype === 'Administrative & Annotations');
  const planimetricsBoolean = additionalDeliverables.some((d) => d.subtype === 'Planimetrics');
  const measurementsBoolean = additionalDeliverables.some((d) => d.subtype === 'Measurements');
  const wallsAndFencesBoolean = additionalDeliverables.some((d) => d.subtype === 'Walls & Fences');
  const additionalLayersBoolean = additionalDeliverables.some((d) => d.subtype === 'Additional Layers');

  return (
    <div className={bundleName === 'Topo' && classes.unavailableDeliverables}>
      <Typography className={classes.addonsHeading} variant="h3">
        Layers
      </Typography>
      {bundleName === 'Topo' ? (
        <Typography className={classes.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add 2D features at checkout
        </Typography>
      ) : <Typography className={classes.description}>Please select any additional layers you’d like to be drafted in your project.</Typography> }
      {(bundleId === 4 || bundleId === 5) && (
        <Typography className={classes.noDeliverableMessage}>
          Please select the 2D + TOPO bundle to add the disabled layers at checkout
        </Typography>
      )}
      {utilitiesBoolean && (
        <div>
          <Typography className={classes.addonsHeading} variant="h4">
            Utilities
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Utilities' ? (
              <div className={(bundleName === '3D Feature Elevation' && !(item.deliverableId === 44 || item.deliverableId === 45)) && classes.unavailableDeliverables}>
                <ListItem button disableRipple className={classes.listItem}>
                  <FormControlLabel
                    label={(
                      <div>
                        <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                      </div>
                    )}
                    control={(
                      <Checkbox
                        classes={{
                          checked: classes.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography className={classes.right} component="span">
                        &nbsp;
                    {' '}
                    {`${(item.acreFactor * order.acreage).toFixed(2)} Credits`}
                  </Typography>
                </ListItem>
              </div>
            ) : null))}
        </div>
      ) }
      {roadsAndSidewalkBoolean && (
        <div>
          <Typography className={classes.addonsHeading} variant="h4">
            Road & Sidewalk
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Road & Sidewalk' ? (
              <ListItem button disableRipple className={classes.listItem}>
                <FormControlLabel
                  label={(
                    <div>
                      <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                    </div>
                  )}
                  control={(
                    <Checkbox
                      classes={{
                        checked: classes.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography className={classes.right} component="span">
                      &nbsp;
                  {' '}
                  {`${(item.acreFactor * order.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </div>
      ) }
      {vegetationAndLandscapeBoolean && (
        <div>
          <Typography className={classes.addonsHeading} variant="h4">
            Vegetation & Landscape
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Vegetation & Landscape' ? (
              <div className={(bundleName === '3D Feature Elevation' && !(item.deliverableId === 39)) && classes.unavailableDeliverables}>
                <ListItem button disableRipple className={classes.listItem}>
                  <FormControlLabel
                    label={(
                      <div>
                        <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                      </div>
                    )}
                    control={(
                      <Checkbox
                        classes={{
                          checked: classes.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography className={classes.right} component="span">
                        &nbsp;
                    {' '}
                    {`${(item.acreFactor * order.acreage).toFixed(2)} Credits`}
                  </Typography>
                </ListItem>
              </div>
            ) : null))}
        </div>
      ) }
      {topographyLayerBoolean && (
        <div className={bundleName === '2D' && classes.unavailableDeliverables}>
          <Typography className={classes.addonsHeading} variant="h4">
            Topography
          </Typography>
          {bundleName === '2D' && (
            <Typography className={classes.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Topography features at checkout
            </Typography>
          )}
          {additionalDeliverables.map((item) =>
            ((item.type === 'layer' && item.subtype === 'Topography') ? (
              <ListItem button disableRipple className={classes.listItem}>
                <FormControlLabel
                  label={(
                    <div>
                      <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                    </div>
                  )}
                  control={(
                    <Checkbox
                      classes={{
                        checked: classes.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography className={classes.right} component="span">
                      &nbsp;
                  {' '}
                  {`${(item.acreFactor * order.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </div>
      ) }
      { administrativeBoolean && (
        <div className={(bundleId === 0 || bundleId === 5) && classes.unavailableDeliverables}>
          <Typography className={classes.addonsHeading} variant="h4">
            Administrative & Annotations
          </Typography>
          {(bundleId === 0 || bundleId === 5) && (
            <Typography className={classes.noDeliverableMessage}>
              Please select the 2D + TOPO bundle to add Administrative & Annotations features at checkout
            </Typography>
          )}
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Administrative & Annotations' ? (
              <ListItem button disableRipple className={classes.listItem}>
                <FormControlLabel
                  label={(
                    <div>
                      <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                    </div>
                  )}
                  control={(
                    <Checkbox
                      classes={{
                        checked: classes.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography className={classes.right} component="span">
                      &nbsp;
                  {' '}
                  {`${(item.acreFactor * order.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </div>
      ) }
      { planimetricsBoolean && (
        <div className={bundleId === 4 && classes.unavailableDeliverables}>
          <Typography className={classes.addonsHeading} variant="h4">
            Planimetrics
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Planimetrics' ? (
              <div className={(!item.selectable || (bundleName === '3D Feature Elevation' && !(item.deliverableId === 21))) && classes.unavailableDeliverables}>
                <ListItem button disableRipple className={classes.listItem}>
                  <FormControlLabel
                    label={(
                      <div>
                        <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                      </div>
                    )}
                    control={(
                      <Checkbox
                        classes={{
                          checked: classes.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography className={classes.right} component="span">
                        &nbsp;
                    {' '}
                    {`${(item.acreFactor * order.acreage).toFixed(2)} Credits`}
                  </Typography>
                </ListItem>
              </div>
            ) : null))}
        </div>
      ) }
      { measurementsBoolean && (
        <div>
          <Typography className={classes.addonsHeading} variant="h4">
            Measurements
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Measurements' ? (
              <ListItem button disableRipple className={classes.listItem}>
                <FormControlLabel
                  label={(
                    <div>
                      <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                    </div>
                  )}
                  control={(
                    <Checkbox
                      classes={{
                        checked: classes.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography component="span">
                  {`${(item.acreFactor * order.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </div>
      ) }
      { wallsAndFencesBoolean && (
        <div className={bundleName === '3D Feature Elevation' && classes.unavailableDeliverables}>
          <Typography className={classes.addonsHeading} variant="h4">
            Walls & Fences
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Walls & Fences' ? (
              <ListItem button disableRipple className={classes.listItem}>
                <FormControlLabel
                  label={(
                    <div>
                      <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                    </div>
                  )}
                  control={(
                    <Checkbox
                      classes={{
                        checked: classes.checked,
                      }}
                      disabled={!item.selectable}
                      checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                      onChange={(e) => onAddOnsClick(item, e.target.checked)}
                    />
                  )}
                />
                <Typography className={classes.right} component="span">
                      &nbsp;
                  {' '}
                  {`${(item.acreFactor * order.acreage).toFixed(2)} Credits`}
                </Typography>
              </ListItem>
            ) : null))}
        </div>
      ) }
      {additionalLayersBoolean && (
        <div className={bundleName === '2D' && classes.unavailableDeliverables}>
          <Typography className={classes.addonsHeading} variant="h4">
            Additional Layers
          </Typography>
          {additionalDeliverables.map((item) =>
            (item.subtype === 'Additional Layers' ? (
              <div
                key={item.name}
                className={(!item.selectable || (bundleId === 5 && item.deliverableId === 16)) && classes.unavailableDeliverables}
              >
                <ListItem button disableRipple className={classes.listItem}>
                  <FormControlLabel
                    label={(
                      <div>
                        <Typography variant="body1" display="inline" className={classes.itemName}>{item.name}</Typography>
                      </div>
                    )}
                    control={(
                      <Checkbox
                        classes={{
                          checked: classes.checked,
                        }}
                        disabled={!item.selectable}
                        checked={selectedAdditionals.findIndex((i) => i._id === item._id) !== -1}
                        onChange={(e) => onAddOnsClick(item, e.target.checked)}
                      />
                    )}
                  />
                  <Typography className={classes.right} component="span">
                    {`${(item.acreFactor * order.acreage).toFixed(2)} credits`}
                  </Typography>
                </ListItem>
              </div>
            ) : null))}
        </div>
      ) }
    </div>
  );
};

export default compose(LayerSelect);
