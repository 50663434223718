// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { SetProjectId } from 'State/project/thunk';

const mapStateToProps = (state: IState) => ({
  showLoader: state.project.loading,
  projectId: state.project.projectId,
});

const mapDispatchToProps = {
  SetProjectIdAction: SetProjectId,
};

interface IOwnProps {
  showDialog: boolean;
  setShowDialog: (show: boolean) => void;
  getProjectUrl: (projectId: string) => string;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
  withRouter,
);
