// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import {
  Divider, ListItem, Checkbox, FormControlLabel, Typography,
} from '@material-ui/core';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const Basemap = ({ layers, ToggleBasemapLayerAction }: PropsType) => {
  const classes = useStyles();

  return (
    <>
      <ListItem button className={classes.expansionPanelHeader}>
        <FormControlLabel
          label={<Typography variant="h4" className={classes.sidebarHeader}>Basemap</Typography>}
          control={(
            <Checkbox
              color="primary"
              onChange={() => ToggleBasemapLayerAction()}
              checked={layers.baseMap}
              classes={{
                root: classes.checkbox,
              }}
            />
          )}
        />
      </ListItem>
      <Divider />
    </>
  );
};

export default compose(Basemap);
