// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { GetGeoJsonForLayer, SetClickedPoint, SetCurrentLayer, SetSiteId, SetLayerUpdate, ResetUpdateEntities, ResetDrawSource, SetlayerSelected, Autosave, SetAutoUpdate, SetHighlightedLayer } from 'State/map/editor/thunk';
import { getOrders } from 'State/order/selectors';

const mapStateToProps = (state: IState, ownProps: IOwnProps) => {
  const vectorTileJson =
    state.map.common.vectorTileJson &&
    state.map.common.vectorTileJson[ownProps.orderId] &&
    state.map.common.vectorTileJson[ownProps.orderId][ownProps.fileVersion];

  const projectAccessUS = !_ADMIN_ && 'projectAccessUS' in state.auth.resources;
  const projectAccessIndia = !_ADMIN_ && 'projectAccessIndia' in state.auth.resources;

  const orders = getOrders(state).projectOrders;
  const order = orders.find((o) => o._id === ownProps.orderId);
  const cadFile = order.cadFiles?.find((f) => f._id === ownProps.fileVersion);
  const expressAI = cadFile?.expressAIUpload && cadFile?.expressAIEmailSent;
  const orderDisplayed = (_ADMIN_ || projectAccessUS || projectAccessIndia || order.orderStatus === 0 || order.orderStatus === 7 || expressAI);

  if (!vectorTileJson) return {};

  const orderVisible = vectorTileJson.visible;
  const layer = vectorTileJson.vector_layers.find((l) => l.id === ownProps.sourceLayerId);
  const layerVisible = layer && layer.visible;

  return {
    visible: Boolean(orderDisplayed && orderVisible && layerVisible),
    currentLayer: state.map.editor.present.currentLayer,
    autoSaveUpdate: state.map.editor.present.autoUpdate,
    update: state.map.editor.present.update,
    editorFunctions: !_ADMIN_ ? 'editorFunctions' in state.auth.resources : true,
    layerSelected: state.map.editor.present.layerSelected,
    currentTool: state.map.editor.present.currentTool,
    highlightedLayer: state.map.editor.present.highlightedLayer,
    updateEntityStart: state.map.editor.present.updateEntityStart,
  };
};

const mapDispatchToProps = {
  GetGeoJsonForLayerAction: GetGeoJsonForLayer,
  SetClickedPointAction: SetClickedPoint,
  SetCurrentLayerAction: SetCurrentLayer,
  SetSiteIdAction: SetSiteId,
  SetLayerUpdateAction: SetLayerUpdate,
  ResetDrawSourceAction: ResetDrawSource,
  ResetUpdateEntitiesAction: ResetUpdateEntities,
  SetlayerSelectedAction: SetlayerSelected,
  SetAutoUpdateAction: SetAutoUpdate,
  SetHighlightedLayerAction: SetHighlightedLayer,
  AutosaveAction: Autosave,
};
interface IOwnProps {
  id: string;
  orderId: string;
  sourceId: string;
  fileVersion: string;
  sourceLayerId: string;
  color: string;
  lineWidth: number;
  map: any;
  layerSelected: string;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
