// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  header: {
    marginTop: theme.spacing(10),
  },
  dialog: {
    padding: theme.spacing(10),
    color: 'white',
  },
  dialogPaper: {
    padding: theme.spacing(5),
    backgroundColor: '#021E40',
    background: 'linear-gradient(135deg, #021E40 0%, #000000 100%)',
  },
  details: {
    marginBottom: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  form: {
    marginTop: theme.spacing(2),
  },
  errorMessage: {
    minHeight: '3em',
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
  },
  buttonWrapper: {
    marginTop: theme.spacing(4),
    position: 'relative',
    display: 'inline-block',
  },
  button: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
  },
  button2: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(10),
    paddingRight: theme.spacing(10),
    marginLeft: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  textField: {
    width: '35%',
  },
  successMessage: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(6),
    color: 'white',
    width: '400px',
  },
  captcha: {
    marginTop: theme.spacing(2),
  },
});

export default styles;
