// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Marker } from 'react-mapbox-gl';
import markerIcon from '@mapbox/maki/icons/marker-15.svg';
import SVG from 'react-inlinesvg';
import compose from './container';

const MapSearchMarker = compose(({ classes, marker }) => (
  <>
    {marker && (
      <Marker coordinates={marker}>
        <div className={classes.markerIconWrapper}>
          <SVG src={markerIcon} className={classes.markerIcon} />
        </div>
      </Marker>
    )}
  </>
));

export default MapSearchMarker;
