// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import {
  Button,
  Typography,
  Fade,
  CircularProgress,
  Dialog,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { TextField, Validators } from 'Common/forms';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const AddTeamForm = ({ AddOrgTeamAction, setSnackMessage, setShowSnackbar }: PropsType) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const createTeam = async (teamName: string) => {
    window?.pendo?.track('Team Created', { team_name: teamName });

    const result = await AddOrgTeamAction(teamName);
    if (result.success) {
      setSnackMessage('Team Created');
      setShowSnackbar(true);
      handleClose();
    }
  };

  const onFormSubmit = (value: any) => createTeam(value?.name);
  return (
    <div>
      <div className={classes.title}>
        <div>
          <Typography className={classes.headerText} variant="h2">Manage Teams</Typography>
        </div>
        <Button
          color="primary"
          variant="contained"
          className={classes.addTeamButton}
          onClick={handleClickOpen}
        >
          <Typography variant="h4" className={classes.addTeamText}>ADD NEW TEAM</Typography>
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <Form
          onSubmit={onFormSubmit}
          render={({ submitting, hasSubmitErrors, handleSubmit }) => (
            <form
              className={classes.form}
              onSubmit={handleSubmit}
            >
              <Typography variant="h2" className={classes.heading}>Add Team</Typography>
              <div className={classes.formRow}>
                <TextField
                  label="Name"
                  name="name"
                  validate={Validators.required}
                  variant="outlined"
                  inputProps={{
                    classes: {
                      notchedOutline: classes.outline,
                      input: classes.input,
                    },
                  }}
                  inputLabelProps={{
                    classes: {
                      outlined: classes.inputLabel,
                      shrink: classes.inputLabelShrink,
                    },
                  }}
                />
              </div>
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                type="submit"
                disabled={hasSubmitErrors || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} className={classes.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>Add Team</span>
                </Fade>
              </Button>
            </form>
          )}
        />
      </Dialog>
    </div>
  );
};

export default compose(AddTeamForm);
