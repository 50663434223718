// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';

import { GetLastLogin, GetOrgSubscription, SetOrgId, GetOrg, GetLoginStats, GetOrgStats } from 'State/admin/thunk';

import component from './component';
import styles from './styles';

const mapStateToProps = (state, ownProps) => {
  const { org, automateSubscription } = state.admin;

  if (!org || !automateSubscription) {
    return {
      orgId: ownProps.match.params.orgId,
    };
  }
  return {
    orgId: ownProps.match.params.orgId,
    loginStats: state.admin.loginStats,
    orgStats: state.admin.orgStats,
    subscriptionType: automateSubscription.interval,
  };
};

const mapDispatchToProps = {
  GetOrgAction: GetOrg,
  GetLastLoginAction: GetLastLogin,
  GetLoginStatsAction: GetLoginStats,
  GetOrgStatsAction: GetOrgStats,
  SetOrgIdAction: SetOrgId,
  GetOrgSubscriptionAction: GetOrgSubscription,
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
  withTheme,
)(component);
