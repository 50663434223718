// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { FileUploadStart, FileUploadCancel, DeleteUploadedFile } from 'State/fileUpload/thunk';

const mapStateToProps = (state: IState) => {
  const { uploadFiles } = state.fileUpload;
  const filesUploaded = uploadFiles.length;
  const allFilesUploaded = uploadFiles.filter((f) => f.progress < 100).length;
  return {
    uploadFiles,
    anyFilesUploaded: Boolean(filesUploaded),
    editorFunctions: !_ADMIN_ ? 'editorFunctions' in state.auth.resources : true,
    placeOrder: !_ADMIN_ ? 'placeOrder' in state.auth.resources : true,
    allFileUploadsComplete: Boolean(!allFilesUploaded),
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    FileUploadStartAction: FileUploadStart,
    FileUploadCancelAction: FileUploadCancel,
    FileDeleteAction: DeleteUploadedFile,
  }, dispatch);

interface IOwnProps {
  onSuccess: () => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
