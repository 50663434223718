// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const SetOrderIdAction = createAction('SET_ORDER_ID', (action) => (data: string) => action(data));

export const SetFileVersionAction = createAction('SET_FILE_VERSION', (action) => (data: { [key: string]: string }) => action(data));

export const SetOutputLasAction = createAction('SET_OUTPUT_LAS', (action) => (data: boolean) => action(data));

export const SetOrderLoadingAction = createAction('SET_ORDER_LOADING', (action) => (data: boolean) => action(data));

export const GetOrdersAsync = createAsyncAction(
  'GET_ORDERS_START',
  'GET_ORDERS_SUCCESS',
  'GET_ORDERS_FAILED',
)<null, IOrder[], string>();

export const CreateOrderAsync = createAsyncAction(
  'CREATE_ORDER_START',
  'CREATE_ORDER_SUCCESS',
  'CREATE_ORDER_FAILED',
)<null, IOrder, string>();

export const CreditAdjustmentAsync = createAsyncAction(
  'CREDIT_ADJUSTMENT_START',
  'CREDIT_ADJUSTMENT_SUCCESS',
  'CREDIT_ADJUSTMENT_FAILED',
)<null, IOrder, string>();

export const EditOrderAsync = createAsyncAction(
  'EDIT_ORDER_START',
  'EDIT_ORDER_SUCCESS',
  'EDIT_ORDER_FAILED',
)<null, IOrder, string>();

export const UpdateOrderAction = createAction('UPDATE_ORDER', (action) => (data: IOrder) => action(data));

export const DeleteOrderAsync = createAsyncAction(
  'DELETE_ORDER_START',
  'DELETE_ORDER_SUCCESS',
  'DELETE_ORDER_FAILED',
)<string, string, string>();

export const DownloadFileAsync = createAsyncAction(
  'DOWNLOAD_FILE_START',
  'DOWNLOAD_FILE_SUCCESS',
  'DOWNLOAD_FILE_FAILED',
)<null, null, string>();

export const DownloadPostgisDxfAsync = createAsyncAction(
  'DOWNLOAD_DXFFILE_START',
  'DOWNLOAD_DXFFILE_SUCCESS',
  'DOWNLOAD_DXFFILE_FAILED',
)<null, null, string>();

// orderStatus is now of type number instead of type IOrderStatus
export const GetOrderStatusAction = createAction('GET_ORDER_STATUS', (action) => (data: {
  orderStatus: { [key: string]: number },
  orderEstimate: string[],
  orderList: IOrder[],
}) => action(data));

export const UpdateOrderStatusAsync = createAsyncAction(
  'UPDATE_ORDER_STATUS_START',
  'UPDATE_ORDER_STATUS_SUCCESS',
  'UPDATE_ORDER_STATUS_FAILED',
)<null, IOrder, string>();

export const UpdateOrderSurfaceFilesAction = createAction('GET_SURFACE_FILE', (action) => (data: ISurfaceFile) => action(data));

export const UpdateOrderCadFilesAction = createAction('GET_CAD_FILE', (action) => (data: ICadFile) => action(data));

export const UpdateOrderLasFilesAction = createAction('GET_OUTPUT_LAS_FILE', (action) => (data: IOutputLasFile) => action(data));

export const DeleteSurfaceFileAsync = createAsyncAction(
  'DELETE_SURFACE_FILE_START',
  'DELETE_SURFACE_FILE_SUCCESS',
  'DELETE_SURFACE_FILE_FAILED',
)<null, any, null>();

export const DeleteDxfFileAsync = createAsyncAction(
  'DELETE_DXF_FILE_START',
  'DELETE_DXF_FILE_SUCCESS',
  'DELETE_DXF_FILE_FAILED',
)<null, any, null>();

export const DeleteOutputLasFileAsync = createAsyncAction(
  'DELETE_OUTPUT_LAS_FILE_START',
  'DELETE_OUTPUT_LAS_FILE_SUCCESS',
  'DELETE_OUTPUT_LAS_FILE_FAILED',
)<null, any, null>();

export const SetPlaceOrderSuccessAction = createAction('SET_PLACE_ORDER_SUCCESS', (action) => (data: boolean) => action(data));

export const SetShowAdjustmentMessageAction = createAction('SET_SHOW_ADJUSTMENT_MESSAGE', (action) => (data: boolean) => action(data));

export const SaveFileDownloadStatusAction = createAction('SET_DXF_DOWNLOAD_STATUS', (action) => (data: { downloadStatus: any, fileReady: boolean }) => action(data));

export const SaveGeoJsonDownloadStatusAction = createAction('SET_GEO_JSON_DOWNLOAD_STATUS', (action) => (data: { downloadGeoJsonStatus: any, fileReady: boolean }) => action(data));
