// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Typography, Avatar, Fab } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import Banner from 'Common/Banner';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { GetProjectDetailsRoute, GetProjectFilesRoute, PROJECTS_ROUTE, ADMIN_ROUTE } from 'Utils/routes';
import CreateProjectDialog from 'Common/CreateProjectDialog';
import CreateOrgDialog from 'Common/CreateOrgDialog';
import ProcessedAcreage from 'Common/ProcessedAcreage';
import classnames from 'classnames';
import WarningBanner from 'Common/WarningBanner';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import Link from '@material-ui/core/Link';
import logo from 'Assets/logo_small_dark.png';
import { clearLocalStorage } from 'Utils/localStorageService';
import ProjectToolbar from './projectToolbar';
import AccountToolbar from './accountToolbar';
import AdminListToolbar from './adminListToolbar';
import AdminToolbar from './adminToolbar';
import NotificationsMenu from './notificationsMenu';
import PopupMenu from './PopupMenu';
import ProjectName from './projectName';
import HelpMenu from './helpToolbar';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
* Component renders Header
*
* @param {object} props Component props
* @param {object} props.classes Injected style props
* @param {object} props.projectListMatch Props to check project dashboard path
* @param {object} props.accountMatch Props to check accoount tabs path
* @param {object} props.adminMatch Props to check admin paths
* @param {string} props.projectId ProjectId of the current project detail page
* @param {string} props.title Title of the page depending on user's curremt page
* @param {string} props.avatarText Props to display name intials
* @param {object} props.history Injected history props
* @param {boolean} props.isOnFreePlan Check if user is on free plan subscription
* @param {boolean} props.isAdmin Check if user is admin of org
* @param {boolean} props.processingUsage Check if the use has the processingUsage role
*/

export const Header = ({ projectListMatch, projectId, projectMatch, accountMatch, adminMatch, title, avatarText, history, isOnFreePlan, isAdmin, adminListMatch, editorFunctions, createProject, placeOrder, filesUploadedLasOrkml, processingUsage, orgId, GetOrgSubscriptionsAction, isOnCadTrial, cadSubscription, automateSubscription }: PropsType) => {
  const classes = useStyles();
  // If a non-admin user enters '/admin' or any other admin path like '/admin/orgId/projects' log them out
  useEffect(() => {
    if (!_ADMIN_) {
      GetOrgSubscriptionsAction(orgId);
    }
    if (!_ADMIN_ && (adminListMatch || adminMatch)) {
      clearLocalStorage();
      history.push('/');
    }
  }, []);

  const ProjectBackButton = (
    <div className={classes.backButton}>
      <img className={classes.logo} src={logo} alt="AirWorks small logo" />
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
        <Link color="inherit" onClick={() => history.push(PROJECTS_ROUTE)}>
          <Typography variant="body1" className={classes.toolbarText}>All Projects</Typography>
        </Link>
        {projectMatch && (<ProjectName initialProjectName={title} projectId={projectId} />)}
        {accountMatch && (<Typography variant="body1" color="textPrimary" style={{ paddingTop: '10px', paddingBottom: '10px', fontWeight: 500 }}>{title}</Typography>)}
      </Breadcrumbs>
    </div>
  );

  const isAutomateSubscriptionAvailable = automateSubscription && Object.keys(automateSubscription).length !== 0;

  const ProjectListHeader = (
    <div className={classes.backButton}>
      <img className={classes.logo} src={logo} alt="AirWorks small logo" />
      {cadSubscription && !isAutomateSubscriptionAvailable && (
        <div className={classes.betaBanner}>
          BETA
        </div>
      )}
    </div>
  );

  const AdminListHeader = (
    <div className={classes.backButton}>
      <img className={classes.logo} src={logo} alt="AirWorks small logo" />
      {(accountMatch || adminMatch) && <Avatar className={classes.avatar}>{avatarText}</Avatar>}
      <Typography variant="h3" className={classes.titleTypography} color="textPrimary">{title}</Typography>
    </div>
  );

  const [showProjectCreateDialog, setShowProjectCreateDialog] = useState(false);
  const [showOrgDialog, setShowOrgDialog] = useState(false);

  const readSession = sessionStorage.getItem('warningBanner');

  // Set Project route (/details or /files) based on user permissions
  let ProjectRoute;
  if (editorFunctions && !placeOrder) {
    ProjectRoute = GetProjectFilesRoute;
  } else if ((!editorFunctions && placeOrder) || filesUploadedLasOrkml) {
    ProjectRoute = GetProjectDetailsRoute;
  } else {
    ProjectRoute = GetProjectDetailsRoute;
  }

  const createProjectClick = () => {
    // Add new layer to a site  with no layers WEB-160
    setShowProjectCreateDialog(true);
  };

  const createOrgClick = () => {
    setShowOrgDialog(true);
  };

  const AdminBackButton = () => (
    <Button
      color="inherit"
      onClick={() => history.push(ADMIN_ROUTE)}
      className={classes.backButton}
    >
      <KeyboardArrowLeftIcon />
      <Typography variant="body1" className={classes.toolbarText}>Back to Dashboard</Typography>
    </Button>
  );

  const toolbarButtonStyle: string = (projectMatch || accountMatch) ? classes.toolbarButtons : classes.toolbarButtonsDashboard;
  const projectNameStyle: string = (projectMatch || accountMatch) ? classes.projectName : classes.backButton;
  return (
    <AppBar elevation={1} color="primary" position="relative" className={classnames(classes.appBar, { [classes.appBarSession]: readSession === 'false' })}>
      {!_ADMIN_ && isOnCadTrial && <Banner />}
      {/* Hide the warning banner */}
      {/* <WarningBanner /> */}
      <Toolbar>
        <div className={classnames(classes.toolbarContentWrapper, { [classes.toolbarNoTabs]: !(projectMatch || accountMatch || adminMatch) })}>
          {(projectMatch || accountMatch) && (ProjectBackButton)}
          {projectListMatch && (ProjectListHeader)}
          {adminMatch && <AdminBackButton />}
          {/* Make sure the admin header info is not shown when a non admin user tries to access the admin route */}
          {(adminListMatch && isAdmin) && (AdminListHeader)}
          <div className={toolbarButtonStyle}>
            {!isAdmin && <HelpMenu />}
            {!isAdmin && <NotificationsMenu />}
            <PopupMenu />
          </div>
          {adminMatch && (
            <div className={classes.adminName}>
              {(accountMatch || adminMatch) && <Avatar className={classes.avatar}>{avatarText}</Avatar>}
              <Typography variant="h3" color="textPrimary">{title}</Typography>
            </div>
          )}
          {projectListMatch && !isOnFreePlan && processingUsage
            && (
              <div className={classes.progressText}>
                <ProcessedAcreage />
              </div>
            )}
        </div>
      </Toolbar>
      <CreateProjectDialog
        showDialog={showProjectCreateDialog}
        setShowDialog={setShowProjectCreateDialog}
        getProjectUrl={ProjectRoute}
      />
      <CreateOrgDialog
        showOrgDialog={showOrgDialog}
        setShowOrgDialog={setShowOrgDialog}
      />
      {
        // Show the create new project button when user is on the dashboard and has the 'createProject' resource
        (projectListMatch && createProject) && (
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.fabButton}
            onClick={(async () => {
              // Add Pendo Track Events
              window?.pendo?.track("Create New Project");
              await createProjectClick();
            })}
          >
            <AddIcon />
          </Fab>
        )
      }
      {
        // Make sure the create org button is not shown when a non admin user tries to access the admin route
        (adminListMatch && isAdmin) && (
          <Fab
            color="primary"
            aria-label="Add"
            className={classes.fabButton}
            onClick={createOrgClick}
          >
            <AddIcon />
          </Fab>
        )
      }
      {projectMatch && <ProjectToolbar />}
      {accountMatch && <AccountToolbar />}
      {adminMatch && <AdminToolbar />}
      {adminListMatch && <AdminListToolbar />}
    </AppBar>
  );
};

export default compose(Header);
