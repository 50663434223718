// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { Button, InputBase, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders the Editable Project Name button/input field in the header
 *
 * @param {object} props Component props
 * @param {object} props.classes Injected style props
 * @param {string} props.initialProjectName Initial Project Name for this Project
 * @param {string} props.projectId ProjectId of the current project
 * @param {function} props.UpdateProjectNameAction Function to update the name of the current project
 */
export const ProjectName = ({ initialProjectName, projectOrders, projectId, ordersLoaded, UpdateProjectNameAction }: PropsType) => {
  const classes = useStyles();
  const [projectName, setProjectName] = useState(null);
  const [isProjectNameEditable, setIsProjectNameEditable] = useState(false);
  const [haveOrdersBeenProcessed, setHaveOrdersBeenProcessed] = useState(false);

  const startEditingProjectName = () => {
    if (!haveOrdersBeenProcessed) {
      setProjectName(initialProjectName);
      setIsProjectNameEditable(true);
    }
  };

  useEffect(() => {
    setHaveOrdersBeenProcessed(projectOrders.some((o) => o.orderStatus > 0));
  }, [projectOrders]);

  const updateProjectName = () => {
    UpdateProjectNameAction({ _id: projectId, name: projectName });
    setIsProjectNameEditable(false);
  };

  const AllowEdit = () => {
    if (isProjectNameEditable) {
      return (
        <IconButton
          aria-label="check"
          onClick={updateProjectName}
        >
          <CheckIcon />
        </IconButton>
      );
    }
    return (
      <IconButton
        aria-label="edit"
        onClick={startEditingProjectName}
      >
        <EditIcon />
      </IconButton>
    );
  };

  return (
    <div className={classes.projectName}>
      {/* TODO: move these components out to constants to help readability - Vinutna - 05/13/2021 */}
      {!isProjectNameEditable ? (
        <Button
          classes={{
            root: classes.titleButton,
            label: classes.buttonText,
          }}
          disableTouchRipple
          onDoubleClick={startEditingProjectName}
        >
          <span>{projectName || initialProjectName}</span>
        </Button>
      ) : (
        <>
          <InputBase
            className={classes.inputField}
            autoFocus
            value={projectName}
            onChange={(e) => {
              setProjectName(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' || e.key === 'Escape') {
                // check if project name has changed
                if (!projectName || projectName.trim() === initialProjectName) {
                  setIsProjectNameEditable(false);
                  setProjectName(null);
                  return;
                }
                // else call the update project name API
                updateProjectName();
                e.preventDefault();
                e.stopPropagation();
              }
            }}
          />
        </>
      )}
      {(ordersLoaded && !haveOrdersBeenProcessed) && AllowEdit()}
    </div>
  );
};

export default compose(ProjectName);
