// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Button, Typography, TextField, Fade, CircularProgress } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import area from 'Assets/area-icon.svg';
import polyline from 'Assets/toolbar-polyLine.svg';
import areaActive from 'Assets/area-icon-active.svg';
import polylineActive from 'Assets/toolbar-polyLine-active.svg';
import { mininumBufferSize } from 'Utils/constants';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const KmlToolBar = ({ currentKmlCount, bufferSize, creatingBuffer, kmlLine, showBufferInfo, uploadingDrawnKml, DrawKmlLineAction, StartKmlDrawAction, SetBufferSizeAction, GenerateKmlBufferAction, DrawPolygonAction, SetCreateKmlAction }: PropsType) => {
    const classes = useStyles();
    const [buffer, setBuffer] = useState(bufferSize);

    useEffect(() => {
      setBuffer(Math.max(bufferSize, mininumBufferSize));
    }, [bufferSize]);

    const onBufferSubmit = () => {
      let finalBufferSize = mininumBufferSize;
      if (!isNaN(buffer)){
        finalBufferSize =  Math.max(buffer, mininumBufferSize); 
      }
      setBuffer(finalBufferSize);
      SetBufferSizeAction(finalBufferSize);
      GenerateKmlBufferAction();
    }

    const saveLineKmlFeature = async () => {
      await DrawKmlLineAction();
    }

    const savePolygonKmlFeature = async () => {
      await DrawPolygonAction();
    }

    return (
      <div className={classes.kmlToolbar}>
        {showBufferInfo ?
          <>
            <Typography variant='h4'>Buffer Distance</Typography>
            <div className={classes.bufferForm}>
              <div className={classes.bufferText}>
                <TextField
                    id="buffer"
                    variant="outlined"
                    type="number"
                    onChange={(e) => { setBuffer(parseFloat(e.target.value)) }}
                    inputProps={{ min: mininumBufferSize }}
                    value={buffer.toString()}
                    className={classes.textField}
                    disabled={creatingBuffer}
                  />
                <div className={classes.unitDiv}>
                  <Typography variant='h5'>ft</Typography>
                </div>
              </div>
              <Button
                  variant='text'
                  color='primary'
                  onClick={onBufferSubmit}
                >
                <Fade in={creatingBuffer}>
                  <CircularProgress size={16} className={classes.expressButtonProgress} />
                </Fade>
                <Fade in={!creatingBuffer}>
                  <span>Apply</span>
                </Fade>
              </Button>
            </div>
          </> :
          <>
            <Button
              className={classes.option}
              onClick={() => {
                StartKmlDrawAction('draw_polygon');
              }}
            >
              {!kmlLine ? (
                <SVG src={areaActive} title="polygon" className={classes.icon}/>
              ) : (
                <SVG src={area} title="polygon" className={classes.icon} />
              )}
            </Button>
            <Button
              className={classes.option}
              onClick={() => {
                StartKmlDrawAction('draw_line_string');
              }}
            >
              {kmlLine ? (
                <SVG src={polylineActive} title="line" className={classes.icon} />
              ) : (
                <SVG src={polyline} title="line" className={classes.icon} />
              )}
            </Button>
            {kmlLine && 
              <Button
                variant='text'
                color='primary'
                onClick={saveLineKmlFeature}
              >
                <Fade in={creatingBuffer}>
                  <CircularProgress size={16} className={classes.expressButtonProgress} />
                </Fade>
                <Fade in={!creatingBuffer}>
                  <span>Save Line</span>
                </Fade>
              </Button>
            }
            {!kmlLine && 
              <Button
                variant='contained'
                color='primary'
                onClick={savePolygonKmlFeature}
                disabled={!currentKmlCount}
              >
                <Fade in={uploadingDrawnKml}>
                  <CircularProgress size={16} className={classes.uploadKmlProgress} />
                </Fade>
                <Fade in={!uploadingDrawnKml}>
                  <span>Save Processing Area</span>
                </Fade>
              </Button>
            }
          </>
        }
      </div>
    );
  };

export default compose(KmlToolBar);
