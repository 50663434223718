// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAsyncAction, createAction } from 'typesafe-actions';

export const GetImageryProvidersAsync = createAsyncAction(
  'GET_IMAGERY_PROVIDERS_START',
  'GET_IMAGERY_PROVIDERS_SUCCESS',
  'GET_IMAGERY_PROVIDERS_FAILED',
)<null, IImageryProvider[], string>();

export const CreateImageryProviderAsync = createAsyncAction(
  'CREATE_IMAGERY_PROVIDER_START',
  'CREATE_IMAGERY_PROVIDER_SUCCESS',
  'CREATE_IMAGERY_PROVIDER_FAILED',
)<null, IImageryProvider, string>();

export const ImageryCoverageAsync = createAsyncAction(
  'CREATE_IMAGERY_COVERAGE_START',
  'CREATE_IMAGERY_COVERAGE_SUCCESS',
  'CREATE_IMAGERY_COVERAGE_FAILED',
)<null, boolean, string>();

export const ResetImageryCoverageAction = createAction('RESET_IMAGERY_COVERAGE');
