// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import PaymentConfirmation from 'Common/PaymentConfirmation';
import { Typography, Divider, Button } from '@material-ui/core';
import { deliverableCost } from 'Utils/deliverables';
import EditOrder from './EditOrder';
import compose from './container';

const ConfirmColumn = compose(({ classes, setPage, creditCards, bundleDeliverables, addOnDeliverableDollars, addOnLayerDollars, placeOrderSuccess, selectedAdditionals, totalCost, referenceId, currentTeam, acreage }) => {
  const bundleBaseLayers: any[] = [];
  const bundleBaseDeliverables: any[] = [];
  const extraLayers: any[] = [];
  const extraDeliverables: any[] = [];

  const addOnCost: number = addOnDeliverableDollars + addOnLayerDollars;
  bundleDeliverables.forEach((del) => {
    if (del.type === 'layer') {
      bundleBaseLayers.push(del);
    } else {
      bundleBaseDeliverables.push(del);
    }
  });

  selectedAdditionals.forEach((del) => {
    if (del.type === 'layer') {
      extraLayers.push(del);
    } else {
      extraDeliverables.push(del);
    }
  });

  return (
    <div className={classes.confirmColumn}>
      {placeOrderSuccess ? (
        <div>
          <Typography className={classes.pageTitle} variant="h1">Thank you for your Order!</Typography>
          <Typography variant="body1" className={classes.confirmText}>Your project has successfully begun processing on AirWorks. Our process includes autonomous drafting, quality control and final delivery.</Typography>
          <Typography variant="body1" className={classes.confirmText}>
            We will notify you when your plan is ready. If you need your project faster, please don't hesitate to reach out. For this or other questions, you can reach us through chat, or at <a className={classes.emailTag} href="mailto:support@airworks.io">support@airworks.io.</a>
          </Typography>
        </div>
      )
        : (
          <>
            <Typography className={classes.pageTitle} variant="h1">Confirm and Process</Typography>
            <EditOrder />
            <Divider className={classes.divider} />
            <div className={classes.deliverableList}>
              <Typography variant="body1">Team</Typography>
              <Typography variant="body1">{currentTeam?.name || 'N/A'}</Typography>
              <Typography variant="body1">Reference Id</Typography>
              <Typography variant="body1">{referenceId || 'N/A'}</Typography>
            </div>
            <div className={classes.section}>
              <Typography className={classes.sectionTitle} variant="h3">Deliverables and Add-Ons</Typography>
              <Button className={classes.button} onClick={() => setPage(0)}>Edit</Button>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.deliverableList}>
              <Typography variant="body1">{`Base Layers (${bundleBaseLayers.length} Layers)`}</Typography>
              <Typography variant="body1">Included</Typography>
              <Typography variant="body1">{`Base Deliverables (${bundleBaseDeliverables.length} Items)`}</Typography>
              <Typography variant="body1">Included</Typography>
            </div>
            {extraDeliverables.length > 0 && <Typography className={classes.subSectionTitle} variant="h6">Extra Deliverables</Typography>}
            {extraDeliverables.map((del) => (
              <div className={classes.deliverableList}>
                <Typography variant="body1">{del.name}</Typography>
                <Typography variant="body1">{`${deliverableCost(del.acreFactor, acreage).toFixed(2)} credits`}</Typography>
              </div>
            ))}
            {extraLayers.length > 0 && <Typography className={classes.subSectionTitle} variant="h6">Extra Layers</Typography>}
            {extraLayers.map((del) => (
              <div className={classes.deliverableList}>
                <Typography variant="body1">{del.name}</Typography>
                <Typography variant="body1">{`${deliverableCost(del.acreFactor, acreage).toFixed(2)} credits`}</Typography>
              </div>
            ))}
            <div className={classes.section}>
              <Typography className={classes.sectionTitle} variant="h3">Billing and Payment</Typography>
              <Button className={classes.button} onClick={() => setPage(1)}>Edit</Button>
            </div>
            <Divider className={classes.divider} />
          </>
        )}
      {(totalCost + addOnCost) > 0 ? (
        <PaymentConfirmation />
      ) : (
        !placeOrderSuccess && <Typography variant="body1">No additional charges required</Typography>
      )}
    </div>
  );
});

export default ConfirmColumn;
