// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import { ShowDialogAction, HideDialogAction } from './actions';

const initialState: ITokenExpiredDialog = {
  show: false,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(ShowDialogAction, (draft) => { draft.show = true; });
  handleAction(HideDialogAction, (draft) => { draft.show = false; });
});
