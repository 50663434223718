// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core/styles';

import { GetCreditCards, GetOrgSubscription } from 'State/subscription/thunk';

import styles from './styles';

const mapStateToProps = (state: IState) => ({
  subscriptionId: state?.subscription?.cadSubscription?.subscriptionId,
  loading: state.subscription.creditCardsLoading && state.subscription.subscriptionsLoading,
  billing: 'billing' in state.auth.resources,
  resources: state.auth.resources,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetCreditCardsAction: GetCreditCards,
    GetOrgSubscriptionAction: GetOrgSubscription,
  }, dispatch);

type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
