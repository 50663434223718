// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  card: {
    padding: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  cardHeader: {
    display: 'grid',
    gridTemplateColumns: '1fr 3fr 5fr',
    alignItems: 'center',
    justifySelf: 'left',
    '&>*:nth-child(3)': {
      textAlign: 'right',
      justifySelf: 'right',
    },
  },
  divider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  orderNumber: {
    padding: '6px',
  },
  statusText: {
    padding: '9px',
  },
  gridChild: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(3),
    alignItems: 'center',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
      justifySelf: 'right',
      paddingRight: '10px',
    },
  },
  orderInfoWrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(3),
  },
  creditAdjustmentItemWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: 600,
    minHeight: '40px',
    height: 'fit-content',
  },
  adjustmentNumber: {
    paddingRight: '15px',
  },
  adjustmentList: {
    marginTop: '0px',
  },
  cadFileList: {
    display: 'flex',
    gridTemplateColumns: '1fr auto auto',
    gridColumnGap: theme.spacing(1),
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '500px',
    '&>*:nth-child(2n)': {
      textAlign: 'right',
      justifySelf: 'right',
    },
  },
  cadFileName: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(1),
  },
  outputFilesWrapper: {
    width: '40%',
  },
  orderContent: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridColumnGap: theme.spacing(3),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  downloadProgress: {
    position: 'relative',

  },
  deleteIcon: {
    fontSize: '20px',
  },
  fontWeight: {
    fontWeight: 300,
  },
  statusButton: {
    padding: '0px',
  },
  select: {
    fontWeight: 300,
  },
  itemWrapper: {
    marginBottom: '10px',
  },
  buttonGroup: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    float: 'right',
  },
  editOrderButtonWrapper: {
    float: 'right',
  },
  inputTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  emailText: {
    fontSize: '14px',
    padding: '5px',
  },
}));

export default useStyles;
