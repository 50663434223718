// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { getOrderIds } from 'State/order/selectors';

const mapStateToProps = (state: IState) => ({
  vectorTileJson: state.map.common.vectorTileJson,
  orgId: _ADMIN_ ? state.admin.orgId : state.auth.user.ownerOrganization,
  projectId: state.project.projectId,
  orderIds: getOrderIds(state),
  fileVersions: state.order.fileVersions,
  changeVersion: state.map.common.changeVersion,
  currentLayer: state.map.editor.present.currentLayer,
  layerSelected: state.map.editor.present.layerSelected,
  dxfExtent: state.map.common.dxfExtent,
});
interface IOwnProps {
  showLayers: boolean;
  map: any;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

const arraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1 === arr2) return true;
  if (!arr1 || !arr2) return false;

  if (arr1.length !== arr2.length) return false;

  return arr1.every((item) => arr2.includes(item));
};

export default compose<PropsType, IOwnProps>(
  connect(
    mapStateToProps,
    null,
    null,
    {
      areStatesEqual: (next, prev) => (
        next.map.common.changeVersion === prev.map.common.changeVersion &&
        next.project.projectId === prev.project.projectId &&
        next.map.common.dxfExtent === prev.map.common.dxfExtent
      ),
    },
  ),
);
