// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CreatePaymentInstrument } from 'State/subscription/thunk';

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    CreatePaymentInstrumentAction: CreatePaymentInstrument,
  }, dispatch);

interface IOwnProps {
  onCancel: () => void;
  setCreateCardState?: (arg0: any) => void;
}

export type PropsType =
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(null, mapDispatchToProps),
);
