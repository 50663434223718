// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { SetBufferSize, GenerateKmlBuffer } from 'State/kml/thunk';
import { SaveDrawnKmlLine, StartKmlDraw, SaveDrawnKmlPolygon } from 'State/map/draw/thunk';
import { SetKmlLineDrawAction, SetCreateKmlAction } from 'State/map/draw/actions';

const mapStateToProps = (state: IState) => ({
  bufferSize: state.kml.present.bufferSize,
  creatingBuffer: state.kml.present.creatingBuffer,
  uploadingDrawnKml: state.map.draw.present.uploadingDrawnKml,
  showBufferInfo: state.kml.present.showBufferInfo,
  kmlLine: state.map.draw.present.kmlLine,
  currentKmlCount: state.map.draw.present.currentKml?.features?.length,
});

const mapDispatchToProps = {
  SetBufferSizeAction: SetBufferSize,
  GenerateKmlBufferAction: GenerateKmlBuffer,
  DrawKmlLineAction: SaveDrawnKmlLine,
  DrawPolygonAction: SaveDrawnKmlPolygon,
  StartKmlDrawAction: StartKmlDraw,
  SetKmlLineDrawAction,
  SetCreateKmlAction,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
