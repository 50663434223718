// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Divider } from '@material-ui/core';
import LoadingSpinner from 'Common/LoadingSpinner';
import history from 'Utils/history';
import { clearLocalStorage } from 'Utils/localStorageService';
import PlanInfo from './PlanInfo';
import PaymentInfo from './PaymentInfo';
import InvoiceList from './InvoiceList';
import compose from './container';

/**
 * Component renders the contents of the subscriptions tab
 *
 * @param {object} props Component props
 * @param {function} props.GetCreditCardsAction Function to call the getCreditCards API
 * @param {function} props.GetOrgSubscriptionAction Function to call the getOrgActiveSubscription API
 * @param {boolean} props.loading Boolean to indicate whether the getOrgActiveSubscription and getCreditCards APIs have returned a response
 * @param {boolean} props.billing Boolean to indicate whether the user has 'billing' resource
 */
const AccountBilling = compose(({ classes, loading, GetCreditCardsAction, GetOrgSubscriptionAction, billing, subscriptionId }) => {
  useEffect(() => {
    if (!billing) {
      clearLocalStorage();
      history.push('/');
    }
    GetCreditCardsAction();
    GetOrgSubscriptionAction();
  }, []);

  return (
    <LoadingSpinner loading={loading}>
      <div className={classes.pageWrapper}>
        <PlanInfo />
        <Divider className={classes.divider} />
        <PaymentInfo />
        <Divider className={classes.divider} />
        {subscriptionId && (
          <>
            <InvoiceList />
            <Divider className={classes.divider} />
          </>
        )}
      </div>
    </LoadingSpinner>
  );
});

export default AccountBilling;
