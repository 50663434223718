// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState, useMemo } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Paper, Button, Typography, MenuItem, InputBase, IconButton, Divider, Select, Fade, CircularProgress, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import ErrorIcon from '@material-ui/icons/Error';
import LoadingSpinner from 'Common/LoadingSpinner';
import InfoIcon from '@material-ui/icons/Info';
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft';
import { PDFDownloadLink } from '@react-pdf/renderer';
import moment from 'moment-timezone';
import { formatValue, deliveryBusinessDays } from 'Utils/deliverables';
import PDF from 'Common/OrderDialog/PDF';
import CreateMap from 'Common/Map';
import ConfirmDialog from 'Common/ConfirmDialog';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmAmountDialog from 'Common/ConfirmAmountDialog';
import history from 'Utils/history';
import { GetOrderStatus } from 'Utils/orderStatus';
import { GetAdminProjectsRoute } from 'Utils/routes';
import OrderCard from './orderCard';
import ProjectDetails from './projectDetails';
import ConvertDialog from './ConvertDialog';
import compose, { PropsType } from './container';
import useStyles from './styles';

moment.tz.setDefault('America/New_York');

const Map = CreateMap('layers');
/**
 * Component renders the order details of all orders within a project
 */
export const AdminProject = ({ projectId, kmlChange, orgId, project, org, vectorTileJson, fileVersions, list, templates, orgTeams, epsgCode, adjustedAcresTotal, showAdjustmentError, trainingErrorMessage, GetOrgAction, GetProjectAction, LoadKmlsAction, ReprocessOrderAction, UpdateOrderStatusAction, PlaceOrderAction, DeleteProjectAction, DeleteOrderAction, GetDxfExtentAction, SetDxfExtentAction, ClearFileVersionsAction, ClearKmlsAction, StopLoadingAction, AdminToggleCadDropdownAction, AdminToggleKmlVisibilityAction, ToggleNoneVectorTilesAction, ChangeVersionAction, GetVectorTilesAction, SetFileVersionAction, SetProjectIdAction, SetOrgIdAction, GetOrdersAction, SetEpsgListAction, SetShowAdjustmentMessageAction }: PropsType) => {
  const classes = useStyles();
  const [showLoader, setShowLoader] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [hasValidEpsg, setHasValidEpsg] = useState(true);
  const [tifMissing, setTifMissing] = useState(false);
  const [projectFileStatuses, setProjectFileStatuses] = useState([]);
  const [showConfirmDeleteProject, setShowConfirmDeleteProject] = useState(false);
  const [fileError, setFileError] = useState(false);
  const [dxfFile, setDxfFile] = useState('None');
  const [dxfFileId, setDxfFileId] = useState('None');
  const [showConfirmStatusDialog, setShowConfirmStatusDialog] = useState(false);
  const [orderStatus, setOrderStatus] = useState(null);
  const [orderId, setOrderId] = useState('');
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [showConvertDialog, setShowConvertDialog] = useState(false);
  const [isReprocessSubmitting, setIsReprocessSubmitting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const currentOrder = list[currentIndex];
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(null);
  const [converted, setConverted] = useState(false);

  const noInputFilesUploaded = project && !projectFileStatuses.length;
  const inputFileHasError = !!fileError;
  const layers =
  vectorTileJson &&
  vectorTileJson[currentOrder._id] &&
  vectorTileJson[currentOrder._id][fileVersions[currentOrder._id]];

  const handleDxfDropdownClick = (siteId: string) => {
    setDxfFileId(siteId);
    if (siteId === 'None') {
      setDxfFile('None');
      ToggleNoneVectorTilesAction();
      ChangeVersionAction();
    } else {
      const file = currentOrder.cadFiles?.find((f) => f._id === siteId)?.filename;
      const fileName = file ? file.split('/').pop() : 'orginal file';
      setDxfFile(fileName);
      SetFileVersionAction(currentOrder._id, siteId);
      GetVectorTilesAction();
      ChangeVersionAction();
      AdminToggleCadDropdownAction(currentOrder._id, siteId);
      GetDxfExtentAction(siteId);
    }
  };

  useEffect(() => {
    SetOrgIdAction(orgId);
    SetProjectIdAction(projectId);
    SetDxfExtentAction(null);
    ClearFileVersionsAction();
    StopLoadingAction();

    const loadData = async () => {
      setShowLoader(true);
      await GetProjectAction();
      await GetOrgAction(orgId);
      await GetOrdersAction(projectId);
      await LoadKmlsAction();
      setShowLoader(false);
    };

    loadData();
    handleDxfDropdownClick('None');
  }, [projectId]);

  useEffect(() => {
    setShowErrorSnackbar(trainingErrorMessage);
    if (trainingErrorMessage) setConverted(false);
  }, [trainingErrorMessage]);

  const pdfData = useMemo(() => {
    const { addOns, deliverables, referenceId, acreage, creditAcreage, costToCustomer, paidForThisProject, middlePoint, cadTemplate, topoIntervalMajor, topoIntervalMinor,
      orderDate, deliveryDate, bundleName, bundleAcreAdjustmentFactor, anticipatedKickoffDate, deliveryDays, density, deliverySpeed, team } = currentOrder || {};
    const imageryCreditCost = currentOrder?.imageryCreditCost || 0;
    const imageryDollarCost = currentOrder?.imageryDollarCost || 0;
    let addOnsInAcres = false;
    // If all add-ons include an acres field, it indicates that the user paid for these add-ons in acres
    if (addOns?.every((addOn) => addOn.hasOwnProperty('acres'))) {
      addOnsInAcres = true;
    }

    const orderTeam = orgTeams?.find((t) => t._id === team);

    const deliverablesCredits = addOns?.reduce((sum, addOn) => {
      if (addOn.addOnType === 'deliverable') {
        if (addOnsInAcres) {
          return sum + addOn.acres;
        }
        return sum + addOn.cost;
      }
      return sum;
    }, 0);
    const deliverablesCreditsString = addOnsInAcres ? `${deliverablesCredits.toFixed(2)} credits` : `$${formatValue(deliverablesCredits)}`;
    const layersCredits = addOns?.reduce((sum, addOn) => {
      if (addOn.addOnType === 'layer') {
        if (addOnsInAcres) {
          return sum + addOn.acres;
        }
        return sum + addOn.cost;
      }
      return sum;
    }, 0);
    const layersCreditsString = addOnsInAcres ? `${layersCredits.toFixed(2)} credits` : `$${formatValue(layersCredits)}`;
    const addOnsCreditsString = addOnsInAcres ? `${(deliverablesCredits + layersCredits + imageryCreditCost).toFixed(2)} credits` : `$${formatValue(deliverablesCredits + layersCredits + imageryDollarCost)}`;
    return ({
      type: 'Estimate',
      projectName: project?.name,
      referenceId,
      team: orderTeam?.name,
      acreage,
      middlePoint: `${middlePoint?.place}, ${middlePoint?.region} ${middlePoint?.postcode}`,
      coordinates: `${middlePoint?.longitude.toFixed(5)}, ${middlePoint?.latitude.toFixed(5)}`,
      cadTemplate: templates && templates.find((temp) => temp._id === cadTemplate)?.name,
      crs: epsgCode && `EPSG: ${epsgCode}`,
      topoMajr: topoIntervalMajor,
      topoMinr: topoIntervalMinor,
      businessDays: deliveryDays ? deliveryBusinessDays(deliveryDays) : 'N/A',
      anticipatedKickoffDate,
      orderDate: orderDate ? moment(orderDate).format('MMMM Do YYYY') : 'N/A',
      deliveryDate: deliveryDate ? moment(deliveryDate).format('MMMM Do YYYY') : 'N/A',
      selectedBundle: { name: bundleName, acreAdjustmentFactor: bundleAcreAdjustmentFactor, density, deliverySpeed },
      costPerAcre: org?.costPerAcre,
      bundleDeliverables: deliverables,
      selectedAdditionals: addOns,
      chargedCredits: bundleName === 'A la carte' ? 0 : (creditAcreage - (addOnsInAcres ? layersCredits + deliverablesCredits + imageryCreditCost : 0))?.toFixed(2),
      totalCreditsCharged: creditAcreage?.toFixed(2),
      deliverablesCreditsString,
      layersCreditsString,
      addOnsCreditsString,
      totalAdditionalCharged: paidForThisProject?.toFixed(2),
      totalCostForProject: costToCustomer?.toFixed(2),
      imageryProvider: project?.imageryRequest?.provider,
      imageryCreditCost,
    });
  }, [currentOrder]);

  const prepareProjectFiles = () => {
    if (project) {
      const projectTifFiles = project?.tifFiles.map((tifFile) => ({
        id: tifFile._id,
        epsg: tifFile.epsg,
        name: tifFile.filename.split('/').pop(),
        adminUpload: tifFile.adminUpload,
        type: 'tif',
        status: 'none',
      }));

      const projectLasFiles = project?.lasFiles.filter((lasFile) => lasFile.lasType === 'unclassified').map((lasFile) => ({
        id: lasFile._id,
        epsg: lasFile.epsg,
        name: lasFile.filename.split('/').pop(),
        adminUpload: lasFile.adminUpload,
        type: 'las',
        status: 'none',
      }));

      const projectFiles = [...projectTifFiles, ...projectLasFiles];

      setHasValidEpsg(projectTifFiles.some((tif) => tif.epsg !== -1));
      setTifMissing(projectTifFiles?.length === 0);

      const epsgList: number[] = [];

      projectFiles.forEach((file) => {
        epsgList.push(file.epsg);
        const fileStatus: any = project.fileStatuses?.find((s: any) => s.filename === file.name);
        if (file.status === 'ERROR' && !fileError) setFileError(true);
        file.status = fileStatus?.status;
        file.messages = fileStatus?.messages;
      });
      setProjectFileStatuses(projectFiles);

      SetEpsgListAction([...new Set(epsgList)]);
    }
  };

  useEffect(() => {
    if (project) {
      prepareProjectFiles();
      SetShowAdjustmentMessageAction(false);
    }
  }, [project]);

  useEffect(() => {
    if (currentOrder) {
      AdminToggleKmlVisibilityAction(currentOrder._id);
      handleDxfDropdownClick('None');
      SetShowAdjustmentMessageAction(false);
    }
  }, [currentOrder, projectId, kmlChange]);

  const reprocessOrderClick = async (oId: string) => {
    setIsReprocessSubmitting(true);
    await ReprocessOrderAction(oId);
    GetOrdersAction(projectId);
    setIsReprocessSubmitting(false);
  };

  const updateStatusClick = (oId: string, sid: string) => {
    setOrderId(oId);
    setOrderStatus(sid);
    setShowConfirmStatusDialog(true);
  };

  const onOkStatusUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmStatusDialog(false);
    UpdateOrderStatusAction(orderId, orderStatus);
    e.stopPropagation();
  };

  const onCancelStatusUpdate = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmStatusDialog(false);
    setOrderStatus(null);
    e.stopPropagation();
  };

  const adjustOrderCreditsClick = (oId: string, orId: string) => {
    setShowConfirmDialog(true);
    setOrderId(oId);
  };

  const placeOrderClick = async () => {
    setIsSubmitting(true);
    await PlaceOrderAction();
    GetOrdersAction(projectId);
    setIsSubmitting(false);
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    e.stopPropagation();
  };

  const onDeleteOrder = async () => {
    const deletingId = currentOrder._id;
    setCurrentIndex(0);
    await DeleteOrderAction(deletingId);
  };

  const onOkDeleteProject = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    DeleteProjectAction(projectId);
    history.push(GetAdminProjectsRoute(orgId));
    e.stopPropagation();
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDeleteProject(false);
    e.stopPropagation();
  };

  const onCancelAmount = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    e.stopPropagation();
  };

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const handleConvert = () => {
    setConverted(true);
    setShowSnackbar(true);
  };

  const handleError = () => {
    setShowSnackbar(false);
    setConverted(false);
    setShowErrorSnackbar(null);
  };

  return (
    <LoadingSpinner loading={showLoader}>
      <Snackbar open={showSnackbar && !showErrorSnackbar} autoHideDuration={5000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="success" color="info">TRAINING PROJECT CONVERSION STARTED</Alert>
      </Snackbar>
      <Snackbar open={!!showErrorSnackbar} autoHideDuration={10000} onClose={handleError} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert severity="error" color="error">{trainingErrorMessage}</Alert>
      </Snackbar>
      <div className={classes.pageWrapper}>
        <Paper className={classes.headerWrapper}>
          <div className={classes.sideTitle}>
            <RouterLink to={GetAdminProjectsRoute(orgId)} className={classes.routerLink} title="Back to Projects">
              <KeyboardArrowLeftIcon />
              <Typography variant="subtitle2">Back</Typography>
            </RouterLink>
            <Typography variant="h3" className={classes.dropdownText} color="textPrimary">
              {project?.name}
            </Typography>
            <Typography>
              {' '}
              {project?.description}
            </Typography>

            {currentOrder?.isEstimate && (
              <div className={classes.estimateContainer}>
                <InfoIcon className={classes.icon} fontSize="small" />
                <Typography>
                  {' '}
                  Estimate
                </Typography>
              </div>
            )}
            {
              ((project?.training || converted) && !showErrorSnackbar) && (
                <Typography variant="h6" className={classes.orderNumber}>Converted to Training Standard</Typography>
              )
            }
          </div>
          <div className={classes.sideTitle}>
            <Typography>{`Project Adjusted Credits: ${adjustedAcresTotal?.toFixed(2)}`}</Typography>
            {currentOrder?.isEstimate && (
              <div className={classes.estimateButtonContainer}>
                <Divider orientation="vertical" className={classes.verticalDivider} flexItem />
                <PDFDownloadLink document={<PDF data={pdfData} />} fileName={`${pdfData?.projectName}_estimate.pdf`} className={classes.pdfButton}>
                  {({ blob, url, loading }) =>
                    (loading ? <CircularProgress color="white" size={24} className={classes.buttonProgress} /> : (
                      <div
                        className={classes.downloadText}
                        onClick={() => {
                          window?.pendo?.track('Download Estimate');
                        }}
                      >
                        DOWNLOAD
                      </div>
                    ))}
                </PDFDownloadLink>
              </div>
            )}
          </div>
        </Paper>
        <div className={classes.bodyWrapper}>
          <ProjectDetails isEstimate={currentOrder?.isEstimate} list={list} siteId={dxfFileId} currentIndex={currentIndex} layers={layers} imageryRequest={project?.imageryRequest} projectFileStatuses={projectFileStatuses} selectIndex={setCurrentIndex} />
          <div className={classes.cardWrapper}>
            {showAdjustmentError && (
              <div className={classes.adjustmentErrorPaper}>
                <Paper className={classes.errorPaper}>
                  <div className={classes.adjustmentErrorTitle}>
                    <ErrorIcon className={classes.errorIcon} />
                    <Typography className={classes.errorTitleText} variant="h5">Out of Credits!</Typography>
                  </div>
                  <div className={classes.adjustmentErrorBody}>
                    <Typography className={classes.errorBodyText}>
                      This order could not be adjusted because the account does not have sufficient credits for the adjustment you are attempting to make.
                      Please add more credits to their account or have them resubmit the order and pay for the overages with their credit card.
                    </Typography>
                    <Button variant="text" color="primary" onClick={() => SetShowAdjustmentMessageAction(false)}>OK</Button>
                  </div>
                </Paper>
              </div>
            )}
            <Paper className={classes.dropdownMap}>
              {currentOrder && (
                <div className={classes.dropdownText}>
                  <div className={classes.leftSide}>
                    <Typography variant="h2" className={classes.orderNumber}>{`Order ${currentIndex + 1}`}</Typography>
                    {(currentOrder?.orderStatus !== 0) && (
                      <IconButton
                        onClick={onDeleteOrder}
                      >
                        <DeleteIcon className={classes.deleteButton} fontSize="small" />
                      </IconButton>
                    )}
                    <Button
                      color="primary"
                      onClick={() => { adjustOrderCreditsClick(currentOrder._id, currentOrder.ownerOrganization); }}
                    >
                      Adjust Credits
                    </Button>
                    { ((!project?.training && !converted) || showErrorSnackbar) && (
                      <Button
                        color="primary"
                        onClick={() => { setShowConvertDialog(true); }}
                      >
                        Convert
                      </Button>
                    )}
                    {(currentOrder.orderStatus === 1 || currentOrder.orderStatus === 3) && (
                      <Button
                        color="primary"
                        disabled={isReprocessSubmitting || !currentOrder.boundaryFile || noInputFilesUploaded || inputFileHasError || tifMissing || !hasValidEpsg}
                        onClick={() => reprocessOrderClick(currentOrder._id)}
                      >
                        <Fade in={isReprocessSubmitting}>
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        </Fade>
                        <Fade in={!isReprocessSubmitting}>
                          <span>Re-try Processing</span>
                        </Fade>
                      </Button>
                    )}

                    {(currentOrder.orderStatus === 0) && (
                      <Button
                        color="primary"
                        onClick={placeOrderClick}
                        disabled={isSubmitting || !currentOrder.boundaryFile || noInputFilesUploaded || inputFileHasError || tifMissing || !hasValidEpsg}
                      >
                        <Fade in={isSubmitting}>
                          <CircularProgress size={24} className={classes.buttonProgress} />
                        </Fade>
                        <Fade in={!isSubmitting}>
                          <span>start processing</span>
                        </Fade>
                      </Button>
                    )}
                  </div>
                  <Typography variant="h6" className={classes.statusText} color="textPrimary">
                    Status:
                    <Select
                      variant="outlined"
                      input={<InputBase style={{ width: '200px' }} />}
                      className={classes.statusText}
                      value={currentOrder.orderStatus}
                      onChange={(ev) => { updateStatusClick(currentOrder._id, ev.target.value); }}
                      classes={{ root: classes.select }}
                    >
                      {[0, 1, 2, 3, 4, 5, 6, 7].map((status) =>
                        <MenuItem className={classes.select} value={status}>{GetOrderStatus(status)}</MenuItem>)}
                    </Select>
                  </Typography>
                </div>
              )}
              <Divider />
              <div className={classes.dropdownText}>
                <Typography variant="h6" className={classes.dropdownText} color="textPrimary">
                  DXF:
                  <Select
                    variant="outlined"
                    input={<InputBase style={{ width: '350px' }} />}
                    className={classes.dropdownText}
                    defaultValue=""
                    value={dxfFile}
                    renderValue={(dxfFile) => <>{dxfFile}</>}
                    disabled={list.map((order) => order.cadFiles).length === 0}
                    onChange={(e) => handleDxfDropdownClick(e.target.value)}
                  >
                    <MenuItem value="None"> None </MenuItem>
                    {currentOrder?.cadFiles && currentOrder.cadFiles.map((cadFile) => (
                      <MenuItem value={`${cadFile._id}`}>
                        {cadFile.filename ? cadFile.filename.split('/')[cadFile.filename.split('/').length - 1] : 'original file'}
                      </MenuItem>
                    ))}
                  </Select>
                </Typography>
                <Typography variant="h6" className={classes.dropdownText} color="textPrimary">
                  KML:
                  {' '}
                  {currentOrder?.boundaryFile ? <Typography>{currentOrder.boundaryFile.split('/').pop()}</Typography> : 'NONE'}
                </Typography>
              </div>
            </Paper>
            <div className={classes.mapWrapper}>
              <Map />
            </div>
            <div className={classes.orderWrapper}>
              {currentOrder && <OrderCard order={currentOrder} projectId={projectId} hasValidEpsg={hasValidEpsg} tifMissing={tifMissing} />}
            </div>
          </div>
          <ConfirmDialog
            showDialog={showConfirmDeleteProject}
            onOk={onOkDeleteProject}
            onCancel={onCancel}
            contentText="Selected project will be deleted"
          />
          <ConfirmAmountDialog
            showDialog={showConfirmDialog}
            onOk={onOk}
            onCancel={onCancelAmount}
            confirmAmount={+(currentOrder?.creditAcreage?.toFixed(2))}
            orderId={orderId}
            orgId={orgId}
            projectId={projectId}
          />
          <ConfirmDialog
            showDialog={showConfirmStatusDialog}
            onOk={onOkStatusUpdate}
            onCancel={onCancelStatusUpdate}
            contentText={orderStatus === 7 ? 'Are you sure you want to change the status to done? Client will be notified by email.' : `Order status will be changed from ${GetOrderStatus(currentOrder?.orderStatus)} to ${GetOrderStatus(orderStatus)}.`}
          />
          <ConvertDialog
            orderId={currentOrder?._id}
            showDialog={showConvertDialog}
            handleConvert={handleConvert}
            onCloseModal={() => setShowConvertDialog(false)}
          />
        </div>

      </div>
    </LoadingSpinner>
  );
};

export default compose(AdminProject);
