// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Button } from '@material-ui/core';
import compose from './container';

const BundleButtons = compose(({
  classes, bundleStep, setBundleStep,
}) => {

  const handleBack = () => {
    setBundleStep(bundleStep - 1);
  };

  const handleNext = () => {
    setBundleStep(bundleStep + 1);
  };

  return (
    <div className={classes.buttonsContainer}>
      {bundleStep !== 0 ? <Button variant="outlined" onClick={handleBack}>Back</Button> : <div />}
      {bundleStep !== 2 ? <Button variant="contained" color="primary" onClick={handleNext}>Next</Button> : <div />}
    </div>
  );
});

export default BundleButtons;
