// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, LinearProgress } from '@material-ui/core';
import compose, { PropsType } from './container';

import useStyles from './styles';

export const Header = ({ totalAcreage, usedCreditAcreage, usedPercent, processingPercent, noAcreage, subscriptionType }: PropsType) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="subtitle1" color="textSecondary">
        <Typography component="span" variant="subtitle1" color="primary">{usedCreditAcreage}</Typography>
        {` of ${totalAcreage} credits`}
      </Typography>
      <Typography variant="body1">
        {subscriptionType === 'Monthly'
          ? 'processed this month'
          : 'processed this year'}
      </Typography>
      <LinearProgress
        variant="buffer"
        value={usedPercent}
        valueBuffer={usedPercent + processingPercent}
        classes={{
          barColorPrimary: noAcreage ? classes.noAcreage : classes.hasAcreage,
          bar2Buffer: noAcreage ? classes.noAcreage : classes.bar2,
          dashed: classes.dashedBar,
        }}
      />
    </>
  );
};

export default compose(Header);
