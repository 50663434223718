// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import settings from 'Assets/settings.svg';
import classnames from 'classnames';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const Settings = ({ currentPanel, editorFunctions, SetCurrentPanelAction }: PropsType) => {
  const classes = useStyles();

  return (
    <div className={classnames({ [classes.panelCurrent]: currentPanel === 'settings' })}>
      {editorFunctions && (
        <Tooltip title="Coming Soon">
          <Button
            style={{ opacity: 0.5 }}
            className={classes.option}
          // onClick={() => {
          //   SetCurrentPanelAction('settings');
          // }}
          >
            <SVG src={settings} title="settings" className={classes.icon} />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

export default compose(Settings);
