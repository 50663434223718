// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  warningIcon: {
    color: '#01a1dd',
  },
  dialog: {
    minWidth: '600px',
    margin: 'auto',
  },
  dialogBox: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  dialogTitle: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    alignItems: 'center',
    padding: theme.spacing(1),
  },
  okButton: {
    color: '#01a1dd',
    marginLeft: 'none',
    '&:hover': {
      backgroundColor: 'white !important',
    },
  },
  titleText: {
    paddingLeft: theme.spacing(1),
  },
});

export default styles;
