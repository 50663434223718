// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

export default {
  AdminGeneral: 'AdminGeneral',
  AdminEnterpriseSubscription: 'AdminEnterpriseSubscription',
  AdminUserManagement: 'AdminUserManagement',
  AccountBillingAddress: 'AccountBillingAddress',
  AccountPaymentMethod: 'AccountPaymentmethod',
  AccountPasswordReset: 'AccountPasswordReset',
  AccountCreateCard: 'AccountCreateCard',
  AccountEditCreditCard: 'AccountEditCreditCard',
  AccountGeneral: 'AccountGeneral',
  AccountGeneralOrg: 'AccountGeneralOrg',
  AccountUserManagement: 'AccountUserManagement',
  AccountTeams: 'AccountTeams',
  CreateProject: 'CreateProject',
  CreateOrgForm: 'CreateOrgForm',
  CheckPromoCodeForm: 'CheckPromoCodeForm',
  Login: 'Login',
  OrderAddress: 'OrderAddress',
  OrderPaymentInstrument: 'OrderPaymentInstrument',
  SubscriptionCreatePaymentInstrument: 'SubscriptionCreatePaymentInstrument',
  SubscriptionModalCreatePaymentInstrument: 'SubscriptionModalCreatePaymentInstrument',
  ForgotPassword: 'ForgotPassword',
  OrderData: 'OrderData',
};
