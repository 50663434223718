// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import '@babel/polyfill';
import WebFont from 'webfontloader';
import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiThemeProvider } from '@material-ui/core/styles';
import { Router } from 'react-router-dom';

import store from 'State/store';
import ErrorScreen from 'Components/errorScreen';
import { lightTheme } from 'Utils/themes';
import { InitAuth } from 'State/auth/thunk';
import BrowserHistory from 'Utils/history';
import TagManager from 'react-gtm-module';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

import App from './app/App';

if (_MODE_ === 'production') {
  Sentry.init({
    release: _VERSION_,
    dsn: 'https://47fa9213f6364dfe8a71e1cc423e8164@sentry.io/1525762',
    environment: _ENV_,
  });
}

WebFont.load({
  google: {
    families: ['Work+Sans:300,400,500,600'],
  },
});

InitAuth()(store.dispatch);

// Google tag manager
const tagManagerArgs = {
  gtmId: _TRACKINGID_,
  auth: _AUTH_,
  preview: _PREVIEW_,
};

TagManager.initialize(tagManagerArgs);

const Wrapper = () => (
  <CssBaseline>
    <ErrorScreen>
      <Provider store={store}>
        <MuiThemeProvider theme={lightTheme}>
          <Router history={BrowserHistory}>
            <HubspotProvider>
              <App />
            </HubspotProvider>
          </Router>
        </MuiThemeProvider>
      </Provider>
    </ErrorScreen>
  </CssBaseline>
);

(async () => {
  const LDProvider = await asyncWithLDProvider({ clientSideID: _LAUNCHDARKLY_CLIENT_SIDE_ID });
  ReactDOM.render(<LDProvider><Wrapper /></LDProvider>, document.getElementById('root'));
})();
