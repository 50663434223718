// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { ListInvoices } from 'State/invoice/thunk';

const mapStateToProps = (state: IState) => ({
  subscriptionId: state?.subscription?.cadSubscription?.subscriptionId,
  invoicesLoading: state.invoice.invoicesLoading,
  invoices: state.invoice.invoices,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    ListInvoicesAction: ListInvoices,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
