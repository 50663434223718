// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { InitializeTrainingProject } from 'State/ops/thunk';

interface IOwnProps {
  orderId?: string;
  showDialog: boolean;
  onCloseModal: () => void;
  handleConvert: () => void;
}

const mapDispatchToProps = {
  InitializeTrainingProject,
};

export type PropsType =
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(null, mapDispatchToProps),
);
