// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import {
  MAP_LAYERS_POLLING_INTERVAL_IN_MILLISECONDS,
  MAP_HIGH_RES_TILES_POLLING_INTERVAL_IN_MILLISECONDS,
} from 'Config';
import useInterval from 'Common/hooks/useInterval';
import moment from 'moment';
import compose from './container';

const MapLayers = compose(({ tif, lasBBoxLoading, lasBBox, rasterTileJson, showLayers, vectorLoading, rasterLoading, allRasterTilesLoaded, lowResCreatedAt, delayInRasterTileLoadAcknowledged, GetLasBBoxAction, SetDelayInRasterTileLoadAction, GetRasterTilesAction, GetVectorTilesAction, CheckHighResolutionRasterAction }) => {
  const loadRaster = async () => {
    if ((!rasterTileJson) && tif && rasterLoading) {
      await GetRasterTilesAction();
    }
  };

  const loadLasBBox = async () => {
    if ((!rasterTileJson) || (lasBBoxLoading && !lasBBox)) {
      await GetLasBBoxAction();
    }
  };

  const checkHighResolutionRaster = async () => {
    if (!allRasterTilesLoaded) {
      if (rasterTileJson && lowResCreatedAt) {
        await CheckHighResolutionRasterAction();
      }
      const utcTimeNow = moment.utc();
      if (lowResCreatedAt && utcTimeNow.diff(lowResCreatedAt, 'minutes') > 20 && !delayInRasterTileLoadAcknowledged) {
        SetDelayInRasterTileLoadAction(true);
      }
    }
  };

  const loadVector = async () => {
    if (showLayers && vectorLoading) {
      await GetVectorTilesAction();
    }
  };

  useInterval(() => {
    loadRaster();
    loadVector();
    loadLasBBox();
  }, MAP_LAYERS_POLLING_INTERVAL_IN_MILLISECONDS);

  useInterval(() => {
    checkHighResolutionRaster();
  }, MAP_HIGH_RES_TILES_POLLING_INTERVAL_IN_MILLISECONDS);

  return null;
});

export default MapLayers;
