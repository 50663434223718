// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { SetDeliverables, SetDensity } from 'State/bundle/thunk';
import { WithStyles, withStyles } from '@material-ui/core';
import styles from './styles';

const mapStateToProps = (state: IState) => ({
  availableBundles: state.bundle.availableBundles,
  selectedBundle: state.bundle.selectedBundle,
  density: state.bundle.density,
  bundles: state.bundle.list,
});

const mapDispatchToProps = {
  SetDeliverablesAction: SetDeliverables,
  SetDensityAction: SetDensity,
};

interface IOwnProps {
  deliverySpeed: string;
  checkFirstTimeChangeEstimate: (arg0: Function) => void;
  zeroAddOns: () => void;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, {}>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
