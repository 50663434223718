// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Button, Typography, Tooltip } from '@material-ui/core';
import SVG from 'react-inlinesvg';
import angle from 'Assets/angle-icon.svg';
import pointer from 'Assets/toolbar-pointer.svg';
import point from 'Assets/toolbar-point.svg';
import polyline from 'Assets/toolbar-polyLine.svg';
import arc from 'Assets/toolbar-arc.svg';
import toolbarBreak from 'Assets/toolbar-break.svg';
import join from 'Assets/toolbar-join.svg';
import moveBackward from 'Assets/toolbar-moveBackward.svg';
import back from 'Assets/toolbar-back.svg';
import moveForward from 'Assets/toolbar-moveForward.svg';
import front from 'Assets/toolbar-front.svg';
import area from 'Assets/area-icon.svg';
import distance from 'Assets/distance-icon.svg';
import angleActive from 'Assets/angle-icon-active.svg';
import pointerActive from 'Assets/toolbar-pointer-active.svg';
import areaActive from 'Assets/area-icon-active.svg';
import distanceActive from 'Assets/distance-icon-active.svg';
import pointActive from 'Assets/toolbar-point-active.svg';
import polylineActive from 'Assets/toolbar-polyLine-active.svg';
import arcActive from 'Assets/toolbar-arc-active.svg';
import toolbarBreakActive from 'Assets/toolbar-break-active.svg';
import joinActive from 'Assets/toolbar-join-active.svg';
import moveBackwardActive from 'Assets/toolbar-moveBackward-active.svg';
import backActive from 'Assets/toolbar-back-active.svg';
import moveForwardActive from 'Assets/toolbar-moveForward-active.svg';
import frontActive from 'Assets/toolbar-front-active.svg';
import circle from 'Assets/circle-icon.svg';
import circleActive from 'Assets/circle-icon-active.svg';
import save from 'Assets/toolbar-save.png';
import { SetCurrentTool } from 'State/map/editor/thunk';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders the measurement options adjacent to sidebar on CAD view
 *
 * @param {object} props Component props
 * @param {string} props.classes Injected style props
 * @param {string} props.currentOption Measurement option that the user selected
 * @param {function} props.SetCurrentOptionAction Sets the current measurement option in the state
 * @param {function} props.StartDrawAction Sets the draw tool's mode in the state
 * @param {function} props.SetMeasurementModeAction Toggles the measurement mode boolean in the state so that selection of a measurement option is recorded
 */
export const MeasurementOptions =
  ({
    currentMeasurementOption,
    editorFunctions,
    SetCurrentMeasurementOptionAction,
    StartDrawAction,
    SetMeasurementModeAction,
    UpdateEntityAction,
    currentTool,
    SetCurrentToolAction,
    SetEditorModeAction,
    SetDrawModeAction,
  }: PropsType) => {
    const classes = useStyles();
    useEffect(() => {
      StartDrawAction('simple_select');

      return () => {
        SetCurrentMeasurementOptionAction(null);
        StartDrawAction('direct_select');
      };
    }, []);

    return (
      <div className={classes.measurements}>
        {editorFunctions && (
          <div className={classes.type}>
            <div className={classes.measurements}>
              <Typography className={classes.typeName} variant="h4">Draw </Typography>
              <div className={classes.betaBanner}>
                BETA
              </div>
            </div>
            <div className={classes.measurements}>
              <Button
                className={classes.option}
                onClick={() => {
                  SetEditorModeAction(true);
                  SetDrawModeAction('simple_select');
                  SetCurrentToolAction('pointer');
                  SetCurrentMeasurementOptionAction(null);
                }}
              >
                {currentTool === 'pointer' ? (
                  <SVG
                    src={pointerActive}
                    title="pointer"
                    className={classes.icon}
                  />
                ) : (
                  <SVG src={pointer} title="pointer" className={classes.icon} />
                )}
              </Button>
              {/* {editorFunctions && (
                <Button className={classes.option} style = {{opacity: 0.5}} >
                  {currentTool === 'point' ? (
                    <SVG src={pointActive} title='point' className={classes.icon} />
                  ) : (
                    <SVG src={point} title='point' className={classes.icon} />
                  )}
                </Button>
              )} */}
              <Button
                className={classes.option}
                onClick={() => {
                  SetEditorModeAction(true);
                  SetDrawModeAction('draw_poly_line');
                  SetCurrentToolAction('polyline');
                  SetCurrentMeasurementOptionAction(null);
                }}
              >
                {currentTool === 'polyline' ? (
                  <SVG
                    src={polylineActive}
                    title="polyline"
                    className={classes.icon}
                  />
                ) : (
                  <SVG src={polyline} title="polyline" className={classes.icon} />
                )}
              </Button>
              <Button
                className={classes.option}
                onClick={() => {
                  SetEditorModeAction(true);
                  SetDrawModeAction('draw_circle');
                  SetCurrentToolAction('circle');
                  SetCurrentMeasurementOptionAction(null);
                }}
              >
                {currentTool === 'circle' ? (
                  <SVG src={circleActive} title="circle" className={classes.icon} />
                ) : (
                  <SVG src={circle} title="circle" className={classes.icon} />
                )}
              </Button>
              <Button 
                className={classes.option}
                onClick={() => {
                  SetEditorModeAction(true);
                  SetDrawModeAction('draw_poly_line');
                  SetCurrentToolAction('arc');
                  SetCurrentMeasurementOptionAction(null);
                }}
              >
                {currentTool === 'arc' ? (
                  <SVG src={arcActive} className={classes.icon} />
                ) : (
                  <SVG src={arc} className={classes.icon} />
                )}
              </Button>
            </div>
          </div>
        )}
        {/* {editorFunctions && (
          <Button className={classes.option} style = {{opacity: 0.5}}>
            {currentTool === 'toolbarBreak' ? (
              <SVG
                src={toolbarBreakActive}
                title='toolbarBreak'
                className={classes.icon}
              />
            ) : (
              <SVG src={toolbarBreak} title='toolbarBreak' className={classes.icon} />
            )}
          </Button>
        )}
        {editorFunctions && (
          <Button className={classes.option} style = {{opacity: 0.5}}>
            {currentTool === 'join' ? (
              <SVG src={joinActive} title='join' className={classes.icon} />
            ) : (
              <SVG src={join} title='join' className={classes.icon} />
            )}
          </Button>
        )}
       {editorFunctions && <span className = {classes.verticalLine}></span> }
        {editorFunctions && (
          <Button className={classes.option} style = {{opacity: 0.5}}>
            {currentTool === 'moveBackward' ? (
              <SVG
                src={moveBackwardActive}
                title='moveBackward'
                className={classes.icon}
              />
            ) : (
              <SVG src={moveBackward} title='moveBackward' className={classes.icon} />
            )}
          </Button>
        )}
        {editorFunctions && (
          <Button className={classes.option} style = {{opacity: 0.5}}>
            {currentTool === 'back' ? (
              <SVG src={backActive} title='back' className={classes.icon} />
            ) : (
              <SVG src={back} title='back' className={classes.icon} />
            )}
          </Button>
        )}
        {editorFunctions && (
          <Button className={classes.option} style = {{opacity: 0.5}}>
            {currentTool === 'moveForward' ? (
              <SVG
                src={moveForwardActive}
                title='moveForward'
                className={classes.icon}
              />
            ) : (
              <SVG src={moveForward} title='moveForward' className={classes.icon} />
            )}
          </Button>
        )}
        {editorFunctions && (
          <Button className={classes.option} style = {{opacity: 0.5}}>
            {currentTool === 'front' ? (
              <SVG src={frontActive} title='front' className={classes.icon} />
            ) : (
              <SVG src={front} title='front' className={classes.icon} />
            )}
          </Button>
        )} */}
        <div className={classes.type}>
          <div className={classes.measurements}>
            <Typography className={classes.typeName} variant="h4">Measure </Typography>
          </div>
          <div className={classes.measurements}>
            <Button
              className={classes.option}
              onClick={() => {
                SetEditorModeAction(false);
                SetCurrentToolAction(null);
                SetMeasurementModeAction();
                StartDrawAction('draw_angle');
                SetCurrentMeasurementOptionAction('angle');
              }}
            >
              {currentMeasurementOption === 'angle' ? (
                <SVG src={angleActive} title="angle" className={classes.icon} />
              ) : (
                <SVG src={angle} title="angle" className={classes.icon} />
              )}
            </Button>
            <Button
              className={classes.option}
              onClick={() => {
                SetEditorModeAction(false);
                SetCurrentToolAction(null);
                SetMeasurementModeAction();
                StartDrawAction('draw_polygon');
                SetCurrentMeasurementOptionAction('polygon');
              }}
            >
              {currentMeasurementOption === 'polygon' ? (
                <SVG src={areaActive} title="area" className={classes.icon} />
              ) : (
                <SVG src={area} title="area" className={classes.icon} />
              )}
            </Button>
            <Button
              className={classes.option}
              onClick={() => {
                SetEditorModeAction(false);
                SetCurrentToolAction(null);
                SetMeasurementModeAction();
                StartDrawAction('draw_line_string');
                SetCurrentMeasurementOptionAction('line_string');
              }}
            >
              {currentMeasurementOption === 'line_string' ? (
                <SVG
                  src={distanceActive}
                  title="distance"
                  className={classes.icon}
                />
              ) : (
                <SVG src={distance} title="distance" className={classes.icon} />
              )}
            </Button>
          </div>
        </div>

        {/* Commented out Save button - Vishwak 5/25 WEB-203 */}
        {/* {editorFunctions && ( */}
        {/*  <Button */}
        {/*    className={classes.option} */}
        {/*    onClick={() => { */}
        {/*      UpdateEntityAction(); */}
        {/*    }} */}
        {/*  > */}
        {/*    <img src={save} alt='save' className={classes.icon} /> */}
        {/*  </Button> */}
        {/* )} */}
      </div>
    );
  };

export default compose(MeasurementOptions);
