// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Dialog, Slide } from '@material-ui/core';
import SubscriptionForm from './SubscriptionForm';
import compose from './container';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const CreateSubscriptionDialog = compose(({ classes, cadSubscriptionExists, showSubscriptionDialog, setShowSubscriptionDialog }) => (
  <Dialog
    fullScreen
    open={showSubscriptionDialog}
    TransitionComponent={Transition}
  >
    <SubscriptionForm {...{ cadSubscriptionExists, setShowSubscriptionDialog }}/>
  </Dialog>
));

export default CreateSubscriptionDialog;
