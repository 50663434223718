// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { PlaceOrder, EditOrder } from 'State/order/thunk';
import { getOrders } from 'State/order/selectors';
import styles from './styles';

const mapStateToProps = (state: IState) => {
  const { teams } = state.auth.user;
  const { orgTeams } = state.account;
  const { activeOrder } = getOrders(state);
  const availableTeamNames: string[] = [];

  const { team } = activeOrder;
  function isUserTeam(value: ITeam) {
    if (teams.includes(value._id)) {
      availableTeamNames.push(value.name);
      return value._id;
    }
    return false;
  }
  const availableTeams = orgTeams.filter((t) => isUserTeam(t));
  const currentTeam = availableTeams?.find((t) => t._id === team);
  return {
    creditCards: state.subscription.creditCards,
    bundleDeliverables: state.bundle.bundleDeliverables,
    selectedAdditionals: state.bundle.selectedAdditionals,
    totalCost: state.bundle.totalCost,
    placeOrderSuccess: state.order.placeOrderSuccess,
    referenceId: getOrders(state).activeOrder?.referenceId,
    acreage: getOrders(state).activeOrder?.acreage,
    currentTeam,
  };
};

const mapDispatchToProps = {
  PlaceOrderAction: PlaceOrder,
  EditOrderAction: EditOrder,
};

interface IOwnProps {
  setPage: (arg0: number) => void;
  addOnDeliverableDollars: number;
  addOnLayerDollars: number;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
