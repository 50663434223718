// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';

import { InitMap, SetViewport, SetCanvasSize, SetLat, SetLng, ToggleNoneVectorTiles } from 'State/map/common/thunk';
import { InitDraw } from 'State/map/draw/thunk';
import { getAllCoordinates, getHoveredCoordinates } from 'State/project/selectors';

import { LoadKmls } from 'State/kml/thunk';
import { GetOrders } from 'State/order/thunk';
import { SetScreenshotAction } from 'State/map/common/actions';
import { getOrders } from 'State/order/selectors';
import styles from './styles';

const mapStateToProps = (state: IState) => {
  const coordinates = getAllCoordinates(state);
  const hoveredCoords = getHoveredCoordinates(state);
  const order = getOrders(state).activeOrder;
  const { projectId } = state.project;
  const currentProject = state.project.project;
  const imageryRequested = (order?.isEstimate && !!(currentProject?.imageryRequest?.requested)) || !!(currentProject?.imageryRequest?.uploaded) || !!(currentProject?.imageryRequest?.notificationSent);
  return {
    imageryRequested,
    coordinates,
    hoveredCoords,
    viewport: state.map.common.viewport,
    layers: state.map.common.layers,
    projectId,
    lasBBoxLoading: state.map.common.lasBBoxLoading,
    mapStyle: state.map.common.mapStyle,
    rasterTileJson: state.map.common.rasterTileJson,
    rasterLoading: state.map.common.rasterTileJsonLoading,
    vectorLoading: state.map.common.vectorTileJsonLoading,
    editor: state.map.editor.present.editorMode,
    showSidebar: state.map.editor.present.showSidebar,
    editorFunctions: !_ADMIN_ ? 'editorFunctions' in state.auth.resources : true,
    opsTrainer: !_ADMIN_ && 'opsTrainer' in state.auth.resources,
  };
};

const mapDispatchToProps = {
  SetViewportAction: SetViewport,
  InitDrawAction: InitDraw,
  InitMapAction: InitMap,
  SetCanvasSizeAction: SetCanvasSize,
  SetLatAction: SetLat,
  SetLngAction: SetLng,
  ToggleNoneVectorTilesAction: ToggleNoneVectorTiles,
  LoadKmlsAction: LoadKmls,
  GetOrdersAction: GetOrders,
  SetScreenshotAction,
};

interface IOwnProps {
  mode: 'details' | 'sidebar' | 'layers' | 'dashboard';
  map: any;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
