// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';

import { DeleteTemplate, DuplicateTemplate } from 'State/cadTemplate/thunk';

const mapStateToProps = (state: IState) => ({
  list: state.cadTemplate.list,
  org: state.account.org,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    DeleteTemplateAction: DeleteTemplate,
    DuplicateTemplateAction: DuplicateTemplate,
  }, dispatch);

interface IOwnProps {
  templateId: string;
  template: ICadTemplate;
  index: number;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
