// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import {
  GetImageryProvidersAsync,
  CreateImageryProviderAsync,
  ImageryCoverageAsync,
  ResetImageryCoverageAction,
} from './actions';

const initialState: IImageryState = {
  loading: false,
  imageryProviders: [],
  nearmapCoverageLoading: false,
  nearmapCoverage: false,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(GetImageryProvidersAsync.request, (draft) => { draft.loading = true; });
  handleAction(GetImageryProvidersAsync.success, (draft, payload) => {
    draft.loading = false;
    draft.imageryProviders = payload;
  });
  handleAction(GetImageryProvidersAsync.failure, (draft) => { draft.loading = false; });

  handleAction(ResetImageryCoverageAction, (draft) => {
    draft.nearmapCoverage = false;
  });

  handleAction(CreateImageryProviderAsync.request, (draft) => draft);
  handleAction(CreateImageryProviderAsync.success, (draft) => draft);
  handleAction(CreateImageryProviderAsync.failure, (draft) => draft);

  handleAction(ImageryCoverageAsync.request, (draft) => {
    draft.nearmapCoverageLoading = true;
  });
  handleAction(ImageryCoverageAsync.success, (draft, payload) => {
    draft.nearmapCoverageLoading = false;
    draft.nearmapCoverage = payload;
  });
  handleAction(ImageryCoverageAsync.failure, (draft) => {
    draft.nearmapCoverageLoading = false;
    draft.nearmapCoverage = false;
  });
});
