// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import { Typography, Card } from '@material-ui/core';
import LoadingSpinner from 'Common/LoadingSpinner';
import InvoiceInfo from './InvoiceInfo';
import compose, { PropsType } from './container';
import useStyles from './styles';

/**
 * Component renders the all invoices in a users CAD subscription
 *
 * @param {object} props Component props
 * @param {IInvoice[]} props.invoices The users CAD subscription stripe invoices
 * @param {function} props.ListInvoicesAction Function to call the listInvoices API
 * @param {string} props.subscriptionId The users CAD subscription stripe id
 * @param {boolean} props.invoicesLoading Boolean to indicate whether the listInvoices API call has finished
 */
export const InvoiceList = ({ invoices, ListInvoicesAction, subscriptionId, invoicesLoading }: PropsType) => {
  const classes = useStyles();

  useEffect(() => {
    if (subscriptionId) {
      ListInvoicesAction();
    }
  }, [subscriptionId]);
  return (
    <>
      <Card className={classes.invoiceListContent}>
        <Typography className={classes.message} variant="h4">CAD Invoice History</Typography>
        <LoadingSpinner loading={invoicesLoading}>
          {invoices.length ?
            invoices.map((invoice) => <InvoiceInfo key={invoice.created} invoice={invoice} />)
            : <Typography className={classes.message}>Looks like you don&#39;t have any invoices yet</Typography>}
        </LoadingSpinner>
      </Card>
    </>
  );
};

export default compose(InvoiceList);
