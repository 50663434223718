// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import moment from 'moment';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';
import useInterval from 'Common/hooks/useInterval';
import compose, { PropsType } from './container';

export const ExpiredSessionModal = ({ showDialog, tokenExpires, ShowTokenExpiredDialogAction, HideTokenExpiredDialogAction, LogoutAction }: PropsType) => {
  const checkToken = () => {
    const currentTime = moment.utc().unix();
    if (currentTime >= tokenExpires) {
      ShowTokenExpiredDialogAction();
    }
  };

  useEffect(() => {
    if (tokenExpires) checkToken();
  }, []);

  useInterval(() => {
    checkToken();
  }, 1000);

  const onLogountClick = () => {
    LogoutAction();
    HideTokenExpiredDialogAction();
  };

  return (
    <Dialog
      open={showDialog}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle>Your session has expired</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please sign out, then sign in again.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onLogountClick} color="primary">
          Sign Out
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(ExpiredSessionModal);
