// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const useStyles = (theme: Theme) => createStyles({
  uploadField: {
    borderWidth: 1,
    borderRadius: theme.shape.borderRadius,
    borderStyle: 'dashed',
    backgroundColor: theme.palette.grey[50],
    borderColor: theme.palette.grey[400],
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
  },
});

export default useStyles;
