// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  warningIcon: {
    color: '#2E4DEB',
    position: 'relative',
    top: '6px',
  },
  dialogRoot: {
    padding: '19px 55px 15px 25px',
  },
  onSave: {
    color: '#2E4DEB',
    marginLeft: 'none',
    '&:hover': {
      backgroundColor: 'rgba(28, 54, 75, 0.08) !important',
    },
  },
  closeButton: {
    position: 'absolute',
    right: '3px',
    color: theme.palette.grey[500],
  },
  submitButton: {
    boxShadow: 'none',
    width: '100%',
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
}));

export default useStyles;
