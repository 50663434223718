// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Link } from 'react-router-dom';
import { Tabs, Tab } from '@material-ui/core';
import { ADMIN_ROUTE, ADMIN_OPS_SETTINGS_ROUTE } from 'Utils/routes';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const AdminToolbar = ({ activeTabId }: PropsType) => {
  const classes = useStyles();

  return (
    <Tabs indicatorColor="primary" value={activeTabId}>
      <Tab
        component={Link}
        label="Customers"
        value={1}
        to={ADMIN_ROUTE}
        classes={{
          wrapper: classes.tabLabel,
        }}
      />
      <Tab
        component={Link}
        label="OPS Settings"
        value={2}
        to={ADMIN_OPS_SETTINGS_ROUTE}
        classes={{
          wrapper: classes.tabLabel,
        }}
      />
    </Tabs>
  );
};

export default compose(AdminToolbar);
