// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { combineReducers } from 'redux';
import undoable, { includeAction } from 'redux-undo';

import authReducer from 'State/auth/reducer';
import accountReducer from 'State/account/reducer';
import projectReducer from 'State/project/reducer';
import orderReducer from 'State/order/reducer';
import bundleReducer from 'State/bundle/reducer';
import kmlReducer from 'State/kml/reducer';
import fileUploadReducer from 'State/fileUpload/reducer';
import adminReducer from 'State/admin/reducer';
import invoiceReducer from 'State/invoice/reducer';
import imageryReducer from 'State/imagery/reducer';
import globalStatusReducer from 'State/globalStatus/reducer';
import subscriptionReducer from 'State/subscription/reducer';
import notificationReducer from 'State/notification/reducer';
import cadTemplateReducer from 'State/cadTemplate/reducer';
import mapCommonReducer from 'State/map/common/reducer';
import mapDrawReducer from 'State/map/draw/reducer';
import mapGeocodeReducer from 'State/map/geocode/reducer';
import mapEditorReducer from 'State/map/editor/reducer';
import uploadKmlDialogReducer from 'State/ui/uploadKmlDialog/reducer';
import opsTrainingReducer from 'State/ops/reducer';
import tokenExpiredDialogReducer from 'State/ui/tokenExpiredDialog/reducer';
import viewer3dReducer from 'State/viewer3D/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  account: accountReducer,
  cadTemplate: cadTemplateReducer,
  project: projectReducer,
  order: orderReducer,
  imagery: imageryReducer,
  bundle: bundleReducer,
  kml: undoable(kmlReducer),
  fileUpload: fileUploadReducer,
  admin: adminReducer,
  invoice: invoiceReducer,
  globalStatus: globalStatusReducer,
  subscription: subscriptionReducer,
  notification: notificationReducer,
  opsTraining: opsTrainingReducer,
  map: combineReducers({
    common: mapCommonReducer,
    draw: undoable(mapDrawReducer, { filter: includeAction(['UPDATE_KML_FEATURE']) }),
    geocode: mapGeocodeReducer,
    editor: undoable(mapEditorReducer),
  }),
  ui: combineReducers({
    uploadKmlDialog: uploadKmlDialogReducer,
    tokenExpiredDialog: tokenExpiredDialogReducer,
  }),
  viewer3D: viewer3dReducer,
});

export default rootReducer;
