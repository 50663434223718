// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import CreateMap from 'Common/Map';
import UploadKmlDialog from 'Common/FileUploadForm/SingleFileUploadForm';
import LoadingSpinner from 'Common/LoadingSpinner';
import EpsgProjection from 'Common/epsgProjection';
import KmlBufferDialog from './KmlBufferDialog';
import KmlToolBar from './KmlToolBar';
import Sidebar from './Sidebar';
import container from './container';

const Map = CreateMap('details');

const Project = container(({ classes, match, opsTrainer, showKmlToolBar, ResetKmlDrawAction, ResetProjectTifInfoAction, ResetProjectLasInfoAction, SetShowKmlToolbarAction, ResetBufferStateAction, ResetEditorStateAction, SetFileStatusAction, SetProjectIdAction, GetProjectAction, LoadKmlsAction, CreateOrderAction, GetOrdersAction, SaveEstimateAction, SetShowBusinessDaysAction, SetBufferSizeAction, SetShowBufferInfoAction }) => {
  const [loading, setLoading] = useState(true);
  const onLoad = async () => {
    const projectId = match.params.id;
    SetProjectIdAction(projectId);
    // Always get orders in a project
    // eslint-disable-next-line no-unused-expressions
    const projectOrders = await GetOrdersAction(projectId);
    await GetProjectAction();

    // Get active order from the project orders and create one only if it does not exist
    const activeOrder = projectOrders?.data?.find((o: IOrder) => o.ownerProject === projectId && o.orderStatus === 0);

    if (!activeOrder && !opsTrainer) {
      await CreateOrderAction(projectId);
    }

    if (activeOrder?.isEstimate) {
      SaveEstimateAction(activeOrder.cadTemplate, null, true, activeOrder, false);
    }

    if (activeOrder?.boundaryFile) {
      const activeKmlName = activeOrder.boundaryFile.split('/').pop();
      if (activeKmlName.includes('buffered')) {
        const bufferSize = activeKmlName.split('_').slice(-2, -1)[0].slice(0, -4);
        SetShowBufferInfoAction(true);
        SetBufferSizeAction(parseFloat(bufferSize));
        SetShowKmlToolbarAction(true);
      }
    }

    await LoadKmlsAction();

    setLoading(false);
    SetShowBusinessDaysAction(true);
  };

  useEffect(() => {
    onLoad();

    return () => {
      SetProjectIdAction(null);
      SetFileStatusAction('reset');
      ResetEditorStateAction();
      ResetKmlDrawAction();
      ResetProjectTifInfoAction();
      ResetProjectLasInfoAction();
      ResetBufferStateAction();
      SetShowKmlToolbarAction(false);
    };
  }, []);

  useEffect(() => {
    if (!loading) {
      const intervalId = setInterval(async () => {
        const response = await GetProjectAction();
        // stop polling when get project action failed(e.g., when the project no longer exists)
        if (!response.success) {
          clearInterval(intervalId);
        }
      }, 3000);
    }
  }, [loading]);

  return (
    <LoadingSpinner loading={loading}>
      <Sidebar />
      <div className={classes.mapWrapper} id="uploadKmlFormContainer">
        {showKmlToolBar && <KmlToolBar />}
        <Map />
        <EpsgProjection />
      </div>
      <KmlBufferDialog />
      <UploadKmlDialog containerId="uploadKmlFormContainer" fileExtension=".kml" adminOutputUpload={false} />
    </LoadingSpinner>
  );
});

export default Project;
