// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from "react";
import LayersPanel from "./LayersPanel";
import ObjectProperties from "./ObjectProperties";
import Autodraft from "./Autodraft";
import Settings from "./Settings";
import compose from './container';
import useStyles from './styles';

export const Panel = () => {
  const classes = useStyles();

  return (
    <div className={classes.panelContent}>
      <LayersPanel />
      <ObjectProperties />
      <Autodraft />
      <Settings />
    </div>
  );
};

export default compose(Panel);
