// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Layer, Source, Popup } from 'react-mapbox-gl';
import { Typography } from '@material-ui/core';
import compose from './container';

const MapLasBBox = compose(({ lasBBox, kmlFile }) => {
  let bboxGeoJSON: GeoJSON.Feature<GeoJSON.Polygon> = {
    type: 'Feature',
    geometry: undefined,
    properties: {},
  };

  if (lasBBox) {
    bboxGeoJSON = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [[
          [lasBBox[0], lasBBox[1]],
          [lasBBox[2], lasBBox[1]],
          [lasBBox[2], lasBBox[3]],
          [lasBBox[0], lasBBox[3]],
          [lasBBox[0], lasBBox[1]],
        ]],
      },
      properties: {},
    };
  }

  return (
    <>
      {lasBBox && (
        <>
          <Source
            id="las-bbox"
            geoJsonSource={{
              type: 'geojson',
              data: bboxGeoJSON,
            }}
          />
          <Layer
            id="las-bbox-layer"
            sourceId="las-bbox"
            layout={{
              visibility: !kmlFile ? 'visible' : 'none',
            }}
            type="line"
            paint={{
              'line-color': '#ff0000',
              'line-width': 2,
            }}
          />
        </>
      )}
      {(lasBBox && !kmlFile) && (
        <Popup coordinates={[lasBBox[2], lasBBox[3]]}>
          <Typography style={{ fontWeight: 400, fontSize: '16px' }} variant="body2">
            Las Bounding Box
          </Typography>
        </Popup>
      )}
    </>
  );
});

export default MapLasBBox;
