// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { SetCurrentPanel } from 'State/map/editor/thunk';

const mapStateToProps = (state: IState) => ({
  editorFunctions: 'editorFunctions' in state.auth.resources,
  propertiesPanel: 'propertiesPanel' in state.auth.resources,
  currentPanel: state.map.editor.present.currentPanel,
});

const mapDispatchToProps = {
  SetCurrentPanelAction: SetCurrentPanel,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
