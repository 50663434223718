// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { ToggleProjectLayer } from 'State/map/common/thunk';
import { SetCurrentLayer, SetlayerSelected, AddNewLayer, EditLayerAttribute, DeleteLayer, Autosave, SetOrderSelected } from 'State/map/editor/thunk';

const mapStateToProps = (state: IState) => ({
  fileVersions: state.order.fileVersions,
  layerSelected: state.map.editor.present.layerSelected,
  editorFunctions: 'editorFunctions' in state.auth.resources,
});

const mapDispatchToProps = {
  ToggleProjectLayerAction: ToggleProjectLayer,
  SetCurrentLayerAction: SetCurrentLayer,
  SetlayerSelectedAction: SetlayerSelected,
  SetOrderSelectedAction: SetOrderSelected,
  AddNewLayerAction: AddNewLayer,
  EditLayerAttributeAction: EditLayerAttribute,
  AutosaveAction: Autosave,
  DeleteLayerAction: DeleteLayer,
};

interface IOwnProps {
  layers?: IVectorTileJson;
  expressAIInfo?: boolean;
  order: IOrder;
  siteId: string;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
