// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { TextField as MaterialTextField } from '@material-ui/core';
import { TextField, Select, Validators } from 'Common/forms';
import states from 'Common/states';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const CreateCardForm = ({ editable, initialValues }: PropsType) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.row}>
        {!editable && (
          <>
            <TextField
              label="Credit Card Number"
              name="number"
              mask="9999 9999 9999 9999"
              placeholder="0000 0000 0000 0000"
              isCreditCard
              validate={Validators.composeValidators(Validators.required, Validators.creditCard)}
            />
            <TextField label="Exp Date" name="expDate" placeholder="mm/yy" mask="99/99" validate={Validators.composeValidators(Validators.required, Validators.expireDate)} />
            <TextField label="CVV" name="cvv" mask="999" placeholder="000" />
          </>
        )}
        {editable && (
          <>
            <MaterialTextField disabled value={initialValues.number} label="Credit Card Number" />
            <MaterialTextField disabled value={initialValues.expDate} label="Exp Date" />
            <MaterialTextField disabled value="***" label="CVV" />
          </>
        )}
      </div>
      <TextField label="Name" name="name" validate={Validators.required} />
      <TextField label="Street Address" name="address_line1" validate={Validators.required} />
      <TextField label="Street Address 2" name="address_line2" />
      <div className={classes.row}>
        <TextField label="City" name="address_city" validate={Validators.required} />
        <Select label="State" name="address_state" values={states} validate={Validators.required} />
        <TextField label="Zip Code" name="address_zip" validate={Validators.required} />
      </div>
    </div>
  );
};

export default compose(CreateCardForm);
