// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const GetProjectsAsync = createAsyncAction(
  'GET_PROJECTS_START',
  'GET_PROJECTS_SUCCESS',
  'GET_PROJECTS_FAILED',
)<null, IProjectSuccess, string>();

export const GetProjectAsync = createAsyncAction(
  'GET_PROJECT_START',
  'GET_PROJECT_SUCCESS',
  'GET_PROJECT_FAILED',
)<null, IProject, string>();

export const SetPageAction = createAction('SET_PAGE', (action) => (data: number) => action(data));

export const SetProjectsAction = createAction('SET_PROJECTS', (action) => (data: IProject[]) => action(data));

export const SetLoadAllAction = createAction('SET_LOAD_ALL', (action) => (data: boolean) => action(data));

export const SetLoadNewAction = createAction('SET_LOAD_NEW', (action) => (data: boolean) => action(data));

export const SetFileStatusJsonAction = createAction('SET_FILE_STATUS_JSON', (action) => (data: {[key: string]: IFileStatusJson}) => action(data));

export const SetProjectIdAction = createAction('SET_PROJECT_ID', (action) => (data: string) => action(data));

export const ChangeJsonAction = createAction('CHANGE_JSON');

export const SetWarningAcceptedAction = createAction('SET_ACCEPT_WARNING');

export const SetThumbnailAsync = createAsyncAction(
  'SET_THUMBNAIL_START',
  'SET_THUMBNAIL_SUCCESS',
  'SET_THUMBNAIL_FAILED',
)<null, IReadThumbnailResponse, null>();

export const CreateProjectAsync = createAsyncAction(
  'CREATE_PROJECT_START',
  'CREATE_PROJECT_SUCCESS',
  'CREATE_PROJECT_FAILED',
)<null, IProject, string>();

export const DeleteProjectAsync = createAsyncAction(
  'DELETE_PROJECT_START',
  'DELETE_PROJECT_SUCCESS',
  'DELETE_PROJECT_FAILED',
)<string, string, string>();

export const UpdateProjectNameAsync = createAsyncAction(
  'UPDATE_PROJECT_NAME_START',
  'UPDATE_PROJECT_NAME_SUCCESS',
  'UPDATE_PROJECT_NAME_FAILED',
)<null, IProject, null>();

export const DeleteTifFileAsync = createAsyncAction(
  'DELETE_TIF_FILE_START',
  'DELETE_TIF_FILE_SUCCESS',
  'DELETE_TIF_FILE_FAILED',
)<null, any, null>();

export const DeleteLasFileAsync = createAsyncAction(
  'DELETE_LAS_FILE_START',
  'DELETE_LAS_FILE_SUCCESS',
  'DELETE_TIF_FILE_FAILED',
)<null, any, null>();

export const SendImageryUploadEmailAsync = createAsyncAction(
  'SEND_IMAGERY_UPLOAD_EMAIL_START',
  'SEND_IMAGERY_UPLOAD_EMAIL_SUCCESS',
  'SEND_IMAGERY_UPLOAD_EMAIL_FAILED',
)<null, IImageryRequest, string>();

export const UpdateImageryRequestAsync = createAsyncAction(
  'UPDATE_IMAGERY_REQUEST_START',
  'UPDATE_IMAGERY_REQUEST_SUCCESS',
  'UPDATE_IMAGERY_REQUEST_FAILED',
)<null, IImageryRequest, string>();

export const CreateImageryRequestAsync = createAsyncAction(
  'CREATE_IMAGERY_REQUEST_START',
  'CREATE_IMAGERY_REQUEST_SUCCESS',
  'CREATE_IMAGERY_REQUEST_FAILED',
)<null, IImageryRequest, string>();

export const SetImageryVendorSelectedAction = createAction('SET_IMAGERY_VENDOR', (action) => (data: string) => action(data));

export const SetProjectionSelectedAction = createAction('SET_PRROJECTION', (action) => (data: string) => action(data));

export const SetKeywordFilterAction = createAction('SET_KEYWORD_FILTER', (action) => (data: string) => action(data));

export const SetSortFilterAction = createAction('SET_SORT_FILTER', (action) => (data: ProjectSortFilter) => action(data));

export const SetOrderFilterAction = createAction('SET_ORDER_FILTER', (action) => (data: ProjectOrderSortFilter) => action(data));

export const UpdateProjectFilesAction = createAction('UPDATE_ORDER_FILES', (action) => (data: {
    tif?: string, las?: string,
  }) => action(data));

export const UpdateProjectTifFilesAction = createAction('GET_TIF_FILE', (action) => (data: ITifFile) => action(data));

export const UpdateProjectLasFilesAction = createAction('GET_LAS_FILE', (action) => (data: ILasFile) => action(data));

export const UpdateProjectNameAction = createAction('UPDATE_PROJECT_NAME', (action) => (data: {_id: string, name: string }) => action(data));

export const UpdateProjectAction = createAction('UPDATE_PROJECT', (action) => (data: IProject) => action(data));

export const GetEpsgProjectionsAction = createAction('GET_EPSG_PROJECTIONS', (action) => (data: IEPSG) => action(data));

export const ResetEpsgProjectionsAction = createAction('RESET_EPSG_PROJECTIONS');

export const SetShowKmlToolbarAction = createAction('SET_SHOW_KML_TOOLBAR', (action) => (data: boolean) => action(data));

export const UpdateImageryRequestAction = createAction('UPDATE_IMAGERY_REQUEST', (action) => (data: IImageryRequest) => action(data));

export const SetAvailableProjections = createAction('SET_AVAILABLE_PROJECTIONS', (action) => (data: string[]) => action(data));

export const ToggleImageryProcessingAction = createAction('TOGGLE_IMAGERY_PROCESSING');
