// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom';

import { connect } from 'react-redux';
import { ACCOUNT_GENERAL_ROUTE, CAD_TEMPLATES_ROUTE, ACCOUNT_BILLING_ROUTE, ACCOUNT_PASSWORD_ROUTE, ORDER_HISTORY_ROUTE, ACCOUNT_PLAN_USAGE, USER_MANAGEMENT_ROUTE, TEAMS_ROUTE } from 'Utils/routes';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps) => ({
  organizationAccess: !_ADMIN_ && 'organizationAccess' in state.auth.resources,
  // eslint-disable-next-line no-console
  billing: !_ADMIN_ && 'billing' in state.auth.resources,
  processingUsage: !_ADMIN_ && 'processingUsage' in state.auth.resources,
  cadTemplates: !_ADMIN_ && 'cadTemplates' in state.auth.resources,
  placeOrder: !_ADMIN_ && 'placeOrder' in state.auth.resources,
  org: state.account.org,
  activeTabId: (matchPath(ownProps.location.pathname, { path: ACCOUNT_GENERAL_ROUTE }) && 1)
    || (matchPath(ownProps.location.pathname, { path: CAD_TEMPLATES_ROUTE }) && 2)
    || (matchPath(ownProps.location.pathname, { path: ACCOUNT_BILLING_ROUTE }) && 3)
    || (matchPath(ownProps.location.pathname, { path: ACCOUNT_PASSWORD_ROUTE }) && 4)
    || (matchPath(ownProps.location.pathname, { path: ORDER_HISTORY_ROUTE }) && 5)
    || (matchPath(ownProps.location.pathname, { path: ACCOUNT_PLAN_USAGE }) && 6)
    || (matchPath(ownProps.location.pathname, { path: USER_MANAGEMENT_ROUTE }) && 7)
    || (matchPath(ownProps.location.pathname, { path: TEAMS_ROUTE }) && 8),
});

export type PropsType =
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, null),
);
