// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  subscriptionContent: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
  },
  wrapper: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
  },
  header: {
    marginBottom: theme.spacing(3),
  },
  dialogWrapper: {
    margin: theme.spacing(4),
  },
  divider: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  planName: {
    display: 'inline',
    fontSize: '22px',
    lineHeight: '25px',
    color: '#1C364B',
  },
  planDetails: {
    fontSize: '16px',
    lineHeight: '19px',
    marginTop: theme.spacing(2),
    color: '#484D52',
    marginRight: theme.spacing(1),
  },
  planOptions: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    alignItems: 'center',
  },
  optionsButton: {
    color: '#01A1DD',
    textTransform: 'none',
    fontSize: '16px',
    lineHeight: '19px',
    marginTop: theme.spacing(2),
  },
  cadDetails: {
    display: 'grid',
    gridTemplateColumns: 'auto auto 1fr',
    alignItems: 'center',
  },
}));

export default useStyles;
