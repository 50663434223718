// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction, createAsyncAction } from 'typesafe-actions';

export const SetOrder3DCadFilesListAction = createAction('SET_ORDER_3D_CAD_FILES_LIST', (action) => (data: { [key: string]: string[] }) => action(data));
export const SetSelectedCadFile3DLayersAction = createAction('SET_SELECTED_CAD_FILE_3D_LAYERS', (action) => (data: { orderId: string, cadFile3DLayers: I3DLayer }) => action(data));
export const SetCad3DLayersAction = createAction('SET_CAD_3D_LAYERS', (action) => (data: { [key: string]: ICad3DLayer }) => action(data));
export const GetSelectedCadFile3DLayersAysnc = createAsyncAction(
  'GET_SELECTED_CAD_FILE_3D_LAYERS_START',
  'GET_SELECTED_CAD_FILE_3D_LAYERS_SUCCESS',
  'GET_SELECTED_CAD_FILE_3D_LAYERS_FAILED',
)<null, { orderId: string, cadFile3DLayers: I3DLayer }, string>();

export const Toggle3DLayerAction = createAction('TOGGLE_3D_LAYER', (action) => (data: { siteId: string, layerId: number }) => action(data));

export const Select3DCadFileAction = createAction('SELECT_3D_FILE_ACTION', (action) => (data: { orderId: string, siteId: string }) => action(data));

export const Toggle3DOrderAction = createAction('TOGGLE_3D_ORDER', (action) => (data: string) => action(data));

export const SetLayer3DChangeAction = createAction('SET_LAYER_3D_CHANGE', (action) => (data: (string|boolean)[]) => action(data));

export const Clear3DLineworkAction = createAction('CLEAR_3D_LINEWORK', (action) => () => action());

export const Get3DLineworkAction = createAsyncAction(
  'GET_3D_LINEWORK_START',
  'GET_3D_LINEWORK_SUCCESS',
  'GET_3D_LINEWORK_FAILED',
)<null, ILinework3DState[], string>();
