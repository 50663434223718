// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Collapse, IconButton, Typography, ListItem } from '@material-ui/core';
import Tooltip from 'Common/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import moment from 'moment';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import ErrorIcon from '@material-ui/icons/Error';
import ClearIcon from '@material-ui/icons/Clear';
import SVG from 'react-inlinesvg';
import nearmapLogo from 'Assets/nearmap-logo.png';
import uploadKmlIcon from 'Assets/upload-kml-icon.svg';
import UploadFilesDialog from 'Common/UploadFilesDialog';
import ConfirmDialog from 'Common/ConfirmDialog';
import WarningDialog from 'Common/WarningDialog';
import ErrorDialog from 'Common/ErrorDialog';
import ImageryRequestInfo from './ImageryRequestInfo';
import CancelImageryRequestDialog from './CancelImageryRequestDialog';
import compose from './container';

const DataSetsPanel = compose(({ classes, epsgCode, projCS, geogCS, lasFiles, newLargeTif, largeTifAcknowledged, tifFiles, fileStatuses, warningsExist, warningAccepted, imageryRequest, delayInRasterTileLoad, delayInRasterTileLoadAcknowledged, loadingHighResTiles, GetRasterTilesAction, DeleteTifAction, DeleteLasAction, SetWarningAcceptedAction, GetEpsgProjectionsAction, SetDelayInRasterTileLoadAcknowledgedAction, SetLargeTifAcknowledgedAction, userReUpFiles }) => {
  const [dataSetsExpanded, setDataSetsExpanded] = useState(true);
  const [showConfirmDialogForTif, setShowConfirmDialogForTif] = useState(false);
  const [showConfirmDialogForLas, setShowConfirmDialogForLas] = useState(false);
  const [showCancelImageryRequest, setShowCancelImageryRequest] = useState(false);
  const [showDelayWarningDialog, setShowDelayWarningDialog] = useState(false);
  const [tifFileId, setTifFileId] = useState('');
  const [lasFileId, setLasFileId] = useState('');

  const fileStatusesValid = fileStatuses && Object.keys(fileStatuses).length > 0;

  const toggleDataSetsExpanded = () => {
    setDataSetsExpanded(!dataSetsExpanded);
  };

  const deleteTifClick = (_id: string) => {
    setShowConfirmDialogForTif(true);
    setTifFileId(_id);
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialogForTif(false);
    DeleteTifAction(tifFileId);
    e.stopPropagation();
  };

  const deleteLasClick = (_id: string) => {
    setShowConfirmDialogForLas(true);
    setLasFileId(_id);
  };

  const onOkDeleteLas = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialogForLas(false);
    DeleteLasAction(lasFileId);
    e.stopPropagation();
  };

  const onOkWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    SetWarningAcceptedAction();
    e.stopPropagation();
  };

  const onIgnoreDelayWarning = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowDelayWarningDialog(false);
    if (newLargeTif && !largeTifAcknowledged) {
      SetLargeTifAcknowledgedAction(true);
      GetRasterTilesAction();
    } else {
      SetDelayInRasterTileLoadAcknowledgedAction(true);
    }
    e.stopPropagation();
  };

  const onUploadDifferentFilesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowDelayWarningDialog(false);
    if (newLargeTif && !largeTifAcknowledged) {
      SetLargeTifAcknowledgedAction(true);
      GetRasterTilesAction();
    } else {
      SetDelayInRasterTileLoadAcknowledgedAction(true);
    }
    setUploadFilesFormVisible(true);
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialogForTif(false);
    setShowConfirmDialogForLas(false);
  };

  const [uploadFilesFormVisible, setUploadFilesFormVisible] = useState(false);
  const showUploadFilesForm = () => setUploadFilesFormVisible(true);

  const onLoad = async () => {
    await GetEpsgProjectionsAction(epsgCode);
  };

  useEffect(() => {
    onLoad();
  }, [epsgCode]);

  useEffect(() => {
    if ((loadingHighResTiles && (delayInRasterTileLoad && !delayInRasterTileLoadAcknowledged)) || (newLargeTif && !largeTifAcknowledged)) {
      setShowDelayWarningDialog(true);
    } else {
      setShowDelayWarningDialog(false);
    }
  }, [newLargeTif, largeTifAcknowledged, delayInRasterTileLoad, delayInRasterTileLoadAcknowledged, loadingHighResTiles]);

  return (
    <>
      <ListItem button className={classes.expansionPanelHeader}>
        <div className={classes.dataSetInfo}>
          <Typography variant="h5">DATA SETS</Typography>
          {(imageryRequest?.uploaded) && (
            <Tooltip
              placement="top-end"
              interactive
              title={(
                <>
                  <Typography className={classes.toolTipTitle}>CRS:</Typography>
                  <Typography className={classes.toolTipText}>{`${projCS} - ${geogCS}`}</Typography>
                  <div className={classes.captureDateInfo}>
                    <img className={classes.toolTipLogo} src={nearmapLogo} alt="nearmap" />
                    <div className={classes.captureDate}>
                      <Typography className={classes.toolTipTitle}>Capture Date:</Typography>
                      <Typography className={classes.toolTipText}>{moment.utc(imageryRequest?.captured).format('MMMM D, YYYY')}</Typography>
                    </div>
                  </div>
                </>
              )}
            >
              <HelpIcon className={classes.helpIcon} />
            </Tooltip>
          )}
        </div>
        <div>
          <IconButton aria-label="Upload" className={classes.button} onClick={showUploadFilesForm}>
            <SVG src={uploadKmlIcon} className={classes.uploadIcon} title="Upload files" />
          </IconButton>
          <IconButton aria-label={dataSetsExpanded ? 'Collapse' : 'Expand'} className={classes.button} onClick={toggleDataSetsExpanded}>
            {dataSetsExpanded
              ? <KeyboardArrowDownIcon fontSize="small" />
              : <KeyboardArrowRightIcon fontSize="small" />}
          </IconButton>
        </div>
      </ListItem>
      <Collapse in={dataSetsExpanded}>
        {/* Check if TIF file(s) & LAS file is available in the project and choose display accordingly */}
        <div className={classes.wrapper}>
          <div className={classes.dataSetRow}>
            {tifFiles.length > 0 && tifFiles.map((file) => (
              <div className={classes.filesList}>
                <div>
                  <div className={classes.fileName}>
                    <Typography noWrap variant="body2">{file.name}</Typography>
                  </div>
                  <Typography variant="body2">{` - [ EPSG: ${file.epsg === -1 ? 'invalid' : file.epsg} ]`}</Typography>
                </div>
                {(fileStatusesValid && fileStatuses[file.name] && (fileStatuses[file.name].status === 'ERROR' || fileStatuses[file.name].status === 'WARNING')) && (
                  <Tooltip title={fileStatuses[file.name].messages.join(', ')} placement="top">
                    <ErrorIcon className={classNames(classes.errorIcon, { [classes.warningIcon]: fileStatuses[file.name].status === 'WARNING' })} />
                  </Tooltip>
                )}
                {loadingHighResTiles && <ErrorIcon className={classes.loadingIcon} />}
                {(userReUpFiles || (fileStatusesValid && fileStatuses[file.name] && (fileStatuses[file.name].status === 'ERROR'))) && (
                  <IconButton className={classes.cancelButton} onClick={() => { deleteTifClick(file._id); }}>
                    <ClearIcon className={classes.cancelButtonIcon} />
                  </IconButton>
                )}
                <ConfirmDialog
                  showDialog={showConfirmDialogForTif}
                  onOk={onOk}
                  onCancel={onCancel}
                  contentText="Selected TIF file will be removed"
                />
              </div>
            ))}
            {lasFiles.length > 0 && lasFiles.map((file) => (
              <div className={classes.filesList}>
                <div>
                  <div className={classes.fileName}>
                    <Typography noWrap variant="body2">{file.name}</Typography>
                  </div>
                  <Typography variant="body2">{` - [ EPSG: ${file.epsg === -1 ? 'invalid' : file.epsg} ]`}</Typography>
                </div>
                {(fileStatusesValid && fileStatuses[file.name] && (fileStatuses[file.name].status === 'ERROR' || fileStatuses[file.name].status === 'WARNING')) && (
                  <Tooltip title={fileStatuses[file.name].messages} placement="top">
                    <ErrorIcon className={classNames(classes.errorIcon, { [classes.warningIcon]: fileStatuses[file.name].status === 'WARNING' })} />
                  </Tooltip>
                )}
                {(userReUpFiles || (fileStatusesValid && fileStatuses[file.name] && (fileStatuses[file.name].status === 'ERROR'))) && (
                  <IconButton className={classes.cancelButton} onClick={() => { deleteLasClick(file._id); }}>
                    <ClearIcon className={classes.cancelButtonIcon} />
                  </IconButton>
                )}
                <ConfirmDialog
                  showDialog={showConfirmDialogForLas}
                  onOk={onOkDeleteLas}
                  onCancel={onCancel}
                  contentText="Selected LAS file will be removed"
                />
              </div>
            ))}
            <WarningDialog
              showDialog={warningsExist && !warningAccepted}
              onOk={onOkWarning}
              onCancel={onOkWarning}
            />
          </div>
        </div>
      </Collapse>
      {!imageryRequest?.rejected && <ImageryRequestInfo setShowCancelDialog={setShowCancelImageryRequest} />}
      <CancelImageryRequestDialog showDialog={showCancelImageryRequest} setShowDialog={setShowCancelImageryRequest} />
      <ErrorDialog
        showDialog={showDelayWarningDialog}
        onAccept={onUploadDifferentFilesClick}
        onCancel={onIgnoreDelayWarning}
        titleText="Heads Up!"
        error
        acceptText="Upload files"
        cancelText="Ignore"
      >
        <Typography variant="body1">
          This file might take a while to fully render, if you’re in a hurry, we recommend
          {' '}
          <a
            className={classes.link}
            href="https://awkb.scrollhelp.site/airworksknowledgebase/how-to-tile-larger-image-files-into-smaller-files"
            onClick={() => { window?.pendo?.track('Tiling KB article clicked'); }}
            target="_blank"
            rel="noreferrer"
          >
            tiling your site
          </a>
          {' '}
          and uploading those smaller tiles instead.
        </Typography>
      </ErrorDialog>
      <UploadFilesDialog showDialog={uploadFilesFormVisible} setShowDialog={setUploadFilesFormVisible} />
    </>
  );
});

export default DataSetsPanel;
