// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core';

import { LoadKmls, SetBufferSize } from 'State/kml/thunk';
import { SetProjectId, GetProject, SetFileStatus, SetShowKmlToolbar } from 'State/project/thunk';
import { CreateOrder, GetOrders, SaveEstimate } from 'State/order/thunk';
import { ResetEditorState } from 'State/map/editor/thunk';
import { ResetKmlDrawAction } from 'State/map/draw/actions';
import { ResetProjectTifInfoAction, ResetProjectLasInfoAction } from 'State/map/common/actions';
import { SetShowBusinessDaysAction, ResetBufferStateAction, SetShowBufferInfoAction } from 'State/kml/actions';
import styles from './styles';

const mapStateToProps = (state: IState) => ({
  orders: state.order.list,
  uploadFiles: state.fileUpload.uploadFiles,
  opsTrainer: 'opsTrainer' in state.auth.resources,
  lineStringKmlUpload: state.kml.present.lineStringKmlUpload,
  showKmlToolBar: state.project.showKmlToolBar,
});

const mapDispatchToProps = {
  LoadKmlsAction: LoadKmls,
  SetProjectIdAction: SetProjectId,
  CreateOrderAction: CreateOrder,
  GetProjectAction: GetProject,
  SetFileStatusAction: SetFileStatus,
  ResetEditorStateAction: ResetEditorState,
  GetOrdersAction: GetOrders,
  SaveEstimateAction: SaveEstimate,
  SetBufferSizeAction: SetBufferSize,
  SetShowBusinessDaysAction,
  SetShowKmlToolbarAction: SetShowKmlToolbar,
  ResetBufferStateAction,
  ResetKmlDrawAction,
  SetShowBufferInfoAction,
  ResetProjectTifInfoAction,
  ResetProjectLasInfoAction,
};

interface IRouterProps {
  id: string;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  RouteComponentProps<IRouterProps>;

export default compose<PropsType, {}>(
  withStyles(styles),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
