// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Button, Fade, CircularProgress, Typography, Collapse } from '@material-ui/core';
import { Form, FormSpy } from 'react-final-form';
import { Helpers } from 'Common/forms';
import CreditCardControls from '../../CreditCardControls';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const CreateCreditCardForm = ({ onCancel, setCreateCardState, CreatePaymentInstrumentAction }: PropsType) => {
  const classes = useStyles();

  const onFormSubmit = (values: any) => {
    Helpers.submit(values, CreatePaymentInstrumentAction);
  };

  return (
    <div>
      <Form
        onSubmit={onFormSubmit}
        render={({ submitError, hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            {setCreateCardState && (
              <FormSpy onChange={(state) => {
                setCreateCardState(state);
              }}
              />
            )}
            <CreditCardControls />
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              className={classes.submitButton}
            >
              <Fade in={submitting}>
                <CircularProgress size={24} className={classes.buttonProgress} />
              </Fade>
              <Fade in={!submitting}>
                <span> Add new card</span>
              </Fade>
            </Button>
            <Button variant="outlined" onClick={onCancel}>Cancel</Button>
            <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
              {submitError && (
                <Typography variant="body1" className={classes.errorMessage}>
                  {submitError}
                </Typography>
              ) }
            </Collapse>
          </form>
        )}
      />
    </div>
  );
};

export default compose(CreateCreditCardForm);
