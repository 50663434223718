// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { GetInvoice } from 'State/invoice/thunk';
import { GetCreditCards, CreateSubscription, EditSubscription, CheckPromoCode, ClearPromoCodeInfo } from 'State/subscription/thunk';
import styles from './styles';

const mapStateToProps = (state: IState) => ({
  cadSubscription: state.subscription.cadSubscription,
  plans: state.subscription.plans,
  proratedChargesLoading: state.subscription.proratedChargesLoading,
  immediateCost: state.subscription.proratedCharges.immediateCost,
  updatedCost: state.subscription.proratedCharges.updatedCost,
  promoValid: state.subscription.promoCode.valid,
  discountPercent: state.subscription.promoCode.discountPercent,
  discountAmount: state.subscription.promoCode.discountAmount,
  totalCostAfterDiscount: state.subscription.promoCode.totalCostAfterDiscount,
  promoCode: state.subscription.promoCode.promotionCode,
  currentInvoice: state.invoice.currentInvoice,
});

const mapDispatchToProps = {
  CreateSubscriptionAction: CreateSubscription,
  GetCreditCardsAction: GetCreditCards,
  EditSubscriptionAction: EditSubscription,
  CheckPromoCodeAction: CheckPromoCode,
  ClearPromoCodeInfoAction: ClearPromoCodeInfo,
  GetInvoiceAction: GetInvoice,
};
interface IOwnProps {
  billingPeriod: string;
  cadSeats: number;
  cards: boolean;
  cadSubscriptionExists: boolean;
  cadSeatsChanged: number;
  page: number;
  setPage: (arg0: number) => void;
  daysRemaining: number;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
