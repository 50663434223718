// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { GetCreditCards } from 'State/subscription/thunk';
import styles from './styles';

const mapStateToProps = (state: IState) => ({
  creditCards: state.subscription.creditCards,
  totalCost: state.bundle.totalCost,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    GetCreditCardsAction: GetCreditCards,
  }, dispatch);

interface IOwnProps {
  setCards: (arg0: boolean) => void;
  orderFormVisible?: boolean;
  addOnDeliverableDollars?: number;
  addOnLayerDollars?: number;
  imageryExtraDollarCost?: number;
}

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
