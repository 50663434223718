// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import MUIDataTable from 'mui-datatables';
import { Typography, Avatar, Divider, IconButton, Snackbar, Button } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ConfirmDialog from 'Common/ConfirmDialog';
import Alert from '@material-ui/lab/Alert';
import HelpIcon from '@material-ui/icons/Help';
import LoadingSpinner from 'Common/LoadingSpinner';
import TeamTooltip from 'Common/Tooltip';
import UserForm from './UserForm';
import compose, { PropsType } from './container';
import useStyles from './styles';

const defaultActiveUser: IActiveUser = {
  firstName: '',
  lastName: '',
  fullName: '',
  email: '',
  _id: '',
  rolesArray: [],
  teams: [],
  jobTitle: '',
  phoneNumber: '',
};

export const UserManagement = ({ orgId, orgUsers, orgRoles, orders, cadSubscription, cadSubscriptionExists, DeleteUserAction, GetOrdersAction, GetOrgSubscriptionAction, SetOrgIdAction, ClearOrgAccountAction }: PropsType) => {
  const classes = useStyles();
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [openOrdersForUser, setOpenOrdersForUser] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [deleteUser, setDeleteUser] = useState('');
  const [showUserForm, setShowUserForm] = useState(false);
  const [userFormMode, setUserFormMode] = useState('add');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [showError, setShowError] = useState(false);
  const [activeUser, setActiveUser] = useState(defaultActiveUser);

  useEffect(() => {
    ClearOrgAccountAction();
    if (_ADMIN_) {
      SetOrgIdAction(orgId);
    }
  }, []);

  useEffect(() => {
    const loadData = async () => {
      setShowLoader(true);
      await GetOrdersAction(null, orgId);
      setShowLoader(false);
    };

    loadData();
  }, []);

  const handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  const handleErrorSnackbarClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowError(false);
  };

  const renderColumnHeader = (columnMeta: any) => (
    <td key={columnMeta.name} className={classes.columnHeader}>
      <Typography variant="h4">{columnMeta.label}</Typography>
    </td>
  );

  const deleteUserClick = (userId: string, email: string) => {
    const openOrders = orders.some((order: IOrder) => (order.owner === userId && (order.orderStatus > 0 && order.orderStatus < 7)));
    setOpenOrdersForUser(openOrders);
    setShowConfirmDialog(true);
    setDeleteUser(email);
  };

  const onOk = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    const result = await DeleteUserAction(deleteUser, openOrdersForUser);
    if (result.success) {
      GetOrgSubscriptionAction(_ADMIN_ ? orgId : undefined);
      setShowSnackbar(true);
    } else {
      setShowError(true);
    }
    e.stopPropagation();
  };

  const AddOrgUserDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setActiveUser(defaultActiveUser);
    setUserFormMode('add');
    setShowUserForm(true);
    e.stopPropagation();
  };

  const EditOrgUserDialog = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, _id: string, rolesArray: [string]) => {
    const orgUser = orgUsers.find((user) => user._id === _id);
    setActiveUser({ ...orgUser, rolesArray });
    setUserFormMode('edit');
    setShowUserForm(!showUserForm);
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  const getAvatarText = (value: string) => {
    const split = value && value.split(' ');
    const firstName = split && split[0];
    const lastName = split && split[1];
    const char1 = (firstName && firstName[0]) || '';
    const char2 = (lastName && lastName[0]) || '';

    return `${char1}${char2}`;
  };

  const getAccesstring = (roles: any) => {
    let assignedRoles: string = '';
    if (orgRoles && roles) {
      roles.forEach((role: any) => {
        let roleString = '';
        if (orgRoles[role] === 'processing') {
          roleString = 'AUTOMATE';
        }
        if (orgRoles[role] === 'cad') {
          roleString = 'CAD';
        }
        if (orgRoles[role] === 'admin') {
          roleString = 'ADMIN';
        }
        if (roleString !== '') {
          assignedRoles += `${roleString} | `;
        }
      });
      return assignedRoles.slice(0, -3);
    }
    return 'No role assigned';
  };

  const gotRolesAsStrings = (roles: [string]) => {
    const assignedRoles: any = [];
    if (orgRoles && roles) {
      roles.forEach((role: any) => {
        assignedRoles.push(orgRoles[role]);
      });
    }
    return assignedRoles;
  };

  const columns = [
    {
      name: 'fullName',
      label: '  ',
      options: {
        customHeadRender: renderColumnHeader,
        customBodyRender: (value: string) => (
          <Avatar className={classes.avatar}>{getAvatarText(value)}</Avatar>
        ),
      },
    },
    {
      name: 'firstName',
      label: 'FIRST NAME',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'lastName',
      label: 'LAST NAME',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'email',
      label: 'EMAIL',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'jobTitle',
      label: 'JOB TITLE',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'phoneNumber',
      label: 'PHONE NUMBER',
      options: { customHeadRender: renderColumnHeader },
    },
    {
      name: 'role',
      label: 'ACCESS',
      options: {
        customHeadRender: renderColumnHeader,
        customBodyRender: (value: any) => (
          <div className={classes.nameColumn}>
            {orgRoles && getAccesstring(value)}
          </div>
        ),
      },
    },
    {
      name: 'teams',
      label: 'TEAM',
      options: {
        customHeadRender: renderColumnHeader,
        customBodyRender: (teams: boolean, tableMeta: { rowData : any }) => (
          // eslint-disable-next-line no-nested-ternary
          tableMeta.rowData[7].length > 1 ? (
            <div className={classes.info}>
              <TeamTooltip
                placement="top"
                interactive
                title={(
                  tableMeta.rowData[7].length > 1 && (
                    <ul className={classes.unOrderList}>
                      {tableMeta.rowData[7].map((team: any) => (
                        <li>{team && team.name}</li>
                      ))}
                    </ul>
                  )
                )}
              >
                <HelpIcon className={classes.icon} />
              </TeamTooltip>
              <Typography>{`${tableMeta.rowData[7].length} Teams`}</Typography>
            </div>
          ) : tableMeta.rowData[7].length === 1 ? (
            <div className={classes.info}>
              <Typography className={classes.onlyTeam}>{`${tableMeta.rowData[7][0] && tableMeta.rowData[7][0].name}`}</Typography>
            </div>
          ) : '-'
        ),
      },
    },
    {
      name: 'admin',
      empty: true,
      options: {
        customHeadRender: () => null,
        customBodyRender: (admin: boolean, tableMeta: { rowData : any }) => (
          <div className={classes.iconButtons}>
            <IconButton
              onClick={(e) => EditOrgUserDialog(e, tableMeta.rowData[9], gotRolesAsStrings(tableMeta.rowData[6]))}
            >
              <EditIcon fontSize="small" />
            </IconButton>
            <IconButton
              onClick={() => deleteUserClick(tableMeta.rowData[9], tableMeta.rowData[3])}
            >
              <DeleteIcon fontSize="small" />
            </IconButton>
          </div>
        ),
      },
    },
    {
      name: '_id',
      label: '_id',
      options: { display: false },
    },
  ];

  const options = {
    elevation: 0,
    responsive: 'stacked',
    serverSide: true,
    selectableRows: 'none',
    print: false,
    download: false,
    viewColumns: false,
    sort: false,
    search: false,
    filter: false,
    pagination: false,
  };
  return (
    <LoadingSpinner loading={showLoader}>
      <div className={classes.pageWrapper}>
        <Snackbar className={classes.snackBar} open={showSnackbar} autoHideDuration={10000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="success" className={classes.alert} color="info">User Deleted</Alert>
        </Snackbar>
        <Snackbar className={classes.snackBar} open={showError} autoHideDuration={10000} onClose={handleErrorSnackbarClose} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert severity="error" className={classes.error} color="error">Unable to Delete User</Alert>
        </Snackbar>
        <div className={classes.planOptions}>
          <div>
            <Typography className={classes.headerText} variant="h2">Users Management</Typography>
          </div>
          <Button
            color="primary"
            variant="contained"
            className={classes.addNewUser}
            onClick={AddOrgUserDialog}
          >
            <Typography variant="h4" className={classes.addUserButton}>ADD NEW USER</Typography>
          </Button>
        </div>
        <div className={classes.headerRow}>
          {cadSubscriptionExists && (
            <Typography variant="h5" className={classes.planDetails}>
              {cadSubscription.cadSeatsTaken}
              {' '}
              of
              {' '}
              {cadSubscription.cadSeatsAvailable}
              {' '}
              seats assigned
            </Typography>
          )}
        </div>
        <>
          <UserForm userFormMode={userFormMode} showUserForm={showUserForm} setShowUserForm={setShowUserForm} activeUser={activeUser} setActiveUser={setActiveUser} />
          <Divider className={classes.divider} />
          <MUIDataTable
            data={orgUsers}
            columns={columns}
            options={options}
            className={classes.table}
          />
          <ConfirmDialog
            showDialog={showConfirmDialog}
            onOk={onOk}
            onCancel={onCancel}
            contentText={openOrdersForUser ? 'There are open order(s) created by this user. By deleting this user, the open order(s) will be assigned to the Organization Admin. Are you sure you want to delete this user?'
              : "Are you sure you want to delete this user? This action can't be undone."}
          />
        </>
      </div>
    </LoadingSpinner>
  );
};

export default compose(UserManagement);
