// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  autosuggestInputWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
  },
  autosuggestInput: {
    flex: 1,
  },
  autosuggestProgress: {
    width: 20,
    height: 20,
    color: theme.palette.lightGrey,
  },
  autosuggestClearIcon: {
    position: 'absolute',
    right: -2,
    cursor: 'pointer',
    color: theme.palette.lightGrey,
  },
  searchIcon: {
    color: theme.palette.lightGrey,
  },
});

export default styles;
