// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  measurements: {
    backgroundColor: '#fff',
    borderRight: '2px solid #E3E7EC',
    display: 'flex',
    flexDirection: 'row',
  },
  type: {
    display: 'flex',
    flexDirection: 'column',
  },
  typeName: {
    paddingLeft: '10px',
    paddingTop: '10px',
  },
  option: {
    padding: '8px',
    boxShadow: 'none',
    borderRadius: '0px',
  },
  verticalLine: {
    borderLeft: '2px solid #E3E7EC',
  },
  icon: {
    height: '30px',
    width: '30px',
  },
  greyed: {
    opacity: 0.5,
  },
  betaBanner: {
    marginTop: '10px',
    marginLeft: '10px',
    background: 'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    fontSize: '11px',
    padding: '1px 10px',
    color: '#ffffff',
    height: '18px',
  },
}));

export default useStyles;
