// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { connect } from 'react-redux';
import compose from 'recompose/compose';
import { withStyles, WithStyles } from '@material-ui/core';
import { getOrders } from 'State/order/selectors';
import styles from './styles';

const mapStateToProps = (state: IState) => ({
  order: getOrders(state).activeOrder,
  availableCreditAcreage: state.account.org.availableCreditAcreage,
  additionalDeliverables: state.bundle.additionalDeliverables,
  acreAdjustmentFactor: state.bundle?.selectedBundle?.acreAdjustmentFactor,
  bundleCost: state.bundle.totalCost,
  costPerAcre: state.account.org.costPerAcre,
  imageryRequest: state.project.project.imageryRequest,
});
interface IOwnProps {
    showDialog: boolean;
    onOk: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onCancel?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onProceed?:(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    titleText: string;
    addOnLayerDollars: number;
    addOnDeliverableDollars: number;
}

type PropsType =
    ReturnType<typeof mapStateToProps> &
    WithStyles<typeof styles> &
    IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, null),
);
