// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAsyncAction } from 'typesafe-actions';

export const GetGlobalStatusAsync = createAsyncAction(
  'GET_GLOBAL_STATUS_START',
  'GET_GLOBAL_STATUS_SUCCESS',
  'GET_GLOBAL_STATUS_FAILED',
)<null, IGlobalStatus, string>();

export const GetOpsCreditCapacityAsync = createAsyncAction(
  'GET_OPS_CREDIT_CAPACITY_START',
  'GET_OPS_CREDIT_CAPACITY_SUCCESS',
  'GET_OPS_CREDIT_CAPACITY_FAILED',
)<null, number, string>();

export const UpdateGlobalStatusAsync = createAsyncAction(
  'UPDATE_GLOBAL_STATUS_START',
  'UPDATE_GLOBAL_STATUS_SUCCESS',
  'UPDATE_GLOBAL_STATUS_FAILED',
)<null, IGlobalStatus, string>();
