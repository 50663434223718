// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Paper, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const FileErrors = ({ tileLoading, tile3DError }: PropsType) => {
  const classes = useStyles();
  const [showTileError, setShowTileError] = useState(true);

  return (
    <>
      {tileLoading && <div className={classes.loadingModal}><p>Loading...</p></div>}
      <div className={classes.errorStacks}>
        {tile3DError && showTileError && (
          <Paper elevation={1} square className={classes.errorPaper}>
            <div className={classes.errorControls}>
              <ErrorIcon className={classes.errorIcon} />
              <Typography variant="h3">
                We are not able to render your point cloud. Please attempt uploading another file or contact
                {' '}
                <a href="mailto:support@airworks.io" className={classes.link}>support@airworks.io</a>
                {' '}
                for us to troubleshoot further.
              </Typography>
            </div>
            <CloseIcon className={classes.closeIcon} onClick={() => setShowTileError(false)} />
          </Paper>
        )}
      </div>
    </>
  );
};

export default compose(FileErrors);
