// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) =>
  createStyles({
    details: {
      marginBottom: theme.spacing(4),
      color: '#FFFFFF',
      marginTop: theme.spacing(4),
    },
    header: {
      marginTop: theme.spacing(4),
    },
    buttonWrapper: {
      marginTop: theme.spacing(4),
      position: 'relative',
      display: 'grid',
      gridTemplateColumns: 'auto auto',
    },
    forgotText: {
      color: '#ffffff',
      textTransform: 'uppercase',
    },
  });

export default styles;
