// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { MenuItem, Button, Typography, Popper, Paper, MenuList, ClickAwayListener, Fade } from '@material-ui/core';
import { ACCOUNT_GENERAL_ROUTE, ACCOUNT_BILLING_ROUTE } from 'Utils/routes';
import CreateSubscriptionDialog from 'Common/CreateSubscriptionDialog';
import { useOktaAuth } from '@okta/okta-react';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const PopupMenu = ({ history, email, cadSubscription, billing, accountMatch, LogoutAction }: PropsType) => {
  const classes = useStyles();
  const { oktaAuth } = _ADMIN_ && useOktaAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuClose = () => setAnchorEl(null);
  const [showSubscriptionDialog, setShowSubscriptionDialog] = useState(false);
  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => setAnchorEl(event.currentTarget);
  const isMenuOpen = Boolean(anchorEl);
  const cadSubscriptionExists = cadSubscription && Object.keys(cadSubscription).length > 0;

  const accountClick = () => {
    handleMenuClose();
    history.push(ACCOUNT_GENERAL_ROUTE);
  };

  const billingClick = () => {
    handleMenuClose();
    history.push(ACCOUNT_BILLING_ROUTE);
    setShowSubscriptionDialog(true);
  };

  const logoutClick = async () => {
    handleMenuClose();
    if (_ADMIN_) {
      await oktaAuth.signOut();
    }
    LogoutAction();
  };

  const purchaseLink = ((billing && cadSubscriptionExists) ? <MenuItem className={classes.cad} onClick={billingClick}>Upgrade Plan</MenuItem> :
    <MenuItem className={classes.cad} onClick={billingClick}>Buy Airworks CAD</MenuItem>);

  return (
    <>
      <Button
        aria-owns={isMenuOpen ? 'material-appbar' : undefined}
        onClick={handleMenuOpen}
        color="inherit"
      >
        <Typography variant="body1" className={classes.toolbarText}>{email}</Typography>
      </Button>
      <Popper style={{ zIndex: 9999 }} open={isMenuOpen} anchorEl={anchorEl} transition>
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleMenuClose}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper>
                <MenuList className={classes.menuList}>
                  { !_ADMIN_ ? (<MenuItem className={classes.fontWeight} onClick={accountClick} selected={Boolean(accountMatch)}>Account</MenuItem>) : null}
                  <MenuItem className={classes.fontWeight} onClick={logoutClick}>Log Out</MenuItem>
                  { !_ADMIN_ ? purchaseLink : null}
                </MenuList>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
      <CreateSubscriptionDialog {...{ cadSubscriptionExists, showSubscriptionDialog, setShowSubscriptionDialog }} />
    </>
  );
};

export default compose(PopupMenu);
