// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Typography, Table, TableRow, TableBody, TableCell } from '@material-ui/core';
import { InView } from 'react-intersection-observer';
import classnames from 'classnames';
import compose, { PropsType } from './container';
import useStyles from './styles';
import LayerItem from './LayerItem';

/**
 * Component renders layers table
 * @param {object} props.template template object
 * @param {string} props.templateId Props - template id
 * @param {function} props.SetTemplateIdAction Function to set the TemplateId property on cadTemplate state
 * @param {function} props.setLayersColor Function to update the color value, user has selected
 * @param {function} props.setLayersStyle Function to update the line style value, user has selected
 * @param {function} props.setLayersWeight Function to update the line Weight value, user has selected
 * @param {function} props.setLayersMapping Function to update the mapping value, user has selected
 */
export const Layers = ({ template, templateId, SetTemplateIdAction, setLayersStyle, setLayersWeight, setLayersColor, setLayersMapping }: PropsType) => {
  const classes = useStyles();

  const [loadAll, setLoadAll] = useState(false);
  const [page, setPage] = useState(0);
  const [layers, setLayers] = useState([]);

  useEffect(() => {
    SetTemplateIdAction(templateId);
    setLoadAll(false);
    setPage(0);
  }, [templateId]);

  useEffect(() => {
    if (template.layers.length / 20 <= page && !loadAll) {
      setLoadAll(true);
    } else {
      setLayers(template.layers.slice(0, 20 * (page + 1)));
    }
  }, [page, template.layers]);

  return (
    <div className={classes.pageWrapper}>
      <Table className={classes.table} aria-label="simple table">
        <TableBody>
          <TableRow className={classes.headerRow}>
            <TableCell
              className={classes.tableCellHeader}
              classes={{
                root: classes.tableCellRoot,
              }}
            >
              <Typography variant="h4">Layer Name</Typography>
            </TableCell>
            <TableCell
              className={classes.tableCellHeadeStyle}
              classes={{
                root: classes.tableCellRoot,
              }}
              align="left"
            >
              <Typography variant="h4">Line Style</Typography>
            </TableCell>
            <TableCell
              className={classes.tableCellHeader}
              classes={{
                root: classes.tableCellRoot,
              }}
              align="left"
            >
              <Typography variant="h4">Line Weight</Typography>
            </TableCell>
            <TableCell
              className={classnames(classes.tableCellHeader, classes.colorCellHeader)}
              classes={{
                root: classes.tableCellRoot,
              }}
              align="left"
            >
              <Typography variant="h4">Line Color</Typography>
            </TableCell>
            <TableCell
              className={classes.tableCellHeader}
              classes={{
                root: classes.tableCellRoot,
              }}
              align="left"
            >
              <Typography variant="h4">Airworks Layers</Typography>
            </TableCell>
          </TableRow>
          {layers.map((layer) => (
            <LayerItem
              key={layer.name}
              layer={layer}
              templateId={templateId}
              setLayersStyle={setLayersStyle}
              setLayersWeight={setLayersWeight}
              setLayersColor={setLayersColor}
              setLayersMapping={setLayersMapping}
            />
          ))}
        </TableBody>
      </Table>
      <div className={classes.loading}>
        {loadAll ? (
          <Typography variant="h3">End of List</Typography>
        ) : (
          <>
            <InView onChange={(inView) => {
              if (inView) {
                setPage(page + 1);
              }
            }}
            />
            <Typography variant="h3">Loading more...</Typography>
          </>
        )}
      </div>
    </div>
  );
};
export default compose(Layers);
