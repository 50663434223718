// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { SetTemplateId, DeleteTemplate, EditTemplate, DuplicateTemplate } from 'State/cadTemplate/thunk';
import { DefaultTemplate } from 'State/account/thunk';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps<{ templateId: string }>) => {
  const { defaultTemplate } = state.account.org;
  const { templateId } = ownProps.match.params;
  const template = state.cadTemplate.list?.find((item) => item._id === templateId);

  return {
    templateId,
    template,
    defaultTemplate,
    title: template?.name,
    isDefaultTemplate: defaultTemplate === templateId,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    EditTemplateAction: EditTemplate,
    DeleteTemplateAction: DeleteTemplate,
    DuplicateTemplateAction: DuplicateTemplate,
    DefaultTemplateAction: DefaultTemplate,
    SetTemplateIdAction: SetTemplateId,
  }, dispatch);

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  RouteComponentProps<{ templateId: string }>;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
