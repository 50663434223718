// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { WideLightTooltip } from 'Common/Tooltip';
import HelpIcon from '@material-ui/icons/Help';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const CadDrawingsCheckbox = ({ order, checked, orderDisplayed, index, toggleChecked, ToggleCadDrawingsAction, fileVersions, ChangeVersionAction }: PropsType) => {
  const classes = useStyles();

  return (
    <>
      <FormControlLabel
        label={(
          <div className={classes.title}>
            <Typography variant="h4" className={classes.sidebarHeader}>{`CAD ${index}`}</Typography>
            <WideLightTooltip
              placement="top"
              interactive
              title="All features extracted by AirWorks are listed here. We are working to ensure your downloaded .dxf file includes all layers listed on your client template. We’ll keep you posted!"
            >
              <HelpIcon className={classes.icon} />
            </WideLightTooltip>
          </div>
        )}
        control={(
          <Checkbox
            color="primary"
            onChange={() => {
              toggleChecked();
              ToggleCadDrawingsAction(order._id, fileVersions[order._id]);
              ChangeVersionAction();
            }}
            checked={orderDisplayed && checked}
            classes={{
              root: classes.checkbox,
            }}
            disabled={!orderDisplayed}
          />
        )}
      />
    </>
  );
};

export default compose(CadDrawingsCheckbox);
