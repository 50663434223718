// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  cardWrapper: {
    position: 'relative',
    marginBottom: '3px',
  },
  projectItem: {
    transition: theme.transitions.create(['box-shadow', 'margin-top']),
    '&:hover': {
      boxShadow: theme.shadows[15],
      marginTop: -3,
    },
    '&:hover $label': {
      opacity: 1,
      marginTop: 0,
    },
    '&:hover $projectItemIcons': {
      opacity: 1,
      marginTop: 0,
    },
    '&:hover $backdrop': {
      opacity: 0.7,
    },
  },
  routerLink: {
    color: 'inherit',
  },
  media: {
    height: 200,
    position: 'relative',
  },
  progress: {
    zIndex: 3001,
    position: 'absolute',
    width: '100%',
  },
  loadingBackdrop: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'white',
    zIndex: 3000,
    opacity: 0.5,
  },
  backdrop: {
    transition: theme.transitions.create('opacity'),
    backgroundColor: 'black',
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  standardBackdrop: {
    opacity: 0.7,
  },
  successBackdrop: {
    opacity: 0.9,
    background: 'linear-gradient(134.72deg, #000000 0%, #05071A 49.93%, #18216D 100%)',
  },
  // failedBackdrop: {
  //   opacity: 0.7,
  //   backgroundColor: 'red',
  // },
  bottomBackdrop: {
    transition: theme.transitions.create('opacity'),
    opacity: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  bottomSuccessBackdrop: {
    opacity: 0.2,
    backgroundColor: '#797979',
  },
  // bottomFailedBackdrop: {
  //   opacity: 0.2,
  //   backgroundColor: 'red',
  // },
  label: {
    transition: theme.transitions.create(['opacity', 'margin-top', 'color', 'background-color']),
    marginTop: -10,
    opacity: 0,
    color: 'white',
    padding: theme.spacing(2),
    border: '1px solid',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  cardActions: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridTemplateAreas: `
      "name icon"
      "date icon"
    `,
    padding: theme.spacing(2),
    position: 'relative',
    height: '80px',
  },
  nameText: {
    gridArea: 'name',
    color: theme.palette.common.black,
    paddingTop: '.25rem',
    display: 'flex',
    alignItems: 'center',
    marginLeft: 0,
  },
  notificationIcon: {
    width: theme.spacing(2),
    marginLeft: theme.spacing(0.5),
  },
  dateText: {
    gridArea: 'date',
    color: '#1C364B',
    paddingBottom: '.25rem',
    gridRow: 2,
    margin: '2px 0',
  },
  details: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  detailsWrapper: {
    display: 'flex',
    alignItems: 'baseline',
    fontSize: '10px',
  },
  errorIcon: {
    gridArea: 'icon',
    fill: 'red',
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  projectItemIcons: {
    color: 'white',
    opacity: 0,
    transition: theme.transitions.create(['opacity', 'margin-top', 'color', 'background-color']),
    display: 'flex',
    justifyContent: 'flex-end',
  },
  shareIcon: {
    color: 'white',
  },
  deleteIcon: {
    color: 'white',
  },
  activeIcon: {
    color: theme.palette.secondary.main,
  },
  avatar: {
    position: 'absolute',
    top: '5%',
    left: '3%',
    background: '#ffffff',
    color: '#000000',
  },
  projectItemButton: {
    color: 'white',
    padding: theme.spacing(2),
    borderRadius: 0,
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    marginTop: theme.spacing(),
    '&:hover': {
      backgroundColor: 'white',
      color: 'black',
    },
  },
  cardText: {
    color: 'white',
  },
  cardTextWrapper: {
    textAlign: 'center',
    gridColumn: '1/3',
  },
  cardControls: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'grid',
    gridTemplateRows: '1fr 1fr',
    gridTemplateColumns: '1fr 1fr',
  },
  progressPanel: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
  },
  progressStepperRoot: {
    height: '100%',
    width: '100%',
  },
  contactSupportButton: {
    gridColumn: '1/3',
  },
  progressHeader: {
    color: 'white',
    textAlign: 'center',
    marginBottom: theme.spacing(3),
  },
  progressInfo: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(),
  },
  progressText: {
    color: 'white',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'clip',
  },
  progressBarColorPrimary: {
    backgroundColor: 'white',
  },
  progressBarColorSecondary: {
    backgroundColor: theme.palette.lightGrey,
  },
  root: {
    backgroundColor: '#797979',
    zIndex: 1,
    color: '#fff',
    width: 20,
    height: 20,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepActive: {
    backgroundImage:
      'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  stepCompleted: {
    backgroundImage:
      'linear-gradient(90deg, #9038F5 0%, #3C34B7 33.29%, #2075FE 74.06%, #01A1DD 100%)',
  },
  stepper: {
    backgroundColor: '#fff0',
  },
  labelText: {
    color: '#fff',
    textAlign: 'center',
  },
}));

export default useStyles;
