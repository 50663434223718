// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import jwtDecode from 'jwt-decode';
import moment from 'moment-timezone';

import { postJson, getJson, patchJson, getFile } from 'Utils/http';
import history from 'Utils/history';
import { ROOT_ROUTE, PROJECTS_ROUTE, ADMIN_ROUTE, EXPIRED_TOKEN_ROUTE, FORGOT_PASSWORD_ROUTE, SIGN_UP_ROUTE } from 'Utils/routes';
import { API_URL, GetTermsAndConditionsUrl } from 'Config';
import {
  getUser,
  getToken,
  getLoginFailedAttempts,
  getHTML,
  setUser,
  setLoginFailedAttempts,
  setToken,
  setHTML,
  clearLocalStorage,
  getPlanDetails,
  setPlanDetails,
  removeLoginFailedAttempts,
  // setResources,
} from 'Utils/localStorageService';
import { clearSessionStorage } from 'Utils/sessionStorageService';
import { InitAsync, LoginAsync, LogoutAction, OktaAdminLoginAsync, OnAcceptAsync, SendForgotPasswordLink } from './actions';

moment.tz.setDefault('America/New_York');

export const InitAuth = () => (dispatch: Dispatch) => {
  const currentLocation = history.location.pathname;
  const user = !_ADMIN_ &&
  currentLocation !== FORGOT_PASSWORD_ROUTE &&
  currentLocation !== SIGN_UP_ROUTE &&
  currentLocation.indexOf('/verifyToken') !== 0 &&
  currentLocation !== EXPIRED_TOKEN_ROUTE &&
  ReadFromStorage()(dispatch);
  if (!user) return;

  if (currentLocation === ROOT_ROUTE) {
    history.push(_ADMIN_ ? ADMIN_ROUTE : PROJECTS_ROUTE);
  }
};

export const ReadFromStorage = () => (dispatch: Dispatch) => {
  const failedLoginAttempts = +getLoginFailedAttempts();
  try {
    const userData = getUser();
    const user = JSON.parse(userData);
    const html = getHTML();
    const token = getToken();
    const tokenExpires = jwtDecode<{ exp: number }>(token).exp;
    const { resources } = jwtDecode<{ resources: any}>(token);
    const planDetails = getPlanDetails();

    dispatch(InitAsync.success({ user, token, tokenExpires, html, resources, planDetails, failedLoginAttempts }));

    return user;
  } catch {
    clearLocalStorage();
    history.push('/');
    dispatch(InitAsync.failure(failedLoginAttempts));
    return false;
  }
};

export const Login = (data: { email: string; password: string }) => async (
  dispatch: Dispatch,
) => {
  const url = `${API_URL}/users/login`;

  dispatch(LoginAsync.request());

  const result = await postJson<{ user: IUser; token: string, html: string, planDetails: any }>(url, data);
  if (!result.success) {
    // convert localstorage value from string to a number
    const numAttempts = +getLoginFailedAttempts();
    setLoginFailedAttempts(JSON.stringify(numAttempts + 1));
    dispatch(LoginAsync.failure(numAttempts + 1));
    return result;
  }

  let user; let token;

  if (result?.data) {
    ({ user, token } = result.data);
  }

  let difference;
  if (user?.lastPasswordUpdate) {
    const today = moment(new Date());
    const previousReset = moment(user?.lastPasswordUpdate);
    difference = today.diff(previousReset, 'days');
  }

  if (difference > 364 || difference === undefined) {
    const forceResetUrl = `${API_URL}/users/forceResetPassword`;

    const resetResult = await postJson<{encodedURLToken: string}>(forceResetUrl, { email: user.email });

    if (resetResult.success) {
      history.push(`/verifyToken/${resetResult.data.encodedURLToken}`);
    } else {
      dispatch(LoginAsync.failure(1));
    }
    return null;
  }

  const decodedToken = jwtDecode<{ exp: number, resources: any }>(token);

  setUser(JSON.stringify(user));
  setToken(token);
  setPlanDetails(JSON.stringify(result.data.planDetails));
  removeLoginFailedAttempts();

  dispatch(
    LoginAsync.success({
      user,
      token,
      tokenExpires: decodedToken.exp,
      html: result.data.html,
      resources: decodedToken.resources,
    }),
  );

  // adding if condition here to not make a call to s3 if the html is 'undefined'
  // it means user have already accepted the latest T&C
  if (result.data.html !== undefined) {
    const fileBlobResult = await getFile(GetTermsAndConditionsUrl(result.data.html));
    setHTML(fileBlobResult.data);
  }

  history.push('/projects');
  return result;
};

export const OktaAdminLogin = (tokens: any) => async (
  dispatch: Dispatch,
) => {
  const decodedToken = jwtDecode<{ exp: number, sub: string }>(tokens.accessToken);

  dispatch(OktaAdminLoginAsync.request());
  const user = { email: decodedToken.sub, roles: ['admin'] };

  setUser(JSON.stringify(user));
  setToken(tokens.accessToken);

  dispatch(
    OktaAdminLoginAsync.success({
      user,
      token: tokens.accessToken,
      tokenExpires: decodedToken.exp,
      resources: ['editorFunctions'],
    }),
  );
};

export const ForgotPassword = (data: { email: string, captchaToken: string }) => async (dispatch: Dispatch) => {
  const url = `${API_URL}/forgotPassword`;
  const result = await postJson<{ message: string }>(url, data);
  dispatch(SendForgotPasswordLink(result.success));
  return result;
};

// eslint-disable-next-line consistent-return
export const VerifyToken = (token: string) => async () => {
  const url = `${API_URL}/verifyToken/${token}`;
  const result = await getJson<{ email: string, jwt: string }>(url, token);
  if (result.success) {
    setToken(result.data.jwt);
    return result.data.jwt;
  }
  history.push(EXPIRED_TOKEN_ROUTE);
};

// eslint-disable-next-line consistent-return
export const ResetPassword = (payload: any) => async () => {
  const url = `${API_URL}/passwordReset`;
  const token = getToken();
  const data = { token, password: payload.password };
  const result = await postJson<{ message: string }>(url, data);
  if (result.success) {
    history.push(ROOT_ROUTE);
    clearLocalStorage();
  } else {
    return 'something went wrong';
  }
  // return {};
};

export const updateUserTnCDate = () =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const { user } = getState().auth;
    const url = `${API_URL}/updateUserTnCDate/${user._id}`;
    const { token } = getState().auth;

    dispatch(OnAcceptAsync.request());
    const result = await patchJson<IUserResponse>(url, user, token);
    if (result.success) {
      dispatch(OnAcceptAsync.success(result.data));
      setHTML(null);
    } else {
      dispatch(OnAcceptAsync.failure(result.message));
    }

    return result;
  };

export const Logout = () => (dispatch: Dispatch) => {
  clearLocalStorage();
  clearSessionStorage();
  dispatch(LogoutAction());
  history.push('/');
  window.location.reload();
};
