// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { FeatureCollection } from 'geojson';
import React from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import compose from './container';

const MapLayers = compose(({ projectId, kmls }) => {
  // Generate KML layer with kml file sources
  const KMLLayer = (params: {fileName: string, data: FeatureCollection, visible: boolean}) => {
    const sourceId = `${params.fileName}-kml_source`;
    const layerId = `${params.fileName}-kml_layer`;
    return (
      <>
        <Source
          id={sourceId}
          geoJsonSource={{
            type: 'geojson',
            data: params.data,
          }}
        />
        <Layer
          before="kmlStub"
          type="fill"
          id={layerId}
          sourceId={sourceId}
          layout={{
            visibility: params.visible ? 'visible' : 'none',
          }}
          paint={{ 'fill-color': 'rgba(1, 161, 221, 0.2)' }}
        />
      </>
    );
  };
  return (
    <>
      {/* Layers order is important so we need to always add tiles layers before draw layers */}
      <Layer id="kmlStub" />
      {kmls.map((kml) => <KMLLayer fileName={kml.name} data={kml.geojson} visible={kml.visible}/>)}
    </>
  );
});

export default MapLayers;
