// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';
import { SetTemplateId } from 'State/cadTemplate/thunk';

import styles from './styles';

const mapStateToProps = (state: IState) => {
  const { templateId } = state.cadTemplate;
  return ({
    currentTemplate: state.cadTemplate.list && state.cadTemplate.list.find((t) => t._id === templateId),
  });
};

const mapDispatchToProps = {
  SetTemplateIdAction: SetTemplateId,
};

interface IOwnProps {
    setCorrectMatching: (data: {}) => void;
}

type PropsType =
    ReturnType<typeof mapStateToProps> &
    typeof mapDispatchToProps &
    WithStyles<typeof styles> &
    IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
