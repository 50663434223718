// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { InitDraw, Draw, SetSelection, SelectPolygon, Undo } from 'State/map/draw/thunk';
import { SetFirstTimeChangeEstimateAction, UpdateKmlFeatureAction } from 'State/map/draw/actions';
import { getOrders } from 'State/order/selectors';
import { getFeatureCollection } from 'State/kml/selectors';
import { SetShowBusinessDaysAction } from 'State/kml/actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import equal from '@turf/boolean-equal';

const mapStateToProps = (state: IState) => {
  const orders = getOrders(state);
  const { createKml, currentKml } = state.map.draw.present;
  const currentProject = state.project.project;
  const imageryRequested = (orders?.activeOrder?.isEstimate && !!(currentProject?.imageryRequest?.requested)) || !!(currentProject?.imageryRequest?.uploaded) || !!(currentProject?.imageryRequest?.notificationSent);
  return {
    collection: createKml ? currentKml : getFeatureCollection(state),
    createKml,
    modeName: state.map.draw.present.mode?.name,
    modeFeatureId: state.map.draw.present.mode?.params?.featureId,
    kmlFile: orders.activeOrder && orders.activeOrder.boundaryFile,
    activeOrder: orders.activeOrder,
    imageryRequested,
  };
};

const mapDispatchToProps = {
  InitDrawAction: InitDraw,
  DrawAction: Draw,
  UndoAction: Undo,
  SetSelectionAction: SetSelection,
  SelectPolygonAction: SelectPolygon,
  SetFirstTimeChangeEstimateAction,
  SetShowBusinessDaysAction,
  UpdateKmlFeatureAction,
};

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

const areCollectionsEqual = (next: GeoJSON.FeatureCollection, prev: GeoJSON.FeatureCollection) => {
  if (next === prev) return true;
  if (next.features.length !== prev.features.length) return false;

  const nextFeatures = next.features;
  const prevFeatures = prev.features;

  const nextIds = nextFeatures.map((f) => f.id.toString());
  const prevIds = prevFeatures.map((f) => f.id.toString());

  if (!arraysEqual(nextIds, prevIds)) return false;

  return areFeaturesEqual(nextFeatures, prevFeatures);
};

const areFeaturesEqual = (arr1: GeoJSON.Feature[], arr2: GeoJSON.Feature[]) => {
  for (let i = 0; i < arr1.length; i += 1) {
    if (!equal(arr1[i], arr2[i])) return false;
  }
  return true;
};

const arraysEqual = (arr1: string[], arr2: string[]) => {
  if (arr1 === arr2) return true;
  if (!arr1 || !arr2) return false;

  if (arr1.length !== arr2.length) return false;

  return arr1.every((item) => arr2.includes(item));
};

export default compose<PropsType, {}>(
  connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    {
      areStatePropsEqual: (next: any, prev: any) => next.modeName === prev.modeName &&
          next.modeFeatureId === prev.modeFeatureId &&
          next.kmlFile === prev.kmlFile &&
          areCollectionsEqual(next.collection, prev.collection)
          && next.activeOrder.isEstimate === prev.activeOrder.isEstimate,
    },
  ),
);
