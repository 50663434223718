// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core/';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const ConfirmDialog = ({ showDialog, onOk, onCancel, contentText }: PropsType) => {
  const classes = useStyles();

  return (
    <Dialog
      open={showDialog}
      onClose={onCancel}
    >
      <DialogTitle>Are you sure?</DialogTitle>
      <DialogContent>{contentText}</DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button onClick={onOk}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(ConfirmDialog);
