// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  tableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    'last-of-type': {
      color: 'blue',
      textAlign: 'right',
      width: '200px',
    },
    width: '300px',
    fontSize: '1em',
  },
  iconTableCell: {
    borderBottom: 'none',
    textAlign: 'left',
    width: '30px',
    fontSize: '1em',
  },
  routerLink: {
    color: '#2E4DEB',
  },
  tooltip: {
    width: '250px',
    height: '200px',
  },
  info: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px',
    cursor: 'pointer',
  },
  icon: {
    width: theme.spacing(2),
    height: theme.spacing(2),
    marginRight: '100px',
    cursor: 'pointer',
  },
  divGrid: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(2),
    alignItems: 'center',
  },
  text: {
    fontSize: '12px',
    fontWeight: 400,
  },
}));

export default useStyles;
