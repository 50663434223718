// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

const useStyles = makeStyles((theme: Theme) => createStyles({
  pageTitle: {
    textAlign: 'center',
    marginBottom: 30,
  },
  form: {
    margin: '0 auto',
    width: 750,
  },
  outline: {
    borderRadius: 0,
    borderColor: 'rgba(0,0,0,0.1) !important',
    boxShadow: theme.lightShadow,
  },
  input: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabel: {
    fontWeight: 300,
    letterSpacing: '1.3px',
    fontSize: '21px',
  },
  inputLabelShrink: {
    transform: 'translate(14px, -7px) scale(0.75) !important',
  },
  helperText: {
    fontWeight: 300,
    letterSpacing: '1.3px',
  },
  buttonsWrapper: {
    marginTop: theme.spacing(2),
    display: 'flex',
  },
  button: {
    flexDirection: 'column',
    flex: 1,
    justifyContent: 'flex-start',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    transition: theme.transitions.create('box-shadow'),
    '&:hover': {
      boxShadow: theme.lightShadow,
    },
  },
  icon: {
    display: 'block',
    margin: '0 auto',
  },
  submitButton: {
    boxShadow: 'none',
    width: '50%',
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    marginTop: theme.spacing(4),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  errorSnackbar: {
    backgroundColor: theme.palette.error.dark,
    boxShadow: 'none',
    marginTop: theme.spacing(4),
  },
  successSnackbar: {
    backgroundColor: green[600],
    boxShadow: 'none',
    marginTop: theme.spacing(4),
  },
  snackbarContent: {
    display: 'grid',
    gridGap: theme.spacing(),
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main,
    display: 'grid',
    gridGap: theme.spacing(),
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'center',
  },
  messageText: {
    color: theme.palette.error.main,
    fontWeight: 400,
    marginTop: '2px',
    fontSize: '14px',
  },
}));

export default useStyles;
