// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState, useMemo } from 'react';
import { Divider, Paper, Button, CircularProgress } from '@material-ui/core';
import OrderDialog from 'Common/OrderDialog';
import Sidebar from 'Common/Sidebar';
import ProcessedAcreage from 'Common/ProcessedAcreage';
import LightTooltip from 'Common/Tooltip';
import UploadFilesDialog from 'Common/UploadFilesDialog';
import { PDFDownloadLink } from '@react-pdf/renderer';
import downloadIcon from 'Assets/download-dark-icon.png';
import { formatValue, deliveryBusinessDays } from 'Utils/deliverables';
import PDF from 'Common/OrderDialog/PDF';
import Autosuggest from './Autosuggest';
import KmlPanel from './KmlPanel';
import DataSetsPanel from './DataSetsPanel';
import SelectionInfo from './SelectionInfo';
import compose from './container';

const ProjectSidebar = compose(({ classes, order, isOnFreePlan, placeOrder, imageryRequested, imageryRequest, projectName, automatePlanType, availableTeams, org, template, epsgCode, geogCS,
  screenshot,
  topoMajr,
  topoMinr,
  selectedBundle,
  deliveryDays,
  imageryCreditCost,
  imageryDollarCost,
  adjustedOrderAcres,
  selectedAdditionals,
  bundleDeliverables,
  creditAcreage,
  creditCost,
  costToCustomer,
  projectId,
  paidForThisProject,
  GetOrdersAction,
  HideUploadKmlDialogAction }) => {
  const noCostString = '0 credits ($0.00)';
  const [orderFormVisible, setOrderFormVisible] = useState(false);
  const [uploadFilesFormVisible, setUploadFilesFormVisible] = useState(false);
  const [deliverableAddOnString, setDeliverableAddOnString] = useState(noCostString);
  const [totalAddOnString, setTotalAddOnString] = useState(noCostString);
  const [layerAddOnString, setLayerAddOnString] = useState(noCostString);

  const needUploadFiles = (!order.boundaryFile);
  const underlingOnFreePlan = !placeOrder && isOnFreePlan;

  useEffect(() => {
    if (imageryRequest && order.isEstimate) {
      const loadData = async () => {
        await GetOrdersAction(projectId);
      };

      loadData();
      const totalAddOnCreditsIncludingImagery = selectedAdditionals.reduce((acc, obj) => acc + obj.acres, 0);
      const totalAddOnCost = selectedAdditionals.reduce((acc, obj) => acc + obj.cost, 0);
      setTotalAddOnString(`${(totalAddOnCreditsIncludingImagery + imageryCreditCost).toFixed(2)} credits ($${formatValue(totalAddOnCost + imageryDollarCost)})`);

      const addOnDeliverables = selectedAdditionals.filter((del) => del.type === 'deliverable');
      const totalAddOnDeliverbalesCredits = addOnDeliverables.reduce((acc, obj) => acc + obj.acres, 0);
      const totalAddOnDeliverbalesDollars = addOnDeliverables.reduce((acc, obj) => acc + obj.cost, 0);
      setDeliverableAddOnString(`${totalAddOnDeliverbalesCredits} credits ($${formatValue(totalAddOnDeliverbalesDollars)})`);

      const addOnLayers = selectedAdditionals.filter((del) => del.type === 'layer');
      const totalAddOnLayersCredits = addOnLayers.reduce((acc, obj) => acc + obj.acres, 0);
      const totalAddOnLayersDollars = addOnLayers.reduce((acc, obj) => acc + obj.cost, 0);

      setLayerAddOnString(`${totalAddOnLayersCredits} credits ($${formatValue(totalAddOnLayersDollars)})`);
    }
  }, [order.isEstimate, order.creditCost]);

  const onButtonClick = () => {
    setOrderFormVisible(true);
  };
  const currentTeam = availableTeams?.find((t) => t._id === order.team);
  const pdfData = useMemo(() => {
    const { costPerAcre } = org;
    return ({
      type: 'Estimate',
      automatePlanType,
      screenshot,
      businessDays: deliveryDays ? deliveryBusinessDays(deliveryDays) : 'N/A',
      projectName,
      referenceId: order.referenceId,
      team: currentTeam?.name,
      acreage: order.acreage,
      middlePoint: `${order.middlePoint?.place}, ${order.middlePoint?.region} ${order.middlePoint?.postcode}`,
      coordinates: `${order.middlePoint?.longitude.toFixed(5)}, ${order.middlePoint?.latitude.toFixed(5)}`,
      cadTemplate: template.find((temp) => temp._id === order.cadTemplate)?.name,
      crs: epsgCode && `${geogCS} - EPSG: ${epsgCode}`,
      totalAdditionalCharged: paidForThisProject?.toFixed(2),
      topoMajr,
      topoMinr,
      selectedBundle,
      bundleDeliverables,
      selectedAdditionals,
      totalCostForProject: costToCustomer?.toFixed(2),
      chargedCredits: adjustedOrderAcres,
      chargedCreditsInDollars: adjustedOrderAcres * costPerAcre,
      totalCreditsCharged: creditAcreage,
      totalCreditsChargedInDollars: creditCost,
      addOnsCreditsString: totalAddOnString,
      deliverablesCreditsString: deliverableAddOnString,
      layersCreditsString: layerAddOnString,
      anticipatedKickoffDate: order.anticipatedKickoffDate,
      imageryProvider: imageryRequest?.provider,
      imageryCreditCost,
      imageryDollarCost,
    });
  }, [order,
    totalAddOnString, layerAddOnString, screenshot]);

  return (
    <Sidebar onClick={HideUploadKmlDialogAction}>
      <div className={classes.sidebarContent}>
        <Autosuggest />
        <Divider />
        <KmlPanel />
        <Divider />
        <DataSetsPanel />
        <Divider />
        <SelectionInfo />
        {!isOnFreePlan && (
          <div className={classes.acreageWrapper}>
            <ProcessedAcreage />
          </div>
        )}
      </div>
      { order.isEstimate && imageryRequested && screenshot ? (
        <div className={classes.downloadContainer}>
          <PDFDownloadLink document={<PDF data={pdfData} />} fileName={`${projectName}_estimate.pdf`} className={classes.pdfButton}>
            {({ blob, url, loading }) =>
              (loading ? <CircularProgress color="white" size={24} /> : (
                <div
                  className={classes.orderButton}
                >
                  <img className={classes.downloadImage} src={downloadIcon} alt="download" />
                  DOWNLOAD ESTIMATE
                </div>
              ))}
          </PDFDownloadLink>
        </div>
      )
        : (
          <>
            <Paper className={classes.orderButtonWrapper} elevation={2}>
              <LightTooltip title={underlingOnFreePlan ? "Your account type doesn't have access to place orders. Notify your account admin to order this project." : ''}>
                <span>
                  <Button
                    variant="outlined"
                    fullWidth
                    className={classes.orderButton}
                    onClick={onButtonClick}
                    disabled={needUploadFiles || order.acreage <= 0 || underlingOnFreePlan || imageryRequested}
                  >
                    Go to Checkout
                  </Button>
                </span>
              </LightTooltip>
            </Paper>
          </>
        )}
      <OrderDialog orderFormVisible={orderFormVisible} setOrderFormVisible={setOrderFormVisible} />
      <UploadFilesDialog showDialog={uploadFilesFormVisible} setShowDialog={setUploadFilesFormVisible} />
    </Sidebar>
  );
});

export default ProjectSidebar;
