// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CheckPromoCode } from 'State/subscription/thunk';
import { WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import styles from './styles';

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    CheckPromoCodeAction: CheckPromoCode,
  }, dispatch);

interface IOwnProps {
    showPromoForm: boolean;
    setShowPromoForm: (arg0: boolean) => void;
    cadSeats: number;
}

type PropsType =
  ReturnType<typeof mapDispatchToProps> &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(null, mapDispatchToProps),
);
