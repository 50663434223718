// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  scrollWrapper: {
    overflow: 'auto',
    flex: 1,
  },
  wrapper: {
    width: 1050,
    margin: 'auto',
  },
  header: {
    display: 'inline-grid',
    gridTemplateColumns: '21fr 3fr 2fr 1fr',
    '&>*:nth-child(3)': {
      textAlign: 'right',
      justifySelf: 'right',
      marginRight: theme.spacing(2),
    },
  },
  headerAirworks: {
    width: 1050,
    gridTemplateColumns: '21fr 3fr 0fr 0fr',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  ordersHeader: {
    marginBottom: theme.spacing(3),
  },
  inputControls: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(3),
    alignItems: 'center',
  },
  files: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto',
    gridColumnGap: theme.spacing(2),
    alignItems: 'center',
  },
  cancelButton: {
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
  submitButton: {
    boxShadow: 'none',
    width: '100%',
    borderRadius: 0,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  downloadButton: {
    fontSize: theme.spacing(2),
  },
  editIcon: {
    cursor: 'pointer',
  },
  checkIcon: {
    cursor: 'pointer',
  },
  checkbox: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  flex: {
    display: 'inline-flex',
    width: '70%',
  },
  button: {
    color: '#315177',
    fontSize: '12px',
  },
  contentEditable: {
    fontSize: '22px',
    fontWeight: 400,
  },
  headerWrapper: {
    position: 'sticky',
    top: 0,
    backgroundColor: '#fafafa',
    paddingTop: '56px',
    zIndex: 1,
    width: 1050,
    margin: 'auto',
  },
}));

export default useStyles;
