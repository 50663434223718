// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogContent: {
      width: '500px',
    },
    dialogTitle: {
      paddingLeft: '30px',
      paddingTop: '30px',
    },
    dialogButtons: {
      paddingRight: '20px',
      paddingBottom: '20px',
    },
    dialogSubtitle: {
      padding: '20px 30px 0 30px',
    },
    radioContent: {
      padding: theme.spacing(3),
    },
    textContent: {
      paddingLeft: '50px',
      paddingBottom: '20px',
    },
  }));

export default useStyles;
