// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';

import { SetSortFilter, SetKeywordFilter, GetProjects, SetLoadNew, SetPage, SetProjects, SetLoadAll, SetOrderSortFilter } from 'State/project/thunk';

const mapStateToProps = (state: IState) => ({
  sortFilter: state.project.sortFilter,
  orderSortFilter: state.project.orderSortFilter,
  keywordFilter: state.project.keywordFilter,
});

const mapDispatchToProps = {
  SetSortFilterAction: SetSortFilter,
  SetOrderSortFilterAction: SetOrderSortFilter,
  SetKeywordFilterAction: SetKeywordFilter,
  GetProjectsAction: GetProjects,
  SetLoadNewAction: SetLoadNew,
  SetLoadAllAction: SetLoadAll,
  SetPageAction: SetPage,
  SetProjectsAction: SetProjects,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps;

export default compose<PropsType, {}>(
  connect(mapStateToProps, mapDispatchToProps),
);
