// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Button, CircularProgress, Collapse, Fade, SnackbarContent, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { Form } from 'react-final-form';
import { Helpers, TextField, Validators } from 'Common/forms';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const CreateOrgForm = ({ CreateOrgAction, createOrgSuccess }: PropsType) => {
  const classes = useStyles();
  const onFormSubmit = (values: any) => Helpers.submit(values, CreateOrgAction);

  return (
    <div className={classes.pageTitle}>
      <Typography variant="h1">Create New Organization</Typography>
      <Form
        onSubmit={onFormSubmit}
        render={({ pristine, submitError, hasSubmitErrors, modifiedSinceLastSubmit, submitting, submitSucceeded, handleSubmit }) => (
          <form
            className={classes.form}
            onSubmit={handleSubmit}
          >
            <TextField
              name="firstName"
              label="First Name"
              variant="outlined"
              placeholder="First Name"
              validate={Validators.required}
              inputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
              inputLabelProps={{
                classes: {
                  outlined: classes.inputLabel,
                  shrink: classes.inputLabelShrink,
                },
              }}
              helperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
            />
            <TextField
              name="lastName"
              label="Last Name"
              placeholder="Last Name"
              variant="outlined"
              validate={Validators.required}
              inputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
              inputLabelProps={{
                classes: {
                  outlined: classes.inputLabel,
                  shrink: classes.inputLabelShrink,
                },
              }}
              helperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
            />
            <TextField
              name="phoneNumber"
              label="Phone Number"
              placeholder="Phone Number"
              variant="outlined"
              validate={Validators.required}
              inputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
              inputLabelProps={{
                classes: {
                  outlined: classes.inputLabel,
                  shrink: classes.inputLabelShrink,
                },
              }}
              helperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
            />
            <TextField
              name="companyName"
              label="Company Name / Organization"
              placeholder="Company Name"
              variant="outlined"
              validate={Validators.required}
              inputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
              inputLabelProps={{
                classes: {
                  outlined: classes.inputLabel,
                  shrink: classes.inputLabelShrink,
                },
              }}
              helperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
            />
            <TextField
              name="email"
              label="Email"
              placeholder="Email"
              variant="outlined"
              validate={Validators.required}
              inputProps={{
                classes: {
                  notchedOutline: classes.outline,
                  input: classes.input,
                },
              }}
              inputLabelProps={{
                classes: {
                  outlined: classes.inputLabel,
                  shrink: classes.inputLabelShrink,
                },
              }}
              helperTextProps={{
                classes: {
                  root: classes.helperText,
                },
              }}
            />
            <div className={classes.buttonsWrapper}>
              <Collapse in={!submitting && submitSucceeded}>
                <SnackbarContent
                  className={classes.successSnackbar}
                  message={(
                    <div className={classes.snackbarContent}>
                      <CheckCircleIcon />
                      {createOrgSuccess}
                      {' '}
                      Org Created Successfully
                    </div>
                  )}
                />
              </Collapse>
              <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                <div className={classes.errorMessage}>
                  <ErrorIcon />
                  <Typography variant="body1" className={classes.messageText}>
                    {submitError}
                  </Typography>
                </div>
              </Collapse>
            </div>
            <div>
              <Button
                variant="contained"
                color="primary"
                className={classes.submitButton}
                type="submit"
                disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
              >
                <Fade in={submitting}>
                  <CircularProgress size={24} className={classes.buttonProgress} />
                </Fade>
                <Fade in={!submitting}>
                  <span>Create Organization</span>
                </Fade>
              </Button>
            </div>
          </form>
        )}
      />
    </div>
  );
};

export default compose(CreateOrgForm);
