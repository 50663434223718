// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, Theme } from '@material-ui/core';

const styles = (theme: Theme) => createStyles({
  pageWrapper: {
    margin: '0 auto',
    marginTop: theme.spacing(8),
    width: 800,
  },
  pageContent: {
    display: 'flex',
  },
  column: {
    width: 400,
    margin: theme.spacing(6),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
});

export default styles;
