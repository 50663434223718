// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Typography,
  Fade,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  InputBase,
  Divider,
  Checkbox,
  FormControlLabel,
  Paper,
} from '@material-ui/core';
import { useFlags } from 'launchdarkly-react-client-sdk';
import moment from 'moment';
import HelpIcon from '@material-ui/icons/Help';
import InfoIcon from '@material-ui/icons/Info';
import downloadIcon from 'Assets/download-dark-icon.png';
import CreateMap from 'Common/Map';
import { deliveryEstimate, deliveryBusinessDays, formatValue, projByProjDollarPerAcre } from 'Utils/deliverables';
import classNames from 'classnames';
import { WideLightTooltip } from 'Common/Tooltip';
import { opsDailyCreditCapacity } from 'Utils/constants';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDF from 'Common/OrderDialog/PDF';
import TalkToSalesDialog from 'Common/TalkToSalesDialog';
import compose, { PropsType } from './container';
import useStyles from './styles';
import KickoffDatePicker from '../KickoffDatePicker';

const Map = CreateMap('sidebar');

export const MapColumn =
  ({
    order,
    availableCreditAcreage,
    template,
    GetTemplatesAction,
    projectName,
    org,
    defaultTemplate,
    goToNextPage,
    selectedBundle,
    screenshot,
    bundleDeliverables,
    selectedBundleName,
    adjustedOrderAcres,
    defaultCostPerAcre,
    selectedBundleCost,
    imageryCreditCost,
    imageryDollarCost,
    imageryExtraDollarCost,
    deliveryDays,
    automatePlanType,
    GetDefaultTemplateAction,
    allowExpeditedProcess,
    setDeliverySpeed,
    deliverySpeed,
    totalCost,
    cadTemplate,
    setCadTemplate,
    isSubmitting,
    isSaved,
    isSaving,
    bundleStep,
    addOnLayerAcres,
    addOnDeliverableAcres,
    addOnLayerDollars,
    addOnDeliverableDollars,
    firstTimeChangeEstimate,
    saveEstimate,
    SetTopoMajrAction,
    SetTopoMinrAction,
    topoMajr,
    topoMinr,
    placeOrderSuccess,
    selectedAdditionals,
    currentOrderAcreage,
    middlePoint,
    referenceId,
    availableTeams,
    team,
    coordinates,
    page,
    cadTemplates,
    density,
    bundles,
    availableBundles,
    SetDeliverablesAction,
    setPage,
    densityOptions,
    geogCS,
    epsgCode,
    checkFirstTimeChangeEstimate,
    ToggleImageryProcessing,
    dailyCreditCommitment,
    extraDeliverablesLength,
    extraLayersLength,
    cards,
    imageryRequest,
    creditCards,
  }: PropsType) => {
    const classes = useStyles();
    const { minOrderCredits, superExpeditedAvailable } = useFlags();
    const minCredits: number = minOrderCredits || 2;
    const noCostString = '0 credits ($0.00)';
    const addOnCount = extraDeliverablesLength + extraLayersLength;
    const [estimatedDate, setEstimatedDate] = useState('');
    const [businessDays, setBusinessDays] = useState(null);
    const hasCards = creditCards.length > 0;
    const [showKickoffDatePicker, setShowKickoffDatePicker] = useState(false);
    const [firstTimeClickSaveEstimate, setFirstTimeClickSaveEstimate] = useState(true);
    const [contactSalesInteraction, setContactSalesInteraction] = useState(false);

    const [showSalesDialog, setShowSalesDialog] = useState(false);

    const [disableDensity, setDisableDensity] = useState(false);

    const [chargedAcresInSubscription, setChargedAcresInSubscription] = useState(null);
    const [chargedAdditionalAcres, setChargedAdditionalAcres] = useState(null);
    const [expeditedAvailability, setExpeditedAvailability] = useState({ expedited: true, super_expedited: false });

    const [creditsToMin, setCreditsToMin] = useState(0);
    const [dollarsToMin, setDollarsToMin] = useState(0);
    const [extraDollarsToMin, setExtraDollarsToMin] = useState(0);
    const [projByProjAddonAcres, setProjByProjAddonAcres] = useState(0);
    const [inLicenseAddonAcres, setinLicenseAddonAcres] = useState(0);
    const [totalAddOnDollars, setTotalAddOnDollars] = useState(0);
    const [totalAddOnString, setTotalAddOnString] = useState(noCostString);
    const [layerAddOnString, setLayerAddOnString] = useState(noCostString);
    const [deliverableAddOnString, setDeliverableAddOnString] = useState(noCostString);

    useEffect(() => {
      // handle minimum order credits for a la carte bundle
      if (selectedBundleName === 'A la carte') {
        setCreditsToMin(0);
        setDollarsToMin(0);
        setExtraDollarsToMin(0);
        const imageryAndAddOn = imageryCreditCost + inLicenseAddonAcres + projByProjAddonAcres;
        if (imageryAndAddOn > 0 && imageryAndAddOn < minCredits) {
          const extraCredits = minCredits - imageryAndAddOn;
          setCreditsToMin(extraCredits);
          if (projByProjAddonAcres === 0) {
            const existingCreditCost = inLicenseAddonAcres + imageryCreditCost;
            const remainingAvailableCreditAcreage = availableCreditAcreage - existingCreditCost > 0 ? availableCreditAcreage - existingCreditCost : 0;
            if (remainingAvailableCreditAcreage >= extraCredits) {
              setDollarsToMin(extraCredits * org.costPerAcre);
            } else {
              setDollarsToMin(remainingAvailableCreditAcreage * org.costPerAcre + (extraCredits - remainingAvailableCreditAcreage) * projByProjDollarPerAcre);
              setExtraDollarsToMin((extraCredits - remainingAvailableCreditAcreage) * projByProjDollarPerAcre);
            }
          } else {
            setDollarsToMin(extraCredits * projByProjDollarPerAcre);
            setExtraDollarsToMin(extraCredits * projByProjDollarPerAcre);
          }
        }
      }
    }, [selectedBundleName, inLicenseAddonAcres, projByProjAddonAcres]);

    useEffect(() => {
      const overageLayerAcres = addOnLayerDollars / projByProjDollarPerAcre;
      const overageDeliverableAcres = addOnDeliverableDollars / projByProjDollarPerAcre;
      setTotalAddOnDollars(addOnDeliverableDollars + addOnLayerDollars);

      setinLicenseAddonAcres(addOnDeliverableAcres + addOnLayerAcres);
      setProjByProjAddonAcres(overageDeliverableAcres + overageLayerAcres);
    }, [addOnLayerAcres, addOnDeliverableAcres, addOnLayerDollars, addOnDeliverableDollars]);

    useEffect(() => {
      const totalAddOnOverageDollars = addOnDeliverableDollars + addOnLayerDollars;

      if (inLicenseAddonAcres === 0 && totalAddOnOverageDollars === 0) {
        setTotalAddOnString(`${imageryCreditCost.toFixed(2)} credits ($${formatValue(imageryDollarCost)})`);
        return;
      }
      if (totalAddOnOverageDollars === 0 && inLicenseAddonAcres !== 0) {
        setTotalAddOnString(`${(imageryCreditCost + inLicenseAddonAcres).toFixed(2)} credits ($${formatValue(imageryDollarCost + inLicenseAddonAcres * org.costPerAcre)})`);
        return;
      }

      if (inLicenseAddonAcres === 0 && totalAddOnOverageDollars !== 0) {
        const acres = totalAddOnOverageDollars / projByProjDollarPerAcre + imageryCreditCost;
        setTotalAddOnString(`${acres.toFixed(2)} credits ($${formatValue(imageryDollarCost + totalAddOnOverageDollars)})`);
        return;
      }

      setTotalAddOnString(`${(inLicenseAddonAcres + projByProjAddonAcres + imageryCreditCost).toFixed(2)} credits ($${formatValue(imageryDollarCost + totalAddOnOverageDollars + (inLicenseAddonAcres * org.costPerAcre))})`);
    }, [inLicenseAddonAcres, totalAddOnDollars, imageryCreditCost, imageryDollarCost]);

    useEffect(() => {
      const overageLayerAcres = addOnLayerDollars / projByProjDollarPerAcre;

      if (addOnLayerAcres === 0 && addOnLayerDollars === 0) {
        setLayerAddOnString(noCostString);
        return;
      }
      if (addOnLayerDollars === 0 && addOnLayerAcres !== 0) {
        setLayerAddOnString(`${addOnLayerAcres} credits ($${formatValue(addOnLayerAcres * org.costPerAcre)})`);
        return;
      }
      if (addOnLayerAcres === 0 && addOnLayerDollars !== 0) {
        setLayerAddOnString(`${overageLayerAcres.toFixed(2)} credits ($${formatValue(addOnLayerDollars)})`);
        return;
      }

      setLayerAddOnString(`${(addOnLayerAcres + overageLayerAcres).toFixed(2)} credits ($${formatValue(addOnLayerDollars + (addOnLayerAcres * org.costPerAcre))})`);
    }, [addOnLayerDollars, addOnLayerAcres]);

    useEffect(() => {
      const overageDeliverableAcres = addOnDeliverableDollars / projByProjDollarPerAcre;

      if (addOnDeliverableDollars === 0 && addOnDeliverableAcres === 0) {
        setDeliverableAddOnString(noCostString);
        return;
      }
      if (addOnDeliverableDollars === 0 && addOnDeliverableAcres !== 0) {
        setDeliverableAddOnString(`${addOnDeliverableAcres} credits ($${formatValue(addOnDeliverableAcres * org.costPerAcre)})`);
        return;
      }
      if (addOnDeliverableAcres === 0 && addOnDeliverableDollars !== 0) {
        setDeliverableAddOnString(`${overageDeliverableAcres.toFixed(2)} credits ($${formatValue(addOnDeliverableDollars)})`);
        return;
      }

      setDeliverableAddOnString(`${(addOnDeliverableAcres + overageDeliverableAcres).toFixed(2)} credits ($${formatValue(addOnDeliverableDollars + (addOnDeliverableAcres * org.costPerAcre))})`);
    }, [addOnDeliverableAcres, addOnDeliverableDollars]);

    useEffect(() => {
      const { isEstimate, cadTemplate, topoIntervalMajor, topoIntervalMinor } = order;
      if (isEstimate) {
        setCadTemplate(cadTemplate);
        SetTopoMajrAction(topoIntervalMajor);
        SetTopoMinrAction(topoIntervalMinor);
      } else {
        setCadTemplate(defaultTemplate._id);
      }
    }, []);

    useEffect(() => {
      if (selectedBundleCost > 0) {
        setChargedAcresInSubscription(availableCreditAcreage);
        setChargedAdditionalAcres(adjustedOrderAcres - availableCreditAcreage);
      } else {
        setChargedAcresInSubscription(adjustedOrderAcres);
        setChargedAdditionalAcres(0);
      }
    }, [adjustedOrderAcres, availableCreditAcreage, selectedBundleCost]);

    useEffect(() => {
      setDisableDensity(selectedBundle?.bundleId === 2);
    }, [selectedBundle?.bundleId]);

    useEffect(() => {
      const dueDate = moment(deliveryEstimate(deliveryDays));
      setEstimatedDate(dueDate.format('LL'));
      setBusinessDays(deliveryBusinessDays(deliveryDays));
    }, [deliveryDays]);

    // The intervals are unitless but are originally meant for US survey feet.
    const topoIntervals = [0.25, 0.5, 1, 2, 5, 10];

    useEffect(() => {
      const loadData = async () => {
        await GetTemplatesAction();
        await GetDefaultTemplateAction(org.defaultTemplate);
      };
      loadData();
    }, []);
    const currentTeam = availableTeams?.find((t) => t._id === order.team);

    const onProceed = () => {
      setContactSalesInteraction(true);
      if (!cards) setPage(1);
    };

    const totalCredits = creditsToMin + imageryCreditCost + adjustedOrderAcres + inLicenseAddonAcres + projByProjAddonAcres;

    useEffect(() => {
      const newExpeditedAvailability: any = { ...expeditedAvailability };
      const speedFactors = availableBundles.find((b) => selectedBundleName === b.name)?.deliverySpeedAdjustmentFactor;
      speedFactors?.forEach((speedObj) => {
        const { speed, creditFactor } = speedObj;
        if (speed !== 'normal' && !(speed === 'super_expedited' && !superExpeditedAvailable)) {
          const dailyCommitment = ((creditFactor * adjustedOrderAcres) / selectedBundle?.bundleDeliveryTime) || 0;
          const availability = dailyCommitment + dailyCreditCommitment < opsDailyCreditCapacity;
          if (speed === 'expedited' && !availability) {
            window?.pendo?.track('Expedited Processing Unavailable', { project_name: projectName, order_size: order.acreage });
          }
          newExpeditedAvailability[speed] = availability;
          setExpeditedAvailability(newExpeditedAvailability);
        }
      });
    }, [selectedBundleName]);

    const pdfData = useMemo(() => {
      const { costPerAcre } = org;
      return ({
        type: 'Estimate',
        automatePlanType,
        projectName,
        referenceId: order.referenceId,
        team: currentTeam?.name,
        acreage: order.acreage,
        middlePoint: `${order.middlePoint?.place}, ${order.middlePoint?.region} ${order.middlePoint?.postcode}`,
        coordinates: `${order.middlePoint?.longitude.toFixed(5)}, ${order.middlePoint?.latitude.toFixed(5)}`,
        cadTemplate: template.find((temp) => temp._id === cadTemplate)?.name,
        crs: epsgCode && `${geogCS} - EPSG: ${epsgCode}`,
        topoMajr,
        topoMinr,
        selectedBundle,
        businessDays,
        bundleDeliverables,
        selectedAdditionals,
        screenshot,
        chargedCredits: adjustedOrderAcres,
        chargedCreditsInDollars: chargedAcresInSubscription * costPerAcre + chargedAdditionalAcres * selectedBundle?.costPerFactors[1],
        totalCreditsCharged: automatePlanType !== 'plan_free' ? +(totalCredits).toFixed(2) : 0,
        totalCreditsChargedInDollars: automatePlanType !== 'plan_free' ? (dollarsToMin + imageryDollarCost + totalCost + (projByProjAddonAcres * projByProjDollarPerAcre) + ((inLicenseAddonAcres + chargedAcresInSubscription) * costPerAcre)) : 0,
        addOnsCreditsString: totalAddOnString,
        deliverablesCreditsString: deliverableAddOnString,
        layersCreditsString: layerAddOnString,
        totalAdditionalCharged: extraDollarsToMin + totalCost + addOnLayerDollars + addOnDeliverableDollars + imageryExtraDollarCost,
        totalCostForProject: dollarsToMin + imageryDollarCost + totalCost + (projByProjAddonAcres * projByProjDollarPerAcre) + ((inLicenseAddonAcres + chargedAcresInSubscription) * costPerAcre),
        anticipatedKickoffDate: order.anticipatedKickoffDate,
        imageryProvider: imageryRequest?.provider,
        imageryCreditCost,
        imageryDollarCost,
      });
    }, [automatePlanType, projectName, order, template, cadTemplate, epsgCode, geogCS, topoMajr, topoMinr,
      selectedBundle, businessDays, org, chargedAcresInSubscription, chargedAdditionalAcres, totalCost, totalAddOnString, layerAddOnString,
      deliverableAddOnString, addOnLayerDollars, inLicenseAddonAcres, projByProjAddonAcres, addOnDeliverableDollars, bundleDeliverables, selectedAdditionals, screenshot,
      imageryRequest, imageryCreditCost, imageryDollarCost, imageryExtraDollarCost, creditsToMin, dollarsToMin, extraDollarsToMin]);

    const handleDensity = (d: string) => {
      const findBundles = bundles.find((b) => b.name === selectedBundleName && b.density === d && b.deliverySpeed === deliverySpeed);
      SetDeliverablesAction(findBundles, true);
    };

    const handleChangeDeliverySpeed = (newSpeed: string) => {
      const delSpeed = deliverySpeed === newSpeed ? 'normal' : newSpeed;
      const findBundles = bundles.find((b) => b.name === selectedBundleName && b.deliverySpeed === delSpeed && b.density === density);
      SetDeliverablesAction(findBundles, true);
      setDeliverySpeed(delSpeed);
    };
    const extraLayers: any[] = [];
    const extraDeliverables: any[] = [];

    selectedAdditionals.forEach((del) => {
      if (del.type === 'layer') {
        extraLayers.push(del);
      } else {
        extraDeliverables.push(del);
      }
    });

    const additonalCharges = +(extraDollarsToMin + totalCost + addOnLayerDollars + addOnDeliverableDollars + imageryExtraDollarCost);
    const onClickSaveEstimate = () => {
      const { anticipatedKickoffDate } = order;
      if (firstTimeClickSaveEstimate && !anticipatedKickoffDate && !imageryRequest) {
        setShowKickoffDatePicker(true);
        setFirstTimeClickSaveEstimate(false);
      } else if (imageryRequest && additonalCharges > 0 && !hasCards) {
        setShowSalesDialog(true);
        setContactSalesInteraction(false);
      } else {
        saveEstimate();
      }
    };

    const contactSalesClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();
      setContactSalesInteraction(true);
      window.open('mailto:sales@airworks.io', '_blank');
    };
    const toggleImageryProcessing = async () => {
      await ToggleImageryProcessing();
    };

    return (
      <>
        <Paper className={classes.mapColumn}>
          <div className={classes.orderInfo}>
            <div>
              <Typography variant="h1" className={classes.header}>
                {projectName}
              </Typography>
              {placeOrderSuccess ? <Typography variant="body1">{`Reference ID: ${referenceId || 'N/A'}`}</Typography> : ''}
              {placeOrderSuccess ? <Typography variant="body1">{`Team: ${team || 'N/A'}`}</Typography> : ''}
              <Typography variant="body1">{`${placeOrderSuccess ? currentOrderAcreage : order.acreage} acres`}</Typography>
              {placeOrderSuccess ? (
                <Typography variant="body1" className={classes.paddingBottom16}>
                  {middlePoint}
                  <br />
                  {coordinates}
                </Typography>
              )
                : (order.middlePoint && (
                  <>
                    <Typography variant="body1" className={classes.paddingBottom16}>
                      {`${order.middlePoint.place}, ${order.middlePoint.region} ${order.middlePoint.postcode}`}
                      <br />
                      {`${order.middlePoint.longitude.toFixed(5)}, ${order.middlePoint.latitude.toFixed(5)}`}
                    </Typography>
                  </>
                )
                )}
            </div>
            <div className={classes.map}>
              <Map />
            </div>
          </div>
          <Divider className={classes.divider} />
          {cadTemplates && (
            <>
              <Typography variant="h6" className={classes.cadText}>
                CAD TEMPLATE
              </Typography>
              {placeOrderSuccess ? (
                <Typography variant="body1">{template.find((temp) => temp._id === cadTemplate).name}</Typography>
              ) : (
                <FormControl className={classes.formControl}>
                  <Select
                    value={cadTemplate}
                    input={<InputBase style={{ width: '100%' }} />}
                    onChange={(e: any) => checkFirstTimeChangeEstimate(() => setCadTemplate(e.target.value))}
                    variant="outlined"
                    className={classes.dropdown}
                  >
                    {template.map((template: any) => (
                      <MenuItem value={template._id} className={classes.menuItem}>
                        <Typography variant="h4" className={classes.innerDropdownText}>
                          {template.name}
                        </Typography>
                        {org.defaultTemplate === template._id ? (
                          <Typography variant="h4" className={classes.defaultText}>
                            Default
                          </Typography>
                        ) : (
                          ''
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )}
            </>
          )}
          <Typography variant="h6" className={classes.topoTitle}>CRS COORDINATE SYSTEM</Typography>
          {epsgCode ? <Typography variant="h5" className={placeOrderSuccess && classes.crs}>{`${geogCS} - EPSG: ${epsgCode}`}</Typography> : 'N/A'}
          {placeOrderSuccess ? (
            <>
              {(topoMajr || topoMinr) && <Typography variant="h6" className={classes.topoTitle}>TOPOGRAPHY INTERVAL</Typography>}
              <div className={classes.topoTextSuccess}>
                {topoMajr && <Typography variant="body1">{`Major: ${topoMajr} ft `}</Typography>}
                {topoMinr && <Typography variant="body1">{`Minor: ${topoMinr} ft `}</Typography>}
              </div>
            </>
          ) : (
            <>
              <Typography variant="h6" className={classes.topoTitle}>TOPOGRAPHY INTERVAL</Typography>
              <div className={classes.topoControls}>
                <Typography variant="h6" className={classes.topoText}>
                  Major:
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    value={topoMajr ?? ''}
                    input={<InputBase />}
                    onChange={(e: any) => checkFirstTimeChangeEstimate(() => SetTopoMajrAction(e.target.value))}
                    variant="outlined"
                    className={classes.dropdown}
                  >
                    {topoIntervals.map((topo: any) => (
                      <MenuItem value={topo} className={classes.menuItem}>
                        <Typography variant="h4" className={classes.innerDropdownText}>
                          {topo}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="h6" className={classes.ftText}>ft</Typography>
                <Typography variant="h6" className={classes.topoText}>
                  Minor:
                </Typography>
                <FormControl className={classes.formControl}>
                  <Select
                    value={topoMinr ?? ''}
                    input={<InputBase />}
                    onChange={(e: any) => checkFirstTimeChangeEstimate(() => SetTopoMinrAction(e.target.value))}
                    variant="outlined"
                    className={classes.dropdown}
                  >
                    {topoIntervals.map((topo: any) => (
                      <MenuItem value={topo} className={classes.menuItem}>
                        <Typography variant="h4" className={classes.innerDropdownText}>
                          {topo}
                        </Typography>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Typography variant="h6" className={classes.ftText}>ft</Typography>
              </div>
              <div className={classes.density}>
                <div className={classes.dateGrid}>
                  <Typography variant="h6" className={`${classes.densityTitle} ${disableDensity && classes.disabled}`} onClick={() => window.open('http://support.airworks.io/Normal-Vs.-Low-Density.2351923205.html', '_tab')}>PROJECT DENSITY</Typography>
                  <WideLightTooltip
                    placement="bottom"
                    interactive
                    disableHoverListener={disableDensity}
                    title={(
                      <div>
                        Density change is subject to final approval by AirWorks. We will notify you within 1 business day if we modify your density estimation. You will be able to view or, if necessary, cancel your order from "Projects".
                      </div>
                    )}
                  >
                    <div className={classes.densityInfo}>
                      <InfoIcon className={`${classes.icon} ${disableDensity && classes.disabled}`} />
                    </div>
                  </WideLightTooltip>
                </div>
                <FormControl className={classes.formControl} disabled={disableDensity}>
                  {disableDensity ? (
                    <Select
                      value="Topo"
                      input={<InputBase style={{ width: '100%' }} />}
                      className={classes.dropdown}
                    >
                      <MenuItem value="Topo" className={classes.menuItem}>
                        <Typography variant="h4" color="inherit" className={`${classes.innerDropdownText} ${classes.disabled}`}>
                          {`No density can be set for the ${selectedBundleName} bundle`}
                        </Typography>
                      </MenuItem>
                    </Select>
                  ) : (
                    <Select
                      value={density}
                      input={<InputBase style={{ width: '100%' }} />}
                      onChange={(e: any) => checkFirstTimeChangeEstimate(() => handleDensity(e.target.value))}
                      className={classes.dropdown}
                    >
                      {densityOptions.map((density) => (
                        <MenuItem value={density} className={classes.menuItem}>
                          <Typography variant="h4" className={`${classes.innerDropdownText} ${classes.densityText}`}>
                            {density}
                          </Typography>
                          {density === 'normal' && (
                            <Typography variant="h4" className={classes.defaultText}>
                              Default
                            </Typography>
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </FormControl>
              </div>
            </>
          )}
          <Divider className={classes.divider} />
          <Typography variant="h6" className={classes.cadText}>
            Estimated Delivery Date
          </Typography>
          {placeOrderSuccess ? (
            <>
              {
                businessDays > 20 ? (
                  <div className={classes.dateGrid}>
                    <Typography variant="body1">This project is eligible for super expedited delivery</Typography>
                    <WideLightTooltip
                      placement="bottom"
                      interactive
                      title={
                        (
                          <div>
                            Need this back super fast? Our team can provide a custom delivery date for this project. Reach out to
                            {' '}
                            <a href="mailto:sales@airworks.io">sales@airworks.io</a>
                            {' '}
                            to get a quote.
                          </div>
                        )
                      }
                    >
                      <HelpIcon className={classes.icon} />
                    </WideLightTooltip>
                  </div>
                ) : (
                  <Typography variant="body1">
                    {estimatedDate}
                    {' '}
                    by 8PM EST
                  </Typography>
                )
              }
            </>
          ) : (
            <>
              {businessDays > 20 ?
                (
                  <div className={classes.dateGrid}>
                    <Typography variant="h6" className={classes.deliveryDateText}>This project is eligible for super expedited delivery</Typography>
                    <WideLightTooltip
                      placement="bottom"
                      interactive
                      title={
                        (
                          <div>
                            Need this back super fast? Our team can provide a custom delivery date for this project. Reach out to
                            {' '}
                            <a href="mailto:sales@airworks.io">sales@airworks.io</a>
                            {' '}
                            to get a quote.
                          </div>
                        )
                      }
                    >
                      <HelpIcon className={classes.icon} />
                    </WideLightTooltip>
                  </div>
                ) :
                (
                  <div className={classes.dateGrid}>
                    <Typography variant="h6" className={classes.deliveryDateText}>
                      {estimatedDate}
                      {' '}
                      by 8PM EST
                      {page === 0 && ` (${businessDays} business days)`}
                    </Typography>
                    <WideLightTooltip
                      placement="bottom"
                      interactive
                      title={
                        (
                          <div>
                            Turn-around time varies depending on site density especially for large projects. The above is our estimated delivery date. If you have questions or need your project faster, please select expedited delivery, or reach out to us through chat or
                            {' '}
                            <a href="mailto:support@airworks.io">support@airworks.io</a>
                            .
                            <br />
                            <br />
                            Add-ons delivery date is an estimate, we'll deliver them as soon as possible.
                          </div>
                        )
                      }
                    >
                      <HelpIcon className={classes.icon} />
                    </WideLightTooltip>
                  </div>
                )}
              <div className={classes.expeditedDelivery}>
                <FormControlLabel
                  disabled={!allowExpeditedProcess || !expeditedAvailability.expedited}
                  control={<Checkbox disabled={!allowExpeditedProcess || !expeditedAvailability.expedited} checked={deliverySpeed === 'expedited'} onChange={() => checkFirstTimeChangeEstimate(() => handleChangeDeliverySpeed('expedited'))} />}
                  label={`Expedited Delivery${!expeditedAvailability.expedited ? ' Not Available' : ''}`}
                  classes={{ root: classes.checkbox, label: classes.checkboxLabel }}
                />
                {!allowExpeditedProcess && (
                  <WideLightTooltip
                    placement="bottom"
                    interactive
                    title={(
                      <div>
                        Expedited processing is currently not available through the order form. Please reach out to us through chat or
                        {' '}
                        <a href="mailto:support@airworks.io">support@airworks.io</a>
                        {' '}
                        if you would like to discuss faster delivery.
                      </div>
                    )}
                  >
                    <div className={classes.info}>
                      <InfoIcon className={classes.icon} />
                      {' '}
                      Talk to rep
                    </div>
                  </WideLightTooltip>
                )}
              </div>
              {allowExpeditedProcess && expeditedAvailability.super_expedited && (
                <FormControlLabel
                  control={<Checkbox checked={deliverySpeed === 'super_expedited'} onChange={() => checkFirstTimeChangeEstimate(() => handleChangeDeliverySpeed('super_expedited'))} />}
                  label="Super Expedited Delivery"
                  classes={{ root: classes.checkbox, label: classes.checkboxLabel }}
                />
              )}
            </>
          )}
          <Divider className={classes.divider} />

          <div className={classes.divGrid}>
            <div className={classes.dateGrid}>
              <Typography variant="h3">
                {selectedBundleName}
                {' '}
                credits
              </Typography>
              <WideLightTooltip
                placement="bottom"
                interactive
                disableHoverListener={disableDensity}
                title={(
                  <div>
                    The credits listed here reflect the number of credits that will be deducted from your plan for this project based on the project size, bundle, project density and delivery date selected. This number is tentative based on AirWorks approval of any density change.
                    {(placeOrderSuccess ? currentOrderAcreage : order.acreage) < minCredits ? (
                      <>
                        <br />
                        <br />
                        As a reminder, AirWorks minimum site size is
                        {' '}
                        {minCredits}
                        {' '}
                        credits.
                      </>
                    ) : ''}
                  </div>
                )}
              >
                <div className={classes.densityInfo}>
                  <InfoIcon className={classes.icon} />
                </div>
              </WideLightTooltip>
            </div>
            <Typography className={classes.rightContent} variant="h3">
              {adjustedOrderAcres}
              {` ($${formatValue(chargedAcresInSubscription * org.costPerAcre + chargedAdditionalAcres * defaultCostPerAcre)})`}
            </Typography>
          </div>
          <div className={classes.orderDetail}>
            <div className={classes.divGrid}>
              <Typography variant="body1" className={classes.orderDetailTitle}>- Project Size</Typography>
              <Typography variant="body1" className={classes.orderDetailValue}>
                {`${placeOrderSuccess ? currentOrderAcreage : order.acreage} ac`}
              </Typography>
            </div>
            <div className={classes.divGrid}>
              <Typography variant="body1" className={classes.orderDetailTitle}>- Bundle</Typography>
              <Typography variant="body1" className={classes.orderDetailValue}>
                {selectedBundleName}
              </Typography>
            </div>
            <div className={classes.divGrid}>
              <Typography variant="body1" className={classes.orderDetailTitle}>- Density</Typography>
              <Typography variant="body1" className={classNames(classes.orderDetailValue, classes.capitalize)}>
                {density}
              </Typography>
            </div>
            <div className={classes.divGrid}>
              <Typography variant="body1" className={classes.orderDetailTitle}>- Delivery Speed</Typography>
              <Typography variant="body1" className={classNames(classes.orderDetailValue, classes.capitalize)}>
                {deliverySpeed.split('_').join(' ')}
              </Typography>
            </div>
          </div>

          <div className={classes.divGrid}>
            <Typography variant="h3">Add-Ons</Typography>
            <Typography className={classes.rightContent} variant="h3">
              {totalAddOnString}
            </Typography>
          </div>
          <div className={classes.orderDetail}>
            <div className={classes.divGrid}>
              <Typography variant="body1" className={classes.orderDetailTitle}>{`- Deliverables (${extraDeliverablesLength})`}</Typography>
              <Typography variant="body1" className={classes.orderDetailValue}>
                {deliverableAddOnString}
              </Typography>
            </div>
            <div className={classes.divGrid}>
              <Typography variant="body1" className={classes.orderDetailTitle}>{`- Layers (${extraLayersLength})`}</Typography>
              <Typography variant="body1" className={classes.orderDetailValue}>
                {layerAddOnString}
              </Typography>
            </div>
            {imageryRequest && (
              <div className={classes.divGrid}>
                <Typography variant="body1" className={classes.orderDetailTitle}>{`- ${imageryRequest.provider} Imagery`}</Typography>
                <Typography variant="body1" className={classes.orderDetailValue}>
                  {`${imageryCreditCost} credits ($${formatValue(imageryDollarCost)})`}
                </Typography>
              </div>
            )}
          </div>

          <Divider className={classes.divider} />

          <div className={classNames(classes.divGrid, classes.paddingTop8)}>
            <div className={classes.dateGrid}>
              <Typography variant="h3">Total Credits Charged</Typography>
              <WideLightTooltip
                placement="bottom"
                interactive
                title={(
                  <div>
                    {`Your total credits are the sum of ${selectedBundleName} credits & add-ons.`}
                    {creditsToMin > 0 && (
                      <>
                        <br />
                        <br />
                        As a reminder, AirWorks minimum order size is
                        {' '}
                        {minCredits}
                        {' '}
                        credits.
                      </>
                    )}
                  </div>
                )}
              >
                <div className={classes.densityInfo}>
                  <InfoIcon className={classes.icon} />
                </div>
              </WideLightTooltip>
            </div>
            <Typography variant="h3" className={classNames(classes.rightContent, classes.paddingTop8)}>
              {`${automatePlanType !== 'plan_free' ? +(totalCredits).toFixed(2) : 0}`}
              {` ($${formatValue(automatePlanType !== 'plan_free' ? (dollarsToMin + imageryDollarCost + totalCost + (projByProjAddonAcres * projByProjDollarPerAcre) + ((inLicenseAddonAcres + chargedAcresInSubscription) * org.costPerAcre)) : 0)})`}
            </Typography>
          </div>
          {(extraDollarsToMin + totalCost + addOnLayerDollars + addOnDeliverableDollars + imageryExtraDollarCost) > 0 && (
            <div className={classNames(classes.divGrid, classes.paddingTop16)}>
              <div className={classes.dateGrid}>
                <Typography variant="h3">Total Additional Charges</Typography>
              </div>
              <Typography variant="h3" className={classNames(classes.rightContent, classes.paddingTop16)}>
                {`$${formatValue(extraDollarsToMin + totalCost + addOnLayerDollars + addOnDeliverableDollars + imageryExtraDollarCost)}`}
              </Typography>
            </div>
          )}
          <div className={classNames(classes.divGrid, classes.paddingTop16, classes.blueFont)}>
            <div className={classes.dateGrid}>
              <Typography variant="h2">Total cost for project</Typography>
            </div>
            <Typography variant="h2" className={classNames(classes.rightContent, classes.paddingTop16)}>
              {`$${formatValue(dollarsToMin + imageryDollarCost + totalCost + (projByProjAddonAcres * projByProjDollarPerAcre) + ((inLicenseAddonAcres + chargedAcresInSubscription) * org.costPerAcre))}`}
            </Typography>
          </div>
          {extraDollarsToMin + imageryDollarCost + totalCost + (projByProjAddonAcres * projByProjDollarPerAcre) === 0 && (
            <div className={classes.notes}>
              &#42; This project incurs no new charges. Cost is for estimating purposes and will come out of your current balance.
            </div>
          )}

          {automatePlanType !== 'plan_free' && selectedBundleCost > 0 && (
            <div className={classes.notes}>
              &#42; Total cost is for estimating purposes only, this project includes purchases that exceed your license contact your sales rep to upgrade and reduce your project cost.
            </div>
          )}
        </Paper>
        {!placeOrderSuccess && (
          <div>
            {imageryRequest && (
              <div className={classes.processingContainer}>
                <FormControlLabel
                  label={<Typography>Begin Processing Immediately</Typography>}
                  control={(
                    <Checkbox
                      disabled={order.isEstimate}
                      color="primary"
                      onChange={toggleImageryProcessing}
                      checked={imageryRequest.processImmediately}
                      classes={{
                        root: classes.checkbox,
                      }}
                    />
                  )}
                />
                <WideLightTooltip
                  placement="bottom"
                  disableHoverListener={order.isEstimate}
                  interactive
                  title={(
                    <div>
                      When start processing immediately is selected, you are forfeiting your chance to review the available imagery for your area of interest before placing your order. If you want to review the imagery before kicking off your project, unselect this option.
                    </div>
                  )}
                >
                  <InfoIcon fontSize="small" />
                </WideLightTooltip>
              </div>
            )}
            <div className={classes.buttonsContainer}>
              <div className={classes.downloadContainer}>
                {selectedBundle && screenshot && (isSaved || firstTimeChangeEstimate) ? (
                  <PDFDownloadLink document={<PDF data={pdfData} />} fileName={`${projectName}_estimate.pdf`} className={classes.pdfButton}>
                    {({ blob, url, loading }) =>
                      (loading ? <CircularProgress color="white" size={24} className={classes.buttonProgress} /> : (
                        <div
                          className={classes.downloadText}
                          onClick={() => {
                            window?.pendo?.track('Download Estimate');
                          }}
                        >
                          <img src={downloadIcon} alt="download" />
                          DOWNLOAD ESTIMATE
                        </div>
                      ))}
                  </PDFDownloadLink>
                ) : (
                  <div>
                    <Button variant="outlined" color="primary" className={classes.saveButton} onClick={onClickSaveEstimate} disabled={(page === 1 && !cards)}>
                      <Fade in={isSaving}>
                        <CircularProgress color="white" size={24} className={classes.buttonProgress} />
                      </Fade>
                      <Fade in={!isSaving && !isSaved && !firstTimeChangeEstimate}>
                        <span className={classes.buttonText}>{ imageryRequest ? 'Request Imagery' : 'Save Estimate'}</span>
                      </Fade>
                    </Button>
                  </div>
                )}
              </div>
              {((bundleStep === 2 && page === 0) || (isSaved || firstTimeChangeEstimate)) || page !== 0 ? (
                <Button variant="contained" color="primary" className={classes.continueButton} onClick={goToNextPage} disabled={(isSubmitting) || (page === 1 && !cards) || (selectedBundle?.bundleId === 5 && addOnCount === 0) || (imageryRequest && (!imageryRequest.uploaded || !order.isEstimate))}>
                  <Fade in={isSubmitting}>
                    <CircularProgress size={24} className={classes.buttonProgress} />
                  </Fade>
                  <Fade in={!isSubmitting}>
                    {page && page === 2 ? <span>Submit Order</span> : <span>Continue</span>}
                  </Fade>
                </Button>
              ) : ''}
            </div>
          </div>
        )}
        <KickoffDatePicker orderId={order._id} showDialog={showKickoffDatePicker} setShowDialog={setShowKickoffDatePicker} />
        {(availableBundles?.length > 0 && imageryRequest) && (
          <TalkToSalesDialog
            showDialog={!contactSalesInteraction && showSalesDialog}
            onOk={contactSalesClick}
            onProceed={onProceed}
            onCancel={() => {
              setContactSalesInteraction(true);
            }}
            addOnDeliverableDollars={addOnDeliverableDollars}
            addOnLayerDollars={addOnLayerDollars}
            titleText="Credit Limit Reached!"
          />
        )}
      </>
    );
  };

export default compose(MapColumn);
