// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CreateProject, ReloadProjects, ResetFilters } from 'State/project/thunk';

const mapStateToProps = (state: IState) => ({
  editorFunctions: !_ADMIN_ ? 'editorFunctions' in state.auth.resources : true,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({
    CreateProjectAction: CreateProject,
    ReloadProjectsAction: ReloadProjects,
    ResetFiltersAction: ResetFilters,
  }, dispatch);

interface IOwnProps {
  onCreate: (project: IProject) => void;
  onUpload: (project: IProject) => void;
  onCreateEstimate: (project: IProject) => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps, mapDispatchToProps),
);
