// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { ListItem, ListItemText, Paper } from '@material-ui/core';
import Input from './Input';
import compose from './container';

const AutosuggestWrapper = compose(({
  classes, suggestions, MapSearchAction, MapClearSuggestionsAction, GoToSearchResultAction, ClearMarkerAction }) => {
  const [searchInputValue, setSearchInputValue] = useState('');
  const _setSearchInputValue = (newValue: string) => {
    setSearchInputValue(newValue);
    if (!newValue) ClearMarkerAction();
  };

  const renderSuggestion = (suggestion: IPlacesSearchFeature, { isHighlighted }: Autosuggest.RenderSuggestionParams) => (
    <ListItem button selected={isHighlighted}>
      <ListItemText primary={suggestion.text} secondary={suggestion.description} />
    </ListItem>
  );

  return (
    <Paper className={classes.autosuggestRoot} elevation={1}>
      <Autosuggest
        suggestions={suggestions}
        renderInputComponent={(e) => <Input inputProps={e} setSearchInputValue={_setSearchInputValue} />}
        renderSuggestion={renderSuggestion}
        onSuggestionsFetchRequested={(ev) => MapSearchAction(ev.value)}
        onSuggestionsClearRequested={() => MapClearSuggestionsAction()}
        getSuggestionValue={(suggestion) => suggestion.text}
        onSuggestionSelected={(ev, { suggestion }) => GoToSearchResultAction(suggestion)}
        renderSuggestionsContainer={(options) => (
          <Paper {...options.containerProps} square>
            {options.children}
          </Paper>
        )}
        inputProps={{
          placeholder: 'Enter city, state, or zip code',
          value: searchInputValue,
          onChange: ((ev, { newValue }) => _setSearchInputValue(newValue)),
        }}
        theme={{
          suggestionsContainerOpen: classes.suggestionsContainerOpen,
          suggestionsList: classes.suggestionsList,
        }}
      />
    </Paper>
  );
});

export default AutosuggestWrapper;
