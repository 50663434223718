// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  shellWrapper: {
    minWidth: 960,
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
  },
  headerWrapper: {
    flex: '0 1 auto',
  },
  contentWrapper: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    minHeight: 0,
    overflow: 'auto',
  },
}));

export default useStyles;
