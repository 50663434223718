// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  sidebarContent: {
    flex: 1,
    overflow: 'auto',
    paddingTop: theme.spacing(3),
  },
  templateItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
  },
  createTemplateButtonWrapper: {
    bottom: 0,
    width: '100%',
    padding: theme.spacing(2),
  },
  addIcon: {
    marginRight: theme.spacing(),
  },
  vertButton: {
    position: 'absolute',
    right: '0px',
    paddingRight: '0px',
  },
  defaultItem: {
    position: 'absolute',
    right: theme.spacing(2),
    color: '#2E4DEB',
    fontSize: '12px',
  },
}));

export default useStyles;
