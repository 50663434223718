// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { InView } from 'react-intersection-observer';
import { Typography, CircularProgress } from '@material-ui/core';
import PageToolbar from './PageToolbar';
import ProjectItem from './ProjectListItem';
import compose from './container';
import CreateMap from 'Common/Map';
import TermsAndConditionsDialog from './Terms&ConditionsDialog';

const Map = CreateMap('dashboard');

const ProjectList = compose(({ classes, totalProjectCount, list, html, page, projectsLoading, loadAll, SetPageAction, GetProjectsAction }) => {
  const [showMapview, setShowMapview] = useState(false);

  const showMapviewClick = () => {
    showMapview ? setShowMapview(true) : setShowMapview(true);
  };

  const showGridviewClick = () => {
    showMapview ? setShowMapview(false) : setShowMapview(false);
  };

  const showNoProjects = totalProjectCount === 0 && list.length === 0;
  const showEmptyList = !projectsLoading && totalProjectCount > 0 && list.length === 0;
  const wrapperStyle : string = showMapview ? classes.contentWrapperMap : classes.contentWrapperGrid;
  const listStyle : string = showMapview ? classes.mapList : classes.gridList;
  const listWrapperStyle : string = showMapview ? classes.mapListWrapper : classes.gridListWrapper;

  return (
    <div className={wrapperStyle}>
      <div className={classes.dashboardContent}>
        {html !== 'undefined' && html !== 'null' && html !== null && (<TermsAndConditionsDialog />)}
        <div className={classes.mapviewLayoutLeft}>
          <PageToolbar
            showMapview={showMapview}
            showMapviewClick={showMapviewClick}
            showGridviewClick={showGridviewClick}
          />
          <div className={listWrapperStyle}>
            {list.length > 0 && (
              <>
                <div className={listStyle}>
                  {list.map((project) => (
                    <ProjectItem
                      showMapview={showMapview}
                      project={project}
                      key={project._id}
                    />
                  ))}
                </div>
                <div className={classes.loading}>
                  {!projectsLoading && (loadAll ? (
                    <Typography variant="h3">End of List</Typography>
                  ) : (
                    <>
                      <InView onChange={(inView) => {
                        if (inView) {
                          SetPageAction(page + 1);
                          GetProjectsAction();
                        }
                      }}
                      />
                      <Typography variant="h3">Loading more...</Typography>
                    </>
                  ))}
                </div>
              </>
            )}
            <div className={classes.loading}>
              {projectsLoading && (<CircularProgress size={50} />)}
            </div>
          </div>
        </div>
        {showMapview && (
          <div className={classes.mapviewLayoutRight}>
            <Map />
          </div>
        )}
        {showNoProjects && (
          <Typography variant="h1" className={classes.noProjects}>
            You don&apos;t currently have any projects. Create a
            <br />
            project to get started!
          </Typography>
        )}
        {showEmptyList && (
          <Typography variant="h1" className={classes.noProjects}>
            Project not found
          </Typography>
        )}
      </div>
    </div>
  );
});

export default ProjectList;
