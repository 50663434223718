// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles(() => createStyles({
  paper: {
    backgroundColor: '#01A1DD',
    height: '35px',
    width: '100%',
    borderRadius: '0px',
    position: 'relative',
    boxShadow: 'none',
  },
  trialText: {
    color: '#ffffff',
    fontWeight: 400,
    textAlign: 'center',
  },
  upgradeButton: {
    zIndex: 10,
  },
}));

export default useStyles;
