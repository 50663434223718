// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { withRouter, RouteComponentProps, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import { ADMIN_ROUTE, ADMIN_OPS_SETTINGS_ROUTE } from 'Utils/routes';

const mapStateToProps = (state: IState, ownProps: RouteComponentProps) => ({
  activeTabId: (matchPath(ownProps.location.pathname, { path: ADMIN_ROUTE,
    exact: true }) && 1)
    || (matchPath(ownProps.location.pathname, { path: ADMIN_OPS_SETTINGS_ROUTE }) && 2),
});

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps),
);
