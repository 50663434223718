// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Collapse, IconButton, ListItem, Typography, Tabs, Tab } from '@material-ui/core';
import GestureIcon from '@material-ui/icons/Gesture';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import HelpIcon from '@material-ui/icons/Help';
import SVG from 'react-inlinesvg';
import downloadIcon from 'Assets/download-icon.png';
import uploadKmlIcon from 'Assets/upload-kml-icon.svg';
import ConfirmDialog from 'Common/ConfirmDialog';
import ConfirmProceedDialog from 'Common/ConfirmProceedDialog';
import { WideLightTooltip } from 'Common/Tooltip';
import compose from './container';

const KmlPanel = compose(({
  classes, activeOrderKml, nonActiveOrdersKmls, activeKmlUrl, mode, shouldShowConfirmDialog, isEstimate, imageryRequested, SetCreateKmlAction, StartKmlDrawAction, CancelDrawAction, DownloadKMLAction, ClearMapAction, ShowUploadKmlDialogAction, ResetBufferStateAction, SetShowKmlToolbarAction, ToggleKmlAction, ResetKmlDrawAction, showBusinessDays }) => {
  const [kmlExpanded, setKmlExpanded] = useState(true);
  const toggleKmlExpanded = () => {
    setKmlExpanded(!kmlExpanded);
  };

  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [onDialogOk, setOnDialogOk] = useState(null);
  const [showConfirmProceedDialog, setShowConfirmProceedDialog] = useState(false);

  const drawKmlClick = () => {
    if (shouldShowConfirmDialog) {
      if (isEstimate) {
        setShowConfirmProceedDialog(true);
      } else {
        setShowConfirmDialog(true);
      }
      setOnDialogOk('startDraw');
    } else {
      SetCreateKmlAction(true);
      SetShowKmlToolbarAction(true);
      StartKmlDrawAction();
      ResetBufferStateAction();
    }
  };

  const uploadKmlClick = (e: React.ChangeEvent<{}>) => {
    if (shouldShowConfirmDialog) {
      setShowConfirmDialog(true);
      setOnDialogOk('showUploadKml');
    } else {
      CancelDrawAction();
      SetShowKmlToolbarAction(false);
      ShowUploadKmlDialogAction();
      ResetBufferStateAction();
    }

    e.stopPropagation();
  };

  const onOk = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setShowConfirmDialog(false);
    setShowConfirmProceedDialog(false);
    ClearMapAction();
    if (onDialogOk === 'startDraw') {
      SetCreateKmlAction(true);
      SetShowKmlToolbarAction(true);
      StartKmlDrawAction();
    } else if (onDialogOk === 'showUploadKml') {
      CancelDrawAction();
      SetShowKmlToolbarAction(false);
      ShowUploadKmlDialogAction();
    }
    ResetBufferStateAction();
    e.stopPropagation();
  };

  const onCancel = () => {
    setShowConfirmDialog(false);
  };

  const KmlDownloadClick =
  async () => {
    await DownloadKMLAction(activeOrderKml.id, activeOrderKml.name);
   }

  let tabValue: boolean | 'draw' | 'upload' = false;
  if (mode && mode.name === 'draw_polygon') {
    tabValue = 'draw';
  }
  if (showConfirmDialog) {
    tabValue = 'upload';
  }

  return (
    <>
      <ListItem button className={classes.expansionPanelHeader}>
        <Typography variant="h5">PROCESSING AREA</Typography>
        {activeKmlUrl && (
          <IconButton
            aria-label="Download .kml"
            className={classes.button}
            onClick={KmlDownloadClick}
          >
            <img src={downloadIcon} alt="Download .kml" />
          </IconButton>
        )}
        <IconButton aria-label={kmlExpanded ? 'Collapse' : 'Expand'} className={classes.button} onClick={toggleKmlExpanded}>
          {kmlExpanded
            ? <KeyboardArrowDownIcon fontSize="small" />
            : <KeyboardArrowRightIcon fontSize="small" />}
        </IconButton>
      </ListItem>
      <Collapse in={kmlExpanded}>
        <div className={classes.kmlFiles}>
          {nonActiveOrdersKmls.map(kml => (
            <>
              <Typography variant="body2" className={classes.kmlHideButton}>{kml.name}</Typography>
              {/* Temporarily remove show/hide kml functionality */}
              {/* <IconButton className={classes.kmlHideButton} onClick={() => ToggleKmlAction(kml.id)}>
                {kml.visible ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton> */}
            </>
          ))}
          {activeOrderKml ?
            (
              <>
                <Typography variant="body2" className={classes.kmlHideButton}>{activeOrderKml.name}</Typography>
                {activeOrderKml.isEstimate && showBusinessDays ? (
                  <>
                    {
                      activeOrderKml.deliveryDays > 20 ? (
                        <div className={classes.businessDays}>
                          <Typography variant="body2" className={classes.businessDaysText}>
                            This project is eligible for super expedited delivery
                          </Typography>
                          <WideLightTooltip
                            placement="bottom"
                            interactive
                            title={(
                              <div>
                                Need this back super fast? Our team can provide a custom delivery date for this project. Reach out to
                                {' '}
                                <a href="mailto:sales@airworks.io">sales@airworks.io</a>
                                {' '}
                                to get a quote.
                              </div>
                            )}
                          >
                            <HelpIcon className={classes.helpIcon} />
                          </WideLightTooltip>
                        </div>
                      ) : (
                        <div className={classes.businessDays}>
                          <Typography variant="body2" className={classes.businessDaysText}>
                            {activeOrderKml.deliveryDays}
                            {' '}
                            Businesss Days
                          </Typography>
                          <WideLightTooltip
                            placement="bottom"
                            interactive
                            title={(
                              <div>
                                The actual delivery date of your project is subject to change at the time you finalize your order.
                              </div>
                            )}
                          >
                            <HelpIcon className={classes.helpIcon} />
                          </WideLightTooltip>
                        </div>
                      )
                    }
                  </>
                ) : null}
              </>
            )
            : null}
        </div>
        <div className={classes.kmlTabsWrapper}>
          <Tabs
            variant="fullWidth"
            indicatorColor="primary"
            value={tabValue}
            onChange={(event, v) => {
              if (v === 'draw') drawKmlClick();
              if (v === 'upload') uploadKmlClick(event);
            }}
          >
            <Tab
              icon={<GestureIcon />}
              label="Draw"
              value="draw"
              disabled={imageryRequested}
              classes={{ root: classes.kmlTabRoot, wrapper: classes.kmlTabWrapper }}
            />
            <Tab
              icon={<SVG src={uploadKmlIcon} className={classes.tabIcon} />}
              value="upload"
              label="Upload"
              disabled={imageryRequested}
              classes={{ root: classes.kmlTabRoot, wrapper: classes.kmlTabWrapper }}
            />
          </Tabs>
        </div>
      </Collapse>
      <ConfirmDialog
        showDialog={showConfirmDialog}
        onOk={onOk}
        onCancel={onCancel}
        contentText="Current .kml file will be removed"
      />
      <ConfirmProceedDialog
        title="Deleting the KML will modify your saved estimate."
        contentText="Are you sure you want to proceed?"
        showDialog={showConfirmProceedDialog}
        cancelButtonText="CANCEL"
        okButtonText="PROCEED"
        onOk={onOk}
        onCancel={() => setShowConfirmProceedDialog(false)}
      />
    </>
  );
});

export default KmlPanel;
