import compose from 'recompose/compose';
import { withStyles, WithStyles } from '@material-ui/core';

import { CancelSubscription } from 'State/subscription/thunk';
import { connect } from 'react-redux';
import styles from './styles';

interface IOwnProps {
  showCancelSubscriptionDialog: boolean;
  handleClose: () => void;
}

const mapStateToProps = (state: IState) => ({
  cadSubscription: state.subscription.cadSubscription,
});

const mapDispatchToProps = {
  CancelSubscriptionAction: CancelSubscription,
};

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
