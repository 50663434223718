// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

const styles = (theme) => ({
  pageWrapper: {
    width: 600,
    margin: '0 auto',
  },
  margin: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
  },
  list: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridRowGap: theme.spacing(2),
    alignItems: 'center',
  },
  acreageList: {
    gridRowGap: 0,
    marginBottom: theme.spacing(),
  },
  progressBar: {
    marginBottom: theme.spacing(3),
  },
  bar2: {
    backgroundColor: theme.palette.secondary.main,
  },
  dashedBar: {
    animation: 'none',
    backgroundColor: theme.palette.lightGrey,
    backgroundImage: 'none',
  },
  alignRight: {
    justifySelf: 'right',
  },
  title: {
    marginBottom: theme.spacing(3),
  },
  divider: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    height: 2,
  },
});

export default styles;
