// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import { Dialog, Slide } from '@material-ui/core';
import OrderForm from './OrderForm';
import compose from './container';

const Transition = React.forwardRef<unknown, TransitionProps>((props, ref) => <Slide ref={ref} direction="up" {...props} />);

const OrderDialog = compose(({ classes, orderFormVisible, setOrderFormVisible }) => (
  <Dialog
    fullScreen
    open={orderFormVisible}
    TransitionComponent={Transition}
  >
    <OrderForm {...{ orderFormVisible, setOrderFormVisible }}/>
  </Dialog>
));

export default OrderDialog;
