// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Layer, Source } from 'react-mapbox-gl';
import { GetRasterTilePbfUrl } from 'Config';
import compose from './container';

/**
 * Component renders the tif files from multiple sources onto one layer inside the mapbox component
 * @param {object} props Component props
 * @param {object} props.classes Injected type props
 * @param {object} props.rasterTileJson Object containing metadata for all TIF files in a project
 * @param {string} props.orgId String to indicate Org ID
 * @param {string} props.projectId String to indicate Project ID
 * @param {string} props.tifFileNames Array of names of TIF files in project
 * @param {string} props.visible Boolean to show raster tiles on the map for project details page and the output page
 */
const MapLayers = compose(({ rasterTileJson, orgId, projectId, showLayers, tifFileNames, visible }) => {
  const fileNames = tifFileNames.map((tifName) => {
    const fileName = tifName.split('/').pop();
    const tifFileName = fileName.substring(0, fileName.lastIndexOf('.'));
    return tifFileName;
  });
  let rasterReady = 0;
  // Check if every file has corresponding bounds
  fileNames.forEach((fileName) => {
    if (rasterTileJson && rasterTileJson[fileName] && rasterTileJson[fileName].bounds) {
      rasterReady += 1;
    }
  });

  // Generate Raster layer with multiple TIF file sources
  const RasterLayer = (params: {fileName: string}) => {
    const sourceId = `${params.fileName}-raster_source`;
    const layerId = `${params.fileName}-raster_layer`;
    return (
      <>
        <Source
          id={sourceId}
          tileJsonSource={{
            type: 'raster',
            scheme: rasterTileJson[params.fileName].isTms ? 'tms' : 'xyz',
            tileSize: 256,
            bounds: rasterTileJson[params.fileName].bounds,
            tiles: [GetRasterTilePbfUrl(orgId, projectId, params.fileName, rasterTileJson[params.fileName].isTms)]
          }}
        />
        <Layer
          before="rasterStub"
          type="raster"
          id={layerId}
          sourceId={sourceId}
          layout={{
            visibility: 'visible',
          }}
        />
      </>
    );
  };
  return (
    <>
      {/* Layers order is important so we need to always add tiles layers before draw layers */}
      <Layer id="rasterStub" />
      {(rasterReady === fileNames.length) && visible && fileNames.map((fileName) => <RasterLayer fileName={fileName} />)}
    </>
  );
});

export default MapLayers;
