// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getOrders } from 'State/order/selectors';

const mapStateToProps = (state: IState) => ({
  bundleName: state.bundle.selectedBundle?.name,
  bundleId: state.bundle.selectedBundle?.bundleId,
  additionalDeliverables: state.bundle.additionalDeliverables,
  selectedAdditionals: state.bundle.selectedAdditionals,
  order: getOrders(state).activeOrder,
});

interface IOwnProps {
  onAddOnsClick: (item: IDeliverable, checked: boolean) => void;
}

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(mapStateToProps),
);
