// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import createImmerReducer from 'State/createImmerReducer';
import { InitAsync, LoginAsync, LogoutAction, OktaAdminLoginAsync, OnAcceptAsync, SendForgotPasswordLink } from './actions';

const initialState: IAuthState = {
  token: null,
  tokenExpires: null,
  user: null,
  html: null,
  resources: null,
  planDetails: null,
  failedLoginAttempts: 0,
  forgotPasswordLinkSend: false,
};

export default createImmerReducer(initialState, (handleAction) => {
  handleAction(InitAsync.success, (draft, payload) => {
    draft.user = payload.user;
    draft.token = payload.token;
    draft.tokenExpires = payload.tokenExpires;
    draft.html = payload.html;
    draft.resources = payload.resources;
    draft.failedLoginAttempts = payload.failedLoginAttempts;
  });

  handleAction(InitAsync.failure, (draft, payload) => {
    draft.failedLoginAttempts = payload;
  });

  handleAction(LoginAsync.success, (draft, payload) => {
    draft.user = payload.user;
    draft.token = payload.token;
    draft.tokenExpires = payload.tokenExpires;
    draft.resources = payload.resources;
    draft.planDetails = payload.planDetails;
    draft.failedLoginAttempts = 0;
  });

  handleAction(LoginAsync.failure, (draft, payload) => {
    draft.failedLoginAttempts = payload;
  });

  handleAction(OktaAdminLoginAsync.success, (draft, payload) => {
    draft.user = payload.user;
    draft.token = payload.token;
    draft.tokenExpires = payload.tokenExpires;
    draft.resources = payload.resources;
  });

  handleAction(OnAcceptAsync.success, (draft, payload) => {
    draft.user = payload.user;
    draft.html = null;
  });

  handleAction(LogoutAction, (draft) => {
    draft.user = null;
    draft.token = null;
  });

  handleAction(SendForgotPasswordLink, (draft, payload) => {
    draft.forgotPasswordLinkSend = payload;
  });
});
