// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

/* eslint-disable import/prefer-default-export */
import { createSelector } from 'reselect';
import { getOrders as ordersSelector } from 'State/order/selectors';

const getOrders = (state: IState) => ordersSelector(state);
const getProject = (state: IState) => state.project.project;
const getKmls = (state: IState) => state.kml.present.kmls;

const getFeatureCollection = createSelector(
  [getOrders, getProject, getKmls],
  (orders, project, kmls) => {
    const activeOrderId = orders.activeOrder._id;

    const collection: GeoJSON.FeatureCollection<GeoJSON.Polygon> = {
      type: 'FeatureCollection',
      features: [],
    };

    orders
      .projectOrders
      .forEach((order) => {
        // if active order has no kml, don't try to get it's feature
        if (!kmls[order._id]) return;
        const { features } = kmls[order._id].featureCollection;
        if (!features.length) return;

        features.forEach((feature: any) => {
          if (order._id !== activeOrderId) feature.properties.disabled = true;
          if (order._id === activeOrderId && ((order.isEstimate && !!(project?.imageryRequest?.requested)) || !!(project?.imageryRequest?.uploaded) || !!(project?.imageryRequest?.notificationSent))) feature.properties.disabled = true;
          collection.features.push(feature);
        });
      });

    return collection;
  },
);

export { getFeatureCollection };
