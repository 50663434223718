// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAsyncAction } from 'typesafe-actions';

export const ListInvoicesAsync = createAsyncAction(
  'LIST_INVOICES_START',
  'LIST_INVOICES_SUCCESS',
  'LIST_INVOICES_FAILED',
)<null, IInvoice[], string>();

export const GetInvoiceAsync = createAsyncAction(
  'GET_INVOICE_START',
  'GET_INVOICE_SUCCESS',
  'GET_INVOICE_FAILED',
)<null, IInvoice, string>();
