// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';

interface IOwnProps {
  loading: boolean;
}

export type PropsType =
  IOwnProps;

export default compose<PropsType, IOwnProps>(
);
