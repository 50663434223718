// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => createStyles({
  scrollWrapper: {
    overflow: 'auto',
    flex: 1,
  },
  wrapper: {
    width: 1000,
    marginTop: theme.spacing(7),
    marginBottom: theme.spacing(7),
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  description: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'last baseline',
  },
  divider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  ordersHeader: {
    marginBottom: theme.spacing(3),
  },
  inputControls: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    gridColumnGap: theme.spacing(3),
    alignItems: 'center',
  },
  files: {
    display: 'grid',
    gridTemplateColumns: '1fr auto auto auto',
    gridColumnGap: theme.spacing(2),
    alignItems: 'center',
  },
  cancelButton: {
    padding: theme.spacing(0.5),
    fontSize: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -8,
    marginLeft: -8,
  },
  downloadButton: {
    fontSize: theme.spacing(2),
  },
  dropdownMap: {
    height: '50px',
  },
  mapWrapper: {
    flex: 1,
    position: 'relative',
    height: '500px',
    width: '100%',
  },
  statusText: {
    padding: '9px',
  },
  select: {
    fontWeight: 300,
  },
  fontWeight: {
    fontWeight: 300,
  },
  errorIcon: {
    color: 'red',
    fontSize: '20px',
  },
}));

export default useStyles;
