// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Paper, Button, Typography } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import CloseIcon from '@material-ui/icons/Close';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const ErrorPaper = ({ errorsExist, kmlError, fileStatus, loadingHighResTiles, lasTileLoading, tile3DError, setFileErrors }: PropsType) => {
  const classes = useStyles();
  const { viewer3D } = useFlags();
  const [expandList, setExpandList] = useState(false);
  const [showFileError, setShowFileError] = useState(true);
  const [showTileWarning, setShowTileWarning] = useState(true);
  const [showTileError, setShowTileError] = useState(true);

  useEffect(() => {
    if (errorsExist) {
      setFileErrors(true);
    }
  }, [errorsExist]);

  return (
    <div className={classes.errorStacks}>
      {loadingHighResTiles && (
        <Paper elevation={1} square className={classes.errorPaper}>
          <div className={classes.tileLoadingInfo}>
            <ErrorIcon className={classes.loadingIcon} />
            <div>
              <Typography className={classes.loadingInfoText} variant="body1">
                We’re still working on rendering the full details of your orthomosaic.
              </Typography>
              <Typography className={classes.loadingInfoText} variant="body1">
                Please draw or upload your KML to continue.
              </Typography>
            </div>
          </div>
        </Paper>
      )}
      {errorsExist && showFileError && (
        <Paper elevation={1} square className={classes.errorPaper}>
          <CloseIcon className={classes.closeIcon} onClick={() => setShowFileError(false)} />
          <div className={classes.errorControls}>
            <ErrorIcon className={classes.errorIcon} />
            <Typography variant="h3">
              An error has occured
            </Typography>
            <div />
            <Button onClick={() => setExpandList(!expandList)}>
              {expandList ? 'HIDE' : 'SHOW'}
            </Button>
          </div>
          {expandList && (
            <div className={classes.errorList}>
              {
                kmlError ? (
                  <Typography variant="body1">
                    We're unable to properly render your KML. Please try uploading a single Polygon or LineString KML.
                  </Typography>
                ) : (
                  <>
                    {(fileStatus && Object.keys(fileStatus).length > 0) && (
                      Object.keys(fileStatus).map((file) => {
                        if (fileStatus[file].status === 'ERROR') {
                          return (
                            <>
                              {fileStatus[file].messages.map((message) => (
                                <Typography variant="body1">
                                  {`Error found in file ${file}:  ${message}`}
                                  <br />
                                </Typography>
                              ))}
                            </>
                          );
                        }
                      })
                    )}
                  </>
                )
              }
            </div>
          )}
        </Paper>
      )}
      {viewer3D && tile3DError && showTileError && (
        <Paper elevation={1} square className={classes.errorPaper}>
          <div className={classes.errorControls}>
            <ErrorIcon className={classes.errorIcon} />
            <Typography variant="h3">
              We are not able to render your point cloud. Please attempt uploading another file or contact
              {' '}
              <a href="mailto:support@airworks.io" className={classes.link}>support@airworks.io</a>
              {' '}
              for us to troubleshoot further.
            </Typography>
          </div>
          <CloseIcon className={classes.closeIcon} onClick={() => setShowTileError(false)} />
        </Paper>
      )}
      {viewer3D && !errorsExist && !tile3DError && lasTileLoading && showTileWarning && (
        <Paper elevation={1} square className={classes.errorPaper}>
          <div className={classes.errorControls}>
            <ErrorIcon className={classes.warningIcon} />
            <Typography variant="h3">
              We’re still working on rendering the full details of your point cloud.
              Please check back in a couple of minutes.
            </Typography>
          </div>
          <CloseIcon className={classes.closeIcon} onClick={() => setShowTileWarning(false)} />
        </Paper>
      )}
    </div>
  );
};

export default compose(ErrorPaper);
