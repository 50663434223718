// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withStyles, WithStyles } from '@material-ui/core';

import { GoToSearchResult, ClearMarker } from 'State/map/common/thunk';
import { MapSearch, MapClearSuggestions } from 'State/map/geocode/thunk';

import styles from './styles';

const mapStateToProps = (state: IState) => ({
  suggestions: state.map.geocode.mapSuggestions || [],
});

const mapDispatchToProps = {
  MapSearchAction: MapSearch,
  MapClearSuggestionsAction: MapClearSuggestions,
  GoToSearchResultAction: GoToSearchResult,
  ClearMarkerAction: ClearMarker,
};

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
