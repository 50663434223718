// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { createAction } from 'typesafe-actions';

export const ShowDialogAction = createAction('SHOW_TOKEN_EXPIRED_DIALOG', (action) => () => action());

export const HideDialogAction = createAction('HIDE_TOKEN_EXPIRED_DIALOG', (action) => () => action());
