// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import classnames from 'classnames';
import CreditCardInfo from './CreditCardInfo';
import EditCardForm from './EditCardForm';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const CreditCard = ({ card }: PropsType) => {
  const [showEditCard, setShowEditCard] = useState(false);
  const classes = useStyles();

  return (
    <div className={classnames(classes.wrapper, { [classes.primary]: card.defaultCard })}>
      {!showEditCard && <CreditCardInfo card={card} onEditClick={() => setShowEditCard(true)} />}
      {showEditCard && <EditCardForm card={card} onCancel={() => setShowEditCard(false)} />}
    </div>
  );
};

export default compose(CreditCard);
