// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState } from 'react';
import { Dialog, DialogActions, Typography, Button, RadioGroup, FormControlLabel, Radio, FormGroup, TextField } from '@material-ui/core/';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const ConvertDialog = ({ showDialog, onCloseModal, handleConvert, orderId, InitializeTrainingProject }: PropsType) => {
  const classes = useStyles();
  const [projectName, setProjectName] = useState(null);
  const [dxfLink, setDxfLink] = useState(null);
  const [tifLink, setTifLink] = useState(null);
  const [kmlLink, setKmlLink] = useState(null);
  const [option, setOption] = useState('tif');

  const changeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOption(event.target.value);
  };

  const onClose = () => {
    setProjectName(null);
    setDxfLink(null);
    setTifLink(null);
    setKmlLink(null);
    setKmlLink('tif');
    onCloseModal();
  };

  const initializeTrainingProject = async () => {
    await InitializeTrainingProject(orderId, projectName, dxfLink, tifLink, kmlLink);
    handleConvert();
    onClose();
  };

  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
      classes={{
        paperWidthSm: classes.dialog,
      }}
    >
      <Typography variant="h2">Initialize a Training Project to TDP</Typography>
      <TextField
        label="Provide a TDP Name for this project (Optional)"
        name="projectName"
        onChange={(e) => { setProjectName(e.target.value); }}
      />
      <TextField
        label="Link to an AirWords Standard DXF (Dropbox Link)"
        name="dxf"
        required
        onChange={(e) => { setDxfLink(e.target.value); }}
      />

      <Typography variant="h5">
        Choose one one of the following
      </Typography>
      <FormGroup>
        <RadioGroup aria-label="format" name="format" value={option} onChange={changeOption}>
          <FormControlLabel value="tif" control={<Radio color="primary" />} label="I have a TIF clipped to the DXF bounds for this project" />
          <FormControlLabel value="kml" control={<Radio color="primary" />} label="I have an unclipped TIF and KML of DXF bounds for this project" />
        </RadioGroup>
      </FormGroup>
      <TextField
        label={`Link to an ${option === 'tif' ? 'Clipped' : 'unclipped'} TIF (Dropbox Share Links only)`}
        name="tif"
        required
        onChange={(e) => { setTifLink(e.target.value); }}
      />
      {option === 'kml' && (
        <TextField
          label="Link to an unclipped KML (Dropbox Share Links only)"
          name="kml"
          required
          onChange={(e) => { setKmlLink(e.target.value); }}
        />
      )}
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          onClick={initializeTrainingProject}
          color="primary"
          variant="contained"
          disabled={!(dxfLink && tifLink && (option === 'kml' ? kmlLink : true))}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default compose(ConvertDialog);
