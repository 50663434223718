// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { WithStyles, withStyles } from '@material-ui/core';
import styles from './styles';

const mapStateToProps = (state: IState) => ({
  bundleName: state.bundle.selectedBundle?.name,
  bundleDeliverables: state.bundle.bundleDeliverables,
});

type PropsType =
  ReturnType<typeof mapStateToProps> &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withStyles(styles),
  connect(mapStateToProps),
);
