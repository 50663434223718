// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useState, useEffect, useCallback } from 'react';
import * as Cesium from 'cesium';
import { Paper, Divider, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { CESIUM_KEY, MAPBOX_KEY } from 'Config';
import compose, { PropsType } from './container';
import useStyles from './styles';
import FileErrors from './FileErrors';

Cesium.Ion.defaultAccessToken = CESIUM_KEY;
const terrain = Cesium.createWorldTerrain();
const mapboxImagery = new Cesium.MapboxImageryProvider({
  mapId: 'mapbox.satellite',
  accessToken: MAPBOX_KEY,
});

export const Map3D = ({ lasFilesWith3DTile, showPointCloud, showTerrain, linework3D, layer3DChange, cad3dLayers }: PropsType) => {
  const classes = useStyles();
  const [viewer, setViewer] = useState(null);
  const [tilesets, setTilesets] = useState(null);
  const [layerDataSource] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    tilesets?.forEach((tileset: any) => {
      tileset.show = showPointCloud;
    });
  }, [showPointCloud]);

  useEffect(() => {
    for (let i = 0; i < layer3DChange.length; i += 2) {
      const name = layer3DChange[i];
      if (layerDataSource[name]) {
        layerDataSource[name].show = layer3DChange[i + 1];
      }
    }
  }, [layer3DChange]);

  useEffect(() => {
    if (viewer) {
      if (showTerrain) {
        viewer.scene.globe.show = true;
        viewer.terrainProvider = terrain;
      } else {
        viewer.scene.globe.show = false;
      }
      if (tilesets?.length) {
        viewer.zoomTo(
          tilesets[0],
          new Cesium.HeadingPitchRange(
            0.0,
            -0.5,
            tilesets[0].boundingSphere.radius * 2.0,
          ),
        );
      }
    }
  }, [showTerrain]);

  useEffect(() => {
    if (viewer) {
      linework3D.forEach((site) => {
        const { site_id, data } = site;
        data.forEach((layer) => {
          const layerName = `${site_id}-${layer.layer_id}`;
          if (!layerDataSource[layerName]) {
            const lineCollection = new Cesium.PolylineCollection();
            viewer.scene.primitives.add(lineCollection);
            layer.offset.forEach((line) => {
              if (line.length % 3 === 0) {
                lineCollection.add({
                  positions: Cesium.Cartesian3.fromDegreesArrayHeights(line),
                  width: 2,
                  material: Cesium.Material.fromType('Color', {
                    color: Cesium.Color.fromCssColorString(cad3dLayers[site_id]?.layers?.find((l) => l.layer_id === layer.layer_id)?.color || '#ffffff'),
                  }),
                });
              }
            });
            layerDataSource[layerName] = lineCollection;
          }
        });
      });
    }
  }, [linework3D, viewer]);

  useEffect(() => {
    // Add 3D tile
    if (!tilesets && viewer) {
      const tilesetPromises: any[] = [];
      setTilesets(lasFilesWith3DTile.map((lasFile) => {
        // eslint-disable-next-line no-restricted-globals
        const url = isNaN(lasFile.tile3DUrl) ? lasFile.tile3DUrl : Cesium.IonResource.fromAssetId(+lasFile.tile3DUrl);
        const newTileset = new Cesium.Cesium3DTileset({
          url,
        });
        tilesetPromises.push(newTileset.readyPromise);
        viewer.scene.primitives.add(newTileset);
        return newTileset;
      }));
      Promise.all(tilesetPromises).then((result) => {
        for (let i = 0; i < result.length; i += 1) {
          // Using cesiumOffsetZ value to adjust 3D tile so the 3D tile is right on the terrain
          const cartographic = new (Cesium.Cartographic.fromCartesian as any)(result[i].boundingSphere.center);
          const surface = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, 0.0);
          const offset = Cesium.Cartesian3.fromRadians(cartographic.longitude, cartographic.latitude, lasFilesWith3DTile[i].cesiumOffsetZ || 0);
          const translation = Cesium.Cartesian3.subtract(offset, surface, new Cesium.Cartesian3());
          const matrix = Cesium.Matrix4.fromTranslation(translation);
          result[i].modelMatrix = matrix;
        }
        // Zoom to the first tile
        viewer.zoomTo(
          result[0],
          new Cesium.HeadingPitchRange(
            0.0,
            -0.5,
            result[0].boundingSphere.radius * 2.0,
          ),
        );
      }).catch(() => {
        // To do: show some error message - Piaotian 1/27/2023
      });
    }
  }, [lasFilesWith3DTile, viewer]);

  const mapRef = useCallback((node) => {
    if (node !== null) {
      const newViewer = new Cesium.Viewer(node, {
        terrainProvider: terrain,
        animation: false,
        baseLayerPicker: false,
        fullscreenButton: false,
        geocoder: false,
        homeButton: false,
        infoBox: false,
        sceneModePicker: false,
        selectionIndicator: false,
        timeline: false,
        navigationHelpButton: false,
        imageryProvider: mapboxImagery,
      });
      newViewer.scene.globe.show = false;
      newViewer.scene.skyAtmosphere.destroy();
      newViewer.scene.skyBox.destroy();
      newViewer.scene.sun.destroy();
      newViewer.scene.globe.baseColor = Cesium.Color.BLACK;
      newViewer.scene.screenSpaceCameraController.minimumZoomDistance = 5;
      setViewer(newViewer);
    }
  }, []);

  const zoomIn = () => {
    viewer.camera.zoomIn(30);
  };

  const zoomOut = () => {
    viewer.camera.zoomOut(30);
  };

  return (
    <>
      <FileErrors />
      <div className={classes.map} ref={mapRef} />
      <Paper elevation={1} square className={classes.mapControls}>
        <Button className={classes.mapButton} onClick={zoomIn}>
          <AddIcon />
        </Button>
        <Divider className={classes.divider} />
        <Button className={classes.mapButton} onClick={zoomOut}>
          <RemoveIcon />
        </Button>
      </Paper>
    </>
  );
};

export default compose(Map3D);
