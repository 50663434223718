// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { getProjectFiles } from 'State/project/selectors';

interface IOwnProps {
    when?: boolean | undefined;
    navigate: (path: string) => void;
    shouldBlockNavigation: (location: Location) => boolean;
    onSaveChanges: () => Promise<void>;
  }

export type PropsType =
    IOwnProps;

export default compose<PropsType, IOwnProps>(
  connect(null, null),
);
