// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect } from 'react';
import compose, { PropsType } from './container';
import ProjectsTable from './ProjectsTable';
import useStyles from './styles';

export const AccountProjects = ({ orgId, SetOrgIdAction, GetTemplatesAction, GetOrgTeamsAction, GetOrgAction, GetProjectsAction }: PropsType) => {
  const classes = useStyles();

  useEffect(() => {
    SetOrgIdAction(orgId);
    const loadData = async () => {
      await GetProjectsAction(orgId);
      await GetOrgAction(orgId);
      await GetOrgTeamsAction(orgId);
      await GetTemplatesAction();
    };
    loadData();
  }, [orgId]);

  return (
    <>
      <ProjectsTable {...{ orgId }} />
    </>
  );
};

export default compose(AccountProjects);
