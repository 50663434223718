// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { IsAccountPath } from 'Utils/routes';

import { Logout } from 'State/auth/thunk';

const mapStateToProps = (state: IState, pr: RouteComponentProps) => {
  const accountMatch = IsAccountPath(pr.history.location.pathname);

  return {
    billing: !_ADMIN_ && 'billing' in state.auth.resources,
    cadSubscription: state.subscription.cadSubscription,
    email: state.auth?.user?.email,
    accountMatch,
  };
};

const mapDispatchToProps = {
  LogoutAction: Logout,
};

export type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  RouteComponentProps;

export default compose<PropsType, {}>(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
);
