// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React, { useEffect, useState } from 'react';
import { Dialog, Typography, RadioGroup, Radio, FormControl, Button, FormControlLabel, LinearProgress, DialogActions, MenuItem, Select, InputBase } from '@material-ui/core/';
import Dropzone from 'Common/dropzone';
import uploadKmlIcon from 'Assets/upload-kml-icon.svg';
import SVG from 'react-inlinesvg';
import compose from './container';

const SingleFileUploadForm = compose(({
  classes, showDialog, file, containerId, fileExtension, adminOutputUpload, expressAIUpload, epsg, epsgList, FileUploadStartAction, SetExpressAIUploadAction, HideUploadKmlDialogAction, SetDxfEpsgAction }) => {
  const [page, setPage] = useState(0);
  const [dxfFile, setDxfFile] = useState(null);

  useEffect(() => {
    HideUploadKmlDialogAction();
    SetDxfEpsgAction(epsgList[0]);
  }, []);

  const onUploadClick = async () => {
    await FileUploadStartAction([dxfFile]);
    onClose();
  };

  const onClose = () => {
    setDxfFile(null);
    setPage(0);
    HideUploadKmlDialogAction();
  };

  const goToNextPage = () => {
    if (expressAIUpload) {
      setPage(2);
    } else {
      setPage(1);
    }
  };

  return (
    <Dialog
      open={showDialog}
      onClose={onClose}
      BackdropProps={{
        classes: {
          root: classes.backdrop,
        },
      }}
      classes={{
        root: classes.dialogRoot,
        paper: classes.dialog,
        paperFullScreen: classes.paperFullscreen,
      }}
      fullScreen={!containerId}
      container={() => document.getElementById(containerId)}
    >
      <div className={classes.dropzoneWrapper}>
        {(adminOutputUpload && page === 0) && (
          <div className={classes.expressAIOptions}>
            <Typography variant="h5" className={classes.dialogText}>Please indicate whether this file is Express AI:</Typography>
            <FormControl>
              <RadioGroup
                name="radio-buttons-group"
                value={expressAIUpload}
                onChange={SetExpressAIUploadAction}
              >
                <FormControlLabel className={classes.radioButton} value control={<Radio />} label="Yes, it is Express AI" />
                <FormControlLabel className={classes.radioButton} value={false} control={<Radio />} label="No" />
              </RadioGroup>
            </FormControl>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={onClose} variant="text">Close</Button>
              <Button onClick={goToNextPage} variant="contained" color="primary">Continue</Button>
            </DialogActions>
          </div>
        )}
        {(adminOutputUpload && page === 1) && (
          <div className={classes.expressAIOptions}>
            <Typography variant="h5" className={classes.dialogText}>Select EPSG Code</Typography>
            <Typography variant="body1">
              Please select below the
              {' '}
              <span className={classes.bold}>
                EPSG
              </span>
              {' '}
              code for your file(s).
            </Typography>
            <FormControl>
              <Select
                variant="outlined"
                input={<InputBase />}
                className={classes.dropdown}
                value={epsg}
                onChange={(e) => {
                  if (e.target.value) {
                    SetDxfEpsgAction(e.target.value);
                  }
                }}
              >
                {epsgList.map((item: number) => (
                  <MenuItem key={item} value={item}>
                    EPSG:
                    {' '}
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={onClose} variant="text">Close</Button>
              <Button onClick={() => setPage(2)} variant="contained" color="primary">Upload Now</Button>
            </DialogActions>
          </div>
        )}
        {(page === 2 || !adminOutputUpload) && (
          <>
            <Dropzone
              acceptedExtensions={[fileExtension]}
              onDropAccepted={async (acceptedFilesArray) => {
                const f = acceptedFilesArray[0];
                const filetype = f.name.substr(f.name.lastIndexOf('.') + 1);
                if (filetype === 'dxf' && expressAIUpload) {
                  setDxfFile(f);
                } else {
                  await FileUploadStartAction([f]);
                  onClose();
                }
              }}
            >
              {(getInputProps, open) => (
                <>
                  <div className={classes.uploadIconWrapper}>
                    <SVG src={uploadKmlIcon} title="Upload" className={classes.uploadFormIcon} />
                  </div>
                  <br />
                  <input {...getInputProps()} />
                  <Typography variant="h5" className={classes.uploadFormText}>
                    Drag and drop to upload
                  </Typography>
                  <br />
                  <Typography variant="caption" className={classes.uploadFormText}>
                    {'or '}
                    <button type="button" className={classes.openFileDialogLink} onClick={open}>browse</button>
                    {' to choose a file'}
                  </Typography>
                </>
              )}
            </Dropzone>
            {file && (
              <div className={classes.progressRow}>
                <div>
                  <Typography variant="body2">{file.name}</Typography>
                </div>
                <div className={classes.progress}>
                  <LinearProgress
                    color="primary"
                    variant="determinate"
                    value={file.progress}
                  />
                </div>
              </div>
            )}
            {(dxfFile && !file) && (
              <div className={classes.dxfFile}>
                <Typography variant="body2">{dxfFile.name}</Typography>
              </div>
            )}
            {expressAIUpload && adminOutputUpload && (
              <>
                <DialogActions className={classes.dialogActions}>
                  <Button onClick={onClose} variant="text">Close</Button>
                  <Button onClick={onUploadClick} variant="contained" color="primary">Upload Now</Button>
                </DialogActions>
              </>
            )}
          </>
        )}
      </div>
    </Dialog>
  );
});

export default SingleFileUploadForm;
