// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Link,} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import compose from './container';

// This component renders the expired token page
const ExpiredToken = compose(({ classes }) => (
  <div>
    <Typography className={classes.header} variant="h1">
      Your link has expired
    </Typography>
    <Typography className={classes.details} variant="h5">
      We're sorry the link we sent you has now expired. Sign up again to get a new link sent to your inbox.
      {' '}
      {' '}
    </Typography>
    <Typography className={classes.details} variant="h5">
      <Link component={RouterLink} className={classes.forgotText} to="/">
        Sign up again
      </Link>
    </Typography>
  </div>
));

export default ExpiredToken;
