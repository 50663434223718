// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Form } from 'react-final-form';
import { Button, CircularProgress, Fade, Collapse, Typography } from '@material-ui/core';
import { TextField, Helpers } from 'Common/forms';
import compose from './container';

const PromoForm = compose(({ classes, cadSeats, CheckPromoCodeAction }) => {
  const onFormSubmit = (values: any) => Helpers.submit({ code: values.promoCode, quantity: cadSeats }, CheckPromoCodeAction);

  return (
    <Form
      onSubmit={onFormSubmit}
      render={({ hasSubmitErrors, modifiedSinceLastSubmit, submitting, pristine, submitError, handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
        >
          <div className={classes.promoCodeForm}>
            <div>
              <TextField
                name="promoCode"
                placeholder="Enter Promo Code"
                inputProps={{ classes: { input: classes.promoField } }}
              />
              <Collapse in={Boolean(hasSubmitErrors && !modifiedSinceLastSubmit)}>
                <Typography className={classes.errorMessage} variant="body1">
                  {submitError}
                </Typography>
              </Collapse>
            </div>
            <Button
              variant="contained"
              color="primary"
              className={classes.submitButton}
              type="submit"
              disabled={pristine || (hasSubmitErrors && !modifiedSinceLastSubmit) || submitting}
            >
              <Fade in={submitting}>
                <CircularProgress size={24} className={classes.buttonProgress} />
              </Fade>
              <Fade in={!submitting}>
                <span>Apply</span>
              </Fade>
            </Button>
          </div>
        </form>
      )}
    />
  );
});

export default PromoForm;
