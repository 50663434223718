// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { WithStyles, withStyles } from '@material-ui/core';
import { GetOrgTeams } from 'State/account/thunk';
import { EditOrder } from 'State/order/thunk';
import { getOrders } from 'State/order/selectors';
import styles from './styles';

const mapStateToProps = (state: IState) => {
  const { teams } = state.auth.user;
  const { orgTeams } = state.account;
  const { activeOrder } = getOrders(state);
  const availableTeamNames: string[] = [];

  const { team } = activeOrder;
  function isUserTeam(value: ITeam) {
    if (teams.includes(value._id)) {
      availableTeamNames.push(value.name);
      return value._id;
    }
    return false;
  }
  const availableTeams = orgTeams.filter((t) => isUserTeam(t));
  const currentTeam = availableTeams?.find((t) => t._id === team);

  return {
    teamError: state.account.teamError,
    orderId: getOrders(state).activeOrder?._id,
    referenceId: getOrders(state).activeOrder?.referenceId,
    team: getOrders(state).activeOrder?.team,
    availableTeams,
    availableTeamNames,
    currentTeam,
    activeOrder,
  };
};

const mapDispatchToProps = {
  EditOrderAction: EditOrder,
  GetOrgTeamsAction: GetOrgTeams,
};

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
