import { Dialog, DialogContent, DialogContentText, DialogTitle, Typography, Button } from '@material-ui/core';
import React from 'react';
import compose from 'Common/CreateSubscriptionDialog/SubscriptionForm/CancelSubscription/container';
import moment from 'moment-timezone';

moment.tz.setDefault('America/New_York');

const CancelSubscription = compose(({ classes, handleClose, showCancelSubscriptionDialog, cadSubscription, CancelSubscriptionAction }) => {
  const cancelSubscriptionClick = () => {
    CancelSubscriptionAction(cadSubscription.subscriptionId);
    handleClose();
  };

  return (
    <div className={classes.pageWrapper}>
      <Dialog
        open={showCancelSubscriptionDialog}
        onClose={handleClose}
        maxWidth="md"
        className={classes.dialog}
      >
        <div className={classes.pageWrapper}>
          <DialogTitle>
            <Typography className={classes.optionTitle} variant="h3">
              Are you sure you want to cancel the plan?
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              <Typography variant="h3">COMMITED TERMS</Typography>
              <Typography variant="subtitle2" className={classes.subheading}>
                {cadSubscription.interval}
                {' '}
                subscription term:
                {' '}
                {moment(cadSubscription.cadCurrentPeriodStart).format('LL')}
                {' '}
                -
                {' '}
                {moment(cadSubscription.cadCurrentPeriodEnd).format('LL')}
              </Typography>
              <div className={classes.billingText}>
                <div className={classes.billingSubText}>
                  <Typography variant="subtitle1">
                    {cadSubscription.name}
                    -
                    {' '}
                    {cadSubscription.cadSeatsAvailable}
                    {' '}
                    licenses
                  </Typography>
                  <Typography variant="overline">
                    BILLED &nbsp;
                    {cadSubscription.priceNickname}
                  </Typography>
                </div>
                <div>
                  <Typography variant="subtitle1">
                    $
                    {cadSubscription.planCost}
                  </Typography>
                </div>
              </div>
              <hr />
              <div>
                <Typography variant="h3" className={classes.cancelTextHeading}>
                  Cancellation will be effective at the end of your billing period on &nbsp;
                  <span
                    className={classes.cancelTextEndDate}
                  >
                    {moment(cadSubscription.cadCurrentPeriodEnd).format('LL')}

                  </span>
                </Typography>
                <Typography className={classes.planText}>Cancelling your plan means:</Typography>
                <ul>
                  <li>
                    <Typography variant="subtitle2">You'll no longer be able to edit any projects</Typography>
                  </li>
                  <li>
                    <Typography variant="subtitle2">
                      You can come back anytime. If you come back within 365 days, your
                      account settings and projects will still be waiting for you.

                    </Typography>
                  </li>
                </ul>
              </div>
              <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.confirmButton}
                  onClick={() => cancelSubscriptionClick()}
                >
                  Confirm cancellation

                </Button>
                <Button variant="text" onClick={() => handleClose()}>Keep Subscription</Button>
              </div>
            </DialogContentText>
          </DialogContent>
        </div>
      </Dialog>
    </div>
  );
});
export default CancelSubscription;
