import { createStyles } from '@material-ui/core';

const styles = () => createStyles({
  pageWrapper: {
    margin: '50px',
    maxWidth: '750px',
  },
  subheading: {
    margin: '8px 0',
    textTransform: 'capitalize',
  },
  billingText: {
    display: 'flex',
    flexDirection: 'row',
    width: 'inherit',
  },
  billingSubText: {
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '100%',
  },
  planText: {
    margin: '16px 0',
  },
  confirmButton: {
    marginRight: '8px',
  },
  cancelTextHeading: {
    width: '80%',
  },
  cancelTextEndDate: {
    fontWeight: 'bolder',
  },
  h6: {
    fontSize: '28px',
  },
  dialog: {
    '& .MuiDialog-paperWidthMd': {
      maxWidth: '750px',
    },
  },
  optionTitle: {
    fontSize: '20px',
    textTransform: 'none',
  },
});

export default styles;
