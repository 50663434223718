// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import compose from 'recompose/compose';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withStyles, WithStyles } from '@material-ui/core/styles';
import { SetShowKmlToolbar } from 'State/project/thunk';
import { SetCreateKmlAction, ResetKmlDrawAction } from 'State/map/draw/actions';
import { Clear, StartKmlDraw, CancelDraw } from 'State/map/draw/thunk';
import { ShowUploadKmlDialog } from 'State/ui/uploadKmlDialog/thunk';
import { ToggleKml, DownloadKML } from 'State/kml/thunk';
import { getOrders } from 'State/order/selectors';
import { ResetBufferStateAction } from 'State/kml/actions';

import styles from './styles';

const mapStateToProps = (state: IState) => {
  const orders = getOrders(state);
  const { nonActiveOrders, activeOrder } = orders;
  let activeOrderKml;
  if (activeOrder.boundaryFile) {
    activeOrderKml = {
      id: activeOrder._id,
      // TODO Don't include the visible property since we don't use it in the component- Vinutna- 08/26/22
      // visible: state.kml.present.kmls[activeOrder._id].visible,
      name: activeOrder.boundaryFile.split('/').pop(),
      isEstimate: activeOrder.isEstimate,
      deliveryDays: activeOrder.deliveryDays,
    };
  }

  const nonActiveOrdersKmls = nonActiveOrders
    .filter((o) => o.boundaryFile)
    .map((o) => {
      const kml = state.kml.present.kmls[o._id];
      return {
        id: o._id,
        // visible: kml.visible,
        name: o.boundaryFile.split('/').pop(),
      };
    });

  const currentProject = state.project.project;
  const imageryRequested = (activeOrder?.isEstimate && !!(currentProject?.imageryRequest?.requested)) || !!(currentProject?.imageryRequest?.uploaded) || !!(currentProject?.imageryRequest?.notificationSent);
  return {
    activeOrderKml,
    nonActiveOrdersKmls,
    activeKmlUrl: orders.activeOrder.boundaryFile,
    shouldShowConfirmDialog: state.kml.present.kmls[orders.activeOrder._id]
      && state.kml.present.kmls[orders.activeOrder._id].featureCollection.features.length > 0,
    mode: state.map.draw.present.mode,
    showKmlDialog: state.ui.uploadKmlDialog.show,
    isEstimate: orders.activeOrder.isEstimate,
    showBusinessDays: state.kml.present.showBusinessDays,
    imageryRequested,
  };
};

const mapDispatchToProps = {
  StartKmlDrawAction: StartKmlDraw,
  ClearMapAction: Clear,
  ShowUploadKmlDialogAction: ShowUploadKmlDialog,
  CancelDrawAction: CancelDraw,
  ToggleKmlAction: ToggleKml,
  DownloadKMLAction: DownloadKML,
  ResetBufferStateAction,
  ResetKmlDrawAction,
  SetShowKmlToolbarAction: SetShowKmlToolbar,
  SetCreateKmlAction,
};

type PropsType =
  ReturnType<typeof mapStateToProps> &
  typeof mapDispatchToProps &
  WithStyles<typeof styles>;

export default compose<PropsType, {}>(
  withRouter,
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps),
);
