// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import { Dispatch } from 'redux';
import { API_URL } from 'Config';
import { getJson, patchJson, postJson, deleteRequest } from 'Utils/http';
import { GetAdminProjectsRoute, GetAdminGeneralRoute, SIGN_UP_SUCCESS } from 'Utils/routes';
import history from 'Utils/history';
import moment from 'moment-timezone';
import { GetOrgStatsAsync } from '../account/actions';
import {
  LoadListAsync,
  SetListAction,
  SetPageAction,
  SetLoadAllAction,
  SetLoadNewAction,
  SetRowsPerPageAction,
  SetSearchFilterAction,
  SetSortFilterAction,
  GetOrgAsync,
  UpdateOrgAsync,
  SetOrgIdAction,
  SetUserIdAction,
  GetOrgUserAsync,
  GetUsersAsync,
  CreateOrgAsync,
  GetLastLoginAsync,
  GetLoginStatsAsync,
  GetSubscriptionAsync,
  UpdateCostPerAcreAction,
  GetOrgProjectsAsync,
  DeleteProjectAdminAsync,
  DeleteOrgAsync,
  OrgEmailsAsync,
} from './actions';

moment.tz.setDefault('America/New_York');

export const SetSearchFilter = (searchFilter: string) => (dispatch: Dispatch) => dispatch(SetSearchFilterAction(searchFilter));
export const SetSortFilter = (sortFilter: string) => (dispatch: Dispatch) => dispatch(SetSortFilterAction(sortFilter));
export const SetPage = (page: number) => (dispatch: Dispatch) => dispatch(SetPageAction(page));
export const SetRowsPerPage = (rowsPerPage: number) => (dispatch: Dispatch) => dispatch(SetRowsPerPageAction(rowsPerPage));

export const SetList = (newData: Array<IAdminUser>) => (dispatch: Dispatch) => dispatch(SetListAction(newData));

export const SetLoadAll = (newData: boolean) => (dispatch: Dispatch) => dispatch(SetLoadAllAction(newData));

export const SetLoadNew = (newData: boolean) => (dispatch: Dispatch) => dispatch(SetLoadNewAction(newData));

export const GetList = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { page, rowsPerPage, searchFilter, sortFilter, loadNew } = getState().admin;
  dispatch(LoadListAsync.request());

  const url = `${API_URL}/admins/dashboard?limit=${rowsPerPage}&page=${page}&query=${searchFilter || ''}&sort=${sortFilter}`;
  const { token } = getState().auth;

  const result = await getJson<IOrgResponse>(url, token);
  if (!result.success) return;

  const data = {
    list: result.data.orgs,
    count: result.data.stats.totalOrgCount,
  };

  if (loadNew) {
    dispatch(SetListAction([]));
  }

  if (data.list.length === 0) {
    dispatch(SetLoadAllAction(true));
  } else {
    dispatch(LoadListAsync.success(data));
  }

  dispatch(SetLoadNewAction(false));
};

export const GetOrgProjects = (orgId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const url = `${API_URL}/admins/getOrgProjects/${orgId}`;
  dispatch(GetOrgProjectsAsync.request());
  const result = await getJson<IAdminProjectsResponse[]>(url, token);
  if (!result.success) {
    dispatch(GetOrgProjectsAsync.failure());
  } else {
    dispatch(GetOrgProjectsAsync.success(result.data.projects));
  }
};

export const DeleteProjectAdmin = (projectId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const { orgId } = getState().admin;
  const url = `${API_URL}/projects/${projectId}`;

  dispatch(DeleteProjectAdminAsync.request());
  const result = await deleteRequest(url, token);
  if (result.success) {
    history.push(GetAdminProjectsRoute(orgId));
    dispatch(DeleteProjectAdminAsync.success(projectId));
  } else {
    dispatch(DeleteProjectAdminAsync.failure());
  }
};

/**
 * Function to call the API to create a new organization
 * and dispatch the CreateOrgAsync action
 */
export const CreateOrg = (data: { firstName: string, lastName: string, phoneNumber: string, companyName: string, email: string; }) => async (dispatch: Dispatch, getState: () => IState) => {
  dispatch(CreateOrgAsync.request());

  const url = `${API_URL}/users/createOrgAdmin`;

  const result = await postJson<IOrgResponse>(url, data);
  if (result.success) {
    dispatch(CreateOrgAsync.success(result.data.organization));
    history.push(
      _ADMIN_
        ? GetAdminGeneralRoute(result.data.organization._id)
        : SIGN_UP_SUCCESS,
    );
  } else {
    dispatch(CreateOrgAsync.failure(result.message));
  }
  return result;
};

// TODO - Create a separate API for admin to fetch subscription details(when there is a change in the shape of data for admin and client)
export const GetOrgSubscription = (orgId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/subscriptions/getOrgActiveSubscription/${orgId}`;
  const { token } = getState().auth;

  dispatch(GetSubscriptionAsync.request());
  const result = await getJson<ISubscriptions>(url, token);

  if (result.success) {
    dispatch(GetSubscriptionAsync.success(result.data));
  } else {
    dispatch(GetSubscriptionAsync.failure(result.message));
  }
  return result;
};

export const GetOrg = (orgId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/admins/getOrgDetails/${orgId}`;
  const { token } = getState().auth;

  const result = await getJson<IOrgResponse>(url, token);
  if (result.success) {
    dispatch(GetOrgAsync.success(result.data.org));
  }

  return result;
};

export const UpdateOrg = (data: IOrg, imageryProvider?: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/organizations/updateOrgDetails/${data._id}`;
  const { token } = getState().auth;

  const result = await patchJson<IOrgResponse>(
    url,
    {
      companyName: data.companyName,
      address: data.address,
      phoneNumber: data.phoneNumber,
      currentContractStart: data.currentContractStart,
      imageryProvider,
    },
    token,
  );
  if (result.success) {
    dispatch(UpdateOrgAsync.success(result.data.org));
  } else {
    dispatch(UpdateOrgAsync.failure(result.message));
  }

  return result;
};

/**
 * Function to call the API to get users in an organization with the orgId
 * and dispatch the GetUsersAsync action
 */
export const GetOrgUsers = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const { token } = getState().auth;
  const { orgId } = getState().admin;
  const url = `${API_URL}/admins/getOrgUsers/${orgId}`;

  dispatch(GetUsersAsync.request());

  const result = await getJson<IUser[]>(url, token);

  if (!result.success) {
    return result;
  }

  if (result.success) {
    dispatch(GetUsersAsync.success(result.data.data));
  }
  return result;
};

/**
 * Function to call the API to get user object for a particular userId
 * and dispatch the GetOrgUserAsync action
 */
export const GetOrgUser = (userId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/users/${userId}`;
  const { token } = getState().auth;

  const result = await getJson<IUser>(url, token);
  if (result.success) {
    dispatch(GetOrgUserAsync.success(result.data));
  }

  return result;
};

export const SetOrgId = (data: string) => (dispatch: Dispatch) =>
  dispatch(SetOrgIdAction(data));

export const SetUserId = (userId: string) => (dispatch: Dispatch) =>
  dispatch(SetUserIdAction(userId));

export const GetLastLogin = (orgId: string) =>
  async (dispatch: Dispatch, getState: () => IState) => {
    const url = `${API_URL}/organizations/getLastLogin/${orgId}`;
    const { token } = getState().auth;

    dispatch(GetLastLoginAsync.request());
    const result = await getJson<{ lastLogin: string }>(url, token);
    if (result.success) {
      const timeString = result.data.lastLogin === 'N/A' ? result.data.lastLogin : moment(result.data.lastLogin).format('MM/DD/YYYY | hh:mm A');
      dispatch(GetLastLoginAsync.success({ lastLogin: timeString }));
    } else {
      dispatch(GetLastLoginAsync.failure(result.message));
    }

    return result;
  };

export const GetLoginStats = (orgId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/organizations/getLoginStats/${orgId}`;
  const { token } = getState().auth;

  dispatch(GetLoginStatsAsync.request());
  const result = await getJson<{ thisMonth: thisMonthStats, perMonth: perMonthStats }>(url, token);
  if (result.success) {
    dispatch(GetLoginStatsAsync.success(result.data));
  } else {
    dispatch(GetLoginStatsAsync.failure(result.message));
  }

  return result;
};

export const GetOrgStats = (id: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/organizations/getOrgStats/${id}`;
  const { token } = getState().auth;

  dispatch(GetOrgStatsAsync.request());

  const result = await getJson<IOrgStats>(url, token);
  if (result.success) {
    dispatch(GetOrgStatsAsync.success(result.data));
  }

  return result;
};

export const UpdateCostPerAcre = (costPerAcre: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/organizations/updateCostPerAcre`;
  const { token } = getState().auth;
  const { orgId } = getState().admin;
  const result = await patchJson<IOrgResponse>(
    url,
    {
      orgId,
      costPerAcre,
    },
    token,
  );
  if (result.success) {
    dispatch(UpdateCostPerAcreAction(result.data.org.costPerAcre));
  }

  return result;
};

export const DeleteOrg = (orgId: string) => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/organizations/removeOrgPII`;
  const { token } = getState().auth;

  const data = { orgId };

  const result = await deleteRequest(url, token, data);

  if (result.success) {
    dispatch(DeleteOrgAsync.success(orgId));
  }

  return result;
};

export const OrgEmails = () => async (
  dispatch: Dispatch,
  getState: () => IState,
) => {
  const url = `${API_URL}/organizations/optOutOfEmails`;
  const { token } = getState().auth;

  const { orgId } = getState().admin;

  const data = { orgId };

  if (!orgId) {
    dispatch(OrgEmailsAsync.failure('ordId undefined'));
    return;
  }

  const result = await patchJson<IOrgEmailsResponse>(url, data, token);

  if (result.success) {
    dispatch(OrgEmailsAsync.success(result.data.optOutOfEmails));
  }
};
