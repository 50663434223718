// Copyright (C) AirWorks Solutions, Inc - All Rights Reserved
// DO NOT REDISTRIBUTE
// UNAUTHORIZED COPYING OF THIS FILE, ANY PART OR WHOLE, VIA ANY MEDIUM IS STRICTLY PROHIBITED
// PROPRIETARY AND CONFIDENTIAL

import React from 'react';
import { Typography, Card } from '@material-ui/core';
import CreditCard from './CreditCard';
import CreateCreditCard from './CreateCreditCard';
import compose, { PropsType } from './container';
import useStyles from './styles';

export const PaymentInfo = ({ cards }: PropsType) => {
  const classes = useStyles();

  return (
    <>
      <Card className={classes.paymentInfoContent}>
        <Typography className={classes.header} variant="h4">Payment Info</Typography>
        {cards.map((card) => <CreditCard card={card} />)}
        <CreateCreditCard />
      </Card>
    </>
  );
};

export default compose(PaymentInfo);
